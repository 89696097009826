import { AnyAction, isType } from 'typescript-fsa';
import { ActionTokenCheckResponse } from '../../../services/account';
import { ActionTokenParams } from './';
import {
    actionTokenCheckPasswordResult,
    actionTokenSetExecuted,
    actionTokenSetParams,
    actionTokenSetType
} from './actions';
export interface ActionTokenState {
    params?: ActionTokenParams;
    checkResponse?: ActionTokenCheckResponse;
    isExecuted: boolean;
    checkPasswordResult: boolean;
}

const initialActionTokenState: ActionTokenState = {
    isExecuted: false,
    checkPasswordResult: true
};

export function actionTokenReducer(
    state: ActionTokenState = initialActionTokenState,
    action: AnyAction
): ActionTokenState {
    if (isType(action, actionTokenSetParams)) {
        return { ...state, params: action.payload };
    } else if (isType(action, actionTokenSetType)) {
        return { ...state, checkResponse: action.payload };
    } else if (isType(action, actionTokenSetExecuted)) {
        return { ...state, isExecuted: action.payload };
    } else if (isType(action, actionTokenCheckPasswordResult)) {
        return { ...state, checkPasswordResult: action.payload };
    }
    return state;
}
