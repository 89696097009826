import { GlobalState } from "../actions";

const teamChatSelector = (state: GlobalState) => state.teamChat;

export const teamChatChatSelector = (state: GlobalState) => teamChatSelector(state).chat;
export const teamChatPageSelector = (state: GlobalState) => teamChatSelector(state).page;
export const teamChatPageSizeSelector = (state: GlobalState) => teamChatSelector(state).pageSize;
export const teamChatCountSelector = (state: GlobalState) => teamChatSelector(state).count;
export const teamChatEntitySelector = (state: GlobalState) => teamChatSelector(state).messages;
export const teamChatVisibleSelector = (state: GlobalState) => teamChatSelector(state).visible;
export const teamChatIsAdminSelector = (state: GlobalState) => teamChatSelector(state).isAdmin;
export const teamChatIsMemberSelector = (state: GlobalState) => teamChatSelector(state).isMember;