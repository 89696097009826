import { faCheck, faExclamation, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BetState } from '../../Betting/components/BetComponent';
import { bettingBetStatePerGameSelector } from '../../Betting/selectors';

interface Props {
    gameId: string;
}

export const BetSavedIndicator: FC<Props> = props => {
    const [showSaved, setShowSaved] = useState(false);
    const [showSaving, setShowSaving] = useState(false);
    const [showError, setShowError] = useState(false);

    const betState = useSelector(bettingBetStatePerGameSelector(props.gameId));

    useEffect(() => {
        if (betState === BetState.saved) {
            setShowSaved(true);
            setShowSaving(false);
            setShowError(false);
            const timer = setTimeout(() => {
                setShowSaved(false);
            }, 3000);
            return () => clearTimeout(timer);
        } else if (betState === BetState.saving) {
            setShowSaving(true);
            setShowSaved(false);
            setShowError(false);
        } else if (betState === BetState.invalid) {
            setShowSaving(false);
            setShowSaved(false);
            setShowError(true);
        }
    }, [betState, props.gameId]);

    return (
        <>
            {showSaved && <FontAwesomeIcon className="text-success" icon={faCheck} />}
            {showSaving && <FontAwesomeIcon className="text-warning" icon={faSpinner} />}
            {showError && <FontAwesomeIcon className="text-danger" icon={faExclamation} />}
        </>
    );
};
