import { userSearchStringSelector } from './selectors';
import { all, select, throttle } from 'redux-saga/effects';
import { fetchWrapperFunc } from '../../../utils';
import { getBettingUserApi2, getSystemConfig } from '../../System/systemConfig';
import { userSearchSetEntities, userSearchSetResultCount, USER_SEARCH_LOAD } from './actions';

function* userSearchGenerator() {
    const searchString: string = yield select(userSearchStringSelector);

    const api = getBettingUserApi2();

    fetchWrapperFunc(() => api.searchActiveUsers({clientId: getSystemConfig().clientId, startPos: 0, slotSize: 10, q: searchString}), [
        r => userSearchSetEntities(r)
    ]);
    fetchWrapperFunc(() => api.countActiveUsers({clientId: getSystemConfig().clientId, q: searchString}), [
        r => userSearchSetResultCount(r)
    ]);
}

function* watchForUserSearchActions() {
    yield throttle(500, USER_SEARCH_LOAD, userSearchGenerator)
}

export function* userSearchSagas() {
    yield all([watchForUserSearchActions()])
}
