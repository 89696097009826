import { faBug } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import { ButtonWithLabelAndIcon } from '../../MenuBetting';
import { deleteSystemErrorMessages, systemShowErrors } from '../actions';
import { SystemError } from '../reducer';
import { errorMessageSelector, isSysAdminSelector, showErrorsSelector } from '../selectors';

export const ErrorMessageButton = () => {
    const errors = useSelector(errorMessageSelector);
    const isSysAdmin = useSelector(isSysAdminSelector);

    const dispatch = useDispatch();

    return (
        <>
            {errors.length > 0 && isSysAdmin && (
                <ButtonWithLabelAndIcon
                    className="ms-2"
                    label="Errors"
                    icon={faBug}
                    outline={false}
                    color="danger"
                    onClick={() => dispatch(systemShowErrors(true))}
                />
            )}
        </>
    );
};

export const ErrorMessage = () => {
    const errors = useSelector(errorMessageSelector);
    const showErrors = useSelector(showErrorsSelector);

    const dispatch = useDispatch();

    const onOK = () => {
        dispatch(systemShowErrors(false));
        dispatch(deleteSystemErrorMessages());
    };

    const onClose = () => {
        dispatch(systemShowErrors(false));
    };

    const displayError = (e: SystemError, index: number) => {
        return (
            <tr key={index}>
                <td>
                    {moment(e.date)
                        .locale('de')
                        .format('DD.MM.YYYY, HH:mm:ss.SSS')}
                </td>
                <td>{e.error.toString()}</td>
            </tr>
        );
    };

    return (
        <>
            <Modal isOpen={showErrors} toggle={onClose}>
                <ModalHeader>System Errors</ModalHeader>
                <ModalBody>
                    <Table>
                        <tbody>{errors && errors.map((e, i) => displayError(e, i))}</tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={onOK}>
                        Löschen und schließen
                    </Button>
                    <Button color="primary" onClick={onClose}>
                        Schließen
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};
