import { AnyAction, isType } from 'typescript-fsa/dist/typescript-fsa';
import { SystemProperty } from '../../../services/account';
import { systemPropertiesSet } from './actions';

export interface SystemPropertyState {
    systemProperties: SystemProperty[];
}

const defaultSystemPropertyState : SystemPropertyState = {
    systemProperties: []
}

export function systemPropertyReducer(state: SystemPropertyState = defaultSystemPropertyState, action: AnyAction): SystemPropertyState {
    if (isType(action, systemPropertiesSet)) {
        return {...state, systemProperties: action.payload};
    }
    return state;
}