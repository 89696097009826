import { AnyAction, isType } from 'typescript-fsa';
import { HighscoreStrategyDescriptor } from '../../../services/betting';
import { hsdSet, hsdSetUgId, hsdSetUgName } from './actions';

export interface HsdState {
    hsd: HighscoreStrategyDescriptor;
    ugId?: string;
    ugName?: string;
}

const InitialHsdState: HsdState = {
    hsd: {
        awayWinDifferenceMax: 0,
        awayWinDifferenceMin: 0,
        awayWinResultMax: 0,
        awayWinResultMin: 0,
        awayWinTendencyMax: 0,
        awayWinTendencyMin: 0,
        description: '',
        distinctByHomeAway: false,
        distinctByQuotes: false,
        drawResultMax: 0,
        drawResultMin: 0,
        drawTendencyMax: 0,
        drawTendencyMin: 0,
        homeWinDifferenceMax: 0,
        homeWinDifferenceMin: 0,
        homeWinResultMax: 0,
        homeWinResultMin: 0,
        homeWinTendencyMax: 0,
        homeWinTendencyMin: 0,
        id: 0,
        name: '',
        standardStrategy: false
    },
    ugId: undefined,
    ugName: undefined
};

export function hsdReducer(state: HsdState = InitialHsdState, action: AnyAction) {

    if (isType(action, hsdSet)) {
        return {...state, hsd: action.payload};
    } else if (isType(action, hsdSetUgId)) {
        return {...state, ugId: action.payload};
    } else if (isType(action, hsdSetUgName)) {
        return {...state, ugName: action.payload};
    }
    return state;
}