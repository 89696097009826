/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.25
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserProfileSettings
 */
export interface UserProfileSettings {
    /**
     * 
     * @type {number}
     * @memberof UserProfileSettings
     */
    userId: number;
    /**
     * 
     * @type {string}
     * @memberof UserProfileSettings
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileSettings
     */
    emailToConfirm?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfileSettings
     */
    infoMails: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfileSettings
     */
    emailValid: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfileSettings
     */
    pushNotifications: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfileSettings
     */
    newsletter: boolean;
    /**
     * 
     * @type {Date}
     * @memberof UserProfileSettings
     */
    nextNewsletterTeaser?: Date;
    /**
     * 
     * @type {Date}
     * @memberof UserProfileSettings
     */
    nextInvalidEmailTeaser?: Date;
}

/**
 * Check if a given object implements the UserProfileSettings interface.
 */
export function instanceOfUserProfileSettings(value: object): value is UserProfileSettings {
    if (!('userId' in value) || value['userId'] === undefined) return false;
    if (!('infoMails' in value) || value['infoMails'] === undefined) return false;
    if (!('emailValid' in value) || value['emailValid'] === undefined) return false;
    if (!('pushNotifications' in value) || value['pushNotifications'] === undefined) return false;
    if (!('newsletter' in value) || value['newsletter'] === undefined) return false;
    return true;
}

export function UserProfileSettingsFromJSON(json: any): UserProfileSettings {
    return UserProfileSettingsFromJSONTyped(json, false);
}

export function UserProfileSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserProfileSettings {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'email': json['email'] == null ? undefined : json['email'],
        'emailToConfirm': json['emailToConfirm'] == null ? undefined : json['emailToConfirm'],
        'infoMails': json['infoMails'],
        'emailValid': json['emailValid'],
        'pushNotifications': json['pushNotifications'],
        'newsletter': json['newsletter'],
        'nextNewsletterTeaser': json['nextNewsletterTeaser'] == null ? undefined : (new Date(json['nextNewsletterTeaser'])),
        'nextInvalidEmailTeaser': json['nextInvalidEmailTeaser'] == null ? undefined : (new Date(json['nextInvalidEmailTeaser'])),
    };
}

export function UserProfileSettingsToJSON(value?: UserProfileSettings | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'email': value['email'],
        'emailToConfirm': value['emailToConfirm'],
        'infoMails': value['infoMails'],
        'emailValid': value['emailValid'],
        'pushNotifications': value['pushNotifications'],
        'newsletter': value['newsletter'],
        'nextNewsletterTeaser': value['nextNewsletterTeaser'] == null ? undefined : ((value['nextNewsletterTeaser']).toISOString()),
        'nextInvalidEmailTeaser': value['nextInvalidEmailTeaser'] == null ? undefined : ((value['nextInvalidEmailTeaser']).toISOString()),
    };
}

