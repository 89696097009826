import * as React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'reactstrap';
import { RoundHighscoreLink } from '../../../../links/RoundHighscoreLink';
import { HighscoreInfo } from '../../../../services/betting';
import { Section, SectionHeading } from '../../../common/Section';
import { userViewUserIdSelector } from '../../selectors';

interface Props {
    highscores: HighscoreInfo[];
}

export const UserViewBettingCurrentHighscoresComponent = (props: Props) => {
    const userId = useSelector(userViewUserIdSelector);

    function renderHighscore(hs: HighscoreInfo) {
        return (
            <tr key={hs.rank}>
                <td>{hs.tournamentName}</td>
                <td>
                    {userId && (
                        <RoundHighscoreLink trId={String(hs.tournamentRoundId)} userId={userId}>
                            {hs.rank}
                        </RoundHighscoreLink>
                    )}
                </td>
            </tr>
        );
    }

    return (
        <Section>
            <SectionHeading>Aktuelle Platzierungen</SectionHeading>
            <Table striped={true}>
                <thead>
                    <tr>
                        <th>Turnier</th>
                        <th>Platzierung</th>
                    </tr>
                </thead>
                <tbody>{props.highscores && props.highscores.map(hs => renderHighscore(hs))}</tbody>
            </Table>
        </Section>
    );
};

export const UserViewBettingCurrentHighscores = UserViewBettingCurrentHighscoresComponent;
