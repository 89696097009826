/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.34
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FixtureStatistic } from './FixtureStatistic';
import {
    FixtureStatisticFromJSON,
    FixtureStatisticFromJSONTyped,
    FixtureStatisticToJSON,
} from './FixtureStatistic';
import type { Team } from './Team';
import {
    TeamFromJSON,
    TeamFromJSONTyped,
    TeamToJSON,
} from './Team';

/**
 * 
 * @export
 * @interface MatchStatistic
 */
export interface MatchStatistic {
    /**
     * 
     * @type {Team}
     * @memberof MatchStatistic
     */
    team1: Team;
    /**
     * 
     * @type {Team}
     * @memberof MatchStatistic
     */
    team2: Team;
    /**
     * 
     * @type {FixtureStatistic}
     * @memberof MatchStatistic
     */
    allStats: FixtureStatistic;
    /**
     * 
     * @type {FixtureStatistic}
     * @memberof MatchStatistic
     */
    homeStats: FixtureStatistic;
    /**
     * 
     * @type {FixtureStatistic}
     * @memberof MatchStatistic
     */
    awayStats: FixtureStatistic;
}

/**
 * Check if a given object implements the MatchStatistic interface.
 */
export function instanceOfMatchStatistic(value: object): value is MatchStatistic {
    if (!('team1' in value) || value['team1'] === undefined) return false;
    if (!('team2' in value) || value['team2'] === undefined) return false;
    if (!('allStats' in value) || value['allStats'] === undefined) return false;
    if (!('homeStats' in value) || value['homeStats'] === undefined) return false;
    if (!('awayStats' in value) || value['awayStats'] === undefined) return false;
    return true;
}

export function MatchStatisticFromJSON(json: any): MatchStatistic {
    return MatchStatisticFromJSONTyped(json, false);
}

export function MatchStatisticFromJSONTyped(json: any, ignoreDiscriminator: boolean): MatchStatistic {
    if (json == null) {
        return json;
    }
    return {
        
        'team1': TeamFromJSON(json['team1']),
        'team2': TeamFromJSON(json['team2']),
        'allStats': FixtureStatisticFromJSON(json['allStats']),
        'homeStats': FixtureStatisticFromJSON(json['homeStats']),
        'awayStats': FixtureStatisticFromJSON(json['awayStats']),
    };
}

export function MatchStatisticToJSON(value?: MatchStatistic | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'team1': TeamToJSON(value['team1']),
        'team2': TeamToJSON(value['team2']),
        'allStats': FixtureStatisticToJSON(value['allStats']),
        'homeStats': FixtureStatisticToJSON(value['homeStats']),
        'awayStats': FixtureStatisticToJSON(value['awayStats']),
    };
}

