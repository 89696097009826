import * as React from 'react';
import { getSystemConfig } from '../../System/systemConfig';

interface Props {
    imageId?: string;
}

export const AlbumImage: React.FC<Props> = props => {
    if (props.imageId && props.imageId !== 'null') {
        return <img className="img-fluid" style={{maxWidth: "50px"}} src={getSystemConfig().imageUrl(props.imageId)} alt="Cover"/>;
    } else {
        return <img src="https://static2.funtip.de/static-img/r1/cover.gif" alt="Cover" />;
    }
};
