import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { systemAccessTokenSelector } from '../selectors';
import { useDispatch } from 'react-redux';
import { logout } from '../actions';

export const VerfiyAccessToken: FC = () => {
    const dispatch = useDispatch();
    const accessToken = useSelector(systemAccessTokenSelector);

    const isTokenExpired = (token: string) => {
        const expiry = JSON.parse(atob(token.split('.')[1])).exp;
        return Math.floor(new Date().getTime() / 1000) >= expiry;
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (accessToken && isTokenExpired(accessToken)) {
                // dispatch action to logout
                dispatch(logout());
            }
        }, 10000);
        return () => clearInterval(interval);
    }, [accessToken, dispatch]);

    return null;
};
