import * as React from 'react';
import { Button, ButtonProps } from 'reactstrap';
import { ConfirmationDialog } from '../ConfirmationDialog';

interface Props extends ButtonProps {
    title: string;
    messages: string[];

    onOK: () => void;
}

interface State {
    showConfirmationDialog: number;
}

export class ButtonWithMultipleConfirmations extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = { showConfirmationDialog: 0 };

        this.handleButtonClick = this.handleButtonClick.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleOK = this.handleOK.bind(this);
    }

    public handleButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
        this.setState(Object.assign({}, this.state, { showConfirmationDialog: 1 }));
    }

    public handleCancel() {
        this.setState(Object.assign({}, this.state, { showConfirmationDialog: 0 }));
    }

    public handleOK() {
        const newCounter = this.state.showConfirmationDialog + 1;
        if (newCounter === this.props.messages.length + 1) {
            this.props.onOK();
            this.setState(Object.assign({}, this.state, { showConfirmationDialog: 0 }));
        } else {
            this.setState(Object.assign({}, this.state, { showConfirmationDialog: newCounter }));
        }
    }

    render() {
        const { title, message, onOK, ...rest } = this.props;
        return (
            <>
                <Button {...rest} title={title} onClick={this.handleButtonClick}>
                    {this.props.children}
                </Button>
                {this.state.showConfirmationDialog !== 0 && (
                    <ConfirmationDialog
                        title={this.props.title}
                        message={this.props.messages[this.state.showConfirmationDialog - 1]}
                        onOK={this.handleOK}
                        onCancel={this.handleCancel}
                    />
                )}
            </>
        );
    }
}
