import { GlobalState } from '../actions';

export const gihsSelector = (state: GlobalState) => state.groupInternalHighscore;

export const gihsStartColumnSelector = (state: GlobalState) => gihsSelector(state).startColumn;
export const gihsColumnCountSelector = (state: GlobalState) => gihsSelector(state).columnCount;
export const gihsEntitySelector = (state: GlobalState) => gihsSelector(state).highscore;
export const gihsIsLoadingSelector = (state: GlobalState) => gihsSelector(state).isLoading;
export const gihsLeftEnabledSelector = (state: GlobalState) => gihsSelector(state).leftEnabled;
export const gihsRightEnabledSelector = (state: GlobalState) => gihsSelector(state).rightEnabled;
export const gihsUserGroupIdSelector = (state: GlobalState) => gihsSelector(state).userGroupId;
export const gihsUserGroupDetailsSelector = (state: GlobalState) => gihsSelector(state).userGroup;
export const gihsAvailableUserGroupsSelector = (state: GlobalState) => gihsSelector(state).availableUserGroups;