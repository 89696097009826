import { faArrowDown, faArrowUp, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Input, Table } from 'reactstrap';
import { NewsletterBlock } from '../../../../services/webapp';
import { isValidEmail } from '../../../../utils';
import { NewsletterPreview } from '../../../AccountUserApp/NewsletterUserProfile';
import { ButtonWithConfirmation } from '../../../common/ButtonWithConfirmation';
import { LoadingIndicatorSpinner2 } from '../../../common/LoadingIndicatorSpinner2';
import { Section, SectionHeading } from '../../../common/Section';
import { systemSetPopupMessage } from '../../../System/actions';
import { getNewsletterAdminApi, getSystemConfig } from '../../../System/systemConfig';
import { NewsletterBlockEdit } from '../NewsletterBlockEdit';
import { NewsletterHeaderFooterEdit } from '../NewsletterHeaderFooterEdit';
import { NewsletterMainEdit } from './NewsletterMainEdit';
import { LoadingIndicatorContent } from '../../../common/LoadingIndicatorSpinner';
import { newsletterListPath } from '../..';

interface Props {}

export const newsletterEditPath = (nlId: string) => `/client-admin/newsletter/list/${nlId}`;

export const NewsletterEdit: FC<Props> = (props) => {
    const { nlId } = useParams();

    const [newsletterChecked, setNewsletterChecked] = React.useState(false);
    const [newsletterSent, setNewsletterSent] = React.useState(false);

    const [emailPreviewAddress, setEmailPreviewAddress] = React.useState('');

    const api = getNewsletterAdminApi();

    const newsletterQuery = useQuery({
        queryKey: ['newsletter', nlId],
        queryFn: () => api.getNewsletter({ nlId: nlId! }),
        enabled: !!nlId,
    });

    const newsletterLayoutBlockQuery = useQuery({
        queryKey: ['newsletterLayoutBlocks', newsletterQuery.data?.layoutId],
        queryFn: () => api.getNewsletterLayoutBlocks({ nllId: newsletterQuery.data?.layoutId! }),
        enabled: !!newsletterQuery.data?.layoutId,
    });

    const newsletterBlockQuery = useQuery({
        queryKey: ['newsletterBlock', nlId],
        queryFn: () => api.getNewsletterBlocks({ nlId: nlId! }),
        enabled: !!nlId,
    });

    const newsletterReceiverFilterByIdQuery = useQuery({
        queryKey: ['newsletterReceiverFilter', newsletterQuery.data?.receiverFilterId],
        queryFn: () => api.getNewsletterReceiverFilter({ nlrfId: newsletterQuery.data!.receiverFilterId }),
        enabled: !!newsletterQuery.data?.receiverFilterId,
    });

    const checkNewsletterMutation = useMutation({
        mutationFn: () => api.checkNewsletterBeforeSending({ nlId: nlId! }),
        onSuccess: () => {
            newsletterReceiverFilterByIdQuery.refetch();
        },
    });

    const dispatch = useDispatch();

    const onCheckNewsletter = () => {
        checkNewsletterMutation.mutate(undefined, {
            onSuccess(data, variables, context) {
                const code = data;
                switch (code) {
                    case 1: // abuse text is missing
                        dispatch(systemSetPopupMessage('Der Newsletter hat keinen Abmeldeblock für den User!'));
                        break;
                    case 2: // invalid state
                        dispatch(systemSetPopupMessage('Der Newsletter wurde bereits versendet!'));
                        break;
                    case 3: // newsletter not found
                        dispatch(systemSetPopupMessage('Der Newsletter wurde nicht gefunden!'));
                        break;
                    default:
                        dispatch(systemSetPopupMessage('Der Newsletter ist in Ordnung und kann versendet werden!'));
                        setNewsletterChecked(true);
                }
            },
        });
    };

    const getLayoutBlockName = (layoutBlockId: string) => {
        return newsletterLayoutBlockQuery.data?.find((nllb) => nllb.id === layoutBlockId)?.name;
    };

    const newsletterBlockUpMutation = useMutation({
        mutationFn: (nlbId: string) => api.newsletterBlockUp({ nlbId }),
    });

    const newsletterBlockDownMutation = useMutation({
        mutationFn: (nlbId: string) => api.newsletterBlockDown({ nlbId }),
    });

    const newsletterBlockDeleteMutation = useMutation({
        mutationFn: (nlbId: string) => api.deleteNewsletterBlock({ nlbId }),
    });

    const newsletterEmailPreviewMutation = useMutation({
        mutationFn: (nlId: string) => api.emailPreview({ nlId, email: emailPreviewAddress }),
    });

    const onEmailPreview = () => {
        if (nlId) {
            newsletterEmailPreviewMutation.mutate(nlId, {
                onSuccess: () => {
                    dispatch(systemSetPopupMessage('Die Email-Vorschau wird in den nächsten Minuten versendet'));
                },
                onError: (error) => {
                    dispatch(systemSetPopupMessage('Fehler beim Versenden der Email-Vorschau: ' + error));
                },
            });
        }
    };

    const onDeleteBlock = (nlbId: string) => {
        newsletterBlockDeleteMutation.mutate(nlbId, {
            onSuccess: () => {
                newsletterBlockQuery.refetch();
                setNewsletterChecked(false);
            },
            onError: (error) => {
                dispatch(systemSetPopupMessage('Fehler beim Löschen des Blocks: ' + error));
            },
        });
    };

    const onBlockUp = (nlbId: string) => {
        newsletterBlockUpMutation.mutate(nlbId, {
            onSuccess: () => {
                newsletterBlockQuery.refetch();
            },
            onError: (error) => {
                dispatch(systemSetPopupMessage('Fehler beim Verschieben des Blocks: ' + error));
            },
        });
    };

    const onBlockDown = (nlbId: string) => {
        newsletterBlockDownMutation.mutate(nlbId, {
            onSuccess: () => {
                newsletterBlockQuery.refetch();
            },
            onError: (error) => {
                dispatch(systemSetPopupMessage('Fehler beim Verschieben des Blocks: ' + error));
            },
        });
    };

    const renderBlock = (nlb: NewsletterBlock, index: number, a: NewsletterBlock[]) => {
        return (
            <tr key={nlb.id}>
                <td>{getLayoutBlockName(nlb.newsletterLayoutBlockId)}</td>
                <td>
                    <Button color="link" className="p-0 m-0" onClick={() => setShowBlockEditModal(nlb.id)}>
                        {!!nlb.title ? nlb.title : '[ohne Namen]'}
                    </Button>
                </td>
                <td>
                    <NewsletterExternalLink link={nlb.link} />
                </td>
                <td className="">
                    <NewsletterImage imageId={nlb.imageId} />
                </td>
                <td>
                    <Button
                        color="link"
                        size="sm"
                        className={`border m-1 ${index === 0 ? 'bg-light' : 'bg-white'}`}
                        disabled={index === 0}
                        onClick={() => onBlockUp(nlb.id)}
                    >
                        <FontAwesomeIcon icon={faArrowUp} color="black" />
                    </Button>
                    <Button
                        color="link"
                        size="sm"
                        className={`border m-1 ${index === a.length - 1 ? 'bg-light' : 'bg-white'}`}
                        disabled={index === a.length - 1}
                        onClick={() => onBlockDown(nlb.id)}
                    >
                        <FontAwesomeIcon icon={faArrowDown} color="black" />
                    </Button>
                    <ButtonWithConfirmation
                        color="link"
                        size="sm"
                        className="border m-1"
                        onOK={() => onDeleteBlock(nlb.id)}
                        title="Newsletter-Thema löschen"
                        message={`Sind Sie sicher, dass Sie den Block '${nlb.title}' löschen wollen?`}
                    >
                        <FontAwesomeIcon icon={faTrash} color="red" />
                    </ButtonWithConfirmation>
                </td>
            </tr>
        );
    };

    const [showHeaderModal, setShowHeaderModal] = React.useState(false);
    const [showFooterModal, setShowFooterModal] = React.useState(false);
    const [showBlockEditModal, setShowBlockEditModal] = React.useState('');

    const [showPreview, setShowPreview] = React.useState(false);

    const onModalClose = () => {
        setShowHeaderModal(false);
        setShowFooterModal(false);
        setShowBlockEditModal('');
        setNewsletterChecked(false);
        newsletterBlockQuery.refetch();
    };

    const onPreview = () => {
        setShowPreview(true);
    };

    const sendNewsletterMutation = useMutation({
        mutationFn: () => api.sendNewsletter({ nlId: nlId! }),
    });

    const onSend = () => {
        sendNewsletterMutation.mutate(undefined, {
            onSuccess: () => {
                newsletterQuery.refetch();
                dispatch(systemSetPopupMessage('Newsletter wird versendet'));
                setNewsletterSent(true);
            },
            onError: (error) => {
                dispatch(systemSetPopupMessage('Fehler beim Versenden des Newsletters: ' + error));
            },
        });
    };

    return (
        <div className="mt-2">
            <LoadingIndicatorSpinner2
                isLoading={
                    newsletterQuery.isFetching || newsletterBlockQuery.isFetching || checkNewsletterMutation.isPending
                }
            />

            <NewsletterMainEdit nlId={nlId} afterStore={() => setNewsletterChecked(false)} />

            <Section>
                <SectionHeading>Newsletter-Layout</SectionHeading>
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <div className="">
                        <Button className="m-1" color="success" onClick={() => setShowBlockEditModal('-1')}>
                            <FontAwesomeIcon icon={faPlus} /> Neuer Block
                        </Button>
                    </div>
                    {nlId && (
                        <div className="">
                            <Button className="m-1 border" color="dark" onClick={onPreview}>
                                Vorschau
                            </Button>
                            {showPreview && (
                                <NewsletterPreview newsletterId={nlId!} onClose={() => setShowPreview(false)} />
                            )}
                        </div>
                    )}
                    <div className="border px-1 py-1 bg-white align-items-center justify-content-center flex-nowrap d-flex">
                        <Input
                            type="email"
                            className="p-1 m-1 "
                            placeholder="EMail-Adresse"
                            value={emailPreviewAddress}
                            onChange={(e) => setEmailPreviewAddress(e.currentTarget.value)}
                        />
                        <Button
                            className="m-1 border text-nowrap"
                            color="dark"
                            disabled={!isValidEmail(emailPreviewAddress) || newsletterEmailPreviewMutation.isPending}
                            onClick={onEmailPreview}
                        >
                            <LoadingIndicatorContent isLoading={newsletterEmailPreviewMutation.isPending}>
                                Email-Vorschau
                            </LoadingIndicatorContent>
                        </Button>
                    </div>
                    <div className="">
                        {newsletterChecked && !newsletterSent && (
                            <ButtonWithConfirmation
                                className="m-1"
                                color="danger"
                                onOK={onSend}
                                title="Newsletter versenden"
                                message={`Sind Sie sicher, dass Sie den Newsletter an ${newsletterReceiverFilterByIdQuery.data?.receiverCount} Empfänger versenden wollen?`}
                            >
                                Versendung an alle Empfänger
                            </ButtonWithConfirmation>
                        )}
                        {!newsletterChecked && (
                            <Button color="danger" onClick={onCheckNewsletter}>
                                <LoadingIndicatorContent
                                    isLoading={checkNewsletterMutation.isPending}
                                ></LoadingIndicatorContent>
                                Newsletter prüfen{' '}
                            </Button>
                        )}
                    </div>
                </div>

                <NewsletterHeaderFooterEdit show={showHeaderModal} type="header" nlId={nlId!} onClose={onModalClose} />
                <NewsletterHeaderFooterEdit show={showFooterModal} type="footer" nlId={nlId!} onClose={onModalClose} />
                <NewsletterBlockEdit
                    nlId={nlId!}
                    show={!!showBlockEditModal}
                    nlbId={String(showBlockEditModal)}
                    onClose={onModalClose}
                    mode={showBlockEditModal === '-1' ? 'create' : 'edit'}
                />

                <hr />
                <Table>
                    <thead>
                        <tr>
                            <th>Layout</th>
                            <th>Thema</th>
                            <th>Link</th>
                            <th className="text-center">Bild</th>
                            <th colSpan={2} className="text-center">
                                Aktionen
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Header</td>
                            <td>
                                <Button color="link" className="m-0 p-0" onClick={() => setShowHeaderModal(true)}>
                                    {!!newsletterQuery.data?.headerTitle
                                        ? newsletterQuery.data?.headerTitle
                                        : '[ohne Namen]'}
                                </Button>
                            </td>
                            <td>
                                <NewsletterExternalLink link={newsletterQuery.data?.headerLink} />
                            </td>
                            <td>
                                <NewsletterImage imageId={newsletterQuery.data?.headerImageId} />
                            </td>
                            <td></td>
                        </tr>

                        {newsletterBlockQuery.data?.map((block, index, a) => renderBlock(block, index, a))}
                        <tr>
                            <td>Footer</td>
                            <td>
                                <Button color="link" className="m-0 p-0" onClick={() => setShowFooterModal(true)}>
                                    {!!newsletterQuery.data?.footerTitle
                                        ? newsletterQuery.data?.footerTitle
                                        : '[ohne Namen]'}
                                </Button>
                            </td>
                            <td>
                                <NewsletterExternalLink link={newsletterQuery.data?.footerLink} />
                            </td>
                            <td>
                                <NewsletterImage imageId={newsletterQuery.data?.footerImageId} />
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </Table>
            </Section>
            <div>
                <Link to={newsletterListPath()}>Zurück zur Newsletter-Übersicht</Link>
            </div>
        </div>
    );
};

interface NewsletterImageProps {
    imageId: string | undefined;
    maxWidth?: string;
}

export const NewsletterImage: FC<NewsletterImageProps> = (props) => {
    if (props.imageId) {
        return (
            <img
                src={getSystemConfig().imageUrl(props.imageId)}
                alt=""
                className={`img-fluid rounded`}
                style={{ maxWidth: props.maxWidth || '75px' }}
            />
        );
    }
    return null;
};

const NewsletterExternalLink: FC<{ link: string | undefined }> = (props) => {
    if (props.link) {
        return (
            <a href={props.link}>
                <span
                    className="text-truncate"
                    style={{ maxWidth: '200px', display: 'inline-block' }}
                    title={props.link}
                >
                    {props.link}
                </span>
            </a>
        );
    }
    return null;
};
