import { useMutation, useQuery } from '@tanstack/react-query';
import * as R from 'ramda';
import { FC, MouseEvent, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { userGroupAdminPath } from '../../links/UserGroupAdminLink';
import UserLink from '../../links/UserLink';
import { LoadUserImage } from '../../links/UserLink/UserImage';
import { withBorder } from '../../reactutils/withBorder';
import { TabbedContent } from '../../reactutils/withTabbedScreen';
import { withTitle } from '../../reactutils/withTitle';
import { userGroupSubTitleSelector } from '../../screens/UserGroupController';
import { TeamChatMessage } from '../../services/image';
import { ButtonWithConfirmation } from '../common/ButtonWithConfirmation';
import { Section, SectionHeading } from '../common/Section';
import { DateTime } from '../DateTime';
import { groupInternalHighscoreForwarderPath } from '../GroupInternaltHighscoresForwarder';
import { usePathSegment } from '../SysAdminApp/CacheStatisticsComponent';
import { systemSetPopupMessage } from '../System/actions';
import { userIdSelector } from '../System/selectors';
import { getTeamChat2Api, getUserGroupApi2 } from '../System/systemConfig';

const TabbedTeamChatComponent: FC<{ ugId: string }> = (props) => {
    const api = getTeamChat2Api();
    const userGroupApi = getUserGroupApi2();

    const dispatch = useDispatch();

    const [message, setMessage] = useState('');

    const [allMessages, setAllMessages] = useState<TeamChatMessage[]>([]);

    const [page, setPage] = useState(0);
    const [visible, setVisible] = useState(false);

    const pageSize = 10;

    const loginUserid = useSelector(userIdSelector);

    const getPageCount = () => Math.floor((countMessagesQuery.data?.value ?? 0) / pageSize);

    const messageQuery = useQuery({
        queryKey: ['teamChat2.getMessages', { ugId: props.ugId, page: page }],
        queryFn: () => api.getMessages({page, pageSize, ugId: props.ugId}),
        enabled: !!props.ugId && visible,
    });

    const teamChatQuery = useQuery({
        queryKey: ['teamChat2', { ugId: props.ugId }],
        queryFn: () => api.getTeamChat({ugId: props.ugId}),
        enabled: !!props.ugId,
    });

    const isMemberQuery = useQuery({
        queryKey: ['userGroup.isMember', { ugId: props.ugId }],
        queryFn: () => userGroupApi.isMember({ugId: Number(props.ugId)}),
        enabled: !!props.ugId,
    });

    const isAdminQuery = useQuery({
        queryKey: ['teamChat2.isAdmin', { ugId: props.ugId }],
        queryFn: () => userGroupApi.isAdmin({ugId: Number(props.ugId)}),
        enabled: !!props.ugId,
    });

    const countMessagesQuery = useQuery({
        queryKey: ['teamChat2.countMessages', { ugId: props.ugId }],
        queryFn: () => api.countMessages({ugId: props.ugId}),
        enabled: !!props.ugId,
    });

    useEffect(() => {
        if (messageQuery.data) {
            setAllMessages((allMessages) => [...allMessages, ...messageQuery.data]);
        }
    }, [messageQuery.data]);

    useEffect(() => {
        setVisible(teamChatQuery.data?.published || !!isMemberQuery.data);
    }, [isMemberQuery.data, teamChatQuery.data?.published]);

    useEffect(() => {
        const interval = setInterval(() => countMessagesQuery.refetch(), 120000);
        return () => clearInterval(interval);
    }, [props.ugId, countMessagesQuery]);

    const loadNext = () => {
        if (page < getPageCount()) {
            setPage(page + 1);
        }
    };

    const publishMutation = useMutation({
        mutationFn: () => api.publish({ugId: props.ugId}),
    });

    const unpublishMutation = useMutation({
        mutationFn: () => api.unpublish({ugId: props.ugId}),
    });

    const publish = () => {
        publishMutation.mutate(undefined, {
            onSuccess: () => {
                teamChatQuery.refetch();
            },
            onError: (error) => {
                dispatch(systemSetPopupMessage('Error publishing chat: ' + error));
            },
        });
    };

    const unpublish = () => {
        unpublishMutation.mutate(undefined, {
            onSuccess: () => {
                teamChatQuery.refetch();
            },
            onError: (error) => {
                dispatch(systemSetPopupMessage('Error unpublishing chat: ' + error));
            },
        });
    };

    const postMessageMutation = useMutation({
        mutationFn: (msg: string) => api.addMessage({ugId: props.ugId, message: msg}),
    });

    const postMessage = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        postMessageMutation.mutate(message, {
            onSuccess: () => {
                setMessage('');
                setAllMessages([]);
                countMessagesQuery.refetch();
                messageQuery.refetch();
            },
            onError: (error) => {
                dispatch(systemSetPopupMessage('Error posting message: ' + error));
            },
        });
        setMessage('');
    };

    const setEmojiMessage = (s: string) => {
        const sWithEmojis = s
            .replace(':-)', '\u{1F642}')
            .replace(':-D', '\u{1F603}')
            .replace(';-)', '\u{1F609}')
            .replace(':-(', '\u{1F612}')
            .replace(':-P', '\u{1F61C}')
            .replace(':-O', '\u{1F632}');
        setMessage(sWithEmojis);
    };

    const renderRow = (msg: TeamChatMessage) => {
        if (msg.userId === loginUserid) {
            return (
                <div className="media w-75 ms-auto" key={msg.id} style={{ flexShrink: 0 }}>
                    <div className="media-body">
                        <div className="bg--r1-menu rounded py-2 px-3 mb-2">
                            <p className="small text-white float-right">
                                <DateTime date={msg.date} format="long" />
                            </p>
                            <p className="text-small mb-0 text-white font-weight-bold">
                                <UserLink id={msg.userId} />
                            </p>
                            <p className="mb-0 text-white">{msg.message}</p>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="media w-75 mb-3" key={msg.id} style={{ flexShrink: 0 }}>
                    <LoadUserImage userId={msg.userId} width={50} height={50} className="rounded-circle" />
                    <div className="media-body ms-3">
                        <div className="bg-white rounded py-2 px-3 mb-2">
                            <p className="small text-muted float-right">
                                <DateTime date={msg.date} format="long" />
                            </p>
                            <p className="text-small mb-0 text-white font-weight-bold">
                                <UserLink id={msg.userId} />
                            </p>
                            <p className="mb-0">{msg.message}</p>
                        </div>
                    </div>
                    <p></p>
                </div>
            );
        }
    };

    return (
        <Section>
            <SectionHeading>Chat</SectionHeading>
            {!teamChatQuery.data?.published && (
                <p>
                    Der Chat der Gruppe ist nicht öffentlich.
                    {!!isAdminQuery.data && (
                        <ButtonWithConfirmation
                            className="ms-2"
                            color="success"
                            message="Sind Sie sicher, dass Sie den Chat für alle Nutzer veröffentlichen wollen?"
                            title="Chat veröffentlichen"
                            onOK={publish}
                        >
                            Veröffentlichen
                        </ButtonWithConfirmation>
                    )}
                </p>
            )}
            {visible && (
                <>
                    {teamChatQuery.data?.published && (
                        <p>
                            Der Chat der Gruppe ist öffentlich
                            {!!isAdminQuery.data && (
                                <ButtonWithConfirmation
                                    className="ms-2"
                                    color="success"
                                    message="Sind Sie sicher, dass Sie die Veröffentlichung des Chats beenden wollen?"
                                    title="Chat-Veröffentlichung beenden"
                                    onOK={unpublish}
                                >
                                    Veröffentlichung beenden
                                </ButtonWithConfirmation>
                            )}
                        </p>
                    )}
                    <InfiniteScroll
                        dataLength={allMessages.length} //This is important field to render the next data
                        next={loadNext}
                        hasMore={page < getPageCount()}
                        loader={<h4>Lade ...</h4>}
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                                <b>Keine weiteren Nachrichten.</b>
                            </p>
                        }
                        style={{ display: 'flex', flexDirection: 'column-reverse' }}
                        height={400}
                        // below props only if you need pull down functionality
                        // refreshFunction={initialLoad}
                        // pullDownToRefresh
                        // pullDownToRefreshThreshold={10}
                        // pullDownToRefreshContent={<h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>}
                        // releaseToRefreshContent={<h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>}
                        inverse={true}
                    >
                        {allMessages.map((m: TeamChatMessage) => renderRow(m))}
                    </InfiniteScroll>
                    <Form className="mt-3">
                        <FormGroup
                            inline={true}
                            className="d-flex align-content-center justify-content-between flex-wrap"
                        >
                            <Label className="me-2">Nachricht:</Label>
                            <Input
                                value={message}
                                size={60}
                                onChange={(e) => setEmojiMessage(e.currentTarget.value)}
                                className="me-2"
                            />
                            <Button type="submit" onClick={(e) => postMessage(e)} className="mt-2" color="success">
                                Senden
                            </Button>
                        </FormGroup>
                    </Form>
                </>
            )}
        </Section>
    );
};

const TeamChatComponent: FC<{}> = () => {
    const [ugId] = usePathSegment(3);

    return (
        <>
            <TabbedContent
                activeTab={'Chat'}
                tabNames={['Wertung', 'Tippteam', 'Chat']}
                tabLinks={userGroupTabLinks(ugId)}
            />
            <TabbedTeamChatComponent ugId={ugId} />
        </>
    );
};

const HighscoreTeamChatComponent: FC<{}> = () => {
    const [ugId] = usePathSegment(4);

    return (
        <TabbedContent activeTab={'Chat'} tabNames={['Wertung', 'Chat']} tabLinks={userGroupHighscoreTabLinks(ugId)}>
            <TabbedTeamChatComponent ugId={ugId} />
        </TabbedContent>
    );
};
export const TeamChat = R.compose(withBorder(), withTitle('Tippteam', userGroupSubTitleSelector))(TeamChatComponent);

export const HighscoreTeamChat = R.compose(
    withBorder(),
    withTitle('Tippteam', userGroupSubTitleSelector),
)(HighscoreTeamChatComponent);

export const userGroupChatPath = (ugId: string | number) => `/b/user-group/${ugId}/chat`;

export const userGroupHighscoreChatPath = (ugId: string | number) => `/b/user-group/highscore/${ugId}/chat`;

export const userGroupTabLinks = (ugId: string) => (tabName: string) => {
    if (tabName === 'Tippteam') {
        return userGroupAdminPath(ugId);
    } else if (tabName === 'Wertung') {
        return groupInternalHighscoreForwarderPath(ugId);
    } else {
        return userGroupChatPath(ugId);
    }
};

export const userGroupHighscoreTabLinks = (ugId: string) => (tabName: string) => {
    if (tabName === 'Wertung') {
        return groupInternalHighscoreForwarderPath(ugId);
    } else {
        return userGroupHighscoreChatPath(ugId);
    }
};
