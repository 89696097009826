import actionCreatorFactory from "typescript-fsa";
import { TeamChat, TeamChatMessage } from "../../services/image";

const actionCreator = actionCreatorFactory();


export interface TeamChatLoadRequest {
    ugId: string;
    page: number;
    pageSize: number;
}

export interface TeamChatPostRequest {
    ugId: string;
    message: string;
}

export const TEAM_CHAT_INITIAL_LOAD = 'TEAM_CHAT_INITIAL_LOAD';
export const teamChatInitialLoad = actionCreator<string>(TEAM_CHAT_INITIAL_LOAD);

export const TEAM_CHAT_LOAD_NEXT = 'TEAM_CHAT_LOAD_NEXT';
export const teamChatLoadNext = actionCreator<string>(TEAM_CHAT_LOAD_NEXT);

export const TEAM_CHAT_SET = 'TEAM_CHAT_SET';
export const teamChatSet = actionCreator<TeamChat>(TEAM_CHAT_SET);

export const TEAM_CHAT_PUBLISH = 'TEAM_CHAT_PUBLISH';
export const teamChatPublish = actionCreator<string>(TEAM_CHAT_PUBLISH);

export const TEAM_CHAT_UNPUBLISH = 'TEAM_CHAT_UNPUBLISH';
export const teamChatUnpublish = actionCreator<string>(TEAM_CHAT_UNPUBLISH);

export const TEAM_CHAT_CHECK_FOR_UPDATES = 'TEAM_CHAT_CHECK_FOR_UPDATES';
export const teamChatCheckForUpdates = actionCreator<string>(TEAM_CHAT_CHECK_FOR_UPDATES);

export const TEAM_CHAT_SET_MESSAGES = 'TEAM_CHAT_SET_MESSAGES';
export const teamChatSetMessages = actionCreator<TeamChatMessage[]>(TEAM_CHAT_SET_MESSAGES);

export const TEAM_CHAT_SET_VISIBLE = 'TEAM_CHAT_SET_VISIBLE';
export const teamChatSetVisible = actionCreator<boolean>(TEAM_CHAT_SET_VISIBLE);

export const TEAM_CHAT_SET_ADMIN = 'TEAM_CHAT_SET_ADMIN';
export const teamChatSetAdmin = actionCreator<boolean>(TEAM_CHAT_SET_ADMIN);

export const TEAM_CHAT_SET_MEMBER = 'TEAM_CHAT_SET_MEMBER';
export const teamChatSetMember = actionCreator<boolean>(TEAM_CHAT_SET_MEMBER);

export const TEAM_CHAT_SET_COUNT = 'TEAM_CHAT_SET_COUNT';
export const teamChatSetCount = actionCreator<number>(TEAM_CHAT_SET_COUNT);

export const TEAM_CHAT_SET_PAGE = 'TEAM_CHAT_SET_PAGE';
export const teamChatSetPage = actionCreator<number>(TEAM_CHAT_SET_PAGE);

export const TEAM_CHAT_ADD_MESSAGES = 'TEAM_CHAT_ADD_MESSAGES';
export const teamChatAddMessages = actionCreator<TeamChatMessage[]>(TEAM_CHAT_ADD_MESSAGES);

export const TEAM_CHAT_POST_MESSAGE = 'TEAM_CHAT_POST_MESSAGE';
export const teamChatPostMessages = actionCreator<TeamChatPostRequest>(TEAM_CHAT_POST_MESSAGE);

export const TEAM_CHAT_SET_LOADING = 'TEAM_CHAT_SET_LOADING';
export const teamChatSetLoading = actionCreator<boolean>(TEAM_CHAT_SET_LOADING);