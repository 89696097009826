/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.34
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BettingUser,
  BettingUserPublicProfile,
  Team,
  TournamentRound,
  TournamentSubscription,
  UserNotificationStatus,
} from '../models/index';
import {
    BettingUserFromJSON,
    BettingUserToJSON,
    BettingUserPublicProfileFromJSON,
    BettingUserPublicProfileToJSON,
    TeamFromJSON,
    TeamToJSON,
    TournamentRoundFromJSON,
    TournamentRoundToJSON,
    TournamentSubscriptionFromJSON,
    TournamentSubscriptionToJSON,
    UserNotificationStatusFromJSON,
    UserNotificationStatusToJSON,
} from '../models/index';

export interface AddFavoriteTeamRequest {
    teamId: number;
}

export interface CountActiveUsersRequest {
    clientId: number;
    q?: string;
}

export interface DeleteFavoriteTeamRequest {
    teamId: number;
}

export interface GetBettingUserProfileByIdRequest {
    userId: string;
}

export interface GetPublicProfileRequest {
    userId: string;
}

export interface GetWonTournamentRoundsRequest {
    userId: number;
}

export interface SearchActiveUsersRequest {
    clientId: number;
    slotSize: number;
    startPos: number;
    q?: string;
}

export interface ToggleTournamentSubscriptionRequest {
    tournamentId: string;
}

export interface UpdateSubscribedTournamentsRequest {
    subscribed: boolean;
    tournamentId: number;
}

export interface UpdateUserBettingPublicRequest {
    value?: boolean;
}

/**
 * 
 */
export class BettingUserControllerApi extends runtime.BaseAPI {

    /**
     */
    async addFavoriteTeamRaw(requestParameters: AddFavoriteTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Team>>> {
        if (requestParameters['teamId'] == null) {
            throw new runtime.RequiredError(
                'teamId',
                'Required parameter "teamId" was null or undefined when calling addFavoriteTeam().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/favorite-teams/{teamId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters['teamId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TeamFromJSON));
    }

    /**
     */
    async addFavoriteTeam(requestParameters: AddFavoriteTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Team>> {
        const response = await this.addFavoriteTeamRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async countActiveUsersRaw(requestParameters: CountActiveUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['clientId'] == null) {
            throw new runtime.RequiredError(
                'clientId',
                'Required parameter "clientId" was null or undefined when calling countActiveUsers().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user/{clientId}/count`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters['clientId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async countActiveUsers(requestParameters: CountActiveUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.countActiveUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteFavoriteTeamRaw(requestParameters: DeleteFavoriteTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Team>>> {
        if (requestParameters['teamId'] == null) {
            throw new runtime.RequiredError(
                'teamId',
                'Required parameter "teamId" was null or undefined when calling deleteFavoriteTeam().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/favorite-teams/{teamId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters['teamId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TeamFromJSON));
    }

    /**
     */
    async deleteFavoriteTeam(requestParameters: DeleteFavoriteTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Team>> {
        const response = await this.deleteFavoriteTeamRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getBettingUserProfileRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BettingUser>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BettingUserFromJSON(jsonValue));
    }

    /**
     */
    async getBettingUserProfile(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BettingUser> {
        const response = await this.getBettingUserProfileRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getBettingUserProfileByIdRaw(requestParameters: GetBettingUserProfileByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BettingUser>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling getBettingUserProfileById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user/profile/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BettingUserFromJSON(jsonValue));
    }

    /**
     */
    async getBettingUserProfileById(requestParameters: GetBettingUserProfileByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BettingUser> {
        const response = await this.getBettingUserProfileByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getFavoriteTeamsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Team>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/favorite-teams`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TeamFromJSON));
    }

    /**
     */
    async getFavoriteTeams(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Team>> {
        const response = await this.getFavoriteTeamsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getFavoriteTeamsAvailableRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Team>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/favorite-teams-available`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TeamFromJSON));
    }

    /**
     */
    async getFavoriteTeamsAvailable(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Team>> {
        const response = await this.getFavoriteTeamsAvailableRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getNotificationStatusRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserNotificationStatus>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/notification-status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserNotificationStatusFromJSON(jsonValue));
    }

    /**
     */
    async getNotificationStatus(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserNotificationStatus> {
        const response = await this.getNotificationStatusRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getPublicProfileRaw(requestParameters: GetPublicProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BettingUserPublicProfile>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling getPublicProfile().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user/public-profile/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BettingUserPublicProfileFromJSON(jsonValue));
    }

    /**
     */
    async getPublicProfile(requestParameters: GetPublicProfileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BettingUserPublicProfile> {
        const response = await this.getPublicProfileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSubscribedTournamentsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TournamentSubscription>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/subscribed-tournaments`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TournamentSubscriptionFromJSON));
    }

    /**
     */
    async getSubscribedTournaments(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TournamentSubscription>> {
        const response = await this.getSubscribedTournamentsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getWonTournamentRoundsRaw(requestParameters: GetWonTournamentRoundsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TournamentRound>>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling getWonTournamentRounds().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user/won-rounds/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TournamentRoundFromJSON));
    }

    /**
     */
    async getWonTournamentRounds(requestParameters: GetWonTournamentRoundsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TournamentRound>> {
        const response = await this.getWonTournamentRoundsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchActiveUsersRaw(requestParameters: SearchActiveUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<BettingUser>>> {
        if (requestParameters['clientId'] == null) {
            throw new runtime.RequiredError(
                'clientId',
                'Required parameter "clientId" was null or undefined when calling searchActiveUsers().'
            );
        }

        if (requestParameters['slotSize'] == null) {
            throw new runtime.RequiredError(
                'slotSize',
                'Required parameter "slotSize" was null or undefined when calling searchActiveUsers().'
            );
        }

        if (requestParameters['startPos'] == null) {
            throw new runtime.RequiredError(
                'startPos',
                'Required parameter "startPos" was null or undefined when calling searchActiveUsers().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/user/{clientId}/search/{startPos}/{slotSize}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters['clientId']))).replace(`{${"slotSize"}}`, encodeURIComponent(String(requestParameters['slotSize']))).replace(`{${"startPos"}}`, encodeURIComponent(String(requestParameters['startPos']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BettingUserFromJSON));
    }

    /**
     */
    async searchActiveUsers(requestParameters: SearchActiveUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<BettingUser>> {
        const response = await this.searchActiveUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async toggleTournamentSubscriptionRaw(requestParameters: ToggleTournamentSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TournamentSubscription>>> {
        if (requestParameters['tournamentId'] == null) {
            throw new runtime.RequiredError(
                'tournamentId',
                'Required parameter "tournamentId" was null or undefined when calling toggleTournamentSubscription().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/subscribed-tournaments/{tournamentId}/toggle`.replace(`{${"tournamentId"}}`, encodeURIComponent(String(requestParameters['tournamentId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TournamentSubscriptionFromJSON));
    }

    /**
     */
    async toggleTournamentSubscription(requestParameters: ToggleTournamentSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TournamentSubscription>> {
        const response = await this.toggleTournamentSubscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateSubscribedTournamentsRaw(requestParameters: UpdateSubscribedTournamentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TournamentSubscription>>> {
        if (requestParameters['subscribed'] == null) {
            throw new runtime.RequiredError(
                'subscribed',
                'Required parameter "subscribed" was null or undefined when calling updateSubscribedTournaments().'
            );
        }

        if (requestParameters['tournamentId'] == null) {
            throw new runtime.RequiredError(
                'tournamentId',
                'Required parameter "tournamentId" was null or undefined when calling updateSubscribedTournaments().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/subscribed-tournaments/{tournamentId}/{subscribed}`.replace(`{${"subscribed"}}`, encodeURIComponent(String(requestParameters['subscribed']))).replace(`{${"tournamentId"}}`, encodeURIComponent(String(requestParameters['tournamentId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TournamentSubscriptionFromJSON));
    }

    /**
     */
    async updateSubscribedTournaments(requestParameters: UpdateSubscribedTournamentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TournamentSubscription>> {
        const response = await this.updateSubscribedTournamentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateUserBettingPublicRaw(requestParameters: UpdateUserBettingPublicRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BettingUser>> {
        const queryParameters: any = {};

        if (requestParameters['value'] != null) {
            queryParameters['value'] = requestParameters['value'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/betting-public`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BettingUserFromJSON(jsonValue));
    }

    /**
     */
    async updateUserBettingPublic(requestParameters: UpdateUserBettingPublicRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BettingUser> {
        const response = await this.updateUserBettingPublicRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
