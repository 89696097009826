import { AnyAction, isType } from 'typescript-fsa';
import { RssFeed } from '../../../services/betting';
import { portletSet } from './actions';

export interface PortletState {
    portlets: { [name: string]: RssFeed };
}

const initialPortletState: PortletState = {
    portlets: {}
};

export function portletReducer(state: PortletState = initialPortletState, action: AnyAction): PortletState {
    if (isType(action, portletSet)) {
        const p = { ...state.portlets, [action.payload.name]: action.payload.feed };
        return { ...state, portlets: p };
    }
    return state;
}
