/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.25
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientStaticTranslation
 */
export interface ClientStaticTranslation {
    /**
     * 
     * @type {string}
     * @memberof ClientStaticTranslation
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ClientStaticTranslation
     */
    key: string;
    /**
     * 
     * @type {string}
     * @memberof ClientStaticTranslation
     */
    value?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientStaticTranslation
     */
    clientId: string;
    /**
     * 
     * @type {string}
     * @memberof ClientStaticTranslation
     */
    clientName: string;
    /**
     * 
     * @type {string}
     * @memberof ClientStaticTranslation
     */
    languageId: string;
    /**
     * 
     * @type {string}
     * @memberof ClientStaticTranslation
     */
    languageName: string;
    /**
     * 
     * @type {Date}
     * @memberof ClientStaticTranslation
     */
    lastUpdate?: Date;
}

/**
 * Check if a given object implements the ClientStaticTranslation interface.
 */
export function instanceOfClientStaticTranslation(value: object): value is ClientStaticTranslation {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('key' in value) || value['key'] === undefined) return false;
    if (!('clientId' in value) || value['clientId'] === undefined) return false;
    if (!('clientName' in value) || value['clientName'] === undefined) return false;
    if (!('languageId' in value) || value['languageId'] === undefined) return false;
    if (!('languageName' in value) || value['languageName'] === undefined) return false;
    return true;
}

export function ClientStaticTranslationFromJSON(json: any): ClientStaticTranslation {
    return ClientStaticTranslationFromJSONTyped(json, false);
}

export function ClientStaticTranslationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientStaticTranslation {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'key': json['key'],
        'value': json['value'] == null ? undefined : json['value'],
        'clientId': json['clientId'],
        'clientName': json['clientName'],
        'languageId': json['languageId'],
        'languageName': json['languageName'],
        'lastUpdate': json['lastUpdate'] == null ? undefined : (new Date(json['lastUpdate'])),
    };
}

export function ClientStaticTranslationToJSON(value?: ClientStaticTranslation | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'key': value['key'],
        'value': value['value'],
        'clientId': value['clientId'],
        'clientName': value['clientName'],
        'languageId': value['languageId'],
        'languageName': value['languageName'],
        'lastUpdate': value['lastUpdate'] == null ? undefined : ((value['lastUpdate']).toISOString()),
    };
}

