import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { logout, systemShowLogoutConfirmation } from '../actions';
import { systemShowLogoutConfirmationSelector } from '../selectors';

export const LogoutConfirmation: FC<{}> = () => {
    const showLogoutConfirmation = useSelector(systemShowLogoutConfirmationSelector);
    const dispatch = useDispatch();

    const onCancel = () => {
        dispatch(systemShowLogoutConfirmation(false));
    };
    const onLogout = () => {
        dispatch(logout());
        dispatch(systemShowLogoutConfirmation(false));
    };
    return (
        <>
            <Modal isOpen={showLogoutConfirmation} toggle={onCancel}>
                <ModalHeader>Abmeldung</ModalHeader>
                <ModalBody>
                    <p>Sie Sie sicher, dass Sie sich abmelden wollen?</p>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={onCancel} className="m-2">
                        Abbrechen
                    </Button>
                    <Button color="danger" onClick={onLogout} className="m-2">
                        Abmelden
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};
