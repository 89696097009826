import { getTournamentApi2, getSystemConfig } from '../../System/systemConfig';
import { fetchWrapperFunc } from '../../../utils';
import { tournamentArchiveSetTournaments, tournamentArchiveSetCategories, TOURNAMENT_ARCHIV_LOAD } from './actions';
import { takeLatest, all } from 'redux-saga/effects';

function* load() {
    const api = getTournamentApi2();
    yield* fetchWrapperFunc(
        () => api.getTournamentArchive({ clientId: getSystemConfig().clientId }),
        [(r) => tournamentArchiveSetTournaments(r)],
    );
    yield* fetchWrapperFunc(
        () => api.getTournamentCategories({ clientId: getSystemConfig().clientId }),
        [(r) => tournamentArchiveSetCategories(r)],
    );
}

function* watchForTournamentArchivActions() {
    yield takeLatest(TOURNAMENT_ARCHIV_LOAD, load);
}

export function* tournamentArchiveSagas() {
    yield all([watchForTournamentArchivActions()]);
}
