import { all, put, select, takeLatest } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { fetchWrapperFuncNoLoading } from '../../utils';
import { myGroupHighscoresRefreshUnreadTeamChatMessages } from '../BettingApp/MyGroupHighscores/actions';
import { getTeamChatApi, getUserGroupApi2 } from '../System/systemConfig';
import {
    teamChatAddMessages,
    teamChatInitialLoad,
    TeamChatPostRequest,
    teamChatSetCount,
    teamChatSetMember,
    teamChatSetAdmin,
    teamChatSetMessages,
    teamChatSetPage,
    teamChatSetVisible,
    TEAM_CHAT_CHECK_FOR_UPDATES,
    TEAM_CHAT_INITIAL_LOAD,
    TEAM_CHAT_LOAD_NEXT,
    TEAM_CHAT_POST_MESSAGE,
    TEAM_CHAT_PUBLISH,
    TEAM_CHAT_UNPUBLISH,
    teamChatSet
} from './actions';
import {
    teamChatCountSelector,
    teamChatPageSelector,
    teamChatPageSizeSelector,
    teamChatVisibleSelector
} from './selectors';

function* load(action: Action<string>) {
    const api = getTeamChatApi();
    const userGroupApi = getUserGroupApi2();

    const ugId = action.payload;

    const pageSize: number = yield select(teamChatPageSizeSelector);

    const teamChat = yield* fetchWrapperFuncNoLoading(() => api.getTeamChat(ugId), [r => teamChatSet(r)]);

    const isMember = yield* fetchWrapperFuncNoLoading(() => userGroupApi.isMember({ugId: Number(ugId)}), [
        r => teamChatSetMember(r)
    ]);
    yield* fetchWrapperFuncNoLoading(() => userGroupApi.isAdmin({ugId: Number(ugId)}), [r => teamChatSetAdmin(r)]);
    const loadTeamChat = teamChat?.published || isMember;

    if (loadTeamChat) {
        yield put(teamChatSetVisible(true));
        const itemCount = yield* fetchWrapperFuncNoLoading(() => api.countMessages(ugId), [
            r => teamChatSetCount(r.value)
        ]);

        if (itemCount) {
            const initialPage = 0; // Math.floor(itemCount / pageSize);
            yield put(teamChatSetPage(initialPage));
            yield* fetchWrapperFuncNoLoading(() => api.getMessages(ugId, initialPage, pageSize), [
                r => teamChatSetMessages(r)
            ]);
            yield put(myGroupHighscoresRefreshUnreadTeamChatMessages(ugId));
        }
    } else {
        yield put(teamChatSetMessages([]));
        yield put(teamChatSetVisible(false));
    }
}

function* post(action: Action<TeamChatPostRequest>) {
    const api = getTeamChatApi();
    const ugId = action.payload.ugId;
    const msg = action.payload.message;

    yield* fetchWrapperFuncNoLoading(() => api.addMessage(ugId, msg));
    yield put(teamChatInitialLoad(ugId));
}

function* loadNext(action: Action<string>) {
    const api = getTeamChatApi();
    const ugId = action.payload;

    const page: number = yield select(teamChatPageSelector);
    const pageSize: number = yield select(teamChatPageSizeSelector);

    const newPage = page + 1;
    yield put(teamChatSetPage(newPage));
    yield* fetchWrapperFuncNoLoading(() => api.getMessages(ugId, newPage, pageSize), [r => teamChatAddMessages(r)]);

    yield put(myGroupHighscoresRefreshUnreadTeamChatMessages(ugId));
}

function* checkForUpdates(action: Action<string>) {
    const api = getTeamChatApi();
    const ugId = action.payload;

    const visible: boolean = yield select(teamChatVisibleSelector);

    if (visible && ugId) {
        const count: number = yield select(teamChatCountSelector);
        const itemCount = yield* fetchWrapperFuncNoLoading(() => api.countMessages(ugId));
        console.info(`count = ${count}, itemCount = ${itemCount?.value}`);
        if (itemCount && itemCount.value > count) {
            yield put(teamChatInitialLoad(ugId));
        }
    }
}

function* publish(action: Action<string>) {
    const api = getTeamChatApi();
    const ugId = action.payload;

    yield* fetchWrapperFuncNoLoading(() => api.publish(ugId));
    yield put(teamChatInitialLoad(ugId));
}

function* unpublish(action: Action<string>) {
    const api = getTeamChatApi();
    const ugId = action.payload;

    yield* fetchWrapperFuncNoLoading(() => api.unpublish(ugId));
    yield put(teamChatInitialLoad(ugId));
}

function* watch() {
    yield takeLatest(TEAM_CHAT_INITIAL_LOAD, load);
    yield takeLatest(TEAM_CHAT_LOAD_NEXT, loadNext);
    yield takeLatest(TEAM_CHAT_POST_MESSAGE, post);
    yield takeLatest(TEAM_CHAT_CHECK_FOR_UPDATES, checkForUpdates);
    yield takeLatest(TEAM_CHAT_PUBLISH, publish);
    yield takeLatest(TEAM_CHAT_UNPUBLISH, unpublish);
}

export function* teamChatSagas() {
    yield all([watch()]);
}
