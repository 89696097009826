import actionCreatorFactory from 'typescript-fsa';
import { UserNotificationStatus } from '../../../../services/betting';

const actionCreator = actionCreatorFactory();

export const NOTIFICATION_STATUS_LOAD = 'NOTIFICATION_STATUS_LOAD';
export const notificationStatusLoad = actionCreator(NOTIFICATION_STATUS_LOAD);

export const NOTIFICATION_STATUS_SET = 'NOTIFICATION_STATUS_SET';
export const notificationStatusSet = actionCreator<UserNotificationStatus>(NOTIFICATION_STATUS_SET);

export const NOTIFICATION_STATUS_CLEAR = 'NOTIFICATION_STATUS_CLEAR';
export const notificationStatusClear = actionCreator(NOTIFICATION_STATUS_CLEAR);