import * as R from 'ramda';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withFooter } from '../../../reactutils/withFooter';
import { withMenu } from '../../../reactutils/withMenu';
import { Section, SectionHeading } from '../../common/Section';
import { MenuBetting } from '../../MenuBetting';
import { actionTokenLoad } from './actions';
import { CancelAllNotifications } from './CancelAllNofications';
import { CancelNewsletter } from './CancelNewsletter';
import { CancelTournamentSubscriptionActionTokenHandler } from './CancelTournamentSubscriptionActionTokenHandler';
import { DeleteUserActionTokenHandler } from './DeleteUserActionTokenHandler';
import { EmailConfirmationActionTokenHandler } from './EmailConfirmationActionTokenHandler';
import { ExternalInvitationActionTokenHandler } from './ExternalInvitationActionTokenHandler';
import { PasswordLostActionTokenHandler } from './PasswordLostActionTokenHandler';
import { actionTokenCheckResponseSelector } from './selectors';

const ActionToken: FC<{}> = () => {
    const checkResponse = useSelector(actionTokenCheckResponseSelector);

    const query = window.location.search.startsWith('?') ? window.location.search.substring(1) : window.location.search;

    const params = new URLSearchParams(query);

    const atId = params.get('atId') || '';
    const uId = params.get('uId') || '';
    const secId = params.get('secId') || '';

    const dispatch = useDispatch();

    useEffect(() => {
        if (atId && uId && secId) {
            dispatch(actionTokenLoad({ atId, uId, secId }));
        }
    }, [dispatch, atId, uId, secId]);

    if (!checkResponse) {
        return (
            <Section>
                <SectionHeading>Überprüfe Link ...</SectionHeading>
            </Section>
        );
    } else {
        if (checkResponse.status === 'invalid') {
            return (
                <Section>
                    <SectionHeading>Überprüfe Link ...</SectionHeading>
                    <p>Der Link ist nicht mehr gültig.</p>
                    <p>Bitte melden Sie sich an oder registrieren Sie sich, um die gewünschte Aktion durchzuführen.</p>
                </Section>
            );
        } else {
            switch (checkResponse.type) {
                case 'cancelAllNotifications':
                    return <CancelAllNotifications atId={atId} uId={uId} secId={secId} />;
                case 'external_invitation':
                    return <ExternalInvitationActionTokenHandler atId={atId} uId={uId} secId={secId} />;
                case 'password_lost':
                    return <PasswordLostActionTokenHandler atId={atId} uId={uId} secId={secId} />;
                case 'cancelNewsletter':
                    return <CancelNewsletter atId={atId} uId={uId} secId={secId} />;
                case 'cancelTournamentSubscriptions':
                    return <CancelTournamentSubscriptionActionTokenHandler atId={atId} uId={uId} secId={secId} />;
                case 'emailConfirmation':
                    return <EmailConfirmationActionTokenHandler atId={atId} uId={uId} secId={secId} />;
                case 'delete_user':
                    return <DeleteUserActionTokenHandler atId={atId} uId={uId} secId={secId} />;
            }
            return (
                <>
                    <Section>
                        <SectionHeading>Fehler! Unbekannter Aktions-Typ '{checkResponse.type}' ...</SectionHeading>
                    </Section>
                </>
            );
        }
    }
};

export interface ActionTokenParams {
    atId: string;
    uId: string;
    secId: string;
    tag?: string;
}

export const ActionTokenScreen = R.compose(withMenu(<MenuBetting />), withFooter())(ActionToken);
