import React, { FC } from 'react';
import { BestBetsIndicator } from '../../../BettingApp/BestBets';

interface Props {
    gameId: string;
    points: number | undefined;
    showBestBets?: boolean;
}

export const BetPoints: FC<Props> = (props) => {
    if (props.showBestBets) {
        return (
            <BestBetsIndicator gameId={props.gameId}>
                <span className="btn-r1--points ps-sm-1 pe-sm-1">{props.points ?? 0}</span>
            </BestBetsIndicator>
        );
    } else {
        return <span className="btn-r1--points ps-sm-1 pe-sm-1">{props.points ?? 0}</span>;
    }
};
