import React, { FC, ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { isRadioEins } from '../components/System/systemConfig';

const defaultSelector = (state: any) => undefined;

interface TitleProps {
    title: string;
    subTitle: string;
    leftComponent?: ReactElement;
    rightComponent?: ReactElement;
}
export const FuntipTitle: FC<TitleProps> = (props) => {
    const titleText = props.title + ' ' + props.subTitle;
    const appTitle = isRadioEins() ? 'RadioEins Tippspiel' : 'Funtip Tippspiel';

    return (
        <>
            <Helmet>
                <title>{appTitle + ' - ' + titleText}</title>
            </Helmet>
            <div className="d-flex justify-content-between align-items-baseline flex-wrap">
                <h2 className="mt-3">
                    {props.title}
                    <span className="text-warning ms-2">{props.subTitle}</span>
                </h2>
                <div>{props.leftComponent}</div>
                <div>{props.rightComponent}</div>
            </div>
        </>
    );
};

export const withTitle =
    <P extends object>(
        title: string | JSX.Element,
        subTitleSelector: (state: any) => string | JSX.Element | undefined = defaultSelector
    ) =>
    (Comp: React.ComponentType<P>): React.ComponentType<P> =>
    (props: P) => {
        const subTitle = useSelector(subTitleSelector);
        const titleText = title + ' ' + subTitle;
        const appTitle = isRadioEins() ? 'RadioEins Tippspiel' : 'Funtip Tippspiel';

        const isTextTitle = () => typeof title === 'string' && typeof subTitle === 'string';

        return (
            <>
                <h2 className="mt-3">
                <Helmet>
                    <title>{appTitle + (isTextTitle() ? ' - ' + titleText : '')}</title>
                </Helmet>
                    {title}
                    {subTitle && typeof subTitle === 'string' && subTitle !== '' && (
                            <span className="text-warning ms-2">{subTitle}</span>
                    )}
                    {subTitle && typeof subTitle === 'object' && <>{subTitle}</>}
                </h2>
                <Comp {...props} />
            </>
        );
    };

export const withComponentTitle =
    <P extends object>(title: JSX.Element) =>
    (Comp: React.ComponentType<P>): React.ComponentType<P> =>
    (props: P) => {
        const appTitle = isRadioEins() ? 'RadioEins Tippspiel' : 'Funtip Tippspiel';

        return (
            <>
                <Helmet>
                    <title>{appTitle}</title>
                </Helmet>
                {title}
                <Comp {...props} />
            </>
        );
    };
