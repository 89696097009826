/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PlayListEntry
 */
export interface PlayListEntry {
    /**
     * 
     * @type {number}
     * @memberof PlayListEntry
     */
    id: number;
    /**
     * 
     * @type {Date}
     * @memberof PlayListEntry
     */
    utcDate: Date;
    /**
     * 
     * @type {string}
     * @memberof PlayListEntry
     */
    remark: string;
    /**
     * 
     * @type {string}
     * @memberof PlayListEntry
     */
    station: string;
    /**
     * 
     * @type {string}
     * @memberof PlayListEntry
     */
    songId: string;
    /**
     * 
     * @type {string}
     * @memberof PlayListEntry
     */
    songName: string;
    /**
     * 
     * @type {string}
     * @memberof PlayListEntry
     */
    songArtist: string;
    /**
     * 
     * @type {string}
     * @memberof PlayListEntry
     */
    songUrl: string;
    /**
     * 
     * @type {string}
     * @memberof PlayListEntry
     */
    albumId: string;
    /**
     * 
     * @type {number}
     * @memberof PlayListEntry
     */
    albumImageId: number;
    /**
     * 
     * @type {string}
     * @memberof PlayListEntry
     */
    albumName: string;
    /**
     * 
     * @type {string}
     * @memberof PlayListEntry
     */
    broadcastId: string;
    /**
     * 
     * @type {string}
     * @memberof PlayListEntry
     */
    broadcastTitle: string;
    /**
     * 
     * @type {string}
     * @memberof PlayListEntry
     */
    broadcastAnchorman: string;
}

/**
 * Check if a given object implements the PlayListEntry interface.
 */
export function instanceOfPlayListEntry(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "utcDate" in value;
    isInstance = isInstance && "remark" in value;
    isInstance = isInstance && "station" in value;
    isInstance = isInstance && "songId" in value;
    isInstance = isInstance && "songName" in value;
    isInstance = isInstance && "songArtist" in value;
    isInstance = isInstance && "songUrl" in value;
    isInstance = isInstance && "albumId" in value;
    isInstance = isInstance && "albumImageId" in value;
    isInstance = isInstance && "albumName" in value;
    isInstance = isInstance && "broadcastId" in value;
    isInstance = isInstance && "broadcastTitle" in value;
    isInstance = isInstance && "broadcastAnchorman" in value;

    return isInstance;
}

export function PlayListEntryFromJSON(json: any): PlayListEntry {
    return PlayListEntryFromJSONTyped(json, false);
}

export function PlayListEntryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlayListEntry {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'utcDate': (new Date(json['utcDate'])),
        'remark': json['remark'],
        'station': json['station'],
        'songId': json['songId'],
        'songName': json['songName'],
        'songArtist': json['songArtist'],
        'songUrl': json['songUrl'],
        'albumId': json['albumId'],
        'albumImageId': json['albumImageId'],
        'albumName': json['albumName'],
        'broadcastId': json['broadcastId'],
        'broadcastTitle': json['broadcastTitle'],
        'broadcastAnchorman': json['broadcastAnchorman'],
    };
}

export function PlayListEntryToJSON(value?: PlayListEntry | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'utcDate': (value.utcDate.toISOString()),
        'remark': value.remark,
        'station': value.station,
        'songId': value.songId,
        'songName': value.songName,
        'songArtist': value.songArtist,
        'songUrl': value.songUrl,
        'albumId': value.albumId,
        'albumImageId': value.albumImageId,
        'albumName': value.albumName,
        'broadcastId': value.broadcastId,
        'broadcastTitle': value.broadcastTitle,
        'broadcastAnchorman': value.broadcastAnchorman,
    };
}

