import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { ButtonWithMultipleConfirmations } from '../../../components/common/ButtonWithMultipleConfirmations';
import { Section, SectionHeading } from '../../../components/common/Section';
import { userGroupDelete } from '../actions';

interface Props {
    ugId: string;
}

export const UserGroupDeleteSection: FC<Props> = props => {
    const dispatch = useDispatch();

    const deleteUserGroup = (ugId: string) => {
        dispatch(userGroupDelete(ugId));
    };

    return (
        <Section>
            <SectionHeading>Tippteam löschen</SectionHeading>
            <ButtonWithMultipleConfirmations
                onOK={() => deleteUserGroup(props.ugId)}
                color="danger"
                messages={[
                    'Sind Sie sicher, dass Sie das Tippteam vollständig löschen wollen?',
                    'Die Löschung des Tippteams kann nicht rückgängig gemacht werden. Bitte ' +
                        'bestätigen Sie erneut, dass Sie alle Daten unwiderruflich löschen wollen.'
                ]}
                title="Gesamtes Tippteam unwiderruflich löschen!"
            >
                Gesamtes Tippteam unwiderruflich löschen!
            </ButtonWithMultipleConfirmations>
        </Section>
    );
};
