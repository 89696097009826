import { all, put, takeLatest } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { RestResult } from '../../../services/account';
import { fetchWrapperFunc } from '../../../utils';
import { getActionToken2Api, getProfile2Api, getSystemConfig } from '../systemConfig';
import { ActionTokenParams } from './';
import {
    ACTION_TOKEN_CHECK_PWD,
    ACTION_TOKEN_EXECUTE,
    ACTION_TOKEN_LOAD,
    actionTokenCheckPasswordResult,
    actionTokenExecute,
    actionTokenSetExecuted,
    actionTokenSetParams,
    actionTokenSetType,
} from './actions';

function* load(action: Action<ActionTokenParams>) {
    const secId = action.payload.secId;
    const atId = action.payload.atId;
    const uId = action.payload.uId;

    yield put(actionTokenSetParams(action.payload));

    yield* fetchWrapperFunc(
        () => getActionToken2Api().checkActionToken({secId, uId, atId}),
        [(r) => actionTokenSetType(r)],
    );
}

function* execute(action: Action<ActionTokenParams>) {
    const secId = action.payload.secId;
    const atId = action.payload.atId;
    const uId = action.payload.uId;
    const tag = action.payload.tag;

    yield* fetchWrapperFunc(() => getActionToken2Api().executeActionToken({secId, uId, atId, tag}));
    yield put(actionTokenSetExecuted(true));
}

const isValidPwd = (r: RestResult) => r.statusCode === 200;

function* checkPwd(action: Action<ActionTokenParams>) {
    const secId = action.payload.secId;
    const atId = action.payload.atId;
    const uId = action.payload.uId;
    const pwd = action.payload.tag;

    if (!pwd) {
        yield actionTokenCheckPasswordResult(false);
        return;
    }
    const r = yield* fetchWrapperFunc(
        () => getProfile2Api().isValidPassword({tenant: getSystemConfig().clientName, pwd}),
        [(r) => actionTokenCheckPasswordResult(isValidPwd(r))],
    );

    if (r && isValidPwd(r)) {
        // password is valid
        yield put(actionTokenExecute({ atId, secId, uId, tag: pwd }));
        yield put(actionTokenSetExecuted(true));
    } else {
        // password is invalid
    }
}
function* watchActionToken() {
    yield takeLatest(ACTION_TOKEN_LOAD, load);
    yield takeLatest(ACTION_TOKEN_EXECUTE, execute);
    yield takeLatest(ACTION_TOKEN_CHECK_PWD, checkPwd);
}

export function* actionTokenSagas() {
    yield all([watchActionToken()]);
}
