/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.25
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ActionTokenType = {
    Registration: 'registration',
    ChangeEmail: 'change_email',
    CancelAllNotifications: 'cancelAllNotifications',
    ExternalInvitation: 'external_invitation',
    DeleteUser: 'delete_user',
    PasswordLost: 'password_lost',
    CancelNewsletter: 'cancelNewsletter',
    CancelTournamentSubscriptions: 'cancelTournamentSubscriptions',
    EmailConfirmation: 'emailConfirmation',
    CancelInfoMails: 'cancel_info_mails'
} as const;
export type ActionTokenType = typeof ActionTokenType[keyof typeof ActionTokenType];


export function instanceOfActionTokenType(value: any): boolean {
    for (const key in ActionTokenType) {
        if (Object.prototype.hasOwnProperty.call(ActionTokenType, key)) {
            if ((ActionTokenType as Record<string, ActionTokenType>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ActionTokenTypeFromJSON(json: any): ActionTokenType {
    return ActionTokenTypeFromJSONTyped(json, false);
}

export function ActionTokenTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionTokenType {
    return json as ActionTokenType;
}

export function ActionTokenTypeToJSON(value?: ActionTokenType | null): any {
    return value as any;
}

