import actionCreatorFactory from 'typescript-fsa';
import { BettingUser } from '../../../services/betting';

const actionCreator = actionCreatorFactory();

export const USER_SEARCH_LOAD = 'USER_SEARCH_LOAD';
export const userSearchLoad = actionCreator(USER_SEARCH_LOAD);

export const USER_SEARCH_SET_SEARCH = 'USER_SEARCH_SET_SEARCH';
export const userSearchSet = actionCreator<string>(USER_SEARCH_SET_SEARCH);

export const USER_SEARCH_SET_RESULT_COUNT = 'USER_SEARCH_SET_RESULT_COUNT';
export const userSearchSetResultCount= actionCreator<number>(USER_SEARCH_SET_RESULT_COUNT);

export const USER_SEARCH_SET_ENTITIES = 'USER_SEARCH_SET_ENTITIES';
export const userSearchSetEntities = actionCreator<BettingUser[]>(USER_SEARCH_SET_ENTITIES);