/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.25
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewsletterBlock
 */
export interface NewsletterBlock {
    /**
     * 
     * @type {string}
     * @memberof NewsletterBlock
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterBlock
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterBlock
     */
    text?: string;
    /**
     * 
     * @type {number}
     * @memberof NewsletterBlock
     */
    order: number;
    /**
     * 
     * @type {string}
     * @memberof NewsletterBlock
     */
    link?: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterBlock
     */
    newsletterId: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterBlock
     */
    newsletterLayoutBlockId: string;
    /**
     * 
     * @type {string}
     * @memberof NewsletterBlock
     */
    imageId?: string;
}

/**
 * Check if a given object implements the NewsletterBlock interface.
 */
export function instanceOfNewsletterBlock(value: object): value is NewsletterBlock {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('order' in value) || value['order'] === undefined) return false;
    if (!('newsletterId' in value) || value['newsletterId'] === undefined) return false;
    if (!('newsletterLayoutBlockId' in value) || value['newsletterLayoutBlockId'] === undefined) return false;
    return true;
}

export function NewsletterBlockFromJSON(json: any): NewsletterBlock {
    return NewsletterBlockFromJSONTyped(json, false);
}

export function NewsletterBlockFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewsletterBlock {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'] == null ? undefined : json['title'],
        'text': json['text'] == null ? undefined : json['text'],
        'order': json['order'],
        'link': json['link'] == null ? undefined : json['link'],
        'newsletterId': json['newsletterId'],
        'newsletterLayoutBlockId': json['newsletterLayoutBlockId'],
        'imageId': json['imageId'] == null ? undefined : json['imageId'],
    };
}

export function NewsletterBlockToJSON(value?: NewsletterBlock | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'title': value['title'],
        'text': value['text'],
        'order': value['order'],
        'link': value['link'],
        'newsletterId': value['newsletterId'],
        'newsletterLayoutBlockId': value['newsletterLayoutBlockId'],
        'imageId': value['imageId'],
    };
}

