import { AnyAction, isType } from 'typescript-fsa';
import { UserProfile } from '../../../services/account';
import { userAdminSet, userAdminSetMergeBetsCount } from './actions';

export interface UserAdminState {
    profile?: UserProfile;
    mergeBetCount: string;
}

const initialUserAdminState: UserAdminState = { mergeBetCount: '' };

export function userAdminReducer(state: UserAdminState = initialUserAdminState, action: AnyAction): UserAdminState {
    if (isType(action, userAdminSet)) {
        return { ...state, profile: action.payload };
    } else if (isType(action, userAdminSetMergeBetsCount)) {
        return { ...state, mergeBetCount: action.payload };
    }
    return state;
}
