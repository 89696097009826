import { useMutation, useQuery } from '@tanstack/react-query';
import * as R from 'ramda';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { withBorder } from '../../../reactutils/withBorder';
import { withLogin } from '../../../reactutils/withLogin';
import { withNavLocation } from '../../../reactutils/withNavLocation';
import { withTitle } from '../../../reactutils/withTitle';
import { SectionHeading, SysAdminSection } from '../../common/Section';
import { userIdSelector, userNameSelector } from '../../System/selectors';
import { getProfile2Api, getPushNotification2Api, getSystemConfig } from '../../System/systemConfig';
import { EmailConfirmationTeaser } from '../EmailConfirmationTeaser';
import { InvalidEmailTeaser } from '../InvalidEmailTeaser';
import { NewsletterTeaser } from '../NewsletterTeaser';
import { ProfileSubMenu } from '../ProfileSubMenu';
import { ChangeEmail } from './ChangeEmail';
import ChangePassword from './ChangePassword';
import { ChangeUserName } from './ChangeUserName';
import { DeleteProfile } from './DeleteProfile';
import { EmailNotificationSettings } from './EmailNotificationSettings';

const AccountUserSettingsComponent: React.FC<{}> = () => {
    const userId = useSelector(userIdSelector);

    const [testTitle, setTestTitle] = React.useState('Bayern - Dortmund 1:1');
    const [testBody, setTestBody] = React.useState('1. Bundesliga 2022/23, 17. Spieltag\nSpielbeginn: 15:30 Uhr');
    const [testIcon, setTestIcon] = React.useState('/images/soccer4.png');

    interface TestMessage {
        title: string;
        body: string;
        icon: string;
    }

    const profileQuery = useQuery({
        queryKey: ['profileSettings', userId],
        queryFn: () => getProfile2Api().getUserSettings({ tenant: getSystemConfig().clientName }),
        enabled: !!userId,
    });

    const api = getPushNotification2Api();

    const testMessageMutation = useMutation({
        mutationFn: (v: TestMessage) => api.test({ title: v.title, body: v.body, icon: v.icon }),
    });

    const sendTestMessage = () => {
        testMessageMutation.mutate({ title: testTitle, body: testBody, icon: testIcon });
    };

    return (
        <>
            <ProfileSubMenu subMenu="settings" />
            <EmailConfirmationTeaser />
            <NewsletterTeaser />
            <InvalidEmailTeaser />
            <SysAdminSection>
                <SectionHeading>Push-Benachrichtigungen</SectionHeading>
                <Form className="">
                    <FormGroup>
                        <Label className="" size="sm">
                            Titel
                        </Label>
                        <Input
                            bsSize="sm"
                            type="text"
                            placeholder="Titel"
                            value={testTitle}
                            onChange={(e) => {
                                setTestTitle(e.currentTarget.value);
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label className="" size="sm">
                            Body
                        </Label>
                        <Input
                            bsSize="sm"
                            type="textarea"
                            placeholder="Body"
                            value={testBody}
                            onChange={(e) => {
                                setTestBody(e.currentTarget.value);
                            }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label className="" size="sm">
                            Icon
                        </Label>
                        <Input
                            bsSize="sm"
                            type="text"
                            placeholder="Icon"
                            value={testIcon}
                            onChange={(e) => {
                                setTestIcon(e.currentTarget.value);
                            }}
                        />
                    </FormGroup>
                    <Button size="sm" onClick={sendTestMessage}>
                        Test
                    </Button>
                </Form>
            </SysAdminSection>

            {profileQuery.data?.userId && <EmailNotificationSettings userId={String(profileQuery.data?.userId)} />}

            <ChangePassword />
            <ChangeEmail />
            <ChangeUserName />
            <DeleteProfile />
        </>
    );
};

export const AccountUserSettingsScreen = R.compose(
    withBorder(),
    withTitle('Einstellungen', userNameSelector),
    withNavLocation('profile'),
    withLogin(),
)(AccountUserSettingsComponent);
