import { useMutation, useQuery } from '@tanstack/react-query';
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Section, SectionHeading } from '../../../../common/Section';
import { systemSetPopupMessage } from '../../../../System/actions';
import { getNewsletterAdminApi, getSystemConfig } from '../../../../System/systemConfig';

interface Props {
    nlId?: string;
    afterStore: () => void;
}

export const NewsletterMainEdit: FC<Props> = (props) => {
    const api = getNewsletterAdminApi();
    const dispatch = useDispatch();

    const [title, setTitle] = React.useState('');
    const [layoutId, setLayoutId] = React.useState('');
    const [receiverFilterId, setReceiverFilterId] = React.useState('');

    const newsletterQuery = useQuery({
        queryKey: ['newsletter', props.nlId],
        queryFn: () => api.getNewsletter({nlId: props.nlId!}),
        enabled: !!props.nlId,
    });

    const newsletterReceiverFilterQuery = useQuery({
        queryKey: ['newsletterReceiverFilter', getSystemConfig().clientId],
        queryFn: () => api.getReceiverFilters({clientId: String(getSystemConfig().clientId)}),
    });

    const newsletterLayoutQuery = useQuery({
        queryKey: ['newsletterLayouts', getSystemConfig().clientId],
        queryFn: () => api.getLayouts({clientId: String(getSystemConfig().clientId)}),
    });

    useEffect(() => {
        if (newsletterQuery.data) {
            setTitle(newsletterQuery.data.title);
            setLayoutId(newsletterQuery.data.layoutId);
            setReceiverFilterId(newsletterQuery.data.receiverFilterId);
        }
    }, [newsletterQuery.data]);

    const updateNewsletterMutation = useMutation({
        mutationFn: () => api.updateNewsletter({nlId: newsletterQuery.data!.id, receiverFilterId, title}),
    });

    const createNewsletterMutation = useMutation({
        mutationFn: () => api.createNewsletter({layoutId, receiverFilterId, title}),
    });

    const onSave = () => {
        if (props.nlId) {
            // update an existing newsletter
            updateNewsletterMutation.mutate(undefined, {
                onSuccess: () => {
                    newsletterQuery.refetch();
                    dispatch(systemSetPopupMessage('Newsletter gespeichert'));
                    props.afterStore();
                },
                onError: (error) => {
                    dispatch(systemSetPopupMessage('Fehler beim Speichern des Newsletters: ' + error));
                },
            });
        } else {
            // create a new newsletter
            createNewsletterMutation.mutate(undefined, {
                onSuccess: () => {
                    newsletterQuery.refetch();
                    dispatch(systemSetPopupMessage('Newsletter erstellt'));
                    props.afterStore();
                },
                onError: (error) => {
                    dispatch(systemSetPopupMessage('Fehler beim Erstellen des Newsletters: ' + error));
                },
            });
        }
    };

    const isValid = (): boolean => {
        return (
            !!title &&
            title.length > 3 &&
            !!layoutId &&
            layoutId.length > 0 &&
            !!receiverFilterId &&
            receiverFilterId.length > 0
        );
    };

    return (
        <Section>
            <SectionHeading>Newsletter '{title}'</SectionHeading>
            <FormGroup>
                <Label>Thema</Label>
                <Input type="text" value={title} onChange={(e) => setTitle(e.currentTarget.value)} />
            </FormGroup>
            <FormGroup>
                <Label>Layoutvorlage</Label>
                <select
                    disabled={props.nlId !== undefined}
                    className="form-control"
                    value={layoutId}
                    onChange={(e) => setLayoutId(e.currentTarget.value)}
                >
                    <option value="">Bitte wählen</option>
                    {newsletterLayoutQuery.data?.map((nl) => (
                        <option key={nl.id} value={nl.id}>
                            {nl.name}
                        </option>
                    ))}
                </select>
            </FormGroup>
            <FormGroup>
                <Label>Empfängerfilter</Label>
                <select
                    className="form-control"
                    value={receiverFilterId}
                    onChange={(e) => setReceiverFilterId(e.currentTarget.value)}
                >
                    <option value="">Bitte wählen</option>

                    {newsletterReceiverFilterQuery.data?.map((nlrf) => (
                        <option key={nlrf.id} value={nlrf.id}>
                            {nlrf.name}
                        </option>
                    ))}
                </select>
            </FormGroup>
            <Button color="success" onClick={onSave} disabled={!isValid()}>
                Speichern
            </Button>
        </Section>
    );
};

interface ModalProps {
    onClose: () => void;
}

export const NewsletterMainModalEdit: FC<ModalProps> = (props) => {
    return (
        <Modal isOpen={true} toggle={props.onClose} size="xl">
            <ModalHeader>Newsletter anlegen</ModalHeader>
            <ModalBody>
                <NewsletterMainEdit afterStore={props.onClose} />
            </ModalBody>
        </Modal>
    );
};
