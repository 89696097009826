import { push } from '@lagunovsky/redux-react-router';
import { all, put, takeLatest } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { UserProfileSettings } from '../../../services/account';
import { TournamentSubscription } from '../../../services/betting';
import { fetchWrapperFunc, fetchWrapperFuncDelayed, fetchWrapperFuncNoLoading } from '../../../utils';
import { logout, systemFinishFetching, systemStartFetching } from '../../System/actions';
import { getBettingUserApi2, getProfile2Api, getSystemConfig } from '../../System/systemConfig';
import {
    ACCOUNT_USER_SETTINGS_SAVE_SETTINGS,
    ACCOUNT_USER_SETTING_DELETE_PROFILE,
    ACCOUNT_USER_SETTING_DISMISS_INVALID_EMAIL_TEASER,
    ACCOUNT_USER_SETTING_DISMISS_NEWSLETTER_TEASER,
    ACCOUNT_USER_SETTING_LOAD_SUBSCRIBED_TOURNAMENTS,
    ACCOUNT_USER_SETTING_LOAD_USER_SETTINGS,
    ACCOUNT_USER_SETTING_SUBSCRIBE_NEWSLETTER,
    accountUserSettingLoadUserSettings,
    accountUserSettingSetDeleteAccountMessage,
    accountUserSettingSetProfileSettings,
    accountUserSettingSetSubscribedTournaments,
} from './actions';
import { DeleteProfileResult } from './DeleteProfile';

function* loadSubscribedTournamentsGenerator() {
    const bettingUserApi = getBettingUserApi2();
    yield* fetchWrapperFuncNoLoading(
        () => bettingUserApi.getSubscribedTournaments(),
        [(r: TournamentSubscription[]) => accountUserSettingSetSubscribedTournaments(r)],
    );
}

function* loadUserSettings() {
    yield* fetchWrapperFuncNoLoading(
        () => getProfile2Api().getUserSettings({ tenant: getSystemConfig().clientName }),
        [(r: UserProfileSettings) => accountUserSettingSetProfileSettings(r)],
    );
}

function* saveUserProfileSettings(
    action: Action<{ subscribedTournaments: TournamentSubscription[]; userProfileSettings: UserProfileSettings }>,
) {
    const bettingUserApi = getBettingUserApi2();
    const profileApi = getProfile2Api();

    yield put(systemStartFetching());
    try {
        yield* fetchWrapperFuncNoLoading(
            () =>
                profileApi.updateUserSettings({
                    tenant: getSystemConfig().clientName,
                    userProfileSettings: action.payload.userProfileSettings,
                }),
            [(r: UserProfileSettings) => accountUserSettingSetProfileSettings(r)],
        );

        for (let ts of action.payload.subscribedTournaments) {
            yield* fetchWrapperFuncNoLoading(
                () => bettingUserApi.updateSubscribedTournaments({tournamentId: ts.tournamentId, subscribed: ts.subscribed}),
                [(r: TournamentSubscription[]) => accountUserSettingSetSubscribedTournaments(r)],
            );
        }
    } finally {
        yield put(systemFinishFetching());
    }
}

function* deleteProfileGenerator() {
    const profileApi = getProfile2Api();
    yield* fetchWrapperFuncDelayed(
        () => profileApi.deleteAccount(),
        [
            {
                action: (r: string) =>
                    accountUserSettingSetDeleteAccountMessage({
                        msg: getDeleteAccountMessage(r),
                        state: getDeleteAccountState(r),
                    }),
            },
            {
                action: (r: string) => (r === 'userDeleted' ? logout() : undefined),
                delayInMillis: 10000,
            },
            { action: (r: string) => (r === 'userDeleted' ? push('/') : undefined) },
        ],
        undefined,
        'none',
    );
}

function getDeleteAccountMessage(status: string) {
    if (status === 'userNotFound' || status === 'userAlreadyDeleted') {
        return 'Der Nutzer konnte nicht gelöscht werden.';
    } else if (status === 'userDeleted') {
        return 'Das Profil wurde vollständig gelöscht. Sie werden in 10 Sekunden abgemeldet.';
    } else if (status === 'confirmationSent') {
        return (
            'Sie erhalten in den nächsten Minuten eine Email mit einem Link zur Bestätigung ' +
            'der Löschung ihres Profils. Zur endgültigen Löschung klicken Sie bitte den dort enthaltenen Link an.'
        );
    } else {
        return `interner Fehler (${status}))`;
    }
}

function getDeleteAccountState(status: string): DeleteProfileResult {
    if (status === 'userNotFound' || status === 'userAlreadyDeleted') {
        return 'error';
    } else if (status === 'userDeleted') {
        return 'ok';
    } else if (status === 'confirmationSent') {
        return 'ok';
    } else {
        return `error`;
    }
}

function* dismissNewsletterTeaser() {
    yield* fetchWrapperFunc(() => getProfile2Api().dismissNewsletterTeaser());
    yield put(accountUserSettingLoadUserSettings());
}

function* dismissInvalidEmailTeaser() {
    yield* fetchWrapperFunc(() => getProfile2Api().dismissInvalidEmailTeaser());
    yield put(accountUserSettingLoadUserSettings());
}

function* subscribeNewsletter() {
    yield* fetchWrapperFunc(() => getProfile2Api().subscribeToNewsletter());
    yield put(accountUserSettingLoadUserSettings());
}

function* watchAccountUserSettings() {
    yield takeLatest(ACCOUNT_USER_SETTING_LOAD_SUBSCRIBED_TOURNAMENTS, loadSubscribedTournamentsGenerator);
    yield takeLatest(ACCOUNT_USER_SETTING_LOAD_USER_SETTINGS, loadUserSettings);
    yield takeLatest(ACCOUNT_USER_SETTINGS_SAVE_SETTINGS, saveUserProfileSettings);
    yield takeLatest(ACCOUNT_USER_SETTING_DELETE_PROFILE, deleteProfileGenerator);
    yield takeLatest(ACCOUNT_USER_SETTING_DISMISS_NEWSLETTER_TEASER, dismissNewsletterTeaser);
    yield takeLatest(ACCOUNT_USER_SETTING_SUBSCRIBE_NEWSLETTER, subscribeNewsletter);
    yield takeLatest(ACCOUNT_USER_SETTING_DISMISS_INVALID_EMAIL_TEASER, dismissInvalidEmailTeaser);
}

export function* accountUserSettingsSagas() {
    yield all([watchAccountUserSettings()]);
}
