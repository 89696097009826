import { Token } from 'client-oauth2';
import { actionCreatorFactory } from 'typescript-fsa';
import { NavLocation } from '../../App';
import { AgbInfo, UserProfile } from '../../services/account';
import { FuntipPushSubscription2 } from '../../services/webapp';
import { DeploymentProperties, ServerType } from './systemConfig';

const actionCreator = actionCreatorFactory();

export const ADD_SYSTEM_ERROR_MESSAGE = 'ADD_SYSTEM_ERROR_MESSAGE';
export const addSystemErrorMessage = actionCreator<any>(ADD_SYSTEM_ERROR_MESSAGE);

export const SET_SYSTEM_START_FETCHING = 'SET_SYSTEM_START_FETCHING';
export const systemStartFetching = actionCreator(SET_SYSTEM_START_FETCHING);

export const SET_SYSTEM_FINISH_FETCHING = 'SET_SYSTEM_FINISH_FETCHING';
export const systemFinishFetching = actionCreator(SET_SYSTEM_FINISH_FETCHING);

export const SET_SYSTEM_ACCESS_TOKEN = 'SET_SYSTEM_ACCESS_TOKEN';
export const setSystemAccessToken = actionCreator<string>(SET_SYSTEM_ACCESS_TOKEN);

export const SET_SYSTEM_USER_INFO = 'SET_SYSTEM_USER_INFO';
export const setSystemUserInfo = actionCreator<UserProfile>(SET_SYSTEM_USER_INFO);

export const LOAD_TOKEN_FROM_STORAGE = 'LOAD_TOKEN_FROM_STORAGE';
export const loadTokenFromStorage = actionCreator(LOAD_TOKEN_FROM_STORAGE);

export const DELETE_SYSTEM_ERROR_MESSAGES = 'DELETE_SYSTEM_ERROR_MESSAGES';
export const deleteSystemErrorMessages = actionCreator(DELETE_SYSTEM_ERROR_MESSAGES);

export const SET_AUTHENTICATED_USER = 'SET_AUTHENTICATED_USER';
export const setAuthenticatedUser = actionCreator<Token>(SET_AUTHENTICATED_USER);

export const SYSTEM_SET_AUTHENTICATED_ACCESS_TOKEN = 'SYSTEM_SET_AUTHENTICATED_ACCESS_TOKEN';
export const systemSetAuthenticatedAccessToken = actionCreator<string>(SYSTEM_SET_AUTHENTICATED_ACCESS_TOKEN);

export const SYSTEM_SET_LOGIN_REQUIRED = 'SYSTEM_SET_LOGIN_REQUIRED';
export const systemSetLoginRequired = actionCreator<{ nextUrl: string }>(SYSTEM_SET_LOGIN_REQUIRED);

export const SYSTEM_SET_LOGIN_NOT_REQUIRED = 'SYSTEM_SET_LOGIN_NOT_REQUIRED';
export const systemSetLoginNotRequired = actionCreator(SYSTEM_SET_LOGIN_NOT_REQUIRED);

export const SYSTEM_SET_PUSH_SUBSCRIPTION = 'SYSTEM_SET_PUSH_SUBSCRIPTION';
export const systemSetPushSubscription = actionCreator<FuntipPushSubscription2 | undefined>(
    SYSTEM_SET_PUSH_SUBSCRIPTION,
);

export const SYSTEM_SET_PUSH_SUBSCRIPTION_PUBLIC_KEY = 'SYSTEM_SET_PUSH_SUBSCRIPTION_PUBLIC_KEY';
export const systemSetPushSubscriptionPublicKey = actionCreator<string>(SYSTEM_SET_PUSH_SUBSCRIPTION_PUBLIC_KEY);

export const LOAD_MY_USER_INFO = 'LOAD_MY_USER_INFO';
export const loadMyUserInfo = actionCreator(LOAD_MY_USER_INFO);

export const LOAD_USER_ROLES = 'LOAD_USER_ROLES';
export const loadUserRoles = actionCreator(LOAD_USER_ROLES);

export const SET_USER_ROLES = 'SET_USER_ROLES';
export const setUserRoles = actionCreator<string[]>(SET_USER_ROLES);

export const HANDLE_FETCH_EXCEPTION = 'HANDLE_FETCH_EXCEPTION';
export const handleFetchException = actionCreator<Response>(HANDLE_FETCH_EXCEPTION);

// initiate a logout
export const LOGOUT_ACTION = 'LOGOUT_ACTION';
export const logout = actionCreator(LOGOUT_ACTION);

// inform others about a logout which happened
export const LOGGED_OUT_ACTION = 'LOGGED_OUT_ACTION';
export const systemLoggedOut = actionCreator(LOGGED_OUT_ACTION);

export const LOGIN_ACTION = 'LOGIN_ACTION';
export const loginAction = actionCreator(LOGIN_ACTION);

export const SYSTEM_STARTUP = 'SYSTEM_STARTUP';
export const systemStartup = actionCreator(SYSTEM_STARTUP);

export const SYSTEM_SHOW_ERRORS = 'SYSTEM_SHOW_ERRORS';
export const systemShowErrors = actionCreator<boolean>(SYSTEM_SHOW_ERRORS);

export const SYSTEM_SET_POPUP_MESSAGE = 'SYSTEM_SET_POPUP_MESSAGE';
export const systemSetPopupMessage = actionCreator<string | undefined>(SYSTEM_SET_POPUP_MESSAGE);

export const SYSTEM_SET_AGB = 'SYSTEM_SET_AGB';
export const systemSetAgbs = actionCreator<AgbInfo>(SYSTEM_SET_AGB);

export const SYSTEM_SET_NAV_LOCATION = 'SYSTEM_SET_NAV_LOCATION';
export const systemSetNavLocation = actionCreator<NavLocation>(SYSTEM_SET_NAV_LOCATION);

export const SYSTEM_SET_DEPLOYMENT_PROPERTIES = 'SYSTEM_SET_DEPLOYMENT_PROPERTIES';
export const systemSetDeploymentProperties = actionCreator<DeploymentProperties>(SYSTEM_SET_DEPLOYMENT_PROPERTIES);

export const SYSTEM_CONFIRM_AGB = 'SYSTEM_CONFIRM_AGB';
export const systemConfirmAgb = actionCreator(SYSTEM_CONFIRM_AGB);

export const SYSTEM_REFUSE_AGB = 'SYSTEM_REFUSE_AGB';
export const systemRefuseAgbs = actionCreator(SYSTEM_REFUSE_AGB);

export const SYSTEM_LOAD_AGB = 'SYSTEM_LOAD_AGB';
export const systemLoadAgbs = actionCreator(SYSTEM_LOAD_AGB);

export const SYSTEM_CHECK_SERVER_AVAILABILITY = 'SYSTEM_CHECK_SERVER_AVAILABILITY';
export const systemCheckServerAvailability = actionCreator(SYSTEM_CHECK_SERVER_AVAILABILITY);

export const SYSTEM_SESSION_TOKEN_REFRESH = 'SYSTEM_SESSION_TOKEN_REFRESH';
export const systemSessionTokenRefresh = actionCreator(SYSTEM_SESSION_TOKEN_REFRESH);

export const SYSTEM_SHOW_LOGOUT_CONFIRMATION = 'SYSTEM_SHOW_LOGOUT_CONFIRMATION';
export const systemShowLogoutConfirmation = actionCreator<boolean>(SYSTEM_SHOW_LOGOUT_CONFIRMATION);

export const SYSTEM_AUTOMATIC_LOGOUT = 'SYSTEM_AUTOMATIC_LOGOUT';
export const systemAutomaticLogout = actionCreator(SYSTEM_AUTOMATIC_LOGOUT);

export const SYSTEM_SHOW_AUTOMATIC_LOGOUT_MESSAGE = 'SYSTEM_SHOW_AUTOMATIC_LOGOUT_MESSAGE';
export const systemShowAutomaticLogoutMessage = actionCreator<boolean>(SYSTEM_SHOW_AUTOMATIC_LOGOUT_MESSAGE);

export interface ServerAvailabilityAction {
    server: ServerType;
    available: boolean;
}

export const SYSTEM_SET_SERVER_AVAILABILITY = 'SYSTEM_SET_SERVER_AVAILABILITY';
export const systemSetServerAvailibility = actionCreator<ServerAvailabilityAction>(SYSTEM_SET_SERVER_AVAILABILITY);
