/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.25
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Newsletter,
  NewsletterContent,
  NewsletterCount,
  RestResult,
} from '../models/index';
import {
    NewsletterFromJSON,
    NewsletterToJSON,
    NewsletterContentFromJSON,
    NewsletterContentToJSON,
    NewsletterCountFromJSON,
    NewsletterCountToJSON,
    RestResultFromJSON,
    RestResultToJSON,
} from '../models/index';

export interface CountNewsletterRequest {
    clientName: string;
}

export interface GetNewsletterContentRequest {
    clientName: string;
    nlId: string;
}

export interface IncreaseDownloadCountRequest {
    clientName: string;
    nlId: string;
}

export interface ListNewsletterRequest {
    clientName: string;
    page?: number;
    pageSize?: number;
}

/**
 * 
 */
export class Newsletter2ControllerApi extends runtime.BaseAPI {

    /**
     */
    async countNewsletterRaw(requestParameters: CountNewsletterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NewsletterCount>> {
        if (requestParameters['clientName'] == null) {
            throw new runtime.RequiredError(
                'clientName',
                'Required parameter "clientName" was null or undefined when calling countNewsletter().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/newsletter/{clientName}/count/sent`.replace(`{${"clientName"}}`, encodeURIComponent(String(requestParameters['clientName']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NewsletterCountFromJSON(jsonValue));
    }

    /**
     */
    async countNewsletter(requestParameters: CountNewsletterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NewsletterCount> {
        const response = await this.countNewsletterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getNewsletterContentRaw(requestParameters: GetNewsletterContentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NewsletterContent>> {
        if (requestParameters['clientName'] == null) {
            throw new runtime.RequiredError(
                'clientName',
                'Required parameter "clientName" was null or undefined when calling getNewsletterContent().'
            );
        }

        if (requestParameters['nlId'] == null) {
            throw new runtime.RequiredError(
                'nlId',
                'Required parameter "nlId" was null or undefined when calling getNewsletterContent().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/newsletter/{clientName}/{nlId}`.replace(`{${"clientName"}}`, encodeURIComponent(String(requestParameters['clientName']))).replace(`{${"nlId"}}`, encodeURIComponent(String(requestParameters['nlId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NewsletterContentFromJSON(jsonValue));
    }

    /**
     */
    async getNewsletterContent(requestParameters: GetNewsletterContentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NewsletterContent> {
        const response = await this.getNewsletterContentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async increaseDownloadCountRaw(requestParameters: IncreaseDownloadCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult>> {
        if (requestParameters['clientName'] == null) {
            throw new runtime.RequiredError(
                'clientName',
                'Required parameter "clientName" was null or undefined when calling increaseDownloadCount().'
            );
        }

        if (requestParameters['nlId'] == null) {
            throw new runtime.RequiredError(
                'nlId',
                'Required parameter "nlId" was null or undefined when calling increaseDownloadCount().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/newsletter/{clientName}/{nlId}/increase-downlaod-count`.replace(`{${"clientName"}}`, encodeURIComponent(String(requestParameters['clientName']))).replace(`{${"nlId"}}`, encodeURIComponent(String(requestParameters['nlId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResultFromJSON(jsonValue));
    }

    /**
     */
    async increaseDownloadCount(requestParameters: IncreaseDownloadCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult> {
        const response = await this.increaseDownloadCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async listNewsletterRaw(requestParameters: ListNewsletterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Newsletter>>> {
        if (requestParameters['clientName'] == null) {
            throw new runtime.RequiredError(
                'clientName',
                'Required parameter "clientName" was null or undefined when calling listNewsletter().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['pageSize'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/newsletter/{clientName}`.replace(`{${"clientName"}}`, encodeURIComponent(String(requestParameters['clientName']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NewsletterFromJSON));
    }

    /**
     */
    async listNewsletter(requestParameters: ListNewsletterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Newsletter>> {
        const response = await this.listNewsletterRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
