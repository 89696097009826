import { faEdit, faImage, faRedo, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import * as R from 'ramda';
import React, { FC, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Container, Form, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import { Dispatch } from 'redux';
import { withBorder } from '../../../reactutils/withBorder';
import { withMenu } from '../../../reactutils/withMenu';
import { withNavLocation } from '../../../reactutils/withNavLocation';
import { withTitle } from '../../../reactutils/withTitle';
import { Blog, BlogEntry, BlogEntryImage } from '../../../services/image';
import { GlobalState } from '../../actions';
import { ButtonWithConfirmation } from '../../common/ButtonWithConfirmation';
import { FileUpload } from '../../common/FileUpload';
import { LoadingIndicatorSpinner } from '../../common/LoadingIndicatorSpinner';
import { Section, SectionHeading } from '../../common/Section';
import { DateTime } from '../../DateTime';
import { MenuBetting } from '../../MenuBetting';
import { usePathSegment } from '../../SysAdminApp/CacheStatisticsComponent';
import { isForumAdminSelector, isSysAdminSelector } from '../../System/selectors';
import { getSystemConfig, isR1Mobile } from '../../System/systemConfig';
import {
    blogAddEntry,
    blogAddImage,
    blogDeleteEntry,
    blogDeleteImage,
    BlogEntryAction,
    blogLoad,
    blogPublishEntryOnTwitter,
    blogPublishOnTwitter,
    blogUpdateEntry,
} from '../actions';
import { blogEntitySelector } from '../selectors';

const blogReload = (dispatch: Dispatch<any>, blog?: Blog) => {
    if (blog) {
        dispatch(blogLoad(blog.id));
    }
};

const BlogComponent: FC<{}> = () => {
    const dispatch = useDispatch();

    const [blogId] = usePathSegment(2);

    useEffect(() => {
        if (blogId) {
            dispatch(blogLoad(blogId));
        }
    }, [dispatch, blogId]);

    const blog = useSelector(blogEntitySelector);
    const isForumAdmin = useSelector(isForumAdminSelector);
    const isSysAdmin = useSelector(isSysAdminSelector);

    const [newEntry, setNewEntry] = useState(false);
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [edit, setEdit] = useState('');
    const [addImage, setAddImage] = useState('');

    useEffect(() => {
        const interval = setInterval(() => {
            blogReload(dispatch, blog);
        }, 1000 * 60 * 2); // reload every 2 minutes
        return () => clearInterval(interval);
    }, [dispatch, blog]);

    const createBlogEntry = () => {
        if (blog) {
            const be: BlogEntryAction = {
                blogId: blog.id,
                title,
                text,
            };
            dispatch(blogAddEntry(be));
            setTitle('');
            setText('');
            setNewEntry(false);
        }
    };

    const editBlogEntry = (beId: string) => {
        if (blog && blog.entries) {
            const entry = blog.entries.find((be) => be.id === beId);
            if (entry) {
                setTitle(entry.title);
                setText(entry.text);
                setEdit(beId);
            }
        }
    };

    const addImages = (beId: string) => {
        setAddImage(beId);
    };

    const dontAddImages = (beId: string) => {
        setAddImage('');
    };

    const deleteBlogEntry = (beId: string) => {
        dispatch(blogDeleteEntry(beId));
    };

    const updateBlogEntry = (beId: string) => {
        const be: BlogEntryAction = {
            blogEntryId: beId,
            title,
            text,
        };
        dispatch(blogUpdateEntry(be));
        cancelEdit();
    };

    const cancelEdit = () => {
        setEdit('');
        setTitle('');
        setText('');
    };

    const imageUploaded = (blogEntryId: string, imageId: string | undefined) => {
        if (imageId) {
            console.info('image uploaded: ' + imageId);
            dispatch(blogAddImage({ blogEntryId, imageId }));
        }
    };

    const onDeleteImage = (blogEntryId?: string, imageId?: string) => {
        if (imageId && blogEntryId) {
            dispatch(blogDeleteImage({ blogEntryId, imageId }));
        }
    };

    const renderBlogEntryImage = (bei: BlogEntryImage) => {
        if (bei.blogEntryId && bei.imageId) {
            return (
                <Row key={bei.id}>
                    <Col>
                        <img src={getSystemConfig().imageUrl(bei.imageId)} alt="" className="img-fluid img-thumbnail" />
                    </Col>
                    {isForumAdmin && (
                        <Col>
                            <ButtonWithConfirmation
                                color="danger"
                                title="Bild löschen"
                                message="Sind Sie sicher, dass Sie das ausgewählte Bild löschen wollen?"
                                onOK={() => onDeleteImage(bei.blogEntryId, bei.imageId)}
                            >
                                <FontAwesomeIcon icon={faTrash} />
                            </ButtonWithConfirmation>
                        </Col>
                    )}
                </Row>
            );
        } else {
            return null;
        }
    };

    const renderBlogEntry = (be: BlogEntry) => {
        const isEditMode = be.id === edit;
        const postedOnTwitter = be.twitterStatusIds && be.twitterStatusIds.length > 0;
        return (
            <React.Fragment key={be.id}>
                <Row key={be.id} className="mb-2">
                    {isEditMode && (
                        <Col>
                            <Section>
                                <SectionHeading>Blogeintrag bearbeiten</SectionHeading>
                                {renderForm(() => {
                                    updateBlogEntry(be.id);
                                }, cancelEdit)}
                            </Section>
                        </Col>
                    )}
                    {!isEditMode && (
                        <>
                            <Col>
                                <h2
                                    style={{
                                        fontSize: '36px',
                                        fontWeight: 'bold',
                                        display: 'inline',
                                        fontFamily: 'Gotham-Black',
                                        paddingRight: '10px',
                                    }}
                                >
                                    {' '}
                                    {be.title}
                                </h2>
                                <div className="blogging-app--blog-entry">
                                    <ReactMarkdown>{be.text}</ReactMarkdown>
                                </div>
                                <div>
                                    <small>
                                        <DateTime date={be.date} />
                                    </small>
                                </div>
                            </Col>
                        </>
                    )}
                    {isForumAdmin && !isEditMode && !newEntry && (
                        <Col>
                            <Button
                                onClick={() => editBlogEntry(be.id)}
                                color="success"
                                className="m-2"
                                title="Eintrag bearbeiten"
                                disabled={postedOnTwitter}
                            >
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>
                            <ButtonWithConfirmation
                                color="danger"
                                title="Blogeintrag löschen"
                                message={`Sind Sie sicher, dass Sie den aktuellen Blogeintrag '${be.title}' löschen wollen?`}
                                onOK={() => deleteBlogEntry(be.id)}
                                className="m-2"
                            >
                                <FontAwesomeIcon icon={faTrash} />
                            </ButtonWithConfirmation>
                            {addImage !== be.id && (
                                <Button
                                    onClick={() => addImages(be.id)}
                                    color="success"
                                    className="m-2"
                                    title="Bild hinzufügen"
                                >
                                    <FontAwesomeIcon icon={faImage} />
                                </Button>
                            )}
                            {!postedOnTwitter && (
                                <ButtonWithConfirmation
                                    color="warning"
                                    className="ms-2"
                                    title="Auf Twitter veröffentlichen"
                                    message="Sind Sie sicher, dass Sie den Blog-Eintrag auf Twitter posten wollen?"
                                    onOK={() => dispatch(blogPublishEntryOnTwitter(be.id))}
                                >
                                    Blog-Eintrag auf Twitter veröffentlichen
                                </ButtonWithConfirmation>
                            )}
                            {addImage === be.id && (
                                <Button onClick={() => dontAddImages(be.id)} color="success" className="m-2">
                                    <FontAwesomeIcon icon={faImage} />
                                </Button>
                            )}
                        </Col>
                    )}
                </Row>
                {addImage === be.id && (
                    <Row>
                        <Col>
                            <FileUpload
                                imageUploaded={(imageId: string | undefined) => imageUploaded(be.id, imageId)}
                            />
                        </Col>
                    </Row>
                )}
                {be.images && be.images.map((bei) => renderBlogEntryImage(bei))}
                <hr />
            </React.Fragment>
        );
    };

    const renderForm = (okFunc: () => void, cancelFunc: () => void) => {
        return (
            <Form>
                <FormGroup>
                    <Label for="title">Titel</Label>
                    <Input
                        type="text"
                        name="title"
                        id="title"
                        placeholder="Titel"
                        value={title}
                        onChange={(e) => {
                            setTitle(e.currentTarget.value);
                        }}
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="text">Text</Label>
                    <Input
                        type="textarea"
                        name="text"
                        id="text"
                        placeholder="Text"
                        value={text}
                        onChange={(e) => {
                            setText(e.currentTarget.value);
                        }}
                    />
                    {/* TODO make the magic number configurable */}
                    <FormText color={title.length + text.length > 275 ? 'danger' : 'muted'}>
                        {title.length + text.length}
                    </FormText>
                </FormGroup>
                <Button color="success" onClick={okFunc} disabled={!title || !text} className="me-2">
                    Blogeintrag speichern
                </Button>
                <Button color="secondary" onClick={cancelFunc}>
                    Abbrechen
                </Button>
            </Form>
        );
    };

    const getSortedBlogEntries = (blog: Blog): BlogEntry[] => {
        if (blog.entries) {
            const max = blog.entries
                .map((be) => be.date)
                .reduce((a, b) => (moment(a).isBefore(moment(b)) ? b : a), moment(new Date()).add(-1, 'days').toDate());
            if (moment(max).isBefore(moment().add(-1, 'hours'))) {
                // the blog was not edited in the last hour -> sort it ascending
                return blog.entries.sort((a, b) => moment(a.date).diff(b.date));
            } else {
                // ...otherwise sort descending
                return blog.entries.sort((a, b) => moment(b.date).diff(a.date));
            }
        } else {
            return [];
        }
    };

    if (blog) {
        return (
            <>
                {blog && isForumAdmin && edit === '' && newEntry && (
                    <Section>
                        <SectionHeading>Neuen Blogeintrag anlegen</SectionHeading>
                        {renderForm(createBlogEntry, () => setNewEntry(false))}
                    </Section>
                )}
                {!edit && !newEntry && isForumAdmin && (
                    <>
                        <Button color="success" onClick={() => setNewEntry(true)}>
                            Neuer Blogeintrag
                        </Button>
                    </>
                )}
                {!edit && !newEntry && isSysAdmin && (
                    <ButtonWithConfirmation
                        className="ms-2"
                        title="Auf Twitter veröffentlichen"
                        message="Sind Sie sicher, dass Sie den gesamten Blog auf Twitter posten wollen?"
                        onOK={() => dispatch(blogPublishOnTwitter(blog.id))}
                    >
                        Blog auf Twitter veröffentlichen
                    </ButtonWithConfirmation>
                )}
                {blog && <Container>{getSortedBlogEntries(blog).map((be) => renderBlogEntry(be))}</Container>}
            </>
        );
    } else {
        return <LoadingIndicatorSpinner />;
    }
};

export interface BlogRequestParams {
    blogId: string;
}

const BlogSubMenu: FC<{}> = () => {
    const blog = useSelector(blogEntitySelector);
    const dispatch = useDispatch();

    return (
        <Button size="sm" color="success" className="ms-3" disabled={!blog} onClick={() => blogReload(dispatch, blog)}>
            <FontAwesomeIcon icon={faRedo} />
        </Button>
    );
};
const subTitleSelector = (state: GlobalState) => {
    const blog = blogEntitySelector(state);

    return (
        <>
            {blog ? blog.title : ''}
            <BlogSubMenu />
        </>
    );
};

export const BlogScreen = R.compose(
    withBorder(),
    withMenu(<MenuBetting />, () => isR1Mobile()),
    withTitle('Blog', subTitleSelector),
    withNavLocation('profile')
)(BlogComponent);

export const BlogLatestScreen = R.compose(
    withBorder(),
    withMenu(<MenuBetting />, () => isR1Mobile()),
    withTitle('Blog', subTitleSelector),
    withNavLocation('profile')
)(BlogComponent);
