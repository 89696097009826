import actionCreatorFactory from 'typescript-fsa';
import { MyGroupHighscore } from '../../../services/betting';

const actionCreator = actionCreatorFactory();

export const MY_GROUP_HIGHSCORES_SET = 'MY_GROUP_HIGHSCORES_SET';
export const myGroupHighscoresSet = actionCreator<MyGroupHighscore>(MY_GROUP_HIGHSCORES_SET);

export const MY_GROUP_HIGHSCORES_FORWARD = 'MY_GROUP_HIGHSCORES_FORWARD';
export const myGroupHighscoresForward = actionCreator<string>(MY_GROUP_HIGHSCORES_FORWARD);

export const MY_GROUP_HIGHSCORES_GOTO_HIGHSCORE = 'MY_GROUP_HIGHSCORES_GOTO_HIGHSCORE';
export const myGroupHighscoresGotoHighscore = actionCreator<string>(MY_GROUP_HIGHSCORES_GOTO_HIGHSCORE);

export const MY_GROUP_HIGHSCORES_SET_UNREAD_TEAMCHAT_MESSAGES = 'MY_GROUP_HIGHSCORES_SET_UNREAD_TEAMCHAT_MESSAGES';
export const myGroupHighscoresSetUnreadTeamChatMessages = actionCreator<number>(
    MY_GROUP_HIGHSCORES_SET_UNREAD_TEAMCHAT_MESSAGES
);

export const MY_GROUP_HIGHSCORES_REFRESH_UNREAD_TEAMCHAT_MESSAGES = 'MY_GROUP_HIGHSCORES_REFRESH_UNREAD_TEAMCHAT_MESSAGES';
export const myGroupHighscoresRefreshUnreadTeamChatMessages = actionCreator<string>(
    MY_GROUP_HIGHSCORES_REFRESH_UNREAD_TEAMCHAT_MESSAGES
);
