import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { getSystemConfig, getTeamApi2 } from '../../System/systemConfig';
import classNames from 'classnames';

interface Props extends React.HTMLAttributes<HTMLDivElement>{
    teamId: string;
    maxWidth?: number;
    teamName?: string;
}

export const TeamImage: React.FC<Props> = (props) => {
    const teamQuery = useQuery({
        queryKey: ['teamImage', props.teamId],
        queryFn: () => getTeamApi2().getTeamImage({ teamId: props.teamId! }),
        enabled: !!props.teamId,
    });

    if (teamQuery.data) {
        return (
            <img
                src={getSystemConfig().imageUrl(teamQuery.data)}
                alt={props.teamName ?? ''}
                className={classNames('img-fluid', props.className)}
                style={{ maxWidth: props.maxWidth ?? 50, maxHeight: props.maxWidth ?? 50 }}
            />
        );
    } else {
        return null;
    }
};
