import React, { FC, useState } from 'react';
import { getTournamentSysAdminApi } from '../../../../../System/systemConfig';
import { useDispatch } from 'react-redux';
import { useMutation } from '@tanstack/react-query';
import { systemSetPopupMessage } from '../../../../../System/actions';
import { TournamentGroupEdit } from '../../TournamentGroupEdit';
import { OnDemandConfirmationDialog } from '../../../../../common/ConfirmationDialog';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

interface TournamentGroupActionsProps {
    trId: string;
    onRefresh: () => void;
}
export const TournamentGroupActions: FC<TournamentGroupActionsProps> = (props) => {
    const [dropDownOpen, setDropDownOpen] = useState(false);
    const [showCreateDialog, setShowCreateDialog] = useState(false);
    const [showAdjustTGMs, setShowAdjustTGMs] = useState(false);

    const toggle = () => setDropDownOpen((state) => !state);

    const tournamentSysadminApi = getTournamentSysAdminApi();
    const dispatch = useDispatch();

    const adjustTournamentGroupMemberMutation = useMutation({
        mutationFn: (trId: string) => tournamentSysadminApi.adjustTournamentGroupMembers({trId}),
        onSuccess(data) {
            if (data.statusCode === 200) {
                dispatch(systemSetPopupMessage('Tournament Group Members successfully adjusted.'));
            } else {
                dispatch(systemSetPopupMessage('Tournament Group Members NOT adjusted.'));
            }
        },
    });

    const onAdjustTGMs = (trId: string) => {
        adjustTournamentGroupMemberMutation.mutate(trId);
    };

    return (
        <div className="d-flex justify-content-end">
            <TournamentGroupEdit
                trId={props.trId}
                isShown={showCreateDialog}
                onClose={() => {
                    setShowCreateDialog(false);
                    props.onRefresh();
                }}
            />
            <OnDemandConfirmationDialog
                title="Adjust Tournament Group Members"
                message={`Are you sure you want to automatically adjust the tournament group members?`}
                onOK={() => onAdjustTGMs(String(props.trId))}
                isShown={() => showAdjustTGMs}
                onCancel={() => {
                    setShowAdjustTGMs(false);
                }}
            />

            <Dropdown isOpen={dropDownOpen} toggle={toggle} className="ms-3">
                <DropdownToggle caret color="primary" className="text-white">
                    Group Actions
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem header>Group Actions</DropdownItem>
                    <DropdownItem onClick={() => setShowCreateDialog(true)}>Add Group</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={() => setShowAdjustTGMs(true)}>
                        Automatically Adjust Group Members
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};
