import React, { FC, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Table } from 'reactstrap';
import { AFFixtureSet, AFGameEvent, AFGameEventResponse } from '../../apifootball-model';
import { getFixturesById } from '../league';
import { getApiFootball } from '../live';

export const getGameEvents = async (fixture: string): Promise<AFGameEventResponse> => {
    const apiFootball = await getApiFootball();
    return apiFootball.get('/fixtures/events?fixture=' + fixture).then((response) => response.data);
};

export const ApiFootballGameEventIcon: FC<{ type: string; detail: string }> = (props) => {
    const maxWidth = '25px';
    switch (props.type) {
        case 'Goal':
            switch (props.detail) {
                case 'Normal Goal':
                case 'Penalty':
                case 'Own Goal':
                    return <img src="/images/goal-451.svg" className="img-fluid" style={{ maxWidth }} alt="goal" />;

                default:
                    return (
                        <span>
                            {props.type} / {props.detail}
                        </span>
                    );
            }
        case 'Card':
            switch (props.detail) {
                case 'Yellow Card':
                    return (
                        <img
                            src="/images/yellow-card-489.svg"
                            className="img-fluid"
                            style={{ maxWidth }}
                            alt="yellow card"
                        />
                    );
                case 'Red Card':
                    return (
                        <img src="/images/red-card-460.svg" className="img-fluid" style={{ maxWidth }} alt="red card" />
                    );
                default:
                    return (
                        <span>
                            {props.type} / {props.detail}
                        </span>
                    );
            }
        case 'Var':
            switch (props.detail) {
                case 'Goal confirmed':
                case 'Goal cancelled':
                case 'Penalty confirmed':
                case 'Red card cancelled':
                case 'Card upgrade':
                default:
                    <span>{props.detail}</span>;
            }
            break;
        case 'subst':
            return (
                <img
                    src="/images/substitution--473.svg"
                    className="img-fluid"
                    style={{ maxWidth }}
                    alt="Substitution"
                />
            );
    }
    return (
        <span>
            {props.type} / {props.detail}
        </span>
    );
};

export const ApiFootballTicker: FC<{ fixtureId: string }> = (props) => {
    const { data } = useQuery({
        queryKey: ['getGameEvents', props.fixtureId],
        queryFn: () => getGameEvents(props.fixtureId).then((r) => r.response),
    });

    const [fixture, setFixture] = useState<AFFixtureSet>();

    const fixtureQuery = useQuery({
        queryKey: ['getFixtureById', props.fixtureId],
        queryFn: () => getFixturesById(props.fixtureId).then((r) => r.response),
    });

    useEffect(() => {
        if (fixtureQuery.data && fixtureQuery.data.length > 0) {
            setFixture(fixtureQuery.data[0]);
        }
    }, [fixtureQuery.data]);

    const getGameEventResult = (ge: AFGameEvent, list: AFGameEvent[]) => {
        const relevantEvents = list.filter(
            (e) =>
                e.time.elapsed <= ge.time.elapsed ||
                (e.time.elapsed === ge.time.elapsed && e.time.extra <= ge.time.extra),
        );
        return relevantEvents
            .filter((e) => e.type === 'Goal')
            .reduce((acc, val) => {
                const oldVal = acc.get(val.team.name) || 0;
                acc.set(val.team.name, oldVal + 1);
                return acc;
            }, new Map<string, number>());
    };

    const renderGameEvent = (ge: AFGameEvent, list: AFGameEvent[]) => {
        return (
            <tr key={ge.type + ge.time}>
                <td>
                    <ApiFootballGameEventIcon type={ge.type} detail={ge.detail} />
                    {ge.type === 'Goal' && fixture && (
                        <span className="ms-2 text-nowrap">
                            {getGameEventResult(ge, list).get(fixture.teams.home.name) || 0}:
                            {getGameEventResult(ge, list).get(fixture.teams.away.name) || 0}
                        </span>
                    )}
                </td>
                <td>{ge.team.name}</td>
                <td>{ge.player.name}</td>
                <td>{ge.assist?.name}</td>
                <td>{ge.time.elapsed}</td>
                <td>{ge.time.extra}</td>
                <td>{ge.comments}</td>
            </tr>
        );
    };

    return (
        <>
            <Table>
                <tbody>{fixture && data && data.map((ge) => renderGameEvent(ge, data))}</tbody>
            </Table>
        </>
    );
};
