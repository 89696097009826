import { GlobalState } from '../../actions';

export const userProfileSelector = (state: GlobalState) => state.accountUserProfile;

export const userProfileEntitySelector = (state: GlobalState) => userProfileSelector(state).profile;

export const userProfileContactDataSelector = (state: GlobalState) => userProfileSelector(state).contactData;

export const userProfileAvailableCountriesSelector = (state: GlobalState) =>
    userProfileSelector(state).availableCountries;

export const userProfileDevicesSelector = (state: GlobalState) => userProfileSelector(state).devices;
