import { useMutation, useQuery } from '@tanstack/react-query';
import * as R from 'ramda';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Table } from 'reactstrap';
import { withBorder } from '../../../reactutils/withBorder';
import { withLogin } from '../../../reactutils/withLogin';
import { withMenu } from '../../../reactutils/withMenu';
import { TabbedContent } from '../../../reactutils/withTabbedScreen';
import { withTitle } from '../../../reactutils/withTitle';
import { UseCaseAccounting } from '../../../services/account';
import { formatNumber } from '../../../utils';
import { MenuSysAdmin } from '../../MenuSysAdmin';
import { systemSetPopupMessage } from '../../System/actions';
import { ServerType, getUseCaseLogApi, relevantServerNames } from '../../System/systemConfig';
import { LoadingIndicatorContent, LoadingIndicatorSpinner } from '../../common/LoadingIndicatorSpinner';
import { usePathSegment } from '../CacheStatisticsComponent';
import { SysAdminSystemSubMenu } from '../SysAdminSubMenu';
import { sysAdminUseCaseLogPath } from '../sysAdminPathes';

const UseCaseLogs: FC<{}> = (props) => {
    const dispatch = useDispatch();

    const [server] = usePathSegment(3) as ServerType[];

    const [entities, setEntities] = useState<UseCaseAccounting[]>([]);

    const api = getUseCaseLogApi(server);

    const useCaseQuery = useQuery({
        queryKey: ['usecaselog', server],
        queryFn: () => api.getUseCaseLogs(),
        retry: false,
    });

    useEffect(() => {
        setEntities(useCaseQuery.data ?? []);
    }, [useCaseQuery.data]);

    const clearUseCaseMutation = useMutation({
        mutationFn: () => api.clearUseCaseLogs(),
        onSuccess() {
            dispatch(systemSetPopupMessage('Use case logs cleared'));
            useCaseQuery.refetch();
        },
        onError() {
            dispatch(systemSetPopupMessage('Failed to clear use case logs'));
            useCaseQuery.refetch();
        },
    });

    const renderUseCaseLog = (ucl: UseCaseAccounting) => {
        return (
            <tr key={ucl.name}>
                <td className="text-break">
                    <LoadingIndicatorContent isLoading={useCaseQuery.isFetching}>{ucl.name}</LoadingIndicatorContent>
                </td>
                <td className="text-end d-none d-lg-table-cell">{ucl.count}</td>
                <td className="text-end d-none d-lg-table-cell">{formatNumber(ucl.duration, 0)}</td>
                <td className="text-end d-none d-lg-table-cell">{formatNumber(ucl.avgDuration, 1)}</td>
                <td className="text-end d-none d-lg-table-cell">{formatNumber(ucl.minDuration, 0)}</td>
                <td className="text-end d-none d-lg-table-cell">{formatNumber(ucl.maxDuration, 0)}</td>
                <td className="text-end d-table-cell d-lg-none">
                    <b>{ucl.count}</b> <br /> ({formatNumber(ucl.avgDuration, 1)} ms)
                </td>
            </tr>
        );
    };
    return (
        <>
            <TabbedContent activeTab={server} tabLinks={sysAdminUseCaseLogPath} tabNames={relevantServerNames()} />
            <Button color="secondary" outline={false} className="m-2" onClick={() => useCaseQuery.refetch()}>
                Reload
            </Button>
            <Button color="danger" outline={false} className="m-2" onClick={() => clearUseCaseMutation.mutate()}>
                Clear
            </Button>
            <Table className="table-fixed mw-100">
                <thead>
                    <tr>
                        <th>Use Case</th>
                        <th className="d-none d-lg-table-cell">Count</th>
                        <th className="d-none d-lg-table-cell">Duration</th>
                        <th className="d-none d-lg-table-cell">Avg (ms)</th>
                        <th className="d-none d-lg-table-cell">Min (ms)</th>
                        <th className="d-none d-lg-table-cell">Max (ms)</th>
                        <th className="d-table-cell d-lg-none">Count / Avg</th>
                    </tr>
                </thead>
                <tbody>
                    {entities && entities.map((e) => renderUseCaseLog(e))}
                    {!entities && (
                        <tr>
                            <td>
                                <LoadingIndicatorSpinner />
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </>
    );
};

export const UseCaseLogScreen = R.compose(
    withMenu(<MenuSysAdmin />),
    withBorder(),
    withMenu(<SysAdminSystemSubMenu />),
    withTitle('Use Case Logs'),
    withLogin(),
)(UseCaseLogs);
