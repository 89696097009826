/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.34
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { TournamentRoundType } from './TournamentRoundType';
import {
    TournamentRoundTypeFromJSON,
    TournamentRoundTypeFromJSONTyped,
    TournamentRoundTypeToJSON,
} from './TournamentRoundType';

/**
 * 
 * @export
 * @interface TournamentRoundSysAdminRestModel
 */
export interface TournamentRoundSysAdminRestModel {
    /**
     * 
     * @type {string}
     * @memberof TournamentRoundSysAdminRestModel
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof TournamentRoundSysAdminRestModel
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof TournamentRoundSysAdminRestModel
     */
    longName?: string;
    /**
     * 
     * @type {string}
     * @memberof TournamentRoundSysAdminRestModel
     */
    tournamentId?: string;
    /**
     * 
     * @type {string}
     * @memberof TournamentRoundSysAdminRestModel
     */
    parentId?: string;
    /**
     * 
     * @type {TournamentRoundType}
     * @memberof TournamentRoundSysAdminRestModel
     */
    roundType?: TournamentRoundType;
    /**
     * 
     * @type {number}
     * @memberof TournamentRoundSysAdminRestModel
     */
    order?: number;
    /**
     * 
     * @type {number}
     * @memberof TournamentRoundSysAdminRestModel
     */
    rankingType?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TournamentRoundSysAdminRestModel
     */
    cupEligible?: boolean;
}



/**
 * Check if a given object implements the TournamentRoundSysAdminRestModel interface.
 */
export function instanceOfTournamentRoundSysAdminRestModel(value: object): value is TournamentRoundSysAdminRestModel {
    return true;
}

export function TournamentRoundSysAdminRestModelFromJSON(json: any): TournamentRoundSysAdminRestModel {
    return TournamentRoundSysAdminRestModelFromJSONTyped(json, false);
}

export function TournamentRoundSysAdminRestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): TournamentRoundSysAdminRestModel {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'name': json['name'] == null ? undefined : json['name'],
        'longName': json['longName'] == null ? undefined : json['longName'],
        'tournamentId': json['tournamentId'] == null ? undefined : json['tournamentId'],
        'parentId': json['parentId'] == null ? undefined : json['parentId'],
        'roundType': json['roundType'] == null ? undefined : TournamentRoundTypeFromJSON(json['roundType']),
        'order': json['order'] == null ? undefined : json['order'],
        'rankingType': json['rankingType'] == null ? undefined : json['rankingType'],
        'cupEligible': json['cupEligible'] == null ? undefined : json['cupEligible'],
    };
}

export function TournamentRoundSysAdminRestModelToJSON(value?: TournamentRoundSysAdminRestModel | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'longName': value['longName'],
        'tournamentId': value['tournamentId'],
        'parentId': value['parentId'],
        'roundType': TournamentRoundTypeToJSON(value['roundType']),
        'order': value['order'],
        'rankingType': value['rankingType'],
        'cupEligible': value['cupEligible'],
    };
}

