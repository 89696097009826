import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { systemConfirmAgb, systemRefuseAgbs } from '../../System/actions';
import {
    isLoggedInSelector,
    systemConfirmedPrivacyStatementVersionSelector,
    systemPrivacyStatementUrlSelector,
    systemPrivacyStatementVersionSelector,
} from '../../System/selectors';
import { Link } from 'react-router-dom';

export const AgbConfirmation: FC<{}> = () => {
    const dispatch = useDispatch();

    const [showDialog, setShowDialog] = useState(false);

    const privacyStatementVersion = useSelector(systemPrivacyStatementVersionSelector);
    const privacyStatementUrl = useSelector(systemPrivacyStatementUrlSelector);
    const confirmedPrivacyStatementVersion = useSelector(systemConfirmedPrivacyStatementVersionSelector);
    const isLoggedIn = useSelector(isLoggedInSelector);

    const onConfirm = () => {
        setShowDialog(false);
        dispatch(systemConfirmAgb());
    };

    const onRefuse = () => {
        setShowDialog(false);
        dispatch(systemRefuseAgbs());
    };

    useEffect(() => {
        setShowDialog(
            isLoggedIn &&
                privacyStatementVersion !== '' &&
                confirmedPrivacyStatementVersion !== '' &&
                privacyStatementVersion !== confirmedPrivacyStatementVersion,
        );
    }, [privacyStatementVersion, confirmedPrivacyStatementVersion, isLoggedIn]);

    return (
        <>
            <Modal isOpen={showDialog} toggle={() => setShowDialog(!showDialog)}>
                <ModalHeader>Allgemeine Geschäftsbedingungen</ModalHeader>
                <ModalBody>
                    Bitte lesen und bestätigen Sie die aktuelle Version der{' '}
                    <Link to={privacyStatementUrl ?? ''}>allgemeinen Geschäftsbedingungen</Link>.
                </ModalBody>
                <ModalFooter>
                    <Container>
                        <Row>
                            <Col xs={6}>
                                <Button color="success" onClick={onConfirm}>
                                    AGBs akzeptieren
                                </Button>
                            </Col>
                            <Col xs={6}>
                                <Button color="danger" onClick={onRefuse}>
                                    AGBs ablehnen
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </ModalFooter>
            </Modal>
        </>
    );
};
