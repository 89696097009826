import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import * as R from 'ramda';
import { useState } from 'react';
import { Button, Form, FormGroup, Input, Label, Table } from 'reactstrap';
import { withBorder } from '../../../reactutils/withBorder';
import { withMenu } from '../../../reactutils/withMenu';
import { withTitle } from '../../../reactutils/withTitle';
import { MenuSystemProperties } from '../../../screens/SysAdmin/SystemProperties';
import { ClientProperty } from '../../../services/account';
import { MenuSysAdmin } from '../../MenuSysAdmin';
import { getClientProperty2Api } from '../../System/systemConfig';
import { Section } from '../../common/Section';
import { ClientPropertyEditComp } from './ClientPropertyEdit';

export const ClientPropertyComp = () => {
    const [filter, setFilter] = useState<string>('');
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [editId, setEditId] = useState<{ clientId: string; name: string }>();

    const api = getClientProperty2Api();

    const clientPropertyQuery = useQuery({
        queryKey: ['clientProperty'],
        queryFn: () => api.getAllUnique(),
    });

    const onEditClick = (clientId: string, name: string) => {
        setEditId({ clientId, name });
        setShowEditDialog(true);
    };

    const renderClientProperty = (cp: ClientProperty) => {
        return (
            <tr key={cp.id}>
                <td className="position-sticky start-0 bg-light">{cp.clientName}</td>
                <td className="text-break">{cp.name}</td>
                <td className="d-none d-lg-table-cell">{cp.value}</td>
                <td className="d-none d-lg-table-cell">
                    {DateTime.fromJSDate(cp.timeStamp).toFormat('dd.MM.yy HH:mm')}
                </td>
                <td className="d-none d-lg-table-cell">{cp.daysValid}</td>
                <td className="d-none d-lg-table-cell">{cp.languageName}</td>
                <td className="d-none d-lg-table-cell">{cp.version}</td>
                <td className="d-none d-lg-table-cell">{cp.versionCount}</td>
                <td className="position-sticky end-0 bg-light">
                    <Button color="link" onClick={() => onEditClick(cp.clientId, cp.name)}>
                        <FontAwesomeIcon icon={faEdit} />
                    </Button>
                </td>
            </tr>
        );
    };

    const filterClientProperty = (cp: ClientProperty) => {
        if (cp.clientName.toLowerCase().includes(filter.toLowerCase())) {
            return true;
        }
        if (cp.name.toLowerCase().includes(filter.toLowerCase())) {
            return true;
        }
        if (cp.value.toLowerCase().includes(filter.toLowerCase())) {
            return true;
        }
        return false;
    };

    const onCloseEditDialog = () => {
        setShowEditDialog(false);
        clientPropertyQuery.refetch();
    };

    return (
        <Section>
            <Form>
                <FormGroup>
                    <Label>Filter ({clientPropertyQuery.data?.filter(filterClientProperty).length})</Label>
                    <Input type="text" value={filter} onChange={(e) => setFilter(e.currentTarget.value)} />
                </FormGroup>
            </Form>
            <FormGroup>
                <Button color="success" onClick={() => onEditClick('', '')}>
                    Add <FontAwesomeIcon icon={faPlus} />
                </Button>
            </FormGroup>

            {showEditDialog && editId && (
                <ClientPropertyEditComp clientId={editId.clientId} name={editId.name} onClose={onCloseEditDialog} />
            )}

            <Table striped={true} responsive={true}>
                <thead>
                    <tr>
                        <th className="position-sticky start-0 bg-light">Client</th>
                        <th>Property</th>
                        <th className="d-none d-lg-table-cell">Value</th>
                        <th className="d-none d-lg-table-cell">Created</th>
                        <th className="d-none d-lg-table-cell">Days valid</th>
                        <th className="d-none d-lg-table-cell">Language</th>
                        <th className="d-none d-lg-table-cell">Version</th>
                        <th className="d-none d-lg-table-cell">Version count</th>
                        <th className="position-sticky end-0 bg-light">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {clientPropertyQuery.data &&
                        clientPropertyQuery.data.filter(filterClientProperty).map((cp) => renderClientProperty(cp))}
                </tbody>
            </Table>
        </Section>
    );
};

export const ClientPropertyComponent = R.compose(
    withMenu(<MenuSysAdmin />),
    withBorder(),
    withMenu(<MenuSystemProperties />),
    withTitle('Client Properties'),
)(ClientPropertyComp);
