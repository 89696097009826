import * as React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'reactstrap';
import { GroupInternalHighscoreLink } from '../../../../links/GroupInternalHighscoreLink';
import { UserGroupAdminLink } from '../../../../links/UserGroupAdminLink';
import { UserGroup } from '../../../../services/betting';
import { Section, SectionHeading } from '../../../common/Section';
import { userGroupHighscoresDefaultTrSelector } from '../../../UserGroupHighscores/selectors';
import { getUserGroupDefaultHighscoreRound } from '../../../UserGroupList';

interface Props {
    userGroups: UserGroup[];
}

export const UserViewBettingUserGroups = (props: Props) => {
    const defaultHighscoreRounds = useSelector(userGroupHighscoresDefaultTrSelector);

    function renderUserGroup(ug: UserGroup) {
        const defaultRound = getUserGroupDefaultHighscoreRound(String(ug.id), defaultHighscoreRounds);
        return (
            <tr key={ug.id}>
                <td>
                    <UserGroupAdminLink ugId={ug.id}>{ug.name}</UserGroupAdminLink>
                </td>
                <td>
                    {defaultRound && (
                        <GroupInternalHighscoreLink trId={defaultRound} ugId={ug.id}>
                            interne Wertung
                        </GroupInternalHighscoreLink>
                    )}
                </td>
            </tr>
        );
    }

    return (
        <Section>
            <SectionHeading>Tippteams</SectionHeading>
            <Table striped={true}>
                <thead>
                    <tr>
                        <th>Tippteam</th>
                        <th>Interne Wertung</th>
                    </tr>
                </thead>
                <tbody>{props.userGroups && props.userGroups.map(ug => renderUserGroup(ug))}</tbody>
            </Table>
        </Section>
    );
};
