import React from 'react';
import { Section, SectionHeading } from '../../../common/Section';
import { Container, FormGroup, Input, Label } from 'reactstrap';
import { getBettingUserApi2, getProfile2Api, getSystemConfig } from '../../../System/systemConfig';
import { useMutation, useQuery } from '@tanstack/react-query';
import { systemSetPopupMessage } from '../../../System/actions';
import { useDispatch } from 'react-redux';

interface Props {
    userId: string;
}

const TOURNAMENT_PREFIX = 'T_';

export const EmailNotificationSettings: React.FC<Props> = (props) => {

    const dispatch = useDispatch();

    const tournamnetSubscriptions = useQuery({
        queryKey: ['tournamentSubscriptions', props.userId],
        queryFn: () => getBettingUserApi2().getSubscribedTournaments(),
        enabled: !!props.userId,
    });

    const profileQuery = useQuery({
        queryKey: ['profileSettings', props.userId],
        queryFn: () => getProfile2Api().getUserSettings({tenant: getSystemConfig().clientName}),
        enabled: !!props.userId,
    });

    const toggleTournamentSubscriptionMutation = useMutation({
        mutationFn: (tournamentId: string) =>
            getBettingUserApi2().toggleTournamentSubscription({tournamentId})
    });

    const toggleInfoMailsMutation = useMutation({
        mutationFn: () =>
            getProfile2Api().toggleInfoMails()
    });

    const toggleNewsletterSubscriptionMutation = useMutation({
        mutationFn: () =>
            getProfile2Api().toggleNewsletterSubscription()
    });

    const onToggleTournamentSubscription = (tournamentId: string) => {
        toggleTournamentSubscriptionMutation.mutate(tournamentId, {
            onSuccess: () => {
                tournamnetSubscriptions.refetch();
            },
            onError: (error) => {
                dispatch(systemSetPopupMessage('Die Einstellungen konnten nicht gespeichert werden.'));
            }
        });
    };

    const onToggleInfoMails = () => {
        toggleInfoMailsMutation.mutate(undefined, {
            onSuccess: () => {
                profileQuery.refetch();
            },
            onError: (error) => {
                dispatch(systemSetPopupMessage('Die Einstellungen konnten nicht gespeichert werden.'));
            }
        });
    };

    const onToggleNewsletterSubscription = () => {
        toggleNewsletterSubscriptionMutation.mutate(undefined, {
            onSuccess: () => {
                profileQuery.refetch();
            },
            onError: (error) => {
                dispatch(systemSetPopupMessage('Die Einstellungen konnten nicht gespeichert werden.'));
            }
        });
    };

    return (
        <Section>
            <SectionHeading>Email-Benachrichtigungen</SectionHeading>
            {profileQuery.data?.userId && (
                <div>
                    <FormGroup check={true}>
                        <Label check={true}>
                            <Input
                                type="checkbox"
                                name="infoMails"
                                checked={profileQuery.data?.infoMails}
                                onChange={onToggleInfoMails}
                            />{' '}
                            Ich möchte grundsätzlich Email-Nachrichten bekommen.
                        </Label>
                    </FormGroup>
                    <Container>
                        <FormGroup check={true}>
                            <Label>
                                <Input
                                    type="checkbox"
                                    name="newsletter"
                                    checked={profileQuery.data?.newsletter}
                                    onChange={onToggleNewsletterSubscription}
                                    disabled={!profileQuery.data?.infoMails}
                                />
                                Ich möchte den Newsletter bekommen.
                            </Label>
                        </FormGroup>
                    </Container>
                </div>
            )}

            <FormGroup tag="fieldset" disabled={!profileQuery.data?.infoMails}>
                <b>Schicken Sie mir Tipperinnerungen für die folgenden Turniere:</b>
                {tournamnetSubscriptions.data?.map((ts, index) => (
                    <FormGroup check={true} key={ts.tournamentId}>
                        <Label>
                            <Input
                                type="checkbox"
                                name={TOURNAMENT_PREFIX + ts.tournamentId}
                                checked={ts.subscribed}
                                onChange={() => onToggleTournamentSubscription(String(ts.tournamentId))}
                                disabled={!profileQuery.data?.infoMails}
                            />
                            {ts.tournamentName}
                        </Label>
                    </FormGroup>
                ))}
            </FormGroup>
        </Section>
    );
};
