import { all, put, select, takeLatest } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { fetchWrapperFunc } from '../../../utils';
import { getUserGroupAdminApi2, getUserGroupApi2 } from '../../System/systemConfig';
import { loadDefaultTournament } from '../../TournamentSelectionComponent/sagas';
import { tournamentSelectionTournamentIdSelector } from '../../TournamentSelectionComponent/selectors';
import { hsdSet, HSD_LOAD, hsdSetUgId, hsdSetUgName } from './actions';

function* loadHsdGenerator(action: Action<string>) {
    const ugId = action.payload;

    // set the ugId in the store
    yield put(hsdSetUgId(ugId));

    const api = getUserGroupAdminApi2();
    const tId: number | undefined = yield select(tournamentSelectionTournamentIdSelector);

    if (!tId) {
        yield* loadDefaultTournament();
        const tId2: number | undefined = yield select(tournamentSelectionTournamentIdSelector);
        // load the highscore descriptor
        if (tId2) {
            yield* fetchWrapperFunc(() => api.getHighscoreDescriptor({ugId: Number(ugId), tId: tId2}), [r => hsdSet(r)]);
        }
    } else {
        // load the highscore descriptor
        yield* fetchWrapperFunc(() => api.getHighscoreDescriptor({ugId: Number(ugId), tId}), [r => hsdSet(r)]);
    }

    // load the user group name
    yield* fetchWrapperFunc(() => getUserGroupApi2().getDetailOfUserGroup({ugId: Number(ugId)}), [r => hsdSetUgName(r.name)]);
}

function* watchForHsdAction() {
    yield takeLatest(HSD_LOAD, loadHsdGenerator);
}

export function* hsdSagas() {
    yield all([watchForHsdAction()]);
}
