/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.34
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ResultMessage
 */
export interface ResultMessage {
    /**
     * 
     * @type {number}
     * @memberof ResultMessage
     */
    statusCode?: number;
    /**
     * 
     * @type {string}
     * @memberof ResultMessage
     */
    message?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ResultMessage
     */
    error?: boolean;
}

/**
 * Check if a given object implements the ResultMessage interface.
 */
export function instanceOfResultMessage(value: object): value is ResultMessage {
    return true;
}

export function ResultMessageFromJSON(json: any): ResultMessage {
    return ResultMessageFromJSONTyped(json, false);
}

export function ResultMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ResultMessage {
    if (json == null) {
        return json;
    }
    return {
        
        'statusCode': json['statusCode'] == null ? undefined : json['statusCode'],
        'message': json['message'] == null ? undefined : json['message'],
        'error': json['error'] == null ? undefined : json['error'],
    };
}

export function ResultMessageToJSON(value?: ResultMessage | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'statusCode': value['statusCode'],
        'message': value['message'],
        'error': value['error'],
    };
}

