/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.34
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  RestResult,
  Team,
} from '../models/index';
import {
    RestResultFromJSON,
    RestResultToJSON,
    TeamFromJSON,
    TeamToJSON,
} from '../models/index';

export interface CreateTeamRequest {
    miniName?: string;
    name?: string;
    shortName?: string;
    sportId?: string;
}

export interface DeleteAlternativeNameRequest {
    teamId: string;
    name?: string;
}

export interface DeleteTeamRequest {
    teamId: string;
}

export interface FilterTeamsRequest {
    filter?: string;
}

export interface GetTeamByIdRequest {
    teamId: string;
}

export interface UpdateAlternativeNameRequest {
    teamId: string;
    newName?: string;
    oldName?: string;
}

export interface UpdateStandardNamesRequest {
    teamId: string;
    miniName?: string;
    name?: string;
    shortName?: string;
}

/**
 * 
 */
export class TeamsSysadminControllerApi extends runtime.BaseAPI {

    /**
     */
    async createTeamRaw(requestParameters: CreateTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Team>> {
        const queryParameters: any = {};

        if (requestParameters['miniName'] != null) {
            queryParameters['miniName'] = requestParameters['miniName'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['shortName'] != null) {
            queryParameters['shortName'] = requestParameters['shortName'];
        }

        if (requestParameters['sportId'] != null) {
            queryParameters['sportId'] = requestParameters['sportId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/teams/team/create`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamFromJSON(jsonValue));
    }

    /**
     */
    async createTeam(requestParameters: CreateTeamRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Team> {
        const response = await this.createTeamRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteAlternativeNameRaw(requestParameters: DeleteAlternativeNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult>> {
        if (requestParameters['teamId'] == null) {
            throw new runtime.RequiredError(
                'teamId',
                'Required parameter "teamId" was null or undefined when calling deleteAlternativeName().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/teams/{teamId}/delete-alternative-name`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters['teamId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResultFromJSON(jsonValue));
    }

    /**
     */
    async deleteAlternativeName(requestParameters: DeleteAlternativeNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult> {
        const response = await this.deleteAlternativeNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteTeamRaw(requestParameters: DeleteTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult>> {
        if (requestParameters['teamId'] == null) {
            throw new runtime.RequiredError(
                'teamId',
                'Required parameter "teamId" was null or undefined when calling deleteTeam().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/teams/{teamId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters['teamId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResultFromJSON(jsonValue));
    }

    /**
     */
    async deleteTeam(requestParameters: DeleteTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult> {
        const response = await this.deleteTeamRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async filterTeamsRaw(requestParameters: FilterTeamsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Team>>> {
        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/teams`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TeamFromJSON));
    }

    /**
     */
    async filterTeams(requestParameters: FilterTeamsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Team>> {
        const response = await this.filterTeamsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTeamByIdRaw(requestParameters: GetTeamByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Team>> {
        if (requestParameters['teamId'] == null) {
            throw new runtime.RequiredError(
                'teamId',
                'Required parameter "teamId" was null or undefined when calling getTeamById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/teams/{teamId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters['teamId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamFromJSON(jsonValue));
    }

    /**
     */
    async getTeamById(requestParameters: GetTeamByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Team> {
        const response = await this.getTeamByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateAlternativeNameRaw(requestParameters: UpdateAlternativeNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult>> {
        if (requestParameters['teamId'] == null) {
            throw new runtime.RequiredError(
                'teamId',
                'Required parameter "teamId" was null or undefined when calling updateAlternativeName().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['newName'] != null) {
            queryParameters['newName'] = requestParameters['newName'];
        }

        if (requestParameters['oldName'] != null) {
            queryParameters['oldName'] = requestParameters['oldName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/teams/{teamId}/update-alternative-name`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters['teamId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResultFromJSON(jsonValue));
    }

    /**
     */
    async updateAlternativeName(requestParameters: UpdateAlternativeNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult> {
        const response = await this.updateAlternativeNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateStandardNamesRaw(requestParameters: UpdateStandardNamesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult>> {
        if (requestParameters['teamId'] == null) {
            throw new runtime.RequiredError(
                'teamId',
                'Required parameter "teamId" was null or undefined when calling updateStandardNames().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['miniName'] != null) {
            queryParameters['miniName'] = requestParameters['miniName'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['shortName'] != null) {
            queryParameters['shortName'] = requestParameters['shortName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sysadm/teams/{teamId}/update-standard-names`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters['teamId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResultFromJSON(jsonValue));
    }

    /**
     */
    async updateStandardNames(requestParameters: UpdateStandardNamesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult> {
        const response = await this.updateStandardNamesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
