import { AnyAction, isType } from 'typescript-fsa';
import { UserViewTabType } from '.';
import { UserProfile } from '../../services/account';
import { BettingUser, BettingUserPublicProfile } from '../../services/betting';
import { PlaylistUser, UserSong } from '../../services/playlist';
import {
    userViewSetBetting,
    userViewSetBettingUser,
    userViewSetProfile,
    userViewSetTabName,
    userViewSetUserId,
} from './actions';

export interface UserViewState {
    accountUserProfile?: UserProfile;
    bettingUserProfile?: BettingUserPublicProfile;
    playlistUser?: PlaylistUser;
    userSongs: UserSong[];
    userId?: string;
    tabName: UserViewTabType;
    bettingUser?: BettingUser;
}

const initialUserViewState: UserViewState = {
    userSongs: [],
    tabName: 'profile',
};

export function userViewReducer(state: UserViewState = initialUserViewState, action: AnyAction): UserViewState {
    if (isType(action, userViewSetProfile)) {
        return Object.assign({}, state, { accountUserProfile: action.payload });
    } else if (isType(action, userViewSetBetting)) {
        return Object.assign({}, state, { bettingUserProfile: action.payload });
    } else if (isType(action, userViewSetUserId)) {
        return { ...state, userId: action.payload };
    } else if (isType(action, userViewSetTabName)) {
        return { ...state, tabName: action.payload };
    } else if (isType(action, userViewSetBettingUser)) {
        return { ...state, bettingUser: action.payload };
    }
    return state;
}
