import moment from 'moment';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { withBorder } from '../../reactutils/withBorder';
import { clientAdminIndexPath, clientAdminRoundWinnerPath } from '../ClientAdminApp';
import { playlistAdminPath } from '../PlaylistAdminApp';
import { getClientAdminUrl } from '../RadioEins/R1MobileFooter';
import { sysAdminWebUsagePath } from '../SysAdminApp/sysAdminPathes';
import {
    isClientAdminSelector,
    isPlaylistAdminSelector,
    isSysAdminSelector,
    systemAccessTokenSelector,
} from '../System/selectors';
import { getVersionNumber } from '../System/system';
import { ShowWhenFuntip } from '../common/ShowWhenFuntip';
import { ShowWhenRadioEins } from '../common/ShowWhenRadioEins';

const Footer: React.FC<{}> = () => {
    const isPlaylistAdmin = useSelector(isPlaylistAdminSelector);
    const isSysAdmin = useSelector(isSysAdminSelector);
    const isClientAdmin = useSelector(isClientAdminSelector);
    const accessToken = useSelector(systemAccessTokenSelector);

    return (
        <>
            <div className="bg-light border-top mt-2 align-items-center">
                <small className="mt-0 p-0 ms-3">
                    <ShowWhenRadioEins>radioeins </ShowWhenRadioEins>Community (©{moment().format('YYYY')} funtip), v
                    {getVersionNumber()}
                    {isClientAdmin && (
                        <>
                            <Link to={clientAdminRoundWinnerPath('')} className="mx-2">
                                Spieltags-Gewinner
                            </Link>
                            |
                            <Link to={getClientAdminUrl(accessToken)} className="mx-2">
                                Administration
                            </Link>
                            |
                            <Link to={clientAdminIndexPath()} className="mx-2">
                                Client-Admin
                            </Link>
                            |
                        </>
                    )}
                    {isPlaylistAdmin && (
                        <>
                            <Link to={playlistAdminPath()} className="mx-2">
                                Playlist-Admin
                            </Link>
                            |
                        </>
                    )}
                    {isSysAdmin && (
                        <>
                            <Link to={sysAdminWebUsagePath()} className="mx-2">
                                SysAdmin
                            </Link>
                        </>
                    )}
                    <ShowWhenFuntip>
                        |
                        <Link to="/privacy" className="mx-2">
                            AGB und Datenschutz
                        </Link>
                        |
                        <Link to="/imprint" className="mx-2">
                            Impressum
                        </Link>
                    </ShowWhenFuntip>
                </small>
            </div>
        </>
    );
};

export default withBorder()(Footer);
