import { useMutation } from '@tanstack/react-query';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Alert,
    Button,
    Form,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';
import '../../../../custom.scss';
import { isValidEmail } from '../../../../utils';
import { Section, SectionHeading } from '../../../common/Section';
import { systemSetPopupMessage } from '../../../System/actions';
import { getProfile2Api } from '../../../System/systemConfig';
import { accountUserSettingsProfileSettingsSelector } from '../selectors';

export type EmailChangeState = 'ok' | 'error' | undefined;

export const ChangeEmail: React.FC<{}> = () => {
    const profileSettings = useSelector(accountUserSettingsProfileSettingsSelector);

    const [showDialog, setShowDialog] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [emailToConfirm, setEmailToConfirm] = React.useState('');

    React.useEffect(() => {
        setEmail(profileSettings.email ? profileSettings.email : '');
        setEmailToConfirm(profileSettings.emailToConfirm ? profileSettings.emailToConfirm : '');
    }, [profileSettings]);

    return (
        <Section id="change-email">
            <SectionHeading>Email-Adresse ändern ({profileSettings.email})</SectionHeading>
            <EmailConfirmationInfo />
            <Form>
                <FormGroup>
                    <Label>hinterlegte Email-Adresse</Label>
                    <Input readOnly={true} invalid={!profileSettings.emailValid} value={email} disabled={true} />
                    <FormFeedback>
                        Die Email-Adresse ist nicht gültig. Bitte teilen Sie uns eine korrekte Email-Adresse mit.
                    </FormFeedback>
                </FormGroup>
                {profileSettings.emailToConfirm && (
                    <FormGroup>
                        <Label>neue, unbestätigte Email-Adresse</Label>
                        <Input readOnly={true} value={emailToConfirm} />
                    </FormGroup>
                )}
            </Form>
            <Button color="success" onClick={() => setShowDialog(true)}>
                Email-Adresse ändern
            </Button>
            <ChangeEmailModalDialog isOpen={showDialog} close={() => setShowDialog(false)} />
        </Section>
    );
};

export const EmailConfirmationInfo: React.FC<{}> = () => {
    const profileSettings = useSelector(accountUserSettingsProfileSettingsSelector);

    const dispatch = useDispatch();

    const resendEmailConfirmationMutation = useMutation({
        mutationFn: () => getProfile2Api().resendEmailConfirmation(),
    });

    const onResendEmailConfirmation = () => {
        resendEmailConfirmationMutation.mutate(undefined, {
            onSuccess: () => {
                dispatch(systemSetPopupMessage('Bestätigungslink wurde erneut versendet.'));
            },
            onError: (error) => {
                dispatch(systemSetPopupMessage('Fehler beim Versenden des Bestätigungslinks. ' + error));
            }
        });
    };

    if (profileSettings.emailToConfirm) {
        return (
            <Alert color="success">
                <h4>Unbestätigte Email-Adresse</h4>
                <p>
                    Sie haben die Email-Adresse auf '{profileSettings.emailToConfirm}' geändert ... aber diese noch
                    nicht über den Bestätigungslink aktiviert.
                </p>
                <hr />
                <Button color="warning" onClick={onResendEmailConfirmation}>
                    Bestätigungslink erneut versenden
                </Button>
            </Alert>
        );
    } else {
        return null;
    }
};

interface ModalProps {
    isOpen: boolean;
    close: () => void;
}

export const ChangeEmailModalDialog: React.FC<ModalProps> = (props) => {
    const [newEmail, setNewEmail] = React.useState('');
    const [newEmail2, setNewEmail2] = React.useState('');

    const [errorMessage, setErrorMessage] = React.useState('');

    const dispatch = useDispatch();

    const profileApi = getProfile2Api();

    const updateEmailMutation = useMutation({
        mutationFn: () => profileApi.updateEmail({emailAddress: newEmail}),
    });

    const onUpdate = () => {
        updateEmailMutation.mutate(undefined, {
            onSuccess: () => {
                setErrorMessage('');
                setNewEmail('');
                setNewEmail2('');
                dispatch(
                    systemSetPopupMessage(
                        'Die neue Email-Adresse wurde erfolgreich gespeichert. Sie erhalten einen ' +
                            'Bestätigungslink per Email. Diesen müssen Sie anklicken, um die Änderung ' +
                            'der Email-Adresse abzuschließen.',
                    ),
                );
                props.close();
            },
            onError: (error) => {
                dispatch(systemSetPopupMessage('Fehler beim Ändern der Email-Adresse. ' + error));
            },
        });
    };

    React.useEffect(() => {
        if (newEmail !== newEmail2 || !isValidEmail(newEmail)) {
            setErrorMessage(`Ungültige Email-Adresse: "${newEmail}" oder die Email-Adressen sind nicht identisch.`);
        } else {
            setErrorMessage('');
        }
    }, [newEmail, newEmail2]);

    return (
        <Modal isOpen={props.isOpen} toggle={props.close}>
            <ModalHeader toggle={props.close}>Email-Adresse ändern</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label>neue Email-Adresse</Label>
                    <Input
                        type="email"
                        name="newEmail"
                        placeholder="neue Email-Adressse"
                        value={newEmail}
                        onChange={(e) => setNewEmail(e.currentTarget.value)}
                        valid={errorMessage === ''}
                        invalid={errorMessage !== ''}
                    />
                    <FormFeedback>{errorMessage}</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label>neue Email-Adresse wiederholen</Label>
                    <Input
                        type="email"
                        name="newEmail2"
                        placeholder="neue Email-Adressse wiederholen"
                        value={newEmail2}
                        onChange={(e) => setNewEmail2(e.currentTarget.value)}
                        valid={errorMessage === ''}
                        invalid={errorMessage !== ''}
                    />
                    <FormFeedback>{errorMessage}</FormFeedback>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button onClick={props.close}>Abbrechen</Button>
                <Button color="success" onClick={onUpdate} disabled={errorMessage !== ''}>
                    Email-Adresse ändern
                </Button>
            </ModalFooter>
        </Modal>
    );
};
