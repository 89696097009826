import moment from 'moment';
import React, { FC, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button } from 'reactstrap';
import { UserProfileSettings } from '../../../services/account';
import { isValidEmail } from '../../../utils';
import { accountUserSettingDismissInvalidEmailTeaser } from '../AccountUserSettings/actions';
import { ChangeEmailModalDialog } from '../AccountUserSettings/ChangeEmail';
import { accountUserSettingsProfileSettingsSelector } from '../AccountUserSettings/selectors';

export const showInvalidEmailTeaser = (profileSettings: UserProfileSettings): boolean => {
    return (
        profileSettings &&
        !profileSettings.emailValid &&
        !isValidEmail(profileSettings.emailToConfirm || '') &&
        (!profileSettings.nextInvalidEmailTeaser || moment(profileSettings.nextInvalidEmailTeaser).isBefore())
    );
};

export const InvalidEmailTeaser: FC<{}> = () => {
    const profileSettings = useSelector(accountUserSettingsProfileSettingsSelector);

    const dispatch = useDispatch();

    const [showDialog, setShowDialog] = useState(false);

    const showTeaser = useCallback(() => {
        return showInvalidEmailTeaser(profileSettings);
    }, [profileSettings]);

    if (showTeaser()) {
        return (
            <>
                <Alert className="mt-2">
                    <h3>Ungültige Email-Adresse</h3>
                    <p>
                        Sie haben keine gültige Email-Adresse hinterlegt. Wir können deshalb mit Ihnen keinen Kontakt
                        aufnehmen, falls Sie Preise gewonnen haben. Bitte vervollständigen Sie ihr Profil mit einer
                        gültigen Email-Adresse.
                    </p>
                    <hr />
                    <Button color="warning" className="my-1" onClick={() => setShowDialog(true)}>
                        Email ändern
                    </Button>
                    <Button
                        className="my-1 ms-2"
                        onClick={() => dispatch(accountUserSettingDismissInvalidEmailTeaser())}
                    >
                        Nein, danke.
                    </Button>
                </Alert>
                <ChangeEmailModalDialog isOpen={showDialog} close={() => setShowDialog(false)} />
            </>
        );
    } else {
        return null;
    }
};
