import { GlobalState } from '../../actions';

const userMailSelector = (state: GlobalState) => state.userMail;

export const userMailEntitySelector = (state: GlobalState) => userMailSelector(state).mails;
export const userMailEntityCountSelector = (state: GlobalState) => userMailSelector(state).mailCount;

export const userMailSlotSizeSelector = (state: GlobalState) => userMailSelector(state).slotSize;
export const userMailCurrentPosSelector = (state: GlobalState) => userMailSelector(state).currentPos;
export const userMailReceiverNameValidSelector = (state: GlobalState) => userMailSelector(state).receiverNameValid;

