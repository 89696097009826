import * as React from 'react';
import { ButtonWithMultipleConfirmations } from '../ButtonWithMultipleConfirmations';
import { ButtonProps } from 'reactstrap';

interface Props extends ButtonProps {
    title: string;
    message: string;

    onOK: () => void;
}

interface State {
    showConfirmationDialog: number;
}

export class ButtonWithConfirmation extends React.Component<Props, State> {
    render() {
        return (
            <ButtonWithMultipleConfirmations
                {...this.props}
                messages={[this.props.message]}
                onOK={this.props.onOK}
                title={this.props.title}
            >
                {this.props.children}
            </ButtonWithMultipleConfirmations>
        );
    }
}

