import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'reactstrap';
import { UserGroupAdminLink } from '../../../links/UserGroupAdminLink';
import UserLink from '../../../links/UserLink';
import { BettingUser, GroupInternalHighscore, TournamentRoundGroupMember } from '../../../services/betting';
import { LoadingIndicatorSpinner } from '../../common/LoadingIndicatorSpinner';
import { loadGroupInternalHighscores } from '../../GroupInternalHighscores/actions';
import { gihsEntitySelector } from '../../GroupInternalHighscores/selectors';

interface Props {
    ugId: string;
    trId: string;
    mainUserId: string;
    maxUser: number;
    filterUserWithoutBets: boolean;
}

export const GroupInternalHighscoresTop5: FC<Props> = props => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(loadGroupInternalHighscores({ ugId: props.ugId, trId: props.trId }));
    }, [dispatch, props.ugId, props.trId]);

    const highscores = useSelector(gihsEntitySelector);

    const renderTrgm = (trgm: TournamentRoundGroupMember, highscores: GroupInternalHighscore, index: number) => {
        const user: BettingUser = highscores.users[index];
        if (!user) {
            return null;
        }
        if (
            (user && (String(user.id) === props.mainUserId)) ||
            (props.maxUser === 0 && (trgm.betCount > 0 || props.filterUserWithoutBets)) ||
            (props.maxUser > index + 1 && (trgm.betCount > 0 || props.filterUserWithoutBets)) ||
            (trgm.betCount > 0 && (props.maxUser === 0 || props.maxUser > index + 1)) ||
            (props.filterUserWithoutBets === false && (props.maxUser === 0 || props.maxUser > index + 1))
        ) {
            return (
                <tr key={trgm.id}>
                    <td>{trgm.rank}</td>
                    <td>
                        <UserLink id={String(user.id)} name={user.name}>
                            <span className={props.mainUserId === String(user.id) ? 'font-weight-bold' : ''}>
                                {user.name}
                            </span>
                        </UserLink>
                    </td>
                    <td>{trgm.points}</td>
                </tr>
            );
        } else {
            return null;
        }
    };

    if (highscores) {
        return (
            <>
                <h5>
                    Team{' '}
                    <UserGroupAdminLink ugId={highscores.userGroup.id}>{highscores.userGroup.name}</UserGroupAdminLink>
                </h5>
                <Table>
                    <thead>
                        <tr>
                            <td>Pl.</td>
                            <td>Mitspieler</td>
                            <td>Pkt.</td>
                        </tr>
                    </thead>
                    <tbody>
                        {highscores.tournamentRoundGroupMembers.map((trgm, index) =>
                            renderTrgm(trgm, highscores, index)
                        )}
                    </tbody>
                </Table>
            </>
        );
    } else {
        return (
            <>
                <LoadingIndicatorSpinner />
            </>
        );
    }
};
