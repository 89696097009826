import actionCreatorFactory from 'typescript-fsa';
import { HighscoreStrategyDescriptor } from '../../../services/betting';

const actionCreator = actionCreatorFactory();

export const HSD_LOAD = 'HSD_LOAD';
export const hsdLoad = actionCreator<string>(HSD_LOAD);

export const HSD_SET = 'HSD_SET';
export const hsdSet = actionCreator<HighscoreStrategyDescriptor>(HSD_SET);

export const HSD_SET_UG_NAME = 'HSD_SET_UG_NAME';
export const hsdSetUgName = actionCreator<string>(HSD_SET_UG_NAME);

export const HSD_SET_UG_ID = 'HSD_SET_UG_ID';
export const hsdSetUgId = actionCreator<string>(HSD_SET_UG_ID);