import { faBackward, faForward, faStop } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from '@lagunovsky/redux-react-router';
import moment from 'moment';
import * as React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import { GameListFilterType, gamesListPath } from '..';
import { LoadingIndicatorContent } from '../../common/LoadingIndicatorSpinner';
import { getGameApi2, getSystemConfig } from '../../System/systemConfig';

interface Props {
    date: string;
    filter: GameListFilterType;
}

export const TODAY_STR = 'today';

const isToday = (s: string) => s === moment().format('YYYYMMDD');

export const paramDateToQueryDate = (s: string) => {
    if (s === TODAY_STR) {
        return moment().format('YYYYMMDD');
    } else {
        return s;
    }
};

export const queryDateToParamDate = (s: string) => {
    if (isToday(s)) {
        return TODAY_STR;
    } else {
        return s;
    }
};

export const GamesListPager: React.FC<Props> = (props) => {
    const [nextDay, setNextDay] = React.useState<string>();
    const [prevDay, setPrevDay] = React.useState<string>();

    const dispatch = useDispatch();

    const nextFilter = (): GameListFilterType => {
        if (props.filter === 'live') {
            return 'all';
        }
        return props.filter;
    };

    const previousDayWithGamesQuery = useQuery({
        queryKey: ['getPreviousDayWithGames', props.date],
        queryFn: () =>
            getGameApi2().getPreviousDayWithGames({
                tenant: getSystemConfig().clientName,
                day: paramDateToQueryDate(props.date),
            }),
    });

    React.useEffect(() => {
        if (previousDayWithGamesQuery.data) {
            setPrevDay(moment(previousDayWithGamesQuery.data, ['YYYYMMDD']).format('DD.MM.YY'));
        }
    }, [previousDayWithGamesQuery.data]);

    const nextDayWithGamesQuery = useQuery({
        queryKey: ['getNextDayWithGames', props.date],
        queryFn: () =>
            getGameApi2().getNextDayWithGames({
                tenant: getSystemConfig().clientName,
                day: paramDateToQueryDate(props.date),
            }),
    });

    React.useEffect(() => {
        if (nextDayWithGamesQuery.data) {
            setNextDay(moment(nextDayWithGamesQuery.data, ['YYYYMMDD']).format('DD.MM.YY'));
        }
    }, [nextDayWithGamesQuery.data]);

    const isLoading = () => nextDayWithGamesQuery.isFetching || previousDayWithGamesQuery.isFetching;

    const today = moment().format('DD.MM.YY');
    const todayEnabled = moment(props.date, ['YYYYMMDD']).format('DD.MM.YY') !== today;

    const onNext = () => {
        if (nextDay) {
            const forwardTo = moment(nextDay, ['DD.MM.YY']).format('YYYYMMDD');
            dispatch(push(gamesListPath(queryDateToParamDate(forwardTo), nextFilter())));
        }
    };
    const onPrevious = () => {
        if (prevDay) {
            const forwardTo = moment(prevDay, ['DD.MM.YY']).format('YYYYMMDD');
            dispatch(push(gamesListPath(queryDateToParamDate(forwardTo), nextFilter())));
        }
    };

    const onToday = () => {
        dispatch(push(gamesListPath(TODAY_STR, !props.filter ? 'all' : nextFilter())));
    };

    return (
        <div className="d-flex justify-content-center">
            <Button onClick={onPrevious} size="sm" className="mx-3 mb-2">
                <FontAwesomeIcon icon={faBackward} />
                <br />
                <LoadingIndicatorContent isLoading={isLoading()}>{prevDay}</LoadingIndicatorContent>
            </Button>
            <Button
                onClick={onToday}
                size="sm"
                className="mx-3 mb-2"
                disabled={!todayEnabled}
                color="success"
                outline={true}
            >
                <FontAwesomeIcon icon={faStop} />
                <br />
                Heute
            </Button>
            <Button onClick={onNext} size="sm" className="mx-3 mb-2">
                <FontAwesomeIcon icon={faForward} />
                <br />
                <LoadingIndicatorContent isLoading={isLoading()}>{nextDay}</LoadingIndicatorContent>
            </Button>
        </div>
    );
};
