import actionCreatorFactory from 'typescript-fsa';
import { UserProfile } from '../../../services/account';

const actionCreator = actionCreatorFactory();

export const USER_ADMIN_LOAD = 'USER_ADMIN_LOAD';
export const userAdminLoad = actionCreator<string>(USER_ADMIN_LOAD);

export const USER_ADMIN_SET = 'USER_ADMIN_SET';
export const userAdminSet = actionCreator<UserProfile>(USER_ADMIN_SET);

export const USER_ADMIN_TOGGLE_R1_MODERATOR = 'USER_ADMIN_TOGGLE_R1_MODERATOR';
export const userAdminToggleR1Moderator = actionCreator<string>(USER_ADMIN_TOGGLE_R1_MODERATOR);

export const USER_ADMIN_MERGE_BETS = 'USER_ADMIN_MERGE_BETS';
export const userAdminMergeBets = actionCreator<{destUserId: string, srcUserName: string}>(USER_ADMIN_MERGE_BETS);

export const USER_ADMIN_SET_MERGE_BETS_COUNT = 'USER_ADMIN_SET_MERGE_BETS_COUNT';
export const userAdminSetMergeBetsCount = actionCreator<string>(USER_ADMIN_SET_MERGE_BETS_COUNT);

