import * as R from 'ramda';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'reactstrap';
import UserLink from '../../links/UserLink';
import { PaginationParams, PagingComponent } from '../../reactutils/withPagination';
import { SearchComponent, SearchProps } from '../../reactutils/withSearch';
import { withTitle } from '../../reactutils/withTitle';
import { pageFirstUsers, pageLastUsers, pageNextUsers, pagePriorUsers, searchUsers } from './actions';
import {
    userListCountSelector,
    userListCurrentPosSelector,
    userListEntitiesSelector,
    userListPageSizeSelector,
    userListSearchStringSelector,
} from './selectors';
import { SubMenuOverallHihgscores } from '../BettingApp/SubMenuOverallHighscores';
import { withNavLocation } from '../../reactutils/withNavLocation';
import { withBorder } from '../../reactutils/withBorder';
import { isRadioEins } from '../System/systemConfig';
import { BettingUser } from '../../services/betting';
import { useDispatch } from 'react-redux';

const UserListComponent = () => {
    const selectedEntities = useSelector(userListEntitiesSelector);

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(searchUsers(''));
    }, [dispatch]);

    function renderUser(u: BettingUser): JSX.Element {
        return (
            <tr key={u.id}>
                <td>
                    <UserLink id={String(u.id)} name={u.name} showTrophies={true} showImage={true} />
                </td>
            </tr>
        );
    }

    return (
        <>
            <SubMenuOverallHihgscores activeSubMenu="user-directory" />
            <div className="d-flex align-items-center justify-content-between flex-wrap">
                <SearchComponent
                    searchResultCountSelector={(state: any) => searchParams.searchResultCountSelector(state)}
                    searchStringSelector={(state: any) => searchParams.searchStringSelector(state)}
                    onSearch={(s: string) => searchParams.onSearch(s)}
                    placeholder="Tipper Suche"
                />
                <PagingComponent
                    onForward={() => paginationParams.onForward()}
                    onFastForward={() => paginationParams.onFastForward()}
                    onBackward={() => paginationParams.onBackward()}
                    onFastBackward={() => paginationParams.onFastBackward()}
                    searchResultCount={paginationParams.searchResultCount}
                    searchResultCurrentPos={paginationParams.searchResultCurrentPos}
                    searchResultPageSize={paginationParams.searchResultPageSize}
                />
            </div>
            <span>
                <Table className="mt-3">
                    <thead>
                        <tr>
                            <th>Name</th>
                        </tr>
                    </thead>
                    <tbody>{selectedEntities.map((u) => renderUser(u))}</tbody>
                </Table>
            </span>
        </>
    );
};

const paginationParams: PaginationParams = {
    searchResultCount: (state: any) => userListCountSelector(state),
    searchResultCurrentPos: (state: any) => userListCurrentPosSelector(state),
    searchResultPageSize: (state: any) => userListPageSizeSelector(state),
    onBackward: () => pagePriorUsers(),
    onFastBackward: () => pageFirstUsers(),
    onFastForward: () => pageLastUsers(),
    onForward: () => pageNextUsers(),
};

const searchParams: SearchProps = {
    searchResultCountSelector: (state: any) => userListCountSelector(state),
    searchStringSelector: (state: any) => userListSearchStringSelector(state),
    onSearch: (s: string) => searchUsers(s),
};

export const UserListScreen = R.compose(
    withBorder(),
    withNavLocation('user-list'),
    withTitle(isRadioEins() ? 'Hörerverzeichnis' : 'Tipperverzeichnis'),
)(UserListComponent);

export const userListPath = () => `/b/user-list`;
