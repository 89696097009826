/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeviceToken
 */
export interface DeviceToken {
    /**
     * 
     * @type {string}
     * @memberof DeviceToken
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceToken
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceToken
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceToken
     */
    platform: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceToken
     */
    deviceName?: string;
    /**
     * 
     * @type {Date}
     * @memberof DeviceToken
     */
    creationTime: Date;
    /**
     * 
     * @type {Date}
     * @memberof DeviceToken
     */
    lastModified?: Date;
    /**
     * 
     * @type {string}
     * @memberof DeviceToken
     */
    endpointArn?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceToken
     */
    state: string;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceToken
     */
    enabled: boolean;
}

/**
 * Check if a given object implements the DeviceToken interface.
 */
export function instanceOfDeviceToken(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "token" in value;
    isInstance = isInstance && "platform" in value;
    isInstance = isInstance && "creationTime" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "enabled" in value;

    return isInstance;
}

export function DeviceTokenFromJSON(json: any): DeviceToken {
    return DeviceTokenFromJSONTyped(json, false);
}

export function DeviceTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeviceToken {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'userId': json['userId'],
        'token': json['token'],
        'platform': json['platform'],
        'deviceName': !exists(json, 'deviceName') ? undefined : json['deviceName'],
        'creationTime': (new Date(json['creationTime'])),
        'lastModified': !exists(json, 'lastModified') ? undefined : (new Date(json['lastModified'])),
        'endpointArn': !exists(json, 'endpointArn') ? undefined : json['endpointArn'],
        'state': json['state'],
        'enabled': json['enabled'],
    };
}

export function DeviceTokenToJSON(value?: DeviceToken | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'userId': value.userId,
        'token': value.token,
        'platform': value.platform,
        'deviceName': value.deviceName,
        'creationTime': (value.creationTime.toISOString()),
        'lastModified': value.lastModified === undefined ? undefined : (value.lastModified.toISOString()),
        'endpointArn': value.endpointArn,
        'state': value.state,
        'enabled': value.enabled,
    };
}

