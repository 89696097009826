import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { GroupMember } from '../../../services/betting';
import { fetchWrapperFunc, fetchWrapperFuncNoLoading } from '../../../utils';
import { LOGIN_ACTION } from '../../System/actions';
import { getBettingUserApi2, getHighscoreApi2, getUserGroupApi2 } from '../../System/systemConfig';
import { groupHighscoresLoadDefaultRound } from '../../UserGroupHighscores/actions';
import {
    bettingProfileLoadFavoriteTeams,
    bettingProfileLoadHighscoreInfos,
    bettingProfileLoadUserGroups,
    bettingProfileSetDefaultGroup,
    bettingProfileSetFavoriteTeams,
    bettingProfileSetHighscoreInfos,
    bettingProfileSetMessage,
    bettingProfileSetMyGroupMember,
    bettingProfileSetMyProfile,
    BETTING_PROFILE_ACCEPT_INVITATION,
    BETTING_PROFILE_ADD_FAVORITE_TEAM,
    BETTING_PROFILE_CREATE_USER_GROUP,
    BETTING_PROFILE_DELETE_FAVORITE_TEAM,
    BETTING_PROFILE_LEAVE_USER_GROUP,
    BETTING_PROFILE_LOAD_BETTING_PROFILE,
    BETTING_PROFILE_LOAD_DEFAULT_GROUP,
    BETTING_PROFILE_LOAD_FAVORITE_TEAMS,
    BETTING_PROFILE_LOAD_HIGHSCORE_INFOS,
    BETTING_PROFILE_LOAD_USER_GROUPS,
    BETTING_PROFILE_REFUSE_INVITATION,
    BETTING_PROFILE_SET_DEFAULT_USER_GROUP,
    BETTING_PROFILE_UPDATE_BETTING_PUBLIC,
} from './actions';
import { bettingProfileMyGroupMembersSelector } from './selectors';

function* loadBettingProfileGenerator() {
    yield* fetchWrapperFunc(() => getBettingUserApi2().getBettingUserProfile(), [(r) => bettingProfileSetMyProfile(r)]);
    yield put(bettingProfileLoadFavoriteTeams());
    yield put(bettingProfileLoadUserGroups());
    yield put(bettingProfileLoadHighscoreInfos());
}

function* loadFavoriteTeams() {
    yield* fetchWrapperFuncNoLoading(
        () => getBettingUserApi2().getFavoriteTeams(),
        [(r) => bettingProfileSetFavoriteTeams(r)],
    );
}

function* loadUserGroups() {
    yield* fetchWrapperFuncNoLoading(
        () => getUserGroupApi2().getMyGroupMember(),
        [(t) => bettingProfileSetMyGroupMember(t)],
    );
    const groupMembers: GroupMember[] = yield select(bettingProfileMyGroupMembersSelector);
    for (let gm of groupMembers) {
        yield put(groupHighscoresLoadDefaultRound(String(gm.userGroupId)));
    }
}

function* loadHighscoreInfos() {
    yield* fetchWrapperFuncNoLoading(
        () => getHighscoreApi2().getHighscoreInfos(),
        [(r) => bettingProfileSetHighscoreInfos(r)],
    );
}

function* deleteFavoriteTeamGenerator(action: Action<string>) {
    const teamId = action.payload;
    yield* fetchWrapperFuncNoLoading(
        () => getBettingUserApi2().deleteFavoriteTeam({ teamId: Number(teamId) }),
        [(r) => bettingProfileSetFavoriteTeams(r)],
    );
}

function* addFavoriteTeamGenerator(action: Action<string>) {
    const teamId = action.payload;
    yield* fetchWrapperFuncNoLoading(
        () => getBettingUserApi2().addFavoriteTeam({ teamId: Number(teamId) }),
        [(r) => bettingProfileSetFavoriteTeams(r)],
    );
}

function* leaveUserGroup(action: Action<number>) {
    const ugId = action.payload;
    yield* fetchWrapperFunc(() => getUserGroupApi2().leaveUserGroup({ ugId }));
    yield put(bettingProfileLoadUserGroups());
}

function* acceptInvitation(action: Action<number>) {
    const ugId = action.payload;
    yield* fetchWrapperFunc(() => getUserGroupApi2().acceptUserGroupInvitation({ ugId }));
    yield put(bettingProfileLoadUserGroups());
}

function* refuseInvitation(action: Action<number>) {
    const ugId = action.payload;
    yield* fetchWrapperFunc(() => getUserGroupApi2().refuseUserGroupInvitation({ ugId }));
    yield put(bettingProfileLoadUserGroups());
}

function* setDefaultUserGroup(action: Action<number>) {
    const ugId = action.payload;
    yield* fetchWrapperFunc(() => getUserGroupApi2().setStandardUserGroup({ ugId }));
    yield put(bettingProfileLoadUserGroups());
}

function* createUserGroup(action: Action<string>) {
    const name = action.payload;
    yield* fetchWrapperFunc(
        () => getUserGroupApi2().createUserGroup({ namne: name }),
        undefined,
        () =>
            bettingProfileSetMessage({
                message: 'Ungültiger Tippteam-Name oder Name bereits in Verwendung',
                level: 'danger',
            }),
    );
    yield put(bettingProfileLoadUserGroups());
}

function* updateBettingPublic(action: Action<boolean>) {
    yield* fetchWrapperFunc(
        () => getBettingUserApi2().updateUserBettingPublic({ value: action.payload }),
        [(r) => bettingProfileSetMyProfile(r)],
    );
}

function* loadDefaultGroup() {
    yield* fetchWrapperFunc(() => getUserGroupApi2().getDefault(), [(r) => bettingProfileSetDefaultGroup(r)]);
}

function* watchBettingProfile() {
    yield takeLatest(BETTING_PROFILE_LOAD_BETTING_PROFILE, loadBettingProfileGenerator);
    yield takeLatest(BETTING_PROFILE_LOAD_FAVORITE_TEAMS, loadFavoriteTeams);
    yield takeLatest(BETTING_PROFILE_LOAD_USER_GROUPS, loadUserGroups);
    yield takeLatest(BETTING_PROFILE_LOAD_HIGHSCORE_INFOS, loadHighscoreInfos);
    yield takeEvery(BETTING_PROFILE_DELETE_FAVORITE_TEAM, deleteFavoriteTeamGenerator);
    yield takeEvery(BETTING_PROFILE_ADD_FAVORITE_TEAM, addFavoriteTeamGenerator);
    yield takeEvery(BETTING_PROFILE_LEAVE_USER_GROUP, leaveUserGroup);
    yield takeEvery(BETTING_PROFILE_ACCEPT_INVITATION, acceptInvitation);
    yield takeEvery(BETTING_PROFILE_REFUSE_INVITATION, refuseInvitation);
    yield takeEvery(BETTING_PROFILE_SET_DEFAULT_USER_GROUP, setDefaultUserGroup);
    yield takeEvery(BETTING_PROFILE_CREATE_USER_GROUP, createUserGroup);
    yield takeEvery(BETTING_PROFILE_UPDATE_BETTING_PUBLIC, updateBettingPublic);
    yield takeEvery(LOGIN_ACTION, loadFavoriteTeams);
    yield takeLatest(BETTING_PROFILE_LOAD_DEFAULT_GROUP, loadDefaultGroup);
}

export function* bettingProfileSagas() {
    yield all([watchBettingProfile()]);
}
