import * as R from 'ramda';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { withBorder } from '../../../reactutils/withBorder';
import { withNavLocation } from '../../../reactutils/withNavLocation';
import { withTitle } from '../../../reactutils/withTitle';
import { isLoggedInSelector, userNameSelector } from '../../System/selectors';
import { userFeedbackSend } from './actions';

const UserFeedbackComponent = () => {
    const dispatch = useDispatch();

    const loggedInUserName = useSelector(userNameSelector);
    const isLoggedIn = useSelector(isLoggedInSelector);

    const [sender, setSender] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [senderErrorMsg, setSenderErrorMsg] = useState('');
    const [subjectErrorMsg, setSubjectErrorMsg] = useState('');
    const [messageErrorMsg, setMessageErrorMsg] = useState('');
    const [confirmationMsg, setConfirmationMsg] = useState('');
    const [readyToSend, setReadyToSend] = useState(false);

    const validate = useCallback(() => {
        setSubjectErrorMsg(subject === '' ? 'Bitte benennen Sie ein Thema für Ihr Feedback.' : '');
        setMessageErrorMsg(message === '' ? 'Bitte erläutern Sie Ihre Veresserungsvorschläge.' : '');
        setSenderErrorMsg(
            sender === '' ? 'Bitte geben Sie Ihre Kontaktinfos ein, z.B. Email-Adresse oder Telefonnummer.' : ''
        );

        const r = senderErrorMsg === '' && subjectErrorMsg === '' && messageErrorMsg === '';
        setReadyToSend(r);
        return r;
    }, [senderErrorMsg, subjectErrorMsg, messageErrorMsg, subject, message, sender]);

    useEffect(() => {
        setSender(loggedInUserName || '');
    }, [loggedInUserName]);

    useEffect(() => {
        validate();
    }, [validate]);

    function onSend() {
        if (validate()) {
            dispatch(userFeedbackSend({ sender, subject, message }));
            setSubject('');
            setMessage('');
            setConfirmationMsg('Vielen Dank für Ihr Feedback.');
        }
    }

    return (
        <>
            <Alert color="warning" isOpen={confirmationMsg !== ''} toggle={() => setConfirmationMsg('')}>
                {confirmationMsg}
            </Alert>
            <Alert color="info" isOpen={confirmationMsg === ''}>
                Sie haben Anregungen, Ideen oder Kritik? Sehr gerne!
                <br />
                Bitte schicken Sie uns ihre Verbesserungsvorschläge über das folgende Formular.
            </Alert>
            <Form>
                <FormGroup>
                    <Label>Absender</Label>
                    <Input
                        value={sender}
                        onChange={e => setSender(e.currentTarget.value)}
                        readOnly={isLoggedIn}
                        valid={senderErrorMsg === ''}
                        invalid={senderErrorMsg !== ''}
                    />
                    <FormFeedback>{senderErrorMsg}</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label>Betreff</Label>
                    <Input
                        value={subject}
                        onChange={e => setSubject(e.currentTarget.value)}
                        valid={subjectErrorMsg === ''}
                        invalid={subjectErrorMsg !== ''}
                    />
                    <FormFeedback>{subjectErrorMsg}</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label>Feedback</Label>
                    <Input
                        type="textarea"
                        value={message}
                        onChange={e => setMessage(e.currentTarget.value)}
                        valid={messageErrorMsg === ''}
                        invalid={messageErrorMsg !== ''}
                    />
                    <FormFeedback>{messageErrorMsg}</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Button color="warning" onClick={onSend} disabled={!readyToSend}>
                        Feedback senden
                    </Button>
                </FormGroup>
            </Form>
        </>
    );
};

export const feedbackPath = () => `/b/feedback`;

export const UserFeedback = R.compose(
    withBorder(),
    withTitle('Feedback'),
    withNavLocation('profile')
)(UserFeedbackComponent);
