import actionCreatorFactory from 'typescript-fsa';
import { Blog } from '../../services/image';

const actionCreator = actionCreatorFactory();

export const BLOG_LOAD_LIST = 'BLOG_LOAD_LIST';
export const blogLoadList = actionCreator(BLOG_LOAD_LIST);

export const BLOG_SET_LIST = 'BLOG_SET_LIST';
export const blogSetList = actionCreator<Blog[]>(BLOG_SET_LIST);

export const BLOG_CREATE = 'BLOG_CREATE';
export const blogCreate = actionCreator<string>(BLOG_CREATE);

export const BLOG_DELETE = 'BLOG_DELETE';
export const blogDelete = actionCreator<string>(BLOG_DELETE);

export const BLOG_LOAD = 'BLOG_LOAD';
export const blogLoad = actionCreator<string>(BLOG_LOAD);

export const BLOG_LOAD_LATEST = 'BLOG_LOAD_LATEST';
export const blogLoadLatest = actionCreator(BLOG_LOAD_LATEST);

export const BLOG_SET = 'BLOG_SET';
export const blogSet = actionCreator<Blog>(BLOG_SET);

export interface BlogEntryAction {
    blogId?: string;
    blogEntryId?: string;
    title: string;
    text: string;
}

export interface BlogImageAction {
    imageId: string;
    blogEntryId: string;
}

export const BLOG_ADD_ENTRY = 'BLOG_ADD_ENTRY';
export const blogAddEntry = actionCreator<BlogEntryAction>(BLOG_ADD_ENTRY);

export const BLOG_UPDATE_ENTRY = 'BLOG_UPDATE_ENTRY';
export const blogUpdateEntry = actionCreator<BlogEntryAction>(BLOG_UPDATE_ENTRY);

export const BLOG_DELETE_ENTRY = 'BLOG_DELETE_ENTRY';
export const blogDeleteEntry = actionCreator<string>(BLOG_DELETE_ENTRY);

export const BLOG_ADD_IMAGE = 'BLOG_ADD_IMAGE';
export const blogAddImage = actionCreator<BlogImageAction>(BLOG_ADD_IMAGE);

export const BLOG_DELETE_IMAGE = 'BLOG_DELETE_IMAGE';
export const blogDeleteImage = actionCreator<BlogImageAction>(BLOG_DELETE_IMAGE);

export const BLOG_PUBLISH_ON_TWITTER = 'BLOG_PUBLISH_ON_TWITTER';
export const blogPublishOnTwitter = actionCreator<string>(BLOG_PUBLISH_ON_TWITTER);

export const BLOG_PUBLISH_ENTRY_ON_TWITTER = 'BLOG_PUBLISH_ENTRY_ON_TWITTER';
export const blogPublishEntryOnTwitter = actionCreator<string>(BLOG_PUBLISH_ENTRY_ON_TWITTER);
