import * as R from 'ramda';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Container, Row, Table } from 'reactstrap';
import UserLink from '../../../links/UserLink';
import { withBorder } from '../../../reactutils/withBorder';
import { withNavLocation } from '../../../reactutils/withNavLocation';
import { Highscore, TournamentRound } from '../../../services/betting';
import { MyMainGroupInternalHighscoresTop5 } from '../../BettingApp/MyMainGroupInternalHighscoresTop5';
import { userIdSelector } from '../../System/selectors';
import { AppFeature, hasFeature, isRadioEins } from '../../System/systemConfig';
import { LoadingIndicatorSpinner } from '../../common/LoadingIndicatorSpinner';
import { Section, SectionHeading } from '../../common/Section';
import { RadioEinsGroupHighscores } from '../RadioEinsGroupHighscores';
import { WeeklyComment } from '../WeeklyComment';
import { PortletComponent } from './PortletComponent';
import { portalLoadTopHighscores } from './actions';
import {
    portalLastHighscoresSelector,
    portalLastRoundSelector,
    portalLastUserHighscoreSelector,
    portalRootHighscoresSelector,
    portalRootRoundSelector,
    portalRootUserHighscoreSelector,
} from './selectors';

interface TopHighscoresProps {
    round: TournamentRound;
    highscores: Highscore[];
    userHighscore?: Highscore;
}

const TopHighscores: FC<TopHighscoresProps> = (props) => {
    const userIsInTop5 =
        props.highscores.find((hs) => props.userHighscore && hs.userId === props.userHighscore.userId) !== undefined;
    const renderHighscore = (hs: Highscore) => {
        const rankClass = props.userHighscore && hs.userId === props.userHighscore.userId ? 'bg-warning' : 'bg-white';

        return (
            <tr key={hs.id}>
                <td>
                    <span className={`p-2 ${rankClass}`}>{hs.rank}</span>
                </td>
                <td>
                    <UserLink id={String(hs.userId)} name={hs.userName} showTrophies={true} />
                </td>
            </tr>
        );
    };

    return (
        <>
            <h5>Pl. {props.round.longName}</h5>
            <Table>
                <thead>
                    <tr>
                        <th className="d-table-cell d-lg-none">Pl.</th>
                        <th className="d-none d-lg-table-cell">Platz</th>
                        <th className="d-table-cell d-lg-none">Mitsp.</th>
                        <th className="d-none d-lg-table-cell">Mitspieler</th>
                    </tr>
                </thead>
                <tbody>
                    {props.highscores.map((hs) => renderHighscore(hs))}
                    {!userIsInTop5 && props.userHighscore && props.userHighscore.id !== 0 && (
                        <tr>
                            <td>
                                <span className="p-2 bg-warning text-white">{props.userHighscore.rank}</span>
                            </td>
                            <td>
                                <UserLink
                                    id={String(props.userHighscore.userId)}
                                    name={props.userHighscore.userName}
                                    showTrophies={true}
                                />
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </>
    );
};

const TopRootHighscores = () => {
    const rootRound = useSelector(portalRootRoundSelector);
    const rootHighscores = useSelector(portalRootHighscoresSelector);
    const rootUserHighscore = useSelector(portalRootUserHighscoreSelector);

    if (rootRound) {
        return <TopHighscores round={rootRound} highscores={rootHighscores} userHighscore={rootUserHighscore} />;
    } else {
        return <LoadingIndicatorSpinner />;
    }
};

const TopLastHighscores = () => {
    const lastRound = useSelector(portalLastRoundSelector);
    const lastHighscores = useSelector(portalLastHighscoresSelector);
    const lastUserHighscore = useSelector(portalLastUserHighscoreSelector);

    if (lastRound) {
        return <TopHighscores round={lastRound} highscores={lastHighscores} userHighscore={lastUserHighscore} />;
    } else {
        return <LoadingIndicatorSpinner />;
    }
};

const PortalComponent = () => {
    const rootRound = useSelector(portalRootRoundSelector);
    const userId = useSelector(userIdSelector);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(portalLoadTopHighscores());
    }, [dispatch, userId]);

    const showCommentAndRssPortal = hasFeature(AppFeature.r1comment) && hasFeature(AppFeature.rssPortal);
    return (
        <div id="r1--comment-rss-portal">
            {showCommentAndRssPortal && (
                <Section>
                    <SectionHeading>Tippen und gewinnen</SectionHeading>
                    <Container fluid={true}>
                        <Row>
                            <Col sm={3} className="bg-white">
                                <WeeklyComment />
                            </Col>
                            <Col sm={9} className="p-0">
                                <div className="container-fluid bg-light p-0 pb-3">
                                    <div className="row p-0 pe-1">
                                        <div className="bg-light pe-1 col-sm-4">
                                            <PortletComponent
                                                tagLine="Preise"
                                                title="Schöne Gewinne"
                                                subTitle="Mittippen lohnt sich!"
                                                imageUrl="https://www.radioeins.de/content/rbb/rad/tippspiel/teaser/jcr:content/middleColumnList/39061/teaserList/manualteaser_1349316061/image.img.png/quality=150/rendition=original/size=100x100.png"
                                                text="Tippen Sie die Spiele der Fußball-EM, der Bundesliga, der Champions-League usw. und sichern Sie sich Schöne Preise..."
                                                linkUrl="https://mein.radioeins.de/b/prizes"
                                            />
                                        </div>
                                        <div className="bg-light pe-1 col-sm-4">
                                            <PortletComponent
                                                tagLine="Regeln"
                                                title="Wie funktioniert das Tippspiel?"
                                                subTitle="Hilfe & FAQs"
                                                imageUrl="https://www.radioeins.de/content/rbb/rad/tippspiel/teaser/jcr:content/middleColumnList/39061/teaserList/43750/image.img.png/quality=150/rendition=original/size=100x100.png"
                                                text="Melden Sie sich an und tippen Sie. Sie können zwischen verschie­denen Tur­nieren wählen und haben bei einigen die Chance, tolle Preise zu gewinnen."
                                                linkUrl="/b/rules"
                                            />
                                        </div>
                                        <div className="bg-light pe-1 col-sm-4">
                                            <PortletComponent
                                                tagLine="Nichts verpassen"
                                                title="Liveticker"
                                                subTitle="immer auf Ballhöhe!"
                                                imageUrl="https://www.radioeins.de/content/rbb/rad/tippspiel/teaser/jcr:content/middleColumnList/39061/teaserList/manualteaser/image.img.png/quality=150/rendition=original/size=100x100.png"
                                                text="Verfolgen Sie alle Spiele aller Ligen bei uns im Liveticker: Klicken Sie einfach auf den Button oben..."
                                                linkUrl="/b/live-ticker"
                                            />
                                        </div>
                                    </div>
                                    <div className="row p-0 pe-1 pt-3">
                                        <div className="bg-light pe-1 col-sm-4">
                                            <PortletComponent
                                                tagLine="sa, 14.00 - 18.00 Uhr"
                                                title="Arena Liga Live"
                                                subTitle="mit ARD-Schlusskonferenz"
                                                imageUrl="https://www.radioeins.de/content/rbb/rad/tippspiel/teaser/jcr:content/middleColumnList/39061/teaserList/manualteaser_0/image.img.png/quality=150/rendition=original/size=100x100.png"
                                                text="Die radioeins Fußballshow ist der Samstags-Klassiker: Musik und Fußball – vier Stunden lang - natürlich immer auf Ballhöhe. radioeins schaltet live"
                                                linkUrl="https://www.radioeins.de/programm/sendungen/arena/index.html"
                                            />
                                        </div>
                                        <div className="bg-light pe-1 col-sm-4">
                                            <PortletComponent
                                                tagLine="ARD-Text"
                                                title="Vielen Dank"
                                                subTitle=""
                                                imageUrl="https://www.radioeins.de/content/rbb/rad/tippspiel/teaser/jcr:content/middleColumnList/39061/teaserList/14778/image.img.png/quality=150/rendition=original/size=100x100.png"
                                                text="Alle Ergeb­nisse werden uns freund­licher­weise vom ARD-​Text zur Verfügung gestellt"
                                                linkUrl="http://www.ard-text.de/index.php?page=200"
                                            />
                                        </div>
                                        <div className="bg-light pe-1 col-sm-4">
                                            <PortletComponent
                                                tagLine="radioeins-App"
                                                title="Fußball-Tippspiel für unterwegs"
                                                subTitle=""
                                                imageUrl="https://www.radioeins.de/content/rbb/rad/tippspiel/teaser/jcr:content/middleColumnList/39061/teaserList/70793/image.img.png/quality=150/rendition=original/size=100x100.png"
                                                text="Das Fußball-Tippspiel auf dem Smartphone und Tablet. Jetzt können Sie Ihre Tipps auch über die radioeins-App abgeben und jederzeit die aktuelle Wer"
                                                linkUrl="https://www.radioeins.de/faqs/apps.html"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Section>
            )}
            <Section>
                <SectionHeading>Tipper-Überblick</SectionHeading>
                <Container fluid={true}>
                    <Row className="bg-light">
                        <Col className="bg-white p-2 m-2">
                            <TopRootHighscores />
                        </Col>
                        <Col className="bg-white p-2 m-2">
                            <TopLastHighscores />
                        </Col>
                        <Col className="bg-white p-2 m-2">
                            {isRadioEins() && rootRound && <RadioEinsGroupHighscores trId={String(rootRound.id)} />}
                            {!isRadioEins() && rootRound && (
                                <MyMainGroupInternalHighscoresTop5 trId={String(rootRound.id)} />
                            )}
                        </Col>
                    </Row>
                </Container>
            </Section>
        </div>
    );
};

export const Portal = R.compose(withBorder(), withNavLocation('betting'))(PortalComponent);
