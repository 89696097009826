import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import { useDispatch } from 'react-redux';
import { Button, Col, Container, Input, Row } from 'reactstrap';
import { UserGroupHighscoreDescriptorDialogScreen } from '../../../components/BettingApp/UserGroupHighscoreDescriptorDialog';
import { ButtonWithConfirmation } from '../../../components/common/ButtonWithConfirmation';
import { FileUpload } from '../../../components/common/FileUpload';
import { Section, SectionHeading } from '../../../components/common/Section';
import { UserImage } from '../../../components/UserImage';
import { UserGroupDetail } from '../../../services/betting';
import { userGroupAssignImage, userGroupDeleteImage } from '../actions';

interface Props {
    userGroupDetail: UserGroupDetail;
    isSysAdmin: boolean;
    onDescriptionSave: (ugId: string, description: string) => void;
    onShowHighscoreDescriptor: (ugId: string) => void;
}

export const UserGroupDetailComponent: React.FC<Props> = props => {
    const dispatch = useDispatch();

    const [editMode, setEditMode] = React.useState(false);
    const [editImageMode, setEditImageMode] = React.useState(false);
    const [description, setDescription] = React.useState(props.userGroupDetail.description);
    const [showHighscoreDescriptor, setShowHighscoreDescriptor] = React.useState(false);

    React.useEffect(() => {
        setDescription(props.userGroupDetail.description);
    }, [props.userGroupDetail]);

    const isEditable = () => {
        return props.isSysAdmin || props.userGroupDetail.loginUserIsGroupAdmin;
    };

    const onShowHighscoreDescriptor = () => {
        props.onShowHighscoreDescriptor(String(props.userGroupDetail.id));
    };

    const onToggleEditHighscoreDescriptor = () => {
        setShowHighscoreDescriptor(!showHighscoreDescriptor);
    };

    const onEditClick = () => {
        setEditMode(true);
    };

    const onDescriptionSaveClick = () => {
        if (description !== undefined) {
            props.onDescriptionSave(String(props.userGroupDetail.id), description);
        }
        setEditMode(false);
    };

    const removeGroupImage = (ugId: string) => {
        dispatch(userGroupDeleteImage(ugId));
        setEditImageMode(false);
    };

    const assignNewGroupImage = (ugId: string, imageId?: string) => {
        if (imageId) {
            dispatch(userGroupAssignImage({ ugId, imageId }));
            setEditImageMode(false);
        }
    };

    return (
        <>
            {showHighscoreDescriptor && (
                <UserGroupHighscoreDescriptorDialogScreen
                    ugId={String(props.userGroupDetail.id)}
                    readonly={editMode}
                    onClose={onToggleEditHighscoreDescriptor}
                />
            )}

            <Section>
                <SectionHeading>Teambild</SectionHeading>
                <UserImage imageId={props.userGroupDetail.imageId} className='me-2'/>
                {!editImageMode && isEditable() && (
                    <Button color="success" onClick={() => setEditImageMode(true)} className="mt-2">
                        Bild ändern
                    </Button>
                )}
                {editImageMode && (
                    <FileUpload
                        imageUploaded={imageId => assignNewGroupImage(String(props.userGroupDetail.id), imageId)}
                    />
                )}
                {editImageMode && (
                    <>
                        <ButtonWithConfirmation
                            title="Teambild löschen"
                            message="Sind Sie sicher, dass Sie das aktuelle Teambild löschen wollen? Diese Aktion kann nicht rückgängig gemacht werden?"
                            onOK={() => removeGroupImage(String(props.userGroupDetail.id))}
                            color="danger"
                            className="mt-2"
                        >
                            Teambild löschen
                        </ButtonWithConfirmation>
                        <Button color="secondary" onClick={() => setEditImageMode(false)} className="ms-2 mt-2">
                            Abbrechen
                        </Button>
                    </>
                )}
            </Section>
            <Container className={'members'}>
                <Row className="m-1">
                    <Col></Col>
                </Row>
                <Row className="m-1">
                    <Col></Col>
                </Row>
            </Container>

            <Section>
                <SectionHeading>Team-Statement</SectionHeading>
                {editMode && (
                    <>
                        <Input
                            name="description"
                            type="textarea"
                            value={description}
                            onChange={e => setDescription(e.currentTarget.value)}
                        />
                        <Button className="mt-2" onClick={onDescriptionSaveClick}>
                            Speichern
                        </Button>
                    </>
                )}
                {!editMode && <ReactMarkdown>{description || ''}</ReactMarkdown>}
                {isEditable() && !editMode && (
                    <Button className="mt-2" onClick={onEditClick}>
                        Bearbeiten
                    </Button>
                )}
            </Section>

            <Section>
                <SectionHeading>Punktemodus</SectionHeading>
                <p>{props.userGroupDetail.highscoreStrategyDescriptorName || 'Standard'}</p>
                {!isEditable() && props.userGroupDetail.highscoreStrategyDescriptorId && (
                    <Button onClick={onShowHighscoreDescriptor}>Punktemodus einsehen</Button>
                )}
                {isEditable() && (
                    <Button color="warning" onClick={onToggleEditHighscoreDescriptor}>
                        Punktemodus bearbeiten
                    </Button>
                )}
            </Section>
        </>
    );
};
