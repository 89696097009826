import actionCreatorFactory from 'typescript-fsa';
import { BettingViewRequestParams } from '.';
import { UserProfile } from '../../../services/account';
import { GameBet } from '../../../services/betting';

const actionCreator = actionCreatorFactory();

export const BETTING_VIEW_LOAD = 'BETTING_VIEW_LOAD';
export const bettingViewLoad = actionCreator<BettingViewRequestParams>(BETTING_VIEW_LOAD);

export const BETTING_VIEW_SET = 'BETTING_VIEW_SET';
export const bettingViewSet = actionCreator<GameBet[]>(BETTING_VIEW_SET);

export const BETTING_VIEW_SET_PARAMS = 'BETTING_VIEW_SET_PARAMS';
export const bettingViewSetParams = actionCreator<BettingViewRequestParams>(BETTING_VIEW_SET_PARAMS);

export const BETTING_VIEW_LOAD_WITHOUT_PROPS = 'BETTING_VIEW_LOAD_WITHOUT_PROPS';
export const bettingViewLoadWithoutParams = actionCreator(BETTING_VIEW_LOAD_WITHOUT_PROPS);

export const BETTING_VIEW_TR_ID_CHANGED = 'BETTING_VIEW_TR_ID_CHANGED';
export const bettingViewTrIdChanged = actionCreator<string>(BETTING_VIEW_TR_ID_CHANGED);

export const BETTING_VIEW_SET_USER = 'BETTING_VIEW_SET_USER';
export const bettingViewSetUser = actionCreator<UserProfile>(BETTING_VIEW_SET_USER);

