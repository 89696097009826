import * as React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { LoadingIndicatorContent } from '../LoadingIndicatorSpinner';

interface Props {
    title: string;
    message: string;

    isFetching?: boolean;
    onOK: () => void;
    onCancel: () => void;
}

export const ConfirmationDialog: React.FC<Props> = (props) => {
    return (
        <Modal isOpen={true} autoFocus={false}>
            <ModalHeader>{props.title}</ModalHeader>
            <ModalBody>{props.message}</ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={props.onOK} autoFocus={true} disabled={!!props.isFetching}>
                    <LoadingIndicatorContent isLoading={!!props.isFetching}>OK</LoadingIndicatorContent>
                </Button>{' '}
                <Button color="secondary" onClick={props.onCancel} disabled={!!props.isFetching}>
                    <LoadingIndicatorContent isLoading={!!props.isFetching}>Abbrechen</LoadingIndicatorContent>
                </Button>
            </ModalFooter>
        </Modal>
    );
};

interface OnDemandConfirmationDialogProps extends Props {
    isShown: () => boolean;
}

export const OnDemandConfirmationDialog: React.FC<OnDemandConfirmationDialogProps> = (props) => {
    if (props.isShown()) {
        return <ConfirmationDialog {...props} />;
    } else {
        return null;
    }
};
