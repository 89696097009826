import * as React from 'react';
import { useSelector } from 'react-redux';
import { systemIsFetchingSelector } from '../selectors';

export const Spinner: React.FC<{}> = () => {
    const isFetching = useSelector(systemIsFetchingSelector);
    if (isFetching) {
        return <div>Loading ...</div>;
    } else {
        return null;
    }
};