import moment from 'moment';
import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button } from 'reactstrap';
import { isRadioEins } from '../../System/systemConfig';
import {
    accountUserSettingDismissNewsletterTeaser,
    accountUserSettingSubscribeNewsletter
} from '../AccountUserSettings/actions';
import { accountUserSettingsProfileSettingsSelector } from '../AccountUserSettings/selectors';

export const NewsletterTeaser: FC<{}> = () => {
    const profileSettings = useSelector(accountUserSettingsProfileSettingsSelector);

    const dispatch = useDispatch();

    const showTeaser = useCallback(() => {
        return (
            isRadioEins() &&
            profileSettings &&
            profileSettings.emailValid &&
            profileSettings.userId &&
            (!profileSettings.newsletter || !profileSettings.infoMails) &&
            (!profileSettings.nextNewsletterTeaser || moment(profileSettings.nextNewsletterTeaser).isBefore())
        );
    }, [profileSettings]);

    if (showTeaser()) {
        return (
            <>
                <Alert className="mt-2">
                    <h3>RadioEins-Newsletter</h3>
                    <p>
                        Abonnieren Sie den RadioEins-Newsletter und erhalten Sie aktuelle Informationen zu Events in
                        Berlin und RadioEins-Veranstaltungen.
                    </p>
                    <hr />
                    <Button
                        color="success"
                        onClick={() => dispatch(accountUserSettingSubscribeNewsletter())}
                    >
                        Newsletter abonnieren
                    </Button>
                    <Button
                        className="ms-3"
                        onClick={() =>
                            dispatch(accountUserSettingDismissNewsletterTeaser())
                        }
                    >
                        Nein, danke.
                    </Button>
                </Alert>
            </>
        );
    } else {
        return null;
    }
};
