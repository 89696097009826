import _ from 'lodash';
import React, { useState } from 'react';
import { Col, Container, Input, ListGroup, ListGroupItem, Row } from 'reactstrap';

export interface KeyNameValue {
    key: string;
    name: string;
}

interface IncrementalSearchProps {
    initialSearchValue?: string;
    showMax?: number;
    selectList: (s: string) => KeyNameValue[];
    onSelected: (val: KeyNameValue) => void;
    isLoaded: () => boolean;
}

export const IncrementalSearch = (props: IncrementalSearchProps) => {
    const [search, setSearch] = useState(props.initialSearchValue || '');
    const [list, setList] = useState([] as KeyNameValue[]);
    const [showList, setShowList] = useState(false);

    const debouncedSearch = _.throttle((s: string) => {
        setList(props.selectList(s));
    }, 500);

    const onChange = (e: React.FormEvent<HTMLInputElement>) => {
        setSearch(e.currentTarget.value);
        setShowList(true);
        debouncedSearch(e.currentTarget.value);
    };

    const showMax = props.showMax ? props.showMax : 5;

    const onSelect = (key: string, name: string) => {
        setSearch(name);
        setShowList(false);
        props.onSelected({ key, name });
    };

    return (
        <Container>
            <Row>
                <Col sm={12} lg={12} xl={12}>
                    <Input
                        type="text"
                        value={search}
                        onChange={onChange}
                        disabled={!props.isLoaded()}
                        placeholder="Suche"
                    />
                </Col>
                <Col sm={12} lg={12} xl={12}>
                    {showList && (
                        <ListGroup>
                            {list.slice(0, showMax).map((v) => (
                                <ListGroupItem key={v.key} value={v.key} onClick={() => onSelect(v.key, v.name)}>
                                    {v.name}
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                    )}
                </Col>
            </Row>
        </Container>
    );
};
