import { AnyAction, isType } from 'typescript-fsa';
import { TeamChat, TeamChatMessage } from '../../services/image';
import {
    teamChatAddMessages,
    teamChatSetCount,
    teamChatSetLoading,
    teamChatSetMessages,
    teamChatSetPage,
    teamChatSetVisible,
    teamChatSetAdmin,
    teamChatSetMember,
    teamChatSet
} from './actions';

export interface TeamChatState {
    chat?: TeamChat;
    messages: TeamChatMessage[];
    page: number;
    count: number;
    pageSize: number;
    loading: boolean;
    visible: boolean;
    isAdmin: boolean;
    isMember: boolean;
}

const initialTeamChatState: TeamChatState = {
    messages: [],
    page: 0,
    count: 0,
    pageSize: 10,
    loading: true,
    visible: false,
    isAdmin: false,
    isMember: false
};

export function teamChatReducer(state: TeamChatState = initialTeamChatState, action: AnyAction): TeamChatState {
    if (isType(action, teamChatSetMessages)) {
        return { ...state, messages: action.payload };
    } else if (isType(action, teamChatSetLoading)) {
        return { ...state, loading: action.payload };
    } else if (isType(action, teamChatSetMessages)) {
        return { ...state, messages: action.payload };
    } else if (isType(action, teamChatAddMessages)) {
        return { ...state, messages: state.messages.concat(action.payload) };
    } else if (isType(action, teamChatSetPage)) {
        return { ...state, page: action.payload };
    } else if (isType(action, teamChatSetCount)) {
        return { ...state, count: action.payload };
    } else if (isType(action, teamChatSetVisible)) {
        return { ...state, visible: action.payload };
    } else if (isType(action, teamChatSetAdmin)) {
        return { ...state, isAdmin: action.payload };
    } else if (isType(action, teamChatSetMember)) {
        return { ...state, isMember: action.payload };
    } else if (isType(action, teamChatSet)) {
        return { ...state, chat: action.payload };
    }

    return state;
}
