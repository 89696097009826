import actionCreatorFactory from 'typescript-fsa';
import { BettingUser, GroupMember, HighscoreInfo, Team, UserGroup } from '../../../services/betting';

const actionCreator = actionCreatorFactory();

export const BETTING_PROFILE_LOAD_BETTING_PROFILE = 'BETTING_PROFILE_LOAD_BETTING_PROFILE';
export const bettingProfileLoadBettingProfile = actionCreator(BETTING_PROFILE_LOAD_BETTING_PROFILE);

export const BETTING_PROFILE_LOAD_FAVORITE_TEAMS = 'BETTING_PROFILE_LOAD_FAVORITE_TEAMS';
export const bettingProfileLoadFavoriteTeams = actionCreator(BETTING_PROFILE_LOAD_FAVORITE_TEAMS);

export const BETTING_PROFILE_LOAD_DEFAULT_GROUP = 'BETTING_PROFILE_LOAD_DEFAULT_GROUP';
export const bettingProfileLoadDefaultGroup = actionCreator(BETTING_PROFILE_LOAD_DEFAULT_GROUP);

export const BETTING_PROFILE_SET_DEFAULT_GROUP = 'BETTING_PROFILE_SET_DEFAULT_GROUP';
export const bettingProfileSetDefaultGroup = actionCreator<UserGroup>(BETTING_PROFILE_SET_DEFAULT_GROUP);

export const BETTING_PROFILE_LOAD_USER_GROUPS = 'BETTING_PROFILE_LOAD_USER_GROUPS';
export const bettingProfileLoadUserGroups = actionCreator(BETTING_PROFILE_LOAD_USER_GROUPS);

export const BETTING_PROFILE_LOAD_HIGHSCORE_INFOS = 'BETTING_PROFILE_LOAD_HIGHSCORE_INFOS';
export const bettingProfileLoadHighscoreInfos = actionCreator(BETTING_PROFILE_LOAD_HIGHSCORE_INFOS);

export const BETTING_PROFILE_SET_FAVORITE_TEAMS = 'BETTING_PROFILE_SET_FAVORITE_TEAMS';
export const bettingProfileSetFavoriteTeams = actionCreator<Team[]>(BETTING_PROFILE_SET_FAVORITE_TEAMS);

export const BETTING_PROFILE_ADD_FAVORITE_TEAM = 'BETTING_PROFILE_ADD_FAVORITE_TEAM';
export const bettingProfileAddFavoriteTeam = actionCreator<string>(BETTING_PROFILE_ADD_FAVORITE_TEAM);

export const BETTING_PROFILE_SET_FAVORITE_TEAMS_AVAILABLE = 'BETTING_PROFILE_SET_FAVORITE_TEAMS_AVAILABLE';
export const bettingProfileSetFavoriteTeamsAvailable = actionCreator<Team[]>(
    BETTING_PROFILE_SET_FAVORITE_TEAMS_AVAILABLE
);

export const BETTING_PROFILE_DELETE_FAVORITE_TEAM = 'BETTING_PROFILE_DELETE_FAVORITE_TEAM';
export const bettingProfileDeleteFavoriteTeam = actionCreator<string>(BETTING_PROFILE_DELETE_FAVORITE_TEAM);

export const BETTING_PROFILE_SET_MY_GROUP_MEMBER = 'BETTING_PROFILE_SET_MY_GROUP_MEMBER';
export const bettingProfileSetMyGroupMember = actionCreator<GroupMember[]>(BETTING_PROFILE_SET_MY_GROUP_MEMBER);

export const BETTING_PROFILE_SET_DEFAULT_USER_GROUP = 'BETTING_PROFILE_SET_DEFAULT_USER_GROUP';
export const bettingProfileSetDefaultUserGroup = actionCreator<number>(BETTING_PROFILE_SET_DEFAULT_USER_GROUP);

export const BETTING_PROFILE_LEAVE_USER_GROUP = 'BETTING_PROFILE_LEAVE_USER_GROUP';
export const bettingProfileLeaveUserGroup = actionCreator<number>(BETTING_PROFILE_LEAVE_USER_GROUP);

export const BETTING_PROFILE_ACCEPT_INVITATION = 'BETTING_PROFILE_ACCEPT_INVITATION';
export const bettingProfileAcceptInvitation = actionCreator<number>(BETTING_PROFILE_ACCEPT_INVITATION);

export const BETTING_PROFILE_REFUSE_INVITATION = 'BETTING_PROFILE_REFUSE_INVITATION';
export const bettingProfileRefuseInvitation = actionCreator<number>(BETTING_PROFILE_REFUSE_INVITATION);

export const BETTING_PROFILE_SET_HIGHSCORE_INFOS = 'BETTING_PROFILE_SET_HIGHSCORE_INFOS';
export const bettingProfileSetHighscoreInfos = actionCreator<HighscoreInfo[]>(BETTING_PROFILE_SET_HIGHSCORE_INFOS);

export const BETTING_PROFILE_CREATE_USER_GROUP = 'BETTING_PROFILE_CREATE_USER_GROUP';
export const bettingProfileCreateUserGroup = actionCreator<string>(BETTING_PROFILE_CREATE_USER_GROUP);

export const BETTING_PROFILE_SET_MESSAGE = 'BETTING_PROFILE_SET_MESSAGE';
export const bettingProfileSetMessage = actionCreator<{message: string, level: string}>(BETTING_PROFILE_SET_MESSAGE);

export const BETTING_PROFILE_SET_MY_PROFILE = 'BETTING_PROFILE_SET_MY_PROFILE';
export const bettingProfileSetMyProfile = actionCreator<BettingUser>(BETTING_PROFILE_SET_MY_PROFILE);

export const BETTING_PROFILE_UPDATE_BETTING_PUBLIC = 'BETTING_PROFILE_UPDATE_BETTING_PUBLIC';
export const bettingProfileUpdateBettingPublic = actionCreator<boolean>(BETTING_PROFILE_UPDATE_BETTING_PUBLIC);