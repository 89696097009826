import { AnyAction, isType } from 'typescript-fsa';
import { GroupMember, HighscoreInfo, Team, UserGroup } from '../../../services/betting';
import {
    bettingProfileSetDefaultGroup,
    bettingProfileSetFavoriteTeams,
    bettingProfileSetFavoriteTeamsAvailable,
    bettingProfileSetHighscoreInfos,
    bettingProfileSetMessage,
    bettingProfileSetMyGroupMember,
    bettingProfileSetMyProfile
} from './actions';

export interface BettingProfileState {
    bettingPublic: boolean;
    favoriteTeams: Team[];
    availableTeams: Team[];
    highscoreInfos: HighscoreInfo[];
    myGroupMember: GroupMember[];
    myDefaultGroup?: UserGroup;
    message: string;
    messageLevel: string;
}

const initialBettingProfileState: BettingProfileState = {
    bettingPublic: false,
    favoriteTeams: [],
    availableTeams: [],
    highscoreInfos: [],
    myGroupMember: [],
    message: '',
    messageLevel: ''
};

export function bettingProfilReducer(
    state: BettingProfileState = initialBettingProfileState,
    action: AnyAction
): BettingProfileState {
    if (isType(action, bettingProfileSetFavoriteTeams)) {
        return Object.assign({}, state, { favoriteTeams: action.payload });
    } else if (isType(action, bettingProfileSetFavoriteTeamsAvailable)) {
        return Object.assign({}, state, { availableTeams: action.payload });
    } else if (isType(action, bettingProfileSetMyGroupMember)) {
        return Object.assign({}, state, { myGroupMember: action.payload });
    } else if (isType(action, bettingProfileSetHighscoreInfos)) {
        return Object.assign({}, state, { highscoreInfos: action.payload });
    } else if (isType(action, bettingProfileSetMessage)) {
        return Object.assign({}, state, { message: action.payload.message, messageLevel: action.payload.level });
    } else if (isType(action, bettingProfileSetMyProfile)) {
        return Object.assign({}, state, { bettingPublic: action.payload.bettingDataPublic });
    } else if (isType(action, bettingProfileSetDefaultGroup)) {
        return { ...state, myDefaultGroup: action.payload };
    }

    return state;
}
