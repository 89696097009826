import { push } from '@lagunovsky/redux-react-router';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { bettingViewPath, BettingViewRequestParams } from '.';
import { loadWithoutPropsTournamentRoundForwarder } from '../../../reactUtils';
import { fetchWrapperFunc } from '../../../utils';
import { getBettingApi2, getProfile2Api, getSystemConfig, getTournamentApi2 } from '../../System/systemConfig';
import {
    bettingViewSet,
    bettingViewSetParams,
    bettingViewSetUser,
    BETTING_VIEW_LOAD,
    BETTING_VIEW_LOAD_WITHOUT_PROPS,
    BETTING_VIEW_TR_ID_CHANGED
} from './action';
import { bettingViewParamsSelector, bettingViewUserSelector } from './selectors';
import {
    tournamentSelectionChangeTournamentRound,
    tournamentSelectionVerifyAndAdjustSelectedTournamentByTrId
} from '../../TournamentSelectionComponent/actions';
import { UserProfile } from '../../../services/account';

function* loadBettingViewGenerator(action: Action<BettingViewRequestParams>) {
    const params = action.payload;

    // test if the given tournament round is valid
    const tr = yield* fetchWrapperFunc(() => getTournamentApi2().getTournamentRoundById({trId: Number(params.trId)}));

    if (!tr) {
        // invalid param, go to main page
        yield put(push('/'));
    } else {
        if (!tr.leaf) {
            // invalid node round -> switch to the next leaf round
            const newTr = yield* fetchWrapperFunc(() =>
                getTournamentApi2().getNextTournamentRoundByType({trId: tr.id, type: 'betting'})
            );
            if (newTr) {
                yield put(push(bettingViewPath(String(newTr.id), params.userId)));
            } else {
                yield put(push('/'));
            }
        } else {
            yield put(
                tournamentSelectionChangeTournamentRound({
                    type: 'betting',
                    trId: params.trId
                })
            );
            yield put(tournamentSelectionVerifyAndAdjustSelectedTournamentByTrId(String(params.trId)));

            yield* fetchWrapperFunc(() => getBettingApi2().getPublicGameBets({trId: Number(params.trId), userId: params.userId}), [
                r => bettingViewSet(r)
            ]);
            const user: UserProfile | undefined = yield select(bettingViewUserSelector);
            if (!user || user.id !== params.userId) {
                yield* fetchWrapperFunc(
                    () => getProfile2Api().getPublicUserProfile({tenant: getSystemConfig().clientName, userId: params.userId}),
                    [r => bettingViewSetUser(r)]
                );
            }

            yield put(bettingViewSetParams(params));
        }
    }
}

function* startWithoutProps() {
    const params: BettingViewRequestParams = yield select(bettingViewParamsSelector);
    yield* loadWithoutPropsTournamentRoundForwarder('betting', (id: string) => bettingViewPath(id, params.userId));
}

function* trIdChange(action: Action<string>) {
    const trId = action.payload;
    const params: BettingViewRequestParams = yield select(bettingViewParamsSelector);
    console.info('forwarding to trId = ' + trId + ' userId = ' + params.userId);
    yield put(push(bettingViewPath(trId, params.userId)));
}

function* watchForBettingViewActions() {
    yield takeLatest(BETTING_VIEW_LOAD, loadBettingViewGenerator);
    yield takeLatest(BETTING_VIEW_LOAD_WITHOUT_PROPS, startWithoutProps);
    yield takeLatest(BETTING_VIEW_TR_ID_CHANGED, trIdChange);
}

export function* bettingViewSagas() {
    yield all([watchForBettingViewActions()]);
}
