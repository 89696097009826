import { push } from '@lagunovsky/redux-react-router';
import { useQuery } from '@tanstack/react-query';
import * as R from 'ramda';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { withLogin } from '../../../reactutils/withLogin';
import { myProfileBettingPath } from '../../../screens/UserProfileScreen';
import { HighscoreInfo } from '../../../services/betting';
import { groupInternalHighscorePath } from '../../GroupInternalHighscores';
import { systemPushSubscriptionSelector, userIdSelector, userNameSelector } from '../../System/selectors';
import { getGroupHighscoreApi2 } from '../../System/systemConfig';
import { userGroupChatPath } from '../../TeamChat';
import { tournamentSelectionTournamentIdSelector } from '../../TournamentSelectionComponent/selectors';
import { bettingProfileLoadHighscoreInfos } from '../BettingProfile/actions';
import { bettingProfileMyHighscoresSelector } from '../BettingProfile/selectors';
import {
    myGroupHighscoresForward,
    myGroupHighscoresGotoHighscore,
    myGroupHighscoresRefreshUnreadTeamChatMessages,
} from './actions';
import { myGroupHighscoreUnreadChatCountSelector } from './selector';
import styled from 'styled-components';

const PushNotificationsEnabledIcon = styled.div`
    position: absolute;
    width: 7px;
    height: 7px;
    top: 4px;
    right: 4px;
    border-radius: 50%;

    background-color: lime;
    border-style: none;
`;

const PushNotificationsDisabledIcon = styled(PushNotificationsEnabledIcon)`
    background-color: orange;
`;

export const MyHighscore: FC<{}> = () => {
    const selectedTournamentId = useSelector(tournamentSelectionTournamentIdSelector);
    const myHighscore = useSelector(bettingProfileMyHighscoresSelector);
    const userName = useSelector(userNameSelector);
    const userId = useSelector(userIdSelector);

    const pushSubscription = useSelector(systemPushSubscriptionSelector);

    const dispatch = useDispatch();

    const [highscore, setHighscore] = useState<HighscoreInfo>();

    useEffect(() => {
        if (userId) {
            dispatch(bettingProfileLoadHighscoreInfos());
        }
    }, [dispatch, userId]);

    useEffect(() => {
        if (userId) {
            const hs = myHighscore.find((hs) => hs.tournamentId === String(selectedTournamentId));
            setHighscore(hs);
        }
    }, [selectedTournamentId, myHighscore, userId]);

    const onClick = (e: React.MouseEvent) => {
        e.preventDefault();
        if (userId && selectedTournamentId) {
            dispatch(myGroupHighscoresGotoHighscore(String(selectedTournamentId)));
        }
    };

    if (!userId) {
        return null;
    } else if (highscore) {
        return (
            <>
                <div className="text-white font-weight-bold position-relative" style={{ fontSize: '0.8rem', lineHeight: '1rem' }}>
                    {userName}
                    {pushSubscription && <PushNotificationsEnabledIcon />}
                    {!pushSubscription && <PushNotificationsDisabledIcon />}
                </div>
                <Link
                    to="/#"
                    className="text-success font-weight-light button"
                    style={{ fontSize: '0.8rem', lineHeight: '1rem' }}
                    onClick={onClick}
                >
                    Platz {highscore.rank} / {highscore.count}
                </Link>
            </>
        );
    } else {
        return (
            <>
                <div className="text-white font-weight-bold position-relative" style={{ fontSize: '0.8rem', lineHeight: '1rem' }}>
                    {userName}
                    {pushSubscription && <PushNotificationsEnabledIcon />}
                    {!pushSubscription && <PushNotificationsDisabledIcon />}
                </div>
                <Link
                    to="/#"
                    className="text-success font-weight-light button"
                    style={{ fontSize: '0.8rem', lineHeight: '1rem' }}
                    onClick={onClick}
                >
                    Noch keine Wertung
                </Link>
            </>
        );
    }
};

const MyGroupHighscoreForwarderComponent: FC<{}> = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(push(groupInternalHighscorePath('1', '')));
    }, [dispatch]);

    return null;
};

export const myGroupHighscorePath = () => `/b/my-group-highscores`;

export const MyGroupHighscoreForwarder = R.compose(withLogin())(MyGroupHighscoreForwarderComponent);

export const MyGroupHighscores: FC<{}> = () => {
    const selectedTournamentId = useSelector(tournamentSelectionTournamentIdSelector);
    const userId = useSelector(userIdSelector);

    const unreadChatCount = useSelector(myGroupHighscoreUnreadChatCountSelector);

    const dispatch = useDispatch();
    const api = getGroupHighscoreApi2();

    const myGroupHighscore = useQuery({
        queryKey: ['myGroupHighscore', userId, selectedTournamentId],
        queryFn: () => api.getMainUserGroupParticipatingWithHighscores({tId: String(selectedTournamentId)}),
        enabled: !!selectedTournamentId && !!userId,
    });

    useEffect(() => {
        const interval = setInterval(() => {
            if (myGroupHighscore.data?.userGroupId) {
                dispatch(myGroupHighscoresRefreshUnreadTeamChatMessages(String(myGroupHighscore.data.userGroupId)));
            }
        }, 120000);
        return () => clearInterval(interval);
    }, [dispatch, myGroupHighscore.data?.userGroupId]);

    const onClick = (e: React.MouseEvent) => {
        e.preventDefault();
        if (myGroupHighscore.data?.userGroupId) {
            dispatch(myGroupHighscoresForward(String(myGroupHighscore.data?.userGroupId)));
        }
    };

    if (!userId) {
        return null;
    }
    if (myGroupHighscore.data?.userGroupId) {
        return (
            <>
                <div className="text-white font-weight-bold" style={{ fontSize: '0.8rem', lineHeight: '1rem' }}>
                    Tippteam {myGroupHighscore.data?.userGroupName}
                    {unreadChatCount > 0 && (
                        <Link to={userGroupChatPath(myGroupHighscore.data?.userGroupId)}>
                            <Badge className="ms-1" color="danger">
                                {unreadChatCount}
                            </Badge>
                        </Link>
                    )}
                </div>
                <Link
                    to="/#"
                    className="text-success font-weight-light button"
                    style={{ fontSize: '0.8rem', lineHeight: '1rem' }}
                    onClick={onClick}
                >
                    {!!myGroupHighscore.data?.rank && !!myGroupHighscore.data?.lastRank && (
                        <span>
                            Platz {myGroupHighscore.data?.rank} / {myGroupHighscore.data?.lastRank}
                        </span>
                    )}
                    {(!myGroupHighscore.data?.rank || !myGroupHighscore.data?.lastRank) && (
                        <span>Noch keine Wertung</span>
                    )}
                </Link>
            </>
        );
    } else {
        return (
            <>
                <div className="text-white font-weight-bold" style={{ fontSize: '0.8rem', lineHeight: '1rem' }}>
                    Tippteam
                </div>
                <Link
                    to={myProfileBettingPath()}
                    className="text-success font-weight-light button"
                    style={{ fontSize: '0.8rem', lineHeight: '1rem' }}
                >
                    Jetzt Team anlegen
                </Link>
            </>
        );
    }
};
