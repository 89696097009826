import { v4 as uuidv4 } from 'uuid';
import { reduxStore } from '../../index';
import { HTTPHeaders } from '../../services/account';
import { GlobalState } from '../actions';
import { systemAutomaticLogout, systemSetLoginRequired } from './actions';
import { ACCESS_TOKEN_KEY, getSystemConfig } from './systemConfig';

export const getVersionNumber = () => {
    return process.env.REACT_APP_VERSION || 'v?.?';
};

export function setLoginRequired(nextUrl: string) {
    reduxStore.dispatch(systemSetLoginRequired({ nextUrl }));
}

export function logoutImmediately() {
    reduxStore.dispatch(systemAutomaticLogout());
}

export function getFetchHeaders(): HTTPHeaders {
    return getFetchHeaderFromState();
}

const clientTag = uuidv4();

function getFetchHeaderFromState() {
    const clientName = getSystemConfig().clientName;
    let headers: HTTPHeaders = {
        'X-FT-Client-Version': getVersionNumber(),
    };

    if (clientName) {
        headers['X-FT-Tenant'] = clientName;
    }

    headers['X-FT-Client-Tag'] = clientTag;
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
    if (accessToken && accessToken !== 'undefined') {
        headers['Authorization'] = 'Bearer ' + accessToken;
    }
    return headers;
}

export const getGlobalStateOld = (): GlobalState | undefined => reduxStore?.getState();
