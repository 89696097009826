
export const isValidBetValue = (val: string): boolean => {
    return val.match('^[0-9]{1,2}$') != null;
};

export const isValidGameResult = (r1?: number, r2?: number): boolean => {
    if (r1 === undefined || r2 === undefined) {
        return false;
    }
    if (r1 < 0 || r2 < 0) {
        return false;
    }
    return true;
};
