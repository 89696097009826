import { useQuery } from '@tanstack/react-query';
import React, { FC, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router';
import Footer from '../../components/Footer';
import { AFLeagueSet } from '../apifootball-model';
import { ApiFootballLeague, ApiFootballLeagueList, getLeagues } from './league';
import { ApiFootballLive, apiSportsLivePath } from './live';
import { ApiFootballTeam } from './team';
import { withLogin } from '../../reactutils/withLogin';
import * as R from 'ramda';
import { Link } from 'react-router-dom';

interface ApiFootballContextModel {
    selectedLeagues: number[];
    allLeagues: AFLeagueSet[];
}

const initialApiFootballContext: ApiFootballContextModel = {
    selectedLeagues: [],
    allLeagues: []
}

export const ApiFootballContext = React.createContext<ApiFootballContextModel>(initialApiFootballContext);

const ApiFootballMainComp: FC<{}> = (props) => {
    const [leagues, setLeagues] = useState<number[]>([1, 4, 2, 39, 78, 135, 79, 80, 140, 848, 3]);
    const [allLeagues, setAllLeagues] = useState<AFLeagueSet[]>([]);
    const [context, setContext] = useState<ApiFootballContextModel>(initialApiFootballContext);

    const leaguesQuery = useQuery({
        queryKey: ['getLeagues'], 
        queryFn: () => getLeagues(), 
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchInterval: 5 * 60 * 1000
    });

    useEffect(() => {
        if (leaguesQuery.data) {
            setAllLeagues(leaguesQuery.data.response);
        }
    }, [leaguesQuery.data]);

    useEffect(() => {
        setContext({selectedLeagues: leagues, allLeagues});
    }, [leagues, allLeagues]);

    const addLeague = (id?: number) => {
        if (id) {
            leagues.push(id);
            leagues.sort();
            setLeagues(leagues);
        }
    };

    const removeLeague = (id?: number) => {
        if (id) {
            setLeagues(leagues.filter(val => val !== id));
        }
    };
    return (
        <>
            <div className="m-2">
                <h2>API Football</h2>
                <ApiFootballContext.Provider value={context} >
                    <div className="d-flex flex-wrap">
                        <div className="m-1">
                            <Link to={apiSportsLivePath()}>Live</Link>
                        </div>
                        <div className="m-1">
                            <Link to={"/"}>Main</Link>
                        </div>
                    </div>
                    Leagues: <ApiFootballLeagueList addLeague={addLeague} removeLeague={removeLeague} />
                    <Routes>
                        <Route path="team/:teamId" element={<ApiFootballTeam />} />
                        <Route path="league/*" element={<ApiFootballLeague />} />
                        <Route path="/" element={<ApiFootballLive />} />
                    </Routes>
                </ApiFootballContext.Provider>
            </div>
            <Footer />
        </>
    );
};

export const ApiFootballMain = R.compose(withLogin())( ApiFootballMainComp);