import { faBackward, faFastBackward, faFastForward, faForward } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Table } from 'reactstrap';
import { Game } from '../../../../services/betting';
import { DateStrResponsive } from '../../../DateTime';
import { TeamLink } from '../../../TeamLink';
import {
    matchStatisticGamesBackward,
    matchStatisticGamesFastBackward,
    matchStatisticGamesFastForward,
    matchStatisticGamesForward,
    matchStatisticLoadGames
} from '../actions';
import {
    matchStatisticCountSelector,
    matchStatisticCurrentOffsetSelector,
    matchStatisticGameCountSelector,
    matchStatisticGamesSelector
} from '../selectors';

interface Props {
    team1Id: string;
    team2Id: string;
    count: number;
}

export const MatchStatisticGames: FC<Props> = props => {
    const games = useSelector(matchStatisticGamesSelector);
    const currentOffset = useSelector(matchStatisticCurrentOffsetSelector);
    const count = useSelector(matchStatisticCountSelector);
    const gameCount = useSelector(matchStatisticGameCountSelector);

    const dispatch = useDispatch();

    const backwardEnabled = () => {
        return currentOffset > 0;
    };

    const forwardEnabled = () => {
        return currentOffset < Math.floor((gameCount - 1) / count) * count;
    };

    useEffect(() => {
        dispatch(
            matchStatisticLoadGames({ team1Id: props.team1Id, team2Id: props.team2Id, count: props.count, offset: 0 })
        );
    }, [dispatch, props.team1Id, props.team2Id, props.count]);

    const renderGame = (g: Game) => {
        return (
            <tr key={g.id}>
                <td>
                    <DateStrResponsive date={g.date} />
                </td>
                <td>
                    <TeamLink teamId={g.team1.id} withIcon/>
                </td>
                <td>
                    <TeamLink teamId={g.team2.id} withIcon/>
                </td>
                <td>
                    {g.result1}&nbsp;:&nbsp;{g.result2}
                </td>
            </tr>
        );
    };

    return (
        <>
            <Button
                className="m-1"
                disabled={!backwardEnabled()}
                onClick={() => dispatch(matchStatisticGamesFastBackward())}
            >
                <FontAwesomeIcon icon={faFastBackward} />
            </Button>
            <Button
                className="m-1"
                disabled={!backwardEnabled()}
                onClick={() => dispatch(matchStatisticGamesBackward())}
            >
                <FontAwesomeIcon icon={faBackward} />
            </Button>
            <Button className="m-1" disabled={!forwardEnabled()} onClick={() => dispatch(matchStatisticGamesForward())}>
                <FontAwesomeIcon icon={faForward} />
            </Button>
            <Button
                className="m-1"
                disabled={!forwardEnabled()}
                onClick={() => dispatch(matchStatisticGamesFastForward())}
            >
                <FontAwesomeIcon icon={faFastForward} />
            </Button>

            <Table>
                <tbody>{games.map(g => renderGame(g))}</tbody>
            </Table>
        </>
    );
};
