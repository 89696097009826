/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.25
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateTask
 */
export interface UpdateTask {
    /**
     * 
     * @type {string}
     * @memberof UpdateTask
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateTask
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof UpdateTask
     */
    creationDate?: Date;
}

/**
 * Check if a given object implements the UpdateTask interface.
 */
export function instanceOfUpdateTask(value: object): value is UpdateTask {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function UpdateTaskFromJSON(json: any): UpdateTask {
    return UpdateTaskFromJSONTyped(json, false);
}

export function UpdateTaskFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateTask {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'creationDate': json['creationDate'] == null ? undefined : (new Date(json['creationDate'])),
    };
}

export function UpdateTaskToJSON(value?: UpdateTask | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'creationDate': value['creationDate'] == null ? undefined : ((value['creationDate']).toISOString()),
    };
}

