import actionCreatorFactory from 'typescript-fsa';
import { UserViewRequestParams, UserViewTabType } from '.';
import { UserProfile } from '../../services/account';
import { BettingUser, BettingUserPublicProfile } from '../../services/betting';

const actionCreator = actionCreatorFactory();

export const USER_VIEW_LOAD = 'USER_VIEW_LOAD';
export const userViewLoad = actionCreator<UserViewRequestParams>(USER_VIEW_LOAD);

export const USER_VIEW_LOAD_PROFILE = 'USER_VIEW_LOAD_PROFILE';
export const userViewLoadProfile = actionCreator<string>(USER_VIEW_LOAD_PROFILE);

export const USER_VIEW_BETTING_USER = 'USER_VIEW_BETTING_USER';
export const userViewSetBettingUser = actionCreator<BettingUser>(USER_VIEW_BETTING_USER);

export const USER_VIEW_SET_PROFILE = 'USER_VIEW_SET_PROFILE';
export const userViewSetProfile = actionCreator<UserProfile>(USER_VIEW_SET_PROFILE);

export const USER_VIEW_LOAD_BETTING = 'USER_VIEW_LOAD_BETTING';
export const userViewLoadBetting = actionCreator<string>(USER_VIEW_LOAD_BETTING);

export const USER_VIEW_SET_BETTING = 'USER_VIEW_SET_BETTING';
export const userViewSetBetting = actionCreator<BettingUserPublicProfile>(USER_VIEW_SET_BETTING);

export const USER_VIEW_SET_USER_ID = 'USER_VIEW_SET_USER_ID';
export const userViewSetUserId = actionCreator<string>(USER_VIEW_SET_USER_ID);

export const USER_VIEW_TAB_NAME = 'USER_VIEW_TAB_NAME';
export const userViewSetTabName = actionCreator<UserViewTabType>(USER_VIEW_TAB_NAME);
