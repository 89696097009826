/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.34
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GroupTournamentScore
 */
export interface GroupTournamentScore {
    /**
     * 
     * @type {number}
     * @memberof GroupTournamentScore
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof GroupTournamentScore
     */
    userGroupId: number;
    /**
     * 
     * @type {number}
     * @memberof GroupTournamentScore
     */
    tournamentId: number;
    /**
     * 
     * @type {number}
     * @memberof GroupTournamentScore
     */
    rank: number;
    /**
     * 
     * @type {number}
     * @memberof GroupTournamentScore
     */
    points: number;
    /**
     * 
     * @type {number}
     * @memberof GroupTournamentScore
     */
    slot: number;
}

/**
 * Check if a given object implements the GroupTournamentScore interface.
 */
export function instanceOfGroupTournamentScore(value: object): value is GroupTournamentScore {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('userGroupId' in value) || value['userGroupId'] === undefined) return false;
    if (!('tournamentId' in value) || value['tournamentId'] === undefined) return false;
    if (!('rank' in value) || value['rank'] === undefined) return false;
    if (!('points' in value) || value['points'] === undefined) return false;
    if (!('slot' in value) || value['slot'] === undefined) return false;
    return true;
}

export function GroupTournamentScoreFromJSON(json: any): GroupTournamentScore {
    return GroupTournamentScoreFromJSONTyped(json, false);
}

export function GroupTournamentScoreFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupTournamentScore {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'userGroupId': json['userGroupId'],
        'tournamentId': json['tournamentId'],
        'rank': json['rank'],
        'points': json['points'],
        'slot': json['slot'],
    };
}

export function GroupTournamentScoreToJSON(value?: GroupTournamentScore | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'userGroupId': value['userGroupId'],
        'tournamentId': value['tournamentId'],
        'rank': value['rank'],
        'points': value['points'],
        'slot': value['slot'],
    };
}

