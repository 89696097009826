/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.25
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ActionTokenCheckResponse
 */
export interface ActionTokenCheckResponse {
    /**
     * 
     * @type {string}
     * @memberof ActionTokenCheckResponse
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof ActionTokenCheckResponse
     */
    type?: string;
}

/**
 * Check if a given object implements the ActionTokenCheckResponse interface.
 */
export function instanceOfActionTokenCheckResponse(value: object): value is ActionTokenCheckResponse {
    return true;
}

export function ActionTokenCheckResponseFromJSON(json: any): ActionTokenCheckResponse {
    return ActionTokenCheckResponseFromJSONTyped(json, false);
}

export function ActionTokenCheckResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionTokenCheckResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'status': json['status'] == null ? undefined : json['status'],
        'type': json['type'] == null ? undefined : json['type'],
    };
}

export function ActionTokenCheckResponseToJSON(value?: ActionTokenCheckResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'status': value['status'],
        'type': value['type'],
    };
}

