/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CacheView
 */
export interface CacheView {
    /**
     * 
     * @type {string}
     * @memberof CacheView
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof CacheView
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CacheView
     */
    type: string;
    /**
     * 
     * @type {number}
     * @memberof CacheView
     */
    timeToLive: number;
    /**
     * 
     * @type {number}
     * @memberof CacheView
     */
    hitCount: number;
    /**
     * 
     * @type {number}
     * @memberof CacheView
     */
    missCount: number;
    /**
     * 
     * @type {number}
     * @memberof CacheView
     */
    requestCount: number;
    /**
     * 
     * @type {number}
     * @memberof CacheView
     */
    deleteCount: number;
    /**
     * 
     * @type {number}
     * @memberof CacheView
     */
    putCount: number;
    /**
     * 
     * @type {number}
     * @memberof CacheView
     */
    requestTime: number;
}

/**
 * Check if a given object implements the CacheView interface.
 */
export function instanceOfCacheView(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "timeToLive" in value;
    isInstance = isInstance && "hitCount" in value;
    isInstance = isInstance && "missCount" in value;
    isInstance = isInstance && "requestCount" in value;
    isInstance = isInstance && "deleteCount" in value;
    isInstance = isInstance && "putCount" in value;
    isInstance = isInstance && "requestTime" in value;

    return isInstance;
}

export function CacheViewFromJSON(json: any): CacheView {
    return CacheViewFromJSONTyped(json, false);
}

export function CacheViewFromJSONTyped(json: any, ignoreDiscriminator: boolean): CacheView {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'type': json['type'],
        'timeToLive': json['timeToLive'],
        'hitCount': json['hitCount'],
        'missCount': json['missCount'],
        'requestCount': json['requestCount'],
        'deleteCount': json['deleteCount'],
        'putCount': json['putCount'],
        'requestTime': json['requestTime'],
    };
}

export function CacheViewToJSON(value?: CacheView | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'type': value.type,
        'timeToLive': value.timeToLive,
        'hitCount': value.hitCount,
        'missCount': value.missCount,
        'requestCount': value.requestCount,
        'deleteCount': value.deleteCount,
        'putCount': value.putCount,
        'requestTime': value.requestTime,
    };
}

