import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { Blog } from '../../services/image';
import { fetchWrapperFunc } from '../../utils';
import { getBlogApi, getSystemConfig } from '../System/systemConfig';
import {
    BlogEntryAction,
    blogLoad,
    blogLoadList,
    blogSet,
    blogSetList,
    BLOG_ADD_ENTRY,
    BLOG_CREATE,
    BLOG_DELETE,
    BLOG_DELETE_ENTRY,
    BLOG_LOAD,
    BLOG_LOAD_LIST,
    BLOG_UPDATE_ENTRY,
    BLOG_ADD_IMAGE,
    BLOG_DELETE_IMAGE,
    BlogImageAction,
    BLOG_LOAD_LATEST,
    BLOG_PUBLISH_ON_TWITTER,
    BLOG_PUBLISH_ENTRY_ON_TWITTER
} from './actions';
import { blogEntitySelector } from './selectors';

function* createBlog(action: Action<string>) {
    const blogName = action.payload;
    yield* fetchWrapperFunc(() =>
        getBlogApi().createBlog(getSystemConfig().clientName, blogName )
    );
    yield put(blogLoadList());
}

function* addBlogEntry(action: Action<BlogEntryAction>) {
    const blogId = action.payload.blogId;
    const title = action.payload.title;
    const text = action.payload.text;
    if (blogId) {
        yield* fetchWrapperFunc(() =>
            getBlogApi().createBlogEntry(getSystemConfig().clientName, blogId, title, text )
        );
        yield put(blogLoad(blogId));
    }
}
function* loadBlogList() {
    yield* fetchWrapperFunc(() => getBlogApi().listBlogs(getSystemConfig().clientName), [r => blogSetList(r)]);
}

function* loadBlog(action: Action<string>) {
    const blogId = action.payload;
    yield* fetchWrapperFunc(() => getBlogApi().getBlog(getSystemConfig().clientName, blogId), [r => blogSet(r)]);
}

function* loadBlogLatest() {
    yield* fetchWrapperFunc(() => getBlogApi().getLatest(getSystemConfig().clientName), [r => blogSet(r)]);
}

function* deleteBlog(action: Action<string>) {
    const blogName = action.payload;
    yield* fetchWrapperFunc(() =>
        getBlogApi().deleteBlog(getSystemConfig().clientName, blogName )
    );
    yield put(blogLoadList());
}

function* deleteBlogEntry(action: Action<string>) {
    const blog: Blog = yield select(blogEntitySelector);
    const blogEntryId = action.payload;
    yield* fetchWrapperFunc(() =>
        getBlogApi().deleteBlogEntry(getSystemConfig().clientName, blogEntryId )
    );
    yield put(blogLoad(blog.id));
}

function* updateBlogEntry(action: Action<BlogEntryAction>) {
    const blog: Blog = yield select(blogEntitySelector);
    const blogEntryId = action.payload.blogEntryId;
    const title = action.payload.title;
    const text = action.payload.text;

    if (blog && blogEntryId) {
        yield* fetchWrapperFunc(() =>
            getBlogApi().updateBlogEntry(
                getSystemConfig().clientName,
                blogEntryId,
                blog.id,
                title,
                text
            )
        );
        yield put(blogLoad(blog.id));
    }
}

function* blogAddImage(action: Action<BlogImageAction>) {
    const blog: Blog = yield select(blogEntitySelector);
    const blogEntryId = action.payload.blogEntryId;
    const imageId = action.payload.imageId;
    if (blog && blogEntryId) {
        yield* fetchWrapperFunc(() =>
            getBlogApi().addImage(getSystemConfig().clientName, blogEntryId, blog.id, imageId )
        );
        yield put(blogLoad(blog.id));
    }
}

function* blogDeleteImage(action: Action<BlogImageAction>) {
    const blog: Blog = yield select(blogEntitySelector);
    const blogEntryId = action.payload.blogEntryId;
    const imageId = action.payload.imageId;
    if (blog && blogEntryId) {
        yield* fetchWrapperFunc(() =>
            getBlogApi().deleteImage(getSystemConfig().clientName, blogEntryId, blog.id, imageId )
        );
        yield put(blogLoad(blog.id));
    }
}

function* blogPublishOnTwitter(action: Action<string>) {
    const blogId = action.payload;
    yield* fetchWrapperFunc(() => getBlogApi().publishBlogOnTwitter(getSystemConfig().clientName, blogId));
    yield put(blogLoad(blogId));
}

function* blogPublishEntryOnTwitter(action: Action<string>) {
    const beId = action.payload;
    yield* fetchWrapperFunc(() => getBlogApi().publishBlogEntryOnTwitter(getSystemConfig().clientName, beId));
    const blogEntity: Blog | undefined = yield select(blogEntitySelector);
    if (blogEntity) {
        yield put(blogLoad(blogEntity.id));
    }
}

function* watchForBlogActions() {
    yield takeLatest(BLOG_LOAD_LIST, loadBlogList);
    yield takeEvery(BLOG_CREATE, createBlog);
    yield takeEvery(BLOG_DELETE, deleteBlog);
    yield takeLatest(BLOG_LOAD, loadBlog);
    yield takeLatest(BLOG_LOAD_LATEST, loadBlogLatest);
    yield takeEvery(BLOG_ADD_ENTRY, addBlogEntry);
    yield takeEvery(BLOG_DELETE_ENTRY, deleteBlogEntry);
    yield takeEvery(BLOG_UPDATE_ENTRY, updateBlogEntry);
    yield takeEvery(BLOG_ADD_IMAGE, blogAddImage);
    yield takeEvery(BLOG_DELETE_IMAGE, blogDeleteImage);
    yield takeEvery(BLOG_PUBLISH_ON_TWITTER, blogPublishOnTwitter);
    yield takeEvery(BLOG_PUBLISH_ENTRY_ON_TWITTER, blogPublishEntryOnTwitter);
}

export function* blogSagas() {
    yield all([watchForBlogActions()]);
}
