import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { LoadingIndicatorContent } from '../../common/LoadingIndicatorSpinner';
import { systemSetPopupMessage } from '../../System/actions';
import { isClientAdminSelector } from '../../System/selectors';
import { getPortletApi2, getStaticFilesBaseUrl, getSystemConfig } from '../../System/systemConfig';
import { userViewProfilePath } from '../../UserView';
import { BettingReminderTextEdit } from '../../ClientAdminApp/BettingReminderTextEdit';

export const WeeklyComment = () => {
    const [weeklyComment, setWeeklyComment] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [showBettingReminderTextEdit, setShowBettingReminderTextEdit] = useState(false);

    const isClientAdmin = useSelector(isClientAdminSelector);

    const dispatch = useDispatch();

    const weeklyCommentQuery = useQuery({
        queryKey: ['radioEinsWeeklyComment'],
        queryFn: () => getPortletApi2().getWeeklyComment({ tenant: getSystemConfig().clientName }),
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    });

    const weeklyCommentUpdateMutation = useMutation({
        mutationFn: (comment: string) =>
            getPortletApi2().updateWeeklyComment({ tenant: getSystemConfig().clientName, comment }),
        onSuccess: () => {
            dispatch(systemSetPopupMessage('Kommentar erfolgreich aktualisiert.'));
        },
        onError: (e) => {
            dispatch(systemSetPopupMessage('Fehler beim Speichern des Kommentars (' + JSON.stringify(e) + ')'));
        },
    });

    const onEnableEditMode = () => {
        setEditMode(true);
        setWeeklyComment(weeklyCommentQuery.data?.comment ?? '');
    };
    const onSaveWeeklyComment = () => {
        weeklyCommentUpdateMutation.mutate(weeklyComment, {
            onSuccess: () => {
                weeklyCommentQuery.refetch();
            },
        });
        setEditMode(false);
    };

    return (
        <>
            <div className="">
                <div className="p-1" style={{ fontSize: '13px' }}>
                    <div className="textbox title">
                        <span className="text-primary" style={{ fontSize: '11px' }}>
                            Kommentar
                        </span>
                        <h5 style={{ fontSize: '20px', fontWeight: 'bold' }}>Der aktuelle Kommentar</h5>
                        von <Link to={userViewProfilePath('3893018')}>Andreas Ulrich</Link>
                    </div>
                    <div>
                        <Link to={userViewProfilePath('3893018')} title="Andreas Ulrich - Profil">
                            <img
                                className="img-fluid"
                                src={getStaticFilesBaseUrl() + '/r1/andreas_ulrich.jpg'}
                                alt="Andreas Ulrich - Profil"
                            />
                        </Link>
                    </div>
                    <div className="textbox body pt-2">
                        {!editMode && (
                            <LoadingIndicatorContent isLoading={weeklyCommentQuery.isFetching}>
                                {weeklyCommentQuery.data?.comment}
                            </LoadingIndicatorContent>
                        )}
                        {editMode && isClientAdmin && (
                            <>
                                <textarea
                                    className="form-control"
                                    rows={20}
                                    value={weeklyComment}
                                    onChange={(e) => setWeeklyComment(e.currentTarget.value)}
                                />
                                <Button className="ms-0 my-2" color="primary" onClick={onSaveWeeklyComment}>
                                    <LoadingIndicatorContent isLoading={weeklyCommentUpdateMutation.isPending}>
                                        Speichern
                                    </LoadingIndicatorContent>
                                </Button>
                            </>
                        )}
                        {isClientAdmin && !editMode && (
                            <>
                                <div className="d-flex flex-row justify-content-start">
                                    <Button color="link" size="sm" className="ms-0 ps-0" onClick={onEnableEditMode}>
                                        Kommentar bearbeiten
                                    </Button>
                                </div>
                                <div className="d-flex flex-row justify-content-start align-items-start">
                                    <Button
                                        color="link"
                                        size="sm"
                                        className="ms-0 ps-0 text-left"
                                        onClick={() => setShowBettingReminderTextEdit(true)}
                                    >
                                        Tipperinnerungstexte
                                    </Button>
                                    {showBettingReminderTextEdit && (
                                        <BettingReminderTextEdit
                                            onClose={() => setShowBettingReminderTextEdit(false)}
                                        />
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                    <div className="textbox footer"></div>
                </div>
            </div>
        </>
    );
};
