/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  RestResult,
  SimpleInteger,
  TeamChat,
  TeamChatMessage,
} from '../models';
import {
    RestResultFromJSON,
    RestResultToJSON,
    SimpleIntegerFromJSON,
    SimpleIntegerToJSON,
    TeamChatFromJSON,
    TeamChatToJSON,
    TeamChatMessageFromJSON,
    TeamChatMessageToJSON,
} from '../models';

export interface AddMessageRequest {
    ugId: string;
    message: string;
}

export interface CountMessagesRequest {
    ugId: string;
}

export interface CountUnreadRequest {
    ugId: string;
}

export interface GetMessagesRequest {
    ugId: string;
    page: number;
    pageSize: number;
}

export interface GetTeamChatRequest {
    ugId: string;
}

export interface PublishRequest {
    ugId: string;
}

export interface UnpublishRequest {
    ugId: string;
}

/**
 * 
 */
export class TeamChatControllerApi extends runtime.BaseAPI {

    /**
     */
    async addMessageRaw(requestParameters: AddMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult>> {
        if (requestParameters.ugId === null || requestParameters.ugId === undefined) {
            throw new runtime.RequiredError('ugId','Required parameter requestParameters.ugId was null or undefined when calling addMessage.');
        }

        if (requestParameters.message === null || requestParameters.message === undefined) {
            throw new runtime.RequiredError('message','Required parameter requestParameters.message was null or undefined when calling addMessage.');
        }

        const queryParameters: any = {};

        if (requestParameters.message !== undefined) {
            queryParameters['message'] = requestParameters.message;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/teamchat/{ugId}/add`.replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters.ugId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResultFromJSON(jsonValue));
    }

    /**
     */
    async addMessage(ugId: string, message: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult> {
        const response = await this.addMessageRaw({ ugId: ugId, message: message }, initOverrides);
        return await response.value();
    }

    /**
     */
    async countMessagesRaw(requestParameters: CountMessagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SimpleInteger>> {
        if (requestParameters.ugId === null || requestParameters.ugId === undefined) {
            throw new runtime.RequiredError('ugId','Required parameter requestParameters.ugId was null or undefined when calling countMessages.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/teamchat/{ugId}/count`.replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters.ugId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SimpleIntegerFromJSON(jsonValue));
    }

    /**
     */
    async countMessages(ugId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SimpleInteger> {
        const response = await this.countMessagesRaw({ ugId: ugId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async countUnreadRaw(requestParameters: CountUnreadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SimpleInteger>> {
        if (requestParameters.ugId === null || requestParameters.ugId === undefined) {
            throw new runtime.RequiredError('ugId','Required parameter requestParameters.ugId was null or undefined when calling countUnread.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/teamchat/{ugId}/count-unread`.replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters.ugId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SimpleIntegerFromJSON(jsonValue));
    }

    /**
     */
    async countUnread(ugId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SimpleInteger> {
        const response = await this.countUnreadRaw({ ugId: ugId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMessagesRaw(requestParameters: GetMessagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TeamChatMessage>>> {
        if (requestParameters.ugId === null || requestParameters.ugId === undefined) {
            throw new runtime.RequiredError('ugId','Required parameter requestParameters.ugId was null or undefined when calling getMessages.');
        }

        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getMessages.');
        }

        if (requestParameters.pageSize === null || requestParameters.pageSize === undefined) {
            throw new runtime.RequiredError('pageSize','Required parameter requestParameters.pageSize was null or undefined when calling getMessages.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/teamchat/{ugId}/{page}/{pageSize}`.replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters.ugId))).replace(`{${"page"}}`, encodeURIComponent(String(requestParameters.page))).replace(`{${"pageSize"}}`, encodeURIComponent(String(requestParameters.pageSize))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TeamChatMessageFromJSON));
    }

    /**
     */
    async getMessages(ugId: string, page: number, pageSize: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TeamChatMessage>> {
        const response = await this.getMessagesRaw({ ugId: ugId, page: page, pageSize: pageSize }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTeamChatRaw(requestParameters: GetTeamChatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TeamChat>> {
        if (requestParameters.ugId === null || requestParameters.ugId === undefined) {
            throw new runtime.RequiredError('ugId','Required parameter requestParameters.ugId was null or undefined when calling getTeamChat.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/teamchat/{ugId}`.replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters.ugId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamChatFromJSON(jsonValue));
    }

    /**
     */
    async getTeamChat(ugId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TeamChat> {
        const response = await this.getTeamChatRaw({ ugId: ugId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async publishRaw(requestParameters: PublishRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult>> {
        if (requestParameters.ugId === null || requestParameters.ugId === undefined) {
            throw new runtime.RequiredError('ugId','Required parameter requestParameters.ugId was null or undefined when calling publish.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/teamchat/{ugId}/publish`.replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters.ugId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResultFromJSON(jsonValue));
    }

    /**
     */
    async publish(ugId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult> {
        const response = await this.publishRaw({ ugId: ugId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async unpublishRaw(requestParameters: UnpublishRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult>> {
        if (requestParameters.ugId === null || requestParameters.ugId === undefined) {
            throw new runtime.RequiredError('ugId','Required parameter requestParameters.ugId was null or undefined when calling unpublish.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/teamchat/{ugId}/unpublish`.replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters.ugId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResultFromJSON(jsonValue));
    }

    /**
     */
    async unpublish(ugId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult> {
        const response = await this.unpublishRaw({ ugId: ugId }, initOverrides);
        return await response.value();
    }

}
