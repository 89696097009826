import * as R from 'ramda';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, FormGroup, Input, Label, Table } from 'reactstrap';
import { withNavLocation } from '../../../reactutils/withNavLocation';
import { withTitle } from '../../../reactutils/withTitle';
import { Blog } from '../../../services/image';
import { ButtonWithConfirmation } from '../../common/ButtonWithConfirmation';
import { DateTime } from '../../DateTime';
import { isForumAdminSelector } from '../../System/selectors';
import { blogCreate, blogDelete, blogLoadList } from '../actions';
import { BlogLink } from '../BlogLink';
import { blogListSelector } from '../selectors';

const BlogListComponent: FC<{}> = () => {
    const blogList = useSelector(blogListSelector);
    const isForumAdmin = useSelector(isForumAdminSelector);

    const [blogName, setBlogName] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(blogLoadList());
    }, [dispatch]);

    const createBlog = () => {
        dispatch(blogCreate(blogName));
        setBlogName('');
    };

    const deleteBlog = (blogId: string) => {
        dispatch(blogDelete(blogId));
    };

    const renderBlog = (blog: Blog) => {
        return (
            <tr key={blog.id}>
                <td>
                    <BlogLink blogId={blog.id}>{blog.title}</BlogLink>
                </td>
                <td>{blog.latestEntry && <DateTime date={blog.latestEntry} />}</td>
                {isForumAdmin && (
                    <td>
                        <ButtonWithConfirmation
                            color="danger"
                            message={`Sind Sie sicher, dass Sie den Blog '${blog.title}' löschen wollen?`}
                            title="Blog löschen"
                            onOK={() => deleteBlog(blog.id)}
                        >
                            Löschen
                        </ButtonWithConfirmation>
                    </td>
                )}
            </tr>
        );
    };

    return (
        <>
            {isForumAdmin && (
                <Form className="mb-2">
                    <FormGroup className="mb-2 me-sm-2 mb-sm-0">
                        <Label for="blogName" className="me-sm-2">
                            Name
                        </Label>
                        <Input
                            type="text"
                            name="blogName"
                            id="blogName"
                            placeholder="Blog Titel"
                            value={blogName}
                            onChange={e => {
                                setBlogName(e.currentTarget.value);
                            }}
                        />
                    </FormGroup>
                    <Button color="success" onClick={createBlog} disabled={!blogName}>
                        neuen Blog anlegen
                    </Button>
                </Form>
            )}
            <Table>
                <tbody>{blogList && blogList.map(b => renderBlog(b))}</tbody>
            </Table>
        </>
    );
};

export const blogListPath = () => '/blog/list';

export const BlogList = R.compose(withTitle('Blogs'), withNavLocation('profile'))(BlogListComponent);
