import { AnyAction } from 'typescript-fsa';
import { isType } from 'typescript-fsa/dist/typescript-fsa';
import { HighscoreStrategyDescriptor } from '../../../services/betting';
import { hsdEditSet, hsdEditSetUgName, hsdEditSetAvailableStandardStrategies } from './actions';

export interface HsdEditState {
    hsd?: HighscoreStrategyDescriptor;
    ugName?: string;
    availableStandardDescriptors: HighscoreStrategyDescriptor[];
}

const initialHsdEditState: HsdEditState = {
    availableStandardDescriptors: []
};

export function hsdEditReducer(state: HsdEditState = initialHsdEditState, action: AnyAction) {
    if (isType(action, hsdEditSet)) {
        return {...state, hsd: action.payload};
    } else if (isType(action, hsdEditSetUgName)) {
        return {...state, ugName: action.payload};
    } else if (isType(action, hsdEditSetAvailableStandardStrategies)) {
        return {...state, availableStandardDescriptors: action.payload};
    }
    return state;
}
