/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Device,
  DeviceToken,
  Message,
} from '../models';
import {
    DeviceFromJSON,
    DeviceToJSON,
    DeviceTokenFromJSON,
    DeviceTokenToJSON,
    MessageFromJSON,
    MessageToJSON,
} from '../models';

export interface AddDeviceTokenRequest {
    tenant: string;
    accessToken: string;
    deviceToken: string;
    devicePlatform: string;
    deviceName: string;
}

export interface DeleteDeviceTokenRequest {
    tenant: string;
    deviceTokenId?: string;
    deviceToken?: string;
    accessToken?: string;
}

export interface GetDevicesForUserRequest {
    userId: string;
}

/**
 * 
 */
export class DeviceControllerApi extends runtime.BaseAPI {

    /**
     */
    async addDeviceTokenRaw(requestParameters: AddDeviceTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeviceToken>> {
        if (requestParameters.tenant === null || requestParameters.tenant === undefined) {
            throw new runtime.RequiredError('tenant','Required parameter requestParameters.tenant was null or undefined when calling addDeviceToken.');
        }

        if (requestParameters.accessToken === null || requestParameters.accessToken === undefined) {
            throw new runtime.RequiredError('accessToken','Required parameter requestParameters.accessToken was null or undefined when calling addDeviceToken.');
        }

        if (requestParameters.deviceToken === null || requestParameters.deviceToken === undefined) {
            throw new runtime.RequiredError('deviceToken','Required parameter requestParameters.deviceToken was null or undefined when calling addDeviceToken.');
        }

        if (requestParameters.devicePlatform === null || requestParameters.devicePlatform === undefined) {
            throw new runtime.RequiredError('devicePlatform','Required parameter requestParameters.devicePlatform was null or undefined when calling addDeviceToken.');
        }

        if (requestParameters.deviceName === null || requestParameters.deviceName === undefined) {
            throw new runtime.RequiredError('deviceName','Required parameter requestParameters.deviceName was null or undefined when calling addDeviceToken.');
        }

        const queryParameters: any = {};

        if (requestParameters.accessToken !== undefined) {
            queryParameters['access_token'] = requestParameters.accessToken;
        }

        if (requestParameters.deviceToken !== undefined) {
            queryParameters['device_token'] = requestParameters.deviceToken;
        }

        if (requestParameters.devicePlatform !== undefined) {
            queryParameters['device_platform'] = requestParameters.devicePlatform;
        }

        if (requestParameters.deviceName !== undefined) {
            queryParameters['device_name'] = requestParameters.deviceName;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/device/{tenant}/device-token`.replace(`{${"tenant"}}`, encodeURIComponent(String(requestParameters.tenant))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeviceTokenFromJSON(jsonValue));
    }

    /**
     */
    async addDeviceToken(tenant: string, accessToken: string, deviceToken: string, devicePlatform: string, deviceName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeviceToken> {
        const response = await this.addDeviceTokenRaw({ tenant: tenant, accessToken: accessToken, deviceToken: deviceToken, devicePlatform: devicePlatform, deviceName: deviceName }, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteDeviceTokenRaw(requestParameters: DeleteDeviceTokenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Message>> {
        if (requestParameters.tenant === null || requestParameters.tenant === undefined) {
            throw new runtime.RequiredError('tenant','Required parameter requestParameters.tenant was null or undefined when calling deleteDeviceToken.');
        }

        const queryParameters: any = {};

        if (requestParameters.deviceTokenId !== undefined) {
            queryParameters['deviceTokenId'] = requestParameters.deviceTokenId;
        }

        if (requestParameters.deviceToken !== undefined) {
            queryParameters['device_token'] = requestParameters.deviceToken;
        }

        if (requestParameters.accessToken !== undefined) {
            queryParameters['access_token'] = requestParameters.accessToken;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/device/{tenant}/device-token`.replace(`{${"tenant"}}`, encodeURIComponent(String(requestParameters.tenant))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MessageFromJSON(jsonValue));
    }

    /**
     */
    async deleteDeviceToken(tenant: string, deviceTokenId?: string, deviceToken?: string, accessToken?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Message> {
        const response = await this.deleteDeviceTokenRaw({ tenant: tenant, deviceTokenId: deviceTokenId, deviceToken: deviceToken, accessToken: accessToken }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getDevicesForUserRaw(requestParameters: GetDevicesForUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Device>>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getDevicesForUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/device/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DeviceFromJSON));
    }

    /**
     */
    async getDevicesForUser(userId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Device>> {
        const response = await this.getDevicesForUserRaw({ userId: userId }, initOverrides);
        return await response.value();
    }

}
