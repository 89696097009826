import React, { FC } from 'react';

export const truncateText = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
    } else {
        return text;
    }
}

interface Props {
    text: string;
    maxLength?: number;
}
export const TruncateText: FC<Props> = props => {
    const length = props.maxLength ? props.maxLength : 10;
        return <span>{truncateText(props.text, length)}</span>;
};
