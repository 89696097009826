import React, { FC, useEffect, useState } from 'react';
import { getTournamentSysAdminApi } from '../../../../System/systemConfig';
import { useQuery } from '@tanstack/react-query';
import { TournamentRound } from '../../../../../services/betting';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { TournamentRoundManagement } from '../TournamentRoundManagement';

interface TournamentManagementProps {
    tId: string;
    onClose: () => void;
}

export const TournamentManagement: FC<TournamentManagementProps> = (props) => {
    const api = getTournamentSysAdminApi();

    const [tournamentRounds, setTournamentRounds] = useState<TournamentRound[]>([]);
    const [selectedTournamentRoundId, setSelectedTournamentRoundId] = useState('');
    const [selectedTournamentRound, setSelectedTournamentRound] = useState<TournamentRound>();

    const tournamentRoundsQuery = useQuery({
        queryKey: ['tournamentRounds.get', props.tId],
        queryFn: () => api.getTournamentRounds({ tId: props.tId }),
    });

    useEffect(() => {
        setTournamentRounds(tournamentRoundsQuery.data ?? []);
        if (tournamentRoundsQuery.data && tournamentRoundsQuery.data.length > 0 && selectedTournamentRoundId === '') {
            setSelectedTournamentRoundId(String(tournamentRoundsQuery.data[0].id));
        }
    }, [selectedTournamentRoundId, tournamentRoundsQuery.data]);

    useEffect(() => {
        if (selectedTournamentRoundId) {
            setSelectedTournamentRound(tournamentRounds.find((tr) => String(tr.id) === selectedTournamentRoundId));
        }
    }, [selectedTournamentRoundId, tournamentRounds]);

    const renderTournamentRound = (tr: TournamentRound) => {
        return (
            <span className="m-1" key={tr.id}>
                {selectedTournamentRoundId !== String(tr.id) && (
                    <Button
                        className="p-0 m-0"
                        color="link"
                        onClick={() => setSelectedTournamentRoundId(String(tr.id))}
                    >
                        {tr.name}
                    </Button>
                )}
                {selectedTournamentRoundId === String(tr.id) && <span className="fw-bold text-warning">{tr.name}</span>}
            </span>
        );
    };

    const tournamentRoundManagementRefresh = () => {
        tournamentRoundsQuery.refetch();
    };

    return (
        <div className="border">
            <div className="d-flex flex-wrap small align-items-center">
                <Button size="sm" onClick={props.onClose}>
                    <FontAwesomeIcon icon={faClose} />
                </Button>
                {tournamentRounds.map((tr) => renderTournamentRound(tr))}
            </div>
            {selectedTournamentRoundId && (
                <TournamentRoundManagement
                    trId={selectedTournamentRoundId}
                    tr={selectedTournamentRound}
                    onRefresh={tournamentRoundManagementRefresh}
                />
            )}
        </div>
    );
};
