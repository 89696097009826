import * as React from 'react';
import { Link } from 'react-router-dom';

interface Props extends React.PropsWithChildren{
    ugId: number;
    maxWidth?: number;
}

export const userGroupAdminPath = (ugId: string | number) => `/b/user-group/${ugId}/admin`;

export class UserGroupAdminLink extends React.Component<Props> {
    public render() {
        return (
            <>
                <Link to={userGroupAdminPath(this.props.ugId)}
                      style={{ maxWidth: this.props.maxWidth+'px' }}
                      className="text-truncate"
                >
                    {this.props.children}
                </Link>
            </>
        );
    }
}
