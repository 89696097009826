import { Action } from 'redux';
import { isType } from 'typescript-fsa';
import { BetQuote, Game, GameBet, GameBetInfo, Tournament, TournamentRound } from '../../services/betting';
import {
    bettingSetBetQuote,
    bettingSetBetQuoteGame,
    bettingSetGameInfo,
    bettingSetLoading,
    bettingSetTournament,
    bettingSetTournamentRound,
    bettingSetTrId,
    bettingShowBetQuote,
    setGameBets,
    updateBetState,
    updateBetValues,
    updateGameBet
} from './actions';
import { BetState } from './components/BetComponent/index';

export interface BettingStore {
    loading: boolean;
    trId: string;
    tournament?: Tournament;
    tournamentRound?: TournamentRound;
    games: Game[];
    gameBets: GameBet[];
    betState: { [gameId: number]: BetState };
    gameInfos: { [gameId: number]: GameBetInfo };
    betQuote: BetQuote;
    betQuoteGame: GameBet;
    showBetQuote: boolean;
}

const initialBettingStore: BettingStore = {
    loading: true,
    trId: '',
    games: [],
    gameBets: [],
    betState: {},
    gameInfos: {},
    betQuote: {
        bet1: -1,
        bet2: -1,
        gameId: 0,
        differenceCount: 0,
        differenceDisabled: false,
        differenceFit: false,
        differencePoints: 0,
        points: 0,
        resultCount: 0,
        resultDisabled: false,
        resultFit: false,
        resultPoints: 0,
        tendencyCount: 0,
        tendencyDisabled: false,
        tendencyFit: false,
        tendencyPoints: 0,
        betCount: 0
    },
    betQuoteGame: {
        betAllowed: false,
        finished: false,
        gameId: 0,
        date: new Date(),
        team1: {
            id: '',
            name: '',
            sportId: '',
        },
        team2: {
            id: '',
            name: '',
            sportId: '',
        },
        hasResult: false
    },
    showBetQuote: false
};

export function bettingReducer(state: BettingStore = initialBettingStore, action: Action): BettingStore {
    if (isType(action, setGameBets)) {
        let gameBets: GameBet[] = action.payload;
        let betState: { [gameId: number]: BetState } = {};
        gameBets.forEach(gb => {
            if (gb.bet1 !== null && gb.bet2 !== null) {
                betState[gb.gameId] = BetState.loaded;
            } else {
                betState[gb.gameId] = BetState.empty;
            }
        });
        return { ...state, gameBets: gameBets, betState };
    } else if (isType(action, updateGameBet)) {
        let gameBet: GameBet = action.payload;
        let updatedGameBets: GameBet[] = state.gameBets.map(gb => {
            if (gb.gameId === gameBet.gameId) {
                return gameBet;
            } else {
                return gb;
            }
        });
        return Object.assign({}, state, { gameBets: updatedGameBets });
    } else if (isType(action, updateBetState)) {
        let betStateInfo: { gameId: number; betState: BetState } = action.payload;
        let newBetState = Object.assign({}, state.betState);
        newBetState[betStateInfo.gameId] = betStateInfo.betState;
        return Object.assign({}, state, { betState: newBetState });
    } else if (isType(action, updateBetValues)) {
        let betValues: { gameId: number; bet1: number; bet2: number } = action.payload;
        let newGameBets: GameBet[] = state.gameBets.map(gb => {
            if (gb.gameId === betValues.gameId) {
                return Object.assign({}, gb, { bet1: betValues.bet1, bet2: betValues.bet2 });
            } else {
                return gb;
            }
        });
        return Object.assign({}, state, { gameBets: newGameBets });
    } else if (isType(action, bettingSetGameInfo)) {
        const gameInfo = action.payload;
        const gameInfos = Object.assign({}, state.gameInfos);
        gameInfos[gameInfo.gameId] = gameInfo;
        return Object.assign({}, state, { gameInfos: gameInfos });
    } else if (isType(action, bettingSetBetQuote)) {
        return Object.assign({}, state, { betQuote: action.payload });
    } else if (isType(action, bettingShowBetQuote)) {
        return Object.assign({}, state, { showBetQuote: action.payload });
    } else if (isType(action, bettingSetBetQuoteGame)) {
        return Object.assign({}, state, { betQuoteGame: action.payload });
    } else if (isType(action, bettingSetTrId)) {
        return { ...state, trId: action.payload };
    } else if (isType(action, bettingSetTournament)) {
        return { ...state, tournament: action.payload };
    } else if (isType(action, bettingSetTournamentRound)) {
        return { ...state, tournamentRound: action.payload };
    } else if (isType(action, bettingSetLoading)) {
        return { ...state, loading: action.payload };
    }

    return state;
}
