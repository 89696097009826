/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BlogId
 */
export interface BlogId {
    /**
     * 
     * @type {string}
     * @memberof BlogId
     */
    id?: string;
}

/**
 * Check if a given object implements the BlogId interface.
 */
export function instanceOfBlogId(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BlogIdFromJSON(json: any): BlogId {
    return BlogIdFromJSONTyped(json, false);
}

export function BlogIdFromJSONTyped(json: any, ignoreDiscriminator: boolean): BlogId {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
    };
}

export function BlogIdToJSON(value?: BlogId | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
    };
}

