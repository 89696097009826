import { all, takeEvery } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { fetchWrapperFuncNoLoading } from '../../../utils';
import { getFeedbackApi2, getSystemConfig } from '../../System/systemConfig';
import { UserFeedback, USER_FEEDBACK_SEND } from './actions';

function* userFeedbackSendGenerator(action: Action<UserFeedback>) {
    const feedback: UserFeedback = action.payload;
    const api = getFeedbackApi2();
    yield* fetchWrapperFuncNoLoading(() =>
        api.sendFeedback({
            tenant: getSystemConfig().clientName,
            sender: feedback.sender,
            subject: feedback.subject,
            message: feedback.message,
        }),
    );
}

function* watchUserFeedback() {
    yield takeEvery(USER_FEEDBACK_SEND, userFeedbackSendGenerator);
}

export function* userFeedbackSagas() {
    yield all([watchUserFeedback()]);
}
