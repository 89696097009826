import { push } from '@lagunovsky/redux-react-router';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { myProfilePath } from '../../../screens/UserProfileScreen';
import { bettingPath } from '../../Betting';
import { systemSetLoginRequired } from '../../System/actions';
import { userIdSelector, userNameSelector } from '../../System/selectors';
import { MenuButtonSelected } from '../SubMenuLink';

export const MenuUserState: FC<{}> = () => {
    const userName = useSelector(userNameSelector);
    const userId = useSelector(userIdSelector);
    const dispatch = useDispatch();

    const onLogin = () => {
        dispatch(systemSetLoginRequired({ nextUrl: bettingPath('') }));
    };

    const onProfileClick = () => {
        dispatch(push(myProfilePath()));
    }

    return (
        <>
            {userId && (
                <>
                    <MenuButtonSelected onClick={onProfileClick}>Mein Profil</MenuButtonSelected>
                    {/*                 <div
                    style={{
                        borderColor: 'lightgray',
                        borderWidth: '0px',
                        borderRadius: '5px',
                        borderStyle: 'solid',
                        backgroundColor: '#66ccff',
                        color: 'white'
                    }}
                    className="px-3 py-1"
                >
                    <UserLink id={userId} linkToMyProfile={true} />
                </div>
 */}
                </>
            )}
            {!userName && (
                <Button size="sm" onClick={onLogin} color="success" outline={false}>
                    Anmelden
                </Button>
            )}
        </>
    );
};
