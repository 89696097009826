import React, { FC } from 'react';
import { IncrementalSearch, KeyNameValue } from '../../../../common/IncrementalSearch';
import { getTournamentSysAdminApi } from '../../../../System/systemConfig';
import { useQuery } from '@tanstack/react-query';

interface Props {
    onSelected: (tId: string) => void;
};

export const IncrementalTournamentSysadminSearch: FC<Props> = (props) => {
    const api = getTournamentSysAdminApi();

    const tournamentQuery = useQuery({
        queryKey: ['tournament.search'],
        queryFn: () => api.searchTournaments({name: ''}),
    });

    const onSelected = (v: KeyNameValue) => {
        props.onSelected(v.key);
    };

    const onSearchChange = (s: string): KeyNameValue[] => {
        return (
            tournamentQuery.data
                ?.filter((t) => t.name.toLowerCase().indexOf(s.toLowerCase()) !== -1)
                .map((t) => ({ key: String(t.id), name: t.name })) || []
        );
    };

    return (
        <div className="m-0 w-100 ps-0">
            <IncrementalSearch
                isLoaded={() => !tournamentQuery.isFetching}
                initialSearchValue={''}
                onSelected={onSelected}
                selectList={onSearchChange}
            />
        </div>
    );
};
