import { push } from '@lagunovsky/redux-react-router';
import moment from 'moment';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { addAccessTokenQueryParam } from '../../../utils';
import { clientAdminIndexPath } from '../../ClientAdminApp';
import { playlistAdminPath } from '../../PlaylistAdminApp';
import { sysAdminCachePath } from '../../SysAdminApp/sysAdminPathes';
import { isClientAdminSelector, isPlaylistAdminSelector, isSysAdminSelector } from '../../System/selectors';
import { getVersionNumber } from '../../System/system';
import { isLocal } from '../../System/systemConfig';

export const gotoClientAdminUrl = (token: string) => {
    window.location.href = getClientAdminUrl(token);
};

export const getClientAdminUrl = (token: string) => {
    if (isLocal()) {
        return addAccessTokenQueryParam('http://radioeins.local.funtip.de:8080/adm/', token);
    } else {
        return addAccessTokenQueryParam('https://redaktion.funtip.de/adm/', token);
    }
};

export const R1MobileFooter: FC<{}> = () => {
    const isClientAdmin = useSelector(isClientAdminSelector);
    const isPlaylistAdmin = useSelector(isPlaylistAdminSelector);
    const isSysAdmin = useSelector(isSysAdminSelector);

    const dispatch = useDispatch();

    return (
        <>
            <div className="bg-light border-top mt-2">
                <small className="mt-0 p-0 ms-3">
                    radioeins Community (©{moment().format('YYYY')} funtip), v{getVersionNumber()}
                </small>
                {isClientAdmin && (
                    <>
                        <Link color="link" to={clientAdminIndexPath()}>
                            <small>Client-Administration</small>
                        </Link>
                        <small>|</small>
                    </>
                )}
                {isPlaylistAdmin && (
                    <>
                        <Button color="link" onClick={() => dispatch(push(playlistAdminPath()))}>
                            <small>Playlist-Admin</small>
                        </Button>
                        <small>|</small>
                    </>
                )}
                {isSysAdmin && (
                    <>
                        <Button
                            color="link"
                            onClick={() => {
                                dispatch(push(sysAdminCachePath('betting')));
                            }}
                        >
                            <small>SysAdmin</small>
                        </Button>
                        <small>|</small>
                    </>
                )}
            </div>
        </>
    );
};
