/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.25
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserProfileContactData
 */
export interface UserProfileContactData {
    /**
     * 
     * @type {number}
     * @memberof UserProfileContactData
     */
    userId: number;
    /**
     * 
     * @type {string}
     * @memberof UserProfileContactData
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileContactData
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileContactData
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileContactData
     */
    street: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileContactData
     */
    streetNumber: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileContactData
     */
    zipCode: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileContactData
     */
    country: string;
    /**
     * 
     * @type {string}
     * @memberof UserProfileContactData
     */
    phone: string;
}

/**
 * Check if a given object implements the UserProfileContactData interface.
 */
export function instanceOfUserProfileContactData(value: object): value is UserProfileContactData {
    if (!('userId' in value) || value['userId'] === undefined) return false;
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('city' in value) || value['city'] === undefined) return false;
    if (!('street' in value) || value['street'] === undefined) return false;
    if (!('streetNumber' in value) || value['streetNumber'] === undefined) return false;
    if (!('zipCode' in value) || value['zipCode'] === undefined) return false;
    if (!('country' in value) || value['country'] === undefined) return false;
    if (!('phone' in value) || value['phone'] === undefined) return false;
    return true;
}

export function UserProfileContactDataFromJSON(json: any): UserProfileContactData {
    return UserProfileContactDataFromJSONTyped(json, false);
}

export function UserProfileContactDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserProfileContactData {
    if (json == null) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'city': json['city'],
        'street': json['street'],
        'streetNumber': json['streetNumber'],
        'zipCode': json['zipCode'],
        'country': json['country'],
        'phone': json['phone'],
    };
}

export function UserProfileContactDataToJSON(value?: UserProfileContactData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'userId': value['userId'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'city': value['city'],
        'street': value['street'],
        'streetNumber': value['streetNumber'],
        'zipCode': value['zipCode'],
        'country': value['country'],
        'phone': value['phone'],
    };
}

