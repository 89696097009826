import React, { FC, useState } from 'react'
import { ClientTournament } from '../../../../../services/betting';
import { getTournamentSysAdminApi } from '../../../../System/systemConfig';
import { useMutation } from '@tanstack/react-query';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faFutbol } from '@fortawesome/free-solid-svg-icons';
import { ClientTournamentEdit } from '../ClientTournamentEdit';

interface ClientTournamentStateProps {
    tId: string;
    clId: string;
    ct: ClientTournament | undefined;
    showReorderButtons: boolean;
    onRefresh: () => void;
}
export const ClientTournamentState: FC<ClientTournamentStateProps> = (props) => {
    const [showForm, setShowForm] = useState(false);

    const api = getTournamentSysAdminApi();
    const upDownMutation = useMutation({
        mutationFn: (upDown: string) => api.changeOrderClientTournament({tId: props.tId, clId: props.clId, direction: upDown}),

        onSuccess(data, variables, context) {
            props.onRefresh();
        },
    });

    let color = 'blue';
    let title = 'unknown';
    switch (props.ct?.state) {
        case 1:
            color = 'green';
            title = 'active';
            break; // active
        case 2:
            color = 'blue';
            title = 'inactive';
            break; // inactive
        case 4:
            color = 'orange';
            title = 'on-hold';
            break; // on-hold
        default:
            color = 'gray';
            title = 'unknown';
    }

    const afterEdit = () => {
        setShowForm(false);
        props.onRefresh();
    };

    const up = () => {
        upDownMutation.mutate('up');
    };

    const down = () => {
        upDownMutation.mutate('down');
        props.onRefresh();
    };

    return (
        <>
            {!showForm && (
                <>
                    <Button color="link" onClick={() => setShowForm(true)}>
                        <FontAwesomeIcon icon={faFutbol} color={color} title={title} />
                    </Button>
                    ({props.ct?.order})
                    {props.showReorderButtons && (
                        <>
                            <Button onClick={up}>
                                <FontAwesomeIcon icon={faArrowUp} />
                            </Button>
                            <Button onClick={down}>
                                <FontAwesomeIcon icon={faArrowDown} />
                            </Button>
                        </>
                    )}
                </>
            )}
            {showForm && <ClientTournamentEdit clId={props.clId} tId={String(props.tId)} onClose={afterEdit} />}
        </>
    );
};