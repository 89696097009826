import { AnyAction, isType } from 'typescript-fsa';
import { UserProfileSettings } from '../../../services/account';
import { TournamentSubscription } from '../../../services/betting';
import {
    accountUserSettingClearProfileSettings,
    accountUserSettingSetDeleteAccountMessage,
    accountUserSettingSetProfileSettings,
    accountUserSettingSetSubscribedTournaments
} from './actions';
import { EmailChangeState } from './ChangeEmail';
import { PasswordChangeState } from './ChangePassword';
import { DeleteProfileResult } from './DeleteProfile';

export interface AccountUserSettingsState {
    subscribedTournaments: TournamentSubscription[];
    userProfileSettings: UserProfileSettings;

    passwordChangeMessage: string;
    passwordChangeState: PasswordChangeState;

    emailChangeMessage: string;
    emailChangeState: EmailChangeState;

    deleteAccountMessage: string;
    deleteAccountState: DeleteProfileResult;
}

const initialAccountUserSettingsState: AccountUserSettingsState = {
    subscribedTournaments: [],
    userProfileSettings: {
        infoMails: false,
        newsletter: false,
        pushNotifications: false,
        emailValid: true,
        userId: 0
    },
    passwordChangeMessage: '',
    passwordChangeState: undefined,
    emailChangeMessage: '',
    emailChangeState: undefined,
    deleteAccountMessage: '',
    deleteAccountState: undefined
};

export function accountUserSettingsReducer(
    state: AccountUserSettingsState = initialAccountUserSettingsState,
    action: AnyAction
): AccountUserSettingsState {
    if (isType(action, accountUserSettingSetSubscribedTournaments)) {
        return Object.assign({}, state, { subscribedTournaments: action.payload });
    } else if (isType(action, accountUserSettingSetProfileSettings)) {
        return Object.assign({}, state, { userProfileSettings: action.payload });
    } else if (isType(action, accountUserSettingClearProfileSettings)) {
        return Object.assign({}, state, { userProfileSettings: initialAccountUserSettingsState.userProfileSettings });
    } else if (isType(action, accountUserSettingSetDeleteAccountMessage)) {
        return Object.assign({}, state, {
            deleteAccountMessage: action.payload.msg,
            deleteAccountState: action.payload.state
        });
    }
    return state;
}
