import { actionCreatorFactory } from 'typescript-fsa';
import { Bet, BetQuote, GameBet, GameBetInfo, Tournament, TournamentRound } from '../../services/betting';
import { BetState } from './components/BetComponent/index';

const actionCreator = actionCreatorFactory();

export const BETTING_SET_GAME_BETS = 'BETTING_SET_GAME_BETS';
export const setGameBets = actionCreator<GameBet[]>('BETTING_SET_GAME_BETS');

export const updateGameBet = actionCreator<GameBet>('UPDATE_GAME_BET');

export const updateBetState = actionCreator<{ gameId: number; betState: BetState }>('UPDATE_BET_STATE');

export const updateBetValues = actionCreator<{ gameId: number; bet1: number; bet2: number }>('UPDATE_BET_VALUES');

export const BETTING_LOAD = 'BETTING_LOAD';
export const bettingLoad = actionCreator<number>(BETTING_LOAD);

export const BETTING_SAVE_BET = 'BETTING_SAVE_BET';
export const saveBet = actionCreator<Bet>(BETTING_SAVE_BET);

export const BETTING_LOAD_GAME_INFO = 'BETTING_LOAD_GAME_INFO';
export const bettingLoadGameInfo = actionCreator<number>(BETTING_LOAD_GAME_INFO);

export const BETTING_SET_GAME_INFO = 'BETTING_SET_GAME_INFO';
export const bettingSetGameInfo = actionCreator<GameBetInfo>(BETTING_SET_GAME_INFO);

export interface BetQuoteRequest {
    gameId: number;
    bet1: number;
    bet2: number;
    ugId?: number;
}

export const BETTING_LOAD_BET_QUOTE = 'BETTING_LOAD_BET_QUOTE';
export const bettingLoadBetQuote = actionCreator<BetQuoteRequest>(BETTING_LOAD_BET_QUOTE);

export const BETTING_SET_LOADING = 'BETTING_SET_LOADING';
export const bettingSetLoading = actionCreator<boolean>(BETTING_SET_LOADING);

export const BETTING_SET_BET_QUOTE = 'BETTING_SET_BET_QUOTE';
export const bettingSetBetQuote = actionCreator<BetQuote>(BETTING_SET_BET_QUOTE);

export const BETTING_SHOW_BET_QUOTE = 'BETTING_SHOW_BET_QUOTE';
export const bettingShowBetQuote = actionCreator<Boolean>(BETTING_SHOW_BET_QUOTE);

export const BETTING_SET_BET_QUOTE_GAME = 'BETTING_SET_BET_QUOTE_GAME';
export const bettingSetBetQuoteGame = actionCreator<GameBet>(BETTING_SET_BET_QUOTE_GAME);

export const BETTING_WITHOUT_PROPS = 'BETTING_WITHOUT_PROPS';
export const bettingWithoutProps = actionCreator(BETTING_WITHOUT_PROPS);

export const BETTING_SET_TR_ID = 'BETTING_SET_TR_ID';
export const bettingSetTrId = actionCreator<string>(BETTING_SET_TR_ID);

export const BETTING_SET_TR = 'BETTING_SET_TR';
export const bettingSetTournamentRound = actionCreator<TournamentRound>(BETTING_SET_TR);

export const BETTING_SET_T = 'BETTING_SET_T';
export const bettingSetTournament = actionCreator<Tournament>(BETTING_SET_T);
