/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.25
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PushSubscriptionKeys } from './PushSubscriptionKeys';
import {
    PushSubscriptionKeysFromJSON,
    PushSubscriptionKeysFromJSONTyped,
    PushSubscriptionKeysToJSON,
} from './PushSubscriptionKeys';

/**
 * 
 * @export
 * @interface FuntipPushSubscription2
 */
export interface FuntipPushSubscription2 {
    /**
     * 
     * @type {string}
     * @memberof FuntipPushSubscription2
     */
    endpoint?: string;
    /**
     * 
     * @type {number}
     * @memberof FuntipPushSubscription2
     */
    expirationTime?: number;
    /**
     * 
     * @type {PushSubscriptionKeys}
     * @memberof FuntipPushSubscription2
     */
    keys?: PushSubscriptionKeys;
}

/**
 * Check if a given object implements the FuntipPushSubscription2 interface.
 */
export function instanceOfFuntipPushSubscription2(value: object): value is FuntipPushSubscription2 {
    return true;
}

export function FuntipPushSubscription2FromJSON(json: any): FuntipPushSubscription2 {
    return FuntipPushSubscription2FromJSONTyped(json, false);
}

export function FuntipPushSubscription2FromJSONTyped(json: any, ignoreDiscriminator: boolean): FuntipPushSubscription2 {
    if (json == null) {
        return json;
    }
    return {
        
        'endpoint': json['endpoint'] == null ? undefined : json['endpoint'],
        'expirationTime': json['expirationTime'] == null ? undefined : json['expirationTime'],
        'keys': json['keys'] == null ? undefined : PushSubscriptionKeysFromJSON(json['keys']),
    };
}

export function FuntipPushSubscription2ToJSON(value?: FuntipPushSubscription2 | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'endpoint': value['endpoint'],
        'expirationTime': value['expirationTime'],
        'keys': PushSubscriptionKeysToJSON(value['keys']),
    };
}

