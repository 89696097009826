import { useMutation, useQuery } from '@tanstack/react-query';
import * as R from 'ramda';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { withBorder } from '../../../reactutils/withBorder';
import { withLogin } from '../../../reactutils/withLogin';
import { withNavLocation } from '../../../reactutils/withNavLocation';
import { withTitle } from '../../../reactutils/withTitle';
import { EmailConfirmationTeaser } from '../../AccountUserApp/EmailConfirmationTeaser';
import { InvalidEmailTeaser } from '../../AccountUserApp/InvalidEmailTeaser';
import { NewsletterTeaser } from '../../AccountUserApp/NewsletterTeaser';
import { ProfileSubMenu } from '../../AccountUserApp/ProfileSubMenu';
import { Section, SectionHeading } from '../../common/Section';
import { systemSetPopupMessage } from '../../System/actions';
import { userNameSelector } from '../../System/selectors';
import { getBettingUserApi2 } from '../../System/systemConfig';
import {
    bettingProfileAddFavoriteTeam,
    bettingProfileDeleteFavoriteTeam,
    bettingProfileLoadBettingProfile,
} from './actions';
import { FavoriteTeams } from './FavoriteTeams';
import { ProfileMyResults } from './ProfileMyResults';
import { ProfileUserGroups } from './ProfileUserGroups';
import { bettingProfileAvailableTeamsSelector, bettingProfileFavoriteTeamsSelector } from './selectors';

const BettingProfileComponent: React.FC<{}> = (props) => {
    const favoriteTeams = useSelector(bettingProfileFavoriteTeamsSelector);
    const availableTeams = useSelector(bettingProfileAvailableTeamsSelector);

    const dispatch = useDispatch();

    const bettingProfileQuery = useQuery({
        queryKey: ['bettingProfile'],
        queryFn: () => getBettingUserApi2().getBettingUserProfile(),
    });

    const onLoad = React.useCallback(() => {
        dispatch(bettingProfileLoadBettingProfile());
    }, [dispatch]);

    const onAddFavoriteTeam = (teamId: string) => {
        dispatch(bettingProfileAddFavoriteTeam(teamId));
    };
    const onDeleteFavoriteTeam = (teamId: string) => {
        dispatch(bettingProfileDeleteFavoriteTeam(teamId));
    };

    const updateBettingVisibilityMutation = useMutation({
        mutationFn: (visible: boolean) => getBettingUserApi2().updateUserBettingPublic({ value: visible }),
    });

    const bettingVisibilityChange = (ev: React.FormEvent<HTMLInputElement>) => {
        updateBettingVisibilityMutation.mutate(ev.currentTarget.checked, {
            onSuccess: () => {
                bettingProfileQuery.refetch();
            },
            onError: (error) => {
                dispatch(systemSetPopupMessage('Die Einstellungen konnten nicht gespeichert werden. ' + error));
            },
        });
    };

    React.useEffect(() => {
        onLoad();
    }, [onLoad]);

    return (
        <>
            <ProfileSubMenu subMenu="betting" />
            <EmailConfirmationTeaser />
            <NewsletterTeaser />
            <InvalidEmailTeaser />
            <Section>
                <SectionHeading>Sichtbarkeit</SectionHeading>
                <Form>
                    <FormGroup check={true} inline={true}>
                        <Label check={true}>
                            <Input
                                type="checkbox"
                                name="bettingPublic"
                                checked={bettingProfileQuery.data?.bettingDataPublic}
                                onChange={bettingVisibilityChange}
                                className="me-3"
                            />
                            Meine Lieblingsteams und Tippteams sind für alle sichtbar
                        </Label>
                    </FormGroup>
                </Form>
            </Section>
            <FavoriteTeams
                favoriteTeams={favoriteTeams}
                availableTeams={availableTeams}
                onAdd={onAddFavoriteTeam}
                onDelete={onDeleteFavoriteTeam}
            />
            <ProfileUserGroups />
            <ProfileMyResults />
        </>
    );
};

export const BettingProfileScreen = R.compose(
    withBorder(),
    withTitle('Tippspiel', userNameSelector),
    withNavLocation('profile'),
    withLogin(),
)(BettingProfileComponent);
