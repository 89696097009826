import { faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Table } from 'reactstrap';
import { Team, TournamentGroupMember } from '../../../../../../services/betting';
import { getTournamentSysAdminApi } from '../../../../../System/systemConfig';
import { TeamLink } from '../../../../../TeamLink';
import { ButtonWithConfirmation } from '../../../../../common/ButtonWithConfirmation';
import { IncrementalSearch, KeyNameValue } from '../../../../../common/IncrementalSearch';
import { Section, SectionHeading } from '../../../../../common/Section';

interface TournamentGroupMemberEditProps {
    tgId: string;
}

export const TournamentGroupMemberEdit: FC<TournamentGroupMemberEditProps> = (props) => {
    const api = getTournamentSysAdminApi();

    const [tournamentGroupMember, setTournamentGroupMember] = useState<TournamentGroupMember[]>([]);
    const [availableTeams, setAvailableTeams] = useState<Team[]>([]);

    const tournamentGroupMemberQuery = useQuery({
        queryKey: ['tournamentGroupMember.getByTgId', props.tgId],
        queryFn: () => api.getTournamentGroupMember({tgId: props.tgId}),
    });

    useEffect(() => {
        setTournamentGroupMember(tournamentGroupMemberQuery.data ?? []);
    }, [tournamentGroupMemberQuery.data]);

    const getFilteredTeams = (s: string): KeyNameValue[] => {
        return availableTeams
            .filter((t) => t.name.toLowerCase().indexOf(s.toLowerCase()) !== -1)
            .map((t) => {
                return { key: t.id, name: t.name };
            });
    };

    const availableTeamsQuery = useQuery({
        queryKey: ['tournamentGroupMember.availableTeams', props.tgId],
        queryFn: () => api.getAvailableTeams({tgId: props.tgId}),
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        setAvailableTeams(availableTeamsQuery.data ?? []);
    }, [availableTeamsQuery.data]);

    const deleteTournamentGroupMemberMutation = useMutation({
        mutationFn: (tgmId: string) => api.deleteTournamentGroupMember({tgId: props.tgId, tgmId}),
        onSuccess: () => tournamentGroupMemberQuery.refetch(),
        onError(error, variables, context) {
            alert('Tournament Group Member cant be deleted because of existing Rankings');
        },
    });

    const addTournamentGroupMemberMutation = useMutation({
        mutationFn: (params: { tgId: string; teamId: string }) =>
            api.addTournamentGroupMember({tgId: params.tgId, teamId: params.teamId}),

        onSuccess: () => tournamentGroupMemberQuery.refetch(),
        onError(error, variables, context) {
            alert('Tournament Group Member cant be added');
        },
    });
    
    const renderTournamentGroupMember = (tgm: TournamentGroupMember, index: number) => {
        return (
            <tr key={tgm.id}>
                <td>{index + 1}</td>
                <td>
                    <TeamLink teamId={String(tgm.teamId)} />
                </td>
                <td>
                    <ButtonWithConfirmation
                        color="link"
                        onOK={() => deleteTournamentGroupMemberMutation.mutate(String(tgm.id))}
                        message={`Are you sure you want to delete Team '${tgm.teamId} of the Tournament Group'?`}
                        title="Delete Team of Tournament Group"
                    >
                        <FontAwesomeIcon icon={faX} title="Delete" />
                    </ButtonWithConfirmation>
                </td>
            </tr>
        );
    };

    const onAdd = (v: KeyNameValue) => {
        addTournamentGroupMemberMutation.mutate({ tgId: props.tgId, teamId: v.key });
    };

    return (
        <>
            <Section>
                <SectionHeading>Teams in Group</SectionHeading>
                <Table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Team</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>{tournamentGroupMember.map((tgm, index) => renderTournamentGroupMember(tgm, index))}</tbody>
                </Table>
                <span>Add Team:</span>
                <IncrementalSearch selectList={getFilteredTeams} isLoaded={() => !!availableTeams} onSelected={onAdd} />
            </Section>
        </>
    );
};
