import { actionCreatorFactory } from 'typescript-fsa';
import { BettingUser } from '../../services/betting';

const actionCreator = actionCreatorFactory();

export const setUserSearchString = actionCreator<string>('SET_USER_SEARCH_STRING');

export const setUserList = actionCreator<BettingUser[]>('SET_USER_LIST');

export const setUserListCount = actionCreator<number>('SET_USER_LIST_COUNT');

export const setUserListStartPos = actionCreator<number>('SET_USER_LIST_START_POS');

export const setUserPriorEnabled = actionCreator<Boolean>('SET_USER_PRIOR_ENABLED');

export const setUserNextEnabled = actionCreator<Boolean>('SET_USER_NEXT_ENABLED');

export const USER_LIST_FETCH = 'USER_LIST_FETCH';
export const fetchUser = actionCreator(USER_LIST_FETCH);

export const USER_LIST_COUNT = 'USER_LIST_COUNT';
export const countUser = actionCreator(USER_LIST_COUNT);

export const USER_LIST_RESET_PAGING = 'USER_LIST_RESET_PAGING';
export const resetPagingActions = actionCreator(USER_LIST_RESET_PAGING);

export const USER_LIST_SEARCH = 'USER_LIST_SEARCH';
export const searchUsers = actionCreator<string>(USER_LIST_SEARCH);

export const USER_LIST_PAGE_NEXT = 'USER_LIST_PAGE_NEXT';
export const pageNextUsers = actionCreator(USER_LIST_PAGE_NEXT);

export const USER_LIST_PAGE_PRIOR = 'USER_LIST_PAGE_PRIOR';
export const pagePriorUsers = actionCreator(USER_LIST_PAGE_PRIOR);

export const USER_LIST_FIRST = 'USER_LIST_FIRST';
export const pageFirstUsers = actionCreator(USER_LIST_FIRST);

export const USER_LIST_LAST = 'USER_LIST_LAST';
export const pageLastUsers = actionCreator(USER_LIST_LAST);
