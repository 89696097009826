import React, { FC } from 'react'
import { getSysAdminProfile2Api } from '../../../System/systemConfig';
import { useQuery } from '@tanstack/react-query';

interface UserEmailProps {
    userId: string;
}

export const UserEmail:FC<UserEmailProps> = (props) => {

    const api = getSysAdminProfile2Api();

    const userEmailQuery = useQuery({
        queryKey: ['userEmail', props.userId],
        queryFn: () => api.getUserEmail({userId: props.userId}),
    });
    return (
        <div>
            {userEmailQuery.data}
        </div>
    )
}