import * as R from 'ramda';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Col, Container, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { withBorder } from '../../../reactutils/withBorder';
import { withLogin } from '../../../reactutils/withLogin';
import { withNavLocation } from '../../../reactutils/withNavLocation';
import { withTitle } from '../../../reactutils/withTitle';
import { UserProfile, UserProfileContactData } from '../../../services/account';
import { UserAdminButton } from '../../SysAdminApp/UserAdmin';
import { userNameSelector } from '../../System/selectors';
import { UserImage } from '../../UserImage';
import { ButtonWithConfirmation } from '../../common/ButtonWithConfirmation';
import { FileUpload } from '../../common/FileUpload';
import { LoadingIndicatorSpinner } from '../../common/LoadingIndicatorSpinner';
import { Section, SectionHeading } from '../../common/Section';
import { EmailConfirmationTeaser } from '../EmailConfirmationTeaser';
import { InvalidEmailTeaser } from '../InvalidEmailTeaser';
import { NewsletterTeaser } from '../NewsletterTeaser';
import { ProfileSubMenu } from '../ProfileSubMenu';
import {
    userProfileAssignImage,
    userProfileDeleteImage,
    userProfileLoad,
    userProfileSave,
    userProfileSaveContactData,
} from './actions';
import {
    userProfileAvailableCountriesSelector,
    userProfileContactDataSelector,
    userProfileEntitySelector,
} from './selectors';

const AccountUserProfileComponent: React.FC<{}> = () => {
    const profile = useSelector(userProfileEntitySelector);
    const contactData = useSelector(userProfileContactDataSelector);
    const availableCountries = useSelector(userProfileAvailableCountriesSelector);

    const initialState: UserProfileContactData = {
        city: '',
        country: '',
        firstName: '',
        lastName: '',
        phone: '',
        street: '',
        streetNumber: '',
        userId: 0,
        zipCode: '',
    };
    const [state, setState] = React.useState<UserProfileContactData>(initialState);

    const [editedProfile, setEditedProfile] = React.useState<UserProfile>(profile);

    React.useEffect(() => {
        setEditedProfile(profile);
    }, [profile]);

    React.useEffect(() => {
        setState(contactData);
    }, [contactData]);

    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(userProfileLoad());
    }, [dispatch]);

    const storeProfileContactData = () => {
        dispatch(userProfileSaveContactData(state));
    };

    const storeProfile = () => {
        dispatch(userProfileSave(editedProfile));
    };

    const onChange = (ev: React.FormEvent<HTMLInputElement>) => {
        setState({ ...state, [ev.currentTarget.name]: ev.currentTarget.value });
    };

    const onChangeProfile = (ev: React.FormEvent<HTMLInputElement>) => {
        setEditedProfile({ ...editedProfile, [ev.currentTarget.name]: ev.currentTarget.value });
    };

    const [updateImage, setUpdateImage] = React.useState(false);

    const imageUploaded = (imageId: string | undefined) => {
        if (imageId) {
            dispatch(userProfileAssignImage(imageId));
            setUpdateImage(false);
        }
    };

    const deleteImage = () => {
        dispatch(userProfileDeleteImage());
    };

    if (!state) {
        return (
            <Container className="m-4">
                <LoadingIndicatorSpinner />
            </Container>
        );
    } else {
        return (
            <>
                <ProfileSubMenu subMenu="profile" />
                <EmailConfirmationTeaser />
                <NewsletterTeaser />
                <InvalidEmailTeaser />
                <UserAdminButton userId={profile.id} />
                <Section>
                    <SectionHeading>Profil-Bild</SectionHeading>
                    <Form>
                        <Row>
                            <Col>{profile && <UserImage imageId={profile.imageId} />}</Col>
                            <Col>
                                {updateImage && (
                                    <>
                                        <FileUpload imageUploaded={imageUploaded} className="m-2" />
                                        <Button color="success" onClick={() => setUpdateImage(false)} className="m-2">
                                            Abbrechen
                                        </Button>
                                    </>
                                )}
                                {!updateImage && (
                                    <>
                                        <Button color="success" onClick={() => setUpdateImage(true)} className="m-2">
                                            Bild hochladen
                                        </Button>
                                        <ButtonWithConfirmation
                                            color="danger"
                                            onOK={deleteImage}
                                            title="Profilbild löschen"
                                            message="Sind Sie sicher, dass Sie das aktuelle Profilebild löschen wollen? Diese Aktion kann nicht rückgängig gemacht werden."
                                            className="m-2"
                                        >
                                            Bild löschen
                                        </ButtonWithConfirmation>
                                    </>
                                )}
                            </Col>
                        </Row>
                    </Form>
                </Section>

                {profile.r1Moderator && (
                    <>
                        <Section>
                            <SectionHeading>Zur Person</SectionHeading>
                            <Form>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="character">Zur Person</Label>
                                            <Input
                                                type="textarea"
                                                id="character"
                                                name="character"
                                                placeholder="Zur Person"
                                                value={editedProfile.character}
                                                onChange={onChangeProfile}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="note">Notiz</Label>
                                            <Input
                                                type="textarea"
                                                id="note"
                                                name="note"
                                                placeholder="Notiz"
                                                value={editedProfile.note}
                                                onChange={onChangeProfile}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="book">Lieblingsbücher</Label>
                                            <Input
                                                type="textarea"
                                                id="books"
                                                name="favoriteBook"
                                                placeholder="Lieblingsbücher"
                                                value={editedProfile.favoriteBook}
                                                onChange={onChangeProfile}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label for="movie">Lieblingsfilme</Label>
                                            <Input
                                                type="textarea"
                                                id="movie"
                                                name="favoriteMovie"
                                                placeholder="Lieblingsfilme"
                                                value={editedProfile.favoriteMovie}
                                                onChange={onChangeProfile}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Button
                                            onClick={storeProfile}
                                            disabled={editedProfile === profile}
                                            color="success"
                                        >
                                            Speichern
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Section>
                    </>
                )}
                <Section>
                    <SectionHeading>Kontaktdaten</SectionHeading>
                    <Alert color="primary" className="m-4">
                        Die folgenden Daten werden nicht veröffentlicht, sondern werden nur vom Administrator genutzt,
                        um Sie zu kontaktieren - z.B. wenn Sie im Tippspiel gewonnen haben.
                    </Alert>
                    <Form>
                        <Row>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label for="firstName">Vorname</Label>
                                    <Input
                                        type="text"
                                        id="firstName"
                                        name="firstName"
                                        placeholder="Vorname"
                                        value={state.firstName}
                                        onChange={onChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label for="lastName">Nachname</Label>
                                    <Input
                                        type="text"
                                        id="lastName"
                                        name="lastName"
                                        placeholder="Nachname"
                                        value={state.lastName}
                                        onChange={onChange}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={10}>
                                <FormGroup>
                                    <Label for="street">Straße</Label>
                                    <Input
                                        type="text"
                                        id="street"
                                        name="street"
                                        placeholder="Straße"
                                        value={state.street}
                                        onChange={onChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={2}>
                                <FormGroup>
                                    <Label for="number">Hausnummer</Label>
                                    <Input
                                        type="text"
                                        id="number"
                                        name="streetNumber"
                                        placeholder="Nr."
                                        value={state.streetNumber}
                                        onChange={onChange}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                <FormGroup>
                                    <Label for="country">Land</Label>
                                    <Input
                                        type="select"
                                        id="country"
                                        name="country"
                                        placeholder="Land"
                                        value={state.country}
                                        onChange={onChange}
                                    >
                                        {availableCountries &&
                                            availableCountries.map((c) => <option key={c}>{c}</option>)}
                                    </Input>
                                </FormGroup>
                            </Col>
                            <Col sm={2}>
                                <FormGroup>
                                    <Label for="zipCode">PLZ</Label>
                                    <Input
                                        type="text"
                                        id="zipCode"
                                        name="zipCode"
                                        placeholder="PLZ"
                                        value={state.zipCode}
                                        onChange={onChange}
                                    />
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup>
                                    <Label for="city">Ort</Label>
                                    <Input
                                        type="text"
                                        id="city"
                                        name="city"
                                        placeholder="Ort"
                                        value={state.city}
                                        onChange={onChange}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                <FormGroup>
                                    <Label for="phone">Telefon</Label>
                                    <Input
                                        type="text"
                                        id="phone"
                                        name="phone"
                                        placeholder="Telefon"
                                        value={state.phone}
                                        onChange={onChange}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button
                                    onClick={storeProfileContactData}
                                    disabled={contactData === state}
                                    color="success"
                                >
                                    Speichern
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Section>
                {/* <AccountUserDevices /> */}
            </>
        );
    }
};

export const AccountUserProfileScreen = R.compose(
    withLogin(),
    withBorder(),
    withTitle('Profil', userNameSelector),
    withNavLocation('profile'),
)(AccountUserProfileComponent);
