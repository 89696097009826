import { GlobalState } from '../../actions';

const bettingProfileSelector = (state: GlobalState) => state.bettingProfile;

export const bettingProfileBettingPublicSelector = (state: GlobalState) => bettingProfileSelector(state).bettingPublic;

export const bettingProfileFavoriteTeamsSelector = (state: GlobalState) => bettingProfileSelector(state).favoriteTeams;

export const bettingProfileAvailableTeamsSelector = (state: GlobalState) =>
    bettingProfileSelector(state).availableTeams;

export const bettingProfileMyGroupMembersSelector = (state: GlobalState) => bettingProfileSelector(state).myGroupMember;

export const bettingProfileMyHighscoresSelector = (state: GlobalState) => bettingProfileSelector(state).highscoreInfos;

export const bettingProfileMessageSelector = (state: GlobalState) => bettingProfileSelector(state).message;
export const bettingProfileMessageLevelSelector = (state: GlobalState) => bettingProfileSelector(state).messageLevel;
export const bettingProfileHasMessageSelector = (state: GlobalState) =>
    bettingProfileSelector(state).message != null && bettingProfileSelector(state).message !== '';

export const bettingProfileMyDefaultGroupSelector = (state: GlobalState) => bettingProfileSelector(state).myDefaultGroup;
