import { useQuery } from '@tanstack/react-query';
import { FC, useState } from 'react';
import { Button, FormGroup, Input, Label, Table } from 'reactstrap';
import { useDebounce } from 'use-debounce';
import { Team } from '../../../../services/betting';
import { getTeamSysadminApi } from '../../../System/systemConfig';
import { LoadingIndicatorContent } from '../../../common/LoadingIndicatorSpinner';
import { Section, SectionHeading } from '../../../common/Section';
import { ModalTeamEditComp } from './TeamEdit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

export const SysAdminTeamAppComp: FC<{}> = () => {
    const [teamsFilter, setTeamsFilter] = useState('');
    const [teamEdited, setTeamEdited] = useState('');
    const [createTeam, setCreateTeam] = useState(false);

    const [debouncedTeamsFilter] = useDebounce(teamsFilter, 1000);

    const api = getTeamSysadminApi();

    const teamsQuery = useQuery({
        queryKey: ['teams', debouncedTeamsFilter],
        queryFn: () => api.filterTeams({filter: debouncedTeamsFilter}),
    });

    const inTransition = teamsQuery.isLoading || teamsQuery.isFetching || teamsFilter !== debouncedTeamsFilter;

    const onTeamClick = (teamId: string) => {
        setTeamEdited(teamId);
    };

    const renderTeam = (team: Team) => {
        return (
            <tr key={team.id}>
                <td>
                    <Button onClick={() => onTeamClick(team.id)} color="link">
                        {team.name}
                    </Button>
                </td>
                <td className="d-none d-sm-table-cell">{team.shortName}</td>
                <td className="d-none d-lg-table-cell">{team.miniName}</td>
                <td>{team.sportName}</td>
            </tr>
        );
    };

    const onAddTeam = () => {
        setCreateTeam(true);
    };

    const onCloseCreate = () => {
        setCreateTeam(false);
        teamsQuery.refetch();
    };

    const onCloseEdit = () => {
        setTeamEdited('');
        teamsQuery.refetch();
    };

    return (
        <>
            <Section>
                <SectionHeading>Teams</SectionHeading>
                <FormGroup check={false}>
                    <Label>Filter</Label>
                    <Input type="text" value={teamsFilter} onChange={(e) => setTeamsFilter(e.currentTarget.value)} />
                </FormGroup>
                <Button color="primary" onClick={onAddTeam}>
                    <FontAwesomeIcon icon={faPlus} />
                </Button>
                {createTeam && <ModalTeamEditComp teamId={undefined} onClose={onCloseCreate} />}
                {teamEdited && <ModalTeamEditComp teamId={teamEdited} onClose={onCloseEdit} />}
                <LoadingIndicatorContent isLoading={inTransition}>
                    <Table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th className="d-none d-sm-table-cell">Short Name</th>
                                <th className="d-none d-lg-table-cell">Mini Name</th>
                                <th>Sport</th>
                            </tr>
                        </thead>
                        <tbody>{teamsQuery.data?.map((t) => renderTeam(t))}</tbody>
                    </Table>
                </LoadingIndicatorContent>
            </Section>
        </>
    );
};
