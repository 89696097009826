/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.25
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const Role = {
    User: 'user',
    Sysadmin: 'sysadmin',
    Langadmin: 'langadmin',
    Clientadmin: 'clientadmin',
    PlaylistAdmin: 'playlistAdmin',
    NewsletterAdmin: 'newsletterAdmin',
    ForumAdmin: 'forumAdmin'
} as const;
export type Role = typeof Role[keyof typeof Role];


export function instanceOfRole(value: any): boolean {
    for (const key in Role) {
        if (Object.prototype.hasOwnProperty.call(Role, key)) {
            if ((Role as Record<string, Role>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function RoleFromJSON(json: any): Role {
    return RoleFromJSONTyped(json, false);
}

export function RoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Role {
    return json as Role;
}

export function RoleToJSON(value?: Role | null): any {
    return value as any;
}

