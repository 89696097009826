import React, { FC } from 'react';
import { getExternalKeyApi } from '../../System/systemConfig';
import { useQuery } from '@tanstack/react-query';
import { Section, SectionHeading } from '../../common/Section';
import { Button } from 'reactstrap';

interface ExternalKeyViewAndEditProps {
    entityType: string;
    entityId: string;
    onClose?: () => void;
}

export const ExternalKeyViewAndEdit: FC<ExternalKeyViewAndEditProps> = (props) => {
    const api = getExternalKeyApi();

    const externalKeyQuery = useQuery({
        queryKey: ['externalKey', props.entityType, props.entityId],
        queryFn: () => api.getExternalKeysByEntity({entityId: Number(props.entityId), type: props.entityType}),
    });
    return (
        <div>
            <Section>
                <SectionHeading>
                    External Keys for '{props.entityType}' / '{props.entityId}'
                </SectionHeading>
                {externalKeyQuery.isLoading && <div>Loading...</div>}
                {externalKeyQuery.isError && <div>Error loading external keys</div>}
                {externalKeyQuery.isSuccess && (
                    <div>
                        {externalKeyQuery.data.map((externalKey) => (
                            <div key={externalKey.id}>
                                {externalKey.provider} - {externalKey.key}
                            </div>
                        ))}
                    </div>
                )}
                {props.onClose && (
                    <Button className="mt-2" color="success" onClick={props.onClose}>
                        Close
                    </Button>
                )}
            </Section>
        </div>
    );
};
