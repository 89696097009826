import { faEnvelope, faQuestionCircle, faUserCog, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Table } from 'reactstrap';
import UserLink from '../../links/UserLink';
import { GroupMember, UserGroupDetail } from '../../services/betting';
import { ButtonWithConfirmation } from '../common/ButtonWithConfirmation';
import { Section } from '../common/Section';
import { DateStr } from '../DateTime';

interface Props {
    groupMembers: GroupMember[];
    userGroupDetail: UserGroupDetail;
    onRefuseInvitation: (ugId: number) => void;
    onAcceptInvitation: (ugId: number) => void;
    onLeaveUserGroup: (ugId: number) => void;
    onWithdrawMembershipRequest: (ugId: number) => void;
    loginUserId: string;
}

export class UserGroupMemberList extends React.Component<Props> {
    showLeaveGroupPrivilegeButton(gm: GroupMember) {
        if (gm.admin) {
            return false;
        }
        if (gm.state !== 'active') {
            return false;
        }
        if (!this.loginUserIsMember(gm)) {
            return false;
        }
        return true;
    }
    showInvitationPrivilegeButton(gm: GroupMember) {
        if (gm.state !== 'invited') {
            return false;
        }
        if (this.props.loginUserId !== String(gm.userId)) {
            return false;
        }
        return true;
    }
    showAskForMembershipPrivilegeButton(gm: GroupMember) {
        if (gm.state !== 'askForMembership') {
            return false;
        }
        if (this.props.loginUserId !== String(gm.userId)) {
            return false;
        }
        return true;
    }
    loginUserIsMember(gm: GroupMember) {
        if (this.props.loginUserId === String(gm.userId)) {
            return true;
        }
        return false;
    }
    showGrantAdminPrivilegeButton(gm: GroupMember) {
        if (gm.admin) {
            return false;
        }
        if (gm.state !== 'active') {
            return false;
        }
        return true;
    }

    public render() {
        return (
            <Section>
                <p className="my-4">
                    <b>Gründungsdatum:</b> <DateStr date={this.props.userGroupDetail.date} />
                </p>
                <Table className={'members'}>
                    <thead>
                        <tr>
                            <th> Mitglieder</th>
                            <th>Status</th>
                            <th>Aktionen</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.groupMembers.map((gm: GroupMember) => {
                            return (
                                <tr key={gm.userId}>
                                    <td>
                                        {gm.state === 'invited' && <span />}
                                        {gm.invitationEmail && gm.invitationEmail}
                                        {!gm.invitationEmail && <UserLink id={String(gm.userId)} name={gm.userName} showTrophies={true}/>}
                                    </td>
                                    <td>
                                        {gm.state === 'active' && gm.admin && (
                                            <FontAwesomeIcon icon={faUserCog} title="Administrator" />
                                        )}
                                        {gm.state === 'active' && !gm.admin && (
                                            <FontAwesomeIcon icon={faUserFriends} title="Mitglied" />
                                        )}
                                        {gm.state === 'invited' && (
                                            <FontAwesomeIcon icon={faEnvelope} title="Einladung" />
                                        )}
                                        {gm.state === 'askForMembership' && (
                                            <FontAwesomeIcon icon={faQuestionCircle} title="Mitgliedsanfrage" />
                                        )}
                                    </td>
                                    <td>
                                        {gm.state === 'active' && this.showLeaveGroupPrivilegeButton(gm) && (
                                            <ButtonWithConfirmation
                                                title={'Aus Team austreten'}
                                                message={'Wollen Sie aus dem Team austreten?'}
                                                color={'link'}
                                                onOK={() => this.props.onLeaveUserGroup(gm.userGroupId)}
                                            >
                                                Aus dem Team austreten
                                            </ButtonWithConfirmation>
                                        )}
                                        {gm.state === 'invited' && this.showInvitationPrivilegeButton(gm) && (
                                            <div>
                                                <ButtonWithConfirmation
                                                    title={'Einladung ablehnen'}
                                                    message={'Wollen Sie die Einladung ablehnen?'}
                                                    color={'link'}
                                                    onOK={() => this.props.onRefuseInvitation(gm.userGroupId)}
                                                >
                                                    Einladung ablehnen
                                                </ButtonWithConfirmation>

                                                <ButtonWithConfirmation
                                                    title={'Einladung annehmen '}
                                                    message={'Wollen Sie die Einladung annehmen?'}
                                                    color={'link'}
                                                    onOK={() => this.props.onAcceptInvitation(gm.userGroupId)}
                                                >
                                                    Einladung annehmen
                                                </ButtonWithConfirmation>
                                            </div>
                                        )}
                                        {gm.state === 'askForMembership' &&
                                            this.showAskForMembershipPrivilegeButton(gm) && (
                                                <div>
                                                    <ButtonWithConfirmation
                                                        title={'Anfrage zurückziehen'}
                                                        message={'Wollen Sie Ihre Mitgliedschaftsanfrage zurückziehen?'}
                                                        color={'link'}
                                                        onOK={() =>
                                                            this.props.onWithdrawMembershipRequest(gm.userGroupId)
                                                        }
                                                    >
                                                        Mitgliedsanfrage zurückziehen
                                                    </ButtonWithConfirmation>
                                                </div>
                                            )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </Section>
        );
    }
}
