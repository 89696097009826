import { faFutbol, faNewspaper, faTrophy, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import { Button } from 'reactstrap';
import { withBorder } from '../../../reactutils/withBorder';
import { withFooter } from '../../../reactutils/withFooter';
import { withLogin } from '../../../reactutils/withLogin';
import { withMenu } from '../../../reactutils/withMenu';
import { MenuClientAdmin } from '../MenuClientAdmin';
import { useNavigate } from 'react-router';
import { clientAdminBetOverviewPath, clientAdminRoundWinnerPath, clientAdminUserAdminSearchPath, newsletterListPath } from '..';

const ClientAdminIndex = () => {

    const navigate = useNavigate();

    return (
        <div style={{ minHeight: '250px' }}>
            <Button className="m-4 p-4" onClick={() => navigate(newsletterListPath())}>
                <FontAwesomeIcon icon={faNewspaper} size="xl" />
                <br />
                Newsletter-Administration
            </Button>
            <Button className="m-4 p-4" onClick={() => navigate(clientAdminBetOverviewPath())}>
                <FontAwesomeIcon icon={faFutbol} size="xl" />
                <br />
                Tippspiel-Statistik
            </Button>
            <Button className="m-4 p-4" onClick={() => navigate(clientAdminUserAdminSearchPath())}>
                <FontAwesomeIcon icon={faUser} size="xl" />
                <br />
                Nutzer-Administration
            </Button>
            <Button className="m-4 p-4" onClick={() => navigate(clientAdminRoundWinnerPath(''))}>
                <FontAwesomeIcon icon={faTrophy} size="xl" />
                <br />
                Spieltages-Gewinner
            </Button>
        </div>
    );
};

export const ClientAdminIndexComp = R.compose(
    withMenu(<MenuClientAdmin />),
    withBorder(),
    withLogin(),
    withFooter(),
)(ClientAdminIndex);
