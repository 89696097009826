import { faRefresh, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import * as R from 'ramda';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Table } from 'reactstrap';
import { withBorder } from '../../../reactutils/withBorder';
import { withLogin } from '../../../reactutils/withLogin';
import { withMenu } from '../../../reactutils/withMenu';
import { withTitle } from '../../../reactutils/withTitle';
import { Mailout, MailoutState } from '../../../services/webapp';
import { MenuSysAdmin } from '../../MenuSysAdmin';
import { systemSetPopupMessage } from '../../System/actions';
import { getMailoutApi } from '../../System/systemConfig';
import { ButtonWithConfirmation } from '../../common/ButtonWithConfirmation';
import { LoadingIndicatorSpinner2 } from '../../common/LoadingIndicatorSpinner2';
import { SysAdminSystemSubMenu } from '../SysAdminSubMenu';

const MailoutComponent: React.FC = () => {
    const api = getMailoutApi();

    const mailoutQuery = useQuery({
        queryKey: ['mailouts'],
        queryFn: () => api.getMailouts(),
    });

    const deleteMailoutMutation = useMutation({
        mutationFn: (id: string) => api.deleteMailout({id}),
    });

    const refreshMailoutMutation = useMutation({
        mutationFn: (id: string) => api.refreshMailout({id}),
    });

    const nullSafeDateFmt = (d: Date | undefined) => {
        if (!!d) {
            return DateTime.fromJSDate(d).toFormat('dd.MM.yy HH:mm');
        } else {
            return '-';
        }
    };

    const stateIcon = (state: MailoutState) => {
        switch (state) {
            case 'building':
                return (
                    <small>
                        <div className="px-2 py-1 rounded border bg-dark text-white">{state}</div>
                    </small>
                );
            case 'built':
                return (
                    <small>
                        <div className="px-2 py-1 rounded border bg-success text-white">{state}</div>
                    </small>
                );
            case 'sending':
                return (
                    <small>
                        <div className="px-2 py-1 rounded border bg-danger text-white">{state}</div>
                    </small>
                );
            case 'sent':
                return (
                    <small>
                        <div className="px-2 py-1 rounded border bg-light text-black">{state}</div>
                    </small>
                );
        }
    };

    const dispatch = useDispatch();

    const onDelete = (id: string) => {
        deleteMailoutMutation.mutate(id, {
            onSuccess() {
                mailoutQuery.refetch();
                dispatch(systemSetPopupMessage('Mailout successfully deleted.'));
            },
            onError() {
                dispatch(systemSetPopupMessage('Error deleting Mailout.'));
            },
        });
    };

    const onRefresh = (id: string) => {
        refreshMailoutMutation.mutate(id, {
            onSuccess() {
                mailoutQuery.refetch();
                dispatch(systemSetPopupMessage('Mailout successfully refreshed.'));
            },
            onError() {
                dispatch(systemSetPopupMessage('Error refreshing Mailout.'));
            },
        });
    }

    const numFormat = new Intl.NumberFormat('de-DE', { style: 'decimal', maximumFractionDigits: 2, minimumFractionDigits: 2 });

    const renderMailout = (mo: Mailout) => {
        return (
            <tr key={mo.id}>
                <td>{mo.name}</td>
                <td>{mo.subject}</td>
                <td>{stateIcon(mo.state)}</td>
                <td className="text-nowrap">
                    {mo.entriesSentCount ?? 0} / {mo.entryCount ?? 0}{' '}
                </td>
                <td className="text-nowrap">
                    {nullSafeDateFmt(mo.time)}
                    <br />
                    {nullSafeDateFmt(mo.validTo)}
                </td>
                <td>{mo.clientName}</td>
                <td>{mo.language}</td>
                <td>{mo.contentType}</td>
                <td>
                    <small>
                        <table>
                            <tbody>
                                <tr>
                                    <td>Start:</td>
                                    <td>{nullSafeDateFmt(mo.sendStart)}</td>
                                </tr>
                                <tr>
                                    <td>End:</td>
                                    <td>{nullSafeDateFmt(mo.sendEnd)}</td>
                                </tr>
                                <tr>
                                    <td>Threads:</td>
                                    <td>{mo.threadCount ?? '-'}</td>
                                </tr>
                                <tr>
                                    <td>Rate:</td>
                                    <td>{mo.sendRate ? numFormat.format(mo.sendRate) : '-'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </small>
                </td>
                <td>
                    <ButtonWithConfirmation
                        color="link"
                        className="border m-1"
                        title="Delete Mailout"
                        message={`Are you sure you want to delete the Mailout '${mo.subject}'?`}
                        onOK={() => onDelete(mo.id)}
                    >
                        <FontAwesomeIcon icon={faTrash} color="red" />
                    </ButtonWithConfirmation>
                    <Button color="link" className="border m-1" onClick={() => onRefresh(mo.id)}>
                        <FontAwesomeIcon icon={faRefresh} color="black" />
                    </Button>
                </td>
            </tr>
        );
    };

    return (
        <div>
            <LoadingIndicatorSpinner2 isLoading={deleteMailoutMutation.isPending || mailoutQuery.isFetching} />

            <Table responsive={true}>
                <thead>
                    <tr>
                        <th>Type</th>
                        <th>Subject</th>
                        <th>State</th>
                        <th>Entries</th>
                        <th>
                            Created
                            <br />
                            Valid Thru
                        </th>
                        <th>Client</th>
                        <th>Lang</th>
                        <th>Format</th>
                        <th>Sending Info</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>{mailoutQuery.data?.map((mo) => renderMailout(mo))}</tbody>
            </Table>
        </div>
    );
};

export const MailoutComp = R.compose(
    withMenu(<MenuSysAdmin />),
    withBorder(),
    withMenu(<SysAdminSystemSubMenu />),
    withTitle('Mailouts'),
    withLogin(),
)(MailoutComponent);
