import { useMutation, useQuery } from '@tanstack/react-query';
import * as R from 'ramda';
import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Table } from 'reactstrap';
import { withBorder } from '../../../reactutils/withBorder';
import { withLogin } from '../../../reactutils/withLogin';
import { withMenu } from '../../../reactutils/withMenu';
import { withTitle } from '../../../reactutils/withTitle';
import { UpdateTask } from '../../../services/webapp';
import { ButtonWithConfirmation } from '../../common/ButtonWithConfirmation';
import { Section } from '../../common/Section';
import { MenuSysAdmin } from '../../MenuSysAdmin';
import { systemSetPopupMessage } from '../../System/actions';
import { getUpdateTaskApi } from '../../System/systemConfig';
import { SysAdminSystemSubMenu } from '../SysAdminSubMenu';
import { LoadingIndicatorSpinner2 } from '../../common/LoadingIndicatorSpinner2';

export const UpdateTaskComp: FC = () => {
    const api = getUpdateTaskApi();

    const dispatch = useDispatch();

    const updateTaskQuery = useQuery({
        queryKey: ['updatetasks'],
        queryFn: () => api.getUpdateTasks(),
    });

    const executeTaskMutation = useMutation({
        mutationFn: (taskId: string) => api.executeTask({ taskId }),
    });

    const onExecuteTask = (taskId: string) => {
        executeTaskMutation.mutate(taskId, {
            onSuccess: () => {
                dispatch(systemSetPopupMessage('Task erfolgreich ausgeführt'));
            },
            onError: () => {
                dispatch(systemSetPopupMessage('Task konnte nicht ausgeführt werden'));
            },
        });
    };

    const renderUpdateTask = (task: UpdateTask) => {
        return (
            <tr key={task.id} className="align-middle">
                <td>{task.name}</td>
                <td>{dateFmt.format(task.creationDate)}</td>
                <td>
                    <ButtonWithConfirmation
                        color="link"
                        title={`Task '${task.name}' ausführen?`}
                        message={`Sind Sie sicher, dass Sie den Task '${task.name}' ausführen wollen?`}
                        onOK={() => onExecuteTask(task.id)}
                    >
                        Execute
                    </ButtonWithConfirmation>
                </td>
            </tr>
        );
    };

    const dateFmt = new Intl.DateTimeFormat('de-DE');

    return (
        <Section>
            <LoadingIndicatorSpinner2 isLoading={updateTaskQuery.isFetching || executeTaskMutation.isPending} />
            <Table>
                <thead>
                    <tr>
                        <th>Task</th>
                        <th>Created</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>{updateTaskQuery.data?.map((task) => renderUpdateTask(task))}</tbody>
            </Table>
        </Section>
    );
};

export const UpdateTasksScreen = R.compose(
    withMenu(<MenuSysAdmin />),
    withBorder(),
    withMenu(<SysAdminSystemSubMenu />),
    withTitle('Update Tasks'),
    withLogin(),
)(UpdateTaskComp);
