import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { systemSetLoginRequired } from '../components/System/actions';
import { isLoggedInSelector } from '../components/System/selectors';

export const withLogin =
    <P extends object>(url?: string) =>
    (WrappedComponent: React.ComponentType<P>): React.ComponentType<P> =>
    (props: P) => {
        const isLoggedIn = useSelector(isLoggedInSelector);
        const dispatch = useDispatch();

        if (!isLoggedIn) {
            // TODO using the full path with hash and query doesn't work yet
            // const redirectUrl = url ? url : window.location.pathname + window.location.hash + window.location.search;
            const redirectUrl = url ? url : window.location.pathname;
            console.info('redirecting to: ' + redirectUrl);
            dispatch(systemSetLoginRequired({ nextUrl: redirectUrl }));
            // redirectToLogin(redirectUrl);
            return <div>Login required</div>;
        } else {
            return (
                <>
                    <WrappedComponent {...props} />
                </>
            ); 
        }
    };
