/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.25
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewsletterCount
 */
export interface NewsletterCount {
    /**
     * 
     * @type {number}
     * @memberof NewsletterCount
     */
    count?: number;
}

/**
 * Check if a given object implements the NewsletterCount interface.
 */
export function instanceOfNewsletterCount(value: object): value is NewsletterCount {
    return true;
}

export function NewsletterCountFromJSON(json: any): NewsletterCount {
    return NewsletterCountFromJSONTyped(json, false);
}

export function NewsletterCountFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewsletterCount {
    if (json == null) {
        return json;
    }
    return {
        
        'count': json['count'] == null ? undefined : json['count'],
    };
}

export function NewsletterCountToJSON(value?: NewsletterCount | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'count': value['count'],
    };
}

