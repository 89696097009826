import * as React from 'react';
import { Alert, Table } from 'reactstrap';
import { Team } from '../../../../services/betting';
import { Section, SectionHeading } from '../../../common/Section';
import { isDefaultSport } from '../../../System/systemConfig';
import { TeamLink } from '../../../TeamLink';

interface Props {
    favoriteTeams: Team[];
}

export class UserViewBettingFavoriteTeams extends React.Component<Props> {
    public renderTeam(team: Team) {
        return (
            <tr key={team.id}>
                <td>
                    <TeamLink teamId={team.id} /> {!isDefaultSport(team) && `(${team.sportName})`}
                </td>
            </tr>
        );
    }

    public render() {
        return (
            <Section>
                <SectionHeading>Lieblingsteams</SectionHeading>
                {this.props.favoriteTeams.length === 0 && (
                    <Alert color="info">Der Benutzer hat keine Lieblingsmannschaften gewählt.</Alert>
                )}
                {this.props.favoriteTeams.length > 0 && (
                    <Table striped={true}>
                        <thead>
                            <tr>
                                <th>Team</th>
                            </tr>
                        </thead>
                        <tbody>{this.props.favoriteTeams.map(t => this.renderTeam(t))}</tbody>
                    </Table>
                )}
            </Section>
        );
    }
}
