/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.25
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  NewsletterAdmin,
  NewsletterBlock,
  NewsletterLayout,
  NewsletterLayoutBlock,
  NewsletterReceiverFilter,
} from '../models/index';
import {
    NewsletterAdminFromJSON,
    NewsletterAdminToJSON,
    NewsletterBlockFromJSON,
    NewsletterBlockToJSON,
    NewsletterLayoutFromJSON,
    NewsletterLayoutToJSON,
    NewsletterLayoutBlockFromJSON,
    NewsletterLayoutBlockToJSON,
    NewsletterReceiverFilterFromJSON,
    NewsletterReceiverFilterToJSON,
} from '../models/index';

export interface CheckNewsletterBeforeSendingRequest {
    nlId: string;
}

export interface CopyNewsletterRequest {
    nlId: string;
}

export interface CountNewslettersRequest {
    clientId: string;
    filter?: string;
}

export interface CreateNewsletterRequest {
    layoutId: string;
    receiverFilterId?: string;
    title?: string;
}

export interface CreateNewsletterBlockRequest {
    nlId: string;
    imageId?: string;
    layoutBlockId?: string;
    link?: string;
    text?: string;
    title?: string;
}

export interface CreateNewsletterLayoutBlockRequest {
    nllId: string;
    name?: string;
    text?: string;
}

export interface CreateNewsletterReceiverFilterRequest {
    clId: string;
    emailFilter?: string;
    name?: string;
    nameFilter?: string;
}

export interface DeleteNewsletterRequest {
    nlId: string;
}

export interface DeleteNewsletterBlockRequest {
    nlbId: string;
}

export interface DeleteNewsletterLayoutRequest {
    nllId: string;
}

export interface DeleteNewsletterLayoutBlockRequest {
    nllbId: string;
}

export interface DeleteNewsletterReceiverFilterRequest {
    nlrfId: string;
}

export interface EmailPreviewRequest {
    nlId: string;
    email?: string;
}

export interface GetLayoutsRequest {
    clientId: string;
}

export interface GetNewsletterRequest {
    nlId: string;
}

export interface GetNewsletterBlockRequest {
    nlbId: string;
}

export interface GetNewsletterBlocksRequest {
    nlId: string;
}

export interface GetNewsletterLayoutRequest {
    nllId: string;
}

export interface GetNewsletterLayoutBlockRequest {
    nllbId: string;
}

export interface GetNewsletterLayoutBlocksRequest {
    nllId: string;
}

export interface GetNewsletterReceiverFilterRequest {
    nlrfId: string;
}

export interface GetReceiverFiltersRequest {
    clientId: string;
}

export interface NewsletterBlockDownRequest {
    nlbId: string;
}

export interface NewsletterBlockUpRequest {
    nlbId: string;
}

export interface RefreshNewsletterReceiverFilterCountRequest {
    nlrfId: string;
}

export interface SearchNewslettersRequest {
    clientId: string;
    filter?: string;
    offset?: number;
    pageSize?: number;
}

export interface SendNewsletterRequest {
    nlId: string;
}

export interface SetStateNewRequest {
    nlId: string;
}

export interface ToggleNewsletterStateRequest {
    nlId: string;
}

export interface UpdateHeaderFooterRequest {
    nlId: string;
    imageId?: string;
    link?: string;
    text?: string;
    title?: string;
    type?: string;
}

export interface UpdateNewsletterRequest {
    nlId: string;
    receiverFilterId?: string;
    title?: string;
}

export interface UpdateNewsletterBlockRequest {
    nlbId: string;
    imageId?: string;
    link?: string;
    text?: string;
    title?: string;
}

export interface UpdateNewsletterLayoutRequest {
    nllId: string;
    name?: string;
}

export interface UpdateNewsletterLayoutBlockRequest {
    nllbId: string;
    name?: string;
    text?: string;
}

export interface UpdateNewsletterLayoutHeaderFooterRequest {
    nllId: string;
    text?: string;
    type?: string;
}

export interface UpdateNewsletterReceiverFilterRequest {
    nlrfId: string;
    emailFilter?: string;
    name?: string;
    nameFilter?: string;
}

/**
 * 
 */
export class NewsletterAdminControllerApi extends runtime.BaseAPI {

    /**
     */
    async checkNewsletterBeforeSendingRaw(requestParameters: CheckNewsletterBeforeSendingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['nlId'] == null) {
            throw new runtime.RequiredError(
                'nlId',
                'Required parameter "nlId" was null or undefined when calling checkNewsletterBeforeSending().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/newsletter-admin/{nlId}/check`.replace(`{${"nlId"}}`, encodeURIComponent(String(requestParameters['nlId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async checkNewsletterBeforeSending(requestParameters: CheckNewsletterBeforeSendingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.checkNewsletterBeforeSendingRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async copyNewsletterRaw(requestParameters: CopyNewsletterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['nlId'] == null) {
            throw new runtime.RequiredError(
                'nlId',
                'Required parameter "nlId" was null or undefined when calling copyNewsletter().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/newsletter-admin/{nlId}/copy`.replace(`{${"nlId"}}`, encodeURIComponent(String(requestParameters['nlId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async copyNewsletter(requestParameters: CopyNewsletterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.copyNewsletterRaw(requestParameters, initOverrides);
    }

    /**
     */
    async countNewslettersRaw(requestParameters: CountNewslettersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['clientId'] == null) {
            throw new runtime.RequiredError(
                'clientId',
                'Required parameter "clientId" was null or undefined when calling countNewsletters().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/newsletter-admin/{clientId}/count`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters['clientId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async countNewsletters(requestParameters: CountNewslettersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.countNewslettersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createNewsletterRaw(requestParameters: CreateNewsletterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['layoutId'] == null) {
            throw new runtime.RequiredError(
                'layoutId',
                'Required parameter "layoutId" was null or undefined when calling createNewsletter().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['receiverFilterId'] != null) {
            queryParameters['receiverFilterId'] = requestParameters['receiverFilterId'];
        }

        if (requestParameters['title'] != null) {
            queryParameters['title'] = requestParameters['title'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/newsletter-admin/{layoutId}`.replace(`{${"layoutId"}}`, encodeURIComponent(String(requestParameters['layoutId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createNewsletter(requestParameters: CreateNewsletterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createNewsletterRaw(requestParameters, initOverrides);
    }

    /**
     */
    async createNewsletterBlockRaw(requestParameters: CreateNewsletterBlockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['nlId'] == null) {
            throw new runtime.RequiredError(
                'nlId',
                'Required parameter "nlId" was null or undefined when calling createNewsletterBlock().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['imageId'] != null) {
            queryParameters['imageId'] = requestParameters['imageId'];
        }

        if (requestParameters['layoutBlockId'] != null) {
            queryParameters['layoutBlockId'] = requestParameters['layoutBlockId'];
        }

        if (requestParameters['link'] != null) {
            queryParameters['link'] = requestParameters['link'];
        }

        if (requestParameters['text'] != null) {
            queryParameters['text'] = requestParameters['text'];
        }

        if (requestParameters['title'] != null) {
            queryParameters['title'] = requestParameters['title'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/newsletter-admin/newsletter-block/{nlId}`.replace(`{${"nlId"}}`, encodeURIComponent(String(requestParameters['nlId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createNewsletterBlock(requestParameters: CreateNewsletterBlockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createNewsletterBlockRaw(requestParameters, initOverrides);
    }

    /**
     */
    async createNewsletterLayoutBlockRaw(requestParameters: CreateNewsletterLayoutBlockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['nllId'] == null) {
            throw new runtime.RequiredError(
                'nllId',
                'Required parameter "nllId" was null or undefined when calling createNewsletterLayoutBlock().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['text'] != null) {
            queryParameters['text'] = requestParameters['text'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/newsletter-admin/newsletter-layout-block/{nllId}`.replace(`{${"nllId"}}`, encodeURIComponent(String(requestParameters['nllId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createNewsletterLayoutBlock(requestParameters: CreateNewsletterLayoutBlockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createNewsletterLayoutBlockRaw(requestParameters, initOverrides);
    }

    /**
     */
    async createNewsletterReceiverFilterRaw(requestParameters: CreateNewsletterReceiverFilterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['clId'] == null) {
            throw new runtime.RequiredError(
                'clId',
                'Required parameter "clId" was null or undefined when calling createNewsletterReceiverFilter().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['emailFilter'] != null) {
            queryParameters['emailFilter'] = requestParameters['emailFilter'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['nameFilter'] != null) {
            queryParameters['nameFilter'] = requestParameters['nameFilter'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/newsletter-admin/newsletter-receiver-filter/{clId}`.replace(`{${"clId"}}`, encodeURIComponent(String(requestParameters['clId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createNewsletterReceiverFilter(requestParameters: CreateNewsletterReceiverFilterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createNewsletterReceiverFilterRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteNewsletterRaw(requestParameters: DeleteNewsletterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['nlId'] == null) {
            throw new runtime.RequiredError(
                'nlId',
                'Required parameter "nlId" was null or undefined when calling deleteNewsletter().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/newsletter-admin/{nlId}`.replace(`{${"nlId"}}`, encodeURIComponent(String(requestParameters['nlId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteNewsletter(requestParameters: DeleteNewsletterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteNewsletterRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteNewsletterBlockRaw(requestParameters: DeleteNewsletterBlockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['nlbId'] == null) {
            throw new runtime.RequiredError(
                'nlbId',
                'Required parameter "nlbId" was null or undefined when calling deleteNewsletterBlock().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/newsletter-admin/newsletter-block/{nlbId}`.replace(`{${"nlbId"}}`, encodeURIComponent(String(requestParameters['nlbId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteNewsletterBlock(requestParameters: DeleteNewsletterBlockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteNewsletterBlockRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteNewsletterLayoutRaw(requestParameters: DeleteNewsletterLayoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['nllId'] == null) {
            throw new runtime.RequiredError(
                'nllId',
                'Required parameter "nllId" was null or undefined when calling deleteNewsletterLayout().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/newsletter-admin/newsletter-layout/{nllId}`.replace(`{${"nllId"}}`, encodeURIComponent(String(requestParameters['nllId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteNewsletterLayout(requestParameters: DeleteNewsletterLayoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteNewsletterLayoutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteNewsletterLayoutBlockRaw(requestParameters: DeleteNewsletterLayoutBlockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['nllbId'] == null) {
            throw new runtime.RequiredError(
                'nllbId',
                'Required parameter "nllbId" was null or undefined when calling deleteNewsletterLayoutBlock().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/newsletter-admin/newsletter-layout-block/{nllbId}`.replace(`{${"nllbId"}}`, encodeURIComponent(String(requestParameters['nllbId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteNewsletterLayoutBlock(requestParameters: DeleteNewsletterLayoutBlockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteNewsletterLayoutBlockRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteNewsletterReceiverFilterRaw(requestParameters: DeleteNewsletterReceiverFilterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['nlrfId'] == null) {
            throw new runtime.RequiredError(
                'nlrfId',
                'Required parameter "nlrfId" was null or undefined when calling deleteNewsletterReceiverFilter().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/newsletter-admin/newsletter-receiver-filter/{nlrfId}`.replace(`{${"nlrfId"}}`, encodeURIComponent(String(requestParameters['nlrfId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteNewsletterReceiverFilter(requestParameters: DeleteNewsletterReceiverFilterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteNewsletterReceiverFilterRaw(requestParameters, initOverrides);
    }

    /**
     */
    async emailPreviewRaw(requestParameters: EmailPreviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['nlId'] == null) {
            throw new runtime.RequiredError(
                'nlId',
                'Required parameter "nlId" was null or undefined when calling emailPreview().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/newsletter-admin/{nlId}/email-preview`.replace(`{${"nlId"}}`, encodeURIComponent(String(requestParameters['nlId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async emailPreview(requestParameters: EmailPreviewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.emailPreviewRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getLayoutsRaw(requestParameters: GetLayoutsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<NewsletterLayout>>> {
        if (requestParameters['clientId'] == null) {
            throw new runtime.RequiredError(
                'clientId',
                'Required parameter "clientId" was null or undefined when calling getLayouts().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/newsletter-admin/{clientId}/layouts`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters['clientId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NewsletterLayoutFromJSON));
    }

    /**
     */
    async getLayouts(requestParameters: GetLayoutsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<NewsletterLayout>> {
        const response = await this.getLayoutsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getNewsletterRaw(requestParameters: GetNewsletterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NewsletterAdmin>> {
        if (requestParameters['nlId'] == null) {
            throw new runtime.RequiredError(
                'nlId',
                'Required parameter "nlId" was null or undefined when calling getNewsletter().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/newsletter-admin/{nlId}`.replace(`{${"nlId"}}`, encodeURIComponent(String(requestParameters['nlId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NewsletterAdminFromJSON(jsonValue));
    }

    /**
     */
    async getNewsletter(requestParameters: GetNewsletterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NewsletterAdmin> {
        const response = await this.getNewsletterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getNewsletterBlockRaw(requestParameters: GetNewsletterBlockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NewsletterBlock>> {
        if (requestParameters['nlbId'] == null) {
            throw new runtime.RequiredError(
                'nlbId',
                'Required parameter "nlbId" was null or undefined when calling getNewsletterBlock().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/newsletter-admin/newsletter-block/{nlbId}`.replace(`{${"nlbId"}}`, encodeURIComponent(String(requestParameters['nlbId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NewsletterBlockFromJSON(jsonValue));
    }

    /**
     */
    async getNewsletterBlock(requestParameters: GetNewsletterBlockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NewsletterBlock> {
        const response = await this.getNewsletterBlockRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getNewsletterBlocksRaw(requestParameters: GetNewsletterBlocksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<NewsletterBlock>>> {
        if (requestParameters['nlId'] == null) {
            throw new runtime.RequiredError(
                'nlId',
                'Required parameter "nlId" was null or undefined when calling getNewsletterBlocks().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/newsletter-admin/{nlId}/newsletter-blocks`.replace(`{${"nlId"}}`, encodeURIComponent(String(requestParameters['nlId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NewsletterBlockFromJSON));
    }

    /**
     */
    async getNewsletterBlocks(requestParameters: GetNewsletterBlocksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<NewsletterBlock>> {
        const response = await this.getNewsletterBlocksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getNewsletterLayoutRaw(requestParameters: GetNewsletterLayoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NewsletterLayout>> {
        if (requestParameters['nllId'] == null) {
            throw new runtime.RequiredError(
                'nllId',
                'Required parameter "nllId" was null or undefined when calling getNewsletterLayout().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/newsletter-admin/newsletter-layout/{nllId}`.replace(`{${"nllId"}}`, encodeURIComponent(String(requestParameters['nllId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NewsletterLayoutFromJSON(jsonValue));
    }

    /**
     */
    async getNewsletterLayout(requestParameters: GetNewsletterLayoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NewsletterLayout> {
        const response = await this.getNewsletterLayoutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getNewsletterLayoutBlockRaw(requestParameters: GetNewsletterLayoutBlockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NewsletterLayoutBlock>> {
        if (requestParameters['nllbId'] == null) {
            throw new runtime.RequiredError(
                'nllbId',
                'Required parameter "nllbId" was null or undefined when calling getNewsletterLayoutBlock().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/newsletter-admin/newsletter-layout-block/{nllbId}`.replace(`{${"nllbId"}}`, encodeURIComponent(String(requestParameters['nllbId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NewsletterLayoutBlockFromJSON(jsonValue));
    }

    /**
     */
    async getNewsletterLayoutBlock(requestParameters: GetNewsletterLayoutBlockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NewsletterLayoutBlock> {
        const response = await this.getNewsletterLayoutBlockRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getNewsletterLayoutBlocksRaw(requestParameters: GetNewsletterLayoutBlocksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<NewsletterLayoutBlock>>> {
        if (requestParameters['nllId'] == null) {
            throw new runtime.RequiredError(
                'nllId',
                'Required parameter "nllId" was null or undefined when calling getNewsletterLayoutBlocks().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/newsletter-admin/{nllId}/newsletter-layout-blocks`.replace(`{${"nllId"}}`, encodeURIComponent(String(requestParameters['nllId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NewsletterLayoutBlockFromJSON));
    }

    /**
     */
    async getNewsletterLayoutBlocks(requestParameters: GetNewsletterLayoutBlocksRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<NewsletterLayoutBlock>> {
        const response = await this.getNewsletterLayoutBlocksRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getNewsletterReceiverFilterRaw(requestParameters: GetNewsletterReceiverFilterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NewsletterReceiverFilter>> {
        if (requestParameters['nlrfId'] == null) {
            throw new runtime.RequiredError(
                'nlrfId',
                'Required parameter "nlrfId" was null or undefined when calling getNewsletterReceiverFilter().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/newsletter-admin/newsletter-receiver-filter/{nlrfId}`.replace(`{${"nlrfId"}}`, encodeURIComponent(String(requestParameters['nlrfId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NewsletterReceiverFilterFromJSON(jsonValue));
    }

    /**
     */
    async getNewsletterReceiverFilter(requestParameters: GetNewsletterReceiverFilterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NewsletterReceiverFilter> {
        const response = await this.getNewsletterReceiverFilterRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getReceiverFiltersRaw(requestParameters: GetReceiverFiltersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<NewsletterReceiverFilter>>> {
        if (requestParameters['clientId'] == null) {
            throw new runtime.RequiredError(
                'clientId',
                'Required parameter "clientId" was null or undefined when calling getReceiverFilters().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/newsletter-admin/{clientId}/receiver-filter`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters['clientId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NewsletterReceiverFilterFromJSON));
    }

    /**
     */
    async getReceiverFilters(requestParameters: GetReceiverFiltersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<NewsletterReceiverFilter>> {
        const response = await this.getReceiverFiltersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async newsletterBlockDownRaw(requestParameters: NewsletterBlockDownRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['nlbId'] == null) {
            throw new runtime.RequiredError(
                'nlbId',
                'Required parameter "nlbId" was null or undefined when calling newsletterBlockDown().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/newsletter-admin/{nlbId}/down`.replace(`{${"nlbId"}}`, encodeURIComponent(String(requestParameters['nlbId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async newsletterBlockDown(requestParameters: NewsletterBlockDownRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.newsletterBlockDownRaw(requestParameters, initOverrides);
    }

    /**
     */
    async newsletterBlockUpRaw(requestParameters: NewsletterBlockUpRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['nlbId'] == null) {
            throw new runtime.RequiredError(
                'nlbId',
                'Required parameter "nlbId" was null or undefined when calling newsletterBlockUp().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/newsletter-admin/{nlbId}/up`.replace(`{${"nlbId"}}`, encodeURIComponent(String(requestParameters['nlbId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async newsletterBlockUp(requestParameters: NewsletterBlockUpRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.newsletterBlockUpRaw(requestParameters, initOverrides);
    }

    /**
     */
    async refreshNewsletterReceiverFilterCountRaw(requestParameters: RefreshNewsletterReceiverFilterCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['nlrfId'] == null) {
            throw new runtime.RequiredError(
                'nlrfId',
                'Required parameter "nlrfId" was null or undefined when calling refreshNewsletterReceiverFilterCount().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/newsletter-admin/newsletter-receiver-filter/{nlrfId}/refresh-count`.replace(`{${"nlrfId"}}`, encodeURIComponent(String(requestParameters['nlrfId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async refreshNewsletterReceiverFilterCount(requestParameters: RefreshNewsletterReceiverFilterCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.refreshNewsletterReceiverFilterCountRaw(requestParameters, initOverrides);
    }

    /**
     */
    async searchNewslettersRaw(requestParameters: SearchNewslettersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<NewsletterAdmin>>> {
        if (requestParameters['clientId'] == null) {
            throw new runtime.RequiredError(
                'clientId',
                'Required parameter "clientId" was null or undefined when calling searchNewsletters().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['offset'] != null) {
            queryParameters['offset'] = requestParameters['offset'];
        }

        if (requestParameters['pageSize'] != null) {
            queryParameters['pageSize'] = requestParameters['pageSize'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/newsletter-admin/{clientId}/search`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters['clientId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NewsletterAdminFromJSON));
    }

    /**
     */
    async searchNewsletters(requestParameters: SearchNewslettersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<NewsletterAdmin>> {
        const response = await this.searchNewslettersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async sendNewsletterRaw(requestParameters: SendNewsletterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['nlId'] == null) {
            throw new runtime.RequiredError(
                'nlId',
                'Required parameter "nlId" was null or undefined when calling sendNewsletter().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/newsletter-admin/{nlId}/send`.replace(`{${"nlId"}}`, encodeURIComponent(String(requestParameters['nlId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sendNewsletter(requestParameters: SendNewsletterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendNewsletterRaw(requestParameters, initOverrides);
    }

    /**
     */
    async setStateNewRaw(requestParameters: SetStateNewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['nlId'] == null) {
            throw new runtime.RequiredError(
                'nlId',
                'Required parameter "nlId" was null or undefined when calling setStateNew().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/newsletter-admin/{nlId}/set-state-new`.replace(`{${"nlId"}}`, encodeURIComponent(String(requestParameters['nlId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async setStateNew(requestParameters: SetStateNewRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.setStateNewRaw(requestParameters, initOverrides);
    }

    /**
     */
    async toggleNewsletterStateRaw(requestParameters: ToggleNewsletterStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['nlId'] == null) {
            throw new runtime.RequiredError(
                'nlId',
                'Required parameter "nlId" was null or undefined when calling toggleNewsletterState().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/newsletter-admin/{nlId}/toggle-state`.replace(`{${"nlId"}}`, encodeURIComponent(String(requestParameters['nlId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async toggleNewsletterState(requestParameters: ToggleNewsletterStateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.toggleNewsletterStateRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateHeaderFooterRaw(requestParameters: UpdateHeaderFooterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['nlId'] == null) {
            throw new runtime.RequiredError(
                'nlId',
                'Required parameter "nlId" was null or undefined when calling updateHeaderFooter().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['imageId'] != null) {
            queryParameters['imageId'] = requestParameters['imageId'];
        }

        if (requestParameters['link'] != null) {
            queryParameters['link'] = requestParameters['link'];
        }

        if (requestParameters['text'] != null) {
            queryParameters['text'] = requestParameters['text'];
        }

        if (requestParameters['title'] != null) {
            queryParameters['title'] = requestParameters['title'];
        }

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/newsletter-admin/{nlId}/update-header-footer`.replace(`{${"nlId"}}`, encodeURIComponent(String(requestParameters['nlId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateHeaderFooter(requestParameters: UpdateHeaderFooterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateHeaderFooterRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateNewsletterRaw(requestParameters: UpdateNewsletterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['nlId'] == null) {
            throw new runtime.RequiredError(
                'nlId',
                'Required parameter "nlId" was null or undefined when calling updateNewsletter().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['receiverFilterId'] != null) {
            queryParameters['receiverFilterId'] = requestParameters['receiverFilterId'];
        }

        if (requestParameters['title'] != null) {
            queryParameters['title'] = requestParameters['title'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/newsletter-admin/{nlId}`.replace(`{${"nlId"}}`, encodeURIComponent(String(requestParameters['nlId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateNewsletter(requestParameters: UpdateNewsletterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateNewsletterRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateNewsletterBlockRaw(requestParameters: UpdateNewsletterBlockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['nlbId'] == null) {
            throw new runtime.RequiredError(
                'nlbId',
                'Required parameter "nlbId" was null or undefined when calling updateNewsletterBlock().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['imageId'] != null) {
            queryParameters['imageId'] = requestParameters['imageId'];
        }

        if (requestParameters['link'] != null) {
            queryParameters['link'] = requestParameters['link'];
        }

        if (requestParameters['text'] != null) {
            queryParameters['text'] = requestParameters['text'];
        }

        if (requestParameters['title'] != null) {
            queryParameters['title'] = requestParameters['title'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/newsletter-admin/newsletter-block/{nlbId}`.replace(`{${"nlbId"}}`, encodeURIComponent(String(requestParameters['nlbId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateNewsletterBlock(requestParameters: UpdateNewsletterBlockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateNewsletterBlockRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateNewsletterLayoutRaw(requestParameters: UpdateNewsletterLayoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['nllId'] == null) {
            throw new runtime.RequiredError(
                'nllId',
                'Required parameter "nllId" was null or undefined when calling updateNewsletterLayout().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/newsletter-admin/newsletter-layout/{nllId}`.replace(`{${"nllId"}}`, encodeURIComponent(String(requestParameters['nllId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateNewsletterLayout(requestParameters: UpdateNewsletterLayoutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateNewsletterLayoutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateNewsletterLayoutBlockRaw(requestParameters: UpdateNewsletterLayoutBlockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['nllbId'] == null) {
            throw new runtime.RequiredError(
                'nllbId',
                'Required parameter "nllbId" was null or undefined when calling updateNewsletterLayoutBlock().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['text'] != null) {
            queryParameters['text'] = requestParameters['text'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/newsletter-admin/newsletter-layout-block/{nllbId}`.replace(`{${"nllbId"}}`, encodeURIComponent(String(requestParameters['nllbId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateNewsletterLayoutBlock(requestParameters: UpdateNewsletterLayoutBlockRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateNewsletterLayoutBlockRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateNewsletterLayoutHeaderFooterRaw(requestParameters: UpdateNewsletterLayoutHeaderFooterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['nllId'] == null) {
            throw new runtime.RequiredError(
                'nllId',
                'Required parameter "nllId" was null or undefined when calling updateNewsletterLayoutHeaderFooter().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['text'] != null) {
            queryParameters['text'] = requestParameters['text'];
        }

        if (requestParameters['type'] != null) {
            queryParameters['type'] = requestParameters['type'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/newsletter-admin/newsletter-layout/{nllId}/update-header-footer`.replace(`{${"nllId"}}`, encodeURIComponent(String(requestParameters['nllId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateNewsletterLayoutHeaderFooter(requestParameters: UpdateNewsletterLayoutHeaderFooterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateNewsletterLayoutHeaderFooterRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateNewsletterReceiverFilterRaw(requestParameters: UpdateNewsletterReceiverFilterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['nlrfId'] == null) {
            throw new runtime.RequiredError(
                'nlrfId',
                'Required parameter "nlrfId" was null or undefined when calling updateNewsletterReceiverFilter().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['emailFilter'] != null) {
            queryParameters['emailFilter'] = requestParameters['emailFilter'];
        }

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        if (requestParameters['nameFilter'] != null) {
            queryParameters['nameFilter'] = requestParameters['nameFilter'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/newsletter-admin/newsletter-receiver-filter/{nlrfId}`.replace(`{${"nlrfId"}}`, encodeURIComponent(String(requestParameters['nlrfId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async updateNewsletterReceiverFilter(requestParameters: UpdateNewsletterReceiverFilterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateNewsletterReceiverFilterRaw(requestParameters, initOverrides);
    }

}
