import { push } from '@lagunovsky/redux-react-router';
import { all, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { Tournament, TournamentRound } from '../../services/betting';
import { fetchWrapperFuncNoLoading } from '../../utils';
import { getSystemConfig, getTournamentApi2 } from '../System/systemConfig';
import {
    TournamentRoundChangeParams,
    TournamentRoundTypeWithId,
    TournamentRoundTypeWithTournamentId,
    tournamentSelectionAddAvailableTournament,
    tournamentSelectionAddTournamentId,
    tournamentSelectionChangeTournament,
    tournamentSelectionLoadAvailableTournamentRounds,
    tournamentSelectionSetAvailableTournamentRounds,
    tournamentSelectionSetAvailableTournaments,
    tournamentSelectionSetNextTournamentRoundId,
    tournamentSelectionSetPreviousTournamentRoundId,
    tournamentSelectionSetSelectedTournamentId,
    tournamentSelectionSetSelectedTournamentIdIfNotSet,
    tournamentSelectionSetSelectedTournamentRoundId,
    tournamentSelectionTournamentChanged,
    tournamentSelectionVerifyAndAdjustSelectedTournamentByTrId,
    TOURNAMENT_SELECTION_ADD_TOURNAMENT_ID,
    TOURNAMENT_SELECTION_CHANGE_TOURNAMENT,
    TOURNAMENT_SELECTION_CHANGE_TOURNAMENT_ROUND,
    TOURNAMENT_SELECTION_LOAD_AVAILABLE_TOURNAMENTS,
    TOURNAMENT_SELECTION_LOAD_AVAILABLE_TOURNAMENT_ROUNDS,
    TOURNAMENT_SELECTION_LOAD_DEFAULT_TOURNAMENT,
    TOURNAMENT_SELECTION_NEXT_TOURNAMENT_ROUND_ID,
    TOURNAMENT_SELECTION_PREV_TOURNAMENT_ROUND_ID,
    TOURNAMENT_SELECTION_SET_SELECTED_TOURNAMENT_ID_IF_NOT_SET,
    TOURNAMENT_SELECTION_VERIFY_AND_ADJUST_SELECTED_TOURNAMENT_BY_TR_ID,
} from './actions';
import {
    tournamentSelectionAvailableTournamentSelector,
    tournamentSelectionNextTournamentRoundIdSelector,
    tournamentSelectionPrevTournamentRoundIdSelector,
    tournamentSelectionSelectedTournamentRoundIdSelector,
    tournamentSelectionTournamentIdSelector,
} from './selectors';

function* verifyAndAdjustTournament(action: Action<string>) {
    const tournamentApi = getTournamentApi2();
    const trId = action.payload;
    const selectedTournamentId: number = yield select(tournamentSelectionTournamentIdSelector);

    // check if the currently selected Tournament needs to be changed
    const tr: TournamentRound | undefined = yield* fetchWrapperFuncNoLoading(() =>
        tournamentApi.getTournamentRoundById({ trId: Number(trId) }),
    );
    if (tr && tr.tournamentId !== selectedTournamentId && tr.tournamentId) {
        // ... we need to update the tournament
        const newTournamentId = tr.tournamentId;
        yield put(tournamentSelectionChangeTournament(newTournamentId));
        // yield put(tournamentSelectionSetSelectedTournamentId(newTournamentId));
        // yield put(tournamentSelectionLoadAvailableTournamentRounds({ type: 'betting', tId: String(newTournamentId) }));
        // yield put(
        //     tournamentSelectionLoadAvailableTournamentRounds({ type: 'highscores', tId: String(newTournamentId) })
        // );
        // yield put(tournamentSelectionLoadAvailableTournamentRounds({ type: 'ranking', tId: String(newTournamentId) }));
    } else {
        console.debug('tournament ids are equal: ' + selectedTournamentId + ' !== ' + tr?.tournamentId);
    }
}

function* changeTournamentRound(action: Action<TournamentRoundTypeWithId>): any {
    const tournamentApi = getTournamentApi2();
    const trId = action.payload.trId;
    const type = action.payload.type;
    const oldTrId = yield select(tournamentSelectionSelectedTournamentRoundIdSelector(type));

    if (trId && trId !== oldTrId) {
        // we need to change the TournamentRound
        yield put(
            tournamentSelectionSetSelectedTournamentRoundId({
                type,
                trId,
            }),
        );
        yield put(tournamentSelectionVerifyAndAdjustSelectedTournamentByTrId(trId));

        // load next and prior tournament rounds
        yield* fetchWrapperFuncNoLoading(
            () => tournamentApi.getNextTournamentRoundByType({ trId: Number(trId), type }),
            [
                (tr: TournamentRound) => {
                    return tournamentSelectionSetNextTournamentRoundId({
                        type,
                        trId: String(tr.id),
                    });
                },
            ],
            () => tournamentSelectionSetNextTournamentRoundId({ type, trId: undefined }),
        );

        yield* fetchWrapperFuncNoLoading(
            () => tournamentApi.getPreviousTournamentRoundByType({ trId: Number(trId), type }),
            [
                (tr: TournamentRound) => {
                    return tournamentSelectionSetPreviousTournamentRoundId({
                        type,
                        trId: String(tr.id),
                    });
                },
            ],
            () => tournamentSelectionSetPreviousTournamentRoundId({ type, trId: undefined }),
        );
    }
}

export function* loadDefaultTournament() {
    const api = getTournamentApi2();

    yield* fetchWrapperFuncNoLoading(
        () => api.getDefaultTournament({ clientId: getSystemConfig().clientId }),
        [(t: Tournament) => tournamentSelectionSetSelectedTournamentIdIfNotSet(t.id)],
    );
}

function* changeTournament(action: Action<number>) {
    const tId = action.payload;

    yield put(tournamentSelectionSetSelectedTournamentId(tId));
    yield put(
        tournamentSelectionLoadAvailableTournamentRounds({
            type: 'betting',
            tId: String(tId),
        }),
    );
    yield put(
        tournamentSelectionLoadAvailableTournamentRounds({
            type: 'highscores',
            tId: String(tId),
        }),
    );
    yield put(
        tournamentSelectionLoadAvailableTournamentRounds({
            type: 'ranking',
            tId: String(tId),
        }),
    );
    yield put(tournamentSelectionTournamentChanged());

    yield put(tournamentSelectionAddTournamentId(String(tId)));
}

function* loadAvailableTournaments() {
    const api = getTournamentApi2();

    yield* fetchWrapperFuncNoLoading(
        () => api.getActiveTournaments({ clientId: getSystemConfig().clientId }),
        [(list: Tournament[]) => tournamentSelectionSetAvailableTournaments(list)],
    );
}

function* loadAvailableTournamentRounds(action: Action<TournamentRoundTypeWithTournamentId>) {
    const tId = action.payload.tId;
    const type = action.payload.type;
    yield* fetchWrapperFuncNoLoading(
        () => getTournamentApi2().getTournamentRoundsByType({ tournamentId: Number(tId), type }),
        [
            (list: TournamentRound[]) =>
                tournamentSelectionSetAvailableTournamentRounds({
                    type: type,
                    rounds: list,
                }),
        ],
    );
}

function* addTournament(action: Action<string>) {
    const tId = Number(action.payload);

    const availableTournaments: Tournament[] = yield select(tournamentSelectionAvailableTournamentSelector);
    const tournamentExists = availableTournaments.map((t) => t.id).indexOf(tId) !== -1;

    if (!tournamentExists) {
        yield* fetchWrapperFuncNoLoading(
            () => getTournamentApi2().getTournamentById({ tId }),
            [(r) => tournamentSelectionAddAvailableTournament(r)],
        );
    }
}

function* nextTournamentRoundGenerator(action: Action<TournamentRoundChangeParams>) {
    const trId: string = yield select(tournamentSelectionNextTournamentRoundIdSelector(action.payload.type));
    yield put(push(action.payload.nextPath(trId)));
}

function* prevTournamentRoundGenerator(action: Action<TournamentRoundChangeParams>) {
    const trId: string = yield select(tournamentSelectionPrevTournamentRoundIdSelector(action.payload.type));
    yield put(push(action.payload.nextPath(trId)));
}

function* setTournamentIfNotSet(action: Action<number>) {
    const tId = action.payload;
    const currentTId: number | undefined = yield select(tournamentSelectionTournamentIdSelector);
    if (!currentTId && tId) {
        yield put(tournamentSelectionChangeTournament(tId));
    }
}

function* watchTournamentChange() {
    yield takeEvery(TOURNAMENT_SELECTION_CHANGE_TOURNAMENT, changeTournament);
}

function* watchForTournamentSelectionActions() {
    yield takeEvery(TOURNAMENT_SELECTION_CHANGE_TOURNAMENT_ROUND, changeTournamentRound);
    yield takeLatest(TOURNAMENT_SELECTION_LOAD_AVAILABLE_TOURNAMENTS, loadAvailableTournaments);
    yield takeLatest(TOURNAMENT_SELECTION_LOAD_DEFAULT_TOURNAMENT, loadDefaultTournament);
    yield takeEvery(TOURNAMENT_SELECTION_LOAD_AVAILABLE_TOURNAMENT_ROUNDS, loadAvailableTournamentRounds);
    yield takeEvery(TOURNAMENT_SELECTION_VERIFY_AND_ADJUST_SELECTED_TOURNAMENT_BY_TR_ID, verifyAndAdjustTournament);
    yield takeEvery(TOURNAMENT_SELECTION_ADD_TOURNAMENT_ID, addTournament);
    yield takeEvery(TOURNAMENT_SELECTION_NEXT_TOURNAMENT_ROUND_ID, nextTournamentRoundGenerator);
    yield takeEvery(TOURNAMENT_SELECTION_PREV_TOURNAMENT_ROUND_ID, prevTournamentRoundGenerator);
    yield takeLatest(TOURNAMENT_SELECTION_SET_SELECTED_TOURNAMENT_ID_IF_NOT_SET, setTournamentIfNotSet);
}

export function* tournamentSelectionSagas() {
    yield all([watchForTournamentSelectionActions(), watchTournamentChange()]);
}
