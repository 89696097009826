import { faHeart as faHeartRegular } from '@fortawesome/free-regular-svg-icons';
import { faHeart, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQuery } from '@tanstack/react-query';
import * as R from 'ramda';
import * as React from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { withBorder } from '../../../reactutils/withBorder';
import { withLogin } from '../../../reactutils/withLogin';
import { withNavLocation } from '../../../reactutils/withNavLocation';
import { withTitle } from '../../../reactutils/withTitle';
import { UserSong } from '../../../services/playlist';
import { EmailConfirmationTeaser } from '../../AccountUserApp/EmailConfirmationTeaser';
import { InvalidEmailTeaser } from '../../AccountUserApp/InvalidEmailTeaser';
import { NewsletterTeaser } from '../../AccountUserApp/NewsletterTeaser';
import { ProfileSubMenu } from '../../AccountUserApp/ProfileSubMenu';
import { ButtonWithConfirmation } from '../../common/ButtonWithConfirmation';
import { Section, SectionHeading } from '../../common/Section';
import { DateTime } from '../../DateTime';
import { userNameSelector } from '../../System/selectors';
import { getPlaylist2Api, getPlaylistUser2Api } from '../../System/systemConfig';
import { AlbumImage } from '../AlbumImage';
import { SongAudioPlayer } from '../SongAudioPlayer';

const MyPlayListComponent: React.FC<{}> = (props) => {
    const api = getPlaylist2Api();
    const userApi = getPlaylistUser2Api();

    const userSongQuery = useQuery({
        queryKey: ['userSongs'],
        queryFn: () => api.getMyFavoriteSongs(),
    });

    const playlistUserQuery = useQuery({
        queryKey: ['playlistUser'],
        queryFn: () => userApi.isPlaylistPublic(),
    });

    const removeUserSongMutation = useMutation({
        mutationFn: (songId: string) => api.removeUserSong({songId}),
    });

    const voteUserSongMutation = useMutation({
        mutationFn: (param: { songId: string; vote: number }) => api.vote({songId: param.songId, vote: param.vote}),
    });

    const updateVisibilityMutation = useMutation({
        mutationFn: (isPublic: boolean) => userApi.updateUserPlaylistPublic({value: isPublic}),
    });

    const onDelete = (songId: string) => {
        removeUserSongMutation.mutate(songId, {
            onSuccess: () => {
                userSongQuery.refetch();
            },
        });
    };
    const onVote = (songId: string, vote: number) => {
        voteUserSongMutation.mutate(
            { songId, vote },
            {
                onSuccess: () => {
                    userSongQuery.refetch();
                },
            },
        );
    };

    const onUpdateVisibility = () => {
        updateVisibilityMutation.mutate(isPublic, {
            onSuccess: () => {
                playlistUserQuery.refetch();
            },
        });
    };

    const [isPublic, setIsPublic] = React.useState(false);

    React.useEffect(() => {
        setIsPublic(playlistUserQuery.data?.playlistDataPublic ?? false);
    }, [playlistUserQuery.data]);

    const renderVote = (songId: string, vote: number) => {
        return [...Array(5).keys()].map((i) =>
            i < vote ? (
                <Button key={i} color="link" size="sm" onClick={() => onVote(songId, i + 1)}>
                    <FontAwesomeIcon icon={faHeart} />
                </Button>
            ) : (
                <Button key={i} color="link" size="sm" onClick={() => onVote(songId, i + 1)}>
                    <FontAwesomeIcon icon={faHeartRegular} />
                </Button>
            ),
        );
    };

    const renderUserSong = (us: UserSong, index: number) => {
        return (
            <tr key={us.id} className="border-bottom">
                <td>{index + 1}</td>
                <td className="">
                    <AlbumImage imageId={us.albumImageId} />
                </td>
                <td>
                    <div>
                        <strong>
                            {us.songArtist} &mdash; "{us.songName}"
                        </strong>
                    </div>
                    <div>{us.albumName}</div>
                    <div>
                        <SongAudioPlayer songAudioUrl={us.songAudioUrl} />
                    </div>
                    {us.lastPlayed && (
                        <div>
                            <small>
                                zuletzt&nbsp;gespielt:&nbsp;
                                <DateTime date={us.lastPlayed} />
                            </small>
                        </div>
                    )}
                </td>
                <td>{renderVote(us.songId, us.vote)}</td>
                <td>
                    <ButtonWithConfirmation
                        onOK={() => onDelete(us.songId)}
                        title="Song löschen"
                        message={`Sind Sie sicher, dass Sie den Song '${us.songName}' von Ihren Lieblingssongs entfernen wollen?`}
                        color="secondary"
                    >
                        <FontAwesomeIcon icon={faTrashAlt} />
                    </ButtonWithConfirmation>
                </td>
            </tr>
        );
    };

    return (
        <>
            <ProfileSubMenu subMenu="playlist" />
            <EmailConfirmationTeaser />
            <NewsletterTeaser />
            <InvalidEmailTeaser />
            <Section>
                <SectionHeading>Sichtbarkeit</SectionHeading>
                <Form>
                    <FormGroup check={true}>
                        <Label check={true}>
                            <Input
                                type="checkbox"
                                name="playlistPublic"
                                checked={isPublic}
                                onChange={() => setIsPublic(!isPublic)}
                            />
                            Meine Lieblingsmusik ist für alle sichtbar
                        </Label>
                    </FormGroup>
                    <Button onClick={onUpdateVisibility} className="mt-2" color="success">
                        Speichern
                    </Button>
                </Form>
            </Section>
            <Section>
                <SectionHeading>Meine Lieblingsmusik</SectionHeading>
                <table className="table table-borderless">
                    <tbody>{userSongQuery.data?.map((us, index) => renderUserSong(us, index))}</tbody>
                </table>
            </Section>
        </>
    );
};

export const MyPlayListScreen = R.compose(
    withBorder(),
    withTitle('Playlist', userNameSelector),
    withNavLocation('profile'),
    withLogin(),
)(MyPlayListComponent);
