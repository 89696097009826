import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

export const WeatherForwardComponent: FC<{}> = props => {
    const dispatch = useDispatch();

    const {locationId} = useParams();

    useEffect(() => {
        if (locationId) {
            window.location.href = 'https://www.radioeins.de/wetter/orte.htm/id=' + locationId;
        }
    }, [dispatch, props, locationId]);

    return null;
};
