/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.34
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WeeklyComment
 */
export interface WeeklyComment {
    /**
     * 
     * @type {string}
     * @memberof WeeklyComment
     */
    comment?: string;
}

/**
 * Check if a given object implements the WeeklyComment interface.
 */
export function instanceOfWeeklyComment(value: object): value is WeeklyComment {
    return true;
}

export function WeeklyCommentFromJSON(json: any): WeeklyComment {
    return WeeklyCommentFromJSONTyped(json, false);
}

export function WeeklyCommentFromJSONTyped(json: any, ignoreDiscriminator: boolean): WeeklyComment {
    if (json == null) {
        return json;
    }
    return {
        
        'comment': json['comment'] == null ? undefined : json['comment'],
    };
}

export function WeeklyCommentToJSON(value?: WeeklyComment | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'comment': value['comment'],
    };
}

