import actionCreatorFactory from 'typescript-fsa';
import { RssFeed } from '../../../services/betting';

const actionCreator = actionCreatorFactory();

export const PORTLET_LOAD = 'PORTLET_LOAD';
export const portletLoad = actionCreator<string>(PORTLET_LOAD);

export type PortletRecord = {
    name: string;
    feed: RssFeed;
};
export const PORTLET_SET = 'PORTLET_SET';
export const portletSet = actionCreator<PortletRecord>(PORTLET_SET);
