/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TeamChat
 */
export interface TeamChat {
    /**
     * 
     * @type {string}
     * @memberof TeamChat
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof TeamChat
     */
    published?: boolean;
}

/**
 * Check if a given object implements the TeamChat interface.
 */
export function instanceOfTeamChat(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TeamChatFromJSON(json: any): TeamChat {
    return TeamChatFromJSONTyped(json, false);
}

export function TeamChatFromJSONTyped(json: any, ignoreDiscriminator: boolean): TeamChat {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'published': !exists(json, 'published') ? undefined : json['published'],
    };
}

export function TeamChatToJSON(value?: TeamChat | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'published': value.published,
    };
}

