import React, { FC, PropsWithChildren } from 'react'
import { useSelector } from 'react-redux';
import { notificationStatusEntitySelector } from '../../BettingApp/BettingProfile/NotificationStatus/selectors';

export const ShowWhenNotificationStatusMessagesExists: FC<PropsWithChildren<{}>> = (props) => {
    const status = useSelector(notificationStatusEntitySelector);

    if (status.messageCount > 0) {
        return <>{props.children}</>
    } else {
        return null;
    }

}