import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { Alert } from 'reactstrap';
import { withBorder } from '../../../reactutils/withBorder';
import { withNavLocation } from '../../../reactutils/withNavLocation';
import { withTitle } from '../../../reactutils/withTitle';
import { Game } from '../../../services/betting';
import { isGameLive } from '../../GamesList';
import { getGameApi2, getSystemConfig } from '../../System/systemConfig';
import { GameResult } from '../../TournamentApp/GameResult';
import { LiveTicker } from '../LiveTicker';

export const liveTickerConferencePath = () => '/b/live-ticker';

const LiveTickerConferenceComp = () => {
    const [selectedGameId, setSelectedGameId] = useState<string>('');

    const gameQuery = useQuery({
        queryKey: ['getDailyGames', DateTime.now().toFormat('yyyyMMdd')],
        queryFn: () => getGameApi2().getDailyGames({tenant: getSystemConfig().clientName, day: DateTime.now().toFormat('yyyyMMdd')}),
        refetchInterval: 5 * 60 * 1000, // every 5 minutes
    });

    useEffect(() => {
        const gameWithEvents = gameQuery.data?.find((g) => isGameLive(g.date) && g.hasEvents);
        if (gameWithEvents) {
            setSelectedGameId(String(gameWithEvents.id));
        } else {
            setSelectedGameId('');
        }
    }, [gameQuery.data]);

    const isSelected = (g: Game) => selectedGameId === String(g.id);

    const onSelectGame = (gameId: string) => {
        setSelectedGameId(gameId);
    };

    const renderGame = (g: Game) => {
        const bgColor = selectedGameId === String(g.id) ? 'bg-success' : 'bg-white';
        const color = selectedGameId === String(g.id) ? 'text-white' : 'text-black';
        return (
            <div
                key={g.id}
                className={`d-flex flex-row p-1 rounded align-items-center flex-nowrap border border-light ${bgColor} ${color}`}
                onClick={(e) => {
                    e.preventDefault();
                    onSelectGame(String(g.id));
                }}
            >
                <div className="col col-1 align-items-center d-none d-lg-block ps-2">
                    {isSelected(g) && <FontAwesomeIcon icon={faCheckSquare} />}
                    {!isSelected(g) && <FontAwesomeIcon icon={faSquare} />}
                </div>
                <div className="d-flex m-0 p-0 col-1 align-items-center d-none d-lg-block">
                    {DateTime.fromJSDate(g.date).toFormat('HH:mm')}
                </div>
                <div className="d-flex col-4 m-0 p-0 ps-2 align-items-center d-block d-lg-none text-truncate">
                    {g.team1.shortName}
                </div>
                <div className="d-flex col-4 m-0 p-0 align-items-center d-block d-lg-none text-truncate">
                    {g.team2.shortName}
                </div>
                <div className="d-flex col-4 m-0 p-0 align-items-center d-none d-lg-block text-truncate">
                    {g.team1.name}
                </div>
                <div className="d-flex col-4 m-0 p-0 align-items-center d-none d-lg-block text-truncate">
                    {g.team2.name}
                </div>
                <div className="d-flex pe-2 col-4 m-0 p-0 align-items-center d-block d-lg-none justify-content-end">
                    <GameResult gameId={String(g.id)} date={g.date} />
                </div>
                <div className="d-flex pe-2 col-2 m-0 p-0 align-items-center d-none d-lg-block justify-content-end">
                    <GameResult gameId={String(g.id)} date={g.date} />
                </div>
            </div>
        );
    };

    return (
        <div>
            <div className="d-flex flex-column">
                {gameQuery.data?.filter((g) => isGameLive(g.date)).map((game) => renderGame(game))}
            </div>
            {gameQuery.data?.filter((g) => isGameLive(g.date)).length === 0 && (
                <Alert color="success">
                    <h4>Live-Ticker</h4>
                    <p>Derzeit laufen keine Spiele. Die Seite wird automatisch aktualisiert.</p>
                </Alert>
            )}
            {selectedGameId && <LiveTicker gameId={selectedGameId} />}
        </div>
    );
};

export const LiveTickerConference = R.compose(
    withBorder(),
    withTitle('Konferenz Live-Ticker'),
    withNavLocation('live-ticker'),
)(LiveTickerConferenceComp);
