import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getNewsletterAdminApi, getSystemConfig } from '../../../System/systemConfig';
import { LoadingIndicatorSpinner2 } from '../../../common/LoadingIndicatorSpinner2';

interface Props {
    mode: 'create' | 'edit';
    nlrfId: string | undefined;
    onClose: () => void;
}

export const NewsletterReceiverFilterEdit: React.FC<Props> = (props) => {
    const api = getNewsletterAdminApi();

    const [name, setName] = React.useState('');
    const [emailFilter, setEmailFilter] = React.useState('');
    const [nameFilter, setNameFilter] = React.useState('');

    const receiverFilterQuery = useQuery({
        queryKey: ['receiverFilter', { nlrfId: props.nlrfId }],
        queryFn: () => api.getNewsletterReceiverFilter({nlrfId: props.nlrfId!}),
        enabled: !!props.nlrfId && props.mode === 'edit',
    });

    const receiverFilterUpdateMutation = useMutation({
        mutationFn: () => api.updateNewsletterReceiverFilter({nlrfId: props.nlrfId!, emailFilter, name, nameFilter}),
    });

    const receiverFilterCreateMutation = useMutation({
        mutationFn: () =>
            api.createNewsletterReceiverFilter({clId: String(getSystemConfig().clientId), emailFilter, name, nameFilter}),
    });

    const onUpdate = () => {
        receiverFilterUpdateMutation.mutate(undefined, {
            onSuccess: () => {
                props.onClose();
            },
            onError: (error) => {
                console.log('Error updating receiver filter: ' + JSON.stringify(error));
            },
        });
    };

    const onCreate = () => {
        receiverFilterCreateMutation.mutate(undefined, {
            onSuccess: () => {
                props.onClose();
            },
            onError: (error) => {
                console.log('Error creating receiver filter: ' + JSON.stringify(error));
            },
        });
    };

    useEffect(() => {
        setName(receiverFilterQuery.data?.name || '');
        setEmailFilter(receiverFilterQuery.data?.emailFilter || '');
        setNameFilter(receiverFilterQuery.data?.nameFilter || '');
    }, [receiverFilterQuery.data]);

    return (
        <Modal isOpen={true} toggle={props.onClose} size="lg">
            <LoadingIndicatorSpinner2
                isLoading={receiverFilterQuery.isFetching || receiverFilterUpdateMutation.isPending}
            />
            <ModalHeader toggle={props.onClose}>Empfängerfilter bearbeiten</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label>Name</Label>
                    <Input value={name} onChange={(e) => setName(e.target.value)} />
                </FormGroup>
                <FormGroup>
                    <Label>Namesfilter</Label>
                    <Input value={nameFilter} onChange={(e) => setNameFilter(e.target.value)} />
                </FormGroup>
                <FormGroup>
                    <Label>Email-Filter</Label>
                    <Input value={emailFilter} onChange={(e) => setEmailFilter(e.target.value)} />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button onClick={props.onClose} className="m-1">
                    Abbrechen
                </Button>
                {props.mode === 'edit' && (
                    <Button color="success" className="m-1" onClick={onUpdate}>
                        Speichern
                    </Button>
                )}
                {props.mode === 'create' && (
                    <Button color="success" className="m-1" onClick={onCreate}>
                        Anlegen
                    </Button>
                )}
            </ModalFooter>
        </Modal>
    );
};
