import { all, put, select, takeEvery, takeLatest, throttle } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { fetchWrapperFunc, fetchWrapperFuncNoLoading } from '../../../utils';
import { getProfile2Api, getSystemConfig, getUserMailApi2 } from '../../System/systemConfig';
import {
    MailToSend,
    USER_MAIL_BACKWARD,
    USER_MAIL_CHECK_RECEIVER_NAME,
    USER_MAIL_DELETE,
    USER_MAIL_FAST_BACKWARD,
    USER_MAIL_FAST_FORWARD,
    USER_MAIL_FORWARD,
    USER_MAIL_LOAD,
    USER_MAIL_SEND,
    userMailLoad,
    userMailSet,
    userMailSetCount,
    userMailSetCurrentPos,
    userMailSetReceiverNameValid,
} from './actions';
import { userMailCurrentPosSelector, userMailEntityCountSelector, userMailSlotSizeSelector } from './selectors';

function* userMailLoadGenerator() {
    const api = getUserMailApi2();

    const current: number = yield select(userMailCurrentPosSelector);
    const slotSize: number = yield select(userMailSlotSizeSelector);

    yield* fetchWrapperFunc(() => api.getReceivedMails({ startPos: current, slotSize }), [(r) => userMailSet(r)]);
    yield* fetchWrapperFunc(() => api.countMails(), [(r) => userMailSetCount(r)]);
}

function* userMailSendGenerator(action: Action<MailToSend>) {
    const api = getUserMailApi2();
    const mail: MailToSend = action.payload;
    yield* fetchWrapperFunc(
        () => api.sendMail({ receiver: mail.receiver, subject: mail.subject, message: mail.message }),
        [(r) => userMailSet(r)],
    );
}

function* userMailForwardGenerator() {
    const current: number = yield select(userMailCurrentPosSelector);
    const count: number = yield select(userMailEntityCountSelector);
    const slotSize: number = yield select(userMailSlotSizeSelector);

    if (current + slotSize <= count) {
        yield put(userMailSetCurrentPos(current + slotSize));
        yield put(userMailLoad());
    }
}

function* userMailBackwardGenerator() {
    const current: number = yield select(userMailCurrentPosSelector);
    const slotSize: number = yield select(userMailSlotSizeSelector);

    if (current - slotSize >= 0) {
        yield put(userMailSetCurrentPos(current - slotSize));
        yield put(userMailLoad());
    }
}

function* userMailFastForwardGenerator() {
    const count: number = yield select(userMailEntityCountSelector);
    const slotSize: number = yield select(userMailSlotSizeSelector);

    const newCurrent = count - (count % slotSize);
    yield put(userMailSetCurrentPos(newCurrent));
    yield put(userMailLoad());
}

function* userMailDeleteGenerator(action: Action<string>) {
    const umId = action.payload;
    const api = getUserMailApi2();

    yield* fetchWrapperFunc(() => api.deleteMail({userMailId: umId}));
    yield put(userMailLoad());
}

function* userMailFastBackwardGenerator() {
    yield put(userMailSetCurrentPos(0));
    yield put(userMailLoad());
}

function* userMailCheckReceiverNameGenerator(action: Action<string>) {
    const userName = action.payload;

    const api = getProfile2Api();
    if (userName) {
        yield* fetchWrapperFuncNoLoading(
            () => api.getPublicUserProfileByName({ tenant: getSystemConfig().clientName, name: userName }),
            [(r) => userMailSetReceiverNameValid(true)],
            () => userMailSetReceiverNameValid(false),
        );
    } else {
        yield put(userMailSetReceiverNameValid(false));
    }
}

function* watchForUserMailActions() {
    yield takeLatest(USER_MAIL_LOAD, userMailLoadGenerator);
    yield takeEvery(USER_MAIL_SEND, userMailSendGenerator);
    yield takeEvery(USER_MAIL_FORWARD, userMailForwardGenerator);
    yield takeEvery(USER_MAIL_FAST_FORWARD, userMailFastForwardGenerator);
    yield takeEvery(USER_MAIL_BACKWARD, userMailBackwardGenerator);
    yield takeEvery(USER_MAIL_FAST_BACKWARD, userMailFastBackwardGenerator);
    yield takeEvery(USER_MAIL_DELETE, userMailDeleteGenerator);
    yield throttle(500, USER_MAIL_CHECK_RECEIVER_NAME, userMailCheckReceiverNameGenerator);
}

export function* userMailSagas() {
    yield all([watchForUserMailActions()]);
}
