import { TournamentRoundType } from '../../reactutils/withTournamentRoundSelection';
import { GlobalState } from '../actions';

export const tournamentSelector = (state: GlobalState) => state.tournamentSelection;

export const tournamentSelectionTournamentIdSelector = (state: GlobalState) =>
    tournamentSelector(state).selectedTournamentId;

export const tournamentSelectionAvailableTournamentSelector = (state: GlobalState) =>
    tournamentSelector(state).availableTournaments;

export const tournamentSelectionAvailableTournamentRoundSelector = (type: TournamentRoundType) => (
    state: GlobalState
) => tournamentSelector(state).availableTournamentRounds[type];

export const tournamentSelectionSelectedTournamentRoundIdSelector = (type: TournamentRoundType) => (
    state: GlobalState
) => tournamentSelector(state).selectedTournamentRoundId[type];

export const tournamentSelectionNextTournamentRoundIdSelector = (type: TournamentRoundType) => (state: GlobalState) =>
    tournamentSelector(state).nextTournamentRoundId[type];

export const tournamentSelectionPrevTournamentRoundIdSelector = (type: TournamentRoundType) => (state: GlobalState) =>
    tournamentSelector(state).previousTournamentRoundId[type];
