import { faCheck, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { Button, Table } from 'reactstrap';
import { LoadingIndicatorContent } from '../../../components/common/LoadingIndicatorSpinner';
import { Section, SectionHeading } from '../../../components/common/Section';
import { isSysAdminSelector } from '../../../components/System/selectors';
import { getUserGroupAdminApi2 } from '../../../components/System/systemConfig';
import { UserGroupTournamentSubscription } from '../../../services/betting';
import { userGroupDetailSelector } from '../selectors';

interface Props {
    ugId: string;
}

export const UserGroupTournamentSubscriptions = (props: Props) => {
    const isSysAdmin = useSelector(isSysAdminSelector);
    const userGroupDetail = useSelector(userGroupDetailSelector);

    const api = getUserGroupAdminApi2();

    const subscriptionQuery = useQuery({
        queryKey: ['userGroupTournamentSubscriptions', props.ugId],
        queryFn: () => api.getUserGroupTournamentSubscriptions({ugId: props.ugId}),
    });

    const subscribeMutations = useMutation({
        mutationFn: (tId: string) => api.subscribeUserGroupToTournament({ugId: props.ugId, tournamentId: tId}),
    });

    const unsubscribeMutations = useMutation({
        mutationFn: (tId: string) => api.unsubscribeUserGroupToTournament({ugId: props.ugId, tournamentId: tId}),
    });

    const isGroupAdmin = () => {
        return (userGroupDetail && userGroupDetail.loginUserIsGroupAdmin) || isSysAdmin;
    };

    const onSubscribe = (tId: string) => {
        subscribeMutations.mutate(tId, {
            onSuccess: () => {
                subscriptionQuery.refetch();
            },
        });
    };

    const onUnsubscribe = (tId: string) => {
        unsubscribeMutations.mutate(tId, {
            onSuccess: () => {
                subscriptionQuery.refetch();
            },
        });
    };

    const renderUgts = (t: UserGroupTournamentSubscription) => {
        return (
            <tr key={t.tournamentId}>
                <td>{t.tournamentName}</td>
                {isGroupAdmin() && (
                    <td className="text-center">
                        {t.subscribed && <FontAwesomeIcon icon={faCheck} color="green" />}
                        {t.subscribed && isSysAdmin && (
                            <Button color="link" className="mt-0 pt-0" onClick={() => onUnsubscribe(t.tournamentId)}>
                                <LoadingIndicatorContent isLoading={unsubscribeMutations.isPending}>
                                    <FontAwesomeIcon icon={faMinus} color="red" />
                                </LoadingIndicatorContent>
                            </Button>
                        )}
                        {!t.subscribed && userGroupDetail && (
                            <Button color="link" className="mt-0 pt-0" onClick={() => onSubscribe(t.tournamentId)}>
                                <LoadingIndicatorContent isLoading={subscribeMutations.isPending}>
                                    <FontAwesomeIcon icon={faPlus} />
                                </LoadingIndicatorContent>
                            </Button>
                        )}
                    </td>
                )}
            </tr>
        );
    };

    return (
        <>
            <Section>
                <SectionHeading>Turniere</SectionHeading>
                <Table>
                    <tbody>{subscriptionQuery.data?.map((t) => renderUgts(t))}</tbody>
                </Table>
            </Section>
        </>
    );
};
