import { AnyAction, isType } from 'typescript-fsa';
import {
    userProfileSet,
    userProfileSetAvailableCountries,
    userProfileSetDevices,
    userProfileSetContactData
} from './actions';
import { Device, UserProfile, UserProfileContactData } from '../../../services/account';
import { BettingUser } from '../../../services/betting';

export interface AccountUserProfilState {
    contactData: UserProfileContactData;
    profile: UserProfile;
    devices: Device[];
    availableCountries: string[];
    bettingUser?: BettingUser;
}

const initialAccountUserProfileState: AccountUserProfilState = {
    contactData: {
        city: '',
        country: '',
        firstName: '',
        lastName: '',
        phone: '',
        street: '',
        streetNumber: '',
        userId: 0,
        zipCode: ''
    },
    profile: {
        bettingPublic: false,
        id: '',
        name: '',
        playlistPublic: false,
        character: '',
        characterHtml: '',
        favoriteBook: '',
        favoriteBookHtml: '',
        favoriteMovie: '',
        favoriteMovieHtml: '',
        note: '',
        status: 'inactive',
        r1Moderator: false
    },
    devices: [],
    availableCountries: []
};

export function accountUserProfileReducer(
    state: AccountUserProfilState = initialAccountUserProfileState,
    action: AnyAction
): AccountUserProfilState {
    if (isType(action, userProfileSetContactData)) {
        return Object.assign({}, state, { contactData: action.payload });
    } else if (isType(action, userProfileSetAvailableCountries)) {
        return Object.assign({}, state, { availableCountries: action.payload });
    } else if (isType(action, userProfileSet)) {
        return { ...state, profile: action.payload };
    } else if (isType(action, userProfileSetDevices)) {
        return { ...state, devices: action.payload };
    }
    return state;
}
