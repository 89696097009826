import { push } from '@lagunovsky/redux-react-router';
import { put, select } from 'redux-saga/effects';
import { getSystemConfig, getTournamentApi2 } from './components/System/systemConfig';
import { tournamentSelectionChangeTournament } from './components/TournamentSelectionComponent/actions';
import { tournamentSelectionTournamentIdSelector } from './components/TournamentSelectionComponent/selectors';
import { TournamentRoundType } from './reactutils/withTournamentRoundSelection';
import { TournamentRound } from './services/betting';
import { fetchWrapperFuncNoLoading } from './utils';

export function* loadWithoutPropsTournamentRoundForwarder(
    trType: TournamentRoundType,
    forwardUrlBuilder: (id: string) => string
) {
    const api = getTournamentApi2();

    let tvarId: number | undefined = yield select(tournamentSelectionTournamentIdSelector);

    if (tvarId == null) {
        const defaultTournament = yield* fetchWrapperFuncNoLoading(() => api.getDefaultTournament({clientId: getSystemConfig().clientId}));
        if (defaultTournament != null) {
            tvarId = defaultTournament.id;
        }
    }
    const tId = tvarId;
    if (tId) {
        yield put(tournamentSelectionChangeTournament(tId));
        yield* fetchWrapperFuncNoLoading(() => api.getDefaultTournamentRoundByType({tournamentId: tId, type: trType}), [
            (tr: TournamentRound) => {
                const path = forwardUrlBuilder(String(tr.id));
                return push(path);
            }
        ]);
    }
}
