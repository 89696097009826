import { useMutation, useQuery } from '@tanstack/react-query';
import * as R from 'ramda';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { Alert, Button, Pagination, PaginationItem, PaginationLink, Table } from 'reactstrap';
import { withBorder } from '../../../reactutils/withBorder';
import { FuntipTitle } from '../../../reactutils/withTitle';
import { Game } from '../../../services/betting';
import { DateTime } from '../../DateTime';
import { systemSetPopupMessage } from '../../System/actions';
import { userIdSelector } from '../../System/selectors';
import { getBettingUserApi2, getGameApi2, getSystemConfig, getTeamApi2 } from '../../System/systemConfig';
import { TeamLink, teamPathPage } from '../../TeamLink';
import { GameResult } from '../../TournamentApp/GameResult';
import { Link } from 'react-router-dom';
import { myProfileBettingPath } from '../../../screens/UserProfileScreen';

interface Props {}

export const TeamViewForward: FC<Props> = (props) => {
    const { teamId } = useParams();

    const navigate = useNavigate();

    const pageNumberQuery = useQuery({
        queryKey: ['teamPageNumber', teamId],
        queryFn: () => getGameApi2().getCurrentPageByTeamId({ teamId: teamId!, pageSize: 10 }),
        enabled: !!teamId,
    });

    useEffect(() => {
        if (pageNumberQuery.data) {
            navigate(`/b/team/${teamId}/${pageNumberQuery.data}/10`);
        }
    }, [navigate, pageNumberQuery.data, teamId]);

    return null;
};

const TeamViewComp: FC<Props> = (props) => {
    const { teamId, page, pageSize } = useParams();

    const [maxPage, setMaxPage] = useState(0);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const loggedInUserId = useSelector(userIdSelector);

    const favoriteTeamsQuery = useQuery({
        queryKey: ['favoriteTeams', loggedInUserId],
        queryFn: () => getBettingUserApi2().getFavoriteTeams(),
        enabled: loggedInUserId !== undefined,
    });

    const teamQuery = useQuery({
        queryKey: ['team', teamId],
        queryFn: () => getTeamApi2().getDetailsById({ teamId: teamId! }),
        enabled: !!teamId,
    });

    const countGamesQuery = useQuery({
        queryKey: ['countGamesByTeam', teamId],
        queryFn: () => getGameApi2().countGamesByTeamId({ teamId: teamId! }),
        enabled: !!teamId,
    });

    const gamesQuery = useQuery({
        queryKey: ['games', teamId, page, pageSize],
        queryFn: () =>
            getGameApi2().getGamesByTeamId({ teamId: teamId!, page: Number(page), pageSize: Number(pageSize) }),
        enabled: !!teamId,
    });

    const addFavoriteTeamMutation = useMutation({
        mutationFn: () => getBettingUserApi2().addFavoriteTeam({ teamId: Number(teamId!) }),
    });

    const removeFavoriteTeamMutation = useMutation({
        mutationFn: () => getBettingUserApi2().deleteFavoriteTeam({ teamId: Number(teamId!) }),
    });

    useEffect(() => {
        if (countGamesQuery.data) {
            setMaxPage(Math.ceil(countGamesQuery.data / Number(pageSize)));
        } else {
            setMaxPage(0);
        }
    }, [countGamesQuery.data, pageSize]);

    const renderGame = (game: Game) => {
        return (
            <tr key={game.id}>
                <td className="d-none d-lg-table-cell">{game.tournament.name}</td>
                <td>
                    <TeamLink teamId={game.team1.id} />
                </td>
                <td>
                    <TeamLink teamId={game.team2.id} />
                </td>
                <td className="d-none d-lg-table-cell">
                    <DateTime date={game.date} format="long" />
                </td>
                <td>
                    <GameResult gameId={String(game.id)} />
                </td>
            </tr>
        );
    };

    const isFavoriteTeam = (teamId: string) => {
        return favoriteTeamsQuery.data?.find((t) => t.id === teamId) !== undefined;
    };

    const addFavoriteTeam = () => {
        addFavoriteTeamMutation.mutate(undefined, {
            onSuccess: () => {
                favoriteTeamsQuery.refetch();
                gamesQuery.refetch();
            },
            onError: (error) => {
                dispatch(systemSetPopupMessage('Fehler beim Hinzufügen des Lieblingsteams (' + error.message + ')'));
            },
        });
    };

    const removeFavoriteTeam = () => {
        removeFavoriteTeamMutation.mutate(undefined, {
            onSuccess: () => {
                favoriteTeamsQuery.refetch();
                gamesQuery.refetch();
            },
            onError: (error) => {
                dispatch(systemSetPopupMessage('Fehler beim Entfernen des Lieblingsteams (' + error.message + ')'));
            },
        });
    };

    return (
        <div>
            <FuntipTitle title="Mannschaft" subTitle={teamQuery.data?.name ?? ''} />
            <div className="w-100">
                {!!loggedInUserId && (
                    <Alert color="primary" className="mt-2">
                        <h5>Lieblingsteam</h5>
                        <p>
                            Für Ihre{' '}
                            <Link to={myProfileBettingPath()} className="text-decoration-underline">
                                Lieblingsteams
                            </Link>{' '}
                            können Sie alle Tore live als Push-Nachrichten erhalten.
                        </p>
                        {!!loggedInUserId && isFavoriteTeam(teamId!) && (
                            <Button className="mt-3" color="warning" onClick={removeFavoriteTeam}>
                                '{teamQuery.data?.name}' als Lieblingsteam entfernen
                            </Button>
                        )}
                        {!!loggedInUserId && !isFavoriteTeam(teamId!) && (
                            <Button className="mt-3" color="success" onClick={addFavoriteTeam}>
                                '{teamQuery.data?.name}' als Lieblingsteam hinzufügen
                            </Button>
                        )}
                    </Alert>
                )}
            </div>{' '}
            <div className="container-fluid m-0">
                <div className="row w-100 justify-content-between">
                    <div className="col-9 m-0 me-2 p-0">
                        <table>
                            <tbody>
                                {teamQuery.data?.established && (
                                    <tr>
                                        <td className="p-2">Gründung</td>
                                        <td className="p-2">{teamQuery.data?.established}</td>
                                    </tr>
                                )}
                                {teamQuery.data?.stadium && (
                                    <tr>
                                        <td className="p-2">Stadion</td>
                                        <td className="p-2">{teamQuery.data?.stadium}</td>
                                    </tr>
                                )}
                                {teamQuery.data?.stadiumCapacity && (
                                    <tr>
                                        <td className="p-2">Stadionkapazität</td>
                                        <td className="p-2">{teamQuery.data?.stadiumCapacity}</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="col m-0 p-0">
                        <div className="d-flex justify-content-end">
                            {teamQuery.data?.imageId && (
                                <img
                                    src={getSystemConfig().imageUrl(teamQuery.data.imageId)}
                                    alt={`Logo ${teamQuery.data?.name}`}
                                    className="img-fluid"
                                    width={200}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="w-100 d-flex justify-content-center">
                {teamQuery.data?.stadiumImageId && (
                    <img
                        src={getSystemConfig().imageUrl(teamQuery.data.stadiumImageId)}
                        alt={`Stadion ${teamQuery.data?.name}`}
                        className="img-fluid mt-2 rounded border shadow"
                        width={400}
                    />
                )}
            </div>
            <div className="d-flex justify-content-center">
                <Pagination>
                    <PaginationItem disabled={Number(page) <= 0} className="m-2">
                        <PaginationLink first onClick={() => navigate(teamPathPage(teamId!, 0, Number(pageSize)))} />
                    </PaginationItem>
                    <PaginationItem disabled={Number(page) <= 0} className="m-2">
                        <PaginationLink
                            previous
                            onClick={() => navigate(teamPathPage(teamId!, Number(page) - 1, Number(pageSize)))}
                        />
                    </PaginationItem>
                    <PaginationItem disabled={Number(page) >= maxPage} className="m-2">
                        <PaginationLink
                            next
                            onClick={() => navigate(teamPathPage(teamId!, Number(page) + 1, Number(pageSize)))}
                        />
                    </PaginationItem>
                    <PaginationItem disabled={Number(page) >= maxPage} className="m-2">
                        <PaginationLink
                            last
                            onClick={() => navigate(teamPathPage(teamId!, maxPage, Number(pageSize)))}
                        />
                    </PaginationItem>
                </Pagination>
            </div>
            <Table>
                <thead>
                    <tr>
                        <th className="d-none d-lg-table-cell">Turnier</th>
                        <th>Heim</th>
                        <th>Gast</th>
                        <th className="d-none d-lg-table-cell">Datum</th>
                        <th>Ergebnis</th>
                    </tr>
                </thead>
                <tbody>{gamesQuery.data?.map((game) => renderGame(game))}</tbody>
            </Table>
        </div>
    );
};

export const TeamView = R.compose(withBorder())(TeamViewComp);
