import * as React from 'react';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row, Table } from 'reactstrap';
import { BetQuote, GameBet } from '../../../../services/betting';
import { TeamLink } from '../../../TeamLink';
import { GameResult } from '../../../TournamentApp/GameResult';

interface Props {
    betQuote: BetQuote;
    gameBet: GameBet;
    onClose: () => void;
}

export const BetQuoteComponent: React.FC<Props> = (props) => {
    const getQuote = (count: number) => {
        const betCount = props.betQuote.betCount;
        if (betCount > 0) {
            return (count * 100.0) / betCount;
        } else {
            return 0;
        }
    };

    if (props.betQuote && props.gameBet) {
        return (
            <Modal isOpen={true} centered={true} keyboard={false} autoFocus={false}>
                <ModalHeader toggle={props.onClose}>Tipp-Quote</ModalHeader>
                <ModalBody>
                    <div className="container-fluid">
                        <Row className="p-2 rounded bg-light text-black">
                            <Col xs={6}>Paarung</Col>
                            <Col xs={3}>Tipp</Col>
                            <Col xs={3}>Ergebnis</Col>
                        </Row>
                        <Row className="p-2 rounded bg-success text-white">
                            <Col xs={3}>
                                <TeamLink teamId={props.gameBet.team1.id} />
                            </Col>
                            <Col xs={3}>
                                <TeamLink teamId={props.gameBet.team2.id} />
                            </Col>
                            <Col xs={3}>
                                {props.gameBet.bet1}
                                &nbsp;:&nbsp;
                                {props.gameBet.bet2}
                            </Col>
                            <Col xs={3}>
                                <GameResult gameId={String(props.gameBet.gameId)} />
                            </Col>
                        </Row>
                    </div>
                    <Table>
                        <tbody>
                            <tr>
                                <td colSpan={3}>
                                    Wenn Dein Tipp richtig ist, würdest Du auf der Basis des aktuellen Punktemodus
                                    folgende Punkte bekommen.
                                </td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td>Quote</td>
                                <td>Punkte</td>
                            </tr>
                            {!props.betQuote.tendencyDisabled && (
                                <tr>
                                    <td>Tendenz</td>
                                    <td>
                                        {getQuote(props.betQuote.tendencyCount).toLocaleString(undefined, {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2,
                                        })}{' '}
                                        %
                                    </td>
                                    <td>{props.betQuote.tendencyPoints}</td>
                                </tr>
                            )}
                            {!props.betQuote.differenceDisabled && (
                                <tr>
                                    <td>Tordifferenz</td>
                                    <td>
                                        {getQuote(props.betQuote.differenceCount).toLocaleString(undefined, {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2,
                                        })}{' '}
                                        %
                                    </td>
                                    <td>{props.betQuote.differencePoints}</td>
                                </tr>
                            )}
                            {!props.betQuote.resultDisabled && (
                                <tr>
                                    <td>Ergebnise</td>
                                    <td>
                                        {getQuote(props.betQuote.resultCount).toLocaleString(undefined, {
                                            maximumFractionDigits: 2,
                                            minimumFractionDigits: 2,
                                        })}{' '}
                                        %
                                    </td>
                                    <td>{props.betQuote.resultPoints}</td>
                                </tr>
                            )}
                            <tr>
                                <td>
                                    <b>Summe</b>
                                </td>
                                <td>&nbsp;</td>
                                <td>
                                    <b>{props.betQuote.points}</b>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={props.onClose}>X</Button>
                </ModalFooter>
            </Modal>
        );
    } else {
        return null;
    }
};
