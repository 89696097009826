import * as R from 'ramda';
import React from 'react';
import { useSelector } from 'react-redux';
import { withBorder } from '../../../reactutils/withBorder';
import { withLogin } from '../../../reactutils/withLogin';
import { withNavLocation } from '../../../reactutils/withNavLocation';
import { withTitle } from '../../../reactutils/withTitle';
import { isClientAdminSelector, userNameSelector } from '../../System/selectors';
import { EmailConfirmationTeaser } from '../EmailConfirmationTeaser';
import { InvalidEmailTeaser } from '../InvalidEmailTeaser';
import { NewsletterTeaser } from '../NewsletterTeaser';
import { ProfileSubMenu } from '../ProfileSubMenu';
import { UserMailForm } from './UserMailForm';
import { UserMailGrid } from './UserMailGrid';

const AccountUserMailComponent = () => {
    const isClientAdmin = useSelector(isClientAdminSelector);

    return (
        <>
            <ProfileSubMenu subMenu="messages" />
            <EmailConfirmationTeaser />
            <NewsletterTeaser />
            <InvalidEmailTeaser />
            <UserMailGrid />
            {isClientAdmin && <UserMailForm />}
        </>
    );
};

export const AccountUserMailScreen = R.compose(
    withBorder(),
    withTitle('Nachrichten', userNameSelector),
    withNavLocation('profile'),
    withLogin()
)(AccountUserMailComponent);
