import { useMutation, useQuery } from '@tanstack/react-query';
import { FC, useEffect, useState } from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { getTournamentSysAdminApi } from '../../../../System/systemConfig';

interface TournamentEditCompProps {
    tId: string;
    onClose: () => void;
}

export const TournamentEditComp: FC<TournamentEditCompProps> = (props) => {
    //const [categories, setCategories] = useState<Category[]>([]);
    //const [rankingStrategies, setRankingStrategies] = useState<RankingStrategy[]>([]);
    // const [tournamentCreators, setTournamentCreators] = useState<TournamentCreator[]>([]);

    const [name, setName] = useState('');
    const [shortName, setShortName] = useState('');
    const [key, setKey] = useState('');
    const [season, setSeason] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [rankingStrategy, setRankingStrategy] = useState('');
    const [tournamentCreator, setTournamentCreator] = useState('');

    const api = getTournamentSysAdminApi();

    const TOURNAMENT_GET_QUERY = 'tournament.get';

    const tournamentQuery = useQuery({
        queryKey: [TOURNAMENT_GET_QUERY, props.tId],
        queryFn: () => api.getTournament({ tId: props.tId }),
    });

    useEffect(() => {
        if (tournamentQuery.data) {
            setName(tournamentQuery.data.name ?? '');
            setShortName(tournamentQuery.data.shortName ?? '');
            setKey(tournamentQuery.data.key ?? '');
            setSeason(tournamentQuery.data.season ?? '');
            setCategoryId(tournamentQuery.data.categoryId);
            setRankingStrategy(tournamentQuery.data.rankingStrategyName ?? '');
            setTournamentCreator(tournamentQuery.data.tournamentCreatorName ?? '');
        }
    }, [tournamentQuery.data]);

    const categoryQuery = useQuery({
        queryKey: ['category.get'],
        queryFn: () => api.getCategories(),
        /*onSuccess(data) {
            setCategories(data);
        },
        */
    });
    const rankingStrategyQuery = useQuery({
        queryKey: ['rankingStrategies.get'],
        queryFn: () => api.getRankingStrategies(),
        /*onSuccess(data) {
            setRankingStrategies(data);
        },*/
    });

    const tournamentCreatorsQuery = useQuery({
        queryKey: ['tournamentCreators.get'],
        queryFn: () => api.getTournamentCreators(),
        /*
        onSuccess(data) {
            setTournamentCreators(data);
        },
        */
    });

    const updateTournamentMutation = useMutation({
        mutationFn: () =>
            api.updateTournament({
                tId: props.tId,
                name: name ?? '',
                shortName: shortName ?? '',
                key: key ?? '',
                season: season ?? '',
                categoryId: Number(categoryId),
                rankingStrategy: rankingStrategy ?? '',
                tournamentCreator: tournamentCreator ?? '',
            }),
    });

    const updateTournamentAttributes = () => {
        updateTournamentMutation.mutate(undefined, {
            onSuccess: () => {
                tournamentQuery.refetch();
                props.onClose();
            },
            onError(error, variables, context) {
                console.info(JSON.stringify(error));
            },
        });
    };

    return (
        <>
            <Form>
                <FormGroup>
                    <Label>Name</Label>
                    <Input type="text" value={name} onChange={(e) => setName(e.currentTarget.value)} />
                </FormGroup>
                <FormGroup>
                    <Label>Short Name</Label>
                    <Input type="text" value={shortName} onChange={(e) => setShortName(e.currentTarget.value)} />
                </FormGroup>
                <FormGroup>
                    <Label>Key</Label>
                    <Input type="text" value={key} onChange={(e) => setKey(e.currentTarget.value)} />
                </FormGroup>
                <FormGroup>
                    <Label>Season</Label>
                    <Input type="text" value={season} onChange={(e) => setSeason(e.currentTarget.value)} />
                </FormGroup>
                <FormGroup>
                    <Label>Category</Label>
                    <Input type="select" value={categoryId} onChange={(e) => setCategoryId(e.currentTarget.value)}>
                        {categoryQuery.data?.map((cat) => (
                            <option key={cat.id} value={String(cat.id)}>
                                {cat.name}
                            </option>
                        ))}
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label>Ranking Strategy</Label>
                    <Input
                        type="select"
                        value={rankingStrategy}
                        onChange={(e) => setRankingStrategy(e.currentTarget.value)}
                    >
                        {rankingStrategyQuery.data?.map((r) => <option key={r.name}>{r.name}</option>)}
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label>Tournament Creator</Label>
                    <Input
                        type="select"
                        value={tournamentCreator}
                        onChange={(e) => setTournamentCreator(e.currentTarget.value)}
                    >
                        {tournamentCreatorsQuery.data?.map((r) => <option key={r.name}>{r.name}</option>)}
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Button className="m-2" onClick={props.onClose}>
                        Abbrechen
                    </Button>
                    <Button className="m-2" color="success" onClick={updateTournamentAttributes}>
                        Speichern
                    </Button>
                </FormGroup>
            </Form>
        </>
    );
};
