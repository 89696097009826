import { push } from '@lagunovsky/redux-react-router';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button } from 'reactstrap';
import { ActionTokenParams } from '../';
import { Section, SectionHeading } from '../../../common/Section';
import { actionTokenExecute } from '../actions';
import { actionTokenIsExecutedSelector } from '../selectors';
import { logout } from '../../actions';

export const DeleteUserActionTokenHandler: FC<ActionTokenParams> = props => {
    const dispatch = useDispatch();

    const isExecuted = useSelector(actionTokenIsExecutedSelector);

    const onCancel = () => {
        dispatch(push('/'));
    };

    const onExecute = () => {
        if (props.atId && props.uId && props.secId) {
            dispatch(actionTokenExecute({ atId: props.atId, uId: props.uId, secId: props.secId }));
            delay(5000).then(() => dispatch(logout()));
        }
    };

    return (
        <Section>
            <SectionHeading>Nutzer löschen</SectionHeading>
            <p>
                Sind Sie sicher, dass Sie Ihren Nutzer löschen wollen? <br />
                Alle Daten werden gelöscht. Sie können diese Aktion nicht rückgängig machen.
            </p>
            {!isExecuted && (
                <>
                    <Button color="danger" className="me-3" onClick={onExecute}>
                        Ja, Nutzer vollständig löschen!
                    </Button>
                    <Button onClick={onCancel}>Nein, Nutzer nicht löschen.</Button>
                </>
            )}
            {isExecuted && <Alert color="info">Der Nutzer wird jetzt vollständig gelöscht.</Alert>}
        </Section>
    );
};
