import * as R from 'ramda';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Container } from 'reactstrap';
import { withBorder } from '../../../reactutils/withBorder';
import { withNavLocation } from '../../../reactutils/withNavLocation';
import { withTitle } from '../../../reactutils/withTitle';
import { LoadingIndicatorSpinner } from '../../common/LoadingIndicatorSpinner';
import { usePathSegment } from '../../SysAdminApp/CacheStatisticsComponent';
import { userViewLoadBetting } from '../actions';
import { userViewBettingUserProfileSelector, userViewUserNameSelector } from '../selectors';
import { UserViewMenu } from '../UserViewMenu';
import { UserViewBettingCurrentHighscores } from './UserViewBettingCurrentHighscores';
import { UserViewBettingFavoriteTeams } from './UserViewBettingFavoriteTeams';
import { UserViewBettingUserGroups } from './UserViewBettingUserGroups';

const UserViewBetting: React.FC<{}> = () => {
    const bettingUserProfile = useSelector(userViewBettingUserProfileSelector);

    const [userId] = usePathSegment(4);
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (userId) {
            dispatch(userViewLoadBetting(userId));
        }
    }, [dispatch, userId]);

    if (!bettingUserProfile) {
        return (
            <Container>
                <LoadingIndicatorSpinner />
            </Container>
        );
    } else {
        return (
            <div className="mt-3">
                <UserViewMenu activeSubMenu="betting" />
                <Alert color="warning" isOpen={!bettingUserProfile.visible}>
                    Das Tipp-Profil ist vom Hörer nicht freigegeben.
                </Alert>
                {bettingUserProfile.visible && bettingUserProfile.favoriteTeams && (
                    <UserViewBettingFavoriteTeams favoriteTeams={bettingUserProfile.favoriteTeams} />
                )}

                {bettingUserProfile.visible && bettingUserProfile.userGroups && (
                    <UserViewBettingUserGroups userGroups={bettingUserProfile.userGroups} />
                )}

                {bettingUserProfile.visible && bettingUserProfile.highscores && (
                    <UserViewBettingCurrentHighscores highscores={bettingUserProfile.highscores} />
                )}
            </div>
        );
    }
};

export const UserViewBettingScreen = R.compose(
    withBorder(),
    withTitle('Hörerprofil', userViewUserNameSelector),
    withNavLocation('profile')
)(UserViewBetting);
