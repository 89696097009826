import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import { Col, Container, Label, Modal, ModalBody, ModalHeader, Row, ModalFooter, Button } from 'reactstrap';
import { DateTime } from '../../../DateTime';
import { ButtonWithConfirmation } from '../../../common/ButtonWithConfirmation';
import { UserMail } from '../../../../services/betting';

interface Props {
    um: UserMail;
    onReply: () => void;
    onCancel: () => void;
    onDelete: () => void;
}

export const UserMailDetails: FC<Props> = props => {
    return (
        <>
            <Modal isOpen={true} toggle={props.onCancel}>
                <ModalHeader>
                    Nachricht von '{props.um.senderName}' (<DateTime date={props.um.date} />)
                </ModalHeader>
                <ModalBody>
                    <Container className="mx-0 px-0">
                        <Row>
                            <Col sm={3}>
                                <Label className="font-weight-bold">Betreff</Label>
                            </Col>
                            <Col sm={9}>
                                <p>{props.um.subject}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Label className="font-weight-bold">Nachricht</Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <ReactMarkdown>{props.um.message}</ReactMarkdown>
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <Button color="success" onClick={props.onReply}>
                        Antworten
                    </Button>
                    <ButtonWithConfirmation
                        color="danger"
                        title="Nachricht löschen"
                        message="Sind Sie sicher, dass Sie diese Nachricht löschen wollen?"
                        onOK={props.onDelete}
                    >
                        Löschen
                    </ButtonWithConfirmation>
                    <Button color="secondary" onClick={props.onCancel}>
                        Schließen
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};
