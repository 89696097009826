import { AnyAction, isType } from 'typescript-fsa';
import { UserNotificationStatus } from '../../../../services/betting';
import { notificationStatusSet, notificationStatusClear } from './actions';

export interface NotificationStatusState {
    status: UserNotificationStatus;
}

const initialNotificationStatusState: NotificationStatusState = {
    status: {
        groupAskForMemberships: [],
        groupInvitations: [],
        messageCount: 0
    }
};

export function notificationStatusReducer(
    state: NotificationStatusState = initialNotificationStatusState,
    action: AnyAction
) {
    if (isType(action, notificationStatusSet)) {
        return {...state, status: action.payload};
    } else if (isType(action, notificationStatusClear)) {
        return {...initialNotificationStatusState };
    }
    return state;
}
