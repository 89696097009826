import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from '@lagunovsky/redux-react-router';
import * as R from 'ramda';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Container, Row } from 'reactstrap';
import { withNavLocation } from '../../../reactutils/withNavLocation';
import { withTitle } from '../../../reactutils/withTitle';
import { Category, Tournament } from '../../../services/betting';
import { NoWrap } from '../../common/NoWrap';
import { Section } from '../../common/Section';
import { highscoresPath } from '../../Highscores';
import { tournamentSelectionChangeTournament } from '../../TournamentSelectionComponent/actions';
import { tournamentArchiveLoad } from './actions';
import { tournamentArchiveCategoriesSelector, tournamentArchiveTournamentsSelector } from './selectors';

const TournamentArchiveComponent: FC<{}> = () => {
    const dispatch = useDispatch();

    const tournaments = useSelector(tournamentArchiveTournamentsSelector);
    const categories = useSelector(tournamentArchiveCategoriesSelector);

    const [displayedCategories, setDisplayedCategories] = useState<Category[]>([]);
    const [visibleCategoryId, setVisibleCategoryId] = useState<string | undefined>(undefined);

    useEffect(() => {
        dispatch(tournamentArchiveLoad());
    }, [dispatch]);

    const calculateDisplayedCategories = useCallback(() => {
        const distinctCatIds = Array.from(new Set(tournaments.map(t => t.categoryId)));

        setDisplayedCategories(
            categories.filter(cat => distinctCatIds.indexOf(cat.id) !== -1).sort((a, b) => a.name.localeCompare(b.name))
        );
    }, [categories, tournaments]);

    useEffect(() => {
        calculateDisplayedCategories();
    }, [tournaments, categories, calculateDisplayedCategories]);

    const selectArchivedTournament = (tId: string) => {
        dispatch(tournamentSelectionChangeTournament(Number(tId)));
        dispatch(push(highscoresPath('')));
    };

    const renderTournament = (t: Tournament) => {
        return (
            <div key={t.id}>
                <Button color="link" onClick={() => selectArchivedTournament(String(t.id))}>
                    &nbsp;&nbsp;{t.name}
                </Button>
            </div>
        );
    };
    const renderCategory = (cat: Category) => {
        return (
            <Row key={cat.id}>
                <Col>
                    {cat.id === visibleCategoryId && (
                        <Button color="link" onClick={() => setVisibleCategoryId(undefined)}>
                            <NoWrap>
                                {cat.name} <FontAwesomeIcon icon={faCaretUp} />
                            </NoWrap>
                        </Button>
                    )}
                    {cat.id !== visibleCategoryId && (
                        <Button color="link" onClick={() => setVisibleCategoryId(cat.id)}>
                            <NoWrap>
                                {cat.name} <FontAwesomeIcon icon={faCaretDown} />
                            </NoWrap>
                        </Button>
                    )}
                </Col>
                <Col>
                    {cat.id === visibleCategoryId &&
                        tournaments
                            .filter(t => t.categoryId === cat.id)
                            .sort((t1, t2) => t1.id - t2.id)
                            .map(t => renderTournament(t))}
                </Col>
            </Row>
        );
    };

    return (
        <>
            <Section>
                <p>
                    Sie können auch in älteren Turnieren die Ergebnisse und die Tippwertung einsehen. Wählen Sie dazu
                    das Turnier aus der Liste der archivierten Turniere aus.
                </p>
                <Container>{displayedCategories.map(cat => renderCategory(cat))}</Container>
            </Section>
        </>
    );
};

export const tournamentArchivePath = () => '/b/archive';

export const TournamentArchive = R.compose(
    withTitle('Turnierarchiv'),
    withNavLocation('betting')
)(TournamentArchiveComponent);
