import { faHeart as faHeartRegular } from '@fortawesome/free-regular-svg-icons';
import { faHeart as faHeartSolid } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';
import * as R from 'ramda';
import * as React from 'react';
import { Alert } from 'reactstrap';
import { withBorder } from '../../../reactutils/withBorder';
import { withNavLocation } from '../../../reactutils/withNavLocation';
import { withTitle } from '../../../reactutils/withTitle';
import { UserSong } from '../../../services/playlist';
import { DateTime } from '../../DateTime';
import { AlbumImage } from '../../Playlist/AlbumImage';
import { SongAudioPlayer } from '../../Playlist/SongAudioPlayer';
import { usePathSegment } from '../../SysAdminApp/CacheStatisticsComponent';
import { getPlaylist2Api, getPlaylistUser2Api } from '../../System/systemConfig';
import { userViewUserNameSelector } from '../selectors';
import { UserViewMenu } from '../UserViewMenu';

const UserViewPlaylist: React.FC<{}> = () => {
    const [userId] = usePathSegment(4);

    const playlistUserQuery = useQuery({
        queryKey: ['playlistUser', userId],
        queryFn: () => getPlaylistUser2Api().getPlaylistUser({userId}),
        enabled: !!userId,
    });

    const userSongQuery = useQuery({
        queryKey: ['userSongs', userId],
        queryFn: () => getPlaylist2Api().getFavoriteSongs({userId}),
        enabled: !!userId,
    });

    const renderVote = (songId: string, vote: number) => {
        return [...Array(5).keys()].map((i) =>
            i < vote ? (
                <FontAwesomeIcon key={i} className="me-1" icon={faHeartSolid} />
            ) : (
                <FontAwesomeIcon key={i} className="me-1" icon={faHeartRegular} />
            ),
        );
    };

    const renderUserSong = (us: UserSong, index: number) => {
        return (
            <tr key={us.id} className="border-bottom">
                <td>{index + 1}</td>
                <td className="">
                    <AlbumImage imageId={us.albumImageId} />
                </td>
                <td>
                    <div>
                        <strong>
                            {us.songArtist} &mdash; "{us.songName}"
                        </strong>
                    </div>
                    <div>{us.albumName}</div>
                    <div>
                        <SongAudioPlayer songAudioUrl={us.songAudioUrl} />
                    </div>
                    {us.lastPlayed && (
                        <div>
                            <small>
                                zuletzt&nbsp;gespielt:&nbsp;
                                <DateTime date={us.lastPlayed} />
                            </small>
                        </div>
                    )}
                </td>
                <td>{renderVote(us.songId, us.vote)}</td>
            </tr>
        );
    };

    return (
        <div className="mt-3">
            <UserViewMenu activeSubMenu="playlist" />
            {!playlistUserQuery.data?.playlistDataPublic && (
                <Alert className="mt-3">Der Nutzer hat seine Lieblingsmusik nicht freigegeben.</Alert>
            )}
            {playlistUserQuery.data?.playlistDataPublic && userSongQuery.data?.length === 0 && (
                <Alert className="mt-3">Der Nutzer hat noch keine Lieblingsmusik ausgewählt.</Alert>
            )}
            <table className="table table-borderless">
                <tbody>
                    {playlistUserQuery.data?.playlistDataPublic &&
                        userSongQuery.data?.map((us, index) => renderUserSong(us, index))}
                </tbody>
            </table>
        </div>
    );
};

export const UserViewPlaylistScreen = R.compose(
    withBorder(),
    withTitle('Hörerprofil', userViewUserNameSelector),
    withNavLocation('profile'),
)(UserViewPlaylist);
