import { push } from '@lagunovsky/redux-react-router';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Alert, Button } from 'reactstrap';
import { ActionTokenParams } from '../';
import { myProfileBettingPath } from '../../../../screens/UserProfileScreen';
import { Section, SectionHeading } from '../../../common/Section';
import { actionTokenExecute } from '../actions';
import { actionTokenIsExecutedSelector } from '../selectors';

export const CancelTournamentSubscriptionActionTokenHandler: FC<ActionTokenParams> = props => {
    const dispatch = useDispatch();

    const isExecuted = useSelector(actionTokenIsExecutedSelector);

    const onCancel = () => {
        dispatch(push('/'));
    };

    const onExecute = () => {
        if (props.atId && props.uId && props.secId) {
            dispatch(actionTokenExecute({ atId: props.atId, uId: props.uId, secId: props.secId }));
        }
    };

    return (
        <Section>
            <SectionHeading>Tipperinnerungen ausschalten</SectionHeading>
            <p>
                Mit dieser Aktion schalten Sie die Tipperinnerungen für alle Turniere aus. Sind Sie sicher, dass Sie
                zukünftig keine Erinnerungen mehr erhalten wollen?
            </p>
            {!isExecuted && (
                <>
                    <Button color="danger" className="me-3" onClick={onExecute}>
                        Alle Tipperinnerungen ausschalten
                    </Button>
                    <Button onClick={onCancel}>Tipperinnerungen beibehalten</Button>
                </>
            )}
            {isExecuted && (
                <Alert color="info">
                    Der Tipperinnerungen wurden ausgeschaltet. Sie können diese jederzeit wieder über ihr
                    <Link to={myProfileBettingPath()}>Profile</Link> aktivieren.
                </Alert>
            )}
        </Section>
    );
};
