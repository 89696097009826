import * as R from 'ramda';
import React from 'react';
import { Route, Routes } from 'react-router';
import { withBorder } from '../../../reactutils/withBorder';
import { withFooter } from '../../../reactutils/withFooter';
import { withLogin } from '../../../reactutils/withLogin';
import { withMenu } from '../../../reactutils/withMenu';
import { MenuClientAdmin } from '../MenuClientAdmin';
import { NewsletterEdit } from './NewsletterEdit';
import { NewsletterList } from './NewsletterList';
import { SysAdminSubMenu } from '../../SysAdminApp/SysAdminSubMenu';
import { NewsletterLayoutScreen, newsletterLayoutsPath } from './NewsletterLayouts';
import { newsletterReceiverFilterPath, NewsletterReceiverFilterScreen } from './NewsletterReceiverFilters';
import { NewsletterLayoutEdit } from './NewsletterLayoutEdit';
import { newsletterListPath } from '..';

export const NewsletterAdmin: React.FC = () => {
    return (
        <>
            <Routes>
                <Route path="list/:nlId" element={<NewsletterEdit />} />
                <Route path="layouts/:nllId" element={<NewsletterLayoutEdit/>} />
                <Route path="layouts" element={<NewsletterLayoutScreen/>} />
                <Route path="receiver-filters" element={<NewsletterReceiverFilterScreen/>} />
                <Route path="list" element={<NewsletterList />} />
            </Routes>
        </>
    );
};

const NewsletterSubMenu: React.FC = () => {
    return (
        <SysAdminSubMenu
            links={[
                { name: 'Newsletter-Übersicht', link: newsletterListPath() },
                { name: 'Layoutvorlagen', link: newsletterLayoutsPath() },
                { name: 'Empfängerfilter', link: newsletterReceiverFilterPath() },
            ]}
        />
    );
};

export const NewsletterAdminScreen = R.compose(
    withMenu(<MenuClientAdmin />),
    withBorder(),
    withMenu(<NewsletterSubMenu />),
    withLogin(),
    withFooter(),
)(NewsletterAdmin);
