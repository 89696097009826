import { faCancel, faEdit, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { systemSetPopupMessage } from '../../../../System/actions';
import { getTeamSysadminApi, getTournamentSysAdminApi } from '../../../../System/systemConfig';
import { ButtonWithConfirmation } from '../../../../common/ButtonWithConfirmation';
import { Section } from '../../../../common/Section';

interface ModalTeamEditProps {
    teamId?: string;
    onClose: () => void;
}

export const ModalTeamEditComp: FC<ModalTeamEditProps> = (props) => {
    const api = getTeamSysadminApi();
    const tournamentApi = getTournamentSysAdminApi();

    const dispatch = useDispatch();

    const [name, setName] = useState('');
    const [shortName, setShortName] = useState('');
    const [miniName, setMiniName] = useState('');
    const [sportId, setSportId] = useState('');
    const [alternativeNames, setAlternativeNames] = useState<string[]>([]);

    const [errorMessage, setErrorMessage] = useState('');

    const teamQuery = useQuery({
        queryKey: ['team', props.teamId],
        queryFn: () => api.getTeamById({teamId: props.teamId!}),
        enabled: !!props.teamId,
    });

    const sportsQuery = useQuery({
        queryKey: ['sports.all'],
        queryFn: () => tournamentApi.getSports(),
    });

    useEffect(() => {
        if (sportsQuery.data && sportId === '') {
            setSportId(sportsQuery.data[0].id);
        }
    }, [sportId, sportsQuery.data]);

    const updateNamesMutation = useMutation({
        mutationFn: () => api.updateStandardNames({teamId: props.teamId!, name, miniName, shortName}),
    });

    const createTeamMutation = useMutation({
        mutationFn: () => api.createTeam({sportId, name, shortName, miniName}),
    });

    const deleteTeamMutation = useMutation({
        mutationFn: () => api.deleteTeam({teamId: props.teamId!}),
    });

    useEffect(() => {
        if (teamQuery.data) {
            setName(teamQuery.data.name);
            setShortName(teamQuery.data.shortName ?? '');
            setMiniName(teamQuery.data.miniName ?? '');
            setSportId(teamQuery.data.sportId);
            setAlternativeNames(teamQuery.data.alternativeNames ?? []);
        }
    }, [teamQuery.data]);

    const onAddAlternativeName = () => {
        setAlternativeNames([...alternativeNames, '']);
    };

    const canAlternativeNameBeAdded = (): boolean => {
        return !!props.teamId && alternativeNames.filter((an) => an === '').length === 0;
    };

    const onSave = () => {
        updateNamesMutation.mutate(undefined, {
            onSuccess: () => {
                dispatch(systemSetPopupMessage('Team names updated.'));
                setErrorMessage('');
                props.onClose();
            },
            onError(error, variables, context) {
                dispatch(
                    systemSetPopupMessage('Error updating Team names. The name is already in use by another team.'),
                );
                setErrorMessage('The name is already in use by another team.');
            },
        });
    };

    const onCreate = () => {
        createTeamMutation.mutate(undefined, {
            onSuccess: () => {
                dispatch(systemSetPopupMessage('Team created.'));
                setErrorMessage('');
                props.onClose();
            },
            onError: () => {
                dispatch(systemSetPopupMessage('Error creating Team'));
                setErrorMessage('Choosen name is already in use by another team.');
            },
        });
    };

    const onDelete = () => {
        deleteTeamMutation.mutate(undefined, {
            onSuccess: () => {
                dispatch(systemSetPopupMessage('Team deleted.'));
                setErrorMessage('');
                props.onClose();
            },
            onError: () => {
                dispatch(systemSetPopupMessage('Error deleting Team'));
                setErrorMessage('The team is already in use and cant be deleted.');
            },
        });
    };

    return (
        <Modal isOpen={true} toggle={props.onClose}>
            <ModalHeader toggle={props.onClose}>Team {teamQuery.data?.name || 'anlegen'}</ModalHeader>
            <ModalBody>
                <Section>
                    <FormGroup>
                        <Label>Name</Label>
                        <Input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.currentTarget.value)}
                            invalid={!!errorMessage}
                        />
                        <FormFeedback invalid={true}>One of the names is already in use by another team!</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label>Short Name</Label>
                        <Input
                            type="text"
                            value={shortName}
                            onChange={(e) => setShortName(e.currentTarget.value)}
                            invalid={!!errorMessage}
                        />
                        <FormFeedback invalid={true}>One of the names is already in use by another team!</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label>Mini Name</Label>
                        <Input
                            type="text"
                            value={miniName}
                            onChange={(e) => setMiniName(e.currentTarget.value)}
                            invalid={!!errorMessage}
                        />
                        <FormFeedback invalid={true}>One of the names is already in use by another team!</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label>Alternative Names</Label>
                        {!!props.teamId &&
                            alternativeNames.map((an, i) => (
                                <TeamAlternativeNameEdit
                                    teamId={props.teamId!}
                                    key={an}
                                    name={an}
                                    onRefresh={() => teamQuery.refetch()}
                                />
                            ))}
                        {canAlternativeNameBeAdded() && (
                            <div>
                                <small>
                                    <Button color="link" onClick={onAddAlternativeName}>
                                        add alternative name
                                    </Button>
                                </small>
                            </div>
                        )}
                    </FormGroup>
                    <FormGroup>
                        <Label>Sport</Label>
                        <select
                            className="form-control"
                            value={sportId}
                            onChange={(e) => setSportId(e.currentTarget.value)}
                            disabled={!!props.teamId}
                        >
                            {sportsQuery.data?.map((sport) => (
                                <option key={sport.id} value={sport.id}>
                                    {sport.name}
                                </option>
                            ))}
                        </select>
                    </FormGroup>
                </Section>
            </ModalBody>
            <ModalFooter>
                {!!props.teamId && (
                    <Button className="m-2" color="danger" onClick={onDelete}>
                        Delete (if possible)
                    </Button>
                )}
                <Button className="m-2" onClick={props.onClose}>
                    Cancel
                </Button>
                {!!props.teamId && (
                    <Button className="m-2" color="success" onClick={onSave}>
                        Speichern{' '}
                    </Button>
                )}
                {!props.teamId && (
                    <Button className="m-2" color="success" onClick={onCreate}>
                        Anlegen{' '}
                    </Button>
                )}
            </ModalFooter>
        </Modal>
    );
};

interface TeamAlternativeNameEditProps {
    teamId: string;
    name: string;
    onRefresh: () => void;
}
const TeamAlternativeNameEdit: FC<TeamAlternativeNameEditProps> = (props) => {
    const [name, setName] = useState(props.name);
    const [editMode, setEditMode] = useState(false);

    const dispatch = useDispatch();

    const api = getTeamSysadminApi();

    const updateMutation = useMutation({
        mutationFn: (name: string) => api.updateAlternativeName({teamId: props.teamId, oldName: props.name, newName: name}),
    });

    const deleteAlternativeNameMutation = useMutation({
        mutationFn: (name: string) => api.deleteAlternativeName({teamId: props.teamId, name: props.name}),
    });

    const onEdit = () => {
        setEditMode(true);
    };

    const onCancel = () => {
        setName(props.name);
        setEditMode(false);
    };

    const onSave = () => {
        updateMutation.mutate(name, {
            onSuccess: () => {
                setEditMode(false);
                dispatch(systemSetPopupMessage('Alternative name updated.'));
                props.onRefresh();
            },
            onError: () => {
                dispatch(systemSetPopupMessage('Error updating alternative name.'));
                props.onRefresh();
            },
        });
    };

    const onDelete = () => {
        if (!editMode) {
            deleteAlternativeNameMutation.mutate(props.name, {
                onSuccess: () => {
                    dispatch(systemSetPopupMessage('Alternative name deleted.'));
                    props.onRefresh();
                },
                onError: (error) => {
                    dispatch(systemSetPopupMessage('Error deleting alternative name.'));
                    props.onRefresh();
                },
            });
        }
    };

    return (
        <div className="d-flex flex-nowrap m-1">
            <Input
                type="text"
                value={name}
                readOnly={!editMode}
                disabled={!editMode}
                onChange={(e) => setName(e.currentTarget.value)}
            />
            {!editMode && (
                <>
                    <Button className="ms-2" color="dark" onClick={onEdit}>
                        <FontAwesomeIcon icon={faEdit} size="1x" />
                    </Button>
                    <ButtonWithConfirmation
                        className="ms-2"
                        color="danger"
                        onOK={onDelete}
                        title="Delete alternative name"
                        message={`Are you sure you want to delete the alternativ name '${props.name}'`}
                    >
                        <FontAwesomeIcon icon={faTrash} size="1x" />
                    </ButtonWithConfirmation>
                </>
            )}
            {editMode && (
                <>
                    <Button className="ms-2" color="warning" onClick={onCancel}>
                        <FontAwesomeIcon icon={faCancel} size="1x" />
                    </Button>
                    <Button className="ms-2" color="success" onClick={onSave}>
                        <FontAwesomeIcon icon={faSave} size="1x" />
                    </Button>
                </>
            )}
        </div>
    );
};
