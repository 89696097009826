import React, { FC } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { systemPopupMessageSelector} from '../selectors';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import { systemSetPopupMessage } from '../actions';

export const PopupMessage: FC<{}> = () => {

    const popupMessage = useSelector(systemPopupMessageSelector);
    const dispatch = useDispatch();

    if (popupMessage) {
        return (
            <>
                <Modal isOpen={popupMessage !== undefined}>
                    <ModalBody>
                        {popupMessage}
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => dispatch(systemSetPopupMessage(undefined))}>Schließen</Button>
                    </ModalFooter>
                </Modal>
            </>
        )
    } else {
        return null;
    }
}