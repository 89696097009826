import * as React from 'react';
import { Input } from 'reactstrap';

interface FormProps {
    minValue: number;
    maxValue: number;
    useQuotes: boolean;
    name: string;
    handleChange: (e: React.FormEvent<HTMLInputElement>) => void;
}

export const HighscoreDescriptorFormValue = (props: FormProps) => {
    const [minValue, setMinValue] = React.useState(props.minValue);
    const [maxValue, setMaxValue] = React.useState(props.maxValue);

    React.useEffect(
        () => {
            setMinValue(props.minValue);
            setMaxValue(props.maxValue);
        },
        [props.minValue, props.maxValue, props.useQuotes, props.name]
    );

    function handleMinChange(ev: React.FormEvent<HTMLInputElement>) {
        const val = Number(ev.currentTarget.value);
        setMinValue(val);
        props.handleChange(ev);
    }

    function handleMaxChange(ev: React.FormEvent<HTMLInputElement>) {
        const val = Number(ev.currentTarget.value);
        setMaxValue(val);
        props.handleChange(ev);
    }

    return (
        <>
            {props.useQuotes && (
                <>
                    {' '}
                    Min:{' '}
                    <Input
                        className="mt-1"
                        type="text"
                        bsSize="sm"
                        name={`${props.name}Min`}
                        value={minValue}
                        onChange={handleMinChange}
                        style={{ display: 'inline-block', width: '35px' }}
                    />
                </>
            )}
            {props.useQuotes && 'Max: '}
            <Input
                className="mt-1"
                type="text"
                bsSize="sm"
                name={`${props.name}Max`}
                value={maxValue}
                onChange={handleMaxChange}
                style={{ display: 'inline-block', width: '35px' }}
            />
        </>
    );
};
