import classnames from 'classnames';
import * as R from 'ramda';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Nav, NavItem, NavLink, TabContent } from 'reactstrap';
import { withBorder } from '../../reactutils/withBorder';
import { withNavLocation } from '../../reactutils/withNavLocation';
import { withTitle } from '../../reactutils/withTitle';
import { usePathSegment } from '../SysAdminApp/CacheStatisticsComponent';
import { AppFeature, hasFeature } from '../System/systemConfig';
import { userViewLoad } from './actions';
import { userViewUserNameSelector } from './selectors';

export type UserViewTabType = 'profile' | 'betting' | 'playlist';

export interface UserViewRequestParams {
    userId: string;
}

const UserViewComponent: React.FC<{}> = () => {
    const [tabName] = usePathSegment(3);
    const [userId] = usePathSegment(4);

    const dispatch = useDispatch();

    React.useEffect(() => {
        if (userId) {
            dispatch(userViewLoad({ userId }));
        }
    }, [dispatch, userId]);

    const isActiveTab = (name: string) => name === tabName;

    if (!userId || !tabName) {
        return null;
    } else {
        return (
            <>
                <Nav tabs={true}>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: isActiveTab('profile') })}
                            tag={Link}
                            to={userViewProfilePath(userId)}
                        >
                            Profil
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: isActiveTab('betting') })}
                            tag={Link}
                            to={userViewBettingPath(userId)}
                        >
                            Tippspiel
                        </NavLink>
                    </NavItem>
                    {hasFeature(AppFeature.playlist) && (
                        <NavItem>
                            <NavLink
                                className={classnames({ active: isActiveTab('playlist') })}
                                tag={Link}
                                to={userViewPlaylistPath(userId)}
                            >
                                Lieblingsmusik
                            </NavLink>
                        </NavItem>
                    )}
                </Nav>
                <TabContent activeTab={tabName}></TabContent>
            </>
        );
    }
};

const userViewBasePath = (page: UserViewTabType, userId: string) => `/b/user/${page}/${userId}`;

export const userViewProfilePath = (userId: string) => userViewBasePath('profile', userId);
export const userViewBettingPath = (userId: string) => userViewBasePath('betting', userId);
export const userViewPlaylistPath = (userId: string) => userViewBasePath('playlist', userId);

export const UserViewScreen = R.compose(
    withBorder(),
    withTitle('Hörerprofil', userViewUserNameSelector),
    withNavLocation('profile')
)(UserViewComponent);
