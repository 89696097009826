import { ComponentProps, FC, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { uploadImage } from '../../PlaylistAdminApp/AlbumEdit/sagas';
import { systemFinishFetching, systemStartFetching } from '../../System/actions';

interface Props extends ComponentProps<'div'> {
    imageUploaded: (imageId: string | undefined) => void;
}

export const FileUpload: FC<Props> = (props) => {
    const dispatch = useDispatch();

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            acceptedFiles.forEach((file: File) => {
                dispatch(systemStartFetching());
                uploadImage(file)
                    .then((imageId) => props.imageUploaded(imageId))
                    .finally(() => dispatch(systemFinishFetching()));
            });
        },
        [dispatch, props],
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: { 'image/*': ['.jpg', '.jpeg', '.png'] },
    });

    return (
        <div {...getRootProps({ className: 'dropzone' })} className="bg-light p-3 border border-primary rounded">
            <input {...getInputProps()} />
            {isDragActive ? (
                <p>Dateien hierher ziehen ...</p>
            ) : (
                <p>Dateien hierher ziehen oder klicken zum Auswählen.</p>
            )}
        </div>
    );
};
