import moment from 'moment';
import * as R from 'ramda';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { withBorder } from '../../reactutils/withBorder';
import { withLogin } from '../../reactutils/withLogin';
import { withNavLocation } from '../../reactutils/withNavLocation';
import { FuntipTitle } from '../../reactutils/withTitle';
import { GameBet } from '../../services/betting';
import { BetSavedIndicator } from '../BettingApp/BetSavedIndicator';
import { LoadingIndicatorContent } from '../common/LoadingIndicatorSpinner';
import { DateStr, TimeStr } from '../DateTime';
import { Login } from '../Login';
import { usePathSegment } from '../SysAdminApp/CacheStatisticsComponent';
import { isLoggedInSelector, userNameSelector } from '../System/selectors';
import { isRadioEins } from '../System/systemConfig';
import { TeamLink } from '../TeamLink';
import { GameResult } from '../TournamentApp/GameResult';
import { TournamentSelectionComponent } from '../TournamentSelectionComponent';
import { TournamentRoundSelectionComponent2 } from '../TournamentSelectionComponent/TournamentRoundSelectionComponent';
import { bettingLoad, bettingLoadBetQuote, bettingShowBetQuote, bettingWithoutProps } from './actions';
import { isValidGameResult } from './betting';
import BetComponent, { BetState } from './components/BetComponent/index';
import { BetPoints } from './components/BetPoints';
import { BetQuoteComponent } from './components/BetQuoteComponent';
import { GameBetInfoComponent } from './components/GameBetInfoComponent';
import {
    bettingBetQuoteGameSelector,
    bettingBetQuoteSelector,
    bettingBetStateSelector,
    bettingGameBetsSelector,
    bettingIsLoadingSelector,
    bettingShowBetQuoteSelector,
} from './selectors';

interface BettingRequestParams extends React.PropsWithChildren {
    trId: string;
}

export const BettingLink: React.FC<BettingRequestParams> = (props) => {
    return <Link to={bettingPath(props.trId)}>{props.children}</Link>;
};

export const bettingPath = (trId?: string) => {
    if (trId) {
        return '/b/betting/' + trId;
    } else {
        return '/b/betting';
    }
};

const Betting: React.FC<{}> = () => {
    const loggedIn = useSelector(isLoggedInSelector);
    const gameBets = useSelector(bettingGameBetsSelector);
    const betState = useSelector(bettingBetStateSelector);
    const betQuote = useSelector(bettingBetQuoteSelector);
    const showBetQuote = useSelector(bettingShowBetQuoteSelector);
    const betQuoteGame = useSelector(bettingBetQuoteGameSelector);
    const isLoading = useSelector(bettingIsLoadingSelector);
    const userName = useSelector(userNameSelector);

    const [trId] = usePathSegment(3);

    const dispatch = useDispatch();

    React.useEffect(() => {
        if (trId) {
            dispatch(bettingLoad(Number(trId)));
        }
    }, [dispatch, trId]);

    const onBetQuoteClose = () => {
        dispatch(bettingShowBetQuote(false));
    };

    const onBetQuoteClick = (gameId: number, bet1: number, bet2: number) => {
        dispatch(bettingLoadBetQuote({ gameId, bet1, bet2 }));
    };

    const renderGame = (gb: GameBet, betState: { [gameId: number]: BetState }, index: number, gameBets: GameBet[]) => {
        let bs: BetState = betState[gb.gameId];

        const showDate = index === 0 || !moment(gb.date).isSame(moment(gameBets[index - 1].date), 'day');
        const showTime = index === 0 || !moment(gb.date).isSame(moment(gameBets[index - 1].date), 'minute');
        return (
            <React.Fragment key={gb.gameId}>
                {showDate && (
                    <tr className="p-0">
                        <td className="py-1 pl-0 d-none d-md-table-cell h6" colSpan={10}>
                            <DateStr date={gb.date} />
                        </td>
                        <td className="py-1 pl-0 d-table-cell d-md-none h6 font-weight-bold" colSpan={9}>
                            <DateStr date={gb.date} />
                        </td>
                    </tr>
                )}
                <tr>
                    <td className="pl-0 align-middle py-1 d-none d-sm-table-cell">
                        {showTime && (
                            <span className="py-0 d-inline-block" style={{color: '#aaaaaa'}}>
                                <TimeStr date={gb.date} />
                            </span>
                        )}
                    </td>
                    <td className="px-0 py-1 align-middle">
                        <LoadingIndicatorContent isLoading={isLoading}>
                            <TeamLink teamId={gb.team1.id} withIcon />
                        </LoadingIndicatorContent>
                    </td>
                    <td className="px-0 py-1 align-middle">
                        <LoadingIndicatorContent isLoading={isLoading}>
                            <TeamLink teamId={gb.team2.id} withIcon />
                        </LoadingIndicatorContent>
                    </td>
                    <td className="px-1 py-1 align-middle">
                        <GameResult gameId={String(gb.gameId)} date={gb.date} />
                    </td>
                    <td className="px-1 py-1 align-middle px-sm-2">
                        <BetComponent
                            gameId={gb.gameId}
                            bet1={gb.bet1}
                            bet2={gb.bet2}
                            bettingAllowed={gb.betAllowed}
                            points={gb.points}
                            betState={bs}
                        />
                    </td>
                    <td className="px-0 py-1 px-sm-2 align-middle">
                        {!gb.betAllowed && isValidGameResult(gb.team1Result, gb.team2Result) && (
                            <BetPoints gameId={String(gb.gameId)} points={gb.points} showBestBets={gb.hasResult} />
                        )}
                        {gb.betAllowed && <BetSavedIndicator gameId={String(gb.gameId)} />}
                    </td>
                    <GameBetInfoComponent
                        gameId={String(gb.gameId)}
                        bet1={gb.bet1}
                        bet2={gb.bet2}
                        onBetQuoteClick={onBetQuoteClick}
                    />
                </tr>
            </React.Fragment>
        );
    };

    if (loggedIn) {
        if (!gameBets) {
            return <div>keine Spiele ...</div>;
        } else {
            return (
                <>
                    <FuntipTitle
                        title="Tipps abgeben"
                        subTitle={userName || ''}
                        leftComponent={
                            <TournamentSelectionComponent onTournamentChange={(tId) => bettingWithoutProps()} />
                        }
                        rightComponent={
                            <TournamentRoundSelectionComponent2
                                type="betting"
                                pathByTournamentRound={bettingPath}
                                trId={trId}
                            />
                        }
                    />
                    {showBetQuote && betQuote && (
                        <BetQuoteComponent betQuote={betQuote} gameBet={betQuoteGame} onClose={onBetQuoteClose} />
                    )}
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="d-none d-sm-table-cell">ZEIT</th>
                                    <th className="col-sm-9 d-table-cell px-1" colSpan={2}>BEGEGNUNG</th>
                                    <th className="d-none d-md-table-cell">ERGEBNIS</th>
                                    <th className="col-sm-1 d-table-cell d-md-none  px-1">ERG</th>
                                    <th className="col-sm-1 pl-1 pr-1">TIPP</th>
                                    <th className="d-none d-md-table-cell">PUNKTE</th>
                                    <th className="col-sm-1 d-table-cell d-md-none px-1">
                                        <span title="Punkte">P</span>
                                    </th>

                                    <th className="d-none d-sm-table-cell">
                                        <span title="Quote">Q</span>
                                    </th>
                                    <th className="d-none d-lg-table-cell">
                                        <span title="Prozentuale Tipp-Verteilung (G/U/V)">%</span>
                                    </th>
                                    <th className="d-none d-md-table-cell">
                                        <span title="Spielpaarung Statistik">S</span>
                                    </th>
                                    {isRadioEins() && (
                                        <th className="d-none d-sm-table-cell">
                                            <span title="Live-Ticker">T</span>
                                        </th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {gameBets.map((gb: GameBet, index, bets) => {
                                    // console.info('BetSTate = ' + this.props.betState.get(gb.gameId));
                                    return renderGame(gb, betState, index, bets);
                                })}
                            </tbody>
                        </table>
                        <TournamentRoundSelectionComponent2
                            type="betting"
                            pathByTournamentRound={bettingPath}
                            trId={trId}
                        />
                    </div>
                </>
            );
        }
    } else {
        return (
            <div>
                Bitte melden Sie sich zum Tippen an ... <Login />
            </div>
        );
    }
};

export const BettingScreen = R.compose(
    withLogin(),
    withBorder({ screenClassName: 'Betting' }),
    withNavLocation('betting'),
)(Betting);
