import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'reactstrap';
import { ActionTokenParams } from '../';
import { Section, SectionHeading } from '../../../common/Section';
import { actionTokenExecute } from '../actions';
import { actionTokenIsExecutedSelector } from '../selectors';

export const ExternalInvitationActionTokenHandler: FC<ActionTokenParams> = props => {
    const dispatch = useDispatch();

    const isExecuted = useSelector(actionTokenIsExecutedSelector);

    const onExecute = useCallback(() => {
        if (props.atId && props.uId && props.secId) {
            dispatch(actionTokenExecute({ atId: props.atId, uId: props.uId, secId: props.secId }));
        }
    }, [props.atId, props.secId, props.uId, dispatch]);

    useEffect(() => {
        const timer = setTimeout(() => onExecute(), 500);
        return () => clearTimeout(timer);
    }, [props.atId, props.secId, props.uId, onExecute]);

    return (
        <Section>
            <SectionHeading>Einladung zum Tippspiel</SectionHeading>
            {!isExecuted && (
                <>
                    <p>Ihre Einladung zum Tippspiel wird jetzt aktiviert ...</p>
                </>
            )}
            {isExecuted && (
                <Alert color="info">
                    Ihre Einladung ist aktiviert. Sie erhalten Ihre Zugangsdaten umgehend per Email.
                </Alert>
            )}
        </Section>
    );
};
