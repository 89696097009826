import { faGreaterThan, faLessThan, faStepBackward, faStepForward } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form } from 'reactstrap';
import { AnyAction } from 'typescript-fsa';

export interface PaginationParams {
    searchResultCount: (state: any) => number;
    searchResultCurrentPos: (state: any) => number;
    searchResultPageSize: (state: any) => number;
    onBackward: () => AnyAction;
    onFastBackward: () => AnyAction;
    onFastForward: () => AnyAction;
    onForward: () => AnyAction;
}

export const withPagination = <P extends object>(param: PaginationParams) => (
    Comp: React.ComponentType<P>
): React.ComponentType<P> => (props: P) => {
    return (
        <>
            <PagingComponent {...param} />
            <Comp {...props} />
            <PagingComponent {...param} />
        </>
    );
};

export const PagingComponent: FC<PaginationParams> = props => {
    const dispatch = useDispatch();

    const searchResultCount = useSelector(props.searchResultCount);
    const searchResultCurrentPos = useSelector(props.searchResultCurrentPos);
    const searchResultPageSize = useSelector(props.searchResultPageSize);

    const isForwardEnabled = (): boolean => {
        return searchResultCurrentPos < searchResultCount - searchResultPageSize;
    };

    const isBackwardEnabled = (): boolean => {
        return searchResultCurrentPos > 0;
    };

    return (
        <div className="d-inline-flex">
            <Form >
                <div className="d-flex d-flex-row d-nowrap align-items-center justify-content-center">
                    <Button
                        type="button"
                        className="btn-r1gray btn-sm mx-1"
                        aria-label="Left Align"
                        disabled={!isBackwardEnabled()}
                        onClick={() => dispatch(props.onFastBackward())}
                    >
                        <FontAwesomeIcon icon={faStepBackward} />
                    </Button>
                    <Button
                        type="button"
                        className="btn-r1gray btn-sm mx-1"
                        aria-label="Left Align"
                        disabled={!isBackwardEnabled()}
                        onClick={() => dispatch(props.onBackward())}
                    >
                        <FontAwesomeIcon icon={faLessThan} />
                    </Button>
                    <Button
                        type="button"
                        className="btn-r1gray btn-sm mx-1"
                        aria-label="Left Align"
                        disabled={!isForwardEnabled()}
                        onClick={() => dispatch(props.onForward())}
                    >
                        <FontAwesomeIcon icon={faGreaterThan} />
                    </Button>
                    <Button
                        type="button"
                        className="btn-r1gray btn-sm mx-1"
                        aria-label="Left Align"
                        disabled={!isForwardEnabled()}
                        onClick={() => dispatch(props.onFastForward())}
                    >
                        <FontAwesomeIcon icon={faStepForward} />
                    </Button>
                </div>
            </Form>
        </div>
    );
};
