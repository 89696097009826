import { library } from '@fortawesome/fontawesome-svg-core';
import { faGhost } from '@fortawesome/free-solid-svg-icons';
import { push } from '@lagunovsky/redux-react-router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { isAxiosError } from 'axios';
import * as React from 'react';
import Loadable, { LoadingComponentProps } from 'react-loadable';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router';
import './App.css';
import { NewsletterSubscription } from './components/AccountUserApp/NewsletterSubscription';
import { NewsletterArchiveScreen } from './components/AccountUserApp/NewsletterUserProfile';
import { bettingPath } from './components/Betting';
import BettingApp from './components/BettingApp';
import { LogoutMessage } from './components/BettingApp/LogoutMessage';
import { BloggingApp } from './components/BloggingApp';
import { BlogScreen } from './components/BloggingApp/Blog';
import { BlogList } from './components/BloggingApp/BlogList';
import { ClientAdminApp } from './components/ClientAdminApp';
import { ImprintStatement } from './components/Footer/ImprintStatement';
import { PrivacyStatement } from './components/Footer/PrivacyStatement';
import { groupInternalHighscoreForwarderPath } from './components/GroupInternaltHighscoresForwarder';
import { LoginForm } from './components/LoginForm';
import { WeatherForwardComponent } from './components/RadioEins/WeatherForward';
import { ActionTokenScreen } from './components/System/ActionToken';
import { AutomaticLogoutMessage } from './components/System/AutomaticLogoutMessage';
import { ErrorMessage } from './components/System/ErrorMessage';
import { GlobalFetchState } from './components/System/GlobalFetchState';
import { LogoutConfirmation } from './components/System/LogoutConfirmation';
import { PopupMessage } from './components/System/PopupMessage';
import {
    systemSetAuthenticatedAccessToken,
    systemShowLogoutConfirmation,
    systemStartup,
} from './components/System/actions';
import { logoutImmediately } from './components/System/system';
import { ACCESS_TOKEN_KEY, localBaseUrl } from './components/System/systemConfig';
import { LoadingIndicator } from './components/common/LoadingIndicator';
import { LoadingIndicatorSpinner } from './components/common/LoadingIndicatorSpinner';
import { RouteChangeAccountServerLog } from './components/common/RouteChange';
import { reduxStore } from './index';
import { ApiFootballMain } from './integration/apifootball';
import { SysAdmin } from './screens/SysAdmin';
import { myProfileBettingPath, myProfilePath, myUserMailPath } from './screens/UserProfileScreen';
import { ResponseError as AccountResponseError } from './services/account';
import { ResponseError as BettingResponseError } from './services/betting';
import { ResponseError as ImageResponseError } from './services/image';
import { ResponseError as PlaylistResponseError } from './services/playlist';
import { VerfiyAccessToken } from './components/System/VerifyAccessToken';
import { PushNotificationSubscriber } from './components/System/PushNotificationSubscriber';
import { PushNotificationAskForPermission } from './components/System/PushNotificationAskForPermission';

library.add(faGhost);

export type NavLocation =
    | 'betting'
    | 'my-highscore'
    | 'overall-highscore'
    | 'overall-group-highscore'
    | 'user-list'
    | 'usergroup-list'
    | 'profile'
    | 'live'
    | 'ranking'
    | 'rules'
    | 'prizes'
    | 'newsletter'
    | 'live-ticker';

export const Loading: React.FC<LoadingComponentProps> = (props) => {
    return (
        <div className="h-100 d-flex justify-content-center">
            <div className="align-self-center">
                Loading ... <LoadingIndicatorSpinner />
            </div>
        </div>
    );
};

const LoadableBettingApp = Loadable({
    loader: () => import('./components/BettingApp'),
    loading: Loading,
});

const LoadablePlaylistAdminApp = Loadable({
    loader: () => import('./components/PlaylistAdminApp'),
    loading: Loading,
});

export const postAccessTokenToLoginIframe = (token: string | undefined) => {
    const iframe = window?.top?.document.getElementById('ftLoginFrame_New');
    if (iframe && iframe instanceof HTMLIFrameElement) {
        if (iframe.contentWindow) {
            iframe.contentWindow.postMessage({ action: 'setAccessToken', value: token }, '*');
        } else {
            console.info('iframe content not loaded');
        }
    } else {
        console.info('login iframe not found! ' + JSON.stringify(iframe));
    }
};

const BaseVisualApp: React.FC<{}> = () => {
    return (
        <>
            <Routes>
                <Route path="a/*" element={<ApiFootballMain />} />
                <Route path="b/*" element={<BettingApp />} />
                <Route path="client-admin/*" element={<ClientAdminApp />} />
                <Route path="playlist-admin/*" element={<LoadablePlaylistAdminApp />} />
                <Route path="sys/*" element={<SysAdmin />} />
                <Route path="blog/*" element={<BloggingApp />} />
                <Route path="newsletter/subscription" element={<NewsletterSubscription />} />
                <Route path="newsletter/newsletterArchive.do" element={<Navigate to="/newsletter/archive" />} />
                <Route path="newsletter/archive/:offset?" element={<NewsletterArchiveScreen />} />
                <Route path="privacy" element={<PrivacyStatement />} />
                <Route path="imprint" element={<ImprintStatement />} />
                <Route path="blog-list" element={<BlogList />} />
                <Route path="blog-list/:blogId" element={<BlogScreen />} />

                {/* route for ActionToken handler ... don't remove */}
                <Route path="token.do" element={<ActionTokenScreen />} />

                {/* route for Konsole App logout handler ... don't remove */}
                <Route path="index.do" element={<LogoutMessage />} />
                <Route path="logout/*" element={<LogoutMessage />} />
                <Route path="betting.do" element={<LoadableBettingApp />} />
                <Route path="wetter/orte.htm/id=:locationId" element={<WeatherForwardComponent />} />
                {/* // default route */}
                <Route path="/*" element={<Navigate to="/b/portal" />} />
            </Routes>
            <LoadingIndicator />
            <ErrorMessage />
        </>
    );
};

window.addEventListener('message', (event: MessageEvent) => {
    if (event.data && event.data.action) {
        switch (event.data.action) {
            case 'loginFrameLoaded':
            case 'getAccessToken':
                if (event.source) {
                    if (!(event.source instanceof MessagePort)) {
                        const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
                        event.source.postMessage(
                            { action: 'setAccessToken', value: accessToken },
                            { targetOrigin: '*' },
                        );
                        // console.info('accessToken sent: ' + accessToken + ' to ' + event.source);
                    }
                }
                break;
            case 'changeUrl':
                const newUrl = event.data.value;
                switch (newUrl) {
                    case 'profile':
                        reduxStore.dispatch(push(myProfilePath()));
                        break;
                    case 'betting':
                        reduxStore.dispatch(push(bettingPath()));
                        break;
                    case 'group-internal-highscores':
                        const ugId = event.data.params.ugId;
                        reduxStore.dispatch(push(groupInternalHighscoreForwarderPath(ugId)));
                        break;
                    case 'logout':
                        reduxStore.dispatch(systemShowLogoutConfirmation(true));
                        break;
                    case 'clientadmin':
                        window.location.href = localBaseUrl() + '/adm/';
                        break;
                    case 'playlistadmin':
                        window.location.href = localBaseUrl() + '/adm2/playlist/play-list!playlistSearch.action';
                        break;
                    case 'registration':
                    case 'messages':
                        reduxStore.dispatch(push(myUserMailPath()));
                        break;
                    case 'betting-profile':
                        reduxStore.dispatch(push(myProfileBettingPath()));
                        break;
                }
        }
    }
});

const MAX_QUERY_FAILURES = 4;

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry(failureCount, error) {
                if (
                    error instanceof AccountResponseError ||
                    error instanceof BettingResponseError ||
                    error instanceof PlaylistResponseError ||
                    error instanceof ImageResponseError
                ) {
                    if (error.response.status === 404) {
                        console.debug('status = 404');
                    } else if (error.response.status === 401) {
                        logoutImmediately();
                    }
                    return error.response.status !== 404 && failureCount < MAX_QUERY_FAILURES;
                } else if (isAxiosError(error)) {
                    if (error.response && error.response.status === 401) {
                        logoutImmediately();
                    }
                    return !!error.response && error.response.status !== 404 && failureCount < MAX_QUERY_FAILURES;
                } else if (error instanceof Error) {
                    console.info('Error: ' + error.message + ':' + String(error));
                } else {
                    console.info('no ResponseError: ' + JSON.stringify(error));
                }
                return failureCount < MAX_QUERY_FAILURES;
            },
        },
    },
});

const App: React.FC<{}> = () => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        onLoad();
    });

    const onLoad = () => {
        dispatch(systemStartup());
    };

    return (
        <>
            <div className="px-0 container-fluid">
                <div className="r1-innerpadding">
                    <div style={{ backgroundColor: 'white' }}>
                        <QueryClientProvider client={queryClient}>
                            <GlobalFetchState />
                            <RouteChangeAccountServerLog />
                            <LogoutConfirmation />
                            <LoginForm />
                            <AutomaticLogoutMessage />
                            <PushNotificationSubscriber />
                            <PushNotificationAskForPermission />
                            <BaseVisualApp />
                            <LoadingIndicator />
                            <ErrorMessage />
                            <PopupMessage />
                            <ReactQueryDevtools initialIsOpen={false} />
                            <VerfiyAccessToken />
                        </QueryClientProvider>
                    </div>
                </div>
            </div>
        </>
    );
};

export const appCallback = {
    setAccessToken: (token: string) => {
        reduxStore.dispatch(systemSetAuthenticatedAccessToken(token));
    },
};

export default App;
