import { useMutation, useQuery } from '@tanstack/react-query';
import * as R from 'ramda';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { Button, Container, Table } from 'reactstrap';
import { withBorder } from '../../../reactutils/withBorder';
import { withLogin } from '../../../reactutils/withLogin';
import { withMenu } from '../../../reactutils/withMenu';
import { TabbedContent } from '../../../reactutils/withTabbedScreen';
import { withTitle } from '../../../reactutils/withTitle';
import { CacheView } from '../../../services/betting';
import { MenuSysAdmin } from '../../MenuSysAdmin';
import { systemSetPopupMessage } from '../../System/actions';
import { ServerType, getCacheApi, relevantServerNames } from '../../System/systemConfig';
import { ButtonWithConfirmation } from '../../common/ButtonWithConfirmation';
import { LoadingIndicatorContent } from '../../common/LoadingIndicatorSpinner';
import { SysAdminSystemSubMenu } from '../SysAdminSubMenu';
import { sysAdminCachePath } from '../sysAdminPathes';

export const getPathSegment = (path: string, pos: number): string => {
    const v = path.split('/');
    if (v.length > pos) {
        return decodeURIComponent(v[pos]);
    } else {
        return '';
    }
};

export const usePathSegment = (pos: number): string[] => {
    const location = useLocation();
    const [segment, setSegment] = React.useState<string>('');

    React.useEffect(() => {
        if (location && location.pathname) {
            setSegment(getPathSegment(location.pathname, pos));
        }
    }, [location, pos]);

    return [segment];
};

const CacheStatisticsComponent: React.FC<{}> = () => {
    const [serverName] = usePathSegment(3) as ServerType[];

    const api = getCacheApi(serverName);
    const dispatch = useDispatch();

    const [caches, setCaches] = React.useState<CacheView[]>([]);

    const cacheQuery = useQuery({
        queryKey: ['caches', serverName],
        queryFn: () => api.getCacheViews(),
        retry: false,
    });

    React.useEffect(() => {
        setCaches(cacheQuery.data || []);
    }, [cacheQuery.data]);

    const clearAllCachesMutation = useMutation({
        mutationFn: () => api.clearAllCaches(),
        onSuccess() {
            cacheQuery.refetch();
            dispatch(systemSetPopupMessage('Caches cleared'));
        },
        onError() {
            dispatch(systemSetPopupMessage('Failed to clear caches'));
        },
    });
    const clearCacheMutation = useMutation({
        mutationFn: (cache: string) => api.clearCache(cache),
        onSuccess() {
            cacheQuery.refetch();
            dispatch(systemSetPopupMessage(`Cache cleared`));
        },
        onError() {
            dispatch(systemSetPopupMessage('Failed to clear cache'));
        },
    });
    // const cachesOld = useSelector((state: any) => cacheEntitySelector(state, serverName));
    // const serverAvailable = useSelector((state: any) => cacheServerAvailableSelector(state, serverName));

    const onReload = () => {
        cacheQuery.refetch();
    };
    const onClearCache = (cache: string) => {
        clearCacheMutation.mutate(cache);
    };
    const onClearAllCaches = () => {
        clearAllCachesMutation.mutate();
    };

    function renderCache(c: CacheView) {
        const avgTime = c.requestCount > 0 ? c.requestTime / c.requestCount : 0;
        const hitRate = c.requestCount > 0 ? (c.hitCount * 100.0) / c.requestCount : 0;
        const missRate = c.requestCount > 0 ? (c.missCount * 100.0) / c.requestCount : 0;
        return (
            <tr key={c.id}>
                <td className="text-break">
                    <LoadingIndicatorContent isLoading={cacheQuery.isFetching}>{c.name}</LoadingIndicatorContent>
                </td>
                <td className="text-end d-none d-lg-table-cell">{c.hitCount}</td>
                <td className="text-end d-none d-lg-table-cell">{c.missCount}</td>
                <td className="text-end d-none d-lg-table-cell">{avgTime.toLocaleString()}</td>
                <td className="text-end d-none d-lg-table-cell">{c.requestTime}</td>
                <td className="text-end d-none d-lg-table-cell">{c.deleteCount}</td>
                <td className="text-end d-none d-lg-table-cell">{c.putCount}</td>
                <td className="text-end d-none d-lg-table-cell">{hitRate.toLocaleString()}</td>
                <td className="text-end d-none d-lg-table-cell">{missRate.toLocaleString()}</td>
                <td className="text-end d-none d-lg-table-cell">{c.timeToLive}</td>
                <td>
                    <ButtonWithConfirmation
                        title="Clear cache"
                        message={`Are you sure that you want to clear caches '${c.name}'?`}
                        color="danger"
                        outline={true}
                        onOK={() => onClearCache(c.id)}
                    >
                        Clear
                    </ButtonWithConfirmation>
                </td>
            </tr>
        );
    }

    return (
        <>
            <TabbedContent activeTab={serverName} tabNames={relevantServerNames()} tabLinks={sysAdminCachePath}>
                <Container>
                    <Button className="m-2" color="secondary" onClick={onReload} outline={false}>
                        Reload
                    </Button>

                    <ButtonWithConfirmation
                        title="Clear all caches"
                        message={`Are you sure that you want to clear all caches on server '${serverName}'`}
                        className="m-2"
                        color="danger"
                        onOK={onClearAllCaches}
                        outline={false}
                    >
                        Clear all
                    </ButtonWithConfirmation>
                </Container>
                <Table striped={true}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th className="d-none d-lg-table-cell">Hit Count</th>
                            <th className="d-none d-lg-table-cell">Miss count</th>
                            <th className="d-none d-lg-table-cell">Avg Time (ms)</th>
                            <th className="d-none d-lg-table-cell">Total Time (ms)</th>
                            <th className="d-none d-lg-table-cell">Deletes</th>
                            <th className="d-none d-lg-table-cell">Puts</th>
                            <th className="d-none d-lg-table-cell">Hit Rate</th>
                            <th className="d-none d-lg-table-cell">Miss Rate</th>
                            <th className="d-none d-lg-table-cell">TTL</th>
                            <th>Clear</th>
                        </tr>
                    </thead>
                    <tbody>{caches && caches.map((c) => renderCache(c))}</tbody>
                </Table>
            </TabbedContent>
        </>
    );
};

export const CacheStatisticsScreen = R.compose(
    withMenu(<MenuSysAdmin />),
    withBorder(),
    withMenu(<SysAdminSystemSubMenu />),
    withTitle('Caches'),
    withLogin(),
)(CacheStatisticsComponent);
