import * as R from 'ramda';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { withBorder } from '../../../reactutils/withBorder';
import { withNavLocation } from '../../../reactutils/withNavLocation';
import { withTitle } from '../../../reactutils/withTitle';
import { bettingPath } from '../../Betting';
import { Section, SectionHeading } from '../../common/Section';
import { userGroupHighscorePath, userGroupListPath } from '../../UserGroupHighscores';
import { prizesPath } from '../R1Prices';

const R1RulesComponent: FC<{}> = () => {
    return (
        <>
            <Section>
                <SectionHeading>Worum geht's beim Tippspiel?</SectionHeading>
                Darum, Spaß zu haben. Sie spielen mit und gegen Tausende andere. Sie können sich zu Tippteams
                zusammenschließen und versuchen, besser zu sein, als die Truppe von radioeins.
            </Section>

            <Section>
                <SectionHeading>Was kann ich tippen?</SectionHeading>
                Jede Woche den Ausgang der Partien in der 1. und in der 2. Bundesliga. Im Angebot ist außerdem die
                Champions League. Und wenn es um Welt- oder Europameisterschaftstitel geht, ist radioeins natürlich auch
                dabei...
            </Section>

            <Section>
                <SectionHeading>Punktevergabe</SectionHeading>
                Getippt wird jeweils das konkrete Spielergebnis.
                <br />
                &nbsp;
                <br />
                <strong>Beispiel</strong>
                <br />
                Lautet die Ansetzung "Team A vs. Team B" und Sie tippen als Ergebnis 4:1, dann:
                <ul style={{ marginLeft: '15px', listStyleType: 'disc' }}>
                    <li>
                        gibt es <em>keine</em> Punkte, wenn anschlie&szlig;end Team B gewonnen hat bzw. unentschieden
                        gespielt wurde.
                    </li>
                    <li>kassiert Punkte, wer 4:1 oder jeden anderen Sieg f&uuml;r Team A getippt hat.</li>
                </ul>
                &nbsp;
                <br />
                <strong>Die genaue Anzahl der Punkte setzt sich aus zwei Punktkomponenten zusammen:</strong>
                <br />
                &nbsp;
                <ul style={{ marginLeft: '15px', listStyleType: 'disc' }}>
                    <li>
                        <strong>Punkte f&uuml;r die richtige Tipptendenz</strong>
                        <br />
                        Sie haben Sieg f&uuml;r Team A getippt, das kann beispielweise auch ein 1:0 oder 3:1 gewesen
                        sein.
                        <br />
                        &nbsp;
                    </li>
                    <li>
                        <strong>Punkte f&uuml;r das exakte Ergebnis</strong>
                    </li>
                </ul>
                &nbsp;
                <br />
                Haben nur wenige Mitspieler ein Ergebnis, das die Tendenz richtig vorhergesagt, gibt es f&uuml;r einen
                zutreffenden Tipp &uuml;berproportional viele Punkte.
                <br />
                &nbsp;
                <br />
                <strong>Die Berechnung der Punkte erfolgt nach folgender Tippspiel-Formel:</strong>
                <br />
                &nbsp;
                <br />
                <table className="tsfaqs">
                    <tr>
                        <td align="right">Punktzahl f&uuml;r exaktes Ergebnis = </td>
                        <td align="center">
                            Anzahl der Tipps insgesamt
                            <hr />
                            Anzahl der Tipps mit exaktem Ergebnis{' '}
                        </td>
                    </tr>
                </table>
                &nbsp;
                <br />
                <em>Die gleiche Formel gilt auch f&uuml;r die Komponente "Tipptendenz".</em>
                <br />
                &nbsp;
                <br />
                Zus&auml;tzlich wird die Punktzahl je Punktkomponente durch einen Minimal- und Maximalwert begrenzt:
                <br />
                &nbsp;
                <ul style={{ marginLeft: '15px', listStyleType: 'disc' }}>
                    <li>
                        <strong>Minimalwert = 3</strong> (je Punktekomponente)
                    </li>
                    <li>
                        <strong>Maximalwert = 10</strong> (je Punktekomponente)
                    </li>
                </ul>
                &nbsp;
                <br />
                Damit k&ouml;nnen bei jedem korrekt getippten Spiel mindestens sechs und maximal 20 Punkte erreicht
                werden.
                <br />
                &nbsp;
                <table >
                    <thead>
                        <tr>
                            <th colSpan={3} id="ergebnis" scope="col" className="spalte_links">
                                <strong>allgemeine Wahrscheinlichkeits-Tabelle pro Wert</strong>
                            </th>
                        </tr>
                    </thead>
                    <tr className="tr_farbe1">
                        <td className="spalte_links">korrekte Tipps &and; Tendenzen bei 10.000 Tippern</td>
                        <td align="right">Wahrscheinlichkeit (gerundet)</td>
                        <td align="right">Punkte</td>
                    </tr>
                    <tr className="tr_farbe2">
                        <td className="spalte_links">1 - 1.000</td>
                        <td align="right">&lt; 10 %</td>
                        <td align="right">10</td>
                    </tr>
                    <tr className="tr_farbe1">
                        <td className="spalte_links">1.001 - 1.111</td>
                        <td align="right">ca. 11 %</td>
                        <td align="right">9</td>
                    </tr>
                    <tr className="tr_farbe2">
                        <td className="spalte_links">1.112 - 1.250</td>
                        <td align="right">ca. 12 %</td>
                        <td align="right">8</td>
                    </tr>
                    <tr className="tr_farbe1">
                        <td className="spalte_links">1.251 - 1.428</td>
                        <td align="right">ca. 13 - 14 %</td>
                        <td align="right">7</td>
                    </tr>
                    <tr className="tr_farbe2">
                        <td className="spalte_links">1.429 - 1.666</td>
                        <td align="right">ca. 15 - 16 %</td>
                        <td align="right">6</td>
                    </tr>
                    <tr className="tr_farbe1">
                        <td className="spalte_links">1.667 - 2.000</td>
                        <td align="right">ca. 17 - 20 %</td>
                        <td align="right">5</td>
                    </tr>
                    <tr className="tr_farbe2">
                        <td className="spalte_links">2.001 - 2.500</td>
                        <td align="right">ca. 21 - 25 %</td>
                        <td align="right">4</td>
                    </tr>
                    <tr className="tr_farbe1">
                        <td className="spalte_links">2.501 - 10.000</td>
                        <td align="right">&gt; 26 %</td>
                        <td align="right">3</td>
                    </tr>
                </table>
                &nbsp;
                <br />
                <strong>Unser Beispiel</strong>
                <table >
                    <thead>
                        <tr>
                            <th colSpan={4} id="ergebnis" scope="col" className="spalte_links">
                                <strong>Korrekt getippt bei einem Spielergebnis von 4:1</strong>
                            </th>
                        </tr>
                    </thead>
                    <tr className="tr_farbe1">
                        <td className="spalte_links">Anzahl der Tipps insgesamt</td>
                        <td align="right">10.000</td>
                        <td align="right">&nbsp;</td>
                        <td align="right">&nbsp;</td>
                    </tr>
                    <tr className="tr_farbe2">
                        <td className="spalte_links">Korrekt getippt:</td>
                        <td align="right">100</td>
                        <td align="right">1 %</td>
                        <td align="right">10</td>
                    </tr>
                    <tr className="tr_farbe1">
                        <td className="spalte_links">Korrekte Tendenz</td>
                        <td align="right">800</td>
                        <td align="right">8 %</td>
                        <td align="right">10</td>
                    </tr>
                    <tr className="tr_farbe2">
                        <td className="spalte_links">
                            <strong>Summe</strong>
                        </td>
                        <td align="right">&nbsp;</td>
                        <td align="right">&nbsp;</td>
                        <td align="right">
                            <strong>20</strong>
                        </td>
                    </tr>
                </table>
                &nbsp;
                <br />
                <em>
                    <strong>
                        Die Wertung erfolgt ohne Ber&uuml;cksichtigung eines eventuellen Elfmeterschie&szlig;ens - d.h.
                        das Spielergebnis nach Ende der regul&auml;ren Spielzeit bzw. der Verl&auml;ngerung ist
                        ausschlaggebend!
                    </strong>
                </em>
                <br />
                &nbsp;
                <br />
            </Section>
            <Section>
                <SectionHeading>Wie kann ich die Punktvergabe nachvollziehen?</SectionHeading>
                Indem Sie, wenn Sie Ihren Tipp abgeben, das kleine Fragezeichen neben dem Eingabefeld anklicken. Ein
                Fenster &ouml;ffnet sich. Dort sehen Sie, wie viel Punkte Ihr Tipp bringen w&uuml;rde.
                <br />
                &nbsp;
                <br />
                <img
                    className="img-fluid"
                    src="https://www.radioeins.de/content/dam/rbb/rad/image_bilder/tstipp.jpg"
                    alt="Fu&szlig;ball-Tippspiel - Screenshot Tipp-Vorschau"
                    title="Fu&szlig;ball-Tippspiel - Screenshot Tipp-Vorschau"
                />
                <br />
                &nbsp;
                <br />
                Nach Spielende klicken Sie einfach die rote Punktzahl hinter dem von Ihnen getippten Ergebnis an und
                wieder &ouml;ffnet sich ein Fenster, in dem Sie die endg&uuml;ltige Punktvergabe nachvollziehen
                k&ouml;nnen.
                <br />
                &nbsp;
                <br />
                <img
                    className="img-fluid"
                    src="https://www.radioeins.de/content/dam/rbb/rad/image_bilder/tsergebnis.jpg"
                    alt="Fu&szlig;ball-Tippspiel - Screenshot Tipp-Ergebnis"
                    title="Fu&szlig;ball-Tippspiel - Screenshot Tipp-Ergebnis"
                />
                <br />
                &nbsp;
                <br />
            </Section>
            <Section>
                <SectionHeading>Wann kann ich tippen?</SectionHeading>
                Bis unmittelbar vor Anpfiff der Spiele. Dann ist es, wie im Casino. Nichts geht mehr. Vor Spielbeginn
                k&ouml;nnen Sie ihre Tipps jedoch beliebig oft ver&auml;ndern. Um sicher zu gehen sollten Sie jedoch bis
                ca. eine Stunde vor Spielbeginn Ihre Tipps abgegeben haben.
                <br />
                &nbsp;
                <br />
            </Section>
            <Section>
                <SectionHeading>Kann ich sehen, wie andere SpielerInnen getippt haben?</SectionHeading>
                Ja, aber erst wenn die Partien laufen und nat&uuml;rlich nach Spielschluss. K&ouml;nnte man vor Anpfiff
                der Spiele sehen, wie andere getippt haben, w&uuml;rde einen das ja evtl. "irritieren".
                <br />
                &nbsp;
                <br />
            </Section>
            <Section>
                <SectionHeading>Was kostet es?</SectionHeading>
                Nichts. Von den &uuml;blichen Telefon-/Internet-Zugangskosten mal abgesehen.
                <br />
                &nbsp;
                <br />
            </Section>
            <Section>
                <SectionHeading>Kann ich etwas gewinnen?</SectionHeading>
                Klar. Das Tippspiel bietet Nervenkitzel und die M&ouml;glichkeit, sich mit Tausenden anderen Leuten zu
                vergleichen. Und es gibt kleine virtuelle Pokale, die auf den Seiten des Tippspiels jedes Mal neben
                Ihrem Namen abgebildet werden. Goldene Pokale f&uuml;r den Gesamtsieg bei einem EM- oder WM-Tipp-Turnier
                bzw. f&uuml;r Saisonsiege in der Bundesliga. Gr&uuml;ne Pokale gibt es dagegen bereits f&uuml;r alle,
                die einen Spieltag in der Bundesliga gewonnen haben.
                <br />
                &nbsp;
                <br />
                Zus&auml;tzlich warten noch diese <Link to={prizesPath()}>Preise</Link> auf Sie.
                <br />
                <em>Der Rechtsweg ist ausgeschlossen.</em>
                <br />
                &nbsp;
                <br />
            </Section>
            <Section>
                <SectionHeading>Wo sehe ich, wie ich abgeschnitten habe?</SectionHeading>
                Ganz einfach. Nach Spielschluss gehen Sie auf den Button "Einzelwertung" und geben Ihren Spielernamen in
                das Suchfeld ein. Schon sehen Sie Ihre Platzierung.
                <br />
                Spielen Sie au&szlig;erdem in einer Mannschaft, klicken Sie auf "Teamwertung". Dort stehen die
                Ergebnisse der Mannschaften.
                <br />
            </Section>
            <Section>
                <SectionHeading>Ist eine Anmeldung erforderlich?</SectionHeading>
                Ja. Denn nur so k&ouml;nnen Sie sich mit anderen Spielern vergleichen und andere Tipper sich mit Ihnen.
                Hier geht es zum <Link to={bettingPath('')}>Registrieren</Link>
                .
                <br />
                &nbsp;
                <br />
            </Section>
            <Section>
                <SectionHeading>Worum geht es im Profil?</SectionHeading>
                Das sind kleine Visitenkarten. Auf denen stellen sich die TipperInnen vor. Sie k&ouml;nnen sich einen
                Tipper-Namen aussuchen. Manche spielen unter ihrem b&uuml;rgerlichen Namen. Andere w&auml;hlen ein
                witziges Pseudonym. Im Profil k&ouml;nnen Sie sich mit einem Foto vorstellen und mit Bekenntnissen, etwa
                &uuml;ber ihren Lieblingsspieler, -verein, -spruch. Auch die Tippteams k&ouml;nnen Profile
                ausf&uuml;llen.
                <br />
                &nbsp;
                <br />
            </Section>
            <Section>
                <SectionHeading>Der Datenschutz</SectionHeading>
                Sofern benutzerbezogene Daten erfragt werden, so werden diese lediglich f&uuml;r a) die Ermittlung von
                Ergebnissen,
                <br />
                b) die Benachrichtigung &uuml;ber Ergebnisse (sofern gew&uuml;nscht),
                <br />
                c) die Anzeige von Ergebnissen (sofern gew&uuml;nscht) o d e r<br />
                d) die Benachrichtigung &uuml;ber neue bzw. fortgef&uuml;hrte Tipp-Wettbewerbe verwendet.
                <br />
                &nbsp;
                <br />
                Ausf&uuml;hrliche Informationen zu den radioeins AGBs finden Sie{' '}
                <Link to="http://www.radioeins.de/agb">hier...</Link>
                <br />
                &nbsp;
                <br />
            </Section>
            <Section>
                <SectionHeading>Warum auch noch im Team tippen?</SectionHeading>
                <strong>Der Clou:</strong>
                Sie spielen ihr Wettspiel untereinander U N D als Mannschaft messen Sie sich zugleich mit vielen anderen
                Teams, darunter dem von radioeins. Au&szlig;erdem vergr&ouml;&szlig;ern Sie ihre Chance, einen der{' '}
                <Link to="/b/prizes">Preise</Link> zu gewinnen. Neben dem Jackpot in ihrer Kollegenrunde spielen Sie auch
                um die tollen Preise in der Einzelwertung und in der Teamwertung bei radioeins.
                <br />
                &nbsp;
                <br />
            </Section>
            <Section>
                <SectionHeading>Wie funktioniert ein Team-Tipp?</SectionHeading>
                Jedes Mitglied einer Mannschaft gibt einen eigenen Tipp an. Nach dem Spiel oder dem Turnier werden die
                Punktanzahlen der Teammitglieder addiert. Die so ermittelte Summe wird anschlie&szlig;end durch die
                Anzahl der Spieler dividiert. Das Ergebnis (der Punktedurchschnitt) bestimmt die Reihenfolge der Teams.
                Je h&ouml;her der Durchschnitt, desto besser die Platzierung. Die Reihenfolge der Teams wird unter{' '}
                <Link to={userGroupHighscorePath('')}>Team-Wertung</Link>
                angezeigt. Ein Klick auf den Namen der Mannschaft und Sie sehen die Reihenfolge innerhalb eines Teams.
                <br />
                &nbsp;
                <br />
            </Section>
            <Section>
                <SectionHeading>Wie viel Tipper bilden ein Team?</SectionHeading>
                Es m&uuml;ssen mindestens drei Leute sein. Nach oben gibt es keine Begrenzung der Mitgliederzahl.
                <br />
                &nbsp;
                <br />
            </Section>
            <Section>
                <SectionHeading>Z&auml;hlen auch die Punkte, die jemand mitbringt?</SectionHeading>
                Nein. Es z&auml;hlen nur die Punkte, die nach Eintritt in das Team Erzielt wurden.
                <br />
                &nbsp;
                <br />
            </Section>
            <Section>
                <SectionHeading>Darf man Mitglied in verschiedenen Teams sein?</SectionHeading>
                Ja, Sie d&uuml;rfen. Ein Team im B&uuml;ro und dann noch das Familienteam und dann noch... so viel Sie
                m&ouml;chten...
                <br />
                &nbsp;
                <br />
            </Section>
            <Section>
                <SectionHeading>Wie finde ich das richtige Team?</SectionHeading>
                Am einfachsten ist es, sie gr&uuml;nden selbst ein Team.
                <br />
                &nbsp;
                <br />
                Wenn Sie in ein bereits bestehendes Team aufgenommen werden wollen, dann Melden Sie sich zuerst an, und
                suchen sich die entsprechende Mannschaft aus und stellen unter
                <Link to={userGroupListPath()}>Suche</Link>
                einen Aufnahmeantrag. Teams sind allerdings nicht verpflichtet, BewerberInnen aufzunehmen.
                <br />
                &nbsp;
                <br />
            </Section>
            <Section>
                <SectionHeading>radioeins mobile - Fu&szlig;ball-Tippspiel f&uuml;r unterwegs</SectionHeading>
                Sie haben vergessen, den n&auml;chsten Spieltag zu tippen und das erste Spiel f&auml;ngt gleich an? Der
                n&auml;chste Rechner steht im B&uuml;ro oder zu Hause, aber Sie sind unterwegs? Ab sofort kein Problem,
                denn Sie haben ja Ihr Mobiltelefon in der Tasche!
                <br />
                &nbsp;
                <br />
                Besuchen Sie mit dem Browser Ihres Telefons einfach{' '}
                <Link to="https://mein.radioeins.de/">https://mein.radioeins.de/</Link>, loggen Sie sich ein, und
                schon k&ouml;nnen Sie Ihre Tipps abgeben, egal wo Sie sind.
                <br />
                &nbsp;
                <br />
                Das mobile Tippspiel ist von Seiten des Rundfunks Berlin-Brandenburg - radioeins - kostenlos! Die
                Geb&uuml;hren f&uuml;r die &Uuml;bertragung der Daten variieren je nach Netzbetreiber.
                <br />
                &nbsp;
                <br />
            </Section>
        </>
    );
};

export const rulesPath = () => `/b/rules`;

export const R1RulesScreen = R.compose(withBorder(), withTitle('Regeln'), withNavLocation('rules'))(R1RulesComponent);
