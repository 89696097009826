import { GlobalState } from '../actions';

const userViewSelector = (state: GlobalState) => state.userView;

export const userViewAccountUserProfileSelector = (state: GlobalState) => userViewSelector(state).accountUserProfile;

export const userViewBettingUserProfileSelector = (state: GlobalState) => userViewSelector(state).bettingUserProfile;

export const userViewUserIdSelector = (state: GlobalState) => userViewSelector(state).userId;

export const userViewTabNameSelector = (state: GlobalState) => userViewSelector(state).tabName;

export const userViewUserNameSelector = (state: GlobalState) => {
    const accountUserProfile = userViewAccountUserProfileSelector(state);
    if (accountUserProfile) {
        return accountUserProfile.name;
    } else {
        return '';
    }
};

export const userViewPlaylistProfileSelector = (state: GlobalState) => userViewSelector(state).playlistUser;

export const userViewPlaylistSelector = (state: GlobalState) => userViewSelector(state).userSongs;
export const userViewBettingUserSelector = (state: GlobalState) => userViewSelector(state).bettingUser;