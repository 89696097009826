/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  RestResult,
  UseCaseAccounting,
} from '../models';
import {
    RestResultFromJSON,
    RestResultToJSON,
    UseCaseAccountingFromJSON,
    UseCaseAccountingToJSON,
} from '../models';

/**
 * 
 */
export class UseCaseLogControllerApi extends runtime.BaseAPI {

    /**
     */
    async clearUseCaseLogsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admsys/usa-case-logs/`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResultFromJSON(jsonValue));
    }

    /**
     */
    async clearUseCaseLogs(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult> {
        const response = await this.clearUseCaseLogsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getUseCaseLogsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UseCaseAccounting>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admsys/usa-case-logs/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UseCaseAccountingFromJSON));
    }

    /**
     */
    async getUseCaseLogs(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UseCaseAccounting>> {
        const response = await this.getUseCaseLogsRaw(initOverrides);
        return await response.value();
    }

}
