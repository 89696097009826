/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.25
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LoginToken
 */
export interface LoginToken {
    /**
     * 
     * @type {string}
     * @memberof LoginToken
     */
    token?: string;
    /**
     * 
     * @type {number}
     * @memberof LoginToken
     */
    userId?: number;
    /**
     * 
     * @type {Date}
     * @memberof LoginToken
     */
    validThru?: Date;
    /**
     * 
     * @type {Array<string>}
     * @memberof LoginToken
     */
    roles?: Array<string>;
}

/**
 * Check if a given object implements the LoginToken interface.
 */
export function instanceOfLoginToken(value: object): value is LoginToken {
    return true;
}

export function LoginTokenFromJSON(json: any): LoginToken {
    return LoginTokenFromJSONTyped(json, false);
}

export function LoginTokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginToken {
    if (json == null) {
        return json;
    }
    return {
        
        'token': json['token'] == null ? undefined : json['token'],
        'userId': json['userId'] == null ? undefined : json['userId'],
        'validThru': json['validThru'] == null ? undefined : (new Date(json['validThru'])),
        'roles': json['roles'] == null ? undefined : json['roles'],
    };
}

export function LoginTokenToJSON(value?: LoginToken | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'token': value['token'],
        'userId': value['userId'],
        'validThru': value['validThru'] == null ? undefined : ((value['validThru']).toISOString()),
        'roles': value['roles'],
    };
}

