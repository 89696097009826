import { faCopy, faEnvelope, faFile, faMailBulk, faPlus, faRetweet, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import * as R from 'ramda';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, FormGroup, Input, Label, Table } from 'reactstrap';
import { useDebounce } from 'use-debounce';
import { withBorder } from '../../../../reactutils/withBorder';
import { withFooter } from '../../../../reactutils/withFooter';
import { withLogin } from '../../../../reactutils/withLogin';
import { withMenu } from '../../../../reactutils/withMenu';
import { NewsletterAdmin } from '../../../../services/webapp';
import { ButtonWithConfirmation } from '../../../common/ButtonWithConfirmation';
import { Section, SectionHeading } from '../../../common/Section';
import { systemSetPopupMessage } from '../../../System/actions';
import { getNewsletterAdminApi, getSystemConfig } from '../../../System/systemConfig';
import { MenuClientAdmin } from '../../MenuClientAdmin';
import { newsletterEditPath } from '../NewsletterEdit';
import { LoadingIndicatorSpinner2 } from '../../../common/LoadingIndicatorSpinner2';
import { NewsletterMainModalEdit } from '../NewsletterEdit/NewsletterMainEdit';
import { useSelector } from 'react-redux';
import { isSysAdminSelector } from '../../../System/selectors';

export const NewsletterList: React.FC = () => {
    const api = getNewsletterAdminApi();

    const [filter, setFilter] = React.useState('');
    const dispatch = useDispatch();

    const [debouncedFilter] = useDebounce(filter, 500);

    const isSysAdmin = useSelector(isSysAdminSelector);

    const newsletterQuery = useQuery({
        queryKey: ['newsletter', debouncedFilter],
        queryFn: () =>
            api.searchNewsletters({
                clientId: String(getSystemConfig().clientId),
                filter: debouncedFilter,
                offset: 0,
                pageSize: 20,
            }),
    });

    const copyNewsletterMutation = useMutation({
        mutationFn: (id: string) => api.copyNewsletter({ nlId: id }),
    });

    const deleteNewsletterMutation = useMutation({
        mutationFn: (id: string) => api.deleteNewsletter({ nlId: id }),
    });

    const toggleNewsletterStateMutation = useMutation({
        mutationFn: (id: string) => api.toggleNewsletterState({ nlId: id }),
    });

    const setNewsletterStateNewMutation = useMutation({
        mutationFn: (id: string) => api.setStateNew({ nlId: id }),
    });

    const onCopy = (nlId: string) => {
        copyNewsletterMutation.mutate(nlId, {
            onSuccess: () => {
                newsletterQuery.refetch();
                dispatch(systemSetPopupMessage('Newsletter kopiert'));
            },
            onError: (error) => {
                dispatch(systemSetPopupMessage('Fehler beim Kopieren des Newsletters: ' + error));
            },
        });
    };

    const onDelete = (nlId: string) => {
        deleteNewsletterMutation.mutate(nlId, {
            onSuccess: () => {
                newsletterQuery.refetch();
                dispatch(systemSetPopupMessage('Newsletter gelöscht'));
            },
            onError: (error) => {
                dispatch(systemSetPopupMessage('Fehler beim Löschen des Newsletters: ' + error));
            },
        });
    };

    const onToggleState = (nlId: string) => {
        toggleNewsletterStateMutation.mutate(nlId, {
            onSuccess: () => {
                newsletterQuery.refetch();
                dispatch(systemSetPopupMessage('Newsletter-Status geändert'));
            },
            onError: (error) => {
                dispatch(systemSetPopupMessage('Fehler beim Ändern des Newsletters-Status: ' + error));
            },
        });
    };

    const onSetStateNew = (nlId: string) => {
        setNewsletterStateNewMutation.mutate(nlId, {
            onSuccess: () => {
                newsletterQuery.refetch();
                dispatch(systemSetPopupMessage('Newsletter-Status geändert'));
            },
            onError: (error) => {
                dispatch(systemSetPopupMessage('Fehler beim Ändern des Newsletters-Status: ' + error));
            },
        });
    };

    const onFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(e.target.value);
    };

    const formatDate = (date: Date | undefined) => {
        if (date) {
            return DateTime.fromJSDate(date).toFormat('dd.MM.yy HH:mm');
        } else {
            return '-';
        }
    };

    const stateStr = (state: number) => {
        switch (state) {
            case 1:
                return <FontAwesomeIcon icon={faFile} title="Neu" />;
            case 2:
                return <FontAwesomeIcon icon={faMailBulk} title="wird versendet" />;
            case 3:
                return <FontAwesomeIcon icon={faEnvelope} title="versendet" />;
            default:
                return 'Unknown';
        }
    };

    const renderNewsletter = (nl: NewsletterAdmin, index: number) => {
        return (
            <tr key={nl.id}>
                <td className="text-center">{stateStr(nl.state)}</td>
                <td>{nl.state === 1 ? <Link to={newsletterEditPath(nl.id)}>{nl.title}</Link> : nl.title}</td>
                <td>{nl.author}</td>
                <td>{nl.receiverCount}</td>
                <td>{nl.archiveAccessCount}</td>
                <td>{nl.clickCount}</td>
                <td>{formatDate(nl.lastEdited)}</td>
                <td>{formatDate(nl.startSending)}</td>
                <td>{nl.layoutName}</td>
                <td>{nl.receiverFilterName}</td>
                <td>
                    <ButtonWithConfirmation
                        color="link"
                        className="border m-1"
                        onOK={() => onCopy(nl.id)}
                        title="Newsletter kopieren"
                        message={`Sind Sie sicher, dass Sie den Newsletter '${nl.title}' kopieren wollen?`}
                    >
                        <FontAwesomeIcon icon={faCopy} color="black" title="Newsletter kopieren" />
                    </ButtonWithConfirmation>
                    {nl.state === 1 && (
                        <ButtonWithConfirmation
                            color="link"
                            className="border m-1"
                            onOK={() => onDelete(nl.id)}
                            title="Newsletter löschen"
                            message={`Sind Sie sicher, dass Sie den Newsletter '${nl.title}' löschen wollen?`}
                        >
                            <FontAwesomeIcon icon={faTrash} color="red" title="Newsletter löschen" />
                        </ButtonWithConfirmation>
                    )}
                    {nl.state === 3 && (
                        <ButtonWithConfirmation
                            color="link"
                            className="border m-1"
                            onOK={() => onToggleState(nl.id)}
                            title="Newsletter-Status ändern"
                            message={`Sind Sie sicher, dass Sie den Status des Newsletter '${nl.title}' ändern wollen?`}
                        >
                            <FontAwesomeIcon icon={faRetweet} color="blue" title="Newsletter-Status ändern" />
                        </ButtonWithConfirmation>
                    )}
                    {nl.state === 2 && isSysAdmin && (
                        <ButtonWithConfirmation
                            color="link"
                            className="border m-1"
                            onOK={() => onSetStateNew(nl.id)}
                            title="Newsletter-Status zurücksetzen"
                            message={`Sind Sie sicher, dass Sie den Status des Newsletter '${nl.title}' zurücksetzen wollen?`}
                        >
                            <FontAwesomeIcon icon={faRetweet} color="red" title="Newsletter-Status auf 'Neu' ändern" />
                        </ButtonWithConfirmation>
                    )}
                </td>
            </tr>
        );
    };

    const [showCreateDialog, setShowCreateDialog] = React.useState(false);

    const onCloseCreateDialog = () => {
        newsletterQuery.refetch();
        setShowCreateDialog(false);
    };

    return (
        <Section>
            <SectionHeading>NewsletterAdmin</SectionHeading>
            <FormGroup>
                <Label>Filter</Label>
                <Input type="text" value={filter} onChange={onFilterChange} />
            </FormGroup>
            <Button color="success" onClick={() => setShowCreateDialog(true)}>
                <FontAwesomeIcon icon={faPlus} /> Neuer Newsletter
            </Button>
            <LoadingIndicatorSpinner2 isLoading={newsletterQuery.isFetching} />
            {showCreateDialog && <NewsletterMainModalEdit onClose={onCloseCreateDialog} />}

            <Table>
                <thead>
                    <tr>
                        <th>Status</th>
                        <th>Titel</th>
                        <th>Autor</th>
                        <th>Empfänger</th>
                        <th>Archivleser</th>
                        <th>Clicks</th>
                        <th>Änderung</th>
                        <th>Sendedatum</th>
                        <th>Layout</th>
                        <th>
                            Empfänger-
                            <br />
                            filter
                        </th>
                        <th>Aktionen</th>
                    </tr>
                </thead>
                <tbody>{newsletterQuery.data?.map((newsletter, index) => renderNewsletter(newsletter, index))}</tbody>
            </Table>
        </Section>
    );
};

export const NewsletterListScreen = R.compose(
    withMenu(<MenuClientAdmin />),
    withBorder(),
    withLogin(),
    withFooter(),
)(NewsletterList);
