import { all, select, takeLatest } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { UserProfile, UserProfileContactData } from '../../../services/account';
import { fetchWrapperFunc, fetchWrapperFuncNoLoading } from '../../../utils';
import { loadMyUserInfo } from '../../System/actions';
import { userIdSelector } from '../../System/selectors';
import { getDeviceApi, getProfile2Api, getSystemConfig } from '../../System/systemConfig';
import {
    USER_PROFILE_ASSIGN_IMAGE,
    USER_PROFILE_DELETE_DEVICE,
    USER_PROFILE_DELETE_IMAGE,
    USER_PROFILE_LOAD,
    USER_PROFILE_LOAD_DEVICES,
    USER_PROFILE_SAVE,
    USER_PROFILE_SAVE_CONTACT_DATA,
    userProfileLoadDevices,
    userProfileSet,
    userProfileSetAvailableCountries,
    userProfileSetContactData,
    userProfileSetDevices,
} from './actions';

function* userProfileLoadGenerator() {
    const api = getProfile2Api();

    yield* fetchWrapperFuncNoLoading(
        () => api.getMyUserProfile({tenant: getSystemConfig().clientName}),
        [(r) => userProfileSet(r)],
    );

    yield* fetchWrapperFuncNoLoading(
        () => api.getUserProfileContactData({tenant: getSystemConfig().clientName}),
        [(r) => userProfileSetContactData(r)],
    );

    yield* fetchWrapperFuncNoLoading(
        () => api.getAvailableCountries({tenant: getSystemConfig().clientName}),
        [(r: string[]) => userProfileSetAvailableCountries(r)],
    );
}

function* userProfileLoadDevicesGenerator() {
    const userId: string | undefined = yield select(userIdSelector);
    if (userId) {
        yield* fetchWrapperFuncNoLoading(
            () => getDeviceApi().getDevicesForUser(userId),
            [(r) => userProfileSetDevices(r)],
        );
    }
}

function* userProfileSaveGenerator(action: Action<UserProfile>) {
    const api = getProfile2Api();
    yield* fetchWrapperFunc(
        () => api.updateUserProfileData({tenant: getSystemConfig().clientName, userProfile: action.payload}),
        [(r: UserProfile) => userProfileSet(r)],
    );
}

function* userProfileSaveContactDataGenerator(action: Action<UserProfileContactData>) {
    const api = getProfile2Api();
    yield* fetchWrapperFunc(
        () => api.updateUserProfileContactData({tenant: getSystemConfig().clientName, userProfileContactData: action.payload}),
        [(r: UserProfileContactData) => userProfileSetContactData(r)],
    );
}

function* userProfileAssignImage(action: Action<string>) {
    const api = getProfile2Api();
    yield* fetchWrapperFunc(() => api.updateImage({imageId: action.payload}), [(r) => loadMyUserInfo()]);
}

function* userProfileDeleteImage() {
    const api = getProfile2Api();
    yield* fetchWrapperFunc(() => api.deleteImage(), [(r) => loadMyUserInfo()]);
}

function* userProfileDeleteDevice(action: Action<string>) {
    const api = getDeviceApi();
    yield* fetchWrapperFunc(
        () => api.deleteDeviceToken(getSystemConfig().clientName, action.payload),
        [(r) => userProfileLoadDevices()],
    );
}

function* watchForUserProfileActions() {
    yield takeLatest(USER_PROFILE_LOAD, userProfileLoadGenerator);
    yield takeLatest(USER_PROFILE_SAVE_CONTACT_DATA, userProfileSaveContactDataGenerator);
    yield takeLatest(USER_PROFILE_SAVE, userProfileSaveGenerator);
    yield takeLatest(USER_PROFILE_ASSIGN_IMAGE, userProfileAssignImage);
    yield takeLatest(USER_PROFILE_DELETE_IMAGE, userProfileDeleteImage);
    yield takeLatest(USER_PROFILE_LOAD_DEVICES, userProfileLoadDevicesGenerator);
    yield takeLatest(USER_PROFILE_DELETE_DEVICE, userProfileDeleteDevice);
}

export function* userProfileSagas() {
    yield all([watchForUserProfileActions()]);
}
