import actionCreatorFactory from 'typescript-fsa';
import { Highscore, TournamentRound } from '../../../services/betting';
const actionCreator = actionCreatorFactory();

export const PORTAL_LOAD_TOP_HIGHSCORES = 'PORTAL_LOAD_TOP_HIGHSCORES';
export const portalLoadTopHighscores = actionCreator(PORTAL_LOAD_TOP_HIGHSCORES);

export const PORTAL_SET_ROOT_HIGHSCORES = 'PORTAL_SET_ROOT_HIGHSCORES';
export const portalSetRootHighscores = actionCreator<Highscore[]>(PORTAL_SET_ROOT_HIGHSCORES);

export const PORTAL_SET_ROOT_ROUND = 'PORTAL_SET_ROOT_ROUND';
export const portalSetRootRound = actionCreator<TournamentRound>(PORTAL_SET_ROOT_ROUND);

export const PORTAL_SET_LAST_ROUND = 'PORTAL_SET_LAST_ROUND';
export const portalSetLastRound = actionCreator<TournamentRound>(PORTAL_SET_LAST_ROUND);

export const PORTAL_SET_LAST_HIGHSCORES = 'PORTAL_SET_LAST_HIGHSCORES';
export const portalSetLastHighscores = actionCreator<Highscore[]>(PORTAL_SET_LAST_HIGHSCORES);

export const PORTAL_SET_ROOT_USER_HIGHSCORES = 'PORTAL_SET_ROOT_USER_HIGHSCORES';
export const portalSetRootUserHighscores = actionCreator<Highscore>(PORTAL_SET_ROOT_USER_HIGHSCORES);

export const PORTAL_SET_LAST_USER_HIGHSCORES = 'PORTAL_SET_LAST_USER_HIGHSCORES';
export const portalSetLastUserHighscores = actionCreator<Highscore>(PORTAL_SET_LAST_USER_HIGHSCORES);
