import actionCreatorFactory from 'typescript-fsa';

const actionCreator = actionCreatorFactory();

export type UserFeedback = {
    sender: string;
    subject: string;
    message: string;
}

export const USER_FEEDBACK_SEND = 'USER_FEEDBACK_SEND';
export const userFeedbackSend = actionCreator<UserFeedback>(USER_FEEDBACK_SEND);