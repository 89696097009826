/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BlogEntry } from './BlogEntry';
import {
    BlogEntryFromJSON,
    BlogEntryFromJSONTyped,
    BlogEntryToJSON,
} from './BlogEntry';

/**
 * 
 * @export
 * @interface Blog
 */
export interface Blog {
    /**
     * 
     * @type {string}
     * @memberof Blog
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Blog
     */
    title: string;
    /**
     * 
     * @type {Date}
     * @memberof Blog
     */
    latestEntry?: Date;
    /**
     * 
     * @type {Array<BlogEntry>}
     * @memberof Blog
     */
    entries?: Array<BlogEntry>;
}

/**
 * Check if a given object implements the Blog interface.
 */
export function instanceOfBlog(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;

    return isInstance;
}

export function BlogFromJSON(json: any): Blog {
    return BlogFromJSONTyped(json, false);
}

export function BlogFromJSONTyped(json: any, ignoreDiscriminator: boolean): Blog {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'latestEntry': !exists(json, 'latestEntry') ? undefined : (new Date(json['latestEntry'])),
        'entries': !exists(json, 'entries') ? undefined : ((json['entries'] as Array<any>).map(BlogEntryFromJSON)),
    };
}

export function BlogToJSON(value?: Blog | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'latestEntry': value.latestEntry === undefined ? undefined : (value.latestEntry.toISOString()),
        'entries': value.entries === undefined ? undefined : ((value.entries as Array<any>).map(BlogEntryToJSON)),
    };
}

