import * as React from 'react';

interface Props {
    songAudioUrl: string;
}

export class SongAudioPlayer extends React.Component<Props> {
    public render() {
        if (this.props.songAudioUrl) {
            return (
                <div className="playerdownload">
                    <div className="player audio playlist" data-media-ref={this.props.songAudioUrl} />
                </div>
            );
        } else {
            return null;
        }
    }
}
