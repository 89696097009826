import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { push } from '@lagunovsky/redux-react-router';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import {
    myProfileBettingPath,
    myProfileNewsletterPath,
    myProfilePath,
    myProfilePlaylistPath,
    myProfileSettingsPath,
    myUserMailPath
} from '../../../screens/UserProfileScreen';
import { notificationStatusEntitySelector } from '../../BettingApp/BettingProfile/NotificationStatus/selectors';
import { logout } from '../../System/actions';
import { isClientAdminSelector, userIdSelector } from '../../System/selectors';
import { isRadioEins } from '../../System/systemConfig';
import { userViewProfilePath } from '../../UserView';

export type ProfileSubMenuType = 'profile' | 'betting' | 'messages' | 'playlist' | 'newsletter' | 'settings';

interface Props {
    subMenu: ProfileSubMenuType;
}
export const ProfileSubMenu: FC<Props> = props => {
    const dispatch = useDispatch();

    const isClientAdmin = useSelector(isClientAdminSelector);
    const userId = useSelector(userIdSelector);
    const notificationStatus = useSelector(notificationStatusEntitySelector);

    return (
        <>
            <Button
                className="me-2 my-1"
                color={props.subMenu === 'profile' ? 'warning' : 'secondary'}
                onClick={() => dispatch(push(myProfilePath()))}
            >
                Profil
            </Button>
            <Button
                className="me-2 my-1"
                color={props.subMenu === 'betting' ? 'warning' : 'secondary'}
                onClick={() => dispatch(push(myProfileBettingPath()))}
            >
                Tippspiel
            </Button>
            {(isClientAdmin || notificationStatus?.messageCount > 0) && (
                <Button
                    className="me-2 my-1"
                    color={props.subMenu === 'messages' ? 'warning' : 'secondary'}
                    onClick={() => dispatch(push(myUserMailPath()))}
                >
                    Nachrichten
                </Button>
            )}
            {isRadioEins() && (
                <Button
                    className="me-2 my-1"
                    color={props.subMenu === 'playlist' ? 'warning' : 'secondary'}
                    onClick={() => dispatch(push(myProfilePlaylistPath()))}
                >
                    Playlists
                </Button>
            )}
            {isRadioEins() && (
                <Button
                    className="me-2 my-1"
                    color={props.subMenu === 'newsletter' ? 'warning' : 'secondary'}
                    onClick={() => dispatch(push(myProfileNewsletterPath()))}
                >
                    Newsletter
                </Button>
            )}
            <Button
                className="me-2 my-1"
                color={props.subMenu === 'settings' ? 'warning' : 'secondary'}
                onClick={() => dispatch(push(myProfileSettingsPath()))}
            >
                Einstellungen
            </Button>
            {userId && (
                <Button
                    className="me-2 my-1"
                    color="success"
                    onClick={() => dispatch(push(userViewProfilePath(userId)))}
                >
                    Besucheransicht
                </Button>
            )}
            <Button className="me-2 my-1" color="danger" onClick={() => dispatch(logout())}>
                <FontAwesomeIcon icon={faSignOutAlt} />
            </Button>
        </>
    );
};
