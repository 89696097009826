import * as React from 'react';
import { useSelector } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { systemIsFetchingSelector } from '../../System/selectors';
import { LoadingIndicatorSpinner } from '../LoadingIndicatorSpinner';

export const LoadingIndicator: React.FC<{}> = () => {
    const isFetching = useSelector(systemIsFetchingSelector);
    return (
        <Modal isOpen={isFetching > 0} centered={true} keyboard={false} autoFocus={false}>
            <ModalBody>
                <p>Lade Daten ...</p>
                <LoadingIndicatorSpinner />
            </ModalBody>
        </Modal>
    );
};
