import { push } from '@lagunovsky/redux-react-router';
import * as R from 'ramda';
import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Col, Container, Row } from 'reactstrap';
import { userGroupAdminPath } from '../../../links/UserGroupAdminLink';
import { withBorder } from '../../../reactutils/withBorder';
import { withNavLocation } from '../../../reactutils/withNavLocation';
import { withTitle } from '../../../reactutils/withTitle';
import { usePathSegment } from '../../SysAdminApp/CacheStatisticsComponent';
import { hsdLoad } from './actions';
import { HighscoreDescriptorValue } from './HighscoreDescriptorValue';
import { hsdEntitySelector, hsdUgNameSelector } from './selectors';

const HighscoreStrategyDescriptorViewComponent: React.FC<{}> = (props) => {
    const hsd = useSelector(hsdEntitySelector);
    const dispatch = useDispatch();

    const [ugId] = usePathSegment(3);

    React.useEffect(() => {
        if (ugId) {
            dispatch(hsdLoad(ugId));
        }
    }, [dispatch, ugId]);

    if (!hsd) {
        return <Container>...loading</Container>;
    } else {
        return (
            <>
                <div className="container-fluid">
                    <Row className="mt-4">
                        <Col xs={12}>
                            <Alert color="success">
                                <h5 className="alert-heading">
                                    {hsd.standardStrategy ? 'Standardmodus' : 'Expertenmodus'}: "{hsd.name}"
                                </h5>
                                <ReactMarkdown>{hsd.description}</ReactMarkdown>
                            </Alert>
                        </Col>
                    </Row>

                    <Row className="mt-2">
                        <Col xs={10}>
                            Wird bei der Punktevergabe nach 'Heimsieg', 'Unentschieden' und 'Auswärtssieg'
                            unterscheiden?
                        </Col>
                        <Col xs={2} className="font-weight-bold">
                            {hsd.distinctByHomeAway ? 'Ja' : 'Nein'}
                        </Col>
                    </Row>

                    <Row className="mt-2">
                        <Col xs={10}>Wird bei der Punktevergabe die Quote der abgegebenen Tipps berücksichtigt?</Col>
                        <Col xs={2} className="font-weight-bold">
                            {hsd.distinctByQuotes ? 'Ja' : 'Nein'}
                        </Col>
                    </Row>

                    <hr />

                    <Row className="mt-3 p-2 bg-secondary text-light">
                        <Col>&nbsp;</Col>
                        <Col>Punkte für korrekte Tipptendenz</Col>
                        <Col>Punkte für korrekte Tordifferenz</Col>
                        <Col>Punkte für exakt richtiges Ergebnis</Col>
                    </Row>
                    {hsd && !hsd.distinctByHomeAway && (
                        <Row className="p-2 bg-light">
                            <Col>&nbsp;</Col>
                            <Col>
                                <HighscoreDescriptorValue
                                    minValue={hsd.homeWinTendencyMin}
                                    maxValue={hsd.homeWinTendencyMax}
                                    distinctByQuotes={hsd.distinctByQuotes}
                                />
                            </Col>
                            <Col>
                                <HighscoreDescriptorValue
                                    minValue={hsd.homeWinDifferenceMin}
                                    maxValue={hsd.homeWinDifferenceMax}
                                    distinctByQuotes={hsd.distinctByQuotes}
                                />
                            </Col>
                            <Col>
                                <HighscoreDescriptorValue
                                    minValue={hsd.homeWinResultMin}
                                    maxValue={hsd.homeWinResultMax}
                                    distinctByQuotes={hsd.distinctByQuotes}
                                />
                            </Col>
                        </Row>
                    )}

                    {hsd && hsd.distinctByHomeAway && (
                        <>
                            <Row className="p-2 bg-light">
                                <Col>Heimsieg</Col>
                                <Col>
                                    <HighscoreDescriptorValue
                                        minValue={hsd.homeWinTendencyMin}
                                        maxValue={hsd.homeWinTendencyMax}
                                        distinctByQuotes={hsd.distinctByQuotes}
                                    />
                                </Col>
                                <Col>
                                    <HighscoreDescriptorValue
                                        minValue={hsd.homeWinDifferenceMin}
                                        maxValue={hsd.homeWinDifferenceMax}
                                        distinctByQuotes={hsd.distinctByQuotes}
                                    />
                                </Col>
                                <Col>
                                    <HighscoreDescriptorValue
                                        minValue={hsd.homeWinResultMin}
                                        maxValue={hsd.homeWinResultMax}
                                        distinctByQuotes={hsd.distinctByQuotes}
                                    />
                                </Col>
                            </Row>

                            <Row className="p-2 bg-light">
                                <Col>Unentschieden</Col>
                                <Col>
                                    <HighscoreDescriptorValue
                                        minValue={hsd.drawTendencyMin}
                                        maxValue={hsd.drawTendencyMax}
                                        distinctByQuotes={hsd.distinctByQuotes}
                                    />
                                </Col>
                                <Col>&nbsp;</Col>
                                <Col>
                                    <HighscoreDescriptorValue
                                        minValue={hsd.drawResultMin}
                                        maxValue={hsd.drawResultMax}
                                        distinctByQuotes={hsd.distinctByQuotes}
                                    />
                                </Col>
                            </Row>

                            <Row className="p-2 bg-light">
                                <Col>Heimsieg</Col>
                                <Col>
                                    <HighscoreDescriptorValue
                                        minValue={hsd.awayWinTendencyMin}
                                        maxValue={hsd.awayWinTendencyMax}
                                        distinctByQuotes={hsd.distinctByQuotes}
                                    />
                                </Col>
                                <Col>
                                    <HighscoreDescriptorValue
                                        minValue={hsd.awayWinDifferenceMin}
                                        maxValue={hsd.awayWinDifferenceMax}
                                        distinctByQuotes={hsd.distinctByQuotes}
                                    />
                                </Col>
                                <Col>
                                    <HighscoreDescriptorValue
                                        minValue={hsd.awayWinResultMin}
                                        maxValue={hsd.awayWinResultMax}
                                        distinctByQuotes={hsd.distinctByQuotes}
                                    />
                                </Col>
                            </Row>
                        </>
                    )}
                </div>
                {ugId && (
                    <Button color="link" onClick={() => dispatch(push(userGroupAdminPath(ugId)))}>
                        Zurück zum Team
                    </Button>
                )}
            </>
        );
    }
};

export const HighscoreStrategyDescriptorScreen = R.compose(
    withBorder(),
    withTitle('Punktemodus von Tippteam', hsdUgNameSelector),
    withNavLocation('profile')
)(HighscoreStrategyDescriptorViewComponent);
