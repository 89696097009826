import { push } from '@lagunovsky/redux-react-router';
import { all, select, takeLatest } from 'redux-saga/effects';
import { rankingPath } from '.';
import { TournamentRound } from '../../services/betting';
import { fetchWrapperFuncNoLoading } from '../../utils';
import { getSystemConfig, getTournamentApi2 } from '../System/systemConfig';
import { tournamentSelectionTournamentIdSelector } from '../TournamentSelectionComponent/selectors';
import { RANKING_LOAD_WITHOUT_PROPS } from './actions';

function* loadWithoutProps() {
    let tvarId: number | undefined = yield select(tournamentSelectionTournamentIdSelector);

    const api = getTournamentApi2();

    try {
        if (tvarId == null) {
            const defaultTournament = yield* fetchWrapperFuncNoLoading(() =>
                api.getDefaultTournament({clientId: getSystemConfig().clientId})
            );
            if (defaultTournament != null) {
                tvarId = defaultTournament.id;
            }
        }
        const tId = tvarId;
        if (tId != null) {
            yield* fetchWrapperFuncNoLoading(
                () => api.getDefaultTournamentRoundByType({tournamentId: tId, type: 'ranking'}),
                [
                    (tr: TournamentRound) => {
                        const path = rankingPath(String(tr.id));
                        return push(path);
                    },
                ]
            );
        }
    } finally {
    }
}

function* watchForRankingActions() {
    yield takeLatest(RANKING_LOAD_WITHOUT_PROPS, loadWithoutProps);
}

export function* rankingSagas() {
    yield all([watchForRankingActions()]);
}
