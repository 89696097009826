import React, { useEffect } from 'react';
import { userIdSelector } from '../selectors';
import { useSelector } from 'react-redux';

type PushSubscriptionState = 'granted' | 'denied' | 'noServiceWorker' | 'unknown';

export const PushNotificationAskForPermission: React.FC = () => {

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [pushSubscriptionState, setPushSubscriptionState] = React.useState<PushSubscriptionState>('unknown');

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const loggedInUserId = useSelector(userIdSelector);

    useEffect(() => {
        if ('Notification' in window && 'serviceWorker' in navigator) {
            if (Notification.permission === 'granted') {
                console.log('Notification permission granted');
                setPushSubscriptionState('granted');
                return;
            } else {
                setPushSubscriptionState('denied');
                console.log('Notification permission not granted: ' + Notification.permission);
            }
        } else {
            setPushSubscriptionState('noServiceWorker');
        }
    }, []);

    // FIXME workin in progess ... finish this component
    return (null);
}