import { faMedal, faTrophy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';
import * as R from 'ramda';
import * as React from 'react';
import Moment from 'react-moment';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Container, Row, Table } from 'reactstrap';
import { RoundHighscoreLink } from '../../../links/RoundHighscoreLink';
import { withBorder } from '../../../reactutils/withBorder';
import { withNavLocation } from '../../../reactutils/withNavLocation';
import { withTitle } from '../../../reactutils/withTitle';
import { TournamentRound } from '../../../services/betting';
import { usePathSegment } from '../../SysAdminApp/CacheStatisticsComponent';
import { UserAdminButton } from '../../SysAdminApp/UserAdmin';
import { getBettingUserApi2, getTournamentApi2 } from '../../System/systemConfig';
import { UserImage } from '../../UserImage';
import { LoadingIndicatorSpinner } from '../../common/LoadingIndicatorSpinner';
import { Section, SectionHeading } from '../../common/Section';
import { UserViewMenu } from '../UserViewMenu';
import { userViewLoadProfile } from '../actions';
import {
    userViewAccountUserProfileSelector,
    userViewBettingUserSelector,
    userViewUserNameSelector,
} from '../selectors';
import { isSysAdminSelector } from '../../System/selectors';

const UserViewProfile: React.FC<{}> = (props) => {
    const accountUserProfile = useSelector(userViewAccountUserProfileSelector);
    const bettingUser = useSelector(userViewBettingUserSelector);

    const isSysAdmin = useSelector(isSysAdminSelector);

    const [userId] = usePathSegment(4);
    const dispatch = useDispatch();

    const [showWonTournamentRounds, setShowWonTournamentRounds] = React.useState(false);

    React.useEffect(() => {
        if (userId) {
            dispatch(userViewLoadProfile(userId));
        }
    }, [dispatch, userId]);

    if (!accountUserProfile) {
        return (
            <Container>
                <LoadingIndicatorSpinner />
            </Container>
        );
    } else {
        return (
            <div className="mt-3">
                <UserViewMenu activeSubMenu="profile" />
                <Row>
                    <Col>
                        <UserImage imageId={accountUserProfile.imageId} width={380} height={380} />
                    </Col>
                    <Col>
                        <Table className="mt-2">
                            <tbody>
                                {accountUserProfile.r1Moderator && accountUserProfile.note && (
                                    <tr>
                                        <td colSpan={2}>
                                            <span dangerouslySetInnerHTML={{ __html: accountUserProfile.note }} />
                                        </td>
                                    </tr>
                                )}
                                {accountUserProfile.regDate && (
                                    <tr>
                                        <td>
                                            registriert:
                                            <UserAdminButton userId={accountUserProfile.id} />
                                        </td>
                                        <td>
                                            <Moment date={accountUserProfile.regDate} locale="de" fromNow={true} />
                                        </td>
                                    </tr>
                                )}
                                {isSysAdmin && accountUserProfile.lastLogin && (
                                    <tr>
                                        <td>letzte Anmeldung:</td>
                                        <td>
                                            <Moment date={accountUserProfile.lastLogin} locale="de" fromNow={true} />
                                        </td>
                                    </tr>
                                )}
                                {bettingUser && bettingUser.wonTournaments > 0 && (
                                    <tr>
                                        <td>Turniersieger</td>
                                        <td>
                                            {bettingUser.wonTournaments}&nbsp;x&nbsp;
                                            <FontAwesomeIcon icon={faTrophy} color="gold" size="2x" />
                                        </td>
                                    </tr>
                                )}
                                {bettingUser && bettingUser.wonTournamentRounds > 0 && (
                                    <tr>
                                        <td>Spieltagssieger</td>
                                        <td>
                                            {bettingUser.wonTournamentRounds}&nbsp;x&nbsp;
                                            <FontAwesomeIcon icon={faMedal} color="silver" size="2x" />
                                        </td>
                                    </tr>
                                )}
                                {bettingUser && (bettingUser.wonTournamentRounds || bettingUser.wonTournaments) && (
                                    <tr>
                                        <td>
                                        {!showWonTournamentRounds && (
                                            <Button color="link" onClick={() => setShowWonTournamentRounds(true)}>
                                                Siege anzeigen
                                            </Button>
                                        )}
                                        {showWonTournamentRounds && (
                                            <UserViewWonTournamentRounds userId={accountUserProfile.id} />
                                        )}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                {accountUserProfile.r1Moderator && (
                    <>
                        {accountUserProfile.character && (
                            <Section>
                                <SectionHeading>Zur Person</SectionHeading>
                                {/*                             
                            <ReactMarkdown source={accountUserProfile.character}/>
                             */}
                                <span dangerouslySetInnerHTML={{ __html: accountUserProfile.characterHtml }} />
                            </Section>
                        )}
                        {accountUserProfile.favoriteBook && (
                            <Section>
                                <SectionHeading>Lieblingsbücher</SectionHeading>
                                <span dangerouslySetInnerHTML={{ __html: accountUserProfile.favoriteBookHtml }} />
                            </Section>
                        )}
                        {accountUserProfile.favoriteMovie && (
                            <Section>
                                <SectionHeading>Lieblings-Filme</SectionHeading>
                                <span dangerouslySetInnerHTML={{ __html: accountUserProfile.favoriteMovieHtml }} />
                            </Section>
                        )}
                    </>
                )}
            </div>
        );
    }
};

interface UserViewWonTournamentProps {
    userId: string;
}

export const TournamentName: React.FC<{ tId: string }> = (props) => {
    const api = getTournamentApi2();
    const { data: tournament } = useQuery({
        queryKey: ['getTournament', props.tId],
        queryFn: () => api.getTournamentById({tId: Number(props.tId)}),
    });
    return <span>{tournament?.name}</span>;
};

const UserViewWonTournamentRounds: React.FC<UserViewWonTournamentProps> = (props) => {
    const api = getBettingUserApi2();

    const { data: rounds } = useQuery({
        queryKey: ['getWonTournamentRounds', props.userId],
        queryFn: () => api.getWonTournamentRounds({userId: Number(props.userId)}),
    });

    const showTournamentRound = (tr: TournamentRound) => {
        return (
            <tr key={tr.id}>
                <td>
                    {tr.parentId ? (
                        <FontAwesomeIcon icon={faMedal} color="silver" />
                    ) : (
                        <FontAwesomeIcon icon={faMedal} color="gold" />
                    )}
                </td>
                <td className="text-nowrap">
                    <TournamentName tId={String(tr.tournamentId)} />
                </td>
                <td className="text-nowrap">
                    <RoundHighscoreLink trId={String(tr.id)} userId={props.userId}>
                        {tr.parentId ? tr.name : 'Gesamtsieger'}
                    </RoundHighscoreLink>
                </td>
            </tr>
        );
    };

    if (rounds) {
        return (
            <small>
                <Table>
                    <tbody>{rounds.map((r) => showTournamentRound(r))}</tbody>
                </Table>
            </small>
        );
    } else {
        return null;
    }
};

export const UserViewProfileScreen = R.compose(
    withBorder(),
    withTitle('Hörerprofil', userViewUserNameSelector),
    withNavLocation('profile'),
)(UserViewProfile);
