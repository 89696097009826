/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.25
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  NewsletterRegistrationResult,
  RegistrationResultRestModel,
} from '../models/index';
import {
    NewsletterRegistrationResultFromJSON,
    NewsletterRegistrationResultToJSON,
    RegistrationResultRestModelFromJSON,
    RegistrationResultRestModelToJSON,
} from '../models/index';

export interface NewsletterRegistrationRequest {
    tenant: string;
    email?: string;
}

export interface RegisterUserRequest {
    tenant: string;
    email?: string;
    infoMail?: boolean;
    login?: string;
    password?: string;
    privacyStatementAccepted?: boolean;
}

/**
 * 
 */
export class Registration2ControllerApi extends runtime.BaseAPI {

    /**
     */
    async newsletterRegistrationRaw(requestParameters: NewsletterRegistrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NewsletterRegistrationResult>> {
        if (requestParameters['tenant'] == null) {
            throw new runtime.RequiredError(
                'tenant',
                'Required parameter "tenant" was null or undefined when calling newsletterRegistration().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/registration/newsletter/{tenant}`.replace(`{${"tenant"}}`, encodeURIComponent(String(requestParameters['tenant']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NewsletterRegistrationResultFromJSON(jsonValue));
    }

    /**
     */
    async newsletterRegistration(requestParameters: NewsletterRegistrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NewsletterRegistrationResult> {
        const response = await this.newsletterRegistrationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async registerUserRaw(requestParameters: RegisterUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RegistrationResultRestModel>> {
        if (requestParameters['tenant'] == null) {
            throw new runtime.RequiredError(
                'tenant',
                'Required parameter "tenant" was null or undefined when calling registerUser().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        if (requestParameters['infoMail'] != null) {
            queryParameters['infoMail'] = requestParameters['infoMail'];
        }

        if (requestParameters['login'] != null) {
            queryParameters['login'] = requestParameters['login'];
        }

        if (requestParameters['password'] != null) {
            queryParameters['password'] = requestParameters['password'];
        }

        if (requestParameters['privacyStatementAccepted'] != null) {
            queryParameters['privacyStatementAccepted'] = requestParameters['privacyStatementAccepted'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/registration/{tenant}`.replace(`{${"tenant"}}`, encodeURIComponent(String(requestParameters['tenant']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RegistrationResultRestModelFromJSON(jsonValue));
    }

    /**
     */
    async registerUser(requestParameters: RegisterUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RegistrationResultRestModel> {
        const response = await this.registerUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
