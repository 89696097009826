/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.34
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClientTournament
 */
export interface ClientTournament {
    /**
     * 
     * @type {number}
     * @memberof ClientTournament
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ClientTournament
     */
    clientId: number;
    /**
     * 
     * @type {number}
     * @memberof ClientTournament
     */
    tournamentId: number;
    /**
     * 
     * @type {number}
     * @memberof ClientTournament
     */
    order: number;
    /**
     * 
     * @type {number}
     * @memberof ClientTournament
     */
    state: number;
    /**
     * 
     * @type {string}
     * @memberof ClientTournament
     */
    clientName: string;
    /**
     * 
     * @type {string}
     * @memberof ClientTournament
     */
    tournamentName: string;
    /**
     * 
     * @type {number}
     * @memberof ClientTournament
     */
    highscoreStrategyDescriptorId?: number;
}

/**
 * Check if a given object implements the ClientTournament interface.
 */
export function instanceOfClientTournament(value: object): value is ClientTournament {
    if (!('clientId' in value) || value['clientId'] === undefined) return false;
    if (!('tournamentId' in value) || value['tournamentId'] === undefined) return false;
    if (!('order' in value) || value['order'] === undefined) return false;
    if (!('state' in value) || value['state'] === undefined) return false;
    if (!('clientName' in value) || value['clientName'] === undefined) return false;
    if (!('tournamentName' in value) || value['tournamentName'] === undefined) return false;
    return true;
}

export function ClientTournamentFromJSON(json: any): ClientTournament {
    return ClientTournamentFromJSONTyped(json, false);
}

export function ClientTournamentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientTournament {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'clientId': json['clientId'],
        'tournamentId': json['tournamentId'],
        'order': json['order'],
        'state': json['state'],
        'clientName': json['clientName'],
        'tournamentName': json['tournamentName'],
        'highscoreStrategyDescriptorId': json['highscoreStrategyDescriptorId'] == null ? undefined : json['highscoreStrategyDescriptorId'],
    };
}

export function ClientTournamentToJSON(value?: ClientTournament | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'clientId': value['clientId'],
        'tournamentId': value['tournamentId'],
        'order': value['order'],
        'state': value['state'],
        'clientName': value['clientName'],
        'tournamentName': value['tournamentName'],
        'highscoreStrategyDescriptorId': value['highscoreStrategyDescriptorId'],
    };
}

