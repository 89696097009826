import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQuery } from '@tanstack/react-query';
import * as R from 'ramda';
import { FC, useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { withMenu } from '../../../reactutils/withMenu';
import { withTitle } from '../../../reactutils/withTitle';
import { EventHandlerConfiguration } from '../../../services/account';
import { MenuSysAdmin } from '../../MenuSysAdmin';
import { ServerType, getEventHandlerConfigApi } from '../../System/systemConfig';
import { Section, SectionHeading } from '../../common/Section';
import { SysAdminSystemSubMenu } from '../SysAdminSubMenu';
import { withBorder } from '../../../reactutils/withBorder';

interface Props {
    server: ServerType;
}

export const createGraphViz = (e: EventHandlerConfiguration[]): string => {
    let graph = 'strict digraph EventHandler {';
    if (e) {
        e.forEach((n) => {
            if (n.eventName) {
                graph += n.eventName + ' [shape=box] ;\n';
            }
            if (n.listenerName) {
                graph += n.listenerName + '[shape=component];\n';
            }
            if (n.destinations) {
                n.destinations.forEach((d) => {
                    graph += d + ' [shape=ellipse] ;\n';
                    graph += n.eventName + ' -> ' + d + ';\n';
                });
            }
            if (n.receivingQueue) {
                graph += n.receivingQueue + ' -> ' + n.listenerName + ';\n';
            } else {
                graph += n.eventName + ' -> ' + n.listenerName + ' [color=red];\n';
            }
        });
    }
    graph += '}';
    return graph;
};

export const renderEventHandlerConfiguration = (e: EventHandlerConfiguration, index: number) => {
    return (
        <tr key={index} className="text-break">
            <td className="text-break">{e.eventName}</td>
            <td className="d-none d-lg-table-cell">
                {(e.destinations &&
                    e.destinations.map((d) => (
                        <span key={d}>
                            {d}
                            <br />
                        </span>
                    ))) || <FontAwesomeIcon icon={faExclamationTriangle} color="red" />}
            </td>
            <td className="d-none d-lg-table-cell">
                {e.listenerName || <FontAwesomeIcon icon={faExclamationTriangle} color="red" />}
            </td>
            <td className="d-none d-lg-table-cell">
                {e.receivingQueue || <FontAwesomeIcon icon={faExclamationTriangle} color="red" />}
            </td>
        </tr>
    );
};

const EventHandlerConfig: FC<Props> = (props) => {
    const [entities, setEntities] = useState<EventHandlerConfiguration[]>([]);

    const api = getEventHandlerConfigApi(props.server);

    const eventHandlerQuery = useQuery({
        queryKey: ['getEventHandlers', props.server],
        queryFn: () => api.getEventHandlerConfiguration(),
        retry: false,
    });

    useEffect(() => {
        setEntities(eventHandlerQuery.data || []);
    }, [eventHandlerQuery.data]);

    return (
        <>
            <Section>
                <SectionHeading>{props.server}</SectionHeading>
                <small>
                    <Table>
                        <thead>
                            <tr>
                                <th>Event</th>
                                <th className="d-none d-lg-table-cell">Destinations</th>
                                <th className="d-none d-lg-table-cell">Listener</th>
                                <th className="d-none d-lg-table-cell">Receiving Queue</th>
                            </tr>
                        </thead>
                        <tbody>
                            {entities && entities.map((e, index) => renderEventHandlerConfiguration(e, index))}
                        </tbody>
                    </Table>
                </small>
            </Section>
        </>
    );
};

const EventHandlerConfigComp: FC<{}> = () => {
    return (
        <>
            <EventHandlerConfig server="account" />
            <EventHandlerConfig server="betting" />
            <EventHandlerConfig server="image" />
            <EventHandlerConfig server="playlist" />
        </>
    );
};

export const EventHandlerConfigScreen = R.compose(
    withMenu(<MenuSysAdmin />),
    withBorder(),
    withMenu(<SysAdminSystemSubMenu />),
    withTitle('Event-Handler'),
)(EventHandlerConfigComp);
