import React, { FC, PropsWithChildren } from 'react';
import { getSystemConfig } from '../../System/systemConfig';

interface Props extends PropsWithChildren {
    tenantId?: string;
    tenantName?: string;
}
export const ShowWhenTenant: FC<Props> = props => {
    const systemConfig = getSystemConfig();
    if (String(systemConfig.clientId) === props.tenantId) {
        return <> {props.children} </>;
    } else if (props.tenantName && (systemConfig.clientName === props.tenantName)) {
        return <> {props.children} </>;
    } else return null;
};
