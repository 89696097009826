import React from 'react';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ShowWhenFuntip } from '../common/ShowWhenFuntip';
import { ShowWhenRadioEins } from '../common/ShowWhenRadioEins';
import { systemIsLoginRequiredSelector } from '../System/selectors';
import { LoginSubForm } from './LoginSubForm';
import { PasswordForgottenSubForm } from './PasswordForgottenSubForm';
import { RegistrationSubForm } from './RegistrationSubForm';

type LoginSubform = 'login' | 'registration' | 'lostPassword' | 'resendActivation';

export const waitCursor = async (on: boolean) => {
    if (on) {
        document.body.style.cursor = 'progress';
    } else {
        document.body.style.cursor = 'auto';
    }
    await new Promise(r => setTimeout(r, 2000));
};

export const LoginForm: FC<{}> = () => {
    const [subForm, setSubForm] = useState<LoginSubform>('login');

    const loginRequired = useSelector(systemIsLoginRequiredSelector);

    const changeSubForm = (next: LoginSubform) => {
        setSubForm(next);
    };
    return (
        <Modal isOpen={loginRequired}>
            <ModalHeader>
                <ShowWhenRadioEins>
                    <img src="https://static2.funtip.de/static-img/r1/radioeins_logo_2021.png" alt="logo" className="img-fluid" />
                </ShowWhenRadioEins>
                <ShowWhenFuntip>
                    <img src="https://static2.funtip.de/static-img/ft/logo.png" style={{maxWidth: "75px"}} alt="logo" className="img-fluid" />
                </ShowWhenFuntip>
            </ModalHeader>
            <ModalBody>
                {subForm === 'login' && <LoginSubForm changeSubForm={changeSubForm} />}
                {subForm === 'registration' && <RegistrationSubForm changeSubForm={changeSubForm} />}
                {subForm === 'lostPassword' && <PasswordForgottenSubForm changeSubForm={changeSubForm} />}
            </ModalBody>
            <ModalFooter>
                {subForm !== 'lostPassword' && (
                    <Button color="link" style={{ fontSize: '14px' }} onClick={() => changeSubForm('lostPassword')}>
                        Kennwort vergessen?
                    </Button>
                )}
                {subForm === 'lostPassword' && (
                    <>
                        <Button color="link" style={{ fontSize: '14px' }} onClick={() => changeSubForm('login')}>
                            Anmelden
                        </Button>
                        <Button color="link" style={{ fontSize: '14px' }} onClick={() => changeSubForm('registration')}>
                            Registrieren
                        </Button>
                    </>
                )}
            </ModalFooter>
        </Modal>
    );
};

export interface SubFormProps {
    changeSubForm: (next: LoginSubform) => void;
}
