import { faChartSimple, faFutbol } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { delay } from 'redux-saga/effects';
import { LiveTicker } from '../../../BettingApp/LiveTicker';
import { MatchStatistic } from '../../../BettingApp/MatchStatistic';
import { LoadingIndicatorSpinner } from '../../../common/LoadingIndicatorSpinner';
import { bettingLoadGameInfo } from '../../actions';
import { bettingGameBetInfoSelector } from '../../selectors';

interface Props {
    gameId: string;
    bet1?: number;
    bet2?: number;
    onBetQuoteClick: (gameId: number, bet1: number, bet2: number) => void;
}

export const GameBetInfoComponent: React.FC<Props> = (props) => {
    const [showMatchStats, setShowMatchStats] = React.useState(false);
    const [showLiveTicker, setShowLiveTicker] = React.useState(false);

    const dispatch = useDispatch();

    const gameInfo = useSelector(bettingGameBetInfoSelector(props.gameId));

    React.useEffect(() => {
        if (props.gameId) {
            dispatch(delay(500));
            dispatch(bettingLoadGameInfo(Number(props.gameId)));
        }
    }, [dispatch, props.gameId]);

    const handleBetQuoteClick = () => {
        if (props.bet1 != null && props.bet2 != null) {
            props.onBetQuoteClick(Number(props.gameId), props.bet1, props.bet2);
        }
    };

    const onShowLiveTicker = () => {
        setShowLiveTicker(true);
    };

    if (gameInfo) {
        return (
            <>
                <MatchStatistic
                    team1Id={String(gameInfo.team1Id)}
                    team2Id={String(gameInfo.team2Id)}
                    display={showMatchStats}
                    onClose={() => setShowMatchStats(false)}
                />
                <td className="d-none d-sm-table-cell align-middle">
                    {props.bet1 != null && props.bet2 != null && (
                        <Button color="link" onClick={handleBetQuoteClick} className="m-0 p-0">
                            ?
                        </Button>
                    )}
                </td>
                <td className="d-none d-lg-table-cell align-middle">
                    <span className="d-inline-block pt-1">
                        {gameInfo.betQuoteWin}
                        &nbsp;/&nbsp;
                        {gameInfo.betQuoteDraw}
                        &nbsp;/&nbsp;
                        {gameInfo.betQuoteLose}
                    </span>
                </td>
                <td className="d-none d-md-table-cell align-middle">
                    {gameInfo.matchStatistics && (
                        <Button color="link" onClick={() => setShowMatchStats(true)}>
                            <FontAwesomeIcon icon={faChartSimple} />
                        </Button>
                    )}
                </td>
                <td className="d-none d-sm-table-cell align-middle">
                    {gameInfo.hasEvents && (
                        <>
                            <Button color="link" onClick={onShowLiveTicker}>
                                <FontAwesomeIcon icon={faFutbol} />
                            </Button>
                            <Modal isOpen={showLiveTicker} toggle={() => setShowLiveTicker(false)} size={'lg'}>
                                <ModalBody>
                                    <LiveTicker gameId={props.gameId} />
                                </ModalBody>
                                <ModalFooter>
                                    <Button onClick={() => setShowLiveTicker(false)}>Schließen</Button>
                                </ModalFooter>
                            </Modal>
                        </>
                    )}
                </td>
            </>
        );
    } else {
        return (
            <td>
                <LoadingIndicatorSpinner />
            </td>
        );
    }
};
