import { useMutation, useQuery } from '@tanstack/react-query';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Alert, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { UserProfile } from '../../../../services/account';
import { systemSetPopupMessage } from '../../../System/actions';
import { getProfile2Api, getSystemConfig } from '../../../System/systemConfig';
import { ButtonWithConfirmation } from '../../../common/ButtonWithConfirmation';
import { LoadingIndicatorContent } from '../../../common/LoadingIndicatorSpinner';
import { Section, SectionHeading } from '../../../common/Section';

export const ChangeUserName: FC<{}> = () => {
    const [userNameChangeAllowed, setUserNameChangeAllowed] = useState(false);
    const [newUserName, setNewUserName] = useState('');
    const [newUserNameValid, setNewUserNameValid] = useState(true);
    const [userProfile, setUserProfile] = useState<UserProfile>();

    const dispatch = useDispatch();

    const api = getProfile2Api();

    const onChange = (e: React.FormEvent<HTMLInputElement>) => {
        setNewUserName(e.currentTarget.value);
    };

    const isNameChangeAllowedQuery = useQuery({
        queryKey: ['userNameChangeAllowed'],
        queryFn: () => api.isNameChangeAllowed(),
    });

    useEffect(() => {
        setUserNameChangeAllowed(!!isNameChangeAllowedQuery.data);
    }, [isNameChangeAllowedQuery.data]);

    const userProfileQuery = useQuery({
        queryKey: ['userProfile'],
        queryFn: () => api.getMyUserProfile({tenant: getSystemConfig().clientName}),
    });

    useEffect(() => {
        setUserProfile(userProfileQuery.data);
    }, [userProfileQuery.data]);

    const updateUserNameMutation = useMutation({
        mutationFn: () => api.changeUserName({name: newUserName}),
        onSuccess: () => {
            dispatch(systemSetPopupMessage(`Der Nutzername wurde erfolgreich zu '${newUserName}' geändert.`));
            isNameChangeAllowedQuery.refetch();
            userProfileQuery.refetch();
        },
        onError: () => {
            setNewUserNameValid(false);
        },
    });

    const onUpdateUserName = () => {
        updateUserNameMutation.mutate();
    };

    if (!userNameChangeAllowed) {
        return null;
    }

    const checkUserNameLengthValid = () => {
        return newUserName.length > 3;
    };

    return (
        <>
            <Section>
                <SectionHeading>Nutzernamen ändern ({userProfile?.name})</SectionHeading>
                {!userNameChangeAllowed && (
                    <Alert color="info">
                        Sie können ihren Nutzernamen nicht ändern, da eine Änderung nur einmalig möglich ist. Sie haben
                        ihren Nutzernamen bereits geändert.
                    </Alert>
                )}
                {userNameChangeAllowed && (
                    <Form>
                        <FormGroup>
                            <Label>Neuer Nutzername</Label>
                            <Input type="text" value={newUserName} onChange={onChange} invalid={!newUserNameValid} />
                            <FormFeedback>
                                Der Nutzername ist nicht gültig oder wird bereits von einem anderen Nutzer verwendet.
                            </FormFeedback>
                        </FormGroup>
                        <ButtonWithConfirmation
                            color="success"
                            title="Nutzernamen ändern"
                            onOK={onUpdateUserName}
                            placeholder="neuer Nutzername"
                            disabled={!checkUserNameLengthValid()}
                            message={`Sind Sie sicher, dass Sie Ihren Nutzernamen zu '${newUserName}' ändern möchten? Der Nutzernamen kann nur einmal geändert werden.`}
                        >
                            <LoadingIndicatorContent isLoading={updateUserNameMutation.isPending}>
                                Nutzernamen ändern
                            </LoadingIndicatorContent>
                        </ButtonWithConfirmation>
                    </Form>
                )}
            </Section>
        </>
    );
};
