import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { faCircleCheck, faCircleXmark, faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import { FC, useEffect, useState } from 'react';
import { Button, Col, Form, Input, Label, Row } from 'reactstrap';
import { getGameApi2, getTournamentSysAdminApi } from '../../../../System/systemConfig';
import { LoadingIndicatorContent } from '../../../../common/LoadingIndicatorSpinner';
import { useDispatch } from 'react-redux';
import { systemSetPopupMessage } from '../../../../System/actions';
import { ButtonWithConfirmation } from '../../../../common/ButtonWithConfirmation';

interface GameResultEditProps {
    gameId: string;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RESULT_TYPES = new Map<string, string>(
    Object.entries({
        halftime: 'Half time',
        regulartime: 'Regular time',
        extratime: 'Extra time',
        penalty: 'Penalty',
    }),
);

export const GameResultEditComponent: FC<GameResultEditProps> = (props) => {
    return (
        <>
            <h5>Game Results</h5>
            <GameResultSingleEdit gameId={props.gameId} type="HALF_TIME" />
            <GameResultSingleEdit gameId={props.gameId} type="REGULAR_TIME" />
            <GameResultSingleEdit gameId={props.gameId} type="EXTRA_TIME" />
            <GameResultSingleEdit gameId={props.gameId} type="PENALTY_SHOOTOUT" />
        </>
    );
};

interface GameResultSingleEditProps {
    gameId: string;
    type: string;
}

const GameResultSingleEdit: FC<GameResultSingleEditProps> = (props) => {
    const [r1, setR1] = useState<number>();
    const [r2, setR2] = useState<number>();
    const [source, setSource] = useState<string>();
    const [lastUpdated, setLastUpdated] = useState<string>();
    const [editMode, setEditMode] = useState(false);

    const dispatch = useDispatch();

    const gameApi = getGameApi2();

    const removeResult = () => {
        setR1(undefined);
        setR2(undefined);
        setSource(undefined);
        setLastUpdated(undefined);
    };

    const gameResultsQuery = useQuery({
        queryKey: ['getGameResults', props.gameId],
        queryFn: () => gameApi.getGameResults({ gameId: props.gameId }),
        retry: false,
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        if (gameResultsQuery.data) {
            const gr = gameResultsQuery.data.find((r) => r.type === props.type);
            if (gr) {
                setR1(gr.result1);
                setR2(gr.result2);
                setSource(gr.source);
                setLastUpdated(moment(gr.lastUpdated).format('DD.MM.YY HH:mm:ss'));
            } else {
                removeResult();
            }
        } else {
            removeResult();
        }
    }, [gameResultsQuery.data, props.type]);

    const resultUpdateMutation = useMutation({
        mutationFn: (v: { gameId: string; type: string; r1: number; r2: number }) =>
            getTournamentSysAdminApi().updateGameResult(v),
    });

    const resultDeleteMutation = useMutation({
        mutationFn: (v: { gameId: string; type: string }) => getTournamentSysAdminApi().deleteGameResult(v),

        onSuccess() {
            dispatch(systemSetPopupMessage('Game result deleted successfully'));
            removeResult();
            gameResultsQuery.refetch();
        },
        onError(error) {
            dispatch(systemSetPopupMessage('Error deleting game result'));
        },
    });

    const onSave = () => {
        if (r1 === undefined || r2 === undefined) {
            dispatch(systemSetPopupMessage('Please enter both results'));
            return;
        }
        resultUpdateMutation.mutate(
            { gameId: props.gameId, type: props.type, r1, r2 },
            {
                onSuccess() {
                    dispatch(systemSetPopupMessage('Game result updated successfully'));
                    setEditMode(false);
                    gameResultsQuery.refetch();
                },
                onError(error) {
                    dispatch(systemSetPopupMessage('Error updating game result. (' + error + ')'));
                },
            },
        );
    };

    const onCancel = () => {
        setEditMode(false);
    };

    const onEdit = () => {
        setEditMode(true);
    };

    const onDelete = () => {
        setEditMode(false);
        resultDeleteMutation.mutate({ gameId: props.gameId, type: props.type });
    };

    const type2Label = (type: string) => {
        switch (type) {
            case 'HALF_TIME':
                return 'Half time';
            case 'REGULAR_TIME':
                return 'Regular time';
            case 'EXTRA_TIME':
                return 'Extra time';
            case 'PENALTY_SHOOTOUT':
                return 'Penalty shootout';
            default:
                return type;
        }
    };

    return (
        <div className="bg-secondary p-2">
            <Form>
                <Row className="align-items-center">
                    <Col className="col-3 align-middle">
                        <Label className="m-0">{type2Label(props.type)}</Label>
                    </Col>
                    <Col className="col-2">
                        <span className="d-flex no-wrap">
                            <LoadingIndicatorContent isLoading={gameResultsQuery.isFetching}>
                                <Input
                                    className="d-inline-block py-0 px-1 text-center"
                                    value={r1}
                                    onChange={(e) => setR1(Number(e.currentTarget.value))}
                                    disabled={!editMode}
                                />
                            </LoadingIndicatorContent>
                            &nbsp;:&nbsp;
                            <LoadingIndicatorContent isLoading={gameResultsQuery.isFetching}>
                                <Input
                                    className="d-inline-block py-0 px-1 text-center"
                                    value={r2}
                                    onChange={(e) => setR2(Number(e.currentTarget.value))}
                                    disabled={!editMode}
                                />
                            </LoadingIndicatorContent>
                        </span>
                    </Col>
                    <Col className="col-5">
                        <div className="d-flex flex-column">
                            <small>
                                <div className="text-truncate">{lastUpdated}</div>
                                <div className="text-truncate">{source}</div>
                            </small>
                        </div>
                    </Col>
                    <Col className="col-2 flex-nowrap text-center">
                        {!editMode && !gameResultsQuery.isFetching && (
                            <Button size="sm" onClick={onEdit}>
                                <FontAwesomeIcon icon={faEdit} />
                            </Button>
                        )}
                        {!editMode && !gameResultsQuery.isFetching && (
                            <ButtonWithConfirmation
                                size="sm"
                                title="Delete game result?"
                                message="Are you sure you want to delete this game result?"
                                onOK={onDelete}
                            >
                                <FontAwesomeIcon icon={faTrashCan} />
                            </ButtonWithConfirmation>
                        )}
                        {editMode && !gameResultsQuery.isFetching && (
                            <Button size="sm" onClick={onSave}>
                                <FontAwesomeIcon icon={faCircleCheck} color="green" />
                            </Button>
                        )}
                        {editMode && !gameResultsQuery.isFetching && (
                            <Button size="sm" onClick={onCancel}>
                                <FontAwesomeIcon icon={faCircleXmark} color="red" />
                            </Button>
                        )}
                    </Col>
                </Row>
            </Form>
        </div>
    );
};
