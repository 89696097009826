import * as React from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'reactstrap';
import { RoundHighscoreLink } from '../../../../links/RoundHighscoreLink';
import { HighscoreInfo } from '../../../../services/betting';
import { Section, SectionHeading } from '../../../common/Section';
import { userInfoSelector } from '../../../System/selectors';
import { bettingProfileMyHighscoresSelector } from '../selectors';

interface Props {}

export const ProfileMyResults = (props: Props) => {
    const highscoreInfos = useSelector(bettingProfileMyHighscoresSelector);
    const user = useSelector(userInfoSelector);

    function showHighscoreInfos(hi: HighscoreInfo) {
        return (
            <tr key={hi.tournamentRoundId}>
                <td>{hi.tournamentName}</td>
                <td>
                    <RoundHighscoreLink trId={String(hi.tournamentRoundId)} userId={user.id}>
                        {hi.rank}
                    </RoundHighscoreLink>
                </td>
            </tr>
        );
    }

    return (
        <Section>
            <SectionHeading>Meine aktuellen Platzierungen</SectionHeading>
            <Table striped={true}>
                <thead>
                    <tr>
                        <th>Turnier</th>
                        <th>Platzierung</th>
                    </tr>
                </thead>
                <tbody>{highscoreInfos && highscoreInfos.map(hi => showHighscoreInfos(hi))}</tbody>
            </Table>
        </Section>
    );
};
