/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Blog,
  BlogEntryId,
  BlogEntryImageId,
  BlogId,
} from '../models';
import {
    BlogFromJSON,
    BlogToJSON,
    BlogEntryIdFromJSON,
    BlogEntryIdToJSON,
    BlogEntryImageIdFromJSON,
    BlogEntryImageIdToJSON,
    BlogIdFromJSON,
    BlogIdToJSON,
} from '../models';

export interface AddImageRequest {
    clientName: string;
    blogEntryId: string;
    blogId: string;
    imageId: string;
}

export interface CreateBlogRequest {
    clientName: string;
    name: string;
}

export interface CreateBlogEntryRequest {
    clientName: string;
    blogId: string;
    title: string;
    text: string;
}

export interface DeleteBlogRequest {
    clientName: string;
    blogId: string;
}

export interface DeleteBlogEntryRequest {
    clientName: string;
    blogEntryId: string;
}

export interface DeleteImageRequest {
    clientName: string;
    blogEntryId: string;
    blogId: string;
    imageId: string;
}

export interface GetBlogRequest {
    clientName: string;
    blogId: string;
}

export interface GetLatestRequest {
    clientName: string;
}

export interface ListBlogsRequest {
    clientName: string;
}

export interface PublishBlogEntryOnTwitterRequest {
    clientName: string;
    blogEntryId: string;
}

export interface PublishBlogOnTwitterRequest {
    clientName: string;
    blogId: string;
}

export interface UpdateBlogEntryRequest {
    clientName: string;
    blogEntryId: string;
    blogId: string;
    title: string;
    text: string;
}

/**
 * 
 */
export class BlogControllerApi extends runtime.BaseAPI {

    /**
     */
    async addImageRaw(requestParameters: AddImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BlogEntryImageId>> {
        if (requestParameters.clientName === null || requestParameters.clientName === undefined) {
            throw new runtime.RequiredError('clientName','Required parameter requestParameters.clientName was null or undefined when calling addImage.');
        }

        if (requestParameters.blogEntryId === null || requestParameters.blogEntryId === undefined) {
            throw new runtime.RequiredError('blogEntryId','Required parameter requestParameters.blogEntryId was null or undefined when calling addImage.');
        }

        if (requestParameters.blogId === null || requestParameters.blogId === undefined) {
            throw new runtime.RequiredError('blogId','Required parameter requestParameters.blogId was null or undefined when calling addImage.');
        }

        if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
            throw new runtime.RequiredError('imageId','Required parameter requestParameters.imageId was null or undefined when calling addImage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/blog/{clientName}/{blogId}/{blogEntryId}/{imageId}`.replace(`{${"clientName"}}`, encodeURIComponent(String(requestParameters.clientName))).replace(`{${"blogEntryId"}}`, encodeURIComponent(String(requestParameters.blogEntryId))).replace(`{${"blogId"}}`, encodeURIComponent(String(requestParameters.blogId))).replace(`{${"imageId"}}`, encodeURIComponent(String(requestParameters.imageId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BlogEntryImageIdFromJSON(jsonValue));
    }

    /**
     */
    async addImage(clientName: string, blogEntryId: string, blogId: string, imageId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BlogEntryImageId> {
        const response = await this.addImageRaw({ clientName: clientName, blogEntryId: blogEntryId, blogId: blogId, imageId: imageId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async createBlogRaw(requestParameters: CreateBlogRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BlogId>> {
        if (requestParameters.clientName === null || requestParameters.clientName === undefined) {
            throw new runtime.RequiredError('clientName','Required parameter requestParameters.clientName was null or undefined when calling createBlog.');
        }

        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling createBlog.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/blog/{clientName}`.replace(`{${"clientName"}}`, encodeURIComponent(String(requestParameters.clientName))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BlogIdFromJSON(jsonValue));
    }

    /**
     */
    async createBlog(clientName: string, name: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BlogId> {
        const response = await this.createBlogRaw({ clientName: clientName, name: name }, initOverrides);
        return await response.value();
    }

    /**
     */
    async createBlogEntryRaw(requestParameters: CreateBlogEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BlogEntryId>> {
        if (requestParameters.clientName === null || requestParameters.clientName === undefined) {
            throw new runtime.RequiredError('clientName','Required parameter requestParameters.clientName was null or undefined when calling createBlogEntry.');
        }

        if (requestParameters.blogId === null || requestParameters.blogId === undefined) {
            throw new runtime.RequiredError('blogId','Required parameter requestParameters.blogId was null or undefined when calling createBlogEntry.');
        }

        if (requestParameters.title === null || requestParameters.title === undefined) {
            throw new runtime.RequiredError('title','Required parameter requestParameters.title was null or undefined when calling createBlogEntry.');
        }

        if (requestParameters.text === null || requestParameters.text === undefined) {
            throw new runtime.RequiredError('text','Required parameter requestParameters.text was null or undefined when calling createBlogEntry.');
        }

        const queryParameters: any = {};

        if (requestParameters.title !== undefined) {
            queryParameters['title'] = requestParameters.title;
        }

        if (requestParameters.text !== undefined) {
            queryParameters['text'] = requestParameters.text;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/blog/{clientName}/{blogId}`.replace(`{${"clientName"}}`, encodeURIComponent(String(requestParameters.clientName))).replace(`{${"blogId"}}`, encodeURIComponent(String(requestParameters.blogId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BlogEntryIdFromJSON(jsonValue));
    }

    /**
     */
    async createBlogEntry(clientName: string, blogId: string, title: string, text: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BlogEntryId> {
        const response = await this.createBlogEntryRaw({ clientName: clientName, blogId: blogId, title: title, text: text }, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteBlogRaw(requestParameters: DeleteBlogRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BlogId>> {
        if (requestParameters.clientName === null || requestParameters.clientName === undefined) {
            throw new runtime.RequiredError('clientName','Required parameter requestParameters.clientName was null or undefined when calling deleteBlog.');
        }

        if (requestParameters.blogId === null || requestParameters.blogId === undefined) {
            throw new runtime.RequiredError('blogId','Required parameter requestParameters.blogId was null or undefined when calling deleteBlog.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/blog/{clientName}/{blogId}`.replace(`{${"clientName"}}`, encodeURIComponent(String(requestParameters.clientName))).replace(`{${"blogId"}}`, encodeURIComponent(String(requestParameters.blogId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BlogIdFromJSON(jsonValue));
    }

    /**
     */
    async deleteBlog(clientName: string, blogId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BlogId> {
        const response = await this.deleteBlogRaw({ clientName: clientName, blogId: blogId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteBlogEntryRaw(requestParameters: DeleteBlogEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BlogEntryId>> {
        if (requestParameters.clientName === null || requestParameters.clientName === undefined) {
            throw new runtime.RequiredError('clientName','Required parameter requestParameters.clientName was null or undefined when calling deleteBlogEntry.');
        }

        if (requestParameters.blogEntryId === null || requestParameters.blogEntryId === undefined) {
            throw new runtime.RequiredError('blogEntryId','Required parameter requestParameters.blogEntryId was null or undefined when calling deleteBlogEntry.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/blog/{clientName}/entry/{blogEntryId}`.replace(`{${"clientName"}}`, encodeURIComponent(String(requestParameters.clientName))).replace(`{${"blogEntryId"}}`, encodeURIComponent(String(requestParameters.blogEntryId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BlogEntryIdFromJSON(jsonValue));
    }

    /**
     */
    async deleteBlogEntry(clientName: string, blogEntryId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BlogEntryId> {
        const response = await this.deleteBlogEntryRaw({ clientName: clientName, blogEntryId: blogEntryId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteImageRaw(requestParameters: DeleteImageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BlogEntryImageId>> {
        if (requestParameters.clientName === null || requestParameters.clientName === undefined) {
            throw new runtime.RequiredError('clientName','Required parameter requestParameters.clientName was null or undefined when calling deleteImage.');
        }

        if (requestParameters.blogEntryId === null || requestParameters.blogEntryId === undefined) {
            throw new runtime.RequiredError('blogEntryId','Required parameter requestParameters.blogEntryId was null or undefined when calling deleteImage.');
        }

        if (requestParameters.blogId === null || requestParameters.blogId === undefined) {
            throw new runtime.RequiredError('blogId','Required parameter requestParameters.blogId was null or undefined when calling deleteImage.');
        }

        if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
            throw new runtime.RequiredError('imageId','Required parameter requestParameters.imageId was null or undefined when calling deleteImage.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/blog/{clientName}/{blogId}/{blogEntryId}/{imageId}`.replace(`{${"clientName"}}`, encodeURIComponent(String(requestParameters.clientName))).replace(`{${"blogEntryId"}}`, encodeURIComponent(String(requestParameters.blogEntryId))).replace(`{${"blogId"}}`, encodeURIComponent(String(requestParameters.blogId))).replace(`{${"imageId"}}`, encodeURIComponent(String(requestParameters.imageId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BlogEntryImageIdFromJSON(jsonValue));
    }

    /**
     */
    async deleteImage(clientName: string, blogEntryId: string, blogId: string, imageId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BlogEntryImageId> {
        const response = await this.deleteImageRaw({ clientName: clientName, blogEntryId: blogEntryId, blogId: blogId, imageId: imageId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getBlogRaw(requestParameters: GetBlogRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blog>> {
        if (requestParameters.clientName === null || requestParameters.clientName === undefined) {
            throw new runtime.RequiredError('clientName','Required parameter requestParameters.clientName was null or undefined when calling getBlog.');
        }

        if (requestParameters.blogId === null || requestParameters.blogId === undefined) {
            throw new runtime.RequiredError('blogId','Required parameter requestParameters.blogId was null or undefined when calling getBlog.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/blog/{clientName}/{blogId}`.replace(`{${"clientName"}}`, encodeURIComponent(String(requestParameters.clientName))).replace(`{${"blogId"}}`, encodeURIComponent(String(requestParameters.blogId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BlogFromJSON(jsonValue));
    }

    /**
     */
    async getBlog(clientName: string, blogId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blog> {
        const response = await this.getBlogRaw({ clientName: clientName, blogId: blogId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getLatestRaw(requestParameters: GetLatestRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blog>> {
        if (requestParameters.clientName === null || requestParameters.clientName === undefined) {
            throw new runtime.RequiredError('clientName','Required parameter requestParameters.clientName was null or undefined when calling getLatest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/blog/{clientName}/latest`.replace(`{${"clientName"}}`, encodeURIComponent(String(requestParameters.clientName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BlogFromJSON(jsonValue));
    }

    /**
     */
    async getLatest(clientName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blog> {
        const response = await this.getLatestRaw({ clientName: clientName }, initOverrides);
        return await response.value();
    }

    /**
     */
    async listBlogsRaw(requestParameters: ListBlogsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Blog>>> {
        if (requestParameters.clientName === null || requestParameters.clientName === undefined) {
            throw new runtime.RequiredError('clientName','Required parameter requestParameters.clientName was null or undefined when calling listBlogs.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/blog/{clientName}`.replace(`{${"clientName"}}`, encodeURIComponent(String(requestParameters.clientName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BlogFromJSON));
    }

    /**
     */
    async listBlogs(clientName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Blog>> {
        const response = await this.listBlogsRaw({ clientName: clientName }, initOverrides);
        return await response.value();
    }

    /**
     */
    async publishBlogEntryOnTwitterRaw(requestParameters: PublishBlogEntryOnTwitterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BlogEntryId>> {
        if (requestParameters.clientName === null || requestParameters.clientName === undefined) {
            throw new runtime.RequiredError('clientName','Required parameter requestParameters.clientName was null or undefined when calling publishBlogEntryOnTwitter.');
        }

        if (requestParameters.blogEntryId === null || requestParameters.blogEntryId === undefined) {
            throw new runtime.RequiredError('blogEntryId','Required parameter requestParameters.blogEntryId was null or undefined when calling publishBlogEntryOnTwitter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/blog/{clientName}/publish-blog-entry-on-twitter/{blogEntryId}`.replace(`{${"clientName"}}`, encodeURIComponent(String(requestParameters.clientName))).replace(`{${"blogEntryId"}}`, encodeURIComponent(String(requestParameters.blogEntryId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BlogEntryIdFromJSON(jsonValue));
    }

    /**
     */
    async publishBlogEntryOnTwitter(clientName: string, blogEntryId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BlogEntryId> {
        const response = await this.publishBlogEntryOnTwitterRaw({ clientName: clientName, blogEntryId: blogEntryId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async publishBlogOnTwitterRaw(requestParameters: PublishBlogOnTwitterRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BlogId>> {
        if (requestParameters.clientName === null || requestParameters.clientName === undefined) {
            throw new runtime.RequiredError('clientName','Required parameter requestParameters.clientName was null or undefined when calling publishBlogOnTwitter.');
        }

        if (requestParameters.blogId === null || requestParameters.blogId === undefined) {
            throw new runtime.RequiredError('blogId','Required parameter requestParameters.blogId was null or undefined when calling publishBlogOnTwitter.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/blog/{clientName}/publish-blog-on-twitter/{blogId}`.replace(`{${"clientName"}}`, encodeURIComponent(String(requestParameters.clientName))).replace(`{${"blogId"}}`, encodeURIComponent(String(requestParameters.blogId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BlogIdFromJSON(jsonValue));
    }

    /**
     */
    async publishBlogOnTwitter(clientName: string, blogId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BlogId> {
        const response = await this.publishBlogOnTwitterRaw({ clientName: clientName, blogId: blogId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateBlogEntryRaw(requestParameters: UpdateBlogEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BlogEntryId>> {
        if (requestParameters.clientName === null || requestParameters.clientName === undefined) {
            throw new runtime.RequiredError('clientName','Required parameter requestParameters.clientName was null or undefined when calling updateBlogEntry.');
        }

        if (requestParameters.blogEntryId === null || requestParameters.blogEntryId === undefined) {
            throw new runtime.RequiredError('blogEntryId','Required parameter requestParameters.blogEntryId was null or undefined when calling updateBlogEntry.');
        }

        if (requestParameters.blogId === null || requestParameters.blogId === undefined) {
            throw new runtime.RequiredError('blogId','Required parameter requestParameters.blogId was null or undefined when calling updateBlogEntry.');
        }

        if (requestParameters.title === null || requestParameters.title === undefined) {
            throw new runtime.RequiredError('title','Required parameter requestParameters.title was null or undefined when calling updateBlogEntry.');
        }

        if (requestParameters.text === null || requestParameters.text === undefined) {
            throw new runtime.RequiredError('text','Required parameter requestParameters.text was null or undefined when calling updateBlogEntry.');
        }

        const queryParameters: any = {};

        if (requestParameters.title !== undefined) {
            queryParameters['title'] = requestParameters.title;
        }

        if (requestParameters.text !== undefined) {
            queryParameters['text'] = requestParameters.text;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/blog/{clientName}/{blogId}/{blogEntryId}`.replace(`{${"clientName"}}`, encodeURIComponent(String(requestParameters.clientName))).replace(`{${"blogEntryId"}}`, encodeURIComponent(String(requestParameters.blogEntryId))).replace(`{${"blogId"}}`, encodeURIComponent(String(requestParameters.blogId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BlogEntryIdFromJSON(jsonValue));
    }

    /**
     */
    async updateBlogEntry(clientName: string, blogEntryId: string, blogId: string, title: string, text: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BlogEntryId> {
        const response = await this.updateBlogEntryRaw({ clientName: clientName, blogEntryId: blogEntryId, blogId: blogId, title: title, text: text }, initOverrides);
        return await response.value();
    }

}
