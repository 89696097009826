import { push } from '@lagunovsky/redux-react-router';
import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Alert, Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { SubFormProps } from '..';
import { LoadingIndicatorSpinner2 } from '../../common/LoadingIndicatorSpinner2';
import { systemSetLoginNotRequired } from '../../System/actions';
import { getLogin2Api, getSystemConfig } from '../../System/systemConfig';

export const PasswordForgottenSubForm: FC<SubFormProps> = (props) => {
    const dispatch = useDispatch();

    const [email, setEmail] = useState('');
    const [generalErrorMessage, setGeneralErrorMessage] = useState('');
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const [loading, setLoading] = useState(false);

    const onSubmit = () => {
        try {
            setLoading(true);
            getLogin2Api()
                .passwordForgotten({ tenant: getSystemConfig().clientName, email })
                .then((r) => onShowSuccessMessage())
                .catch((e) =>
                    setGeneralErrorMessage(
                        'Ein Nutzer mit dieser Email-Adresse existiert nicht oder die Email-Adresse ist ungültig.',
                    ),
                )
                .finally(() => setLoading(false));
        } finally {
        }
    };

    const onCancel = () => {
        // FIXME define a global method for the portal path
        dispatch(systemSetLoginNotRequired());
        setEmail('');
        setGeneralErrorMessage('');
        setShowSuccessMessage(false);
        props.changeSubForm('login');
        dispatch(push('/b/portal'));
    };

    var timeout: NodeJS.Timeout;

    const onShowSuccessMessage = () => {
        setShowSuccessMessage(true);
        timeout = setTimeout(() => {
            onSuccessfulPasswordReset();
        }, 10000);
    };

    const onSuccessfulPasswordReset = () => {
        dispatch(systemSetLoginNotRequired());
        setEmail('');
        setShowSuccessMessage(false);
        props.changeSubForm('login');
        dispatch(push('/b/portal'));
        clearTimeout(timeout);
    };

    return (
        <>
            <LoadingIndicatorSpinner2 isLoading={loading} />
            <h3>Kennwort vergessen</h3>
            <p>
                Geben Sie die Email-Adresse ein, die Sie bei der Registrierung verwendet haben. Sie erhalten dann per
                Email einen Link zum Zurücksetzen Ihres Kennwortes.
            </p>

            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    onSubmit();
                }}
            >
                <FormGroup>
                    <Label>
                        <b>Email-Adresse</b>
                    </Label>
                    <Input value={email} onChange={(e) => setEmail(e.currentTarget.value)} />
                </FormGroup>

                <FormGroup>
                    <div style={{ color: 'red' }}>{generalErrorMessage}</div>
                </FormGroup>
                {!showSuccessMessage && (
                    <>
                        <Button color="success" type="submit" className="me-3">
                            Kennwort zurücksetzen
                        </Button>
                        <Button onClick={onCancel}>Abbrechen</Button>
                    </>
                )}
                {showSuccessMessage && (
                    <Alert color="primary">
                        <p>
                            Sie erhalten jetzt eine Email mit einem Link zum Zurücksetzen Ihres Kennworts.
                            <br />
                            Sie werden in 10 Sekunden automatisch weitergeleitet.
                        </p>
                        <Button color="success" onClick={onSuccessfulPasswordReset}>
                            OK
                        </Button>
                    </Alert>
                )}
            </Form>
        </>
    );
};
