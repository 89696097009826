import { all, select, takeLatest } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { HighscoreStrategyDescriptor } from '../../../services/betting';
import { fetchWrapperFunc } from '../../../utils';
import { getUserGroupAdminApi2, getUserGroupApi2 } from '../../System/systemConfig';
import { loadDefaultTournament } from '../../TournamentSelectionComponent/sagas';
import { tournamentSelectionTournamentIdSelector } from '../../TournamentSelectionComponent/selectors';
import {
    hsdEditSet,
    hsdEditSetAvailableStandardStrategies,
    hsdEditSetUgName,
    HSD_EDIT_CHOOSE_STANDARD,
    HSD_EDIT_LOAD,
    HSD_EDIT_UPDATE_CUSTOM,
} from './actions';

function* loadGenerator(action: Action<string>) {
    const ugId = action.payload;

    // set the ugId in the store
    // yield put(hsdSetUgId(ugId));

    const api = getUserGroupAdminApi2();
    const tId: number | undefined = yield select(tournamentSelectionTournamentIdSelector);

    if (!tId) {
        yield* loadDefaultTournament();
        const tId2: number | undefined = yield select(tournamentSelectionTournamentIdSelector);
        if (tId2) {
            yield* fetchWrapperFunc(
                () => api.getHighscoreDescriptor({ ugId: Number(ugId), tId: tId2 }),
                [(r) => hsdEditSet(r)],
            );
        }
    } else {
        // load the highscore descriptor
        yield* fetchWrapperFunc(() => api.getHighscoreDescriptor({ ugId: Number(ugId), tId }), [(r) => hsdEditSet(r)]);
    }
    // load all available standard descriptors
    yield* fetchWrapperFunc(
        () => api.getAvailableStandardHighscoreDescriptors({ ugId: Number(ugId) }),
        [(r) => hsdEditSetAvailableStandardStrategies(r)],
    );

    // load the user group name
    yield* fetchWrapperFunc(
        () => getUserGroupApi2().getDetailOfUserGroup({ ugId: Number(ugId) }),
        [(r) => hsdEditSetUgName(r.name)],
    );
}

function* chooseStandardGenerator(action: Action<{ ugId: string; hsdId: string }>) {
    const { ugId, hsdId } = action.payload;
    const api = getUserGroupAdminApi2();

    yield* fetchWrapperFunc(() =>
        api.updateUserGroupStandardHighscoreDescriptor({ ugId: Number(ugId), hsdId: Number(hsdId) }),
    );
}

function* updateCustomGenerator(action: Action<{ ugId: string; hsd: HighscoreStrategyDescriptor }>) {
    const { ugId, hsd } = action.payload;
    const api = getUserGroupAdminApi2();

    yield* fetchWrapperFunc(() =>
        api.updateUserGroupCustomHighscoreDescriptor({ ugId: Number(ugId), highscoreStrategyDescriptor: hsd }),
    );
}

function* watchForHsdAction() {
    yield takeLatest(HSD_EDIT_LOAD, loadGenerator);
    yield takeLatest(HSD_EDIT_CHOOSE_STANDARD, chooseStandardGenerator);
    yield takeLatest(HSD_EDIT_UPDATE_CUSTOM, updateCustomGenerator);
}

export function* hsdEditSagas() {
    yield all([watchForHsdAction()]);
}
