import { all, select, takeLatest } from 'redux-saga/effects';
import { Tournament } from '../../../services/betting';
import { fetchWrapperFuncNoLoading } from '../../../utils';
import { isLoggedInSelector, userIdSelector } from '../../System/selectors';
import { getHighscoreApi2, getSystemConfig, getTournamentApi2 } from '../../System/systemConfig';
import { tournamentSelectionTournamentIdSelector } from '../../TournamentSelectionComponent/selectors';
import {
    portalSetLastHighscores,
    portalSetLastRound,
    portalSetLastUserHighscores,
    portalSetRootHighscores,
    portalSetRootRound,
    portalSetRootUserHighscores,
    PORTAL_LOAD_TOP_HIGHSCORES,
} from './actions';

function* portalLoadTopHighscoreGenerator() {
    const highscoreApi = getHighscoreApi2();
    const tournamentApi = getTournamentApi2();

    let tvarId: number | undefined = yield select(tournamentSelectionTournamentIdSelector);

    if (!tvarId) {
        const defaultTournament: Tournament | undefined = yield* fetchWrapperFuncNoLoading(() =>
            tournamentApi.getDefaultTournament({ clientId: getSystemConfig().clientId }),
        );

        if (defaultTournament) {
            tvarId = defaultTournament.id;
        }
    }

    const tId = tvarId;
    const isLoggedIn: boolean = yield select(isLoggedInSelector);
    const userId: string | undefined = yield select(userIdSelector);

    if (tId) {
        const rootRound = yield* fetchWrapperFuncNoLoading(
            () => tournamentApi.getRootTournamentRoundByType({ tournamentId: tId, type: 'highscores' }),
            [(r) => portalSetRootRound(r)],
        );

        const lastRound = yield* fetchWrapperFuncNoLoading(
            () => tournamentApi.getLastTournamentRoundByType({ tournamentId: tId, type: 'highscores' }),
            [(r) => portalSetLastRound(r)],
        );

        if (rootRound && rootRound.id) {
            yield* fetchWrapperFuncNoLoading(
                () =>
                    highscoreApi.getHighscoreTop5({ tenant: getSystemConfig().clientName, trId: String(rootRound.id) }),
                [(r) => portalSetRootHighscores(r)],
            );
            if (isLoggedIn && userId) {
                yield* fetchWrapperFuncNoLoading(
                    () => highscoreApi.getHighscoreForRoundAndUser({ tournamentRoundId: String(rootRound.id), userId }),
                    [(r) => portalSetRootUserHighscores(r)],
                );
            }
        }
        if (lastRound && lastRound.id) {
            yield* fetchWrapperFuncNoLoading(
                () =>
                    highscoreApi.getHighscoreTop5({ tenant: getSystemConfig().clientName, trId: String(lastRound.id) }),
                [(r) => portalSetLastHighscores(r)],
            );
            if (isLoggedIn && userId) {
                yield* fetchWrapperFuncNoLoading(
                    () => highscoreApi.getHighscoreForRoundAndUser({ tournamentRoundId: String(lastRound.id), userId }),
                    [(r) => portalSetLastUserHighscores(r)],
                );
            }
        }
    }
}

function* watchForPortalActions() {
    yield takeLatest(PORTAL_LOAD_TOP_HIGHSCORES, portalLoadTopHighscoreGenerator);
}

export function* portalSagas() {
    yield all([watchForPortalActions()]);
}
