import React, { FC, useState } from 'react';
import { Section } from '../../common/Section';
import { LoadingIndicatorSpinner2 } from '../../common/LoadingIndicatorSpinner2';
import { FormGroup, Input, Label, Table } from 'reactstrap';
import { useQuery } from '@tanstack/react-query';
import { getTranslationApi } from '../../System/systemConfig';
import { ClientStaticTranslation } from '../../../services/webapp';
import { withMenu } from '../../../reactutils/withMenu';
import { MenuSysAdmin } from '../../MenuSysAdmin';
import { withBorder } from '../../../reactutils/withBorder';
import { SysAdminSystemSubMenu } from '../SysAdminSubMenu';
import { withTitle } from '../../../reactutils/withTitle';
import { withLogin } from '../../../reactutils/withLogin';
import * as R from 'ramda';
import { useDebounce } from 'use-debounce';

export const TranslationComp: FC = () => {
    const [filter, setFilter] = useState<string>('');
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [page, setPage] = useState<number>(0);

    const [debouncedFilter] = useDebounce(filter, 500);

    const pageSize = 20;

    const api = getTranslationApi();

    const translationQuery = useQuery({
        queryKey: ['translations', debouncedFilter, page, pageSize],
        queryFn: () => api.searchTranslation({ filter: debouncedFilter, page, pageSize }),
    });

    const dateFmt = new Intl.DateTimeFormat('de-DE');

    const renderTranslation = (cst: ClientStaticTranslation) => {
        return (
            <tr key={cst.id}>
                <td>{cst.key}</td>
                <td>{cst.lastUpdate ? dateFmt.format(cst.lastUpdate) : '-'}</td>
                <td>{cst.clientName}</td>
                <td>{cst.languageName}</td>
                <td className="text-break col-4 truncate">{cst.value}</td>
            </tr>
        );
    };

    return (
        <Section>
            <LoadingIndicatorSpinner2 isLoading={translationQuery.isFetching} />
            <FormGroup>
                <Label>Filter</Label>
                <Input type="text" value={filter} onChange={(e) => setFilter(e.target.value)} />
            </FormGroup>
            <Table>
                <thead>
                    <tr>
                        <th>Key</th>
                        <th>Update</th>
                        <th>Client</th>
                        <th>Langugage</th>
                        <th>Value</th>
                    </tr>
                </thead>
                <tbody>{translationQuery.data?.map((cst) => renderTranslation(cst))}</tbody>
            </Table>
        </Section>
    );
};

export const TranslationScreen = R.compose(
    withMenu(<MenuSysAdmin />),
    withBorder(),
    withMenu(<SysAdminSystemSubMenu />),
    withTitle('Static Translations'),
    withLogin(),
)(TranslationComp);
