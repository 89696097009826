import { GlobalState } from '../../../actions';

export const notificationStatusSelector = (state: GlobalState) => state.notificationStatus;

export const notificationStatusEntitySelector = (state: GlobalState) => notificationStatusSelector(state).status;

export const notificationStatusInvitationsSelector = (state: GlobalState) =>
    notificationStatusSelector(state).status.groupInvitations;

export const notificationStatusAskForMembershipSelector = (state: GlobalState) =>
    notificationStatusSelector(state).status.groupAskForMemberships;
