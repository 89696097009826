/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.25
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  RestResult,
  SimpleInteger,
  TeamChat,
  TeamChatMessage,
} from '../models/index';
import {
    RestResultFromJSON,
    RestResultToJSON,
    SimpleIntegerFromJSON,
    SimpleIntegerToJSON,
    TeamChatFromJSON,
    TeamChatToJSON,
    TeamChatMessageFromJSON,
    TeamChatMessageToJSON,
} from '../models/index';

export interface AddMessageRequest {
    ugId: string;
    message?: string;
}

export interface CountMessagesRequest {
    ugId: string;
}

export interface CountUnreadRequest {
    ugId: string;
}

export interface GetMessagesRequest {
    page: number;
    pageSize: number;
    ugId: string;
}

export interface GetTeamChatRequest {
    ugId: string;
}

export interface PublishRequest {
    ugId: string;
}

export interface UnpublishRequest {
    ugId: string;
}

/**
 * 
 */
export class TeamChat2ControllerApi extends runtime.BaseAPI {

    /**
     */
    async addMessageRaw(requestParameters: AddMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult>> {
        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling addMessage().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['message'] != null) {
            queryParameters['message'] = requestParameters['message'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/teamchat/{ugId}/add`.replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResultFromJSON(jsonValue));
    }

    /**
     */
    async addMessage(requestParameters: AddMessageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult> {
        const response = await this.addMessageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async countMessagesRaw(requestParameters: CountMessagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SimpleInteger>> {
        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling countMessages().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/teamchat/{ugId}/count`.replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SimpleIntegerFromJSON(jsonValue));
    }

    /**
     */
    async countMessages(requestParameters: CountMessagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SimpleInteger> {
        const response = await this.countMessagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async countUnreadRaw(requestParameters: CountUnreadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SimpleInteger>> {
        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling countUnread().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/teamchat/{ugId}/count-unread`.replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SimpleIntegerFromJSON(jsonValue));
    }

    /**
     */
    async countUnread(requestParameters: CountUnreadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SimpleInteger> {
        const response = await this.countUnreadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMessagesRaw(requestParameters: GetMessagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TeamChatMessage>>> {
        if (requestParameters['page'] == null) {
            throw new runtime.RequiredError(
                'page',
                'Required parameter "page" was null or undefined when calling getMessages().'
            );
        }

        if (requestParameters['pageSize'] == null) {
            throw new runtime.RequiredError(
                'pageSize',
                'Required parameter "pageSize" was null or undefined when calling getMessages().'
            );
        }

        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling getMessages().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/teamchat/{ugId}/{page}/{pageSize}`.replace(`{${"page"}}`, encodeURIComponent(String(requestParameters['page']))).replace(`{${"pageSize"}}`, encodeURIComponent(String(requestParameters['pageSize']))).replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TeamChatMessageFromJSON));
    }

    /**
     */
    async getMessages(requestParameters: GetMessagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TeamChatMessage>> {
        const response = await this.getMessagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTeamChatRaw(requestParameters: GetTeamChatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TeamChat>> {
        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling getTeamChat().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/teamchat/{ugId}`.replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamChatFromJSON(jsonValue));
    }

    /**
     */
    async getTeamChat(requestParameters: GetTeamChatRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TeamChat> {
        const response = await this.getTeamChatRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async publishRaw(requestParameters: PublishRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult>> {
        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling publish().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/teamchat/{ugId}/publish`.replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResultFromJSON(jsonValue));
    }

    /**
     */
    async publish(requestParameters: PublishRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult> {
        const response = await this.publishRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async unpublishRaw(requestParameters: UnpublishRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult>> {
        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling unpublish().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/teamchat/{ugId}/unpublish`.replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResultFromJSON(jsonValue));
    }

    /**
     */
    async unpublish(requestParameters: UnpublishRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult> {
        const response = await this.unpublishRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
