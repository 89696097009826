import actionCreatorFactory from 'typescript-fsa';
import { GroupMember, GroupMemberChange, UserGroupDetail } from '../../services/betting';
import { GroupInvitationState } from './reducer';

const actionCreator = actionCreatorFactory();

export const LOAD_USER_GROUP_MEMBERS = 'LOAD_USER_GROUP_MEMBERS';
export const LOAD_USER_GROUP_DETAIL = 'LOAD_USER_GROUP_DETAIL';
export const loadUserGroupMembers = actionCreator<number>(LOAD_USER_GROUP_MEMBERS);
export const loadUserGroupDetail = actionCreator<number>(LOAD_USER_GROUP_DETAIL);

export const SET_USER_GROUP_MEMBERS = 'SET_USER_GROUP_MEMBERS';
export const SET_USER_GROUP_DETAIL = 'SET_USER_GROUP_DETAIL';
export const setUserGroupMembers = actionCreator<GroupMember[]>(SET_USER_GROUP_MEMBERS);
export const setUserGroupDetail = actionCreator<UserGroupDetail>(SET_USER_GROUP_DETAIL);

export const REMOVE_MEMBER_FROM_USER_GROUP = 'REMOVE_MEMBER_FROM_USER_GROUP';
export const removeMemberFromUserGroup = actionCreator<{ ugId: number; userId: number }>(REMOVE_MEMBER_FROM_USER_GROUP);

export const REINVITE_MEMBER_FOR_USER_GROUP = 'REINVITE_MEMBER_FOR_USER_GROUP';
export const reinviteMemberForUserGroup = actionCreator<{ ugId: number; userId: number }>(
    REINVITE_MEMBER_FOR_USER_GROUP
);

export const REMOVE_INVITATION_FOR_USER_GROUP = 'REMOVE_INVITATION_FOR_USER_GROUP';
export const removeInvitationForUserGroup = actionCreator<{ ugId: number; userId: number }>(
    REMOVE_INVITATION_FOR_USER_GROUP
);

export const REFUSE_INVITATION_FOR_USER_GROUP = 'REFUSE_INVITATION_FOR_USER_GROUP';
export const refuseInvitationForUserGroup = actionCreator<{ ugId: number }>(REFUSE_INVITATION_FOR_USER_GROUP);

export const ACCEPT_INVITATION_FOR_USER_GROUP = 'ACCEPT_INVITATION_FOR_USER_GROUP';
export const acceptInvitationForUserGroup = actionCreator<{ ugId: number }>(ACCEPT_INVITATION_FOR_USER_GROUP);

export const LEAVE_USER_GROUP = 'LEAVE_USER_GROUP';
export const leaveUserGroup = actionCreator<{ ugId: number }>(LEAVE_USER_GROUP);

export const ACCEPT_MEMBERSHIP_REQUEST_FOR_USER_GROUP = 'ACCEPT_MEMBERSHIP_REQUEST_FOR_USER_GROUP';
export const acceptMembershipRequestForUserGroup = actionCreator<{ ugId: number; userId: number }>(
    ACCEPT_MEMBERSHIP_REQUEST_FOR_USER_GROUP
);

export const REFUSE_MEMBERSHIP_REQUEST_FOR_USER_GROUP = 'REFUSE_MEMBERSHIP_REQUEST_FOR_USER_GROUP';
export const refuseMembershipRequestForUserGroup = actionCreator<{ ugId: number; userId: number }>(
    REFUSE_MEMBERSHIP_REQUEST_FOR_USER_GROUP
);

export const CREATE_MEMBERSHIP_REQUEST_FOR_USER_GROUP = 'CREATE_MEMBERSHIP_REQUEST_FOR_USER_GROUP';
export const createMembershipRequestForUserGroup = actionCreator<{ ugId: number }>(
    CREATE_MEMBERSHIP_REQUEST_FOR_USER_GROUP
);

export const WITHDRAW_MEMBERSHIP_REQUEST_FOR_USER_GROUP = 'WITHDRAW_MEMBERSHIP_REQUEST_FOR_USER_GROUP';
export const withdrawMembershipRequest = actionCreator<{ ugId: number }>(WITHDRAW_MEMBERSHIP_REQUEST_FOR_USER_GROUP);

export const INVITE_USER_TO_USER_GROUP = 'INVITE_USER_TO_USER_GROUP';
export const inviteUserToUserGroup = actionCreator<{ ugId: number; userNameOrMail: string }>(INVITE_USER_TO_USER_GROUP);

export const REVOKE_ADMIN_PRIVILEGE = 'REVOKE_ADMIN_PRIVILEGE';
export const revokeAdminPrivilege = actionCreator<{ ugId: number; userId: number }>(REVOKE_ADMIN_PRIVILEGE);

export const GRANT_ADMIN_PRIVILEGE = 'GRANT_ADMIN_PRIVILEGE';
export const grantAdminPrivilege = actionCreator<{ ugId: number; userId: number }>(GRANT_ADMIN_PRIVILEGE);

export const USER_GROUP_UPDATE_DESCRIPTION = 'USER_GROUP_UPDATE_DESCRIPTION';
export const userGroupUpdateDescription = actionCreator<{ ugId: string; description: string }>(
    USER_GROUP_UPDATE_DESCRIPTION
);

export interface UserGroupImageAction {
    ugId: string;
    imageId: string;
}
export const USER_GROUP_ASSIGN_IMAGE = 'USER_GROUP_ASSIGN_IMAGE';
export const userGroupAssignImage = actionCreator<UserGroupImageAction>(USER_GROUP_ASSIGN_IMAGE);

export const USER_GROUP_DELETE_IMAGE = 'USER_GROUP_DELETE_IMAGE';
export const userGroupDeleteImage = actionCreator<string>(USER_GROUP_DELETE_IMAGE);

export const USER_GROUP_DELETE = 'USER_GROUP_DELETE';
export const userGroupDelete = actionCreator<string>(USER_GROUP_DELETE);

export const USER_GROUP_SET_INVITATION_STATE = 'USER_GROUP_SET_INVITATION_STATE';
export const userGroupSetInvitationState = actionCreator<GroupInvitationState>(USER_GROUP_SET_INVITATION_STATE);

export const USER_GROUP_LOAD_GROUP_MEMBER_CHANGES = 'USER_GROUP_LOAD_GROUP_MEMBER_CHANGES';
export const userGroupLoadGroupMemberChanges = actionCreator<string>(USER_GROUP_LOAD_GROUP_MEMBER_CHANGES);

export const USER_GROUP_SET_GROUP_MEMBER_CHANGES = 'USER_GROUP_SET_GROUP_MEMBER_CHANGES';
export const userGroupSetGroupMemberChanges = actionCreator<GroupMemberChange[]>(USER_GROUP_SET_GROUP_MEMBER_CHANGES);