import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Alert,
    Button,
    Col,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row
} from 'reactstrap';
import { HighscoreStrategyDescriptor } from '../../../services/betting';
import { ButtonWithConfirmation } from '../../common/ButtonWithConfirmation';
import { HighscoreStrategyDecriptorForm } from '../HighscoreStrategyDescriptorForm';
import { hsdEditChooseStandardDescriptor, hsdEditLoad, hsdEditUpdateCustomDescriptor } from './actions';
import { hsdEditAvailableEntitiesSelector, hsdEditEntitySelector, hsdEditUgNameSelector } from './selectors';

interface Props {
    ugId: string;
    readonly: boolean;

    onClose: () => void;
}

const UserGroupHighscoreDescriptorDialog = (props: Props) => {
    const hsd = useSelector(hsdEditEntitySelector);
    const ugName = useSelector(hsdEditUgNameSelector);
    const availableStrategies = useSelector(hsdEditAvailableEntitiesSelector);

    const dispatch = useDispatch();
    const onLoad = React.useCallback(
        (ugId: string) => {
            dispatch(hsdEditLoad(ugId));
        },
        [dispatch]
    );
    const onChooseStandardDescriptor = (ugId: string, hsdId: string) => {
        dispatch(hsdEditChooseStandardDescriptor({ ugId, hsdId }));
    };
    const onUpdateCustomDescriptor = (ugId: string, hsd: HighscoreStrategyDescriptor) => {
        dispatch(hsdEditUpdateCustomDescriptor({ ugId, hsd }));
    };

    const [standardStrategy, setStandardStrategy] = React.useState(true);
    const [selectedStandardStrategyId, setSelectedStandardStrategyId] = React.useState(0);
    const [standardStrategyDescription, setStandardStrategyDescription] = React.useState('');
    const [currentHsd, setCurrentHsd] = React.useState(hsd);
    const [isUpdated, setIsUpdated] = React.useState(false);

    const initializeStandardStrategyId = React.useCallback(() => {
        if (hsd && availableStrategies) {
            setStandardStrategy(hsd.standardStrategy);
            const id = hsd.id;
            let desc = availableStrategies.find(h => h.id === id);
            if (desc) {
                setSelectedStandardStrategyId(desc.id);
                setStandardStrategyDescription(desc.description);
            } else {
                setSelectedStandardStrategyId(0);
                setStandardStrategyDescription('');
            }
        } else {
            setSelectedStandardStrategyId(0);
            setStandardStrategyDescription('');
            setStandardStrategy(false);
        }
        setCurrentHsd(hsd);
    }, [hsd, availableStrategies]);

    const isHsdUpdated = React.useCallback(() => {
        if (!hsd || !currentHsd) {
            return;
        }

        const diff = Object.keys(hsd).filter(k => hsd !== undefined && (hsd as any)[k] !== (currentHsd as any)[k]);
        setIsUpdated(diff.length > 0 || hsd.standardStrategy !== standardStrategy);
    }, [hsd, currentHsd, standardStrategy]);

    React.useEffect(() => onLoad(props.ugId), [onLoad, props.ugId]);
    React.useEffect(() => initializeStandardStrategyId(), [initializeStandardStrategyId]);
    React.useEffect(() => isHsdUpdated(), [isHsdUpdated]);

    function showSelectedStandardDescription(hsdId: number) {
        if (!availableStrategies) {
            return;
        }
        let desc = availableStrategies.find(h => h.id === hsdId);
        if (!desc) {
            [desc] = availableStrategies;
        }
        setSelectedStandardStrategyId(hsdId);
        if (desc) {
            setStandardStrategyDescription(desc.description);
        } else {
            setStandardStrategyDescription('');
        }
    }

    function onSelectStandardDescriptor(e: React.FormEvent<HTMLInputElement>) {
        const hsdId = Number(e.currentTarget.value);

        showSelectedStandardDescription(hsdId);
    }

    function onSave() {
        if (hsd && currentHsd) {
            if (standardStrategy) {
                onChooseStandardDescriptor(props.ugId, String(selectedStandardStrategyId));
            } else {
                console.info('updating: ' + JSON.stringify(currentHsd));
                onUpdateCustomDescriptor(props.ugId, currentHsd);
            }
        }
        props.onClose();
    }

    function onHsdChange(hsd: HighscoreStrategyDescriptor) {
        setCurrentHsd(hsd);
    }

    return (
        <>
            <Modal isOpen={true} toggle={props.onClose} size="lg">
                <ModalHeader toggle={props.onClose}>Punktemodus von Tippteam '{ugName}'</ModalHeader>
                <ModalBody>
                    <div className="container-fluid p-3 border border-primary bg-light">
                        <Row>
                            <Col xs={12}>
                                <FormGroup tag="fieldset">
                                    <FormGroup check={true}>
                                        <Label check={true}>
                                            <Input
                                                type="radio"
                                                checked={standardStrategy}
                                                onChange={e => setStandardStrategy(e.currentTarget.checked)}
                                            />
                                            Standardmodus
                                            <Input
                                                type="select"
                                                disabled={!standardStrategy}
                                                value={selectedStandardStrategyId}
                                                onChange={onSelectStandardDescriptor}
                                            >
                                                <option value="0">[Standard]</option>
                                                {availableStrategies &&
                                                    availableStrategies.map(hsd => (
                                                        <option key={hsd.id} value={hsd.id}>
                                                            {hsd.name}
                                                        </option>
                                                    ))}
                                            </Input>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check={true} className="mt-2">
                                        <Label check={true}>
                                            <Input
                                                type="radio"
                                                checked={!standardStrategy}
                                                onChange={e => setStandardStrategy(!e.currentTarget.checked)}
                                            />
                                            Expertenmodus
                                        </Label>
                                    </FormGroup>
                                </FormGroup>
                            </Col>
                        </Row>
                        {hsd && standardStrategy && (
                            <Row>
                                <Col xs={12}>
                                    <Alert color="primary">{standardStrategyDescription}</Alert>
                                </Col>
                            </Row>
                        )}
                    </div>
                    {hsd && !standardStrategy && (
                        <HighscoreStrategyDecriptorForm readonly={props.readonly} hsd={hsd} onUpdateHsd={onHsdChange} />
                    )}
                </ModalBody>
                <ModalFooter>
                    {props.readonly && (
                        <Button color="primary" onClick={props.onClose}>
                            Schließen
                        </Button>
                    )}
                    {!props.readonly && (
                        <>
                            <ButtonWithConfirmation
                                title="Punktemodus ändern"
                                message={
                                    'Sind Sie sicher, dass Sie den Punktemodus ändern wollen. ' +
                                    'Die Punkte aller aktiven Turniere werden neu berechnet.'
                                }
                                color="primary"
                                onOK={onSave}
                                disabled={!isUpdated}
                            >
                                Speichern
                            </ButtonWithConfirmation>
                            <Button color="secondary" onClick={props.onClose}>
                                Abbrechen
                            </Button>
                        </>
                    )}
                </ModalFooter>
            </Modal>
        </>
    );
};

export const UserGroupHighscoreDescriptorDialogScreen = UserGroupHighscoreDescriptorDialog;
