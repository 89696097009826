/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.25
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  LoginToken,
  RestResult,
} from '../models/index';
import {
    LoginTokenFromJSON,
    LoginTokenToJSON,
    RestResultFromJSON,
    RestResultToJSON,
} from '../models/index';

export interface LoginRequest {
    tenant: string;
    login?: string;
    password?: string;
}

export interface LoginAsUserRequest {
    tenant: string;
    userId?: string;
}

export interface LoginByCredentialsRequest {
    tenant: string;
    login?: string;
    password?: string;
}

export interface PasswordForgottenRequest {
    tenant: string;
    email?: string;
}

/**
 * 
 */
export class Login2ControllerApi extends runtime.BaseAPI {

    /**
     */
    async loginRaw(requestParameters: LoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginToken>> {
        if (requestParameters['tenant'] == null) {
            throw new runtime.RequiredError(
                'tenant',
                'Required parameter "tenant" was null or undefined when calling login().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['login'] != null) {
            queryParameters['login'] = requestParameters['login'];
        }

        if (requestParameters['password'] != null) {
            queryParameters['password'] = requestParameters['password'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/login/{tenant}`.replace(`{${"tenant"}}`, encodeURIComponent(String(requestParameters['tenant']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginTokenFromJSON(jsonValue));
    }

    /**
     */
    async login(requestParameters: LoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginToken> {
        const response = await this.loginRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async loginAsUserRaw(requestParameters: LoginAsUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginToken>> {
        if (requestParameters['tenant'] == null) {
            throw new runtime.RequiredError(
                'tenant',
                'Required parameter "tenant" was null or undefined when calling loginAsUser().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['userId'] != null) {
            queryParameters['userId'] = requestParameters['userId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/login/{tenant}/login-as-user`.replace(`{${"tenant"}}`, encodeURIComponent(String(requestParameters['tenant']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginTokenFromJSON(jsonValue));
    }

    /**
     */
    async loginAsUser(requestParameters: LoginAsUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginToken> {
        const response = await this.loginAsUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async loginByCredentialsRaw(requestParameters: LoginByCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginToken>> {
        if (requestParameters['tenant'] == null) {
            throw new runtime.RequiredError(
                'tenant',
                'Required parameter "tenant" was null or undefined when calling loginByCredentials().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['login'] != null) {
            queryParameters['login'] = requestParameters['login'];
        }

        if (requestParameters['password'] != null) {
            queryParameters['password'] = requestParameters['password'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/login/{tenant}/credentials`.replace(`{${"tenant"}}`, encodeURIComponent(String(requestParameters['tenant']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginTokenFromJSON(jsonValue));
    }

    /**
     */
    async loginByCredentials(requestParameters: LoginByCredentialsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginToken> {
        const response = await this.loginByCredentialsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async passwordForgottenRaw(requestParameters: PasswordForgottenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult>> {
        if (requestParameters['tenant'] == null) {
            throw new runtime.RequiredError(
                'tenant',
                'Required parameter "tenant" was null or undefined when calling passwordForgotten().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/login/{tenant}/password-forgotten`.replace(`{${"tenant"}}`, encodeURIComponent(String(requestParameters['tenant']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResultFromJSON(jsonValue));
    }

    /**
     */
    async passwordForgotten(requestParameters: PasswordForgottenRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult> {
        const response = await this.passwordForgottenRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
