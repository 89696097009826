import actionCreatorFactory from 'typescript-fsa';
import { TournamentRoundType } from '../../reactutils/withTournamentRoundSelection';
import { Tournament, TournamentRound } from '../../services/betting';

const actionCreator = actionCreatorFactory();

export const TOURNAMENT_SELECTION_VERIFY_AND_ADJUST_SELECTED_TOURNAMENT_BY_TR_ID =
    'TOURNAMENT_SELECTION_VERIFY_AND_ADJUST_SELECTED_TOURNAMENT_BY_TR_ID';
export const tournamentSelectionVerifyAndAdjustSelectedTournamentByTrId = actionCreator<string>(
    TOURNAMENT_SELECTION_VERIFY_AND_ADJUST_SELECTED_TOURNAMENT_BY_TR_ID
);

export const TOURNAMENT_SELECTION_SET_AVAILABLE_TOURNAMENTS = 'TOURNAMENT_SELECTION_SET_AVAILABLE_TOURNAMENTS';
export const tournamentSelectionSetAvailableTournaments = actionCreator<Tournament[]>(
    TOURNAMENT_SELECTION_SET_AVAILABLE_TOURNAMENTS
);

export const TOURNAMENT_SELECTION_SET_SELECTED_TOURNAMENT_ID = 'TOURNAMENT_SELECTION_SET_SELECTED_TOURNAMENT_ID';
export const tournamentSelectionSetSelectedTournamentId = actionCreator<number>(
    TOURNAMENT_SELECTION_SET_SELECTED_TOURNAMENT_ID
);

export const TOURNAMENT_SELECTION_SET_SELECTED_TOURNAMENT_ID_IF_NOT_SET =
    'TOURNAMENT_SELECTION_SET_SELECTED_TOURNAMENT_ID_IF_NOT_SET';
export const tournamentSelectionSetSelectedTournamentIdIfNotSet = actionCreator<number>(
    TOURNAMENT_SELECTION_SET_SELECTED_TOURNAMENT_ID_IF_NOT_SET
);

export interface AvailableTournamentRounds {
    type: TournamentRoundType;
    rounds: TournamentRound[];
}

export interface TournamentRoundTypeWithId {
    type: TournamentRoundType;
    trId?: string;
}

export interface TournamentRoundTypeWithTournamentId {
    type: TournamentRoundType;
    tId: string;
}
export interface TournamentRoundChangeParams {
    type: TournamentRoundType;
    nextPath: (trId: string) => string;
}

export const TOURNAMENT_SELECTION_SET_AVAILABLE_TOURNAMENT_ROUNDS =
    'TOURNAMENT_SELECTION_SET_AVAILABLE_TOURNAMENT_ROUNDS';
export const tournamentSelectionSetAvailableTournamentRounds = actionCreator<AvailableTournamentRounds>(
    TOURNAMENT_SELECTION_SET_AVAILABLE_TOURNAMENT_ROUNDS
);

export const TOURNAMENT_SELECTION_SET_SELECTED_TOURNAMENT_ROUND_ID =
    'TOURNAMENT_SELECTION_SET_SELECTED_TOURNAMENT_ROUND_ID';
export const tournamentSelectionSetSelectedTournamentRoundId = actionCreator<TournamentRoundTypeWithId>(
    TOURNAMENT_SELECTION_SET_SELECTED_TOURNAMENT_ROUND_ID
);

export const TOURNAMENT_SELECTION_CHANGE_TOURNAMENT = 'TOURNAMENT_SELECTION_CHANGE_TOURNAMENT';
export const tournamentSelectionChangeTournament = actionCreator<number>(TOURNAMENT_SELECTION_CHANGE_TOURNAMENT);

export const TOURNAMENT_SELECTION_CHANGE_TOURNAMENT_ROUND = 'TOURNAMENT_SELECTION_CHANGE_TOURNAMENT_ROUND';
export const tournamentSelectionChangeTournamentRound = actionCreator<TournamentRoundTypeWithId>(
    TOURNAMENT_SELECTION_CHANGE_TOURNAMENT_ROUND
);

export const TOURNAMENT_SELECTION_SET_NEXT_TOURNAMENT_ROUND_ID = 'TOURNAMENT_SELECTION_SET_NEXT_TOURNAMENT_ROUND_ID';
export const tournamentSelectionSetNextTournamentRoundId = actionCreator<TournamentRoundTypeWithId>(
    TOURNAMENT_SELECTION_SET_NEXT_TOURNAMENT_ROUND_ID
);

export const TOURNAMENT_SELECTION_SET_PREVIOUS_TOURNAMENT_ROUND_ID =
    'TOURNAMENT_SELECTION_SET_PREVIOUS_TOURNAMENT_ROUND_ID';
export const tournamentSelectionSetPreviousTournamentRoundId = actionCreator<TournamentRoundTypeWithId>(
    TOURNAMENT_SELECTION_SET_PREVIOUS_TOURNAMENT_ROUND_ID
);

export const TOURNAMENT_SELECTION_NEXT_TOURNAMENT_ROUND_ID = 'TOURNAMENT_SELECTION_NEXT_TOURNAMENT_ROUND_ID';
export const tournamentSelectionNextTournamentRoundId = actionCreator<TournamentRoundChangeParams>(
    TOURNAMENT_SELECTION_NEXT_TOURNAMENT_ROUND_ID
);
export const TOURNAMENT_SELECTION_PREV_TOURNAMENT_ROUND_ID = 'TOURNAMENT_SELECTION_PREV_TOURNAMENT_ROUND_ID';
export const tournamentSelectionPrevTournamentRoundId = actionCreator<TournamentRoundChangeParams>(
    TOURNAMENT_SELECTION_PREV_TOURNAMENT_ROUND_ID
);

export const TOURNAMENT_SELECTION_LOAD_DEFAULT_TOURNAMENT = 'TOURNAMENT_SELECTION_LOAD_DEFAULT_TOURNAMENT';
export const tournamentSelectionLoadDefaultTournament = actionCreator(TOURNAMENT_SELECTION_LOAD_DEFAULT_TOURNAMENT);

export const TOURNAMENT_SELECTION_LOAD_AVAILABLE_TOURNAMENTS = 'TOURNAMENT_SELECTION_LOAD_AVAILABLE_TOURNAMENTS';
export const tournamentSelectionLoadAvailableTournaments = actionCreator(
    TOURNAMENT_SELECTION_LOAD_AVAILABLE_TOURNAMENTS
);

export const TOURNAMENT_SELECTION_LOAD_AVAILABLE_TOURNAMENT_ROUNDS =
    'TOURNAMENT_SELECTION_LOAD_AVAILABLE_TOURNAMENT_ROUNDS';
export const tournamentSelectionLoadAvailableTournamentRounds = actionCreator<TournamentRoundTypeWithTournamentId>(
    TOURNAMENT_SELECTION_LOAD_AVAILABLE_TOURNAMENT_ROUNDS
);

export const TOURNAMENT_SELECTION_TOURNAMENT_CHANGED = 'TOURNAMENT_SELECTION_TOURNAMENT_CHANGED';
export const tournamentSelectionTournamentChanged = actionCreator(TOURNAMENT_SELECTION_TOURNAMENT_CHANGED);

export const TOURNAMENT_SELECTION_ADD_TOURNAMENT_ID = 'TOURNAMENT_SELECTION_ADD_TOURNAMENT_ID';
export const tournamentSelectionAddTournamentId = actionCreator<string>(TOURNAMENT_SELECTION_ADD_TOURNAMENT_ID);

export const TOURNAMENT_SELECTION_ADD_AVAILABLE_TOURNAMENT = 'TOURNAMENT_SELECTION_ADD_AVAILABLE_TOURNAMENT';
export const tournamentSelectionAddAvailableTournament = actionCreator<Tournament>(
    TOURNAMENT_SELECTION_ADD_AVAILABLE_TOURNAMENT
);
