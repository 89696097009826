import { GlobalState } from '../../actions';

const myGroupHighscoreSelector = (state: GlobalState) => state.myGroupHighscores;

export const myGroupHighscoreRankSelector = (state: GlobalState) => myGroupHighscoreSelector(state).rank;
export const myGroupHighscoreTotalGroupsSelector = (state: GlobalState) =>
    myGroupHighscoreSelector(state).totalNumberOfGroups;
export const myGroupHighscoreUgIdSelector = (state: GlobalState) => myGroupHighscoreSelector(state).ugId;
export const myGroupHighscoreUgNameSelector = (state: GlobalState) => myGroupHighscoreSelector(state).userGroupName;
export const myGroupHighscoreUnreadChatCountSelector = (state: GlobalState) => myGroupHighscoreSelector(state).unreadTeamChatMessages;
