import React, { FC } from 'react';
import { GroupInternalHighscoresTop5 } from '../../BettingApp/GroupInternalHighscoresTop5';

interface Props {
    trId: string;
}

export const RadioEinsGroupHighscores: FC<Props> = props => {
    return (
        <>
            <GroupInternalHighscoresTop5
                trId={props.trId}
                ugId={'3548207'}
                mainUserId={'3893018'}
                maxUser={5}
                filterUserWithoutBets={true}
            />
        </>
    );
};
