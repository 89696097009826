import { push } from '@lagunovsky/redux-react-router';
import { all, put, takeLatest } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { getUserGroupAdminApi2, getUserGroupApi2 } from '../../components/System/systemConfig';
import { GroupMember, UserGroupDetail } from '../../services/betting';
import { fetchWrapperFunc } from '../../utils';
import { myProfileBettingPath } from '../UserProfileScreen';
import {
    ACCEPT_INVITATION_FOR_USER_GROUP,
    ACCEPT_MEMBERSHIP_REQUEST_FOR_USER_GROUP,
    CREATE_MEMBERSHIP_REQUEST_FOR_USER_GROUP,
    GRANT_ADMIN_PRIVILEGE,
    INVITE_USER_TO_USER_GROUP,
    LEAVE_USER_GROUP,
    loadUserGroupDetail,
    LOAD_USER_GROUP_DETAIL,
    LOAD_USER_GROUP_MEMBERS,
    REFUSE_INVITATION_FOR_USER_GROUP,
    REFUSE_MEMBERSHIP_REQUEST_FOR_USER_GROUP,
    REINVITE_MEMBER_FOR_USER_GROUP,
    REMOVE_INVITATION_FOR_USER_GROUP,
    REMOVE_MEMBER_FROM_USER_GROUP,
    REVOKE_ADMIN_PRIVILEGE,
    setUserGroupDetail,
    setUserGroupMembers,
    UserGroupImageAction,
    userGroupSetGroupMemberChanges,
    userGroupSetInvitationState,
    USER_GROUP_ASSIGN_IMAGE,
    USER_GROUP_DELETE,
    USER_GROUP_DELETE_IMAGE,
    USER_GROUP_LOAD_GROUP_MEMBER_CHANGES,
    USER_GROUP_UPDATE_DESCRIPTION,
    WITHDRAW_MEMBERSHIP_REQUEST_FOR_USER_GROUP,
} from './actions';

function* loadUserGroupMemberGenerator(action: Action<number>) {
    const userGroupController = getUserGroupApi2();
    const groupMembers: GroupMember[] = yield userGroupController.getGroupMembers({ugId: action.payload});
    yield put(setUserGroupMembers(groupMembers));
}
function* loadUserGroupDetailGenerator(action: Action<number>) {
    const userGroupController = getUserGroupApi2();
    yield* fetchWrapperFunc(
        () => userGroupController.getDetailOfUserGroup({ugId: action.payload}),
        [(r: UserGroupDetail) => setUserGroupDetail(r)]
    );
}
function* removeMemberFromUserGroupGenerator(action: Action<{ ugId: number; userId: number }>) {
    const userGroupAdminController = getUserGroupAdminApi2();
    const userGroupController = getUserGroupApi2();

    console.info('fetchWrapperFuncNoLoading: getGroupMembers - ' + action.payload.ugId);
    yield* fetchWrapperFunc(() => userGroupAdminController.removeMember({ugId: action.payload.ugId, uId: action.payload.userId}));

    yield* fetchWrapperFunc(
        () => userGroupController.getGroupMembers({ugId: action.payload.ugId}),
        [(r: GroupMember[]) => setUserGroupMembers(r)]
    );

    // yield* membershipReload(action, userGroupAdminController.removeMember);
}

function* reinviteMemberForUserGroupGenerator(action: Action<{ ugId: number; userId: number }>) {
    const userGroupAdminController = getUserGroupAdminApi2();
    const userGroupController = getUserGroupApi2();

    yield* fetchWrapperFunc(() => userGroupAdminController.reinviteUser({ugId: action.payload.ugId, uId: action.payload.userId}));

    yield* fetchWrapperFunc(
        () => userGroupController.getGroupMembers({ugId: action.payload.ugId}),
        [(r: GroupMember[]) => setUserGroupMembers(r)]
    );

    // yield* membershipReload(action, userGroupAdminController.reinviteUser);
}

function* removeInvitationForUserGroupGenerator(action: Action<{ ugId: number; userId: number }>) {
    const userGroupAdminController = getUserGroupAdminApi2();
    const userGroupController = getUserGroupApi2();

    yield* fetchWrapperFunc(() =>
        userGroupAdminController.removeInvitation({ugId: action.payload.ugId, uId: action.payload.userId})
    );

    yield* fetchWrapperFunc(
        () => userGroupController.getGroupMembers({ugId: action.payload.ugId}),
        [(r: GroupMember[]) => setUserGroupMembers(r)]
    );

    // yield* membershipReload(action, userGroupAdminController.removeInvitation);
}

function* refuseMembershipRequestForUserGroupGenerator(action: Action<{ ugId: number; userId: number }>) {
    const userGroupAdminController = getUserGroupAdminApi2();
    const userGroupController = getUserGroupApi2();
    yield* fetchWrapperFunc(() =>
        userGroupAdminController.refuseMembershipRequest({ugId: action.payload.ugId, uId: action.payload.userId})
    );

    yield* fetchWrapperFunc(
        () => userGroupController.getGroupMembers({ugId: action.payload.ugId}),
        [(r: GroupMember[]) => setUserGroupMembers(r)]
    );

    // yield* membershipReload(action, userGroupAdminController.refuseMembershipRequest);
}

function* createMembershipRequestForUserGroupGenerator(action: Action<{ ugId: number }>) {
    const userGroupController = getUserGroupApi2();

    yield* fetchWrapperFunc(() => userGroupController.createMembershipRequest({ugId: action.payload.ugId}));

    yield* fetchWrapperFunc(
        () => userGroupController.getGroupMembers({ugId: action.payload.ugId}),
        [(r: GroupMember[]) => setUserGroupMembers(r)]
    );

    yield* fetchWrapperFunc(
        () => userGroupController.getDetailOfUserGroup({ugId: action.payload.ugId}),
        [(r: UserGroupDetail) => setUserGroupDetail(r)]
    );

    // yield* membershipReload(action, userGroupController.createMembershipRequest);
}

function* withdrawMembershipRequestForUserGroupGenerator(action: Action<{ ugId: number }>) {
    const userGroupController = getUserGroupApi2();

    yield* fetchWrapperFunc(() => userGroupController.withdrawMembershipRequest({ugId: action.payload.ugId}));

    yield* fetchWrapperFunc(
        () => userGroupController.getGroupMembers({ugId: action.payload.ugId}),
        [(r: GroupMember[]) => setUserGroupMembers(r)]
    );

    yield* fetchWrapperFunc(
        () => userGroupController.getDetailOfUserGroup({ugId: action.payload.ugId}),
        [(r: UserGroupDetail) => setUserGroupDetail(r)]
    );

    // yield* membershipReload(action, userGroupController.createMembershipRequest);
}

function* inviteUserToUserGroupGenerator(action: Action<{ ugId: number; userNameOrMail: string }>) {
    const userGroupAdminController = getUserGroupAdminApi2();
    const userGroupController = getUserGroupApi2();

    yield* fetchWrapperFunc(
        () => userGroupAdminController.inviteUser({ugId: action.payload.ugId, userNameOrEmail: action.payload.userNameOrMail}),
        [
            (r) => {
                switch (r.message) {
                    case 'userNotFoundOrEmailInvalid':
                        return userGroupSetInvitationState({
                            state: 'failure',
                            message: 'Der Nutzer ist unbekannt und/oder die Email-Adresse ist ungültig.',
                        });
                    case 'userIsAlreadyMember':
                        return userGroupSetInvitationState({
                            state: 'failure',
                            message: 'Der eingeladene Nutzer ist bereits Mitglied des Tippteams.',
                        });
                    case 'confirmMembershipApplication':
                        return userGroupSetInvitationState({
                            state: 'success',
                            message: 'Der Nutzer ist jetzt Mitglied des Tippteams.',
                        });
                    case 'emailInvitationResent':
                        return userGroupSetInvitationState({
                            state: 'success',
                            message: 'Der Nutzer hat eine erneute Einladung per Email erhalten.',
                        });
                    case 'emailInvitationSent':
                        return userGroupSetInvitationState({
                            state: 'success',
                            message: 'Der Nutzer hat eine Einladung per Email erhalten.',
                        });
                    case 'invitationCreatedForExistingUser':
                        return userGroupSetInvitationState({
                            state: 'success',
                            message: 'Der Nutzer wurde eingeladen uns muss die Einladung jetzt noch annehmen.',
                        });
                }
                return userGroupSetInvitationState({ state: 'unknown', message: '' });
            },
        ]
    );

    yield* fetchWrapperFunc(
        () => userGroupController.getGroupMembers({ugId: action.payload.ugId}),
        [(r: GroupMember[]) => setUserGroupMembers(r)]
    );
}

function* revokeAdminPrivilegeGenerator(action: Action<{ ugId: number; userId: number }>) {
    const userGroupAdminController = getUserGroupAdminApi2();
    const userGroupController = getUserGroupApi2();

    yield* fetchWrapperFunc(() => userGroupAdminController.revokeAdminRole({ugId: action.payload.ugId, uId: action.payload.userId}));

    yield* fetchWrapperFunc(
        () => userGroupController.getGroupMembers({ugId: action.payload.ugId}),
        [(r: GroupMember[]) => setUserGroupMembers(r)]
    );
}
function* grantAdminPrivilegeGenerator(action: Action<{ ugId: number; userId: number }>) {
    const userGroupAdminController = getUserGroupAdminApi2();
    const userGroupController = getUserGroupApi2();

    yield* fetchWrapperFunc(() => userGroupAdminController.grantAdminRole({ugId: action.payload.ugId, uId: action.payload.userId}));

    yield* fetchWrapperFunc(
        () => userGroupController.getGroupMembers({ugId: action.payload.ugId}),
        [(r: GroupMember[]) => setUserGroupMembers(r)]
    );
}
function* userGroupUpdateDescriptionGenerator(action: Action<{ ugId: number; description: string }>) {
    const userGroupAdminController = getUserGroupAdminApi2();
    yield* fetchWrapperFunc(() =>
        userGroupAdminController.updateUserGroupDescription({ugId: action.payload.ugId, description: action.payload.description})
    );
    yield put(loadUserGroupDetail(action.payload.ugId));
}

/* ***
 * MEMBER API START
 * ***
 */

function* refuseInvitationForUserGroupGenerator(action: Action<{ ugId: number; userId: number }>) {
    const userGroupController = getUserGroupApi2();

    yield* fetchWrapperFunc(() => userGroupController.refuseUserGroupInvitation({ugId: action.payload.ugId}));

    yield* fetchWrapperFunc(
        () => userGroupController.getGroupMembers({ugId: action.payload.ugId}),
        [(r: GroupMember[]) => setUserGroupMembers(r)]
    );

    // yield* membershipReload(action, userGroupController.refuseInvitation);
}

function* acceptInvitationForUserGroupGenerator(action: Action<{ ugId: number; userId: number }>) {
    const userGroupController = getUserGroupApi2();

    yield* fetchWrapperFunc(() => userGroupController.acceptUserGroupInvitation({ugId: action.payload.ugId}));

    yield* fetchWrapperFunc(
        () => userGroupController.getGroupMembers({ugId: action.payload.ugId}),
        [(r: GroupMember[]) => setUserGroupMembers(r)]
    );

    // yield* membershipReload(action, userGroupController.refuseInvitation);
}

function* leaveUserGroupGenerator(action: Action<{ ugId: number; userId: number }>) {
    const userGroupController = getUserGroupApi2();

    yield* fetchWrapperFunc(() => userGroupController.leaveUserGroup({ugId: action.payload.ugId}));

    yield* fetchWrapperFunc(
        () => userGroupController.getGroupMembers({ugId: action.payload.ugId}),
        [(r: GroupMember[]) => setUserGroupMembers(r)]
    );

    yield* fetchWrapperFunc(
        () => userGroupController.getDetailOfUserGroup({ugId: action.payload.ugId}),
        [(r: UserGroupDetail) => setUserGroupDetail(r)]
    );

    // yield* membershipReload(action, userGroupController.refuseInvitation);
}

function* acceptMembershipRequestForUserGroupGenerator(action: Action<{ ugId: number; userId: number }>) {
    const userGroupAdminController = getUserGroupAdminApi2();
    const userGroupController = getUserGroupApi2();

    yield* fetchWrapperFunc(() =>
        userGroupAdminController.acceptMembershipRequest({ugId: action.payload.ugId, uId: action.payload.userId})
    );

    yield* fetchWrapperFunc(
        () => userGroupController.getGroupMembers({ugId: action.payload.ugId}),
        [(r: GroupMember[]) => setUserGroupMembers(r)]
    );

    // yield* membershipReload(action, userGroupAdminController.acceptMembershipRequest);
}

/*
// tslint:disable no-any
function* membershipReload(
    action: Action<{ ugId: number; userId: number }>,
    fun: (ugId: number, userId: number, options?: any) => Promise<any>
) {
    const header = getAuthorizationHeader();
    console.info('fetchWrapperFuncNoLoading: fun - ' + fun);
    yield * fetchWrapperFuncNoLoading(() => fun(action.payload.ugId, action.payload.userId, header));

    console.info('fetchWrapperFuncNoLoading: getGroupMembers - ' + action.payload.ugId);
    yield* fetchWrapperFuncNoLoading(() => userGroupAdminController.getGroupMembers(action.payload.ugId, header), [
        (r: GroupMember[]) => setUserGroupMembers(r)
    ]);
}
// tslint:ensable no-any
*/

function* watchForLoadLoadUserGroupMember() {
    yield takeLatest(LOAD_USER_GROUP_MEMBERS, loadUserGroupMemberGenerator);
}
function* watchForLoadLoadUserGroupDetail() {
    yield takeLatest(LOAD_USER_GROUP_DETAIL, loadUserGroupDetailGenerator);
}
function* watchForRemoveMemberFromUserGroup() {
    yield takeLatest(REMOVE_MEMBER_FROM_USER_GROUP, removeMemberFromUserGroupGenerator);
}
function* watchForReinviteMemberForUserGroup() {
    yield takeLatest(REINVITE_MEMBER_FOR_USER_GROUP, reinviteMemberForUserGroupGenerator);
}
function* watchForRemoveInvitationForUserGroup() {
    yield takeLatest(REMOVE_INVITATION_FOR_USER_GROUP, removeInvitationForUserGroupGenerator);
}
function* watchForRefuseInvitationForUserGroup() {
    yield takeLatest(REFUSE_INVITATION_FOR_USER_GROUP, refuseInvitationForUserGroupGenerator);
}
function* watchForAcceptInvitationForUserGroup() {
    yield takeLatest(ACCEPT_INVITATION_FOR_USER_GROUP, acceptInvitationForUserGroupGenerator);
}
function* watchForLeaveUserGroup() {
    yield takeLatest(LEAVE_USER_GROUP, leaveUserGroupGenerator);
}
function* watchForWithdrawMembershipRequestForUserGroup() {
    yield takeLatest(WITHDRAW_MEMBERSHIP_REQUEST_FOR_USER_GROUP, withdrawMembershipRequestForUserGroupGenerator);
}
function* watchForAcceptMembershipRequestForUserGroup() {
    yield takeLatest(ACCEPT_MEMBERSHIP_REQUEST_FOR_USER_GROUP, acceptMembershipRequestForUserGroupGenerator);
}
function* watchForRefuseMembershipRequestForUserGroup() {
    yield takeLatest(REFUSE_MEMBERSHIP_REQUEST_FOR_USER_GROUP, refuseMembershipRequestForUserGroupGenerator);
}
function* watchForCreateMembershipRequestForUserGroup() {
    yield takeLatest(CREATE_MEMBERSHIP_REQUEST_FOR_USER_GROUP, createMembershipRequestForUserGroupGenerator);
}
function* watchForInviteUserToUserGroup() {
    yield takeLatest(INVITE_USER_TO_USER_GROUP, inviteUserToUserGroupGenerator);
}
function* watchForRevokeAdminPrivilege() {
    yield takeLatest(REVOKE_ADMIN_PRIVILEGE, revokeAdminPrivilegeGenerator);
}
function* watchForGrantAdminPrivilege() {
    yield takeLatest(GRANT_ADMIN_PRIVILEGE, grantAdminPrivilegeGenerator);
}

function* userGroupAssignImageGenerator(action: Action<UserGroupImageAction>) {
    const api = getUserGroupAdminApi2();
    const ugId = Number(action.payload.ugId);
    const imageId = action.payload.imageId;
    yield* fetchWrapperFunc(() => api.setImage({ugId, imageId}), [(r) => loadUserGroupDetail(ugId)]);
}

function* userGroupDeleteImageGenerator(action: Action<string>) {
    const api = getUserGroupAdminApi2();
    const ugId = Number(action.payload);
    yield* fetchWrapperFunc(() => api.deleteImage({ugId}), [(r) => loadUserGroupDetail(ugId)]);
}

function* userGroupDeleteGenerator(action: Action<string>) {
    const api = getUserGroupAdminApi2();
    const ugId = Number(action.payload);
    yield* fetchWrapperFunc(() => api.deleteUserGroup({ugId}));
    yield put(push(myProfileBettingPath()));
}

function* userGroupLoadGroupMemberChangesGenerator(action: Action<string>) {
    const api = getUserGroupAdminApi2();
    const ugId = Number(action.payload);
    yield* fetchWrapperFunc(() => api.getGroupMemberChanges({ugId}), [(r) => userGroupSetGroupMemberChanges(r)]);
}

function* watchForUserGroupUpdateDescription() {
    yield takeLatest(USER_GROUP_UPDATE_DESCRIPTION, userGroupUpdateDescriptionGenerator);
    yield takeLatest(USER_GROUP_ASSIGN_IMAGE, userGroupAssignImageGenerator);
    yield takeLatest(USER_GROUP_DELETE_IMAGE, userGroupDeleteImageGenerator);
    yield takeLatest(USER_GROUP_DELETE, userGroupDeleteGenerator);
    yield takeLatest(USER_GROUP_LOAD_GROUP_MEMBER_CHANGES, userGroupLoadGroupMemberChangesGenerator);
}

export function* userGroupSagas() {
    yield all([
        watchForLoadLoadUserGroupMember(),
        watchForLoadLoadUserGroupDetail(),
        //admin api
        watchForRemoveMemberFromUserGroup(),
        watchForReinviteMemberForUserGroup(),
        watchForRemoveInvitationForUserGroup(),
        watchForAcceptMembershipRequestForUserGroup(),
        watchForRefuseMembershipRequestForUserGroup(),
        watchForCreateMembershipRequestForUserGroup(),
        watchForInviteUserToUserGroup(),
        watchForRevokeAdminPrivilege(),
        watchForGrantAdminPrivilege(),
        watchForUserGroupUpdateDescription(),
        //member api
        watchForRefuseInvitationForUserGroup(),
        watchForAcceptInvitationForUserGroup(),
        watchForLeaveUserGroup(),
        watchForWithdrawMembershipRequestForUserGroup(),
    ]);
}
