import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { sysAdminCachePath } from '../../SysAdminApp/sysAdminPathes'

export const PlaylistAdminFooter: FC<{}> = () => {

    return (
        <>
            <small>
                <Link to={sysAdminCachePath('betting')}>SysAdmin</Link>
            </small>
        </>
    )
}