import * as React from 'react';

class CursorRight extends React.Component {
    render() {
        return (
            <svg width="10px" height="16px" viewBox="0 0 10 16" version="1.1" >
                <title>icon_page_right_black</title>
                <desc>Created with Sketch.</desc>
                <g id="icon_page_right_black" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <polygon id="Fill-1-Copy" fill="#000000" points="1.908294 0 0 1.88666667 6.183412 8 0 14.1133333 1.908294 16 10 8"/>
                </g>
            </svg>
        );
    }
}

export default CursorRight;