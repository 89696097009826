import { useMutation, useQuery } from '@tanstack/react-query';
import { FC, useEffect, useState } from 'react';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getTournamentSysAdminApi } from '../../../../../System/systemConfig';
import { TournamentRoundType } from '../../../../../../services/betting';
import { useDispatch } from 'react-redux';
import { systemSetPopupMessage } from '../../../../../System/actions';

interface ModalProps {
    trId?: string;
    tournamentId: string;
    onClose: () => void;
}

export const TournamentRoundEditModal: FC<ModalProps> = (props) => {
    const [name, setName] = useState('');
    const [longName, setLongName] = useState('');
    const [parentId, setParentId] = useState('');
    const [roundType, setRoundType] = useState<TournamentRoundType>('node');
    const [order, setOrder] = useState(0);
    const [rankingType, setRankingType] = useState(3); // default to ALL_PRECEDING
    const [cupEligible, setCupEligible] = useState(false);

    const sysAdminApi = getTournamentSysAdminApi();
    const dispatch = useDispatch();

    const tournamentRoundQuery = useQuery({
        queryKey: ['tournamentRound.get', props.trId],
        queryFn: () => sysAdminApi.getTournamentRoundSysAdminById({ trId: props.trId! }),
        enabled: !!props.trId,
    });

    const nodeRoundsQuery = useQuery({
        queryKey: ['tournamentNodeRounds.get', props.tournamentId],
        queryFn: () => sysAdminApi.getTournamentNodeRounds({ tId: props.tournamentId }),
        enabled: !!props.tournamentId,
    });

    const updateMutation = useMutation({
        mutationFn: () =>
            sysAdminApi.updateTournamentRound({
                trId: props.trId!,
                name,
                longName,
                parentId,
                roundType,
                order,
                rankingType,
                cupEligible,
            }),
    });

    const createMutation = useMutation({
        mutationFn: () =>
            sysAdminApi.createTournamentRound({
                tId: props.tournamentId,
                name,
                longName,
                parentId,
                roundType,
                order,
                rankingType,
                cupEligible,
            }),
    });

    useEffect(() => {
        if (tournamentRoundQuery.data) {
            setName(tournamentRoundQuery.data.name ?? '');
            setLongName(tournamentRoundQuery.data.longName ?? '');
            setParentId(tournamentRoundQuery.data.parentId ?? '');
            setRoundType(tournamentRoundQuery.data.roundType ?? 'node');
            setOrder(tournamentRoundQuery.data.order ?? 0);
            setRankingType(tournamentRoundQuery.data.rankingType ?? 3);
            setCupEligible(tournamentRoundQuery.data.cupEligible ?? false);
        }
    }, [tournamentRoundQuery.data]);

    const onUpdate = () => {
        updateMutation.mutate(undefined, {
            onSuccess: () => {
                props.onClose();
            },
            onError: (error) => {
                dispatch(systemSetPopupMessage('Error updating round. ' + JSON.stringify(error)));
            },
        });
    };

    const onCreate = () => {
        createMutation.mutate(undefined, {
            onSuccess: () => {
                props.onClose();
            },
            onError: (error) => {
                dispatch(systemSetPopupMessage('Error creating round. ' + JSON.stringify(error)));
            },
        });
    };

    return (
        <Modal isOpen={true} toggle={props.onClose} size="xl">
            <ModalHeader toggle={props.onClose}>Edit Round</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label>Name</Label>
                    <Input type="text" value={name} onChange={(e) => setName(e.target.value)} />
                </FormGroup>
                <FormGroup>
                    <Label>Long Name</Label>
                    <Input type="text" value={longName} onChange={(e) => setLongName(e.target.value)} />
                </FormGroup>
                <FormGroup>
                    <Label>Parent Round</Label>
                    <select className="form-control" value={parentId} onChange={(e) => setParentId(e.target.value)}>
                        <option value="">[None]</option>
                        {nodeRoundsQuery.data
                            ?.filter((r) => r.id !== Number(props.trId))
                            .map((round) => (
                                <option key={round.id} value={round.id}>
                                    {round.name}
                                </option>
                            ))}
                    </select>
                </FormGroup>
                <FormGroup>
                    <Label>Round Type</Label>
                    <select
                        className="form-control"
                        value={roundType}
                        onChange={(e) => setRoundType(e.target.value as TournamentRoundType)}
                    >
                        <option value="node">Node</option>
                        <option value="leaf">Leaf</option>
                    </select>
                </FormGroup>
                <FormGroup>
                    <Label>Order</Label>
                    <Input type="number" value={order} onChange={(e) => setOrder(Number(e.target.value))} />
                </FormGroup>
                <FormGroup>
                    <Label>Ranking Type</Label>
                    <select
                        className="form-control"
                        value={rankingType}
                        onChange={(e) => setRankingType(Number(e.target.value))}
                    >
                        <option value="0">NONE</option>
                        <option value="1">ROUND_ONLY</option>
                        <option value="2">CHILDS_ONLY</option>
                        <option value="3">ALL_PRECEDING</option>
                    </select>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input
                            type="checkbox"
                            checked={cupEligible}
                            onChange={(e) => setCupEligible(e.target.checked)}
                        />
                        Cup Eligible
                    </Label>
                </FormGroup>{' '}
            </ModalBody>
            <ModalFooter>
                {props.trId && (
                    <Button color="success" onClick={onUpdate}>
                        Save
                    </Button>
                )}
                {!props.trId && (
                    <Button color="success" onClick={onCreate}>
                        Create
                    </Button>
                )}
                <Button onClick={props.onClose}>Close</Button>
            </ModalFooter>
        </Modal>
    );
};
