import { all, takeEvery } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { fetchWrapperFuncNoLoading } from '../../../utils';
import { getPortletApi2, getSystemConfig } from '../../System/systemConfig';
import { portletSet, PORTLET_LOAD } from './actions';

function* portletLoadGenerator(action: Action<string>) {
    const portletName = action.payload;

    yield* fetchWrapperFuncNoLoading(
        () => getPortletApi2().getRssFeed({ tenant: getSystemConfig().clientName, portletName }),
        [(r) => portletSet({ name: portletName, feed: r })],
    );
}

function* watchPortletActions() {
    yield takeEvery(PORTLET_LOAD, portletLoadGenerator);
}

export function* portletSagas() {
    yield all([watchPortletActions()]);
}
