/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MusicbrainzTrack } from './MusicbrainzTrack';
import {
    MusicbrainzTrackFromJSON,
    MusicbrainzTrackFromJSONTyped,
    MusicbrainzTrackToJSON,
} from './MusicbrainzTrack';

/**
 * 
 * @export
 * @interface MusicbrainzMedia
 */
export interface MusicbrainzMedia {
    /**
     * 
     * @type {string}
     * @memberof MusicbrainzMedia
     */
    format?: string;
    /**
     * 
     * @type {number}
     * @memberof MusicbrainzMedia
     */
    position?: number;
    /**
     * 
     * @type {Array<MusicbrainzTrack>}
     * @memberof MusicbrainzMedia
     */
    tracks: Array<MusicbrainzTrack>;
}

/**
 * Check if a given object implements the MusicbrainzMedia interface.
 */
export function instanceOfMusicbrainzMedia(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "tracks" in value;

    return isInstance;
}

export function MusicbrainzMediaFromJSON(json: any): MusicbrainzMedia {
    return MusicbrainzMediaFromJSONTyped(json, false);
}

export function MusicbrainzMediaFromJSONTyped(json: any, ignoreDiscriminator: boolean): MusicbrainzMedia {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'format': !exists(json, 'format') ? undefined : json['format'],
        'position': !exists(json, 'position') ? undefined : json['position'],
        'tracks': ((json['tracks'] as Array<any>).map(MusicbrainzTrackFromJSON)),
    };
}

export function MusicbrainzMediaToJSON(value?: MusicbrainzMedia | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'format': value.format,
        'position': value.position,
        'tracks': ((value.tracks as Array<any>).map(MusicbrainzTrackToJSON)),
    };
}

