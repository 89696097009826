import React from 'react'

import Footer from '../components/Footer';

export const withFooter = <P extends object>() => (Comp: React.ComponentType<P>): React.FC<P> => (props: P) => (
    <>
        <Comp {...props} />
        <Footer />
    </>
);
