import { all, put, takeLatest } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { fetchWrapperFunc, fetchWrapperFuncNoLoading } from '../../utils';
import { getBettingUserApi2, getProfile2Api, getSystemConfig } from '../System/systemConfig';
import { groupHighscoresLoadDefaultRound } from '../UserGroupHighscores/actions';
import {
    USER_VIEW_LOAD_BETTING,
    USER_VIEW_LOAD_PROFILE,
    userViewLoadProfile,
    userViewSetBetting,
    userViewSetBettingUser,
    userViewSetProfile,
    userViewSetUserId,
} from './actions';

function* loadProfileGenerator(action: Action<string>) {
    const userId = action.payload;
    yield put(userViewSetUserId(userId));
    yield* fetchWrapperFunc(
        () => getProfile2Api().getPublicUserProfile({tenant: getSystemConfig().clientName, userId}),
        [(r) => userViewSetProfile(r)],
    );
    yield* fetchWrapperFuncNoLoading(
        () => getBettingUserApi2().getBettingUserProfileById({userId}),
        [(r) => userViewSetBettingUser(r)],
    );
}

function* loadBettingGenerator(action: Action<string>) {
    const userId = action.payload;
    yield put(userViewSetUserId(userId));
    yield put(userViewLoadProfile(userId)); // load the basic profile anyway because we need id and name for display
    const profile = yield* fetchWrapperFunc(
        () => getBettingUserApi2().getPublicProfile({userId}),
        [(r) => userViewSetBetting(r)],
    );

    if (profile && profile.userGroups) {
        for (const ug of profile.userGroups) {
            yield put(groupHighscoresLoadDefaultRound(String(ug.id)));
        }
    }
}

function* watchForUserViewActions() {
    yield takeLatest(USER_VIEW_LOAD_PROFILE, loadProfileGenerator);
    yield takeLatest(USER_VIEW_LOAD_BETTING, loadBettingGenerator);
}

export function* userViewSagas() {
    yield all([watchForUserViewActions()]);
}
