import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { systemShowAutomaticLogoutMessage } from '../actions';
import { systemShowAutomaticLogoutMessageSelector } from '../selectors';

export const AutomaticLogoutMessage: FC<{}> = () => {
    const dispatch = useDispatch();
    const showAutomaticLogoutMessage = useSelector(systemShowAutomaticLogoutMessageSelector);

    const onClick = () => {
        dispatch(systemShowAutomaticLogoutMessage(false));
    };

    return (
        <>
            <Modal isOpen={showAutomaticLogoutMessage}>
                <ModalHeader>Abmeldung</ModalHeader>
                <ModalBody>
                    <p>Die Sitzung ist abgelaufen - Sie wurden automatisch abgemeldet.</p>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={onClick}>OK</Button>
                </ModalFooter>
            </Modal>
        </>
    );
};
