import { AnyAction, isType } from 'typescript-fsa';
import { GroupMember, GroupMemberChange, UserGroupDetail } from '../../services/betting';
import { setUserGroupDetail, setUserGroupMembers, userGroupSetGroupMemberChanges, userGroupSetInvitationState } from './actions';

export interface GroupInvitationState {
    state: 'success' | 'failure' | 'unknown';
    message: string;
}

export interface UserGroupState {
    groupMembers: GroupMember[];
    groupDetail?: UserGroupDetail;
    invitationState: GroupInvitationState;
    groupMemberChanges: GroupMemberChange[];
}

const initialUserGroupState: UserGroupState = {
    groupMembers: [],
    groupDetail: undefined,
    invitationState: {
        state: 'unknown',
        message: ''
    },
    groupMemberChanges: []
};

export function userGroupReducer(state: UserGroupState = initialUserGroupState, action: AnyAction): UserGroupState {
    if (isType(action, setUserGroupMembers)) {
        return Object.assign({}, state, { groupMembers: action.payload });
    } else if (isType(action, setUserGroupDetail)) {
        return Object.assign({}, state, { groupDetail: action.payload });
    } else if (isType(action, userGroupSetInvitationState)) {
        return Object.assign({}, state, { invitationState: action.payload });
    } else if (isType(action, userGroupSetGroupMemberChanges)) {
        return {...state, groupMemberChanges: action.payload };
    }

    return state;
}
