/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.25
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const UpdatePasswordResult = {
    Success: 'success',
    InvalidUser: 'invalidUser',
    WrongOldPassword: 'wrongOldPassword',
    InvalidNewPassword: 'invalidNewPassword'
} as const;
export type UpdatePasswordResult = typeof UpdatePasswordResult[keyof typeof UpdatePasswordResult];


export function instanceOfUpdatePasswordResult(value: any): boolean {
    for (const key in UpdatePasswordResult) {
        if (Object.prototype.hasOwnProperty.call(UpdatePasswordResult, key)) {
            if ((UpdatePasswordResult as Record<string, UpdatePasswordResult>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function UpdatePasswordResultFromJSON(json: any): UpdatePasswordResult {
    return UpdatePasswordResultFromJSONTyped(json, false);
}

export function UpdatePasswordResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePasswordResult {
    return json as UpdatePasswordResult;
}

export function UpdatePasswordResultToJSON(value?: UpdatePasswordResult | null): any {
    return value as any;
}

