import { all, select, takeLatest } from 'redux-saga/effects';
import { fetchWrapperFuncNoLoading } from '../../../../utils';
import { isLoggedInSelector } from '../../../System/selectors';
import { getBettingUserApi2 } from '../../../System/systemConfig';
import { notificationStatusSet, NOTIFICATION_STATUS_LOAD } from './actions';

function* loadGenerator() {
    const api = getBettingUserApi2();
    const isLoggedIn: boolean = yield select(isLoggedInSelector);
    if (isLoggedIn) {
        yield* fetchWrapperFuncNoLoading(() => api.getNotificationStatus(), [r => notificationStatusSet(r)]);
    }
}

function* watch() {
    yield takeLatest(NOTIFICATION_STATUS_LOAD, loadGenerator);
}

export function* notificationStatusSagas() {
    yield all([watch()]);
}
