import { GlobalState } from '../actions';

export const userGroupHighscoresSelector = (state: GlobalState) => state.groupHighscore;

export const userGroupHighscoresEntitiesSelector = (state: GlobalState) =>
    userGroupHighscoresSelector(state).highscores;

export const userGroupHighscoresCountSelector = (state: GlobalState) => userGroupHighscoresSelector(state).count;

export const userGroupHighscoresSearchStringSelector = (state: GlobalState) =>
    userGroupHighscoresSelector(state).searchString;

export const userGroupHighscoresCurrentPosSelector = (state: GlobalState) => userGroupHighscoresSelector(state).current;

export const userGroupHighscoresPageSizeSelector = (state: GlobalState) => userGroupHighscoresSelector(state).slotSize;

export const userGroupHighscoresTrIdSelector = (state: GlobalState) => userGroupHighscoresSelector(state).trId;
export const userGroupHighscoresDefaultTrSelector = (state: GlobalState) => userGroupHighscoresSelector(state).defaultHighscoreRounds;
export const userGroupHighscoresIsLoadingSelector = (state: GlobalState) => userGroupHighscoresSelector(state).isLoading;
