import actionCreatorFactory from 'typescript-fsa';
import { Category, Tournament } from '../../../services/betting';

const actionCreator = actionCreatorFactory();

export const TOURNAMENT_ARCHIV_LOAD = 'TOURNAMENT_ARCHIV_LOAD';
export const tournamentArchiveLoad = actionCreator(TOURNAMENT_ARCHIV_LOAD);

export const TOURNAMENT_ARCHIV_SET_TOURNAMENTS = 'TOURNAMENT_ARCHIV_SET_TOURNAMENTS';
export const tournamentArchiveSetTournaments = actionCreator<Tournament[]>(TOURNAMENT_ARCHIV_SET_TOURNAMENTS);

export const TOURNAMENT_ARCHIV_SET_CATEGORIES = 'TOURNAMENT_ARCHIV_SET_CATEGORIES';
export const tournamentArchiveSetCategories = actionCreator<Category[]>(TOURNAMENT_ARCHIV_SET_CATEGORIES);
