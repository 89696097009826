import { push } from '@lagunovsky/redux-react-router';
import { all, put, takeLatest } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { myProfileBettingPath } from '../../screens/UserProfileScreen';
import { fetchWrapperFuncNoLoading } from '../../utils';
import { groupInternalHighscorePath } from '../GroupInternalHighscores';
import { getUserGroupApi2 } from '../System/systemConfig';
import { GIHSF_LOAD_WITHOUT_PROPS, GIHSF_LOAD_WITH_PROPS } from './actions';

function* loadWithProps(action: Action<{ ugId: string }>) {
    const ugId = action.payload.ugId;
    yield* fetchWrapperFuncNoLoading(() => getUserGroupApi2().getDefaultRound({ugId}), [
        r => push(groupInternalHighscorePath(r, ugId))
    ]);
}

function* loadWithoutProps() {
    yield put(push(myProfileBettingPath()));
}

function* watch() {
    yield takeLatest(GIHSF_LOAD_WITHOUT_PROPS, loadWithoutProps);
    yield takeLatest(GIHSF_LOAD_WITH_PROPS, loadWithProps);
}

export function* groupInternalHighscoreForwarderSagas() {
    yield all([watch()]);
}
