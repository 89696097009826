/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CacheView,
} from '../models';
import {
    CacheViewFromJSON,
    CacheViewToJSON,
} from '../models';

export interface ClearCacheRequest {
    cacheId: string;
}

/**
 * 
 */
export class CacheControllerApi extends runtime.BaseAPI {

    /**
     */
    async clearAllCachesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CacheView>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admsys/cache/all`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CacheViewFromJSON));
    }

    /**
     */
    async clearAllCaches(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CacheView>> {
        const response = await this.clearAllCachesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async clearCacheRaw(requestParameters: ClearCacheRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CacheView>>> {
        if (requestParameters.cacheId === null || requestParameters.cacheId === undefined) {
            throw new runtime.RequiredError('cacheId','Required parameter requestParameters.cacheId was null or undefined when calling clearCache.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admsys/cache/{cacheId}`.replace(`{${"cacheId"}}`, encodeURIComponent(String(requestParameters.cacheId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CacheViewFromJSON));
    }

    /**
     */
    async clearCache(cacheId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CacheView>> {
        const response = await this.clearCacheRaw({ cacheId: cacheId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCacheViewsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CacheView>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admsys/cache/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CacheViewFromJSON));
    }

    /**
     */
    async getCacheViews(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CacheView>> {
        const response = await this.getCacheViewsRaw(initOverrides);
        return await response.value();
    }

}
