import { push } from '@lagunovsky/redux-react-router';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { ActionTokenParams } from '../';
import { Section, SectionHeading } from '../../../common/Section';
import { actionTokenCheckPassword } from '../actions';
import { actionTokenCheckPasswordResultSelector, actionTokenIsExecutedSelector } from '../selectors';

export const PasswordLostActionTokenHandler: FC<ActionTokenParams> = props => {
    const dispatch = useDispatch();

    const isExecuted = useSelector(actionTokenIsExecutedSelector);
    const isPasswordValid = useSelector(actionTokenCheckPasswordResultSelector);

    const [newPwd1, setNewPwd1] = useState('');
    const [newPwd2, setNewPwd2] = useState('');
    const [errorMsg, setErrorMsg] = useState('');

    const onCancel = () => {
        dispatch(push('/'));
    };

    useEffect(() => {
        if (!isPasswordValid) {
            setErrorMsg('Ungültiges Kennwort. Komplexität nicht ausreichend.');
        }
    }, [isExecuted, isPasswordValid]);

    const onExecute = () => {
        if (newPwd1 !== newPwd2) {
            setErrorMsg('Die Kennwörter sind nicht gleich.');
        } else {
            setErrorMsg('');
            if (props.atId && props.uId && props.secId) {
                dispatch(
                    actionTokenCheckPassword({ atId: props.atId, uId: props.uId, secId: props.secId, tag: newPwd1 })
                );
            }
        }
    };

    return (
        <Section>
            <SectionHeading>Kennwort zurücksetzen</SectionHeading>
            {!isExecuted && (
                <>
                    <p>Sie können hier ihr Kennwort zurücksetzen. Geben Sie dazu zweimal ihr neues Kennwort ein:</p>
                    {!isPasswordValid && <Alert color="danger">{errorMsg}</Alert>}
                    <Form>
                        <FormGroup>
                            <Label>neues Kennwort</Label>
                            <Input
                                type="text"
                                value={newPwd1}
                                onChange={e => setNewPwd1(e.currentTarget.value)}
                            ></Input>
                        </FormGroup>
                        <FormGroup>
                            <Label>neues Kennwort wiederholen</Label>
                            <Input
                                type="text"
                                value={newPwd2}
                                onChange={e => setNewPwd2(e.currentTarget.value)}
                            ></Input>
                        </FormGroup>
                        <Button color="danger" className="me-3" onClick={onExecute}>
                            Kennwort setzen
                        </Button>
                        <Button onClick={onCancel}>Abbrechen</Button>
                    </Form>
                </>
            )}
            {isExecuted && (
                <Alert color="info">
                    Das Kennwort wurde zurückgesetzt. Sie können sich jetzt mit dem neuen Kennwort anmelden.
                </Alert>
            )}
        </Section>
    );
};
