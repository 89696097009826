import { push } from '@lagunovsky/redux-react-router';
import * as R from 'ramda';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import { withBorder } from '../../../reactutils/withBorder';
import { withNavLocation } from '../../../reactutils/withNavLocation';
import { FuntipTitle } from '../../../reactutils/withTitle';
import { GameBet } from '../../../services/betting';
import { BetPoints } from '../../Betting/components/BetPoints';
import { usePathSegment } from '../../SysAdminApp/CacheStatisticsComponent';
import { TeamLink } from '../../TeamLink';
import { GameResult } from '../../TournamentApp/GameResult';
import { TournamentSelectionComponent } from '../../TournamentSelectionComponent';
import { tournamentSelectionVerifyAndAdjustSelectedTournamentByTrId } from '../../TournamentSelectionComponent/actions';
import { TournamentRoundSelectionComponent2 } from '../../TournamentSelectionComponent/TournamentRoundSelectionComponent';
import { bettingViewLoad } from './action';
import { bettingViewEntitySelector, bettingViewUserSelector } from './selectors';

export interface BettingViewRequestParams {
    trId: string;
    userId: string;
}

const BettingViewComponent: React.FC<{}> = () => {
    const gameBets = useSelector(bettingViewEntitySelector);
    const user = useSelector(bettingViewUserSelector);

    const [trId] = usePathSegment(3);
    const [userId] = usePathSegment(4);

    const dispatch = useDispatch();

    React.useEffect(() => {
        if (trId && userId) {
            dispatch(bettingViewLoad({ trId, userId }));
            dispatch(tournamentSelectionVerifyAndAdjustSelectedTournamentByTrId(trId));
        }
    }, [dispatch, trId, userId]);

    function renderGameBet(gb: GameBet) {
        return (
            <tr key={gb.gameId}>
                <td className="px-0 py-1 text-right align-middle">
                    <TeamLink teamId={gb.team1.id} withIcon />
                </td>
                <td className="px-0 py-1 text-left align-middle">
                    <TeamLink teamId={gb.team2.id} withIcon />
                </td>

                <td className="ps-0 pe-1 py-1 align-middle text-center">
                    <GameResult gameId={String(gb.gameId)} date={gb.date} />
                </td>
                <td className="ps-0 pe-1 py-1 align-middle text-center">
                    <span className="btn-r1--bet px-sm-3">
                        {gb.bet1}&nbsp;:&nbsp;{gb.bet2}
                    </span>
                </td>
                <td className="ps-0 pe-0 py-1 px-sm-2 align-middle text-center">
                    <BetPoints gameId={String(gb.gameId)} points={gb.points} showBestBets={gb.hasResult} />
                </td>
            </tr>
        );
    }

    return (
        <>
            <FuntipTitle
                title="Tipps von Nutzer"
                subTitle={user?.name || ''}
                leftComponent={
                    <TournamentSelectionComponent
                        onTournamentChange={(tId) => push(bettingViewPath('', user?.id || ''))}
                    />
                }
                rightComponent={
                    <TournamentRoundSelectionComponent2
                        trId={trId}
                        type="betting"
                        pathByTournamentRound={(trId) => bettingViewPath(trId, user?.id || '')}
                    />
                }
            />
            <Table>
                <thead>
                    <tr>
                        <th colSpan={2} className="col-md-6 col-sm-9 d-table-cell ps-1 pe-1">
                            BEGEGNUNG
                        </th>
                        <th className="col-sm-1 d-none d-md-table-cell">ERGEBNIS</th>
                        <th className="col-1 d-table-cell d-md-none text-center ps-1 pe-1">ERG</th>
                        <th className="col-sm-1 ps-1 pe-1 text-center">TIPP</th>
                        <th className="col-sm-1 d-none d-md-table-cell text-center">PUNKTE</th>
                        <th className="col-1 d-table-cell d-md-none text-center  px-1">
                            <span title="Punkte">P</span>
                        </th>
                    </tr>
                </thead>
                <tbody>{gameBets.map((gb) => renderGameBet(gb))}</tbody>
            </Table>
        </>
    );
};

export const bettingViewPath = (trId: string, userId: string) => `/b/betting-view/${trId}/${userId}`;

export const BettingViewScreen = R.compose(withBorder(), withNavLocation('betting'))(BettingViewComponent);

export const BettingViewLink = (props: BettingViewRequestParams & React.PropsWithChildren<{}>) => (
    <Link to={bettingViewPath(props.trId, props.userId)}>{props.children}</Link>
);
