import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Alert, Button, Col, Container, Form, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { HighscoreStrategyDescriptor } from '../../../services/betting';
import { HighscoreDescriptorFormValue } from './HighscoreDescriptorFormValue';

interface Props {
    hsd: HighscoreStrategyDescriptor;
    readonly: boolean;

    onUpdateHsd: (hsd: HighscoreStrategyDescriptor) => void;
}

export const HighscoreStrategyDecriptorForm = (props: Props) => {
    const [descriptor, setDescriptor] = React.useState(props.hsd);
    const [showHelp, setShowHelp] = React.useState(false);

    function handleChange(e: React.FormEvent<HTMLInputElement>) {
        const val: any = descriptor; // needed to access the property of descriptor by name
        if (e.currentTarget.type === 'checkbox') {
            setDescriptor({ ...descriptor, [e.currentTarget.name]: e.currentTarget.checked });
        } else if (typeof val[e.currentTarget.name] === 'number') {
            setDescriptor({ ...descriptor, [e.currentTarget.name]: Number(e.currentTarget.value) });
        } else {
            setDescriptor({ ...descriptor, [e.currentTarget.name]: e.currentTarget.value });
        }
    }

    React.useEffect(() => setDescriptor(props.hsd), [props.hsd]);

    const { onUpdateHsd } = props;
    React.useEffect(() => {
        onUpdateHsd(descriptor);
    }, [descriptor, onUpdateHsd]);

    return (
        <Container>
            <Form>
                <h5 className="mt-3">
                    Expertenmodus
                    <Button color="link" onClick={() => setShowHelp(!showHelp)}>
                        <FontAwesomeIcon icon={faInfoCircle} color="primary" />
                    </Button>
                </h5>
                <Row>
                    <Col xs={12}>
                        <FormGroup check={true}>
                            <Label check={true}>
                                <Input
                                    type="checkbox"
                                    name="distinctByHomeAway"
                                    checked={descriptor.distinctByHomeAway}
                                    onChange={handleChange}
                                    disabled={props.readonly}
                                />
                                Wird bei der Punktevergabe nach 'Heimsieg', 'Unentschieden' und 'Auswärtssieg'
                                unterscheiden?
                            </Label>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <FormGroup check={true}>
                            <Label check={true}>
                                <Input
                                    type="checkbox"
                                    name="distinctByQuotes"
                                    checked={descriptor.distinctByQuotes}
                                    onChange={handleChange}
                                    disabled={props.readonly}
                                />
                                Wird bei der Punktevergabe die Quote der abgegebenen Tipps berücksichtigt?
                            </Label>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <Table striped={true} className="mt-2">
                            <thead>
                                <tr>
                                    <th>&nbsp;</th>
                                    <th>Punkte für korrekte Tipptendenz</th>
                                    <th>Punkte für korrekte Tordifferenz</th>
                                    <th>Punkte für exakt richtiges Ergebnis</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{descriptor.distinctByHomeAway && 'Heimsieg'}</td>
                                    <td>
                                        <HighscoreDescriptorFormValue
                                            minValue={descriptor.homeWinTendencyMin}
                                            maxValue={descriptor.homeWinTendencyMax}
                                            useQuotes={descriptor.distinctByQuotes}
                                            name="homeWinTendency"
                                            handleChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <HighscoreDescriptorFormValue
                                            minValue={descriptor.homeWinDifferenceMin}
                                            maxValue={descriptor.homeWinDifferenceMax}
                                            useQuotes={descriptor.distinctByQuotes}
                                            name="homeWinDifference"
                                            handleChange={handleChange}
                                        />
                                    </td>
                                    <td>
                                        <HighscoreDescriptorFormValue
                                            minValue={descriptor.homeWinResultMin}
                                            maxValue={descriptor.homeWinResultMax}
                                            useQuotes={descriptor.distinctByQuotes}
                                            name="homeWinResult"
                                            handleChange={handleChange}
                                        />
                                    </td>
                                </tr>
                                {descriptor.distinctByHomeAway && (
                                    <>
                                        <tr>
                                            <td>Unentschieden</td>
                                            <td>
                                                <HighscoreDescriptorFormValue
                                                    minValue={descriptor.drawTendencyMin}
                                                    maxValue={descriptor.drawTendencyMax}
                                                    useQuotes={descriptor.distinctByQuotes}
                                                    name="drawTendency"
                                                    handleChange={handleChange}
                                                />
                                            </td>
                                            <td>&nbsp;</td>
                                            <td>
                                                <HighscoreDescriptorFormValue
                                                    minValue={descriptor.drawResultMin}
                                                    maxValue={descriptor.drawResultMax}
                                                    useQuotes={descriptor.distinctByQuotes}
                                                    name="drawResult"
                                                    handleChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Auswärtssieg</td>
                                            <td>
                                                <HighscoreDescriptorFormValue
                                                    minValue={descriptor.awayWinTendencyMin}
                                                    maxValue={descriptor.awayWinTendencyMax}
                                                    useQuotes={descriptor.distinctByQuotes}
                                                    name="awayWinTendency"
                                                    handleChange={handleChange}
                                                />
                                            </td>
                                            <td>
                                                <HighscoreDescriptorFormValue
                                                    minValue={descriptor.awayWinDifferenceMin}
                                                    maxValue={descriptor.awayWinDifferenceMax}
                                                    useQuotes={descriptor.distinctByQuotes}
                                                    name="awayWinDifference"
                                                    handleChange={handleChange}
                                                />
                                            </td>
                                            <td>
                                                <HighscoreDescriptorFormValue
                                                    minValue={descriptor.awayWinResultMin}
                                                    maxValue={descriptor.awayWinResultMax}
                                                    useQuotes={descriptor.distinctByQuotes}
                                                    name="awayWinResult"
                                                    handleChange={handleChange}
                                                />
                                            </td>
                                        </tr>
                                    </>
                                )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <FormGroup>
                            <Label for="textarea">Beschreibung des Expertenmodus</Label>
                            <Input
                                id="textarea"
                                type="textarea"
                                name="description"
                                value={descriptor.description}
                                onChange={handleChange}
                                disabled={props.readonly}
                            />
                        </FormGroup>
                    </Col>
                </Row>
                {showHelp && (
                    <Alert color="primary">
                        <p>
                            <b>Wichtiger Hinweis:</b> Die Gesamtpunktzahl je Spiel ergibt sich aus der Summe der Punkte
                            für 'korrekte Tipptendenz', 'richtige Tordifferenz' und 'exaktes Ergebnis'!
                        </p>
                        <p>
                            <b>Quote:</b> Die erreichbaren Punkte richten sich nach der Tippquote, d.h. für
                            unwahrscheinliche Ergebnisse erhalten sie mehr Punkte als für Favoritentipps. Die Quote für
                            jedes Spiel wird bei der Tippabgabe angezeigt. Mit den Werten 'Min' und 'Max' legen sie fest
                            wie viele Punkte sie mit einen richtigen Tipp unabhängig von der Quote mindestens bzw.
                            maximal erhalten.
                        </p>
                    </Alert>
                )}
            </Form>
        </Container>
    );
};
