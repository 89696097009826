/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.34
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GroupHighscore,
  GroupInternalHighscore,
  GroupTournamentScore,
  MyGroupHighscore,
  UserGroupDefaultHighscoreRound,
} from '../models/index';
import {
    GroupHighscoreFromJSON,
    GroupHighscoreToJSON,
    GroupInternalHighscoreFromJSON,
    GroupInternalHighscoreToJSON,
    GroupTournamentScoreFromJSON,
    GroupTournamentScoreToJSON,
    MyGroupHighscoreFromJSON,
    MyGroupHighscoreToJSON,
    UserGroupDefaultHighscoreRoundFromJSON,
    UserGroupDefaultHighscoreRoundToJSON,
} from '../models/index';

export interface GetDefaultHighscoreRoundRequest {
    ugId: string;
}

export interface GetGroupHighscoreCountRequest {
    clientName: string;
    trId: number;
    q: string;
}

export interface GetGroupHighscoresRequest {
    clientName: string;
    slotSize: number;
    startPos: number;
    trId: number;
    q: string;
}

export interface GetGroupInternalHighscoresRequest {
    clientName: string;
    trId: number;
    ugId: number;
}

export interface GetGroupTournamentRootHighscoresRequest {
    clientName: string;
    ugId: number;
}

export interface GetMainUserGroupParticipatingWithHighscoresRequest {
    tId: string;
}

export interface GetMyGroupHighscoresRequest {
    tId: string;
}

/**
 * 
 */
export class GroupHighscoreControllerApi extends runtime.BaseAPI {

    /**
     */
    async getDefaultHighscoreRoundRaw(requestParameters: GetDefaultHighscoreRoundRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserGroupDefaultHighscoreRound>> {
        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling getDefaultHighscoreRound().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/group-highscore/default-highscore-round/{ugId}`.replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserGroupDefaultHighscoreRoundFromJSON(jsonValue));
    }

    /**
     */
    async getDefaultHighscoreRound(requestParameters: GetDefaultHighscoreRoundRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserGroupDefaultHighscoreRound> {
        const response = await this.getDefaultHighscoreRoundRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getGroupHighscoreCountRaw(requestParameters: GetGroupHighscoreCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['clientName'] == null) {
            throw new runtime.RequiredError(
                'clientName',
                'Required parameter "clientName" was null or undefined when calling getGroupHighscoreCount().'
            );
        }

        if (requestParameters['trId'] == null) {
            throw new runtime.RequiredError(
                'trId',
                'Required parameter "trId" was null or undefined when calling getGroupHighscoreCount().'
            );
        }

        if (requestParameters['q'] == null) {
            throw new runtime.RequiredError(
                'q',
                'Required parameter "q" was null or undefined when calling getGroupHighscoreCount().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/group-highscore/{clientName}/{trId}/count`.replace(`{${"clientName"}}`, encodeURIComponent(String(requestParameters['clientName']))).replace(`{${"trId"}}`, encodeURIComponent(String(requestParameters['trId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async getGroupHighscoreCount(requestParameters: GetGroupHighscoreCountRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.getGroupHighscoreCountRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getGroupHighscoresRaw(requestParameters: GetGroupHighscoresRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GroupHighscore>>> {
        if (requestParameters['clientName'] == null) {
            throw new runtime.RequiredError(
                'clientName',
                'Required parameter "clientName" was null or undefined when calling getGroupHighscores().'
            );
        }

        if (requestParameters['slotSize'] == null) {
            throw new runtime.RequiredError(
                'slotSize',
                'Required parameter "slotSize" was null or undefined when calling getGroupHighscores().'
            );
        }

        if (requestParameters['startPos'] == null) {
            throw new runtime.RequiredError(
                'startPos',
                'Required parameter "startPos" was null or undefined when calling getGroupHighscores().'
            );
        }

        if (requestParameters['trId'] == null) {
            throw new runtime.RequiredError(
                'trId',
                'Required parameter "trId" was null or undefined when calling getGroupHighscores().'
            );
        }

        if (requestParameters['q'] == null) {
            throw new runtime.RequiredError(
                'q',
                'Required parameter "q" was null or undefined when calling getGroupHighscores().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/group-highscore/{clientName}/{trId}/{startPos}/{slotSize}`.replace(`{${"clientName"}}`, encodeURIComponent(String(requestParameters['clientName']))).replace(`{${"slotSize"}}`, encodeURIComponent(String(requestParameters['slotSize']))).replace(`{${"startPos"}}`, encodeURIComponent(String(requestParameters['startPos']))).replace(`{${"trId"}}`, encodeURIComponent(String(requestParameters['trId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GroupHighscoreFromJSON));
    }

    /**
     */
    async getGroupHighscores(requestParameters: GetGroupHighscoresRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GroupHighscore>> {
        const response = await this.getGroupHighscoresRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getGroupInternalHighscoresRaw(requestParameters: GetGroupInternalHighscoresRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupInternalHighscore>> {
        if (requestParameters['clientName'] == null) {
            throw new runtime.RequiredError(
                'clientName',
                'Required parameter "clientName" was null or undefined when calling getGroupInternalHighscores().'
            );
        }

        if (requestParameters['trId'] == null) {
            throw new runtime.RequiredError(
                'trId',
                'Required parameter "trId" was null or undefined when calling getGroupInternalHighscores().'
            );
        }

        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling getGroupInternalHighscores().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/group-highscore/{clientName}/{trId}/user-group/{ugId}`.replace(`{${"clientName"}}`, encodeURIComponent(String(requestParameters['clientName']))).replace(`{${"trId"}}`, encodeURIComponent(String(requestParameters['trId']))).replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupInternalHighscoreFromJSON(jsonValue));
    }

    /**
     */
    async getGroupInternalHighscores(requestParameters: GetGroupInternalHighscoresRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupInternalHighscore> {
        const response = await this.getGroupInternalHighscoresRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getGroupTournamentRootHighscoresRaw(requestParameters: GetGroupTournamentRootHighscoresRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GroupTournamentScore>>> {
        if (requestParameters['clientName'] == null) {
            throw new runtime.RequiredError(
                'clientName',
                'Required parameter "clientName" was null or undefined when calling getGroupTournamentRootHighscores().'
            );
        }

        if (requestParameters['ugId'] == null) {
            throw new runtime.RequiredError(
                'ugId',
                'Required parameter "ugId" was null or undefined when calling getGroupTournamentRootHighscores().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/group-highscore/{clientName}/roots/user-group/{ugId}`.replace(`{${"clientName"}}`, encodeURIComponent(String(requestParameters['clientName']))).replace(`{${"ugId"}}`, encodeURIComponent(String(requestParameters['ugId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GroupTournamentScoreFromJSON));
    }

    /**
     */
    async getGroupTournamentRootHighscores(requestParameters: GetGroupTournamentRootHighscoresRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GroupTournamentScore>> {
        const response = await this.getGroupTournamentRootHighscoresRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMainUserGroupParticipatingWithHighscoresRaw(requestParameters: GetMainUserGroupParticipatingWithHighscoresRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupHighscore>> {
        if (requestParameters['tId'] == null) {
            throw new runtime.RequiredError(
                'tId',
                'Required parameter "tId" was null or undefined when calling getMainUserGroupParticipatingWithHighscores().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/group-highscore/main-participating-with-highscores/{tId}`.replace(`{${"tId"}}`, encodeURIComponent(String(requestParameters['tId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupHighscoreFromJSON(jsonValue));
    }

    /**
     */
    async getMainUserGroupParticipatingWithHighscores(requestParameters: GetMainUserGroupParticipatingWithHighscoresRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupHighscore> {
        const response = await this.getMainUserGroupParticipatingWithHighscoresRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMyGroupHighscoresRaw(requestParameters: GetMyGroupHighscoresRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MyGroupHighscore>> {
        if (requestParameters['tId'] == null) {
            throw new runtime.RequiredError(
                'tId',
                'Required parameter "tId" was null or undefined when calling getMyGroupHighscores().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/group-highscore/my/{tId}`.replace(`{${"tId"}}`, encodeURIComponent(String(requestParameters['tId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MyGroupHighscoreFromJSON(jsonValue));
    }

    /**
     */
    async getMyGroupHighscores(requestParameters: GetMyGroupHighscoresRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MyGroupHighscore> {
        const response = await this.getMyGroupHighscoresRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async nullPointerExceptionRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/group-highscore/npe`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async nullPointerException(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.nullPointerExceptionRaw(initOverrides);
        return await response.value();
    }

}
