import actionCreatorFactory from 'typescript-fsa';
import { Device, UserProfile, UserProfileContactData } from '../../../services/account';

const actionCreator = actionCreatorFactory();

export const USER_PROFILE_LOAD = 'USER_PROFILE_LOAD';
export const userProfileLoad = actionCreator(USER_PROFILE_LOAD);

export const USER_PROFILE_SET = 'USER_PROFILE_SET';
export const userProfileSet = actionCreator<UserProfile>(USER_PROFILE_SET);

export const USER_PROFILE_SET_CONTACT_DATA = 'USER_PROFILE_SET_CONTACT_DATA';
export const userProfileSetContactData = actionCreator<UserProfileContactData>(USER_PROFILE_SET_CONTACT_DATA);

export const USER_PROFILE_SET_AVAILABLE_COUNTRIES = 'USER_PROFILE_SET_AVAILABLE_COUNTRIES';
export const userProfileSetAvailableCountries = actionCreator<string[]>(USER_PROFILE_SET_AVAILABLE_COUNTRIES);

export const USER_PROFILE_SAVE_CONTACT_DATA = 'USER_PROFILE_SAVE_CONTACT_DATA';
export const userProfileSaveContactData = actionCreator<UserProfileContactData>(USER_PROFILE_SAVE_CONTACT_DATA);

export const USER_PROFILE_SAVE = 'USER_PROFILE_SAVE';
export const userProfileSave = actionCreator<UserProfile>(USER_PROFILE_SAVE);

export const USER_PROFILE_LOAD_DEVICES = 'USER_PROFILE_LOAD_DEVICES';
export const userProfileLoadDevices = actionCreator(USER_PROFILE_LOAD_DEVICES);

export const USER_PROFILE_SET_DEVICES = 'USER_PROFILE_SET_DEVICES';
export const userProfileSetDevices = actionCreator<Device[]>(USER_PROFILE_SET_DEVICES);

export const USER_PROFILE_DELETE_DEVICE = 'USER_PROFILE_DELETE_DEVICE';
export const userProfileDeleteDevice = actionCreator<string>(USER_PROFILE_DELETE_DEVICE);

export const USER_PROFILE_ASSIGN_IMAGE = 'USER_PROFILE_ASSIGN_IMAGE';
export const userProfileAssignImage = actionCreator<string>(USER_PROFILE_ASSIGN_IMAGE);

export const USER_PROFILE_DELETE_IMAGE = 'USER_PROFILE_DELETE_IMAGE';
export const userProfileDeleteImage = actionCreator(USER_PROFILE_DELETE_IMAGE);
