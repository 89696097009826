/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.25
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ClientProperty,
  RestResult,
} from '../models/index';
import {
    ClientPropertyFromJSON,
    ClientPropertyToJSON,
    RestResultFromJSON,
    RestResultToJSON,
} from '../models/index';

export interface GetByIdRequest {
    clientId: string;
    name: string;
}

export interface UpdateRequest {
    clientId: string;
    name: string;
    createNewVersion: boolean;
    daysValid: string;
    value: string;
    languageId?: string;
}

/**
 * 
 */
export class ClientProperty2ControllerApi extends runtime.BaseAPI {

    /**
     */
    async getAllUniqueRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ClientProperty>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sys/client-properties`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ClientPropertyFromJSON));
    }

    /**
     */
    async getAllUnique(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ClientProperty>> {
        const response = await this.getAllUniqueRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getAvailablePropertyNamesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sys/client-properties/available-property-names`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getAvailablePropertyNames(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getAvailablePropertyNamesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getByIdRaw(requestParameters: GetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ClientProperty>> {
        if (requestParameters['clientId'] == null) {
            throw new runtime.RequiredError(
                'clientId',
                'Required parameter "clientId" was null or undefined when calling getById().'
            );
        }

        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling getById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sys/client-properties/{clientId}/{name}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters['clientId']))).replace(`{${"name"}}`, encodeURIComponent(String(requestParameters['name']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ClientPropertyFromJSON(jsonValue));
    }

    /**
     */
    async getById(requestParameters: GetByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ClientProperty> {
        const response = await this.getByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateRaw(requestParameters: UpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult>> {
        if (requestParameters['clientId'] == null) {
            throw new runtime.RequiredError(
                'clientId',
                'Required parameter "clientId" was null or undefined when calling update().'
            );
        }

        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling update().'
            );
        }

        if (requestParameters['createNewVersion'] == null) {
            throw new runtime.RequiredError(
                'createNewVersion',
                'Required parameter "createNewVersion" was null or undefined when calling update().'
            );
        }

        if (requestParameters['daysValid'] == null) {
            throw new runtime.RequiredError(
                'daysValid',
                'Required parameter "daysValid" was null or undefined when calling update().'
            );
        }

        if (requestParameters['value'] == null) {
            throw new runtime.RequiredError(
                'value',
                'Required parameter "value" was null or undefined when calling update().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['createNewVersion'] != null) {
            queryParameters['createNewVersion'] = requestParameters['createNewVersion'];
        }

        if (requestParameters['daysValid'] != null) {
            queryParameters['daysValid'] = requestParameters['daysValid'];
        }

        if (requestParameters['languageId'] != null) {
            queryParameters['languageId'] = requestParameters['languageId'];
        }

        if (requestParameters['value'] != null) {
            queryParameters['value'] = requestParameters['value'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/sys/client-properties/{clientId}/{name}`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters['clientId']))).replace(`{${"name"}}`, encodeURIComponent(String(requestParameters['name']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResultFromJSON(jsonValue));
    }

    /**
     */
    async update(requestParameters: UpdateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult> {
        const response = await this.updateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
