import * as R from 'ramda';
import React, { FC } from 'react';
import { Route, Routes } from 'react-router';
import { withFooter } from '../../reactutils/withFooter';
import { BlogLatestScreen, BlogScreen } from './Blog';
import { BlogList } from './BlogList';

interface Props {}

const BloggingAppComponent: FC<Props> = props => {
    return (
        <>
            <Routes>
                <Route path="list"  element={<BlogList />} />
                <Route path=":blogId"  element={<BlogScreen />} />
                <Route path="latest" element={<BlogLatestScreen />} />
            </Routes>
        </>
    );
};

export const BloggingApp = R.compose(withFooter<Props>())(BloggingAppComponent);
