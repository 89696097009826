/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Job
 */
export interface Job {
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    jobName: string;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    triggerName: string;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    triggerGroup: string;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    jobDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    triggerState: string;
    /**
     * 
     * @type {Date}
     * @memberof Job
     */
    nextFireTime: Date;
    /**
     * 
     * @type {string}
     * @memberof Job
     */
    cronExpression?: string;
}

/**
 * Check if a given object implements the Job interface.
 */
export function instanceOfJob(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "jobName" in value;
    isInstance = isInstance && "triggerName" in value;
    isInstance = isInstance && "triggerGroup" in value;
    isInstance = isInstance && "triggerState" in value;
    isInstance = isInstance && "nextFireTime" in value;

    return isInstance;
}

export function JobFromJSON(json: any): Job {
    return JobFromJSONTyped(json, false);
}

export function JobFromJSONTyped(json: any, ignoreDiscriminator: boolean): Job {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'jobName': json['jobName'],
        'triggerName': json['triggerName'],
        'triggerGroup': json['triggerGroup'],
        'jobDescription': !exists(json, 'jobDescription') ? undefined : json['jobDescription'],
        'triggerState': json['triggerState'],
        'nextFireTime': (new Date(json['nextFireTime'])),
        'cronExpression': !exists(json, 'cronExpression') ? undefined : json['cronExpression'],
    };
}

export function JobToJSON(value?: Job | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'jobName': value.jobName,
        'triggerName': value.triggerName,
        'triggerGroup': value.triggerGroup,
        'jobDescription': value.jobDescription,
        'triggerState': value.triggerState,
        'nextFireTime': (value.nextFireTime.toISOString()),
        'cronExpression': value.cronExpression,
    };
}

