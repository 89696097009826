import { useMutation } from '@tanstack/react-query';
import { FC, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button } from 'reactstrap';
import { UserProfileSettings } from '../../../services/account';
import { isValidEmail } from '../../../utils';
import { systemSetPopupMessage } from '../../System/actions';
import { getProfile2Api } from '../../System/systemConfig';
import { accountUserSettingsProfileSettingsSelector } from '../AccountUserSettings/selectors';

export const showTeaserByProfile = (profileSettings: UserProfileSettings): boolean => {
    return profileSettings && isValidEmail(profileSettings.emailToConfirm || '');
};

export const EmailConfirmationTeaser: FC<{}> = () => {
    const profileSettings = useSelector(accountUserSettingsProfileSettingsSelector);
    const emailToConfirm = profileSettings.emailToConfirm;

    const dispatch = useDispatch();

    const [emailSent, setEmailSent] = useState(false);

    const showTeaser = useCallback(() => {
        return showTeaserByProfile(profileSettings);
    }, [profileSettings]);

    const resendEmailConfirmationMutation = useMutation({
        mutationFn: () => getProfile2Api().resendEmailConfirmation(),
    });

    const onResendEmailConfirmation = () => {
        resendEmailConfirmationMutation.mutate(undefined, {
            onSuccess: () => {
                dispatch(systemSetPopupMessage('Bestätigungslink wurde erneut versendet.'));
                setEmailSent(true);
            },
            onError: (error) => {
                dispatch(systemSetPopupMessage('Fehler beim Versenden des Bestätigungslinks. ' + error));
            },
        });
    };

    if (showTeaser()) {
        return (
            <>
                <Alert className="mt-2">
                    <h3>Email-Adresse noch nicht bestätigt</h3>
                    <p>
                        Sie haben Ihre Email-Adresse '{emailToConfirm}' noch nicht bestätigt. Dafür haben Sie eine Email
                        erhalten, die einen Bestätigungs-Link enthält.
                        <br />
                        Sie können sich die Bestätigungs-Email nochmals zuschicken lassen.
                    </p>
                    <hr />
                    <Button color="warning" className="my-1" onClick={() => onResendEmailConfirmation()}>
                        Bestätigungs-Email nochmals senden
                    </Button>
                    {emailSent && (
                        <p className="mt-2">
                            <b>Die Bestätigungs-Email wurde erneut an ihre Email gesendet.</b>
                        </p>
                    )}
                </Alert>
            </>
        );
    } else {
        return null;
    }
};
