import { AnyAction, isType } from 'typescript-fsa';
import { Game, MatchStatistic } from '../../../services/betting';
import { matchStatisticGamesSet, matchStatisticSet, matchStatisticGamesSetGameCount, matchStatisticGamesSetCurrent } from './actions';

export interface MatchStatisticState {
    stats?: MatchStatistic;
    games: Game[];
    currentOffset: number;
    count: number;
    gameCount: number;
}

const initialMatchStatisticState: MatchStatisticState = {
    games: [],
    currentOffset: 0,
    gameCount: 0,
    count: 5
};

export function matchStatisticReducer(
    state: MatchStatisticState = initialMatchStatisticState,
    action: AnyAction
): MatchStatisticState {
    if (isType(action, matchStatisticSet)) {
        return { ...state, stats: action.payload };
    } else if (isType(action, matchStatisticGamesSet)) {
        return { ...state, games: action.payload };
    } else if (isType(action, matchStatisticGamesSetGameCount)) {
        return { ...state, gameCount: action.payload };
    } else if (isType(action, matchStatisticGamesSetCurrent)) {
        return { ...state, currentOffset: action.payload };
    }
    return state;
}
