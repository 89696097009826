import moment from 'moment';
import * as React from 'react';

interface Props {
    date?: Date;
    format?: 'veryLong' | 'long' | 'medium' | 'short' | 'mini';
}

export const TimeStr = (props: Props) => {
    if (props.date) {
        return <>{moment(props.date).locale('de').format('HH:mm')}</>;
    } else {
        return null;
    }
};

interface DateStrProps {
    date?: Date;
}

export const DateStr = (props: DateStrProps) => {
    const d = props.date ? props.date : new Date();
    return <>{moment(d).locale('de').format('dddd, DD.MM.YYYY')}</>;
};

export const DateStrResponsive = (props: DateStrProps) => {
    const d = props.date ? props.date : new Date();

    const mini = moment(d).locale('de').format('YYYY');
    const short = moment(d).locale('de').format('DD.MM.YYYY');

    const long = moment(d).locale('de').format('dddd, DD.MM.YYYY');

    return (
        <>
            <span className="d-none d-md-inline-block d-lg-inline-block d-xl-inline-block">{long}</span>
            <span className="d-none d-sm-inline-block d-md-none">{short}</span>
            <span className="d-sx-inline-block d-sm-none">{mini}</span>
        </>
    );
};

export const DateTime: React.FC<Props> = (props) => {
    const mini = moment(props.date).locale('de').format('dd HH:mm');
    const short = moment(props.date).locale('de').format('DD.MM., HH:mm');

    const long = moment(props.date).locale('de').format('DD.MM.YYYY, HH:mm');

    const veryLong = moment(props.date).locale('de').format('dddd, DD.MM.YYYY, HH:mm');

    switch (props.format) {
        case 'veryLong':
            return <>{veryLong}</>;
        case 'long':
            return <>{long}</>;
        case 'short':
            return <>{short}</>;
        case 'mini':
            return <>{mini}</>;
        default:
            return (
                <>
                    <span className="d-none d-md-inline-block d-lg-inline-block d-xl-inline-block">{long}</span>
                    <span className="d-none d-sm-inline-block d-md-none">{short}</span>
                    <span className="d-sx-inline-block d-sm-none">{mini}</span>
                </>
            );
    }
};

export const DateFormatter: React.FC<Props> = (props) => {
    const mini = moment(props.date).locale('de').format('dd');
    const short = moment(props.date).locale('de').format('DD.MM.');

    const medium = moment(props.date).locale('de').format('DD.MM.YY');
    const long = moment(props.date).locale('de').format('DD.MM.YYYY');

    const veryLong = moment(props.date).locale('de').format('dddd, DD.MM.YYYY');

    switch (props.format) {
        case 'veryLong':
            return <>{veryLong}</>;
        case 'long':
            return <>{long}</>;
            case 'medium':
                return <>{medium}</>;
            case 'short':
            return <>{short}</>;
        case 'mini':
            return <>{mini}</>;
        default:
            return (
                <>
                    <span className="d-none d-md-inline-block d-lg-inline-block d-xl-inline-block">{long}</span>
                    <span className="d-none d-sm-inline-block d-md-none">{short}</span>
                    <span className="d-sx-inline-block d-sm-none">{mini}</span>
                </>
            );
    }
};
