import { push } from '@lagunovsky/redux-react-router';
import * as R from 'ramda';
import * as React from 'react';
import { Route, Routes } from 'react-router';
import { withFooter } from '../../reactutils/withFooter';
import { withMenu } from '../../reactutils/withMenu';
import { UserGroupController } from '../../screens/UserGroupController';
import { AccountUserMailScreen } from '../AccountUserApp/AccountUserMail';
import { AccountUserProfileScreen } from '../AccountUserApp/AccountUserProfile';
import { AccountUserSettingsScreen } from '../AccountUserApp/AccountUserSettings';
import { NewsletterUserProfileScreen } from '../AccountUserApp/NewsletterUserProfile';
import { UserFeedback } from '../AccountUserApp/UserFeedback';
import { BettingScreen } from '../Betting';
import { bettingWithoutProps } from '../Betting/actions';
import { GameList, gamesListTodayPath } from '../GamesList';
import { GroupInternalHighscoreScreen } from '../GroupInternalHighscores';
import { gihsLoadWithoutProps } from '../GroupInternalHighscores/actions';
import { GroupInternalHighscoreForwarderScreen } from '../GroupInternaltHighscoresForwarder';
import { ActionForwardComponent, HighscoreScreen } from '../Highscores';
import { MenuBetting } from '../MenuBetting';
import { MyPlayListScreen } from '../Playlist/MyPlayList';
import { Portal } from '../RadioEins/Portal';
import { R1PrizesScreen } from '../RadioEins/R1Prices';
import { R1RulesScreen } from '../RadioEins/R1Rules';
import { RankingScreen } from '../RankingComponent';
import { rankingLoadWithoutProps } from '../RankingComponent/actions';
import { HighscoreTeamChat, TeamChat } from '../TeamChat';
import { UserGroupHighscoreScreen } from '../UserGroupHighscores';
import { groupHighscoresLoadWithoutProps, highscoresLoadWithoutProps } from '../UserGroupHighscores/actions';
import { UserGroupListScreen } from '../UserGroupList';
import { UserListScreen } from '../UserList';
import { UserViewBettingScreen } from '../UserView/UserViewBetting';
import { UserViewPlaylistScreen } from '../UserView/UserViewPlaylist';
import { UserViewProfileScreen } from '../UserView/UserViewProfile';
import { BettingProfileScreen } from './BettingProfile';
import { BettingViewScreen } from './BettingView';
import { bettingViewLoadWithoutParams } from './BettingView/action';
import { HighscoreStrategyDescriptorScreen } from './HighscoreStrategyDescriptorScreen';
import { LiveTickerConference } from './LiveTickerConference';
import { MyGroupHighscoreForwarder } from './MyGroupHighscores';
import { RoundWinnerScreen } from './RoundWinners';
import { TournamentArchive } from './TournamentArchive';
import { TeamView, TeamViewForward } from './TeamView';

interface Props {}

const BettingAppComponent: React.FC<Props> = (props) => {
    return (
        <div className="container-fluid p-0 m-0">
            <Routes>
                <Route path="team/:teamId" element={<TeamViewForward />} />
                <Route path="team/:teamId/:page/:pageSize" element={<TeamView />} />
                <Route path="user/:userId" element={<UserViewProfileScreen />} />
                <Route path="user/profile/:userId" element={<UserViewProfileScreen />} />
                <Route path="user/profile/*" element={<ActionForwardComponent action={push('/')} />} />
                <Route path="user/playlist/:userId" element={<UserViewPlaylistScreen />} />
                <Route path="user/betting/:userId" element={<UserViewBettingScreen />} />
                <Route path="user-group/:ugId/admin" element={<UserGroupController />} />
                <Route path="user-group/:ugId/chat" element={<TeamChat />} />
                <Route path="user-group/highscore/:ugId/chat" element={<HighscoreTeamChat />} />
                <Route path="user-group/:ugId/hsd" element={<HighscoreStrategyDescriptorScreen />} />
                <Route path="games/:date/:filter" element={<GameList />} />
                <Route path="games/*" element={<ActionForwardComponent action={push(gamesListTodayPath())} />} />
                <Route path="highscores/:trId/:pos" element={<HighscoreScreen />} />
                <Route path="highscores/*" element={<ActionForwardComponent action={highscoresLoadWithoutProps()} />} />
                <Route path="group-highscores/:trId" element={<UserGroupHighscoreScreen />} />
                <Route
                    path="group-highscores/*"
                    element={<ActionForwardComponent action={groupHighscoresLoadWithoutProps()} />}
                />
                <Route path="group-highscores/:trId/user-group/:ugId" element={<GroupInternalHighscoreScreen />} />
                <Route
                    path="group-highscores/:trId/user-group/*"
                    element={<ActionForwardComponent action={gihsLoadWithoutProps()} />}
                />
                <Route
                    path="group-internal-highscore-forward/:ugId?"
                    element={<GroupInternalHighscoreForwarderScreen />}
                />
                <Route path="my-group-highscores" element={<MyGroupHighscoreForwarder />} />
                <Route path="ranking/:trId" element={<RankingScreen />} />
                <Route path="ranking" element={<ActionForwardComponent action={rankingLoadWithoutProps()} />} />

                <Route path="betting/:trId" element={<BettingScreen />} />
                <Route path="betting" element={<ActionForwardComponent action={bettingWithoutProps()} />} />
                <Route path="betting-view/:trId/:userId" element={<BettingViewScreen />} />
                <Route
                    path="betting-view/*"
                    element={<ActionForwardComponent action={bettingViewLoadWithoutParams()} />}
                />

                <Route path="profile/settings" element={<AccountUserSettingsScreen />} />
                <Route path="profile/profile" element={<AccountUserProfileScreen />} />
                <Route path="profile/mail" element={<AccountUserMailScreen />} />
                <Route path="profile/betting" element={<BettingProfileScreen />} />
                <Route path="profile/playlist" element={<MyPlayListScreen />} />
                <Route path="profile/newsletter" element={<NewsletterUserProfileScreen />} />
                <Route path="user-group-list" element={<UserGroupListScreen />} />
                <Route path="user-list" element={<UserListScreen />} />
                <Route path="feedback" element={<UserFeedback />} />
                <Route path="portal" element={<Portal />} />
                <Route path="archive" element={<TournamentArchive />} />
                <Route path="rules" element={<R1RulesScreen />} />
                <Route path="prizes" element={<R1PrizesScreen />} />
                <Route path="round-winners/:tId" element={<RoundWinnerScreen />} />
                <Route path="live-ticker" element={<LiveTickerConference />} />

                <Route path="betting.do:trId?" element={<BettingScreen />} />

                {/*
// default route
*/}
                <Route path="/" element={<GameList />} />
            </Routes>
        </div>
    );
};

export default R.compose(withFooter(), withMenu<Props>(<MenuBetting />))(BettingAppComponent);
