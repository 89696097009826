import React, { FC, useEffect } from 'react';
import { Button, FormGroup, FormText, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getNewsletterAdminApi } from '../../../System/systemConfig';
import { useMutation, useQuery } from '@tanstack/react-query';

interface Props {
    mode: 'header' | 'footer';
    nllId: string;
    onClose: () => void;
}

export const NewsletterLayoutHeaderFooterEdit: FC<Props> = (props) => {
    const api = getNewsletterAdminApi();

    const [text, setText] = React.useState('');

    const newsletterLayoutQuery = useQuery({
        queryKey: ['newsletterLayout', props.nllId],
        queryFn: () => api.getNewsletterLayout({nllId: props.nllId}),
        enabled: !!props.nllId,
    });

    useEffect(() => {
        if (props.mode === 'header') {
            setText(newsletterLayoutQuery.data?.headerText || '');
        } else {
            setText(newsletterLayoutQuery.data?.footerText || '');
        }
    }, [newsletterLayoutQuery.data?.footerText, newsletterLayoutQuery.data?.headerText, props.mode]);

    const updateHeaderFooterMutation = useMutation({
        mutationFn: () =>
            api.updateNewsletterLayoutHeaderFooter({nllId: props.nllId, text, type: props.mode}),
    });

    const onUpdate = () => {
        updateHeaderFooterMutation.mutate(undefined, {
            onSuccess: () => {
                props.onClose();
            },
            onError: (error) => {
                console.log('Error updating newsletter layout header/footer: ' + JSON.stringify(error));
            },
        });
    };

    return (
        <Modal isOpen={true} toggle={props.onClose} size="lg">
            <ModalHeader toggle={props.onClose}>Layout Header/Footer bearbeiten</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label for="text">Text</Label>
                    <textarea
                        className="form-control"
                        id="text"
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        rows={10}
                    />
                    <FormText>
                        Der Text des Blocks muss HTML-Code enthalten und kann folgende Variablen verwenden: #{'{'}title
                        {'}'}, #{'{'}text{'}'}, #{'{'}image_link{'}'}, #{'{'}link{'}'}, #{'{'}user.correspondenceName
                        {'}'}
                    </FormText>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button onClick={props.onClose}>Abbrechen</Button>
                <Button color="success" onClick={onUpdate}>Speichern</Button>
            </ModalFooter>
        </Modal>
    );
};
