import { AnyAction } from 'typescript-fsa';
import { isType } from 'typescript-fsa/dist/typescript-fsa';
import { Category, Tournament } from '../../../services/betting';
import { tournamentArchiveSetCategories, tournamentArchiveSetTournaments } from './actions';

export interface TournamentArchiveState {
    tournaments: Tournament[];
    categories: Category[];
}

const initialTournamentArchiveState: TournamentArchiveState = {
    tournaments: [],
    categories: []
};

export function tournamentArchiveReducer(
    state: TournamentArchiveState = initialTournamentArchiveState,
    action: AnyAction
): TournamentArchiveState {
    if (isType(action, tournamentArchiveSetCategories)) {
        return { ...state, categories: action.payload };
    } else if (isType(action, tournamentArchiveSetTournaments)) {
        return { ...state, tournaments: action.payload };
    }
    return state;
}
