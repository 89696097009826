import { FC } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import {
    sysAdminCachePath,
    sysAdminClientStatisticsPath,
    sysAdminEventHandlerPath,
    sysAdminJobBasePath,
    sysAdminMailoutPath,
    sysAdminSqlPath,
    sysAdminTranslationTaskPath,
    sysAdminUpdateTaskPath,
    sysAdminUseCaseLogPath,
    sysAdminWebUsagePath,
} from '../sysAdminPathes';

interface SysAdminLink {
    name: string;
    link: string;
}

interface Props {
    links: SysAdminLink[];
}

export const SysAdminSubMenu = (props: Props) => {
    const location = useLocation();
    return (
        <div className="d-flex flex-row flex-wrap">
            {props.links &&
                props.links.map((l, i) => (
                    <div
                        key={i}
                        className={`${
                            location.pathname.indexOf(l.link) !== -1 ? 'bg-dark' : 'bg-white'
                        } d-flex flex-row p-1 rounded align-items-center flex-nowrap border border-gray m-2 px-2 py-1`}
                    >
                        <Link
                            className={`${location.pathname.indexOf(l.link) !== -1 ? 'text-white' : 'text-blue'}`}
                            to={l.link}
                        >
                            {l.name}
                        </Link>
                    </div>
                ))}
        </div>
    );
};

export const SysAdminSystemSubMenu: FC<{}> = () => {
    return (
        <SysAdminSubMenu
            links={[
                { name: 'Jobs', link: sysAdminJobBasePath() },
                { name: 'Caches', link: sysAdminCachePath('betting') },
                { name: 'SQL Statistics', link: sysAdminSqlPath('betting') },
                { name: 'Rest-Clients', link: sysAdminWebUsagePath() },
                { name: 'Use Case Logs', link: sysAdminUseCaseLogPath('betting') },
                { name: 'Event Handler', link: sysAdminEventHandlerPath() },
                { name: 'Mailouts', link: sysAdminMailoutPath() },
                { name: 'Update Tasks', link: sysAdminUpdateTaskPath() },
                { name: 'Translations', link: sysAdminTranslationTaskPath() },
                { name: 'Client Statistics', link: sysAdminClientStatisticsPath() },
            ]}
        />
    );
};
