/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  WebUsage,
  WebUsageSummary,
} from '../models';
import {
    WebUsageFromJSON,
    WebUsageToJSON,
    WebUsageSummaryFromJSON,
    WebUsageSummaryToJSON,
} from '../models';

export interface GetWebUsageListRequest {
    serverName: string;
}

export interface GetWebUsageSummaryRequest {
    serverName: string;
}

/**
 * 
 */
export class WebUsageControllerApi extends runtime.BaseAPI {

    /**
     */
    async getWebUsageListRaw(requestParameters: GetWebUsageListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WebUsage>>> {
        if (requestParameters.serverName === null || requestParameters.serverName === undefined) {
            throw new runtime.RequiredError('serverName','Required parameter requestParameters.serverName was null or undefined when calling getWebUsageList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admsys/usage/list/{serverName}`.replace(`{${"serverName"}}`, encodeURIComponent(String(requestParameters.serverName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WebUsageFromJSON));
    }

    /**
     */
    async getWebUsageList(serverName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WebUsage>> {
        const response = await this.getWebUsageListRaw({ serverName: serverName }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getWebUsageSummaryRaw(requestParameters: GetWebUsageSummaryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<WebUsageSummary>>> {
        if (requestParameters.serverName === null || requestParameters.serverName === undefined) {
            throw new runtime.RequiredError('serverName','Required parameter requestParameters.serverName was null or undefined when calling getWebUsageSummary.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admsys/usage/{serverName}`.replace(`{${"serverName"}}`, encodeURIComponent(String(requestParameters.serverName))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(WebUsageSummaryFromJSON));
    }

    /**
     */
    async getWebUsageSummary(serverName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<WebUsageSummary>> {
        const response = await this.getWebUsageSummaryRaw({ serverName: serverName }, initOverrides);
        return await response.value();
    }

}
