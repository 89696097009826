import * as React from 'react';
import { Badge, Button } from 'reactstrap';

interface Props {
    minValue: number;
    maxValue: number;
    distinctByQuotes: boolean;
}

export const HighscoreDescriptorValue = (props: Props) => {
    return (
        <>
            <Button color="primary" outline={true} disabled={true} className="m-2">
                min: <Badge color="primary">{props.minValue}</Badge>
            </Button>
            {props.distinctByQuotes && (
                <Button color="success" outline={true} disabled={true} className="m-2">
                    max: <Badge color="success">{props.maxValue}</Badge>
                </Button>
            )}
        </>
    );
};
