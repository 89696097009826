import { push } from '@lagunovsky/redux-react-router';
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { SubFormProps } from '..';
import { LoadingIndicatorSpinner2 } from '../../common/LoadingIndicatorSpinner2';
import { systemSetAuthenticatedAccessToken, systemSetLoginNotRequired } from '../../System/actions';
import { systemLoginNextUrlSelector } from '../../System/selectors';
import { getLogin2Api, getSystemConfig } from '../../System/systemConfig';

export const LoginSubForm: FC<SubFormProps> = props => {
    const dispatch = useDispatch();

    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const nextUrl = useSelector(systemLoginNextUrlSelector);

    const onCancel = () => {
        // FIXME define a global method for the portal path
        dispatch(systemSetLoginNotRequired());
        dispatch(push('/b/portal'));
        setPassword('');
        setUserName('');
        setErrorMessage('');
    };

    const isUserNameValid = (name: string): boolean => {
        return name !== undefined && name.length > 0;
    };

    const isPasswordValid = (pwd: string): boolean => {
        return pwd !== undefined && pwd.length > 0;
    };

    const onClick = async () => {
        if (!isUserNameValid(userName)) {
            setErrorMessage('ungültiger Nutzername.');
        } else if (!isPasswordValid(password)) {
            setErrorMessage('ungültiges Kennwort.');
        } else {
            try {
                setLoading(true);
                getLogin2Api()
                    .loginByCredentials({tenant: getSystemConfig().clientName, login: userName, password})
                    .then(lr => {
                        if (lr.token) {
                            setErrorMessage('');
                            dispatch(systemSetAuthenticatedAccessToken(lr.token));
                            dispatch(systemSetLoginNotRequired());
                            setPassword('');
                            setUserName('');
                            dispatch(push(nextUrl));
                        }
                    })
                    .catch(e => setErrorMessage('Nutzername / Kennwort sind nicht korrekt.'))
                    .finally(() => setLoading(false));
            } finally {
            }
        }
    };
    return (
        <>
            <LoadingIndicatorSpinner2 isLoading={loading} />
            <Form
                onSubmit={e => {
                    e.preventDefault();
                    onClick();
                }}
            >
                <FormGroup>
                    <Label>
                        <b>Nutzername</b>
                    </Label>
                    <Input value={userName} onChange={e => setUserName(e.currentTarget.value)} />
                </FormGroup>
                <FormGroup>
                    <Label>
                        <b>Kennwort</b>
                    </Label>
                    <Input
                        style={{boxSizing: 'border-box'}}
                        type="password"
                        value={password}
                        onChange={e => setPassword(e.currentTarget.value)}
                    />
                </FormGroup>
                <FormGroup>
                    <div style={{ color: 'red' }} className="my-2">
                        {errorMessage}
                    </div>
                </FormGroup>
                <Button type="submit" color="success" className="me-3">
                    Anmelden
                </Button>
                <Button onClick={onCancel}>Abbrechen</Button>
            </Form>
            <div className="mt-4">
                <b>Neu hier?</b>
            </div>
            <Button
                style={{ backgroundColor: '#337ab7', color: 'white' }}
                onClick={() => props.changeSubForm('registration')}
            >
                Registrieren Sie sich kostenlos.
            </Button>
        </>
    );
};
