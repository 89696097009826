import React, { FC } from 'react';

interface PortletComponentProps {
    tagLine: string;
    title: string;
    subTitle: string;
    imageUrl: string;
    text: string;
    linkUrl: string;
}

export const PortletComponent: FC<PortletComponentProps> = (props) => {
    return (
        <div className="w-100 h-100 bg-white p-3">
            <div>
                <span className="text-primary" style={{ fontSize: '11px' }}>
                    {props.tagLine}
                </span>
                <h5 style={{ fontWeight: 'bold', minHeight: '3rem' }}>{props.title}</h5>
                <span>{props.subTitle}</span>
            </div>
            <div>
                <a title={props.title} className="pe-2 float-left" href={props.linkUrl}>
                    <img src={props.imageUrl} alt={props.title} width="80" className="py-2" />
                </a>
                <span>
                    <span>
                        <p>{props.text}</p>
                    </span>
                    ...<a href={props.linkUrl}>[mehr]</a>
                </span>
            </div>
        </div>
    );
};
