import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Badge } from 'reactstrap';
import { notificationStatusLoad } from './actions';
import { notificationStatusEntitySelector } from './selectors';

export const NewMessagesBadge = () => {
    const status = useSelector(notificationStatusEntitySelector);

    if (status.messageCount > 0) {
        return (
            <Badge className="ms-2 me-1" color="warning">
                {status.messageCount}
            </Badge>
        );
    } else {
        return null;
    }
};

export const NewInvitationsBadge = () => {
    const status = useSelector(notificationStatusEntitySelector);

    if (status.groupInvitations.length > 0) {
        return (
            <Badge className="ms-2 me-1" color="danger">
                {status.groupInvitations.length}
            </Badge>
        );
    } else {
        return null;
    }
};

export const NewAskForMembershipBadge = () => {
    const status = useSelector(notificationStatusEntitySelector);

    if (status.groupAskForMemberships.length > 0) {
        return (
            <Badge className="ms-2 me-1" color="danger">
                {status.groupAskForMemberships.length}
            </Badge>
        );
    } else {
        return null;
    }
};

export const NotificationStatus = () => {
    const dispatch = useDispatch();

    const onLoad = React.useCallback(() => {
        dispatch(notificationStatusLoad());
    }, [dispatch]);
    
    React.useEffect(() => onLoad(), [onLoad]);

    React.useEffect(() => {
        const timeout = setInterval(() => onLoad(), 120000); // refresh every two minutes

        return () => clearInterval(timeout);
    }, [onLoad]);

    return null;
};
