import React, { FC } from 'react';
import styled from 'styled-components';

interface GameBettingAllowedProps {
    bettingAllowed: boolean;
}

const BettingAllowedStatusIcon = styled.div`
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: green;
    border-style: none;
`;

const BettingNotAllowedStatusIcon = styled.div`
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: white;
    border-color: gray;
    border-width: 1px;
    border-style: solid;
`;
export const GameBettingAllowed: FC<GameBettingAllowedProps> = (props) => {
    if (props.bettingAllowed) {
        return <BettingAllowedStatusIcon />;
    } else {
        return <BettingNotAllowedStatusIcon />;
    }
};
