/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Job,
} from '../models';
import {
    JobFromJSON,
    JobToJSON,
} from '../models';

export interface DeleteRequest {
    triggerId: string;
    triggerGroup: string;
}

export interface CreateRequest {
    jobName: string;
    immediately: boolean;
    seconds: string;
    minute: string;
    hours: string;
    dayOfMonth: string;
    months: string;
    daysOfWeek: string;
    years: string;
}

/**
 * 
 */
export class JobControllerApi extends runtime.BaseAPI {

    /**
     */
    async _deleteRaw(requestParameters: DeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.triggerId === null || requestParameters.triggerId === undefined) {
            throw new runtime.RequiredError('triggerId','Required parameter requestParameters.triggerId was null or undefined when calling _delete.');
        }

        if (requestParameters.triggerGroup === null || requestParameters.triggerGroup === undefined) {
            throw new runtime.RequiredError('triggerGroup','Required parameter requestParameters.triggerGroup was null or undefined when calling _delete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admsys/jobs/{triggerId}/{triggerGroup}`.replace(`{${"triggerId"}}`, encodeURIComponent(String(requestParameters.triggerId))).replace(`{${"triggerGroup"}}`, encodeURIComponent(String(requestParameters.triggerGroup))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async _delete(triggerId: string, triggerGroup: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this._deleteRaw({ triggerId: triggerId, triggerGroup: triggerGroup }, initOverrides);
        return await response.value();
    }

    /**
     */
    async createRaw(requestParameters: CreateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.jobName === null || requestParameters.jobName === undefined) {
            throw new runtime.RequiredError('jobName','Required parameter requestParameters.jobName was null or undefined when calling create.');
        }

        if (requestParameters.immediately === null || requestParameters.immediately === undefined) {
            throw new runtime.RequiredError('immediately','Required parameter requestParameters.immediately was null or undefined when calling create.');
        }

        if (requestParameters.seconds === null || requestParameters.seconds === undefined) {
            throw new runtime.RequiredError('seconds','Required parameter requestParameters.seconds was null or undefined when calling create.');
        }

        if (requestParameters.minute === null || requestParameters.minute === undefined) {
            throw new runtime.RequiredError('minute','Required parameter requestParameters.minute was null or undefined when calling create.');
        }

        if (requestParameters.hours === null || requestParameters.hours === undefined) {
            throw new runtime.RequiredError('hours','Required parameter requestParameters.hours was null or undefined when calling create.');
        }

        if (requestParameters.dayOfMonth === null || requestParameters.dayOfMonth === undefined) {
            throw new runtime.RequiredError('dayOfMonth','Required parameter requestParameters.dayOfMonth was null or undefined when calling create.');
        }

        if (requestParameters.months === null || requestParameters.months === undefined) {
            throw new runtime.RequiredError('months','Required parameter requestParameters.months was null or undefined when calling create.');
        }

        if (requestParameters.daysOfWeek === null || requestParameters.daysOfWeek === undefined) {
            throw new runtime.RequiredError('daysOfWeek','Required parameter requestParameters.daysOfWeek was null or undefined when calling create.');
        }

        if (requestParameters.years === null || requestParameters.years === undefined) {
            throw new runtime.RequiredError('years','Required parameter requestParameters.years was null or undefined when calling create.');
        }

        const queryParameters: any = {};

        if (requestParameters.jobName !== undefined) {
            queryParameters['jobName'] = requestParameters.jobName;
        }

        if (requestParameters.immediately !== undefined) {
            queryParameters['immediately'] = requestParameters.immediately;
        }

        if (requestParameters.seconds !== undefined) {
            queryParameters['seconds'] = requestParameters.seconds;
        }

        if (requestParameters.minute !== undefined) {
            queryParameters['minute'] = requestParameters.minute;
        }

        if (requestParameters.hours !== undefined) {
            queryParameters['hours'] = requestParameters.hours;
        }

        if (requestParameters.dayOfMonth !== undefined) {
            queryParameters['dayOfMonth'] = requestParameters.dayOfMonth;
        }

        if (requestParameters.months !== undefined) {
            queryParameters['months'] = requestParameters.months;
        }

        if (requestParameters.daysOfWeek !== undefined) {
            queryParameters['daysOfWeek'] = requestParameters.daysOfWeek;
        }

        if (requestParameters.years !== undefined) {
            queryParameters['years'] = requestParameters.years;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admsys/jobs/`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async create(jobName: string, immediately: boolean, seconds: string, minute: string, hours: string, dayOfMonth: string, months: string, daysOfWeek: string, years: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.createRaw({ jobName: jobName, immediately: immediately, seconds: seconds, minute: minute, hours: hours, dayOfMonth: dayOfMonth, months: months, daysOfWeek: daysOfWeek, years: years }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAvailableJobsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admsys/jobs/available`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getAvailableJobs(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getAvailableJobsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getScheduledJobsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Job>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admsys/jobs/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(JobFromJSON));
    }

    /**
     */
    async getScheduledJobs(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Job>> {
        const response = await this.getScheduledJobsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async isSchedulerEnabledRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admsys/jobs/enabled`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async isSchedulerEnabled(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.isSchedulerEnabledRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async standbyRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admsys/jobs/standby`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async standby(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.standbyRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async startRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/admsys/jobs/start`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async start(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.startRaw(initOverrides);
        return await response.value();
    }

}
