/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Album,
  AlbumSong,
  MusicbrainzRelease,
  PlayListEntry,
  PlaylistStatistic,
  RestResult,
  ResultMessage,
  Song,
} from '../models';
import {
    AlbumFromJSON,
    AlbumToJSON,
    AlbumSongFromJSON,
    AlbumSongToJSON,
    MusicbrainzReleaseFromJSON,
    MusicbrainzReleaseToJSON,
    PlayListEntryFromJSON,
    PlayListEntryToJSON,
    PlaylistStatisticFromJSON,
    PlaylistStatisticToJSON,
    RestResultFromJSON,
    RestResultToJSON,
    ResultMessageFromJSON,
    ResultMessageToJSON,
    SongFromJSON,
    SongToJSON,
} from '../models';

export interface AddPlaylistEntryRequest {
    station: string;
    artist: string;
    title: string;
    album: string;
    duration: number;
}

export interface AssignCoverArtRequest {
    albumId: string;
    imageId: string;
}

export interface CountAlbumsRequest {
    q: string;
}

export interface CountPlayListRequest {
    stationId: string;
    from: Date;
    to: Date;
    q: string;
}

export interface CountSongsRequest {
    q: string;
}

export interface CreateAlbumRequest {
    name: string;
}

export interface CreateSongRequest {
    title: string;
    artist: string;
    remark: string;
    audioUrl: string;
}

export interface DeleteAlbumRequest {
    albumId: string;
}

export interface DeleteAlbumCoverRequest {
    albumId: string;
}

export interface GetAlbumRequest {
    albumId: string;
}

export interface GetAlbumSongsRequest {
    albId: string;
}

export interface GetAlbumsBySongRequest {
    songId: string;
}

export interface GetLastSongRequest {
    station: string;
}

export interface GetMusicbrainzAlbumListRequest {
    albumId: string;
}

export interface GetMusicbrainzReleaseRequest {
    mbId: string;
}

export interface GetSongRequest {
    songId: string;
}

export interface ImportMusicbrainzReleaseRequest {
    albumId: string;
    releaseId: string;
}

export interface ImportMusicbrainzSongRequest {
    songId: string;
}

export interface RefreshMainAlbumIdsRequest {
    station: string;
    date: string;
}

export interface RemoveDuplicatedSongsRequest {
    albumId: string;
}

export interface SearchAlbumsRequest {
    startPos: number;
    limit: number;
    q: string;
}

export interface SearchPlaylistRequest {
    stationId: string;
    startPos: number;
    limit: number;
    from: Date;
    to: Date;
    q: string;
}

export interface SearchSongsRequest {
    startPos: number;
    limit: number;
    q: string;
}

export interface UpdateAlbumRequest {
    album: Album;
}

export interface UpdateSongRequest {
    song: Song;
}

/**
 * 
 */
export class PlaylistAdminControllerApi extends runtime.BaseAPI {

    /**
     */
    async addPlaylistEntryRaw(requestParameters: AddPlaylistEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PlayListEntry>> {
        if (requestParameters.station === null || requestParameters.station === undefined) {
            throw new runtime.RequiredError('station','Required parameter requestParameters.station was null or undefined when calling addPlaylistEntry.');
        }

        if (requestParameters.artist === null || requestParameters.artist === undefined) {
            throw new runtime.RequiredError('artist','Required parameter requestParameters.artist was null or undefined when calling addPlaylistEntry.');
        }

        if (requestParameters.title === null || requestParameters.title === undefined) {
            throw new runtime.RequiredError('title','Required parameter requestParameters.title was null or undefined when calling addPlaylistEntry.');
        }

        if (requestParameters.album === null || requestParameters.album === undefined) {
            throw new runtime.RequiredError('album','Required parameter requestParameters.album was null or undefined when calling addPlaylistEntry.');
        }

        if (requestParameters.duration === null || requestParameters.duration === undefined) {
            throw new runtime.RequiredError('duration','Required parameter requestParameters.duration was null or undefined when calling addPlaylistEntry.');
        }

        const queryParameters: any = {};

        if (requestParameters.artist !== undefined) {
            queryParameters['artist'] = requestParameters.artist;
        }

        if (requestParameters.title !== undefined) {
            queryParameters['title'] = requestParameters.title;
        }

        if (requestParameters.album !== undefined) {
            queryParameters['album'] = requestParameters.album;
        }

        if (requestParameters.duration !== undefined) {
            queryParameters['duration'] = requestParameters.duration;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/playlist/{station}/add-entry`.replace(`{${"station"}}`, encodeURIComponent(String(requestParameters.station))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlayListEntryFromJSON(jsonValue));
    }

    /**
     */
    async addPlaylistEntry(station: string, artist: string, title: string, album: string, duration: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PlayListEntry> {
        const response = await this.addPlaylistEntryRaw({ station: station, artist: artist, title: title, album: album, duration: duration }, initOverrides);
        return await response.value();
    }

    /**
     */
    async assignCoverArtRaw(requestParameters: AssignCoverArtRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult>> {
        if (requestParameters.albumId === null || requestParameters.albumId === undefined) {
            throw new runtime.RequiredError('albumId','Required parameter requestParameters.albumId was null or undefined when calling assignCoverArt.');
        }

        if (requestParameters.imageId === null || requestParameters.imageId === undefined) {
            throw new runtime.RequiredError('imageId','Required parameter requestParameters.imageId was null or undefined when calling assignCoverArt.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/playlist/musicbrainz/album/assign-cover-art/{albumId}/{imageId}`.replace(`{${"albumId"}}`, encodeURIComponent(String(requestParameters.albumId))).replace(`{${"imageId"}}`, encodeURIComponent(String(requestParameters.imageId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResultFromJSON(jsonValue));
    }

    /**
     */
    async assignCoverArt(albumId: string, imageId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult> {
        const response = await this.assignCoverArtRaw({ albumId: albumId, imageId: imageId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async countAlbumsRaw(requestParameters: CountAlbumsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.q === null || requestParameters.q === undefined) {
            throw new runtime.RequiredError('q','Required parameter requestParameters.q was null or undefined when calling countAlbums.');
        }

        const queryParameters: any = {};

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/playlist/album/list/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async countAlbums(q: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.countAlbumsRaw({ q: q }, initOverrides);
        return await response.value();
    }

    /**
     */
    async countPlayListRaw(requestParameters: CountPlayListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.stationId === null || requestParameters.stationId === undefined) {
            throw new runtime.RequiredError('stationId','Required parameter requestParameters.stationId was null or undefined when calling countPlayList.');
        }

        if (requestParameters.from === null || requestParameters.from === undefined) {
            throw new runtime.RequiredError('from','Required parameter requestParameters.from was null or undefined when calling countPlayList.');
        }

        if (requestParameters.to === null || requestParameters.to === undefined) {
            throw new runtime.RequiredError('to','Required parameter requestParameters.to was null or undefined when calling countPlayList.');
        }

        if (requestParameters.q === null || requestParameters.q === undefined) {
            throw new runtime.RequiredError('q','Required parameter requestParameters.q was null or undefined when calling countPlayList.');
        }

        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString();
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString();
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/playlist/search/{stationId}/count`.replace(`{${"stationId"}}`, encodeURIComponent(String(requestParameters.stationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async countPlayList(stationId: string, from: Date, to: Date, q: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.countPlayListRaw({ stationId: stationId, from: from, to: to, q: q }, initOverrides);
        return await response.value();
    }

    /**
     */
    async countSongsRaw(requestParameters: CountSongsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters.q === null || requestParameters.q === undefined) {
            throw new runtime.RequiredError('q','Required parameter requestParameters.q was null or undefined when calling countSongs.');
        }

        const queryParameters: any = {};

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/playlist/song/search/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async countSongs(q: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.countSongsRaw({ q: q }, initOverrides);
        return await response.value();
    }

    /**
     */
    async createAlbumRaw(requestParameters: CreateAlbumRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Album>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling createAlbum.');
        }

        const queryParameters: any = {};

        if (requestParameters.name !== undefined) {
            queryParameters['name'] = requestParameters.name;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/playlist/album`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlbumFromJSON(jsonValue));
    }

    /**
     */
    async createAlbum(name: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Album> {
        const response = await this.createAlbumRaw({ name: name }, initOverrides);
        return await response.value();
    }

    /**
     */
    async createSongRaw(requestParameters: CreateSongRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Song>> {
        if (requestParameters.title === null || requestParameters.title === undefined) {
            throw new runtime.RequiredError('title','Required parameter requestParameters.title was null or undefined when calling createSong.');
        }

        if (requestParameters.artist === null || requestParameters.artist === undefined) {
            throw new runtime.RequiredError('artist','Required parameter requestParameters.artist was null or undefined when calling createSong.');
        }

        if (requestParameters.remark === null || requestParameters.remark === undefined) {
            throw new runtime.RequiredError('remark','Required parameter requestParameters.remark was null or undefined when calling createSong.');
        }

        if (requestParameters.audioUrl === null || requestParameters.audioUrl === undefined) {
            throw new runtime.RequiredError('audioUrl','Required parameter requestParameters.audioUrl was null or undefined when calling createSong.');
        }

        const queryParameters: any = {};

        if (requestParameters.title !== undefined) {
            queryParameters['title'] = requestParameters.title;
        }

        if (requestParameters.artist !== undefined) {
            queryParameters['artist'] = requestParameters.artist;
        }

        if (requestParameters.remark !== undefined) {
            queryParameters['remark'] = requestParameters.remark;
        }

        if (requestParameters.audioUrl !== undefined) {
            queryParameters['audioUrl'] = requestParameters.audioUrl;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/playlist/song`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SongFromJSON(jsonValue));
    }

    /**
     */
    async createSong(title: string, artist: string, remark: string, audioUrl: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Song> {
        const response = await this.createSongRaw({ title: title, artist: artist, remark: remark, audioUrl: audioUrl }, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteAlbumRaw(requestParameters: DeleteAlbumRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult>> {
        if (requestParameters.albumId === null || requestParameters.albumId === undefined) {
            throw new runtime.RequiredError('albumId','Required parameter requestParameters.albumId was null or undefined when calling deleteAlbum.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/playlist/album/{albumId}`.replace(`{${"albumId"}}`, encodeURIComponent(String(requestParameters.albumId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResultFromJSON(jsonValue));
    }

    /**
     */
    async deleteAlbum(albumId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult> {
        const response = await this.deleteAlbumRaw({ albumId: albumId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteAlbumCoverRaw(requestParameters: DeleteAlbumCoverRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Album>> {
        if (requestParameters.albumId === null || requestParameters.albumId === undefined) {
            throw new runtime.RequiredError('albumId','Required parameter requestParameters.albumId was null or undefined when calling deleteAlbumCover.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/playlist/album/delete-cover/{albumId}`.replace(`{${"albumId"}}`, encodeURIComponent(String(requestParameters.albumId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlbumFromJSON(jsonValue));
    }

    /**
     */
    async deleteAlbumCover(albumId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Album> {
        const response = await this.deleteAlbumCoverRaw({ albumId: albumId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAlbumRaw(requestParameters: GetAlbumRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Album>> {
        if (requestParameters.albumId === null || requestParameters.albumId === undefined) {
            throw new runtime.RequiredError('albumId','Required parameter requestParameters.albumId was null or undefined when calling getAlbum.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/playlist/album/{albumId}`.replace(`{${"albumId"}}`, encodeURIComponent(String(requestParameters.albumId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlbumFromJSON(jsonValue));
    }

    /**
     */
    async getAlbum(albumId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Album> {
        const response = await this.getAlbumRaw({ albumId: albumId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAlbumSongsRaw(requestParameters: GetAlbumSongsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<AlbumSong>>> {
        if (requestParameters.albId === null || requestParameters.albId === undefined) {
            throw new runtime.RequiredError('albId','Required parameter requestParameters.albId was null or undefined when calling getAlbumSongs.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/playlist/album/song/{albId}`.replace(`{${"albId"}}`, encodeURIComponent(String(requestParameters.albId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AlbumSongFromJSON));
    }

    /**
     */
    async getAlbumSongs(albId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<AlbumSong>> {
        const response = await this.getAlbumSongsRaw({ albId: albId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getAlbumsBySongRaw(requestParameters: GetAlbumsBySongRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Album>>> {
        if (requestParameters.songId === null || requestParameters.songId === undefined) {
            throw new runtime.RequiredError('songId','Required parameter requestParameters.songId was null or undefined when calling getAlbumsBySong.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/playlist/albums-by-song/{songId}`.replace(`{${"songId"}}`, encodeURIComponent(String(requestParameters.songId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AlbumFromJSON));
    }

    /**
     */
    async getAlbumsBySong(songId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Album>> {
        const response = await this.getAlbumsBySongRaw({ songId: songId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getLastSongRaw(requestParameters: GetLastSongRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PlayListEntry>> {
        if (requestParameters.station === null || requestParameters.station === undefined) {
            throw new runtime.RequiredError('station','Required parameter requestParameters.station was null or undefined when calling getLastSong.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/playlist/{station}/last-song`.replace(`{${"station"}}`, encodeURIComponent(String(requestParameters.station))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlayListEntryFromJSON(jsonValue));
    }

    /**
     */
    async getLastSong(station: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PlayListEntry> {
        const response = await this.getLastSongRaw({ station: station }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getLoveUserSongsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PlayListEntry>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/playlist/my`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PlayListEntryFromJSON));
    }

    /**
     */
    async getLoveUserSongs(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PlayListEntry>> {
        const response = await this.getLoveUserSongsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getMusicbrainzAlbumListRaw(requestParameters: GetMusicbrainzAlbumListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MusicbrainzRelease>>> {
        if (requestParameters.albumId === null || requestParameters.albumId === undefined) {
            throw new runtime.RequiredError('albumId','Required parameter requestParameters.albumId was null or undefined when calling getMusicbrainzAlbumList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/playlist/musicbrainz/album/{albumId}`.replace(`{${"albumId"}}`, encodeURIComponent(String(requestParameters.albumId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MusicbrainzReleaseFromJSON));
    }

    /**
     */
    async getMusicbrainzAlbumList(albumId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MusicbrainzRelease>> {
        const response = await this.getMusicbrainzAlbumListRaw({ albumId: albumId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMusicbrainzReleaseRaw(requestParameters: GetMusicbrainzReleaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MusicbrainzRelease>> {
        if (requestParameters.mbId === null || requestParameters.mbId === undefined) {
            throw new runtime.RequiredError('mbId','Required parameter requestParameters.mbId was null or undefined when calling getMusicbrainzRelease.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/playlist/musicbrainz/release/{mbId}`.replace(`{${"mbId"}}`, encodeURIComponent(String(requestParameters.mbId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MusicbrainzReleaseFromJSON(jsonValue));
    }

    /**
     */
    async getMusicbrainzRelease(mbId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MusicbrainzRelease> {
        const response = await this.getMusicbrainzReleaseRaw({ mbId: mbId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSongRaw(requestParameters: GetSongRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Song>> {
        if (requestParameters.songId === null || requestParameters.songId === undefined) {
            throw new runtime.RequiredError('songId','Required parameter requestParameters.songId was null or undefined when calling getSong.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/playlist/song/{songId}`.replace(`{${"songId"}}`, encodeURIComponent(String(requestParameters.songId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SongFromJSON(jsonValue));
    }

    /**
     */
    async getSong(songId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Song> {
        const response = await this.getSongRaw({ songId: songId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getStatisticsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PlaylistStatistic>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/playlist/statistics`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlaylistStatisticFromJSON(jsonValue));
    }

    /**
     */
    async getStatistics(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PlaylistStatistic> {
        const response = await this.getStatisticsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async importMusicbrainzReleaseRaw(requestParameters: ImportMusicbrainzReleaseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult>> {
        if (requestParameters.albumId === null || requestParameters.albumId === undefined) {
            throw new runtime.RequiredError('albumId','Required parameter requestParameters.albumId was null or undefined when calling importMusicbrainzRelease.');
        }

        if (requestParameters.releaseId === null || requestParameters.releaseId === undefined) {
            throw new runtime.RequiredError('releaseId','Required parameter requestParameters.releaseId was null or undefined when calling importMusicbrainzRelease.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/playlist/musicbrainz/album/import-release/{albumId}/{releaseId}`.replace(`{${"albumId"}}`, encodeURIComponent(String(requestParameters.albumId))).replace(`{${"releaseId"}}`, encodeURIComponent(String(requestParameters.releaseId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResultFromJSON(jsonValue));
    }

    /**
     */
    async importMusicbrainzRelease(albumId: string, releaseId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult> {
        const response = await this.importMusicbrainzReleaseRaw({ albumId: albumId, releaseId: releaseId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async importMusicbrainzSongRaw(requestParameters: ImportMusicbrainzSongRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RestResult>> {
        if (requestParameters.songId === null || requestParameters.songId === undefined) {
            throw new runtime.RequiredError('songId','Required parameter requestParameters.songId was null or undefined when calling importMusicbrainzSong.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/playlist/musicbrainz/song/import/{songId}`.replace(`{${"songId"}}`, encodeURIComponent(String(requestParameters.songId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RestResultFromJSON(jsonValue));
    }

    /**
     */
    async importMusicbrainzSong(songId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RestResult> {
        const response = await this.importMusicbrainzSongRaw({ songId: songId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async refreshMainAlbumIdsRaw(requestParameters: RefreshMainAlbumIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ResultMessage>> {
        if (requestParameters.station === null || requestParameters.station === undefined) {
            throw new runtime.RequiredError('station','Required parameter requestParameters.station was null or undefined when calling refreshMainAlbumIds.');
        }

        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date','Required parameter requestParameters.date was null or undefined when calling refreshMainAlbumIds.');
        }

        const queryParameters: any = {};

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = requestParameters.date;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/playlist/{station}/refresh-main-album`.replace(`{${"station"}}`, encodeURIComponent(String(requestParameters.station))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ResultMessageFromJSON(jsonValue));
    }

    /**
     */
    async refreshMainAlbumIds(station: string, date: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ResultMessage> {
        const response = await this.refreshMainAlbumIdsRaw({ station: station, date: date }, initOverrides);
        return await response.value();
    }

    /**
     */
    async removeDuplicatedSongsRaw(requestParameters: RemoveDuplicatedSongsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Album>> {
        if (requestParameters.albumId === null || requestParameters.albumId === undefined) {
            throw new runtime.RequiredError('albumId','Required parameter requestParameters.albumId was null or undefined when calling removeDuplicatedSongs.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/playlist/album/remove-duplicated-songs/{albumId}`.replace(`{${"albumId"}}`, encodeURIComponent(String(requestParameters.albumId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlbumFromJSON(jsonValue));
    }

    /**
     */
    async removeDuplicatedSongs(albumId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Album> {
        const response = await this.removeDuplicatedSongsRaw({ albumId: albumId }, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchAlbumsRaw(requestParameters: SearchAlbumsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Album>>> {
        if (requestParameters.startPos === null || requestParameters.startPos === undefined) {
            throw new runtime.RequiredError('startPos','Required parameter requestParameters.startPos was null or undefined when calling searchAlbums.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling searchAlbums.');
        }

        if (requestParameters.q === null || requestParameters.q === undefined) {
            throw new runtime.RequiredError('q','Required parameter requestParameters.q was null or undefined when calling searchAlbums.');
        }

        const queryParameters: any = {};

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/playlist/album/list/{startPos}/{limit}`.replace(`{${"startPos"}}`, encodeURIComponent(String(requestParameters.startPos))).replace(`{${"limit"}}`, encodeURIComponent(String(requestParameters.limit))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AlbumFromJSON));
    }

    /**
     */
    async searchAlbums(startPos: number, limit: number, q: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Album>> {
        const response = await this.searchAlbumsRaw({ startPos: startPos, limit: limit, q: q }, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchPlaylistRaw(requestParameters: SearchPlaylistRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PlayListEntry>>> {
        if (requestParameters.stationId === null || requestParameters.stationId === undefined) {
            throw new runtime.RequiredError('stationId','Required parameter requestParameters.stationId was null or undefined when calling searchPlaylist.');
        }

        if (requestParameters.startPos === null || requestParameters.startPos === undefined) {
            throw new runtime.RequiredError('startPos','Required parameter requestParameters.startPos was null or undefined when calling searchPlaylist.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling searchPlaylist.');
        }

        if (requestParameters.from === null || requestParameters.from === undefined) {
            throw new runtime.RequiredError('from','Required parameter requestParameters.from was null or undefined when calling searchPlaylist.');
        }

        if (requestParameters.to === null || requestParameters.to === undefined) {
            throw new runtime.RequiredError('to','Required parameter requestParameters.to was null or undefined when calling searchPlaylist.');
        }

        if (requestParameters.q === null || requestParameters.q === undefined) {
            throw new runtime.RequiredError('q','Required parameter requestParameters.q was null or undefined when calling searchPlaylist.');
        }

        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString();
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString();
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/playlist/search/{stationId}/{startPos}/{limit}`.replace(`{${"stationId"}}`, encodeURIComponent(String(requestParameters.stationId))).replace(`{${"startPos"}}`, encodeURIComponent(String(requestParameters.startPos))).replace(`{${"limit"}}`, encodeURIComponent(String(requestParameters.limit))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PlayListEntryFromJSON));
    }

    /**
     */
    async searchPlaylist(stationId: string, startPos: number, limit: number, from: Date, to: Date, q: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PlayListEntry>> {
        const response = await this.searchPlaylistRaw({ stationId: stationId, startPos: startPos, limit: limit, from: from, to: to, q: q }, initOverrides);
        return await response.value();
    }

    /**
     */
    async searchSongsRaw(requestParameters: SearchSongsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Song>>> {
        if (requestParameters.startPos === null || requestParameters.startPos === undefined) {
            throw new runtime.RequiredError('startPos','Required parameter requestParameters.startPos was null or undefined when calling searchSongs.');
        }

        if (requestParameters.limit === null || requestParameters.limit === undefined) {
            throw new runtime.RequiredError('limit','Required parameter requestParameters.limit was null or undefined when calling searchSongs.');
        }

        if (requestParameters.q === null || requestParameters.q === undefined) {
            throw new runtime.RequiredError('q','Required parameter requestParameters.q was null or undefined when calling searchSongs.');
        }

        const queryParameters: any = {};

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/playlist/song/search/{startPos}/{limit}`.replace(`{${"startPos"}}`, encodeURIComponent(String(requestParameters.startPos))).replace(`{${"limit"}}`, encodeURIComponent(String(requestParameters.limit))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SongFromJSON));
    }

    /**
     */
    async searchSongs(startPos: number, limit: number, q: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Song>> {
        const response = await this.searchSongsRaw({ startPos: startPos, limit: limit, q: q }, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateAlbumRaw(requestParameters: UpdateAlbumRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Album>> {
        if (requestParameters.album === null || requestParameters.album === undefined) {
            throw new runtime.RequiredError('album','Required parameter requestParameters.album was null or undefined when calling updateAlbum.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/playlist/album`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AlbumToJSON(requestParameters.album),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AlbumFromJSON(jsonValue));
    }

    /**
     */
    async updateAlbum(album: Album, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Album> {
        const response = await this.updateAlbumRaw({ album: album }, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateSongRaw(requestParameters: UpdateSongRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Song>> {
        if (requestParameters.song === null || requestParameters.song === undefined) {
            throw new runtime.RequiredError('song','Required parameter requestParameters.song was null or undefined when calling updateSong.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/playlist/song`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SongToJSON(requestParameters.song),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SongFromJSON(jsonValue));
    }

    /**
     */
    async updateSong(song: Song, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Song> {
        const response = await this.updateSongRaw({ song: song }, initOverrides);
        return await response.value();
    }

}
