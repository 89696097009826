import { useMutation, useQuery } from '@tanstack/react-query';
import React, { FC, useEffect, useState } from 'react';
import { Button, FormGroup, FormText, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getNewsletterAdminApi } from '../../../System/systemConfig';

interface Props {
    mode: 'create' | 'edit';
    nllbId: string | undefined;
    nllId: string;
    onClose: () => void;
}

export const NewsletterLayoutBlockEdit: FC<Props> = (props) => {
    const api = getNewsletterAdminApi();

    const [name, setName] = useState('');
    const [text, setText] = useState('');

    const newsletterLayoutBlockQuery = useQuery({
        queryKey: ['newsletterLayoutBlock', { nllbId: props.nllbId }],
        queryFn: () => api.getNewsletterLayoutBlock({nllbId: props.nllbId!}),
        enabled: !!props.nllbId && props.mode === 'edit',
    });

    useEffect(() => {
        setName(newsletterLayoutBlockQuery.data?.name || '');
        setText(newsletterLayoutBlockQuery.data?.text || '');
    }, [newsletterLayoutBlockQuery.data?.name, newsletterLayoutBlockQuery.data?.text]);

    const updateNewsletterLayoutBlockMutation = useMutation({
        mutationFn: () => api.updateNewsletterLayoutBlock({nllbId: props.nllbId!, name, text}),
    });

    const createNewsletterLayoutBlockMutation = useMutation({
        mutationFn: () => api.createNewsletterLayoutBlock({nllId: props.nllId, name, text}),
    });

    const onUpdate = () => {
        updateNewsletterLayoutBlockMutation.mutate(undefined, {
            onSuccess: () => {
                props.onClose();
            },
            onError: (error) => {
                console.log('Error updating newsletter layout block: ' + JSON.stringify(error));
            },
        });
    };

    const onCreate = () => {
        createNewsletterLayoutBlockMutation.mutate(undefined, {
            onSuccess: () => {
                props.onClose();
            },
            onError: (error) => {
                console.log('Error creating newsletter layout block: ' + JSON.stringify(error));
            },
        });
    };

    return (
        <Modal isOpen={true} toggle={props.onClose} size="lg">
            <ModalHeader>
                {props.mode === 'create' ? 'Newsletter Layout Block anlegen' : 'Newsletter Layout Block bearbeiten'}
            </ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label>Name</Label>
                    <Input value={name} onChange={(e) => setName(e.target.value)} />
                </FormGroup>
                <FormGroup>
                    <Label>Text</Label>
                    <textarea
                        className="form-control"
                        rows={6}
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                    />
                    <FormText>
                        Der Text des Blocks muss HTML-Code enthalten und kann folgende Variablen verwenden: #{'{'}title
                        {'}'}, #{'{'}text{'}'}, #{'{'}image_link{'}'}, #{'{'}link{'}'}, #{'{'}user.correspondenceName
                        {'}'}
                    </FormText>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button onClick={props.onClose}>Abbrechen</Button>
                {props.mode === 'edit' && (
                    <Button onClick={onUpdate} color="success">
                        Speichern
                    </Button>
                )}
                {props.mode === 'create' && (
                    <Button onClick={onCreate} color="success">
                        Anlegen
                    </Button>
                )}
            </ModalFooter>
        </Modal>
    );
};
