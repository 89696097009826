import React, { useEffect, useState } from 'react';
import { IncrementalSearch, KeyNameValue } from '../IncrementalSearch';
import { getBettingUserApi2, isDefaultSport } from '../../System/systemConfig';
import { useQuery } from '@tanstack/react-query';
import { Team } from '../../../services/betting';

const teamFilter = (t: Team, s: string): boolean => {
    return t.name != null && t.name.toLowerCase().indexOf(s) !== -1;
};

interface Props {
    onTeamSelected: (teamId: string) => void;
}

export const IncrementalTeamSearch = (props: Props) => {
    const [availableTeams, setAvailableTeams] = useState<Team[]>();

    const bettingUserApi = getBettingUserApi2();
    const availableTeamsQuery = useQuery({
        queryKey: ['availableTeams'],
        queryFn: () => bettingUserApi.getFavoriteTeamsAvailable(),
    });

    useEffect(() => {
        setAvailableTeams(availableTeamsQuery.data);
    }, [availableTeamsQuery.data]);

    const getTeamSearchName = (t: Team) => {
        if (!isDefaultSport(t)) {
            return t.name + ' (' + t.sportName + ')';
        } else {
            return t.name;
        }
    };

    const showList = (search: string) => {
        const lowerSearch = search.toLowerCase();
        if (availableTeams) {
            return availableTeams
                .filter((t) => teamFilter(t, lowerSearch))
                .map((t) => ({ key: t.id, name: getTeamSearchName(t) }));
        } else {
            return [];
        }
    };

    const onSelected = (team: KeyNameValue) => {
        props.onTeamSelected(team.key);
    };

    const availableTeamLoaded = (): boolean => {
        return !!availableTeams && availableTeams.length > 0;
    };

    return (
        <>
            <IncrementalSearch selectList={showList} onSelected={onSelected} isLoaded={availableTeamLoaded} />
        </>
    );
};
