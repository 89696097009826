import React, { FC } from 'react';


export const withTitleAsComponent = <P extends object>(
    Title: FC
) => (Comp: React.ComponentType<P>): React.ComponentType<P> => (props: P) => {
    return (
        <> 
            <Title/>
            <Comp {...props}/>
        </>
    );
};
