import { useMutation, useQuery } from '@tanstack/react-query';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Button, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { usePathSegment } from '../../../components/SysAdminApp/CacheStatisticsComponent';
import { getTeamApi2 } from '../../../components/System/systemConfig';
import { ButtonWithConfirmation } from '../../../components/common/ButtonWithConfirmation';
import { IncrementalTeamSearch } from '../../../components/common/IncrementalTeamSearch';
import { ExternalKey } from '../../../services/betting';
import { AFTeamResponse, AFTeamSet } from '../../apifootball-model';
import { apiSportsLivePath, getApiFootball } from '../live';

interface TeamLinkProps extends PropsWithChildren {
    id: number;
    name: string;
}

export const ApiFootballTeamLink: FC<TeamLinkProps> = (props) => {
    return (
        <>
            <Link to={apiFootballTeamPath(props.id)}>{props.children}</Link>
            <ApiFootballTeamExternalKeyAssignment
                provider="apiFootball"
                externalKey={String(props.id)}
                name={props.name}
            />
        </>
    );
};

export const ApiFootballTeam: FC<{}> = () => {
    const [teamId] = usePathSegment(3);

    const fetchTeam = async (teamId: string): Promise<AFTeamResponse> => {
        const apiFootball = await getApiFootball();
        return apiFootball.get('/teams?id=' + teamId).then((response) => response.data);
    };

    const [team, setTeam] = useState<AFTeamSet>();
    const [externalKeys, setExternalKeys] = useState<ExternalKey[]>();

    const teamQuery = useQuery({
        queryKey: ['team', teamId],
        queryFn: () => fetchTeam(teamId),
    });

    useEffect(() => {
        if (teamQuery.data) {
            setTeam(teamQuery.data.response[0]);
        }
    }, [teamQuery.data]);

    const externalKeysQuery = useQuery({
        queryKey: ['teamExternalKeys', teamId],
        queryFn: () => getTeamApi2().findExternalKeys({externalKey: teamId}),
    });

    useEffect(() => {
        if (externalKeysQuery.data) {
            setExternalKeys(externalKeysQuery.data);
        }
    }, [externalKeysQuery.data]);

    const deleteExternalKeyMutation = useMutation({
        mutationFn: (p: { teamId: string; externalKeyId: string }) =>
            getTeamApi2().deleteExternalKey({teamId: p.teamId, id: p.externalKeyId}),
    });

    const deleteExternalkey = (teamId: string, externalKeyId: string) => {
        deleteExternalKeyMutation.mutate({ teamId, externalKeyId });
        externalKeysQuery.refetch();
    };

    return (
        <>
            {teamQuery.isSuccess && (
                <>
                    <h2>Team {team?.team?.name}</h2>
                    <Container>
                        <Row>
                            <Col>Land</Col>
                            <Col>{team?.team.country}</Col>
                        </Row>
                        <Row>
                            <Col>Gründung</Col>
                            <Col>{team?.team.founded}</Col>
                        </Row>
                        <Row>
                            <Col>Logo</Col>
                            <Col>
                                <img src={team?.team.logo} alt="" />
                            </Col>
                        </Row>
                        <Row>
                            <Col>Stadion</Col>
                            <Col>{team?.venue.name}</Col>
                        </Row>
                        <Row>
                            <Col>Kapazität</Col>
                            <Col>{team?.venue.capacity}</Col>
                        </Row>
                        <Row>
                            <Col>Foto</Col>
                            <Col>
                                <img src={team?.venue?.image} alt="" />
                            </Col>
                        </Row>
                        <Row>
                            <Col>External Keys</Col>
                            <Col>
                                <Container>
                                    {externalKeys &&
                                        externalKeys.map((ek) => (
                                            <Row>
                                                <Col>{ek.provider}</Col>
                                                <Col>{ek.key}</Col>
                                                <Col>
                                                    <ButtonWithConfirmation
                                                        onOK={() => deleteExternalkey(ek.entityId ?? '', ek.id ?? '')}
                                                        message="Are you sure to delete the ExternalKey?"
                                                        title="Delete ExternalKey"
                                                    >
                                                        Delete
                                                    </ButtonWithConfirmation>
                                                </Col>
                                            </Row>
                                        ))}
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                </>
            )}
            <Button onClick={() => teamQuery.refetch()}>Refresh</Button>{' '}
            <Link to={apiSportsLivePath()}>Live-Spiele</Link>
        </>
    );
};

export const apiFootballTeamPath = (teamId: number) => `/a/team/${teamId}`;

interface ExternalKeyAssignmentProps {
    externalKey: string;
    provider: string;
    name: string;
}

export const ApiFootballTeamExternalKeyAssignment: FC<ExternalKeyAssignmentProps> = (props) => {
    const [isOpen, setOpen] = useState(false);
    const [teamExist, setTeamExist] = useState(false);
    const [selectedTeamId, setSelectedTeamId] = useState<string>();

    const teamApi = getTeamApi2();

    const teamSearchQuery = useQuery({
        queryKey: ['teamSearchByNameAndKey', props.externalKey],
        queryFn: () => teamApi.findByExternalKey({key: props.externalKey, externalKeyProvider: props.provider}),
        retry: false,
    });

    useEffect(() => {
        if (teamSearchQuery.data) {
            setTeamExist(teamSearchQuery.data.statusCode !== -1);
        }
    }, [teamSearchQuery.data]);

    const assignKeyMutation = useMutation({
        mutationFn: () => teamApi.assignExternalKey({teamId: selectedTeamId || '', key: props.externalKey, externalKeyProvider: props.provider}),

        onSuccess: () => {
            console.info('success');
            setOpen(false);
            teamSearchQuery.refetch();
        },
    });

    const cancel = () => {
        setOpen(false);
    };

    const submit = () => {
        if (selectedTeamId) {
            assignKeyMutation.mutate();
        }
    };

    const teamSelected = (teamId: string) => {
        setSelectedTeamId(teamId);
    };

    return (
        <>
            {!teamExist && (
                <Button color="link" onClick={() => setOpen(true)}>
                    <Badge color="danger" className="m-1">
                        !
                    </Badge>
                </Button>
            )}

            <Modal isOpen={isOpen}>
                <ModalHeader>
                    Externe ID für Team '{props.name}'' für Provider '{props.provider}' hinzufügen
                </ModalHeader>
                <ModalBody>
                    <IncrementalTeamSearch onTeamSelected={teamSelected} />
                </ModalBody>
                <ModalFooter>
                    <Button color="success" disabled={!selectedTeamId} onClick={submit}>
                        Speichern
                    </Button>
                    <Button onClick={cancel}>Abbrechen</Button>
                </ModalFooter>
            </Modal>
        </>
    );
};
