import React from 'react';
import { Section, SectionHeading } from '../../../common/Section';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getNewsletterAdminApi, getSystemConfig } from '../../../System/systemConfig';
import { Button, Table } from 'reactstrap';
import { NewsletterReceiverFilter } from '../../../../services/webapp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faRefresh, faTrash } from '@fortawesome/free-solid-svg-icons';
import { LoadingIndicatorSpinner2 } from '../../../common/LoadingIndicatorSpinner2';
import { ButtonWithConfirmation } from '../../../common/ButtonWithConfirmation';
import { NewsletterReceiverFilterEdit } from '../NewsletterReceiverFilterEdit';
import { useDispatch } from 'react-redux';
import { systemSetPopupMessage } from '../../../System/actions';

export const newsletterReceiverFilterPath = () => `/client-admin/newsletter/receiver-filters`;

const NewsletterReceiverFilters: React.FC = () => {
    const api = getNewsletterAdminApi();

    const [editReceiverFilterId, setEditReceiverFilterId] = React.useState<string | undefined>(undefined);
    const [createReceiverFilter, setCreateReceiverFilter] = React.useState<boolean>(false);

    const dispatch = useDispatch();

    const receiverFilterQuery = useQuery({
        queryKey: ['receiverFilter', getSystemConfig().clientId],
        queryFn: () => api.getReceiverFilters({clientId: String(getSystemConfig().clientId)}),
    });

    const refreshUsageMutation = useMutation({
        mutationFn: (id: string) => api.refreshNewsletterReceiverFilterCount({nlrfId: id}),
        onSuccess: () => {
            receiverFilterQuery.refetch();
        },
    });

    const deleteReceiverFilterMutation = useMutation({
        mutationFn: (id: string) => api.deleteNewsletterReceiverFilter({nlrfId: id}),
    });

    const onRefreshUsage = (id: string) => {
        refreshUsageMutation.mutate(id);
    };

    const onDelete = (id: string) => {
        deleteReceiverFilterMutation.mutate(id, {
            onSuccess: () => {
                receiverFilterQuery.refetch();
            },
            onError: (error) => {
                dispatch(systemSetPopupMessage('Fehler beim Löschen des Empfängerfilters: ' + error));
            },
        });
    };

    const onCloseEditDialog = () => {
        setEditReceiverFilterId(undefined);
        setCreateReceiverFilter(false);
        receiverFilterQuery.refetch();
    };

    const renderReceiverFilter = (receiverFilter: NewsletterReceiverFilter) => {
        return (
            <tr key={receiverFilter.id}>
                <td>
                    <Button
                        color="link"
                        className="text-start ms-0 ps-0 text-nowrap"
                        onClick={() => setEditReceiverFilterId(receiverFilter.id)}
                    >
                        {receiverFilter.name}
                    </Button>
                </td>
                <td>{receiverFilter.receiverCount}</td>
                <td className="text-break">{receiverFilter.nameFilter}</td>
                <td className="text-break">{receiverFilter.emailFilter}</td>
                <td className="">{receiverFilter.usageCount}</td>
                <td>
                    <Button
                        onClick={() => onRefreshUsage(receiverFilter.id)}
                        title="Verwendung aktualisieren"
                        size="sm"
                        className="m-1 border"
                    >
                        <FontAwesomeIcon icon={faRefresh} />{' '}
                    </Button>
                    {receiverFilter.usageCount === 0 && (
                        <ButtonWithConfirmation
                            onClick={() => {}}
                            size="sm"
                            className="m-1 border"
                            onOK={() => onDelete(receiverFilter.id)}
                            title="Empfängerfilter löschen"
                            message={`Sie Sie sicher, dass Sie den Empfängerfilter '${receiverFilter.name}' löschen wollen?`}
                        >
                            <FontAwesomeIcon icon={faTrash} color="red" />
                        </ButtonWithConfirmation>
                    )}
                </td>
            </tr>
        );
    };

    return (
        <Section>
            <LoadingIndicatorSpinner2 isLoading={receiverFilterQuery.isFetching || refreshUsageMutation.isPending} />
            <SectionHeading>Newsletter Empfängerfilter</SectionHeading>
            {editReceiverFilterId && (
                <NewsletterReceiverFilterEdit mode="edit" nlrfId={editReceiverFilterId!} onClose={onCloseEditDialog} />
            )}
            {createReceiverFilter && (
                <NewsletterReceiverFilterEdit mode="create" nlrfId={undefined} onClose={onCloseEditDialog} />
            )}
            <Button onClick={() => setCreateReceiverFilter(true)} className="m-1" color="success">
                <FontAwesomeIcon icon={faPlus} /> Empfängerfilter anlegen
            </Button>
            <Table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Empfänger</th>
                        <th>Namens-Filter</th>
                        <th>Email-Filter</th>
                        <th>Verwendung</th>
                        <th>Aktionen</th>
                    </tr>
                </thead>
                <tbody>{receiverFilterQuery.data?.map((receiverFilter) => renderReceiverFilter(receiverFilter))}</tbody>
            </Table>
        </Section>
    );
};

export const NewsletterReceiverFilterScreen = NewsletterReceiverFilters;
