import { all, put, select, takeLatest } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { MatchStatistic } from '../../../services/betting';
import { fetchWrapperFunc } from '../../../utils';
import { getGameApi2 } from '../../System/systemConfig';
import {
    MatchStatisticGameParams,
    matchStatisticGamesSet,
    matchStatisticGamesSetCurrent,
    matchStatisticLoadGames,
    matchStatisticGamesSetGameCount,
    matchStatisticSet,
    MS_GAMES_FORWARD,
    MS_LOAD,
    MS_LOAD_GAMES,
    MS_GAMES_BACKWARD,
    MS_GAMES_FAST_FORWARD,
    MS_GAMES_FAST_BACKWARD
} from './actions';
import {
    matchStatisticCountSelector,
    matchStatisticCurrentOffsetSelector,
    matchStatisticEntitySelector,
    matchStatisticGameCountSelector
} from './selectors';

function* loadGenerator(action: Action<{ team1: string; team2: string }>) {
    const team1 = action.payload.team1;
    const team2 = action.payload.team2;

    yield* fetchWrapperFunc(() => getGameApi2().getMatchStatistic({team1Id: team1, team2Id: team2}), [r => matchStatisticSet(r)]);
}

function* loadGamesGenerator(action: Action<MatchStatisticGameParams>) {
    const team1 = action.payload.team1Id;
    const team2 = action.payload.team2Id;
    const count = action.payload.count;
    const offset = action.payload.offset;

    yield* fetchWrapperFunc(() => getGameApi2().getMatches({team1Id: team1, team2Id: team2, count, offset}), [
        r => matchStatisticGamesSet(r)
    ]);

    yield* fetchWrapperFunc(() => getGameApi2().countMatches({team1Id: team1, team2Id: team2}),
    [r => matchStatisticGamesSetGameCount(r)]);

    yield put(matchStatisticGamesSetCurrent(offset));
}

function* gamesForwardGenerator() {
    const stats: MatchStatistic | undefined = yield select(matchStatisticEntitySelector);
    const currentOffset: number = yield select(matchStatisticCurrentOffsetSelector);
    const count: number = yield select(matchStatisticCountSelector);
    const gameCount: number = yield select(matchStatisticGameCountSelector);
    if (stats) {
        const newOffset = currentOffset + count;
        if (newOffset < gameCount) {
            yield put(
                matchStatisticLoadGames({
                    team1Id: stats.team1.id,
                    team2Id: stats.team2.id,
                    count,
                    offset: newOffset
                })
            );
        }
    }
}

function* gamesFastForwardGenerator() {
    const stats: MatchStatistic | undefined = yield select(matchStatisticEntitySelector);
    const count: number = yield select(matchStatisticCountSelector);
    const gameCount: number = yield select(matchStatisticGameCountSelector);
    if (stats) {
        const newOffset = Math.floor((gameCount - 1) / count) * count;
        if (newOffset < gameCount) {
            yield put(
                matchStatisticLoadGames({
                    team1Id: stats.team1.id,
                    team2Id: stats.team2.id,
                    count,
                    offset: newOffset
                })
            );
        }
    }
}
function* gamesBackwardGenerator() {
    const stats: MatchStatistic | undefined = yield select(matchStatisticEntitySelector);
    const currentOffset: number = yield select(matchStatisticCurrentOffsetSelector);
    const count: number = yield select(matchStatisticCountSelector);
    if (stats) {
        const newOffset = currentOffset - count;
        if (newOffset >= 0) {
            yield put(
                matchStatisticLoadGames({
                    team1Id: stats.team1.id,
                    team2Id: stats.team2.id,
                    count,
                    offset: newOffset
                })
            );
        }
    }
}

function* gamesFastBackwardGenerator() {
    const stats: MatchStatistic | undefined = yield select(matchStatisticEntitySelector);
    const count: number = yield select(matchStatisticCountSelector);
    if (stats) {
        const newOffset = 0;
        if (newOffset >= 0) {
            yield put(
                matchStatisticLoadGames({
                    team1Id: stats.team1.id,
                    team2Id: stats.team2.id,
                    count,
                    offset: newOffset
                })
            );
        }
    }
}
function* watchMSActions() {
    yield takeLatest(MS_LOAD, loadGenerator);
    yield takeLatest(MS_LOAD_GAMES, loadGamesGenerator);
    yield takeLatest(MS_GAMES_FORWARD, gamesForwardGenerator);
    yield takeLatest(MS_GAMES_BACKWARD, gamesBackwardGenerator);
    yield takeLatest(MS_GAMES_FAST_FORWARD, gamesFastForwardGenerator);
    yield takeLatest(MS_GAMES_FAST_BACKWARD, gamesFastBackwardGenerator);
}

export function* matchStatisticSagas() {
    yield all([watchMSActions()]);
}
