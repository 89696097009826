import React, { FC } from 'react';
import { Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { push } from '@lagunovsky/redux-react-router';
import { highscoresPath } from '../../Highscores';
import { userGroupHighscorePath, userGroupListPath } from '../../UserGroupHighscores';
import { userListPath } from '../../UserList';
import { tournamentSelectionSelectedTournamentRoundIdSelector } from '../../TournamentSelectionComponent/selectors';
import { isFuntip, isRadioEins } from '../../System/systemConfig';

interface Props {
    activeSubMenu: 'highscores' | 'group-highscore' | 'user-directory' | 'team-directory';
}

export const SubMenuOverallHihgscores: FC<Props> = props => {
    const currentTrId = useSelector(tournamentSelectionSelectedTournamentRoundIdSelector('highscores')) ?? '';
    const dispatch = useDispatch();

    return (
        <div className="mb-4">
            <Button
                color={props.activeSubMenu === 'highscores' ? 'warning' : 'secondary'}
                className="me-2 my-1"
                onClick={() => dispatch(push(highscoresPath(currentTrId)))}
            >
                Einzelwertung
            </Button>
            <Button
                color={props.activeSubMenu === 'group-highscore' ? 'warning' : 'secondary'}
                className="me-2 my-1"
                onClick={() => dispatch(push(userGroupHighscorePath(currentTrId)))}
            >
                Teamwertung
            </Button>
            <Button
                color={props.activeSubMenu === 'user-directory' ? 'warning' : 'secondary'}
                className="me-2 my-1"
                onClick={() => dispatch(push(userListPath()))}
            >
                Alle {isFuntip() && 'Mitspieler'}
                {isRadioEins() && 'Hörer'}
            </Button>
            <Button
                color={props.activeSubMenu === 'team-directory' ? 'warning' : 'secondary'}
                className="me-2 my-1"
                onClick={() => dispatch(push(userGroupListPath()))}
            >
                Alle Teams
            </Button>
        </div>
    );
};
