import { useQuery, useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import styled from 'styled-components';
import { isGameLive } from '../../GamesList';
import { getGameApi2 } from '../../System/systemConfig';
import { LoadingIndicatorSpinner2 } from '../../common/LoadingIndicatorSpinner2';

interface Props {
    gameId: string;
    date?: Date;
}

const LiveGameStatusIcon = styled.div`
    position: absolute;
    width: 8px;
    height: 8px;
    top: 5px;
    right: 5px;
    border-radius: 50%;

    background-color: lime;
    border-style: none;
`;

const BreakGameStatusIcon = styled(LiveGameStatusIcon)`
    background-color: orange;
`;

export const GameResult: React.FC<Props> = (props) => {
    const gameResultQuery = useQuery({
        queryKey: ['gameResults', props.gameId],
        queryFn: () => getGameApi2().getGameState({gameId: props.gameId}),
        refetchInterval: 60000,
        refetchOnWindowFocus: false,
    });

    const queryClient = useQueryClient();

    const [gameState, setGameState] = React.useState<string>();

    const [result1, setResult1] = React.useState('-');
    const [result2, setResult2] = React.useState('-');

    const [penaltyResult1, setPenaltyResult1] = React.useState<string | undefined>(undefined);
    const [penaltyResult2, setPenaltyResult2] = React.useState<string | undefined>(undefined);

    const checkForUpdates = React.useCallback(() => {
        if (props.date && isGameLive(props.date)) {
            gameResultQuery.refetch();
        }
    }, [props.date, gameResultQuery]);

    React.useEffect(() => {
        if (props.date && queryClient) {
            const interval = setInterval(() => checkForUpdates(), 59 * 1000);
            return () => clearInterval(interval);
        }
    }, [props, queryClient, checkForUpdates]);

    React.useEffect(() => {
        if (gameResultQuery.data && gameResultQuery.data.gameResults) {
            let r = gameResultQuery.data.gameResults.find((gr) => gr.type === 'EXTRA_TIME');
            if (!r) {
                r = gameResultQuery.data.gameResults.find((gr) => gr.type === 'REGULAR_TIME');
            }
            if (!r) {
                r = gameResultQuery.data.gameResults.find((gr) => gr.type === 'HALF_TIME');
            }
            if (r) {
                setResult1(String(r.result1) || '!');
                setResult2(String(r.result2) || '-');
            }
            const penaltyResult = gameResultQuery.data.gameResults.find((gr) => gr.type === 'PENALTY_SHOOTOUT');
            if (penaltyResult) {
                setPenaltyResult1(String(penaltyResult.result1));
                setPenaltyResult2(String(penaltyResult.result2));
            } else {
                setPenaltyResult1(undefined);
                setPenaltyResult2(undefined);
            }

            if (gameResultQuery.data.state) {
                setGameState(gameResultQuery.data.state);
            }
            /*  
            switch (data.state) {
                case 2:
                case 6:
                    setGameState('paused');
                    break;
                case 1:
                case 3:
                case 5:
                case 7:
                case 9:
                    setGameState('playing');
                    break;
                case 98:
                case 99:
                case 10:
                case 8:
                case 4:
                    setGameState('finished');
                    break;
                default:
                    setGameState('scheduled');
            }
            */
        } else {
            setResult1('-');
            setResult2('-');
            setPenaltyResult1(undefined);
            setPenaltyResult2(undefined);
            setGameState(undefined);
        }
    }, [gameResultQuery.data]);

    const isPlaying = () => {
        switch (gameState) {
            case 'firstHalf':
            case 'secondHalf':
            case 'extraTimeFirstHalf':
            case 'extraTimeSecondHalf':
            case 'penaltyStarted':
                return true;
            default:
                return false;
        }
    };

    const isBreak = () => {
        switch (gameState) {
            case 'halfTime':
            case 'extraTimeHalfTime':
                return true;
            default:
                return false;
        }
    };

    return (
        <>
            <LoadingIndicatorSpinner2 isLoading={gameResultQuery.isLoading}>
                <span
                    className="btn-r1--gameresult px-sm-3 m-0 text-nowrap"
                    style={{ minWidth: '65px', position: 'relative' }}
                >
                    {result1}&nbsp;:&nbsp;{result2}
                    {isPlaying() && <LiveGameStatusIcon />}
                    {isBreak() && <BreakGameStatusIcon />}
                </span>
                {penaltyResult1 && penaltyResult2 && (
                    <span className="mx-2 fontSize">
                        <small>
                            {penaltyResult1}:{penaltyResult2}&nbsp;n.E.
                        </small>
                    </span>
                )}
            </LoadingIndicatorSpinner2>
        </>
    );
};
