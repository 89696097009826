import { push } from '@lagunovsky/redux-react-router';
import classnames from 'classnames';
import React, { FC, PropsWithChildren } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Container, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

export type TabbedRequestParams = {
    tabName: string;
};

export interface TabbedParams<P extends TabbedRequestParams> {
    tabNames: string[];
    tabLinks: (params: P) => string;
}

export interface TabbedContentProps extends PropsWithChildren{
    tabNames: string[];
    tabLinks: (tabName: string) => string;
    activeTab: string;
}

export const TabbedContent: FC<TabbedContentProps> = props => {
    const isActiveTab = (tabName: string) => props.activeTab === tabName;

    const dispatch = useDispatch();
    return (
        <div className="pe-2 pe-lg-4" >
            {props.tabNames.map(tab => (
                <Button
                    className="me-2 my-1"
                    color={isActiveTab(tab) ? 'warning' : 'secondary'}
                    onClick={() => dispatch(push(props.tabLinks(tab)))}
                    key={tab}
                >
                    {tab}
                </Button>
            ))}
            {props.children}
        </div>
    );
};

export const withTabbedScreen = <P extends TabbedRequestParams>(tabProps: TabbedParams<P>) => (
    Comp: React.ComponentType<P>
): React.ComponentType<P> => {
    const r = class TabbedScreenComponentBase extends React.Component<P> {
        public getActiveTabName() {
            const path = window.location.pathname;
            return tabProps.tabNames.find(tn => tabProps.tabLinks({ ...this.props, tabName: tn }) === path);
        }

        public isActiveTab(tabName: string) {
            const path = window.location.pathname;
            return tabProps.tabLinks({ ...this.props, tabName }) === path;
        }

        public render() {
            return (
                <Container className="ps-2 pe-2 ps-lg-4 pe-lg-4">
                    <Nav tabs={true}>
                        {tabProps.tabNames.map(tab => (
                            <NavItem key={tab}>
                                <NavLink
                                    className={classnames({ active: this.isActiveTab(tab) })}
                                    tag={Link}
                                    to={tabProps.tabLinks({ ...this.props, tabName: tab })}
                                >
                                    {tab}
                                </NavLink>
                            </NavItem>
                        ))}
                    </Nav>
                    <TabContent activeTab={this.getActiveTabName()}>
                        {tabProps.tabNames.map(tab => (
                            <TabPane tabId={tab} key={tab}>
                                {this.isActiveTab(tab) && <Comp {...this.props} tabName={tab} />}
                            </TabPane>
                        ))}
                    </TabContent>
                </Container>
            );
        }
    };
    return r as React.ComponentType<P>;
};
