import { GlobalState } from '../../actions';

export const bettingViewSelector = (state: GlobalState) => state.bettingView;

export const bettingViewEntitySelector = (state: GlobalState) => bettingViewSelector(state).gameBets;
export const bettingViewParamsSelector = (state: GlobalState) => bettingViewSelector(state).params;
export const bettingViewTrIdSelector = (state: GlobalState) => {
    const params = bettingViewSelector(state).params;
    return params ? params.trId : undefined;
};

export const bettingViewUserSelector = (state: GlobalState) => bettingViewSelector(state).user;