import { push } from '@lagunovsky/redux-react-router';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { userViewBettingPath, userViewPlaylistPath, userViewProfilePath } from '..';
import { isRadioEins } from '../../System/systemConfig';
import { userViewUserIdSelector } from '../selectors';

export type UserViewMenuSelection = 'profile' | 'playlist' | 'betting';

interface Props {
    activeSubMenu: UserViewMenuSelection;
}

export const UserViewMenu: React.FC<Props> = props => {
    const dispatch = useDispatch();

    const userId = useSelector(userViewUserIdSelector);

    if (!userId) {
        return null;
    }
    return (
        <div className="mb-4">
            <Button
                color={props.activeSubMenu === 'profile' ? 'warning' : 'secondary'}
                className="me-2 my-1"
                onClick={() => dispatch(push(userViewProfilePath(userId)))}
            >
                Profil
            </Button>
            {isRadioEins() && (
                <Button
                    color={props.activeSubMenu === 'playlist' ? 'warning' : 'secondary'}
                    className="me-2 my-1"
                    onClick={() => dispatch(push(userViewPlaylistPath(userId)))}
                >
                    Playlists
                </Button>
            )}
            <Button
                color={props.activeSubMenu === 'betting' ? 'warning' : 'secondary'}
                className="me-2 my-1"
                onClick={() => dispatch(push(userViewBettingPath(userId)))}
            >
                Tippspiel
            </Button>
        </div>
    );
};
