import actionCreatorFactory from 'typescript-fsa';
import { UserMail } from '../../../services/betting';

const actionCreator = actionCreatorFactory();

export const USER_MAIL_LOAD = 'USER_MAIL_LOAD';
export const userMailLoad = actionCreator(USER_MAIL_LOAD);

export const USER_MAIL_SET = 'USER_MAIL_SET';
export const userMailSet = actionCreator<UserMail[]>(USER_MAIL_SET);

export type MailToSend = {
    receiver: string;
    subject: string;
    message: string;
};

export const USER_MAIL_SET_CURRENT_POS = 'USER_MAIL_SET_CURRENT_POS';
export const userMailSetCurrentPos = actionCreator<number>(USER_MAIL_SET_CURRENT_POS);

export const USER_MAIL_SET_COUNT = 'USER_MAIL_SET_COUNT';
export const userMailSetCount = actionCreator<number>(USER_MAIL_SET_COUNT);

export const USER_MAIL_SEND = 'USER_MAIL_SEND';
export const userMailSend = actionCreator<MailToSend>(USER_MAIL_SEND);

export const USER_MAIL_BACKWARD = 'USER_MAIL_BACKWARD';
export const userMailBackward = actionCreator(USER_MAIL_BACKWARD);

export const USER_MAIL_FORWARD = 'USER_MAIL_FORWARD';
export const userMailForward = actionCreator(USER_MAIL_FORWARD);

export const USER_MAIL_FAST_FORWARD = 'USER_MAIL_FAST_FORWARD';
export const userMailFastForward = actionCreator(USER_MAIL_FAST_FORWARD);

export const USER_MAIL_FAST_BACKWARD = 'USER_MAIL_FAST_BACKWARD';
export const userMailFastBackward = actionCreator(USER_MAIL_FAST_BACKWARD);

export const USER_MAIL_DELETE = 'USER_MAIL_DELETE';
export const userMailDelete = actionCreator<string>(USER_MAIL_DELETE);

export const USER_MAIL_RECEIVER_NAME_VALID = 'USER_MAIL_RECEIVER_NAME_VALID';
export const userMailSetReceiverNameValid = actionCreator<Boolean>(USER_MAIL_RECEIVER_NAME_VALID);

export const USER_MAIL_CHECK_RECEIVER_NAME = 'USER_MAIL_CHECK_RECEIVER_NAME';
export const userMailCheckReceiverName = actionCreator<string>(USER_MAIL_CHECK_RECEIVER_NAME);
