/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RestResult
 */
export interface RestResult {
    /**
     * 
     * @type {number}
     * @memberof RestResult
     */
    statusCode?: number;
    /**
     * 
     * @type {string}
     * @memberof RestResult
     */
    message?: string;
}

/**
 * Check if a given object implements the RestResult interface.
 */
export function instanceOfRestResult(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RestResultFromJSON(json: any): RestResult {
    return RestResultFromJSONTyped(json, false);
}

export function RestResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): RestResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'statusCode': !exists(json, 'statusCode') ? undefined : json['statusCode'],
        'message': !exists(json, 'message') ? undefined : json['message'],
    };
}

export function RestResultToJSON(value?: RestResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'statusCode': value.statusCode,
        'message': value.message,
    };
}

