import { useIsFetching } from '@tanstack/react-query';
import React, { FC } from 'react';
import { getSystemConfig } from '../systemConfig';

export const GlobalFetchState: FC<{}> = () => {

    const isProduction = getSystemConfig().isProduction();
    const isFetching = useIsFetching();

    if (isFetching && !isProduction) {
        return (
            <span 
                style={{
                    display: 'inline-flex',
                    color: 'black',
                    backgroundColor: 'orange',
                    borderRadius: '50%',
                    width: '20px',
                    height: '20px',
                    padding: '0px',
                    margin: '0px',
                    fontSize: '14px',
                    textAlign: 'center',
                    verticalAlign: 'middle',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'absolute',
                    left: 0,
                    top: 0
                }}
            >
                {isFetching}
            </span>
        );
    } else {
        return null;
    }
};
