import { AnyAction, isType } from 'typescript-fsa';
import { UserMail } from '../../../services/betting';
import { userMailSet, userMailSetCurrentPos, userMailSetCount, userMailSetReceiverNameValid } from './actions';

export interface UserMailState {
    mails: UserMail[];
    mailCount: number;
    currentPos: number;
    slotSize: number;

    receiverNameValid: boolean;
}

const initialUserMailState: UserMailState = {
    mails: [],
    mailCount: 0,
    currentPos: 0,
    slotSize: 10,
    receiverNameValid: false
};

export function userMailReducer(state: UserMailState = initialUserMailState, action: AnyAction): UserMailState {
    if (isType(action, userMailSet)) {
        return { ...state, mails: action.payload };
    } else if (isType(action, userMailSetCurrentPos)) {
        return { ...state, currentPos: action.payload };
    } else if (isType(action, userMailSetCount)) {
        return { ...state, mailCount: action.payload };
    } else if (isType(action, userMailSetReceiverNameValid)) {
        return { ...state, receiverNameValid: action.payload as boolean };
    }

    return state;
}
