/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.25
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Newsletter
 */
export interface Newsletter {
    /**
     * 
     * @type {string}
     * @memberof Newsletter
     */
    id: string;
    /**
     * 
     * @type {Date}
     * @memberof Newsletter
     */
    sendingStartDate: Date;
    /**
     * 
     * @type {string}
     * @memberof Newsletter
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Newsletter
     */
    firstBlockText: string;
    /**
     * 
     * @type {string}
     * @memberof Newsletter
     */
    firstImageId: string;
}

/**
 * Check if a given object implements the Newsletter interface.
 */
export function instanceOfNewsletter(value: object): value is Newsletter {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('sendingStartDate' in value) || value['sendingStartDate'] === undefined) return false;
    if (!('title' in value) || value['title'] === undefined) return false;
    if (!('firstBlockText' in value) || value['firstBlockText'] === undefined) return false;
    if (!('firstImageId' in value) || value['firstImageId'] === undefined) return false;
    return true;
}

export function NewsletterFromJSON(json: any): Newsletter {
    return NewsletterFromJSONTyped(json, false);
}

export function NewsletterFromJSONTyped(json: any, ignoreDiscriminator: boolean): Newsletter {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'sendingStartDate': (new Date(json['sendingStartDate'])),
        'title': json['title'],
        'firstBlockText': json['firstBlockText'],
        'firstImageId': json['firstImageId'],
    };
}

export function NewsletterToJSON(value?: Newsletter | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'sendingStartDate': ((value['sendingStartDate']).toISOString()),
        'title': value['title'],
        'firstBlockText': value['firstBlockText'],
        'firstImageId': value['firstImageId'],
    };
}

