import {
    setUserList,
    setUserListCount,
    setUserListStartPos,
    setUserNextEnabled,
    setUserPriorEnabled,
    setUserSearchString
} from './actions';
import { isType } from 'typescript-fsa';
import { Action } from 'redux';
import { BettingUser } from '../../services/betting';

export interface UserListStore {
    users: BettingUser[];
    count: number;
    startPos: number;
    slotSize: number;
    searchString: string;
    priorEnabled: boolean;
    nextEnabled: boolean;
}

const initialUserListStore: UserListStore = {
    users: [],
    count: 0,
    startPos: 0,
    slotSize: 20,
    searchString: '',
    priorEnabled: false,
    nextEnabled: false
};

export function userListReducer(state: UserListStore = initialUserListStore, action: Action) {
    if (isType(action, setUserList)) {
        return Object.assign({}, state, {users: action.payload});

    } else if (isType(action, setUserListCount)) {
        return Object.assign({}, state, {count: action.payload});

    } else if (isType(action, setUserListStartPos)) {
        return Object.assign({}, state, {startPos: action.payload});

    } else if (isType(action, setUserSearchString)) {
        return Object.assign({}, state, {searchString: action.payload});

    } else if (isType(action, setUserPriorEnabled)) {
        return Object.assign({}, state, {priorEnabled: action.payload});

    } else if (isType(action, setUserNextEnabled)) {
        return Object.assign({}, state, {nextEnabled: action.payload});
    } else {
        return state;
    }
}
