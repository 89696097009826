import { useMutation, useQuery } from '@tanstack/react-query';
import { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Input,
    Label,
    Table,
} from 'reactstrap';
import { Client, ClientTournament, Tournament } from '../../../../services/betting';
import { systemSetPopupMessage } from '../../../System/actions';
import { getClientSysAdminApi, getTournamentApi2, getTournamentSysAdminApi } from '../../../System/systemConfig';
import { ButtonWithConfirmation } from '../../../common/ButtonWithConfirmation';
import { OnDemandConfirmationDialog } from '../../../common/ConfirmationDialog';
import { Section, SectionHeading } from '../../../common/Section';
import { ClientTournamentState } from './ClientTournamentState';
import { TournamentEditComp } from './TournamentEdit';
import { TournamentManagement } from './TournamentManagement';
import { LoadingIndicatorSpinner2 } from '../../../common/LoadingIndicatorSpinner2';
import { TournamentRoundEditModal } from './TournamentRoundManagement/TournamentRoundEdit';

export const SysAdminTournamentAppComp: FC<{}> = () => {
    const [allTournaments, setAllTournaments] = useState<Tournament[]>([]);
    const [filteredTournaments, setFilteredTournaments] = useState<Tournament[]>([]);
    const [tournamentFilter, setTournamentFilter] = useState('');

    const [showLoading, setShowLoading] = useState(false);

    const [allClients, setAllClients] = useState<Client[]>([]);
    const [showTournamentManagement, setShowTournamentManagement] = useState('');

    const [showOnlyActive, setShowOnlyActive] = useState(true);
    const [clientTournaments, setClientTournaments] = useState<ClientTournament[]>([]);

    const [editTournamentId, setEditTournamentId] = useState('');

    const tournamentApi = getTournamentSysAdminApi();
    const clientApi = getClientSysAdminApi();

    const dispatch = useDispatch();

    const [orderByClient, setOrderByClient] = useState('');

    const tournamentQuery = useQuery({
        queryKey: ['tournaments.list'],
        queryFn: () => tournamentApi.listTournaments(),
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        setAllTournaments(tournamentQuery.data ?? []);
    }, [tournamentQuery.data]);

    const clientQuery = useQuery({
        queryKey: ['clients.list'],
        queryFn: () => clientApi.listActiveClients(),
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        setAllClients(clientQuery.data ?? []);
    }, [clientQuery.data]);

    const clientTournamentsQuery = useQuery({
        queryKey: ['clientTournaments.list'],
        queryFn: () => tournamentApi.getClientTournaments(),
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        setClientTournaments(clientTournamentsQuery.data ?? []);
    }, [clientTournamentsQuery.data]);

    const deleteTournamentMutation = useMutation({
        mutationFn: (tId: string) => tournamentApi.deleteTournament({ tId }),
    });

    const getClientTournament = (tId: string, clId: string): ClientTournament | undefined => {
        return clientTournaments.find((ct) => String(ct.clientId) === clId && String(ct.tournamentId) === tId);
    };

    const onEditClose = () => {
        setEditTournamentId('');
        tournamentQuery.refetch();
    };

    const onDeleteTournament = (tId: string) => {
        setShowLoading(true);
        deleteTournamentMutation.mutate(tId, {
            onSuccess: () => {
                tournamentQuery.refetch();
                dispatch(systemSetPopupMessage('Tournament deleted.'));
                setShowLoading(false);
            },
            onError: () => {
                dispatch(systemSetPopupMessage('Error deleting tournament.'));
                setShowLoading(false);
            },
        });
    };

    const onClientTournamentRoundChange = () => {
        clientTournamentsQuery.refetch();
        tournamentQuery.refetch();
    };

    const onTournamentActionSuccess = () => {
        tournamentQuery.refetch();
    };

    const renderTournament = (t: Tournament) => {
        return (
            <Fragment key={t.id}>
                <tr>
                    <td>
                        <Button color="link" onClick={() => setShowTournamentManagement(String(t.id))}>
                            {t.name}
                        </Button>
                    </td>
                    <td>
                        {editTournamentId !== String(t.id) && (
                            <Button color="link" onClick={() => setEditTournamentId(String(t.id))}>
                                Edit
                            </Button>
                        )}
                        {editTournamentId === String(t.id) && (
                            <TournamentEditComp tId={String(t.id)} onClose={onEditClose} />
                        )}
                    </td>

                    <td>
                        {' '}
                        <ButtonWithConfirmation
                            color="link"
                            title="Delete Tournament"
                            message={`Are you sure you want to delete the tournament ${t.name}?`}
                            onOK={() => onDeleteTournament(String(t.id))}
                        >
                            Delete
                        </ButtonWithConfirmation>
                    </td>
                    <td>
                        <TournamentActions tId={String(t.id)} onSuccess={onTournamentActionSuccess} />
                    </td>
                    {allClients.map((cl) => (
                        <td key={cl.id} className="text-center">
                            <ClientTournamentState
                                tId={String(t.id)}
                                clId={String(cl.id)}
                                showReorderButtons={String(cl.id) === orderByClient && showOnlyActive}
                                ct={getClientTournament(String(t.id), String(cl.id))}
                                onRefresh={onClientTournamentRoundChange}
                            />
                        </td>
                    ))}
                </tr>
                {showTournamentManagement === String(t.id) && (
                    <tr>
                        <td colSpan={6}>
                            <TournamentManagement tId={String(t.id)} onClose={() => setShowTournamentManagement('')} />
                        </td>
                    </tr>
                )}
            </Fragment>
        );
    };
    const renderClientTH = (cl: Client) => {
        return (
            <th key={cl.id} className="text-center align-top">
                {cl.name}
                <br />
                {showOnlyActive && orderByClient !== String(cl.id) && (
                    <Button color="link" onClick={() => setOrderByClient(String(cl.id))}>
                        Order
                    </Button>
                )}
            </th>
        );
    };

    const isActive = useCallback(
        (tId: string) => {
            return clientTournaments.findIndex((ct) => String(ct.tournamentId) === tId && ct.state === 1) !== -1;
        },
        [clientTournaments],
    );

    const getOrderNumber = useCallback(
        (tId: string, clId: string) => {
            return (
                clientTournaments.find((ct) => String(ct.clientId) === clId && String(ct.tournamentId) === tId)
                    ?.order ?? -1
            );
        },
        [clientTournaments],
    );

    useEffect(() => {
        if (orderByClient && showOnlyActive) {
            setFilteredTournaments(
                allTournaments
                    .filter((t) => isActive(String(t.id)))
                    .filter((t) => t.name.toLowerCase().includes(tournamentFilter.toLowerCase()))
                    .sort(
                        (a, b) =>
                            getOrderNumber(String(a.id), orderByClient) - getOrderNumber(String(b.id), orderByClient),
                    ),
            );
        } else {
            setFilteredTournaments(
                allTournaments
                    .filter((t) => !showOnlyActive || isActive(String(t.id)))
                    .filter((t) => t.name.toLowerCase().includes(tournamentFilter.toLowerCase())),
            );
        }
    }, [orderByClient, showOnlyActive, allTournaments, isActive, getOrderNumber, tournamentFilter]);

    return (
        <>
            <Section>
                <LoadingIndicatorSpinner2 isLoading={showLoading} />
                <SectionHeading>Tournaments</SectionHeading>
                <FormGroup check={true}>
                    <Input
                        type="checkbox"
                        checked={showOnlyActive}
                        onChange={() => setShowOnlyActive(!showOnlyActive)}
                    />
                    <Label>nur aktive Turniere anzeigen</Label>
                </FormGroup>
                <FormGroup>
                    <Label>Filter</Label>
                    <Input
                        type="text"
                        value={tournamentFilter}
                        onChange={(e) => setTournamentFilter(e.currentTarget.value)}
                    />
                </FormGroup>
                <Table>
                    <thead>
                        <tr>
                            <th colSpan={4}>&nbsp;</th>
                            {allClients && allClients.map((cl) => renderClientTH(cl))}
                        </tr>
                    </thead>
                    <tbody>{filteredTournaments.map((t) => renderTournament(t))}</tbody>
                </Table>
            </Section>
        </>
    );
};

interface TournamentActionsProps {
    tId: string;
    onSuccess: () => void;
}
const TournamentActions: FC<TournamentActionsProps> = (props) => {
    const [dropDownOpen, setDropDownOpen] = useState(false);

    const toggle = () => setDropDownOpen((state) => !state);

    const [showCopyTournament, setShowCopyTournament] = useState(false);
    const [showUpdateRanking, setShowUpdateRanking] = useState(false);
    const [showUpdateDates, setShowUpdateDates] = useState(false);
    const [showAllowBets, setShowAllowBets] = useState(false);
    const [showAddRound, setShowAddRound] = useState(false);

    const tournamentSysadminApi = getTournamentSysAdminApi();
    const tournamentApi = getTournamentApi2();

    const { data: tournament } = useQuery({
        queryKey: ['tournament.getById', props.tId],
        queryFn: () => tournamentApi.getTournamentById({ tId: Number(props.tId) }),
    });

    const dispatch = useDispatch();

    const copyTournamentMutation = useMutation({
        mutationFn: (tId: string) => tournamentSysadminApi.copyTournament({ tId }),
    });
    const updateRankingMutation = useMutation({
        mutationFn: (tId: string) => tournamentSysadminApi.updateTournamentRanking({ tId }),
    });
    const updateDatesMutation = useMutation({
        mutationFn: (tId: string) => tournamentSysadminApi.updateTournamentStartAndEndDates({ tId }),
    });
    const allowBetsMutation = useMutation({
        mutationFn: (tId: string) => tournamentSysadminApi.tournamentAllowBets({ tId }),
    });

    const onCopyTournament = (tId: string) => {
        copyTournamentMutation.mutate(tId, {
            onSuccess: () => {
                props.onSuccess();
                dispatch(systemSetPopupMessage('Tournament copied.'));
                setShowCopyTournament(false);
            },
            onError: () => {
                dispatch(systemSetPopupMessage('Error copying tournament.'));
                setShowCopyTournament(false);
            },
        });
    };

    const onUpdateRanking = (tId: string) => {
        updateRankingMutation.mutate(tId, {
            onSuccess: () => {
                dispatch(systemSetPopupMessage('Tournament Rankings updated.'));
                props.onSuccess();
                setShowUpdateRanking(false);
            },
            onError: () => {
                dispatch(systemSetPopupMessage('Error updating tournament rankings.'));
                setShowUpdateRanking(false);
            },
        });
    };

    const onAllowBets = (tId: string) => {
        allowBetsMutation.mutate(tId, {
            onSuccess: () => {
                dispatch(systemSetPopupMessage('Bets allowed for all Games in the Tournament.'));
                props.onSuccess();
                setShowAllowBets(false);
            },
            onError: () => {
                dispatch(systemSetPopupMessage('Error allowing bets for all Games in the Tournament.'));
                setShowAllowBets(false);
            },
        });
    };

    const onUpdateDates = (tId: string) => {
        updateDatesMutation.mutate(tId, {
            onSuccess: () => {
                dispatch(systemSetPopupMessage('Tournament Start and End Dates updated.'));
                props.onSuccess();
                setShowUpdateDates(false);
            },
            onError: () => {
                dispatch(systemSetPopupMessage('Error updating Tournament Start and End Dates.'));
                setShowUpdateDates(false);
            },
        });
    };
    return (
        <>
            <OnDemandConfirmationDialog
                title="Copy Tournament"
                message={`Are you sure you want to copy the tournament ${tournament?.name}?`}
                onOK={() => onCopyTournament(String(props.tId))}
                isShown={() => showCopyTournament}
                isFetching={copyTournamentMutation.isPending}
                onCancel={() => {
                    setShowCopyTournament(false);
                }}
            />
            <OnDemandConfirmationDialog
                title="Update Ranking"
                message={`Are you sure you want to update the tournament ranking?`}
                onOK={() => onUpdateRanking(String(props.tId))}
                isShown={() => showUpdateRanking}
                onCancel={() => {
                    setShowUpdateRanking(false);
                }}
            />
            <OnDemandConfirmationDialog
                title="Update Start and End Dates"
                message={`Are you sure you want to update the tournament start and end dates?`}
                onOK={() => onUpdateDates(String(props.tId))}
                isShown={() => showUpdateDates}
                onCancel={() => {
                    setShowUpdateDates(false);
                }}
            />
            <OnDemandConfirmationDialog
                title="Allow Bets"
                message={`Are you sure you want to allow bets for all games in the tournament?`}
                onOK={() => onAllowBets(String(props.tId))}
                isShown={() => showAllowBets}
                onCancel={() => {
                    setShowAllowBets(false);
                }}
            />

            {showAddRound && <TournamentRoundEditModal tournamentId={props.tId} onClose={() => setShowAddRound(false)} />}

            <Dropdown isOpen={dropDownOpen} toggle={toggle} className="m-0" size="sm">
                <DropdownToggle caret color="primary" className="text-white">
                    Actions
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem header>Tournament Actions</DropdownItem>
                    <DropdownItem disabled>Edit</DropdownItem>
                    <DropdownItem onClick={() => setShowCopyTournament(true)}>Copy</DropdownItem>
                    <DropdownItem disabled>Delete</DropdownItem>
                    <DropdownItem onClick={() => setShowAddRound(true)}>Add Round</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={() => setShowUpdateRanking(true)}>Update Ranking</DropdownItem>
                    <DropdownItem onClick={() => setShowUpdateDates(true)}>Update Start and End Dates</DropdownItem>
                    <DropdownItem onClick={() => setShowAllowBets(true)}>Allow Bets</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </>
    );
};
