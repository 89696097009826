import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from 'reactstrap';
import { ButtonWithMultipleConfirmations } from '../../../common/ButtonWithMultipleConfirmations';
import { Section, SectionHeading } from '../../../common/Section';
import { accountUserSettingDeleteProfile, accountUserSettingSetDeleteAccountMessage } from '../actions';
import {
    accountUserSettingsDeleteAccountMessageSelector,
    accountUserSettingsDeleteAccountStateSelector,
} from '../selectors';

export type DeleteProfileResult = 'ok' | 'error' | undefined;

interface Props {}

export const DeleteProfile: React.FC<Props> = (props) => {
    const dispatch = useDispatch();

    const deleteProfileMessage = useSelector(accountUserSettingsDeleteAccountMessageSelector);
    const deleteProfileState = useSelector(accountUserSettingsDeleteAccountStateSelector);

    const onDeleteProfile = () => {
        dispatch(accountUserSettingDeleteProfile());
    };
    const onDismissMessage = () => {
        dispatch(accountUserSettingSetDeleteAccountMessage({ msg: '', state: undefined }));
    };

    return (
        <Section>
            <SectionHeading>Profil löschen</SectionHeading>
            <Alert
                color={deleteProfileState === 'error' ? 'danger' : 'success'}
                toggle={onDismissMessage}
                isOpen={deleteProfileState !== undefined}
            >
                {deleteProfileMessage}
            </Alert>
            {deleteProfileState !== 'ok' && (
                <ButtonWithMultipleConfirmations
                    onOK={onDeleteProfile}
                    color="danger"
                    messages={[
                        'Sind Sie sicher, dass Sie Ihren Account vollständig löschen wollen?',
                        'Die Löschung des Profils kann nicht rückgängig gemacht werden. Bitte ' +
                            'bestätigen Sie erneut, dass Sie alle Daten unwiderruflich löschen wollen.',
                    ]}
                    title="Gesamtes Profil unwiderruflich löschen!"
                >
                    Gesamtes Profil unwiderruflich löschen!
                </ButtonWithMultipleConfirmations>
            )}
        </Section>
    );
};
