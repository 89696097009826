import React, { FC, useState } from 'react';
import { getTournamentSysAdminApi } from '../../../../../System/systemConfig';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from '@tanstack/react-query';
import { systemSetPopupMessage } from '../../../../../System/actions';
import { GameEditComp } from '../GameEdit';
import { OnDemandConfirmationDialog } from '../../../../../common/ConfirmationDialog';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { TournamentRoundEditModal } from '../TournamentRoundEdit';

interface TournamentRoundActionsProps {
    trId: string;
    onRefresh: () => void;
}
export const TournamentRoundActions: FC<TournamentRoundActionsProps> = (props) => {
    const [dropDownOpen, setDropDownOpen] = useState(false);

    const [confDialogTitle, setConfDialogTitle] = useState('');
    const [confDialogMessage, setConfDialogMessage] = useState('');
    const [showConfDialog, setShowConfDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [confDialogCallback, setConfDialogCallback] = useState<() => void>(() => () => {});

    const [showAddGameDialog, setShowAddGameDialog] = useState(false);

    const toggle = () => setDropDownOpen((state) => !state);

    const api = getTournamentSysAdminApi();
    const dispatch = useDispatch();

    const tournamentRoundQuery = useQuery({
        queryKey: ['tournamentRound.get', props.trId],
        queryFn: () => api.getTournamentRoundSysAdminById({ trId: props.trId }),
        enabled: showEditDialog,
    });

    const onAddGame = () => {
        setShowAddGameDialog(true);
    };

    const onEdit = () => {
        setShowEditDialog(true);
    };

    const onEditClose = () => {
        setShowEditDialog(false);
        props.onRefresh();
    }

    const allowBetsMutation = useMutation({
        mutationFn: () => api.tournamentRoundAllowBets({ trId: props.trId }),
        onSuccess: () => {
            dispatch(systemSetPopupMessage('Bets are allowed for this round.'));
            props.onRefresh();
        },
        onError: (error) => {
            dispatch(systemSetPopupMessage('Error allowing bets for this round.'));
        },
    });

    const recalculatePointsMutation = useMutation({
        mutationFn: () => api.recalculatePoints({ trId: props.trId }),
        onSuccess: () => {
            dispatch(systemSetPopupMessage('Points will be recalculated soon.'));
        },
        onError: (error) => {
            dispatch(systemSetPopupMessage('Error recalculating points.'));
        },
    });
    const recalculateTRGMsMutation = useMutation({
        mutationFn: () => api.recalculateTRGMs({ trId: props.trId }),
        onSuccess: () => {
            dispatch(systemSetPopupMessage('TRGMs and Highscores will be recalculated soon.'));
        },
        onError: (error) => {
            dispatch(systemSetPopupMessage('Error recalculating TRGMS.'));
        },
    });

    const onAllowBets = () => {
        setConfDialogTitle('Allow Bets');
        setConfDialogMessage('Are you sure you want to allow bets for this round?');
        setConfDialogCallback(() => () => {
            allowBetsMutation.mutate();
        });
        setShowConfDialog(true);
    };

    const onRecalculatePoints = () => {
        setConfDialogTitle('Recalculate Points');
        setConfDialogMessage('Are you sure you want to recalculate points for this round?');
        setConfDialogCallback(() => () => {
            recalculatePointsMutation.mutate();
        });
        setShowConfDialog(true);
    };
    const onRecalculateTRGMs = () => {
        setConfDialogTitle('Recalculate TRGMs');
        setConfDialogMessage('Are you sure you want to recalculate TRGMs for this round?');
        setConfDialogCallback(() => () => {
            recalculateTRGMsMutation.mutate();
        });
        setShowConfDialog(true);
    };

    const onConfOK = () => {
        confDialogCallback();
        setShowConfDialog(false);
    };

    return (
        <>
            <GameEditComp
                trId={props.trId}
                isShown={showAddGameDialog}
                onClose={() => {
                    setShowAddGameDialog(false);
                    props.onRefresh();
                }}
            />
            {showEditDialog && tournamentRoundQuery.data?.tournamentId && (
                <TournamentRoundEditModal
                    trId={props.trId}
                    tournamentId={tournamentRoundQuery.data?.tournamentId}
                    onClose={onEditClose}
                />
            )}
            <OnDemandConfirmationDialog
                title={confDialogTitle}
                message={confDialogMessage}
                onOK={onConfOK}
                isShown={() => showConfDialog}
                onCancel={() => {
                    setShowConfDialog(false);
                }}
            />

            <Dropdown isOpen={dropDownOpen} toggle={toggle} className="ms-3">
                <DropdownToggle caret color="primary" className="text-white">
                    Round Actions
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem header>Round Actions</DropdownItem>
                    <DropdownItem onClick={onEdit}>Edit</DropdownItem>
                    <DropdownItem onClick={onAddGame}>Add Game</DropdownItem>
                    <DropdownItem disabled>Edit Groups</DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem onClick={onAllowBets}>Allow Bets</DropdownItem>
                    <DropdownItem onClick={onRecalculatePoints}>Recalculate Points</DropdownItem>
                    <DropdownItem onClick={onRecalculateTRGMs}>Recalculate TRGMs</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </>
    );
};
