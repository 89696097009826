import { AnyAction, isType } from 'typescript-fsa';
import { Highscore, TournamentRound } from '../../../services/betting';
import { portalSetRootHighscores, portalSetLastHighscores, portalSetRootUserHighscores, portalSetLastUserHighscores, portalSetRootRound, portalSetLastRound } from './actions';

export interface PortalState {
    rootRound?: TournamentRound;
    lastRound?: TournamentRound;
    rootHighscores: Highscore[];
    lastHighscores: Highscore[];
    rootUserHighscore?: Highscore;
    lastUserHighscore?: Highscore;
}

const initialPortalState: PortalState = {
    rootHighscores: [],
    lastHighscores: [],
    rootUserHighscore: undefined,
    lastUserHighscore: undefined
}

export function portalReducer(state: PortalState = initialPortalState, action: AnyAction): PortalState {
    if (isType(action, portalSetRootHighscores)) {
        return {...state, rootHighscores: action.payload };
    } else if (isType(action, portalSetLastHighscores)) {
        return {...state, lastHighscores: action.payload };
    } else if (isType(action, portalSetRootUserHighscores)) {
        return {...state, rootUserHighscore: action.payload };
    } else if (isType(action, portalSetLastUserHighscores)) {
        return {...state, lastUserHighscore: action.payload };
    } else if (isType(action, portalSetRootRound)) {
        return {...state, rootRound: action.payload };
    } else if (isType(action, portalSetLastRound)) {
        return {...state, lastRound: action.payload };
    }
    return state;
}