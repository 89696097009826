import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getNewsletterAdminApi } from '../../../System/systemConfig';
import { FileUpload } from '../../../common/FileUpload';
import { NewsletterImage } from '../NewsletterEdit';
import { LoadingIndicatorSpinner2 } from '../../../common/LoadingIndicatorSpinner2';
import { useDispatch } from 'react-redux';
import { systemSetPopupMessage } from '../../../System/actions';

interface Props {
    type: 'header' | 'footer';
    nlId: string;
    show: boolean;
    onClose: () => void;
}

export const NewsletterHeaderFooterEdit: React.FC<Props> = (props) => {
    const api = getNewsletterAdminApi();

    const [title, setTitle] = React.useState('');
    const [link, setLink] = React.useState('');
    const [text, setText] = React.useState('');
    const [imageId, setImageId] = React.useState<string>();

    const newsletterQuery = useQuery({
        queryKey: ['newsletter', { nlId: props.nlId }],
        queryFn: async () => api.getNewsletter({nlId: props.nlId}),
    });

    const newsletterUpdateMutation = useMutation({
        mutationFn: () => api.updateHeaderFooter({nlId: props.nlId, imageId, link, text, title, type: props.type}),
    });

    useEffect(() => {
        if (props.type === 'header') {
            setTitle(newsletterQuery.data?.headerTitle || '');
            setLink(newsletterQuery.data?.headerLink || '');
            setText(newsletterQuery.data?.headerText || '');
            setImageId(newsletterQuery.data?.headerImageId);
        } else {
            setTitle(newsletterQuery.data?.footerTitle || '');
            setLink(newsletterQuery.data?.footerLink || '');
            setText(newsletterQuery.data?.footerText || '');
            setImageId(newsletterQuery.data?.footerImageId);
        }
    }, [newsletterQuery.data, props.type]);

    const onClose = () => {
        props.onClose();
    };

    const dispatch = useDispatch();

    const onUpdate = () => {
        newsletterUpdateMutation.mutate(undefined, {
            onSuccess: () => {
                onClose();
            },
            onError: (error) => {
                dispatch(systemSetPopupMessage('Fehler beim Speichern: ' + error));
            }
        })
    }

    return (
        <Modal isOpen={props.show} toggle={onClose}>
            <ModalHeader toggle={onClose}>
                Newsletter {props.type === 'header' ? 'Header' : 'Footer'} bearbeiten
            </ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label>Newsletter</Label>
                    <Input type="text" value={newsletterQuery.data?.title} disabled={true} />
                </FormGroup>
                <FormGroup>
                    <Label>Titel</Label>
                    <Input type="text" value={title} onChange={(e) => setTitle(e.currentTarget.value)} />
                </FormGroup>
                <FormGroup>
                    <Label>Link</Label>
                    <Input type="text" value={link} onChange={(e) => setLink(e.currentTarget.value)} />
                </FormGroup>
                <FormGroup>
                    <Label>Text/HTML-Code</Label>
                    <textarea
                        className="form-control"
                        rows={5}
                        value={text}
                        onChange={(e) => setText(e.currentTarget.value)}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Image</Label>
                    <div>
                        <div className="py-2 px-0 m-0 rounded">
                            <NewsletterImage imageId={imageId} maxWidth="200px"/>
                        </div>
                        <FileUpload imageUploaded={(imageId) => setImageId(imageId)} />
                    </div>
                </FormGroup>
                <LoadingIndicatorSpinner2 isLoading={newsletterUpdateMutation.isPending || newsletterQuery.isFetching} />
            </ModalBody>
            <ModalFooter>
                <Button onClick={onClose}>Abbrechen</Button>
                <Button color="success" onClick={onUpdate}>Speichern</Button>
            </ModalFooter>
        </Modal>
    );
};
