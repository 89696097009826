/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.25
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NewsletterRegistrationResult
 */
export interface NewsletterRegistrationResult {
    /**
     * 
     * @type {string}
     * @memberof NewsletterRegistrationResult
     */
    registrationResult?: string;
}

/**
 * Check if a given object implements the NewsletterRegistrationResult interface.
 */
export function instanceOfNewsletterRegistrationResult(value: object): value is NewsletterRegistrationResult {
    return true;
}

export function NewsletterRegistrationResultFromJSON(json: any): NewsletterRegistrationResult {
    return NewsletterRegistrationResultFromJSONTyped(json, false);
}

export function NewsletterRegistrationResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): NewsletterRegistrationResult {
    if (json == null) {
        return json;
    }
    return {
        
        'registrationResult': json['registrationResult'] == null ? undefined : json['registrationResult'],
    };
}

export function NewsletterRegistrationResultToJSON(value?: NewsletterRegistrationResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'registrationResult': value['registrationResult'],
    };
}

