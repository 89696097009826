import { push } from '@lagunovsky/redux-react-router';
import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { AnyAction } from 'typescript-fsa';
import { tournamentSelectionChangeTournament } from './actions';
import { tournamentSelectionAvailableTournamentSelector, tournamentSelectionTournamentIdSelector } from './selectors';

interface Props2 {
    tId: string;
    onTournamentChange: (tId: string) => string;
}

export const TournamentSelectionComponent2: FC<Props2> = (props) => {
    const dispatch = useDispatch();

    const onTournamentSelected = (tId: string) => {
        dispatch(push(props.onTournamentChange(tId)));
    };

    const availableTournaments = useSelector(tournamentSelectionAvailableTournamentSelector);

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const getSelectedTournamentName = () => {
        const t = availableTournaments && availableTournaments.find((t) => String(t.id) === props.tId);
        return (t && t.name) || '';
    };

    return (
        <Dropdown
            isOpen={dropdownOpen}
            toggle={() => setDropdownOpen(!dropdownOpen)}
            size="lg"
            tag="span"
            direction='down'
            className="tournamentSelectionComponent"
        >
            <DropdownToggle color="link" className="pt-1 ps-1 pe-0" caret={true}>
                {getSelectedTournamentName()}
            </DropdownToggle>
            <DropdownMenu>
                {availableTournaments &&
                    availableTournaments.map((t) => (
                        <DropdownItem key={t.id} onClick={() => onTournamentSelected(String(t.id))}>
                            {t.name}
                        </DropdownItem>
                    ))}
                <DropdownItem divider={true} />
                <DropdownItem onClick={() => dispatch(push('/b/archive'))}>Archiv</DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
};

interface Props {
    onTournamentChange: (tId: string) => AnyAction;
}

export const TournamentSelectionComponent:FC<Props> = (props) => {
    const dispatch = useDispatch();
    const onTournamentSelected = (tId: string) => {
        dispatch(tournamentSelectionChangeTournament(Number(tId)));
        dispatch(props.onTournamentChange(tId));
    };

    const availableTournaments = useSelector(tournamentSelectionAvailableTournamentSelector);
    const selectedTournamnetId = useSelector(tournamentSelectionTournamentIdSelector);

    const [dropdownOpen, setDropdownOpen] = useState(false);

    const onTournamentIdChange = (tId: string) => {
        onTournamentSelected(tId);
    };

    const getSelectedTournamentName = () => {
        const t = availableTournaments && availableTournaments.find((t) => t.id === selectedTournamnetId);
        return (t && t.name) || '';
    };

    /*    const getSelectedTournamentShortName = () => {
        const t = availableTournaments && availableTournaments.find(t => t.id === selectedTournamnetId);
        return (t && t.shortName) || '';
    };
*/
    return (
        <>
            <Dropdown
                isOpen={dropdownOpen}
                toggle={() => setDropdownOpen(!dropdownOpen)}
                size="lg"
                tag="span"
                className="tournamentSelectionComponent"
            >
                <DropdownToggle color="link" className="pt-1 ps-1 pe-0" caret={true}>
                    {getSelectedTournamentName()}
                </DropdownToggle>
                <DropdownMenu>
                    {availableTournaments &&
                        availableTournaments.map((t) => (
                            <DropdownItem key={t.id} onClick={() => onTournamentIdChange(String(t.id))}>
                                {t.name}
                            </DropdownItem>
                        ))}
                    <DropdownItem divider={true} />
                    <DropdownItem onClick={() => dispatch(push('/b/archive'))}>Archiv</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </>
    );
};
