import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

export const LoadingIndicatorSpinner: React.FC<{}> = () => {
    return <FontAwesomeIcon icon={faSpinner} spin={true} pulse={true} />;
};

interface LoadingIndicatorProps extends React.PropsWithChildren {
    isLoading: boolean;
}

export const LoadingIndicatorContent: React.FC<LoadingIndicatorProps> = (props) => {
    return (props.isLoading ? <LoadingIndicatorSpinner /> : <>{props.children}</>);
};
