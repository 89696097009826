import * as R from 'ramda';
import { JobManagement } from '../../../components/JobManagement';
import { MenuSysAdmin } from '../../../components/MenuSysAdmin';
import { withBorder } from '../../../reactutils/withBorder';
import { withLogin } from '../../../reactutils/withLogin';
import { withMenu } from '../../../reactutils/withMenu';
import { withTitle } from '../../../reactutils/withTitle';
import { SysAdminSystemSubMenu } from '../../../components/SysAdminApp/SysAdminSubMenu';

export const JobScreen = R.compose(
    withMenu(<MenuSysAdmin />),
    withBorder(),
    withMenu(<SysAdminSystemSubMenu />),
    withTitle('Job Management'),
    withLogin()
)(JobManagement);
