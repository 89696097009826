/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.34
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExternalKey
 */
export interface ExternalKey {
    /**
     * 
     * @type {string}
     * @memberof ExternalKey
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalKey
     */
    provider?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalKey
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalKey
     */
    entityId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalKey
     */
    entityType?: string;
}

/**
 * Check if a given object implements the ExternalKey interface.
 */
export function instanceOfExternalKey(value: object): value is ExternalKey {
    return true;
}

export function ExternalKeyFromJSON(json: any): ExternalKey {
    return ExternalKeyFromJSONTyped(json, false);
}

export function ExternalKeyFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalKey {
    if (json == null) {
        return json;
    }
    return {
        
        'key': json['key'] == null ? undefined : json['key'],
        'provider': json['provider'] == null ? undefined : json['provider'],
        'id': json['id'] == null ? undefined : json['id'],
        'entityId': json['entityId'] == null ? undefined : json['entityId'],
        'entityType': json['entityType'] == null ? undefined : json['entityType'],
    };
}

export function ExternalKeyToJSON(value?: ExternalKey | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'key': value['key'],
        'provider': value['provider'],
        'id': value['id'],
        'entityId': value['entityId'],
        'entityType': value['entityType'],
    };
}

