import { AnyAction, isType } from 'typescript-fsa';
import { BettingViewRequestParams } from '.';
import { UserProfile } from '../../../services/account';
import { GameBet } from '../../../services/betting';
import { bettingViewSet, bettingViewSetParams, bettingViewSetUser } from './action';

export interface BettingViewState {
    params?: BettingViewRequestParams;
    gameBets: GameBet[];
    user?: UserProfile;
}

const initialBettingViewState: BettingViewState = {
    gameBets: []
};

export function bettingViewReducer(state: BettingViewState = initialBettingViewState, action: AnyAction) {
    if (isType(action, bettingViewSet)) {
        return { ...state, gameBets: action.payload };
    } else if (isType(action, bettingViewSetParams)) {
        return { ...state, params: action.payload };
    } else if (isType(action, bettingViewSetUser)) {
        return { ...state, user: action.payload };
    }
    return state;
}
