import { push } from '@lagunovsky/redux-react-router';
import React, { FC } from 'react';
import { PropsWithChildren } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

export const MenuButton = styled.button`
    font-size: 15px;
    border-radius: 3px;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 3px;
    padding-bottom: 3px;
    margin: 4px;
    background-color: #66b3ff;
    color: white;
    border-style: none;
`;

export const MenuButtonSelected = styled(MenuButton)`
    background-color: #ee7203;
    color: white;
    // border-bottom-width: 3px;
    // border-bottom-color: orange;
    // border-bottom-style: solid;
`;

interface SubMenuLinkProps extends PropsWithChildren{
    selected?: boolean;
    enabled?: boolean;
    to: string;
    onClick?: () => void;
}

export const SubMenuLink: FC<SubMenuLinkProps> = props => {
    const dispatch = useDispatch();

    const onClick = () => {
        if (props.onClick) {
            props.onClick();
        }
        dispatch(push(props.to));
    };

    return (
        <>
            {props.selected && <MenuButtonSelected onClick={onClick}>{props.children}</MenuButtonSelected>}
            {!props.selected && (
                <MenuButton
                    onClick={onClick}
                    disabled={props.enabled !== undefined ? !props.enabled : false}
                >
                    {props.children}
                </MenuButton>
            )}
        </>
    );
};
