import { faEdit, faSave, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as R from 'ramda';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Table } from 'reactstrap';
import { Section, SectionHeading } from '../../../components/common/Section';
import { MenuSysAdmin } from '../../../components/MenuSysAdmin';
import { withLogin } from '../../../reactutils/withLogin';
import { withMenu } from '../../../reactutils/withMenu';
import { SystemProperty } from '../../../services/account';
import { systemPropertiesLoad, systemPropertiesUpdate } from './actions';
import { SystemPropertyEntitySelector } from './selectors';
import { withBorder } from '../../../reactutils/withBorder';
import { SysAdminSubMenu } from '../../../components/SysAdminApp/SysAdminSubMenu';
import { sysAdminClientPropertyPath, sysAdminSystemPropertyPath } from '../../../components/SysAdminApp/sysAdminPathes';


export const MenuSystemProperties: FC<{}> = () => {
    return (
        <SysAdminSubMenu
            links={[
                { name: 'System Properties', link: sysAdminSystemPropertyPath() },
                { name: 'Client Properties', link: sysAdminClientPropertyPath() },
            ]}
        />
    );
};

const SystemPropertiesComp: FC<{}> = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(systemPropertiesLoad());
    }, [dispatch]);

    const [propName, setPropName] = useState('');
    const [propValue, setPropValue] = useState('');

    const systemProperties = useSelector(SystemPropertyEntitySelector);

    const edit = (name?: string, value?: string) => {
        setPropName(name || '');
        setPropValue(value || '');
    };

    const isInEditMode = (name?: string) => propName === name;

    const save = () => {
        dispatch(systemPropertiesUpdate({ name: propName, value: propValue }));
        setPropName('');
    };

    const cancel = () => {
        setPropName('');
    };

    const renderSystemProperty = (p: SystemProperty) => {
        return (
            <tr key={p.property}>
                <td className="text-break">{p.property}</td>
                <td className="text-truncate">
                    {!isInEditMode(p.property) && p.value}
                    {isInEditMode(p.property) && (
                        <Input
                            type="textarea"
                            value={propValue}
                            onChange={(e) => setPropValue(e.currentTarget.value)}
                        />
                    )}
                </td>
                <td>
                    {!isInEditMode(p.property) && (
                        <Button disabled={isInEditMode(p.property)} onClick={() => edit(p.property, p.value)}>
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>
                    )}
                    {isInEditMode(p.property) && (
                        <>
                            <Button onClick={() => save()}>
                                <FontAwesomeIcon icon={faSave} title="Speichern" />
                            </Button>
                            <Button onClick={() => cancel()}>
                                <FontAwesomeIcon icon={faWindowClose} title="Verwerfen" />
                            </Button>
                        </>
                    )}
                </td>
            </tr>
        );
    };

    return (
        <>
            <Section>
                <SectionHeading>System-Properties</SectionHeading>
                <Table style={{ tableLayout: 'fixed' }}>
                    <tbody>{systemProperties.map((p) => renderSystemProperty(p))}</tbody>
                </Table>
            </Section>
        </>
    );
};

export const SystemProperties = R.compose(
    withMenu(<MenuSysAdmin />),
    withBorder(),
    withMenu(<MenuSystemProperties />),
    withLogin(),
)(SystemPropertiesComp);
