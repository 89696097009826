import actionCreatorFactory from 'typescript-fsa';
import { ActionTokenParams } from '.';
import { ActionTokenCheckResponse } from '../../../services/account';

const actionCreator = actionCreatorFactory();

export const ACTION_TOKEN_LOAD = 'ACTION_TOKEN_LOAD';
export const actionTokenLoad = actionCreator<ActionTokenParams>(ACTION_TOKEN_LOAD);

export const ACTION_TOKEN_SET_PARAMS = 'ACTION_TOKEN_SET_PARAMS';
export const actionTokenSetParams = actionCreator<ActionTokenParams>(ACTION_TOKEN_SET_PARAMS);

export const ACTION_TOKEN_SET_TYPE = 'ACTION_TOKEN_SET_TYPE';
export const actionTokenSetType = actionCreator<ActionTokenCheckResponse>(ACTION_TOKEN_SET_TYPE);

export const ACTION_TOKEN_EXECUTE = 'ACTION_TOKEN_EXECUTE';
export const actionTokenExecute = actionCreator<ActionTokenParams>(ACTION_TOKEN_EXECUTE);

export const ACTION_TOKEN_SET_EXECUTED = 'ACTION_TOKEN_SET_EXECUTED';
export const actionTokenSetExecuted = actionCreator<boolean>(ACTION_TOKEN_SET_EXECUTED);

export const ACTION_TOKEN_CHECK_PWD = 'ACTION_TOKEN_CHECK_PWD';
export const actionTokenCheckPassword = actionCreator<ActionTokenParams>(ACTION_TOKEN_CHECK_PWD);

export const ACTION_TOKEN_CHECK_PWD_RESULT = 'ACTION_TOKEN_CHECK_PWD_RESULT';
export const actionTokenCheckPasswordResult = actionCreator<boolean>(ACTION_TOKEN_CHECK_PWD_RESULT);
