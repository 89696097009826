import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Container, Form, FormGroup, Input, Label, Table, Badge } from 'reactstrap';
import { GroupInternalHighscoreLink } from '../../../../links/GroupInternalHighscoreLink';
import { UserGroupAdminLink } from '../../../../links/UserGroupAdminLink';
import { ButtonWithConfirmation } from '../../../common/ButtonWithConfirmation';
import { LoadingIndicatorSpinner } from '../../../common/LoadingIndicatorSpinner';
import { userGroupHighscoresDefaultTrSelector } from '../../../UserGroupHighscores/selectors';
import {
    bettingProfileAcceptInvitation,
    bettingProfileCreateUserGroup,
    bettingProfileLeaveUserGroup,
    bettingProfileRefuseInvitation,
    bettingProfileSetDefaultUserGroup,
    bettingProfileSetMessage
} from '../actions';
import {
    bettingProfileHasMessageSelector,
    bettingProfileMessageLevelSelector,
    bettingProfileMessageSelector,
    bettingProfileMyGroupMembersSelector
} from '../selectors';
import { Section, SectionHeading } from '../../../common/Section';
import {
    notificationStatusInvitationsSelector,
    notificationStatusAskForMembershipSelector
} from '../NotificationStatus/selectors';
import { GroupMember, UserGroupDefaultHighscoreRound } from '../../../../services/betting';

export const ProfileUserGroups = () => {
    const dispatch = useDispatch();

    const [newUserGroupName, setNewUserGroupName] = React.useState('');

    const message = useSelector(bettingProfileMessageSelector);
    const messageLevel = useSelector(bettingProfileMessageLevelSelector);
    const hasMessage = useSelector(bettingProfileHasMessageSelector);
    const myGroupMember = useSelector(bettingProfileMyGroupMembersSelector);
    const invitations = useSelector(notificationStatusInvitationsSelector);
    const askFormMemberships = useSelector(notificationStatusAskForMembershipSelector);

    const onSetStandardGroup = (ugId: number) => {
        dispatch(bettingProfileSetDefaultUserGroup(ugId));
    };
    const onLeaveUserGroup = (ugId: number) => {
        dispatch(bettingProfileLeaveUserGroup(ugId));
    };
    const onAcceptInvitation = (ugId: number) => {
        dispatch(bettingProfileAcceptInvitation(ugId));
    };
    const onRefuseInvitation = (ugId: number) => {
        dispatch(bettingProfileRefuseInvitation(ugId));
    };
    const onMessageDismiss = () => {
        dispatch(bettingProfileSetMessage({ message: '', level: '' }));
    };

    const onCreateUserGroup = () => {
        dispatch(bettingProfileCreateUserGroup(newUserGroupName));
    };

    const countAdminRequests = (ugId: string) => {
        return (
            invitations.filter(gm => String(gm.userGroupId) === ugId).length +
            askFormMemberships.filter(gm => String(gm.userGroupId) === ugId).length
        );
    };

    const defaultHighscoreRounds = useSelector(userGroupHighscoresDefaultTrSelector);

    function getDefaultRound(ugId: string) {
        const defaultHighscoreTournamentRound: UserGroupDefaultHighscoreRound = defaultHighscoreRounds[ugId];
        if (defaultHighscoreTournamentRound) {
            return defaultHighscoreTournamentRound.tournamentRoundId;
        } else {
            return null;
        }
    }

    function showGroupMember(gm: GroupMember) {
        const defaultRound = getDefaultRound(String(gm.userGroupId));
        const adminRequestCount = countAdminRequests(String(gm.userGroupId));
        return (
            <tr key={gm.groupMemberId}>
                <td className="align-middle">
                    <UserGroupAdminLink ugId={gm.userGroupId}>{gm.userGroup.name}</UserGroupAdminLink>
                </td>

                <td className="d-none d-lg-table-cell align-middle">
                    {defaultRound && (
                        <GroupInternalHighscoreLink trId={defaultRound} ugId={gm.userGroupId}>
                            interne Wertung
                        </GroupInternalHighscoreLink>
                    )}
                </td>

                <td className="d-none d-sm-table-cell align-middle">
                    {gm.admin && (
                        <>
                            <UserGroupAdminLink ugId={gm.userGroupId}>Administration</UserGroupAdminLink>
                            {adminRequestCount > 0 && <Badge color="danger">{adminRequestCount}</Badge>}
                        </>
                    )}
                    {gm.state === 'invited' && (
                        <div>
                            <ButtonWithConfirmation
                                title="Einladung annehmen"
                                message={`Sie wollen die Einladung in die Gruppe '${gm.userGroup.name} annehmen?`}
                                color="success"
                                onOK={() => onAcceptInvitation(gm.userGroupId)}
                                className="m-1"
                            >
                                Einladung Annehmen
                            </ButtonWithConfirmation>
                            <ButtonWithConfirmation
                                title="Einladung annehmen"
                                message={`Sind Sie sicher, dass Sie die Einladung in die Gruppe '${gm.userGroup.name} ablehnen wollen?`}
                                color="danger"
                                onOK={() => onRefuseInvitation(gm.userGroupId)}
                                className="m-1"
                            >
                                Einladung ablehnen
                            </ButtonWithConfirmation>
                        </div>
                    )}
                    {gm.state === 'active' && !gm.admin && (
                        <ButtonWithConfirmation
                            title="Aus Tippteam austreten"
                            message={`Sind Sie sicher, dass Sie aus dem Tippteam '${gm.userGroup.name}' austreten wollen?`}
                            color="link"
                            onOK={() => onLeaveUserGroup(gm.userGroupId)}
                            className="m-1"
                            >
                            Austreten
                        </ButtonWithConfirmation>
                    )}
                </td>

                <td className="align-middle">
                    {gm.defaultGroup && <FontAwesomeIcon icon={faStar} />}
                    {!gm.defaultGroup && (
                        <ButtonWithConfirmation
                            title="Standardteam setzen"
                            message={`Sind Sie sicher, dass Sie das Tippteam '${gm.userGroup.name}' als Ihr Standardteam setzen wollen?`}
                            color="link"
                            onOK={() => onSetStandardGroup(gm.userGroupId)}
                            className="m-1"
                            >
                            als Standard setzen
                        </ButtonWithConfirmation>
                    )}
                </td>
            </tr>
        );
    }

    return (
        <Section>
            <SectionHeading>Sie wollen ein Tippteam gründen?</SectionHeading>
            <Alert color={messageLevel} isOpen={hasMessage} toggle={onMessageDismiss}>
                {message}
            </Alert>
            <Form >
                <FormGroup className="mb-2 me-sm-2 mb-sm-0">
                    <Label for="newUserGroup" className="me-sm-2">
                        Neues Tippteam
                    </Label>
                    <Input
                        id="newUserGroup"
                        name="newUserGroupName"
                        type="text"
                        className="me-sm-2"
                        onChange={e => setNewUserGroupName(e.currentTarget.value)}
                        placeholder="Name des Tippteams"
                    />
                    <Button onClick={onCreateUserGroup} color="success" className="my-2">
                        Tippteam gründen
                    </Button>
                </FormGroup>
            </Form>
            <SectionHeading>Meine Tippteams</SectionHeading>
            {myGroupMember && (
                <Table striped={true}>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th className="d-none d-lg-table-cell">Interne Wertung</th>
                            <th className="d-none d-sm-table-cell">Mitgliedschaft</th>
                            <th>Aktionen</th>
                        </tr>
                    </thead>
                    <tbody>{myGroupMember.map(gm => showGroupMember(gm))}</tbody>
                </Table>
            )}
            {myGroupMember && myGroupMember.length === 0 && (
                <Container>
                    <p>Sie sind bisher noch kein Mitglied in einem Tippteam.</p>
                </Container>
            )}
            {!myGroupMember && <LoadingIndicatorSpinner />}
        </Section>
    );
};
