/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebUsage
 */
export interface WebUsage {
    /**
     * 
     * @type {string}
     * @memberof WebUsage
     */
    accessToken?: string;
    /**
     * 
     * @type {Date}
     * @memberof WebUsage
     */
    lastAccess?: Date;
    /**
     * 
     * @type {string}
     * @memberof WebUsage
     */
    clientVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof WebUsage
     */
    clientTag?: string;
    /**
     * 
     * @type {string}
     * @memberof WebUsage
     */
    tenant?: string;
}

/**
 * Check if a given object implements the WebUsage interface.
 */
export function instanceOfWebUsage(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebUsageFromJSON(json: any): WebUsage {
    return WebUsageFromJSONTyped(json, false);
}

export function WebUsageFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebUsage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessToken': !exists(json, 'accessToken') ? undefined : json['accessToken'],
        'lastAccess': !exists(json, 'lastAccess') ? undefined : (new Date(json['lastAccess'])),
        'clientVersion': !exists(json, 'clientVersion') ? undefined : json['clientVersion'],
        'clientTag': !exists(json, 'clientTag') ? undefined : json['clientTag'],
        'tenant': !exists(json, 'tenant') ? undefined : json['tenant'],
    };
}

export function WebUsageToJSON(value?: WebUsage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessToken': value.accessToken,
        'lastAccess': value.lastAccess === undefined ? undefined : (value.lastAccess.toISOString()),
        'clientVersion': value.clientVersion,
        'clientTag': value.clientTag,
        'tenant': value.tenant,
    };
}

