import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { NavLocation } from '../App';
import { systemSetNavLocation } from '../components/System/actions';
import { isRadioEins } from '../components/System/systemConfig';

export const withNavLocation =
    <P extends object>(location: NavLocation) =>
    (Comp: React.ComponentType<P>): React.ComponentType<P> =>
    (props: P) => {
        const dispatch = useDispatch();

        const setBackground = (element: HTMLElement, location: NavLocation) => {
            if (location === 'newsletter') {
                console.info('background empty');
                element.style.background = 'none';
            } else {
                console.info('background grün');
                element.style.background = 'url(https://static2.funtip.de/static-img/r1/bg.jpg) repeat;';
            }
        };

        useEffect(() => {
            if (dispatch) {
                dispatch(systemSetNavLocation(location));
            }
            if (isRadioEins()) {
                const mainDiv = document.body;
                setBackground(mainDiv, location);
            }
        }, [dispatch]);
        return <Comp {...props} />;
    };
