import { AnyAction, isType } from 'typescript-fsa';
import { MyGroupHighscore } from '../../../services/betting';
import { myGroupHighscoresSet, myGroupHighscoresSetUnreadTeamChatMessages } from './actions';

export interface MyGroupHighscoreState extends MyGroupHighscore {
    unreadTeamChatMessages: number;
}

const defaultMyGroupHighscoreState: MyGroupHighscoreState = {
    rank: 0,
    totalNumberOfGroups: 0,
    ugId: '',
    userGroupName: '',
    unreadTeamChatMessages: 0
};

export function myGroupHighscoreReducer(
    state: MyGroupHighscoreState = defaultMyGroupHighscoreState,
    action: AnyAction
): MyGroupHighscoreState {
    if (isType(action, myGroupHighscoresSet)) {
        const { rank, totalNumberOfGroups, ugId, userGroupName } = action.payload;
        return { ...state, rank, totalNumberOfGroups, ugId, userGroupName };
    } else if (isType(action, myGroupHighscoresSetUnreadTeamChatMessages)) {
        return { ...state, unreadTeamChatMessages: action.payload };
    }

    return state;
}
