import actionCreatorFactory from 'typescript-fsa';
import { HighscoreStrategyDescriptor } from '../../../services/betting';

const actionCreator = actionCreatorFactory();

export const HSD_EDIT_LOAD = 'HSD_EDIT_LOAD';
export const hsdEditLoad = actionCreator<string>(HSD_EDIT_LOAD);

export const HSD_EDIT_SET = 'HSD_EDIT_SET';
export const hsdEditSet = actionCreator<HighscoreStrategyDescriptor>(HSD_EDIT_SET);

export const HSD_EDIT_SET_UG_NAME = 'HSD_EDIT_SET_UG_NAME';
export const hsdEditSetUgName = actionCreator<string>(HSD_EDIT_SET_UG_NAME);

export const HSD_EDIT_SET_AVAILABLE_STANDARD_STRATEGIES = 'HSD_EDIT_SET_AVAILABLE_STANDARD_STRATEGIES';
export const hsdEditSetAvailableStandardStrategies = actionCreator<HighscoreStrategyDescriptor[]>(
    HSD_EDIT_SET_AVAILABLE_STANDARD_STRATEGIES
);

export const HSD_EDIT_CHOOSE_STANDARD = 'HSD_EDIT_CHOOSE_STANDARD';
export const hsdEditChooseStandardDescriptor = actionCreator<{ ugId: string; hsdId: string }>(HSD_EDIT_CHOOSE_STANDARD);

export const HSD_EDIT_UPDATE_CUSTOM = 'HSD_EDIT_UPDATE_CUSTOM';
export const hsdEditUpdateCustomDescriptor = actionCreator<{ ugId: string; hsd: HighscoreStrategyDescriptor }>(
    HSD_EDIT_UPDATE_CUSTOM
);
