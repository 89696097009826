import { useMutation, useQuery } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { systemSetPopupMessage } from '../../../System/actions';
import { getClientAdminApi, getClientProperty2Api, getSystemApi } from '../../../System/systemConfig';

interface Props {
    clientId: string;
    name: string;
    onClose: () => void;
}

export const ClientPropertyEditComp: FC<Props> = (props) => {
    const [clientId, setClientId] = React.useState<string>('');
    const [name, setName] = React.useState<string>('');
    const [value, setValue] = React.useState<string>('');
    const [daysValid, setDaysValid] = React.useState<number>(0);
    const [languageId, setLanguageId] = React.useState<string>('');

    const systemApi = getSystemApi();
    const clientPropertyApi = getClientProperty2Api();

    const dispatch = useDispatch();

    const languageQuery = useQuery({
        queryKey: ['languages'],
        queryFn: () => systemApi.getAvailableLanguages(),
    });

    const isCreateDialog = () => {
        return props.clientId === '' && props.name === '';
    };

    const cpQuery = useQuery({
        queryKey: ['clientProperty', props.clientId, props.name],
        queryFn: () => clientPropertyApi.getById({clientId: props.clientId, name: props.name}),
        enabled: !isCreateDialog(),
    });

    const clientsQuery = useQuery({
        queryKey: ['clients'],
        queryFn: () => getClientAdminApi().getClients(),
    });

    const availablePropertiesQuery = useQuery({
        queryKey: ['availableClientProperties'],
        queryFn: () => clientPropertyApi.getAvailablePropertyNames(),
    });

    const updateCPMutation = useMutation({
        mutationFn: (cp: { value: string; daysValid: number; languageId: string; createNewVersion: boolean }) =>
            clientPropertyApi.update({
                clientId,
                name,
                createNewVersion: cp.createNewVersion,
                daysValid: String(cp.daysValid),
                value: cp.value,
                languageId: !!cp.languageId ? cp.languageId : undefined,
            }),
        onSuccess: () => {
            dispatch(systemSetPopupMessage('Client property updated successfully'));
            props.onClose();
        },
        onError: (e) => {
            dispatch(systemSetPopupMessage('Error updating client property: ' + JSON.stringify(e)));
        },
    });

    useEffect(() => {
        if (cpQuery.data) {
            setClientId(cpQuery.data.clientId);
            setName(cpQuery.data.name);
            setValue(cpQuery.data.value);
            setDaysValid(cpQuery.data.daysValid);
            setLanguageId(cpQuery.data.languageId);
        }
    }, [cpQuery.data]);

    const onCancel = () => {
        props.onClose();
    };

    const onSaveNewVersion = () => {
        console.info(daysValid);
        updateCPMutation.mutate({ value, daysValid, languageId, createNewVersion: true });
    };

    const updateLastVersion = () => {
        updateCPMutation.mutate({ value, daysValid, languageId, createNewVersion: false });
    };

    return (
        <Modal toggle={props.onClose} isOpen={true}>
            <ModalHeader>
                ClientProperty {cpQuery.data?.clientName}:{props.name}
            </ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                        <Label>Client</Label>
                        <select
                            value={clientId}
                            className="form-control"
                            onChange={(e) => setClientId(e.currentTarget.value)}
                            disabled={!isCreateDialog()}
                        >
                            <option value={''}>&lt;None&gt;</option>
                            {clientsQuery.data?.map((c) => (
                                <option key={c.id} value={c.id}>
                                    {c.name}
                                </option>
                            ))}
                        </select>
                    </FormGroup>
                    <FormGroup>
                        <Label>Name</Label>
                        <select
                            value={name}
                            className="form-control"
                            onChange={(e) => setName(e.currentTarget.value)}
                            disabled={!isCreateDialog()}
                        >
                            <option value={''}>&lt;None&gt;</option>
                            {availablePropertiesQuery.data?.map((p) => (
                                <option key={p} value={p}>
                                    {p}
                                </option>
                            ))}
                        </select>
                    </FormGroup>
                    <FormGroup>
                        <Label>Value</Label>
                        <Input
                            type="textarea"
                            value={value}
                            onChange={(e) => setValue(e.currentTarget.value)}
                            rows={4}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Days valid</Label>
                        <Input
                            type="number"
                            value={daysValid}
                            onChange={(e) => setDaysValid(Number(e.currentTarget.value))}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Language</Label>
                        <Input type="select" value={languageId} onChange={(e) => setLanguageId(e.currentTarget.value)}>
                            <option value={''}>&lt;None&gt;</option>
                            {languageQuery.data?.map((l) => (
                                <option key={l.id} value={l.id}>
                                    {l.name}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label>Created at</Label>
                        <Input
                            type="text"
                            disabled={true}
                            value={
                                cpQuery.data?.timeStamp
                                    ? DateTime.fromJSDate(cpQuery.data?.timeStamp).toFormat('dd.MM.yy HH:mm')
                                    : ''
                            }
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Version</Label>
                        <Input type="text" disabled={true} value={cpQuery.data?.version} />
                    </FormGroup>
                    <FormGroup>
                        <Label>Version Count</Label>
                        <Input type="text" disabled={true} value={cpQuery.data?.versionCount} />
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={onCancel}>
                    Cancel
                </Button>
                <Button color="primary" onClick={onSaveNewVersion}>
                    Neue Version speichern
                </Button>
                <Button color="primary" onClick={updateLastVersion}>
                    Letzte Version ändern
                </Button>
            </ModalFooter>
        </Modal>
    );
};
