import * as R from 'ramda';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withBorder } from '../../reactutils/withBorder';
import { withNavLocation } from '../../reactutils/withNavLocation';
import { withTitle } from '../../reactutils/withTitle';
import { LoadingIndicatorSpinner } from '../common/LoadingIndicatorSpinner';
import { usePathSegment } from '../SysAdminApp/CacheStatisticsComponent';
import { gihsfLoadWithProps } from './actions';

const GroupInternalHighscoresForwarder: FC<{}> = () => {
    const dispatch = useDispatch();
    const [ugId] = usePathSegment(3);

    useEffect(() => {
        if (ugId) {
            dispatch(gihsfLoadWithProps({ ugId }));
        }
    });
    return (
        <>
            <LoadingIndicatorSpinner />
        </>
    );
};

export const groupInternalHighscoreForwarderPath = (ugId: string) => `/b/group-internal-highscore-forward/${ugId}`;

export const GroupInternalHighscoreForwarderScreen = R.compose(
    withBorder(),
    withTitle('Gruppenwertung'),
    withNavLocation('my-highscore')
)(GroupInternalHighscoresForwarder);
