import { FC } from 'react';
import { Route, Routes } from 'react-router';
import { AuthTest } from './AuthTest';
import { NewsletterAdminScreen } from './NewsletterAdmin';
import { ClientAdminIndexComp } from './ClientAdminIndex';
import { ClientAdminBetOverviewScreen } from './ClientAdminBetOverview';
import { UserAdminApp } from '../SysAdminApp/UserAdminSearch';
import { RoundWinnerScreen, RoundWinnerScreenForwarder } from '../BettingApp/RoundWinners';

interface Props {}

export const clientAdminBetOverviewPath = () => `/client-admin/bet-overview`;
export const newsletterListPath = () => `/client-admin/newsletter/list`;
export const clientAdminIndexPath = () => `/client-admin/`;
export const clientAdminUserAdminSearchPath = () => `/client-admin/user-admin-search`;
export const clientAdminRoundWinnerPath = (tId: string) => `/client-admin/round-winner/${tId}`;


export const ClientAdminApp: FC<Props> = props => {
    return (
        <>
            <Routes>
                <Route path="auth-test" element={<AuthTest />} />
                <Route path="newsletter/*" element={<NewsletterAdminScreen />} />
                <Route path="bet-overview" element={<ClientAdminBetOverviewScreen />} />
                <Route path="user-admin-search" element={<UserAdminApp />} />
                <Route path="round-winner/:tId" element={<RoundWinnerScreen />} />
                <Route path="round-winner/*" element={<RoundWinnerScreenForwarder />} />
                <Route path="/*" index={true} element={<ClientAdminIndexComp />} />
            </Routes>
        </>
    );
};
