import * as R from 'ramda';
import React, { FC, useEffect } from 'react';
import { withFooter } from '../../../reactutils/withFooter';
import { withMenu } from '../../../reactutils/withMenu';
import { withNavLocation } from '../../../reactutils/withNavLocation';
import { withTitle } from '../../../reactutils/withTitle';
import { MenuBetting } from '../../MenuBetting';
import { useDispatch } from 'react-redux';
import { logout } from '../../System/actions';

const LogoutMessageComp: FC<{}> = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(logout());
    });
    
    return (
        <>
            <p>Sie wurden erfolgreich abgemeldet.</p>
        </>
    );
};

export const LogoutMessage = R.compose(
    withMenu(<MenuBetting />),
    withTitle('Abmeldung'),
    withNavLocation('profile'),
    withFooter()
)(LogoutMessageComp);
