/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.34
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GroupMember } from './GroupMember';
import {
    GroupMemberFromJSON,
    GroupMemberFromJSONTyped,
    GroupMemberToJSON,
} from './GroupMember';

/**
 * 
 * @export
 * @interface UserNotificationStatus
 */
export interface UserNotificationStatus {
    /**
     * 
     * @type {Array<GroupMember>}
     * @memberof UserNotificationStatus
     */
    groupInvitations: Array<GroupMember>;
    /**
     * 
     * @type {Array<GroupMember>}
     * @memberof UserNotificationStatus
     */
    groupAskForMemberships: Array<GroupMember>;
    /**
     * 
     * @type {number}
     * @memberof UserNotificationStatus
     */
    messageCount: number;
}

/**
 * Check if a given object implements the UserNotificationStatus interface.
 */
export function instanceOfUserNotificationStatus(value: object): value is UserNotificationStatus {
    if (!('groupInvitations' in value) || value['groupInvitations'] === undefined) return false;
    if (!('groupAskForMemberships' in value) || value['groupAskForMemberships'] === undefined) return false;
    if (!('messageCount' in value) || value['messageCount'] === undefined) return false;
    return true;
}

export function UserNotificationStatusFromJSON(json: any): UserNotificationStatus {
    return UserNotificationStatusFromJSONTyped(json, false);
}

export function UserNotificationStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserNotificationStatus {
    if (json == null) {
        return json;
    }
    return {
        
        'groupInvitations': ((json['groupInvitations'] as Array<any>).map(GroupMemberFromJSON)),
        'groupAskForMemberships': ((json['groupAskForMemberships'] as Array<any>).map(GroupMemberFromJSON)),
        'messageCount': json['messageCount'],
    };
}

export function UserNotificationStatusToJSON(value?: UserNotificationStatus | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'groupInvitations': ((value['groupInvitations'] as Array<any>).map(GroupMemberToJSON)),
        'groupAskForMemberships': ((value['groupAskForMemberships'] as Array<any>).map(GroupMemberToJSON)),
        'messageCount': value['messageCount'],
    };
}

