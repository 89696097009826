/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WebUsageSummary
 */
export interface WebUsageSummary {
    /**
     * 
     * @type {string}
     * @memberof WebUsageSummary
     */
    clientVersion?: string;
    /**
     * 
     * @type {string}
     * @memberof WebUsageSummary
     */
    tenant?: string;
    /**
     * 
     * @type {boolean}
     * @memberof WebUsageSummary
     */
    anonymous?: boolean;
    /**
     * 
     * @type {number}
     * @memberof WebUsageSummary
     */
    clientCount?: number;
}

/**
 * Check if a given object implements the WebUsageSummary interface.
 */
export function instanceOfWebUsageSummary(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function WebUsageSummaryFromJSON(json: any): WebUsageSummary {
    return WebUsageSummaryFromJSONTyped(json, false);
}

export function WebUsageSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): WebUsageSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientVersion': !exists(json, 'clientVersion') ? undefined : json['clientVersion'],
        'tenant': !exists(json, 'tenant') ? undefined : json['tenant'],
        'anonymous': !exists(json, 'anonymous') ? undefined : json['anonymous'],
        'clientCount': !exists(json, 'clientCount') ? undefined : json['clientCount'],
    };
}

export function WebUsageSummaryToJSON(value?: WebUsageSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clientVersion': value.clientVersion,
        'tenant': value.tenant,
        'anonymous': value.anonymous,
        'clientCount': value.clientCount,
    };
}

