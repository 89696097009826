import { AnyAction, isType } from 'typescript-fsa';
import { Tournament, TournamentRound } from '../../services/betting';
import {
    tournamentSelectionAddAvailableTournament,
    tournamentSelectionSetAvailableTournamentRounds,
    tournamentSelectionSetAvailableTournaments,
    tournamentSelectionSetNextTournamentRoundId,
    tournamentSelectionSetPreviousTournamentRoundId,
    tournamentSelectionSetSelectedTournamentId,
    tournamentSelectionSetSelectedTournamentRoundId
} from './actions';

export interface TournamentSelectionState {
    availableTournaments: Tournament[];
    selectedTournamentId?: number;

    availableTournamentRounds: { [type: string]: TournamentRound[] };
    selectedTournamentRoundId: { [type: string]: string | undefined };
    nextTournamentRoundId: { [type: string]: string | undefined };
    previousTournamentRoundId: { [type: string]: string | undefined };
}

const initialState: TournamentSelectionState = {
    availableTournaments: [],
    selectedTournamentId: undefined,

    availableTournamentRounds: {},
    selectedTournamentRoundId: {},
    nextTournamentRoundId: {},
    previousTournamentRoundId: {}
};

export function tournamentSelectionStateReducer(
    state: TournamentSelectionState = initialState,
    action: AnyAction
): TournamentSelectionState {
    if (isType(action, tournamentSelectionSetSelectedTournamentRoundId)) {
        const prop = { ...state.selectedTournamentRoundId, [action.payload.type as string]: action.payload.trId };
        return Object.assign({}, state, { selectedTournamentRoundId: prop });
    } else if (isType(action, tournamentSelectionSetAvailableTournamentRounds)) {
        const prop = { ...state.availableTournamentRounds, [action.payload.type as string]: action.payload.rounds };
        return Object.assign({}, state, { availableTournamentRounds: prop });
    } else if (isType(action, tournamentSelectionSetSelectedTournamentId)) {
        return Object.assign({}, state, { selectedTournamentId: action.payload });
    } else if (isType(action, tournamentSelectionSetAvailableTournaments)) {
        return Object.assign({}, state, { availableTournaments: action.payload });
    } else if (isType(action, tournamentSelectionAddAvailableTournament)) {
        const avail: Tournament[] = [...state.availableTournaments];
        const exists = avail.find(t => t.id === Number(action.payload.id));
        if (!exists) {
            avail.push(action.payload);
            const newAvail = avail.sort((t1, t2) => t1.name.localeCompare(t2.name));
            return { ...state, availableTournaments: newAvail };
        }
    } else if (isType(action, tournamentSelectionSetNextTournamentRoundId)) {
        const type = action.payload.type;
        const trId = action.payload.trId;
        const nextTrIds = { ...state.nextTournamentRoundId, [type as string]: trId };
        return { ...state, nextTournamentRoundId: nextTrIds };
    } else if (isType(action, tournamentSelectionSetPreviousTournamentRoundId)) {
        const type = action.payload.type;
        const trId = action.payload.trId;
        const prevTrIds = { ...state.previousTournamentRoundId, [type as string]: trId };
        return { ...state, previousTournamentRoundId: prevTrIds };
    }

    return state;
}
