import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';

interface GameFinishedProps {
    finished: boolean;
}

export const GameFinished: FC<GameFinishedProps> = (props) => {
    if (props.finished) {
        return <FontAwesomeIcon icon={faCheck} size="sm" />;
    } else {
        return null;
    }
};
