import { push } from '@lagunovsky/redux-react-router';
import { useQuery } from '@tanstack/react-query';
import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Alert, Button, Form, FormGroup, FormText, Input, Label } from 'reactstrap';
import { SubFormProps } from '..';
import { LoadingIndicatorSpinner2 } from '../../common/LoadingIndicatorSpinner2';
import { ShowWhenRadioEins } from '../../common/ShowWhenRadioEins';
import { systemSetAuthenticatedAccessToken, systemSetLoginNotRequired } from '../../System/actions';
import { systemLoginNextUrlSelector } from '../../System/selectors';
import { getProfile2Api, getRegistration2Api, getSystemConfig, isRadioEins } from '../../System/systemConfig';

export const validateEmail = (email: string) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
};

export const RegistrationSubForm: FC<SubFormProps> = (props) => {
    const minPasswordLength = 5;

    const agbInfoQuery = useQuery({
        queryKey: ['agbInfos', getSystemConfig().clientName],
        queryFn: () => getProfile2Api().getAgbInfos({ tenant: getSystemConfig().clientName }),
    });

    const nextUrl = useSelector(systemLoginNextUrlSelector);

    const dispatch = useDispatch();

    const [generalErrorMessage, setGeneralErrorMessage] = useState('');

    const [loginName, setLoginName] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [email, setEmail] = useState('');

    const [loading, setLoading] = useState(false);

    const [agb, setAgb] = useState(false);
    const [receiveNotifications, setReceiveNotifications] = useState(false);
    const [privacyStatement, setPrivacyStatement] = useState(false);

    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const ERR_MESSAGE_INVALID_USER_NAME = 'Der Nutzernamen ist nicht gültig oder bereits vergeben.';
    const ERR_MESSAGE_INVALID_EAMIL = 'Die Email ist nicht gülitg.';
    const ERR_MESSAGE_PRIVACY_STATEMENT_NOT_CONFIRMED = 'Bitte bestätigen Sie die Datenschutzbestimmungen.';

    const checkFormValues = (): boolean => {
        if (loginName.length < 3) {
            setGeneralErrorMessage(ERR_MESSAGE_INVALID_USER_NAME);
            return false;
        } else if (password.length < minPasswordLength) {
            setGeneralErrorMessage(`Das Kennwort muss mindestens aus ${minPasswordLength} Zeichen bestehen`);
            return false;
        } else if (password !== password2) {
            setGeneralErrorMessage('Die Kennwörter sind nicht identisch.');
            return false;
        } else if (!validateEmail(email)) {
            setGeneralErrorMessage(ERR_MESSAGE_INVALID_EAMIL);
            return false;
        } else if (!agb && isRadioEins()) {
            setGeneralErrorMessage('Bitte lesen und bestätigen Sie die AGBs.');
            return false;
        } else if (!privacyStatement) {
            setGeneralErrorMessage(ERR_MESSAGE_PRIVACY_STATEMENT_NOT_CONFIRMED);
            return false;
        }
        setGeneralErrorMessage('');
        return true;
    };

    const onCancel = () => {
        // FIXME define a global method for the portal path
        dispatch(systemSetLoginNotRequired());
        setPassword('');
        setPassword2('');
        setGeneralErrorMessage('');
        setAgb(false);
        setReceiveNotifications(false);
        setPrivacyStatement(false);
        props.changeSubForm('login');
        dispatch(push('/b/portal'));
    };

    var timeout: NodeJS.Timeout;

    const onShowSuccessMessage = () => {
        setShowSuccessMessage(true);
        timeout = setTimeout(() => {
            onSuccessfulRegistration();
        }, 10000);
    };

    const onSuccessfulRegistration = () => {
        dispatch(systemSetLoginNotRequired());
        setPassword('');
        setPassword2('');
        setLoginName('');
        setAgb(false);
        setPrivacyStatement(false);
        setReceiveNotifications(false);
        setShowSuccessMessage(false);
        props.changeSubForm('login');
        dispatch(push(nextUrl));
        clearTimeout(timeout);
    };

    const onSubmit = () => {
        if (checkFormValues()) {
            // execute registration and check for errors
            try {
                setLoading(true);
                getRegistration2Api()
                    .registerUser({
                        tenant: getSystemConfig().clientName,
                        email,
                        infoMail: receiveNotifications,
                        login: loginName,
                        password,
                        privacyStatementAccepted: privacyStatement,
                    })
                    .then((rr) => {
                        switch (rr.registrationResult) {
                            case 'success':
                                // FIXME diplay info message that the registration was successful for 10 seconds
                                // and then forward to next url
                                dispatch(systemSetAuthenticatedAccessToken(rr.accessToken || ''));
                                onShowSuccessMessage();
                                break;
                            case 'errorNameAlreadyExists':
                            case 'errorNameInvalid':
                                setGeneralErrorMessage(ERR_MESSAGE_INVALID_USER_NAME);
                                break;
                            case 'errorEmailInvalid':
                                setGeneralErrorMessage(ERR_MESSAGE_INVALID_EAMIL);
                                break;
                            case 'errorPrivacyStatementNotAccepted':
                                setGeneralErrorMessage(ERR_MESSAGE_PRIVACY_STATEMENT_NOT_CONFIRMED);
                                break;
                            default:
                                setGeneralErrorMessage('Ein Fehler ist aufgetreten (' + rr.registrationResult + ')');
                        }
                    })
                    .catch((e) => setGeneralErrorMessage(e.message))
                    .finally(() => setLoading(false));
            } finally {
            }
        }
    };

    return (
        <>
            <LoadingIndicatorSpinner2 isLoading={loading} />
            <h3>Registrierung</h3>
            <p>Nehmen Sie im Tippspiel teil und sichern Sie sich Schöne Preise. Melden Sie sich an und tippen Sie!</p>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    onSubmit();
                }}
            >
                <FormGroup>
                    <Label>
                        <b>Login</b>
                    </Label>
                    <Input value={loginName} onChange={(e) => setLoginName(e.currentTarget.value)} />
                </FormGroup>
                <FormGroup>
                    <Label>
                        <b>Kennwort</b>
                    </Label>
                    <Input value={password} onChange={(e) => setPassword(e.currentTarget.value)} type="password" />
                    <FormText>min. {minPasswordLength} Zeichen</FormText>
                </FormGroup>
                <FormGroup>
                    <Label>
                        <b>Kennwort Wiederholung</b>
                    </Label>
                    <Input value={password2} onChange={(e) => setPassword2(e.currentTarget.value)} type="password" />
                </FormGroup>
                <FormGroup>
                    <Label>
                        <b>Email</b>
                    </Label>
                    <Input value={email} onChange={(e) => setEmail(e.currentTarget.value)} />
                </FormGroup>
                <ShowWhenRadioEins>
                    <FormGroup check={true}>
                        <Input type="checkbox" checked={agb} onChange={() => setAgb(!agb)} />
                        <Label>
                            Ja, ich habe die <Link to="http://www.radioeins.de/agb">AGBs</Link> gelesen und stimme Ihnen
                            zu.
                        </Label>
                    </FormGroup>
                </ShowWhenRadioEins>
                <FormGroup check={true}>
                    <Input
                        type="checkbox"
                        checked={receiveNotifications}
                        onChange={() => setReceiveNotifications(!receiveNotifications)}
                    />
                    <Label>Ich möchte den Newsletter und Tipperinnerungen per Email erhalten.</Label>
                </FormGroup>
                <FormGroup check={true}>
                    <Input
                        type="checkbox"
                        checked={privacyStatement}
                        onChange={() => setPrivacyStatement(!privacyStatement)}
                    />
                    <Label>
                        Ich akzeptiere die{' '}
                        <Link to={agbInfoQuery.data?.privacyStatementUrl ?? ''} className="text-decoration-underline">
                            Datenschutzbestimmungen
                        </Link>
                        .
                    </Label>
                </FormGroup>
                <FormGroup>
                    <div style={{ color: 'red' }}>{generalErrorMessage}</div>
                </FormGroup>
                {!showSuccessMessage && (
                    <>
                        <Button color="success" type="submit" className="me-3">
                            Registrieren
                        </Button>
                        <Button onClick={onCancel}>Abbrechen</Button>
                    </>
                )}
                {showSuccessMessage && (
                    <Alert color="primary">
                        <p>
                            Die Registrierung war erfolgreich. Sie erhalten jetzt einen Bestätigungslink per Email
                            zugeschickt und werden in 10 Sekunden weitergeleitet.
                        </p>
                        <Button color="success" onClick={onSuccessfulRegistration}>
                            OK
                        </Button>
                    </Alert>
                )}
            </Form>
            <hr />
            <p>
                <b>Bereits registriert?</b>
            </p>
            <Button style={{ backgroundColor: '#337ab7', color: 'white' }} onClick={() => props.changeSubForm('login')}>
                Melden Sie sich an.
            </Button>
        </>
    );
};
