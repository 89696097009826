/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.34
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TournamentGroupMember
 */
export interface TournamentGroupMember {
    /**
     * 
     * @type {number}
     * @memberof TournamentGroupMember
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof TournamentGroupMember
     */
    tournamentParticipantId: number;
    /**
     * 
     * @type {number}
     * @memberof TournamentGroupMember
     */
    teamId: number;
    /**
     * 
     * @type {number}
     * @memberof TournamentGroupMember
     */
    tournamentGroupId: number;
}

/**
 * Check if a given object implements the TournamentGroupMember interface.
 */
export function instanceOfTournamentGroupMember(value: object): value is TournamentGroupMember {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('tournamentParticipantId' in value) || value['tournamentParticipantId'] === undefined) return false;
    if (!('teamId' in value) || value['teamId'] === undefined) return false;
    if (!('tournamentGroupId' in value) || value['tournamentGroupId'] === undefined) return false;
    return true;
}

export function TournamentGroupMemberFromJSON(json: any): TournamentGroupMember {
    return TournamentGroupMemberFromJSONTyped(json, false);
}

export function TournamentGroupMemberFromJSONTyped(json: any, ignoreDiscriminator: boolean): TournamentGroupMember {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'tournamentParticipantId': json['tournamentParticipantId'],
        'teamId': json['teamId'],
        'tournamentGroupId': json['tournamentGroupId'],
    };
}

export function TournamentGroupMemberToJSON(value?: TournamentGroupMember | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'tournamentParticipantId': value['tournamentParticipantId'],
        'teamId': value['teamId'],
        'tournamentGroupId': value['tournamentGroupId'],
    };
}

