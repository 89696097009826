/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.34
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BestBet
 */
export interface BestBet {
    /**
     * 
     * @type {number}
     * @memberof BestBet
     */
    gameId: number;
    /**
     * 
     * @type {number}
     * @memberof BestBet
     */
    userId: number;
    /**
     * 
     * @type {number}
     * @memberof BestBet
     */
    bet1?: number;
    /**
     * 
     * @type {number}
     * @memberof BestBet
     */
    bet2?: number;
    /**
     * 
     * @type {number}
     * @memberof BestBet
     */
    points?: number;
}

/**
 * Check if a given object implements the BestBet interface.
 */
export function instanceOfBestBet(value: object): value is BestBet {
    if (!('gameId' in value) || value['gameId'] === undefined) return false;
    if (!('userId' in value) || value['userId'] === undefined) return false;
    return true;
}

export function BestBetFromJSON(json: any): BestBet {
    return BestBetFromJSONTyped(json, false);
}

export function BestBetFromJSONTyped(json: any, ignoreDiscriminator: boolean): BestBet {
    if (json == null) {
        return json;
    }
    return {
        
        'gameId': json['gameId'],
        'userId': json['userId'],
        'bet1': json['bet1'] == null ? undefined : json['bet1'],
        'bet2': json['bet2'] == null ? undefined : json['bet2'],
        'points': json['points'] == null ? undefined : json['points'],
    };
}

export function BestBetToJSON(value?: BestBet | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'gameId': value['gameId'],
        'userId': value['userId'],
        'bet1': value['bet1'],
        'bet2': value['bet2'],
        'points': value['points'],
    };
}

