import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'reactstrap';
import { AnyAction } from 'typescript-fsa';
import { SearchableTableSearch } from '../components/common/SearchableTable/SearchableTableSearch';

export interface SearchStateProps {
    searchResultCount: number;
    searchString: string;
}

export interface SearchDispatchProps {
    onSearch: (s: string) => void;
}

export type SearchPropsOld = SearchStateProps & SearchDispatchProps;

export const withSearchOld = <P extends object>(Comp: React.ComponentType<P>) =>
    class WithSearch extends React.Component<P & SearchPropsOld> {
        public render() {
            return (
                <span>
                    <SearchableTableSearch
                        count={this.props.searchResultCount}
                        searchString={this.props.searchString}
                        onSearch={this.props.onSearch}
                    />
                    <Comp {...this.props} />
                </span>
            );
        }
    };

export interface SearchProps {
    searchResultCountSelector: (state: any) => number;
    searchStringSelector: (state: any) => string;
    onSearch: (s: string) => AnyAction;
    placeholder?: string;
}

export const withSearch = <P extends object>(searchProps: SearchProps) => (
    Comp: React.ComponentType<P>
): React.ComponentType<P> => (props: P) => {
    return (
        <>
            <SearchComponent {...searchProps} />
            <Comp {...props} />
        </>
    );
};

export const SearchComponent: FC<SearchProps> = props => {
    const [searchString, setSearchString] = useState('');

    const count = useSelector(props.searchResultCountSelector);
    const initialSearchString = useSelector(props.searchStringSelector);

    useEffect(() => {
        setSearchString(initialSearchString);
    }, [initialSearchString]);

    const dispatch = useDispatch();

    const searchStringChange = (s: string) => {
        setSearchString(s);
        dispatch(props.onSearch(s));
    };

    return (
        <div className="d-inline-flex">
            <Form >
                <div className="d-flex d-flex-row d-nowrap align-items-center">
                    <div className="">
                        <input
                            id="searchField"
                            type="text"
                            className="form-control"
                            placeholder={props.placeholder !== undefined ? props.placeholder : 'Suche'}
                            value={searchString}
                            onChange={e => searchStringChange(e.currentTarget.value)}
                            size={ 15 }
                        />
                    </div>
                    <div className="ms-1 d-none d-md-block">({count})</div>
                </div>
            </Form>
        </div>
    );
};
