import React from 'react';

export const withMenu = <P extends object>(menu: JSX.Element | React.ComponentType, condition?: () => boolean) => (
    Comp: React.ComponentType<P>
): React.ComponentType<P> => (props: P) => (
    <>
        {(!condition || condition()) && menu}
        <Comp {...props} />
    </>
);
