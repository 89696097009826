/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.25
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ClientState = {
    Active: 'active',
    Inactive: 'inactive'
} as const;
export type ClientState = typeof ClientState[keyof typeof ClientState];


export function instanceOfClientState(value: any): boolean {
    for (const key in ClientState) {
        if (Object.prototype.hasOwnProperty.call(ClientState, key)) {
            if ((ClientState as Record<string, ClientState>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function ClientStateFromJSON(json: any): ClientState {
    return ClientStateFromJSONTyped(json, false);
}

export function ClientStateFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClientState {
    return json as ClientState;
}

export function ClientStateToJSON(value?: ClientState | null): any {
    return value as any;
}

