import { push } from '@lagunovsky/redux-react-router';
import { useQuery } from '@tanstack/react-query';
import * as R from 'ramda';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import CursorLeft from '../../../icons/CursorLeft';
import CursorRight from '../../../icons/CursorRight';
import { withBorder } from '../../../reactutils/withBorder';
import { withLogin } from '../../../reactutils/withLogin';
import { withNavLocation } from '../../../reactutils/withNavLocation';
import { FuntipTitle, withTitle } from '../../../reactutils/withTitle';
import { myProfileSettingsPath } from '../../../screens/UserProfileScreen';
import { Newsletter } from '../../../services/webapp';
import { LoadingIndicatorSpinner2 } from '../../common/LoadingIndicatorSpinner2';
import { DateFormatter } from '../../DateTime';
import { usePathSegment } from '../../SysAdminApp/CacheStatisticsComponent';
import { isLoggedInSelector, userNameSelector } from '../../System/selectors';
import { getNewsletter2Api, getProfile2Api, getSystemConfig } from '../../System/systemConfig';
import { EmailConfirmationTeaser } from '../EmailConfirmationTeaser';
import { InvalidEmailTeaser } from '../InvalidEmailTeaser';
import { NewsletterTeaser } from '../NewsletterTeaser';
import { ProfileSubMenu } from '../ProfileSubMenu';

export const newsletterArchivePath = (offset: number) => `/newsletter/archive/${offset}`;

const NewsletterSubscriptionButton: FC<{}> = () => {
    const dispatch = useDispatch();

    const isLoggedIn = useSelector(isLoggedInSelector);
    const settingsProfileQuery = useQuery({
        queryKey: ['settingsProfileQuery'],
        queryFn: () => getProfile2Api().getUserSettings({tenant: getSystemConfig().clientName}),
        enabled: isLoggedIn,
    });
    const [subscribedToNewsletter, setSubscribedToNewsletter] = useState(false);

    useEffect(() => {
        if (isLoggedIn && settingsProfileQuery.isSuccess) {
            setSubscribedToNewsletter(settingsProfileQuery.data.newsletter && settingsProfileQuery.data.infoMails);
        }
    }, [isLoggedIn, settingsProfileQuery]);

    return (
        <>
            {subscribedToNewsletter && (
                <Button color="warning" outline={true} onClick={() => dispatch(push(myProfileSettingsPath()))}>
                    bereits angemeldet
                </Button>
            )}
            {!subscribedToNewsletter && (
                <Button color="dark" onClick={() => dispatch(push('/newsletter/subscription'))}>
                    Zum Newsletter anmelden
                </Button>
            )}
        </>
    );
};

interface NewsletterPagerProps {
    priorEnabled: boolean;
    nextEnabled: boolean;
    priorAction: () => void;
    nextAction: () => void;
}
const NewsletterPager: FC<NewsletterPagerProps> = (props) => {
    return (
        <>
            <Button className="m-1" onClick={props.priorAction} disabled={!props.priorEnabled}>
                <CursorLeft />
            </Button>
            <Button className="m-1" onClick={props.nextAction} disabled={!props.nextEnabled}>
                <CursorRight />
            </Button>
        </>
    );
};

export const NewsletterList: FC<{}> = () => {
    const dispatch = useDispatch();

    const pageSize = 20;

    const [pageOffset] = usePathSegment(3);

    const [offset, setOffset] = useState(0);
    const [priorEnabled, setPriorEnabled] = useState(false);
    const [nextEnabled, setNextEnabled] = useState(false);
    const [newsletterCount, setNewsletterCount] = useState(0);
    const [newsletterId, setNewsletterId] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (!isNaN(Number(pageOffset))) {
            setOffset(Number(pageOffset));
        }
    }, [pageOffset]);

    const api = getNewsletter2Api();
    const nlListQuery = useQuery({
        queryKey: ['newsletterList', offset],
        queryFn: () => api.listNewsletter({clientName: getSystemConfig().clientName, page: offset / pageSize, pageSize}),
        refetchOnWindowFocus: false,
    });

    const newsletterCountQuery = useQuery({
        queryKey: ['newsletterCount'],
        queryFn: () => api.countNewsletter({clientName: getSystemConfig().clientName}),
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        setNewsletterCount(newsletterCountQuery.data?.count ?? 0);
    }, [newsletterCountQuery.data]);

    useEffect(() => {
        setPriorEnabled(offset > 0);
        if (newsletterCount) {
            setNextEnabled(newsletterCount > offset + pageSize);
        }
    }, [newsletterCount, offset]);

    const [showDialog, setShowDialog] = useState(false);

    const showNewsletter = (nlId: string) => {
        setNewsletterId(nlId);
        // dispatch(newsletterLoadContent(nlId));
        setShowDialog(true);
    };

    const renderNewsletter = (nl: Newsletter) => {
        const text = nl.firstBlockText.length > 300 ? nl.firstBlockText.substring(0, 300) : nl.firstBlockText;
        return (
            <React.Fragment key={nl.id}>
                <tr>
                    <td className="d-lg-table-cell d-none">
                        <DateFormatter date={nl.sendingStartDate} format="medium" />
                    </td>
                    <td colSpan={2}>
                        <h3 className="title">{nl.title}</h3>
                        <span className="d-block d-lg-none">
                            <DateFormatter date={nl.sendingStartDate} format="medium" />
                        </span>
                    </td>
                </tr>
                <tr>
                    <td className="d-lg-table-cell d-none">&nbsp;</td>
                    <td>
                        <Button color="link" onClick={() => showNewsletter(nl.id)}>
                            <img
                                src={getSystemConfig().imageUrl(nl.firstImageId)}
                                alt={nl.title}
                                style={{ width: '100%', maxWidth: '250px', minWidth: '50px' }}
                            />
                        </Button>
                    </td>
                    <td className="align-content-start">
                        <span>{text}...</span>
                        <Button color="link" onClick={() => showNewsletter(nl.id)}>
                            [mehr]
                        </Button>
                    </td>
                </tr>
            </React.Fragment>
        );
    };

    const toggle = () => setShowDialog(!showDialog);

    const next = () => {
        dispatch(push(newsletterArchivePath(offset + pageSize)));
    };

    const prior = () => {
        dispatch(push(newsletterArchivePath(offset - pageSize)));
    };

    return (
        <>
            <FuntipTitle
                title="Newsletter-Archiv"
                subTitle=""
                leftComponent={
                    <NewsletterPager
                        priorEnabled={priorEnabled}
                        nextEnabled={nextEnabled}
                        priorAction={prior}
                        nextAction={next}
                    />
                }
                rightComponent={<NewsletterSubscriptionButton />}
            />

            <LoadingIndicatorSpinner2 isLoading={nlListQuery.isFetching || newsletterCountQuery.isFetching} />
            <Table>
                <thead>
                    <tr>
                        <td className="col-1 d-lg-table-cell d-none" />
                        <td className="col-4" />
                        <td className="col-7" />
                    </tr>
                </thead>
                <tbody>
                    {nlListQuery.isSuccess && nlListQuery.data && nlListQuery.data.map((nl) => renderNewsletter(nl))}
                </tbody>
            </Table>
            {showDialog && !!newsletterId && <NewsletterPreview newsletterId={newsletterId} onClose={toggle} />}
        </>
    );
};

const NewsletterUserProfile: FC<{}> = () => {
    return (
        <>
            <ProfileSubMenu subMenu="newsletter" />
            <EmailConfirmationTeaser />
            <NewsletterTeaser />
            <InvalidEmailTeaser />

            <NewsletterList />
        </>
    );
};

export const NewsletterUserProfileScreen = R.compose(
    withBorder(),
    withTitle('Newsletter', userNameSelector),
    withNavLocation('profile'),
    withLogin(),
)(NewsletterUserProfile);

export const NewsletterArchiveScreen = R.compose(withNavLocation('newsletter'))(NewsletterList);

interface NewsletterPreviewProps {
    newsletterId: string;
    onClose: () => void;
}

export const NewsletterPreview: FC<NewsletterPreviewProps> = (props) => {
    const api = getNewsletter2Api();

    const newsletterQuery = useQuery({
        queryKey: ['newsletterContent', props.newsletterId],
        queryFn: () => api.getNewsletterContent({clientName: getSystemConfig().clientName, nlId: props.newsletterId}),
        enabled: !!props.newsletterId,
    });

    const getNewsletterContent = () => {
        return (newsletterQuery.data?.content ?? '').replace('#{user.correspondenceName}', '<Name>');
    };

    return (
        <Modal isOpen={true} toggle={props.onClose} size="xl">
            <ModalHeader toggle={props.onClose} />
            <ModalBody>
                <span dangerouslySetInnerHTML={{ __html: getNewsletterContent() }}></span>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={props.onClose}>
                    Schließen
                </Button>
            </ModalFooter>
        </Modal>
    );
};
