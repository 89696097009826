import { AnyAction, isType } from 'typescript-fsa';
import { Blog } from '../../services/image';
import { blogSet, blogSetList } from './actions';

export interface BlogState {
    blogList: Blog[];
    blog?: Blog;
}

const initialBlogState: BlogState = {
    blogList: []
};

export function blogReducer(state: BlogState = initialBlogState, action: AnyAction): BlogState {
    if (isType(action, blogSetList)) {
        return { ...state, blogList: action.payload };
    } else if (isType(action, blogSet)) {
        return { ...state, blog: action.payload };
    }

    return state;
}
