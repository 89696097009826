import React, { FC, PropsWithChildren } from 'react'
import { Link } from 'react-router-dom';

interface Props extends PropsWithChildren{
    blogId: string;
}
export const BlogLink: FC<Props> = (props) => {
    return <Link to={blogPath(props.blogId)}>{props.children}</Link>;
}

export const blogPath = (blogId: string) => `/blog/${blogId}`;