import { faEnvelope, faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faCrown, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { Table } from 'reactstrap';
import UserLink from '../../links/UserLink';
import { GroupMember, UserGroupDetail } from '../../services/betting';
import { ButtonWithConfirmation } from '../common/ButtonWithConfirmation';
import { Section } from '../common/Section';
import { DateStr } from '../DateTime';
import { isSysAdminSelector } from '../System/selectors';
import { useSelector } from 'react-redux';

interface Props {
    groupMembers: GroupMember[];
    userGroupDetail: UserGroupDetail;
    onRemoveMember: (ugId: number, userId: number) => void;
    onRemoveInvitation: (ugId: number, userId: number) => void;
    onReinviteMember: (ugId: number, userId: number) => void;
    onAcceptMembershipRequest: (ugId: number, userId: number) => void;
    onRefuseMembershipRequest: (ugId: number, userId: number) => void;
    onGrantAdminPrivilege: (ugId: number, userId: number) => void;
    onRevokeAdminPrivilege: (ugId: number, userId: number) => void;
    loginUserId: string;
}

export const UserGroupMemberAdminList: React.FC<Props> = (props) => {
    const isSysAdmin = useSelector(isSysAdminSelector);

    function showRevokeAdminPrivilegeButton(gm: GroupMember) {
        if (!gm.admin) {
            return false;
        }
        if (gm.state !== 'active') {
            return false;
        }
        if (props.loginUserId === String(gm.userId)) {
            return false;
        }
        return true;
    }
    function showGrantAdminPrivilegeButton(gm: GroupMember) {
        if (gm.admin) {
            return false;
        }
        if (gm.state !== 'active') {
            return false;
        }
        return true;
    }

    return (
        <Section>
            <p className="my-4">
                <b>Gründungsdatum:</b> <DateStr date={props.userGroupDetail.date} />
            </p>
            <Table className={'members'}>
                <thead>
                    <tr>
                        <th>Mitglied</th>
                        <th>Status</th>
                        <th>Aktionen</th>
                    </tr>
                </thead>
                <tbody>
                    {props.groupMembers.map((gm: GroupMember) => {
                        return (
                            <tr key={gm.userId}>
                                <td>
                                    {gm.state === 'invited' && <span />}
                                    {gm.invitationEmail && gm.invitationEmail}
                                    {!gm.invitationEmail && <UserLink id={String(gm.userId)} name={gm.userName} />}
                                </td>
                                <td>
                                    {(props.userGroupDetail.loginUserIsGroupAdmin || isSysAdmin) && (
                                        <>
                                            {gm.state === 'active' && gm.admin && (
                                                <FontAwesomeIcon icon={faCrown} title="Administrator" />
                                            )}
                                            {gm.state === 'active' && !gm.admin && (
                                                <FontAwesomeIcon icon={faUserFriends} title="Mitglied" />
                                            )}
                                            {gm.state === 'invited' && (
                                                <FontAwesomeIcon icon={faEnvelope} title="Einladung" />
                                            )}
                                            {gm.state === 'askForMembership' && (
                                                <FontAwesomeIcon
                                                    icon={faQuestionCircle}
                                                    color="#ff2222"
                                                    title="Mitgliedsanfrage"
                                                />
                                            )}
                                        </>
                                    )}
                                </td>
                                <td>
                                    {gm.state === 'active' && !gm.admin && (
                                        <ButtonWithConfirmation
                                            title={'Mitglied aus Team entfernen'}
                                            message={'Wollen Sie das Mitglied aus dem Team entfernen?'}
                                            color={'link'}
                                            onOK={() => props.onRemoveMember(gm.userGroupId, gm.userId)}
                                            size="sm"
                                        >
                                            Mitglied entfernen
                                        </ButtonWithConfirmation>
                                    )}
                                    {gm.state === 'invited' && (
                                        <div>
                                            <ButtonWithConfirmation
                                                title={'Einladung löschen'}
                                                message={'Wollen Sie die Einladung löschen?'}
                                                color={'link'}
                                                onOK={() => props.onRemoveInvitation(gm.userGroupId, gm.userId)}
                                                size="sm"
                                            >
                                                Einladung löschen
                                            </ButtonWithConfirmation>

                                            <ButtonWithConfirmation
                                                title={'Einladung erneut versenden'}
                                                message={'Wollen Sie die Einladung erneut versenden?'}
                                                color={'link'}
                                                onOK={() => props.onReinviteMember(gm.userGroupId, gm.userId)}
                                                size="sm"
                                            >
                                                Einladung erneut versenden
                                            </ButtonWithConfirmation>
                                        </div>
                                    )}
                                    {gm.state === 'askForMembership' && (
                                        <>
                                            <ButtonWithConfirmation
                                                title={'Anfrage annehmen'}
                                                message={'Wollen Sie die Anfrage annehmen?'}
                                                color={'link'}
                                                onOK={() => props.onAcceptMembershipRequest(gm.userGroupId, gm.userId)}
                                                size='sm'
                                            >
                                                Mitgliedsanfrage annehmen
                                            </ButtonWithConfirmation>
                                            <ButtonWithConfirmation
                                                title={'Anfrage ablehen'}
                                                message={'Wollen Sie die Anfrage ablehnen?'}
                                                color={'link'}
                                                onOK={() => props.onRefuseMembershipRequest(gm.userGroupId, gm.userId)}
                                                size='sm'
                                            >
                                                Mitgliedsanfrage ablehnen
                                            </ButtonWithConfirmation>
                                        </>
                                    )}
                                    {showRevokeAdminPrivilegeButton(gm) && (
                                        <ButtonWithConfirmation
                                            title={'Admin-Rechte entziehen'}
                                            message={'Wollen Sie die Admin-Rechte entziehen?'}
                                            color={'link'}
                                            onOK={() => props.onRevokeAdminPrivilege(gm.userGroupId, gm.userId)}
                                            size='sm'   
                                        >
                                            Admin-Rechte entziehen
                                        </ButtonWithConfirmation>
                                    )}
                                    {showGrantAdminPrivilegeButton(gm) && (
                                        <ButtonWithConfirmation
                                            title={'Admin-Rechte erteilen'}
                                            message={'Wollen Sie die Admin-Rechte erteilen?'}
                                            color={'link'}
                                            onOK={() => props.onGrantAdminPrivilege(gm.userGroupId, gm.userId)}
                                            size="sm"
                                        >
                                            zum Team-Admin machen
                                        </ButtonWithConfirmation>
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </Section>
    );
};
