import { useMutation, useQuery } from '@tanstack/react-query';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Table } from 'reactstrap';
import { NewsletterLayout } from '../../../../services/webapp';
import { ButtonWithConfirmation } from '../../../common/ButtonWithConfirmation';
import { Section, SectionHeading } from '../../../common/Section';
import { systemSetPopupMessage } from '../../../System/actions';
import { getNewsletterAdminApi, getSystemConfig } from '../../../System/systemConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { newsletterLayoutEditPath } from '../NewsletterLayoutEdit';

export const newsletterLayoutsPath = () => `/client-admin/newsletter/layouts`;

const NewsletterLayouts: React.FC = () => {
    const api = getNewsletterAdminApi();

    const dispatch = useDispatch();

    const newsletterLayoutQuery = useQuery({
        queryKey: ['newsletterLayouts', getSystemConfig().clientId],
        queryFn: () => api.getLayouts({clientId: String(getSystemConfig().clientId)}),
    });

    const deleteLayoutMutation = useMutation({
        mutationFn: (id: string) => api.deleteNewsletterLayout({nllId: id}),
    });

    const onDelete = (id: string) => {
        deleteLayoutMutation.mutate(id, {
            onSuccess: () => {
                newsletterLayoutQuery.refetch();
            },
            onError: (error) => {
                dispatch(systemSetPopupMessage('Fehler beim Löschen des Layouts: ' + error));
            },
        });
    };

    const renderLayout = (nll: NewsletterLayout) => {
        return (
            <tr key={nll.id}>
                <td><Link to={newsletterLayoutEditPath(nll.id)}>{nll.name}</Link></td>
                <td>{nll.usageCount}</td>
                <td>
                    {nll.usageCount === 0 && (
                        <ButtonWithConfirmation
                            size="sm"
                            color="link"
                            className="border"
                            onOK={() => onDelete(nll.id)}
                            title="Layout löschen"
                            message={`Sind Sie sicher, dass Sie das Layout '${nll.name} 'löschen möchten?`}
                        >
                            <FontAwesomeIcon icon={faTrash} color="red" />
                        </ButtonWithConfirmation>
                    )}
                </td>
            </tr>
        );
    };

    return (
        <Section>
            <SectionHeading>Newsletter Layoutvorlagen</SectionHeading>

            <Table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Verwendung</th>
                        <th>Aktionen</th>
                    </tr>
                </thead>
                <tbody>{newsletterLayoutQuery.data?.map((layout) => renderLayout(layout))}</tbody>
            </Table>
        </Section>
    );
};

export const NewsletterLayoutScreen = NewsletterLayouts;
