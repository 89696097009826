/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.34
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Team
 */
export interface Team {
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    shortName?: string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    miniName?: string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    sportId: string;
    /**
     * 
     * @type {string}
     * @memberof Team
     */
    sportName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Team
     */
    alternativeNames?: Array<string>;
}

/**
 * Check if a given object implements the Team interface.
 */
export function instanceOfTeam(value: object): value is Team {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('sportId' in value) || value['sportId'] === undefined) return false;
    return true;
}

export function TeamFromJSON(json: any): Team {
    return TeamFromJSONTyped(json, false);
}

export function TeamFromJSONTyped(json: any, ignoreDiscriminator: boolean): Team {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'shortName': json['shortName'] == null ? undefined : json['shortName'],
        'miniName': json['miniName'] == null ? undefined : json['miniName'],
        'sportId': json['sportId'],
        'sportName': json['sportName'] == null ? undefined : json['sportName'],
        'alternativeNames': json['alternativeNames'] == null ? undefined : json['alternativeNames'],
    };
}

export function TeamToJSON(value?: Team | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'shortName': value['shortName'],
        'miniName': value['miniName'],
        'sportId': value['sportId'],
        'sportName': value['sportName'],
        'alternativeNames': value['alternativeNames'],
    };
}

