import * as React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { highscoresPath, HIGHSCORE_SLOT_SIZE } from '../../components/Highscores';
import { getHighscoreApi2 } from '../../components/System/systemConfig';

interface Props extends React.PropsWithChildren {
    trId: string;
    userId: string;
}

export const RoundHighscoreLink: React.FC<Props> = (props) => {
    const api = getHighscoreApi2();

    const { data: rhe } = useQuery({
        queryKey: ['getHighscoreForRoundAndUser', props.trId, props.userId],
        queryFn: () => api.getHighscoreForRoundAndUser({tournamentRoundId: props.trId, userId: props.userId}),
    });

    if (rhe) {
        const slot = Math.floor(rhe.absolutePos / HIGHSCORE_SLOT_SIZE) * HIGHSCORE_SLOT_SIZE;
        return <Link to={highscoresPath(props.trId, slot)}>{props.children}</Link>;
    } else {
        return <span>[{props.children}]</span>;
    }
};
