import { FC, PropsWithChildren } from 'react';
import { ServerType } from '../../System/systemConfig';

interface Props extends PropsWithChildren {
    server: ServerType;
}
export const ShowWhenServerAvailable: FC<Props> = (props) => {
    // TODO enabled when we have a more reliable version

    return <>{props.children}</>;
    /*
    if (serverAvailable) {
        return <>{props.children}</>;
    } else {
        return null;
    }
    */
};
