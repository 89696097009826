import { useQuery } from '@tanstack/react-query';
import * as R from 'ramda';
import { FC, useEffect, useState } from 'react';
import { Button, Container, Table } from 'reactstrap';
import { withMenu } from '../../../reactutils/withMenu';
import { withTitle } from '../../../reactutils/withTitle';
import { WebUsage, WebUsageSummary } from '../../../services/account';
import { DateTime } from '../../DateTime';
import { MenuSysAdmin } from '../../MenuSysAdmin';
import { ServerType, getWebUsageApi } from '../../System/systemConfig';
import { LoadingIndicatorContent, LoadingIndicatorSpinner } from '../../common/LoadingIndicatorSpinner';
import { Section, SectionHeading } from '../../common/Section';
import { TruncateText } from '../../common/TruncateText';
import { SysAdminSystemSubMenu } from '../SysAdminSubMenu';
import { withBorder } from '../../../reactutils/withBorder';

interface Props {
    serverName: ServerType;
}
const WebUsageComp: FC<Props> = (props) => {
    const [showDetails, setShowDetails] = useState(false);
    const [totalUsers, setTotalUsers] = useState(0);
    const [totalLoggedInUsers, setTotalLoggedInUsers] = useState(0);

    const [entities, setEntities] = useState<WebUsageSummary[]>([]);
    const [details, setDetails] = useState<WebUsage[]>([]);

    const api = getWebUsageApi(props.serverName);
    const summaryQuery = useQuery({
        queryKey: ['webUsageSummary', props.serverName],
        queryFn: () => api.getWebUsageSummary(props.serverName),
        retry: false,
    });

    useEffect(() => {
        setEntities(summaryQuery.data ?? []);
    }, [summaryQuery.data]);

    const detailQuery = useQuery({
        queryKey: ['webUsageDetails', props.serverName],
        queryFn: () => api.getWebUsageList(props.serverName),
        enabled: showDetails,
        retry: false,
    });

    useEffect(() => {
        setDetails(detailQuery.data ?? []);
    }, [detailQuery.data]);

    useEffect(() => {
        if (entities) {
            setTotalUsers(entities.map((e) => e.clientCount ?? 0).reduce((a, c) => a + c, 0));
            setTotalLoggedInUsers(
                entities
                    .filter((e) => !e.anonymous)
                    .map((e) => e.clientCount ?? 0)
                    .reduce((a, c) => a + c, 0),
            );
        }
    }, [entities]);

    const renderWebUsage = (wu: WebUsageSummary, index: number) => {
        return (
            <tr key={index}>
                <td>{wu.tenant}</td>
                <td>{wu.clientVersion}</td>
                <td>{wu.anonymous ? 'ja' : 'nein'}</td>
                <td>
                    <LoadingIndicatorContent isLoading={summaryQuery.isFetching}>
                        {wu.clientCount}
                    </LoadingIndicatorContent>
                </td>
            </tr>
        );
    };

    const renderWebUsageDetails = (wu: WebUsage, index: number) => {
        return (
            <tr key={index}>
                <td>{wu.tenant}</td>
                <td>{wu.clientVersion}</td>
                <td>{wu.clientTag}</td>
                <td>
                    <DateTime date={wu.lastAccess} />
                </td>
                <td>
                    <TruncateText text={wu.accessToken || ''} maxLength={50} />
                </td>
            </tr>
        );
    };

    return (
        <>
            <Section>
                <SectionHeading>{props.serverName}</SectionHeading>
                <Button color="warning" className="m-2" outline={false} onClick={() => summaryQuery.refetch()}>
                    <LoadingIndicatorContent isLoading={summaryQuery.isFetching}>Reload</LoadingIndicatorContent>
                </Button>
                {!showDetails && (
                    <Button color="secondary" className="m-2" outline={false} onClick={() => setShowDetails(true)}>
                        Show Details
                    </Button>
                )}
                {showDetails && (
                    <Button color="secondary" className="m-2" outline={false} onClick={() => setShowDetails(false)}>
                        Hide Details
                    </Button>
                )}
                <span className="ms-4">
                    <b>Total:</b> {totalUsers}&nbsp;&nbsp;<b>LoggedIn:</b> {totalLoggedInUsers}&nbsp;&nbsp;
                    <b>Anonymous:</b> {totalUsers - totalLoggedInUsers}
                </span>
                <Table>
                    <thead>
                        <tr>
                            <th>Tenant</th>
                            <th>Client-Version</th>
                            <th>Anonymous</th>
                            <th>Count</th>
                        </tr>
                    </thead>
                    <tbody>
                        {entities && entities.map((e, index) => renderWebUsage(e, index))}
                        {!entities && (
                            <tr>
                                <td>
                                    <LoadingIndicatorSpinner />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>

                {showDetails && (
                    <Container className="bg-warning pt-2">
                        <h5>Details</h5>
                        <small>
                            <LoadingIndicatorContent isLoading={detailQuery.isLoading}>
                                <Table striped={true}>
                                    <thead>
                                        <th>Tenant</th>
                                        <th>Client-Version</th>
                                        <th>Client-tag</th>
                                        <th>Last Access</th>
                                        <th>AccessToken</th>
                                    </thead>
                                    <tbody>
                                        {details && details.map((e, index) => renderWebUsageDetails(e, index))}
                                    </tbody>
                                </Table>
                            </LoadingIndicatorContent>
                        </small>
                    </Container>
                )}
            </Section>
        </>
    );
};

const WebUsageComponent: FC<{}> = () => {
    return (
        <>
            <p>The page shows the active clients per server in the last 20 minutes.</p>
            <WebUsageComp serverName="betting" />
            <WebUsageComp serverName="webapp" />
        </>
    );
};

export const WebUsageScreen = R.compose(
    withMenu(<MenuSysAdmin />),
    withBorder(),
    withMenu(<SysAdminSystemSubMenu />),
    withTitle('Rest-Clients'),
)(WebUsageComponent);
