import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Form, FormFeedback, FormGroup, FormText, Input, Label } from 'reactstrap';
import { Section, SectionHeading } from '../../../common/Section';
import { userMailCheckReceiverName, userMailSend } from '../actions';
import { userMailReceiverNameValidSelector } from '../selectors';

interface Props {
    receiver?: string;
    subject?: string;
    message?: string;
    onSend?: () => void;
    onCancel?: () => void;
}

export const UserMailForm = (props: Props) => {
    const [receiver, setReceiver] = useState(props.receiver || '');
    const [subject, setSubject] = useState(props.subject || '');
    const [message, setMessage] = useState(props.message || '');
    const [confirmationMessage, setConfirmationMessage] = useState('');

    const dispatch = useDispatch();

    const receiverNameValid = useSelector(userMailReceiverNameValidSelector);

    useEffect(() => {
        dispatch(userMailCheckReceiverName(receiver));
    }, [dispatch, receiver]);

    function onSend() {
        dispatch(userMailSend({ receiver, subject, message }));
        setConfirmationMessage('Die Nachricht wurde verschickt.');
        setReceiver('');
        setSubject('');
        setMessage('');
        if (props.onSend) {
            props.onSend();
        }
        dispatch(userMailCheckReceiverName(receiver));
    }

    function onCancel() {
        setReceiver('');
        setSubject('');
        setMessage('');
        if (props.onCancel) {
            props.onCancel();
        }
        dispatch(userMailCheckReceiverName(receiver));
    }

    function hideInfoMessage() {
        setConfirmationMessage('');
    }

    function onChangeReceiver(e: React.FormEvent<HTMLInputElement>) {
        setReceiver(e.currentTarget.value);
        dispatch(userMailCheckReceiverName(e.currentTarget.value));
    }

    return (
        <>
            <Section>
                <SectionHeading>Nachricht verfassen</SectionHeading>
                <Alert color="info" isOpen={confirmationMessage !== ''} toggle={hideInfoMessage}>
                    {confirmationMessage}
                </Alert>
                <Form>
                    <FormGroup>
                        <Label>Empfänger</Label>
                        <Input
                            value={receiver}
                            onChange={onChangeReceiver}
                            readOnly={props.receiver !== undefined}
                            invalid={!receiverNameValid}
                            valid={receiverNameValid}
                        />
                        <FormFeedback invalid="true">Der Nuternamen ist ungültig.</FormFeedback>
                        <FormFeedback valid={true}>Der Nutzernamen ist gültig.</FormFeedback>
                        <FormText>Geben Sie den Namen des Nutzers ein.</FormText>
                    </FormGroup>
                    <FormGroup>
                        <Label>Thema</Label>
                        <Input value={subject} onChange={e => setSubject(e.currentTarget.value)} />
                    </FormGroup>
                    <FormGroup>
                        <Label>Nachricht</Label>
                        <Input type="textarea" value={message} onChange={e => setMessage(e.currentTarget.value)} />
                    </FormGroup>
                    <FormGroup>
                        <Button className="me-2" color="primary" onClick={onSend}>
                            Senden
                        </Button>
                        <Button className="me-2" color="secondary" onClick={onCancel}>
                            Abbrechen
                        </Button>
                    </FormGroup>
                </Form>
            </Section>
        </>
    );
};
