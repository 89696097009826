import { GlobalState } from '../../components/actions';

const userGroupSelector = (state: GlobalState) => state.userGroup;

export const userGroupMemberSelector = (state: GlobalState) => userGroupSelector(state).groupMembers;
export const userGroupDetailSelector = (state: GlobalState) => userGroupSelector(state).groupDetail;

export const userGroupNameSelector = (state: GlobalState) => {
    const ugDetails = userGroupDetailSelector(state);
    return ugDetails ? ugDetails.name : undefined;
};

export const userGroupInvitationStateSelector = (state: GlobalState) => userGroupSelector(state).invitationState;
export const userGroupGroupMemberChangesSelector = (state: GlobalState) => userGroupSelector(state).groupMemberChanges;
