import { push } from '@lagunovsky/redux-react-router';
import { useQuery } from '@tanstack/react-query';
import * as R from 'ramda';
import React, { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Table } from 'reactstrap';
import UserLink from '../../../links/UserLink';
import { withBorder } from '../../../reactutils/withBorder';
import { withFooter } from '../../../reactutils/withFooter';
import { withMenu } from '../../../reactutils/withMenu';
import { withNavLocation } from '../../../reactutils/withNavLocation';
import { withTitle } from '../../../reactutils/withTitle';
import { RoundWinners } from '../../../services/betting';
import { clientAdminRoundWinnerPath } from '../../ClientAdminApp';
import { MenuClientAdmin } from '../../ClientAdminApp/MenuClientAdmin';
import { getHighscoreApi2, getSystemConfig, getTournamentApi2 } from '../../System/systemConfig';
import { TournamentSelectionComponent } from '../../TournamentSelectionComponent';
import { useDispatch } from 'react-redux';
import { tournamentSelectionChangeTournament } from '../../TournamentSelectionComponent/actions';

export const RoundWinnerScreenForwarder:FC = () => {
    const defaultTournamentQuery = useQuery({
        queryKey: ['defaultTournament'],
        queryFn: () => getTournamentApi2().getDefaultTournament({ clientId: getSystemConfig().clientId }),
    });

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        if (defaultTournamentQuery.data?.id) {
            dispatch(tournamentSelectionChangeTournament(defaultTournamentQuery.data.id));
            navigate(clientAdminRoundWinnerPath(String(defaultTournamentQuery.data.id)));
        }
    }, [defaultTournamentQuery.data, dispatch, navigate]);

    return null;

}

const RoundWinnersComp: FC<{}> = () => {
    const { tId } = useParams();


    const winnerQuery = useQuery({
        queryKey: ['roundWinners', tId],
        queryFn: () => getHighscoreApi2().getWinners({ clientId: getSystemConfig().clientId, tId: tId! }),
        enabled: !!tId,
    });

    const renderRow = (w: RoundWinners) => {
        return (
            <React.Fragment key={w.trId}>
                <tr>
                    <td colSpan={2}>
                        <b>{w.name}</b>
                    </td>
                </tr>
                {w.winners.map((w) => (
                    <tr key={w.userId}>
                        <td>
                            <UserLink id={String(w.userId)} name={w.userName} />
                        </td>
                        <td>{w.email}</td>
                    </tr>
                ))}
            </React.Fragment>
        );
    };

    return (
        <>
            <span className="flex-nowrap align-items-center me-auto">
                <TournamentSelectionComponent onTournamentChange={(tId) => push(clientAdminRoundWinnerPath(tId))} />
            </span>

            <Table>
                <tbody>{winnerQuery.data?.filter(w => w.winners.length > 0).map((w) => renderRow(w))}</tbody>
            </Table>
        </>
    );
};

export const RoundWinnerScreen = R.compose(
    withMenu(<MenuClientAdmin />),
    withBorder(),
    withTitle("Spieltags-Gewinner"),
    withNavLocation('betting'),
    withFooter()
)(RoundWinnersComp);
