import React from 'react';

interface BorderParams {
    screenClassName?: string;
}

export const withBorder = <P extends object>(params?: BorderParams) => (
    Comp: React.ComponentType<P>
): React.ComponentType<P> => (props: P) => {
    return (
        <div className={'ft-screen ft-screen--' + (params?.screenClassName ?? '') + ' ps-2 pe-2 ps-lg-4 pe-lg-4'}>
            <Comp {...props} />
        </div>
    );
};
