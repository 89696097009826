import * as R from 'ramda';
import { FC } from 'react';
import { Route, Routes } from 'react-router';
import { withBorder } from '../../../reactutils/withBorder';
import { withLogin } from '../../../reactutils/withLogin';
import { withMenu } from '../../../reactutils/withMenu';
import { MenuSysAdmin } from '../../MenuSysAdmin';
import { SysAdminSubMenu } from '../SysAdminSubMenu';
import { sysAdminSportOverviewPath, sysAdminSportTeamPath, sysAdminSportTournamentPath } from '../sysAdminPathes';
import { SysAdminTeamAppComp } from './SysAdminTeamApp';
import { SysAdminTournamentAppComp } from './SysAdminTournamentApp';
import { SysAdminOverview } from './SysAdminTournamentApp/SysAdminOverview';

export const SysAdminSportsAppMenu: FC<{}> = () => {
    return (
        <SysAdminSubMenu
            links={[
                { name: 'Overview', link: sysAdminSportOverviewPath() },
                { name: 'Tournaments', link: sysAdminSportTournamentPath() },
                { name: 'Teams', link: sysAdminSportTeamPath() },
            ]}
        />
    );
};

const SysAdminSportsAppComp: FC<{}> = () => {
    return (
        <>
            <Routes>
                <Route path="/teams" element={<SysAdminTeamAppComp />} />
                <Route path="/tournaments" element={<SysAdminTournamentAppComp />} />
                <Route path="/overview" element={<SysAdminOverview />} />
            </Routes>
        </>
    );
};

export const SysAdminSportsApp = R.compose(
    withMenu(<MenuSysAdmin />),
    withBorder(),
    withMenu(<SysAdminSportsAppMenu />),
    withLogin(),
)(SysAdminSportsAppComp);
