import { useMutation, useQuery } from '@tanstack/react-query';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { isClientAdminSelector } from '../../System/selectors';
import { getPortletApi2, getSystemConfig } from '../../System/systemConfig';
import { DateTime } from 'luxon';
import { useDispatch } from 'react-redux';
import { LoadingIndicatorSpinner2 } from '../../common/LoadingIndicatorSpinner2';
import { systemSetPopupMessage } from '../../System/actions';

interface Props {
    onClose: () => void;
}

export const BettingReminderTextEdit: FC<Props> = (props) => {
    const isClientAdmin = useSelector(isClientAdminSelector);

    const [currentText, setCurrentText] = useState('');
    const [daysValid, setDaysValid] = useState('');
    const [standardText, setStandardText] = useState('');
    const [validThruDate, setValidThruDate] = useState<DateTime>();

    const dispatch = useDispatch();

    const bettingReminderTextQuery = useQuery({
        queryKey: ['bettingReminderText.get'],
        queryFn: () => getPortletApi2().getBettingReminderText({ tenant: getSystemConfig().clientName }),
    });

    const updateMutation = useMutation({
        mutationFn: () =>
            getPortletApi2().updateBettingReminderText({
                tenant: getSystemConfig().clientName,
                currentText: currentText,
                validDays: Number(daysValid),
                standardText: standardText,
            }),
    });

    useEffect(() => {
        if (bettingReminderTextQuery.data) {
            setCurrentText(bettingReminderTextQuery.data.currentText);
            setDaysValid(String(bettingReminderTextQuery.data.validDays));
            setStandardText(bettingReminderTextQuery.data.standardText);
        }
    }, [bettingReminderTextQuery.data]);

    useEffect(() => {
        if (
            bettingReminderTextQuery.data?.currentText === currentText &&
            bettingReminderTextQuery.data?.validDays === Number(daysValid)
        ) {
            // calculate the valid thru date of the existing reminder text
            const d = DateTime.fromJSDate(bettingReminderTextQuery.data?.createdAt).plus({
                days: bettingReminderTextQuery.data?.validDays,
            });
            if (d.isValid) {
                setValidThruDate(d);
            } else {
                setValidThruDate(undefined);
            }
        } else {
            // calculate the valid thru date of the new reminder text
            const d = DateTime.now().plus({
                days: Number(daysValid),
            });
            if (d.isValid) {
                setValidThruDate(d);
            } else {
                setValidThruDate(undefined);
            }
        }
    }, [
        bettingReminderTextQuery.data?.createdAt,
        bettingReminderTextQuery.data?.currentText,
        bettingReminderTextQuery.data?.validDays,
        currentText,
        daysValid,
    ]);

    const isOverDue = () => {
        return (validThruDate?.diffNow().milliseconds ?? 0) < 0;
    };

    const onSave = () => {
        updateMutation.mutate(undefined, {
            onSuccess: () => {
                bettingReminderTextQuery.refetch();
                dispatch(systemSetPopupMessage('Tipperinnerungstexte erfolgreich aktualisiert.'));
                props.onClose();
            },
        });
    };

    const onCancel = () => {
        props.onClose();
    };

    if (!isClientAdmin) {
        return null;
    }

    return (
        <Modal isOpen={true} toggle={props.onClose}>
            <ModalHeader toggle={props.onClose}>Tipperinnerungstexte</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label>
                        aktueller Tipperinnerungstext (
                        <span className={isOverDue() ? 'text-danger' : ''}>
                            gültig bis {validThruDate?.toFormat('EEEE, dd.MM.yy HH:mm', { locale: 'de' })}
                        </span>
                        )
                    </Label>
                    <textarea
                        className="form-control"
                        rows={5}
                        value={currentText}
                        onChange={(e) => setCurrentText(e.currentTarget.value)}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Gültig für Tage </Label>
                    <Input type="number" value={daysValid} onChange={(e) => setDaysValid(e.currentTarget.value)} />
                </FormGroup>
                <FormGroup>
                    <Label>Standard-Tipperinnerungstext (immer gültig)</Label>
                    <textarea
                        className="form-control"
                        rows={5}
                        value={standardText}
                        onChange={(e) => setStandardText(e.currentTarget.value)}
                    />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <LoadingIndicatorSpinner2 isLoading={updateMutation.isPending} />
                <Button color="secondary" className="m-2" onClick={onCancel}>
                    Abbrechen
                </Button>
                <Button color="success" className="m-2" onClick={onSave}>
                    Speichern
                </Button>
            </ModalFooter>
        </Modal>
    );
};
