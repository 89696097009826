/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EventHandlerConfiguration
 */
export interface EventHandlerConfiguration {
    /**
     * 
     * @type {string}
     * @memberof EventHandlerConfiguration
     */
    eventName: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EventHandlerConfiguration
     */
    destinations?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof EventHandlerConfiguration
     */
    listenerName?: string;
    /**
     * 
     * @type {string}
     * @memberof EventHandlerConfiguration
     */
    receivingQueue?: string;
}

/**
 * Check if a given object implements the EventHandlerConfiguration interface.
 */
export function instanceOfEventHandlerConfiguration(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "eventName" in value;

    return isInstance;
}

export function EventHandlerConfigurationFromJSON(json: any): EventHandlerConfiguration {
    return EventHandlerConfigurationFromJSONTyped(json, false);
}

export function EventHandlerConfigurationFromJSONTyped(json: any, ignoreDiscriminator: boolean): EventHandlerConfiguration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'eventName': json['eventName'],
        'destinations': !exists(json, 'destinations') ? undefined : json['destinations'],
        'listenerName': !exists(json, 'listenerName') ? undefined : json['listenerName'],
        'receivingQueue': !exists(json, 'receivingQueue') ? undefined : json['receivingQueue'],
    };
}

export function EventHandlerConfigurationToJSON(value?: EventHandlerConfiguration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'eventName': value.eventName,
        'destinations': value.destinations,
        'listenerName': value.listenerName,
        'receivingQueue': value.receivingQueue,
    };
}

