import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Button, Form } from 'reactstrap';
import { Section, SectionHeading } from '../../common/Section';
import { systemAccessTokenSelector } from '../../System/selectors';
import { getAuthToken, registerAuthToken, unregisterAuthToken } from '../../System/systemConfig';
import { MenuClientAdmin } from '../MenuClientAdmin';

export const AuthTest: FC<{}> = () => {

    const accessToken = useSelector(systemAccessTokenSelector);

    return (
        <>
            <MenuClientAdmin />

            <Section>
                <SectionHeading>Auth-Tests</SectionHeading>

                <Form>
                    <Button color="warning" className="m-2" onClick={() => registerAuthToken(accessToken)}>
                        call 'registerAuthToken'
                    </Button>
                    <Button color="warning" className="m-2" onClick={() => unregisterAuthToken(accessToken)}>
                        call 'unregisterAuthToken'
                    </Button>
                    <Button color="warning" className="m-2" onClick={() => getAuthToken()}>
                        call 'getAuthToken'
                    </Button>
                </Form>
            </Section>
        </>
    );
};
