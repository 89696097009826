import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { Button, FormGroup, Input, Label, Table } from 'reactstrap';
import { NewsletterLayoutBlock } from '../../../../services/webapp';
import { ButtonWithConfirmation } from '../../../common/ButtonWithConfirmation';
import { Section, SectionHeading } from '../../../common/Section';
import { systemSetPopupMessage } from '../../../System/actions';
import { getNewsletterAdminApi } from '../../../System/systemConfig';
import { NewsletterLayoutBlockEdit } from '../NewsletterLayoutBlockAdmin';
import { NewsletterLayoutHeaderFooterEdit } from '../NewsletterLayoutHeaderFooterEdit';

interface Props {}

export const newsletterLayoutEditPath = (nllId: string) => `/client-admin/newsletter/layouts/${nllId}`;

export const NewsletterLayoutEdit: React.FC<Props> = (props) => {
    const { nllId } = useParams();

    const [name, setName] = React.useState('');

    const [editBlockId, setEditBlockId] = React.useState<string | undefined>(undefined);
    const [createBlock, setCreateBlock] = React.useState<boolean>(false);
    const [editHeaderFooter, setEditHeaderFooter] = React.useState<'header' | 'footer' | undefined>(undefined);

    const api = getNewsletterAdminApi();

    const dispatch = useDispatch();

    const newsletterLayoutQuery = useQuery({
        queryKey: ['newsletterLayout', nllId],
        queryFn: () => api.getNewsletterLayout({nllId: nllId!}),
        enabled: !!nllId,
    });

    useEffect(() => {
        setName(newsletterLayoutQuery.data?.name || '');
    }, [newsletterLayoutQuery.data?.name]);

    const newsletterLayoutBlockQuery = useQuery({
        queryKey: ['newsletterLayoutBlock', nllId],
        queryFn: () => api.getNewsletterLayoutBlocks({nllId: nllId!}),
        enabled: !!nllId,
    });

    const updateNewsletterLayoutMutation = useMutation({
        mutationFn: () => api.updateNewsletterLayout({nllId: nllId!, name}),
    });

    const deleteNewsletterLayoutBlockMutation = useMutation({
        mutationFn: (nllbId: string) => api.deleteNewsletterLayoutBlock({nllbId: nllbId!}),
    });

    const onUpdate = () => {
        updateNewsletterLayoutMutation.mutate(undefined, {
            onSuccess: () => {
                dispatch(systemSetPopupMessage('Das Newsletter Layout wurde gespeichert'));
            },
            onError: (error) => {
                dispatch(systemSetPopupMessage('Error updating newsletter layout: ' + JSON.stringify(error)));
            },
        });
    };

    const onDelete = (nllbId: string) => {
        deleteNewsletterLayoutBlockMutation.mutate(nllbId, {
            onSuccess: () => {
                dispatch(systemSetPopupMessage('Die Newsletter Themenvorlage wurde gelöscht'));
                newsletterLayoutBlockQuery.refetch();
            },
            onError: (error) => {
                dispatch(systemSetPopupMessage('Error deleting newsletter layout block: ' + JSON.stringify(error)));
            },
        });
    };

    const onModalClose = () => {
        setEditBlockId(undefined);
        setCreateBlock(false);
        setEditHeaderFooter(undefined);
        newsletterLayoutBlockQuery.refetch();
    };

    const onCreate = () => {
        setCreateBlock(true);
    };

    const renderNewsletterLayoutBlock = (nllb: NewsletterLayoutBlock) => {
        return (
            <tr key={nllb.id}>
                <td>
                    <Button color="link" className="ms-0 ps-0" onClick={() => setEditBlockId(nllb.id)}>
                        {nllb.name}
                    </Button>
                </td>
                <td className="text-break">{nllb.usageCount}</td>
                <td>
                    {nllb.usageCount === 0 && (
                        <ButtonWithConfirmation
                            size="sm"
                            title="Themenblock löschen"
                            message={`Sind Sie sicher, dass Sie den Themenblock '${nllb.name}' löschen möchten?`}
                            onOK={() => onDelete(nllb.id)}
                            className="border"
                        >
                            <FontAwesomeIcon icon={faTrash} color="red" />
                        </ButtonWithConfirmation>
                    )}
                </td>
            </tr>
        );
    };

    return (
        <div>
            <Section>
                <SectionHeading>Newsletter Layoutvorlage bearbeiten</SectionHeading>

                <FormGroup>
                    <Label>Name</Label>
                    <Input type="text" value={name} onChange={(e) => setName(e.currentTarget.value)} />
                </FormGroup>
                <Button color="success" className="mb-3" onClick={onUpdate}>
                    Speichern
                </Button>
            </Section>
            <Section>
                <SectionHeading>Themenvorlagen</SectionHeading>
                <Button color="success" className="mb-3" onClick={onCreate}>
                    <FontAwesomeIcon icon={faPlus} /> Neue Themenvorlage
                </Button>
                {editBlockId && (
                    <NewsletterLayoutBlockEdit mode="edit" nllbId={editBlockId} nllId={nllId!} onClose={onModalClose} />
                )}
                {createBlock && (
                    <NewsletterLayoutBlockEdit mode="create" nllbId={undefined} nllId={nllId!} onClose={onModalClose} />
                )}
                {editHeaderFooter && (
                    <NewsletterLayoutHeaderFooterEdit mode={editHeaderFooter} nllId={nllId!} onClose={onModalClose} />
                )}
                <Table>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Verwendung</th>
                            <th>Aktionen</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr>
                            <td>
                                <Button color="link" className="ms-0 ps-0" onClick={() => setEditHeaderFooter('header')}>
                                    Header
                                </Button>
                            </td>
                            <td></td>
                            <td></td>
                        </tr>
                        {newsletterLayoutBlockQuery.data?.map((nllb) => renderNewsletterLayoutBlock(nllb))}
                        <tr>
                            <Button color="link" className="ms-0 ps-0" onClick={() => setEditHeaderFooter('footer')}>
                                Footer
                            </Button>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </Table>
            </Section>
        </div>
    );
};
