/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.25
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PlayListEntry,
  UserSong,
} from '../models/index';
import {
    PlayListEntryFromJSON,
    PlayListEntryToJSON,
    UserSongFromJSON,
    UserSongToJSON,
} from '../models/index';

export interface GetFavoriteSongsRequest {
    userId: string;
}

export interface GetLastSongRequest {
    from?: string;
    maxTimeframe?: number;
    to?: string;
}

export interface RemoveUserSongRequest {
    songId: string;
}

export interface VoteRequest {
    songId: string;
    vote: number;
}

/**
 * 
 */
export class Playlist2ControllerApi extends runtime.BaseAPI {

    /**
     */
    async getFavoriteSongsRaw(requestParameters: GetFavoriteSongsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserSong>>> {
        if (requestParameters['userId'] == null) {
            throw new runtime.RequiredError(
                'userId',
                'Required parameter "userId" was null or undefined when calling getFavoriteSongs().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/pl/favorite-songs/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters['userId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserSongFromJSON));
    }

    /**
     */
    async getFavoriteSongs(requestParameters: GetFavoriteSongsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserSong>> {
        const response = await this.getFavoriteSongsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getLastSongRaw(requestParameters: GetLastSongRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<PlayListEntry>>> {
        const queryParameters: any = {};

        if (requestParameters['from'] != null) {
            queryParameters['from'] = requestParameters['from'];
        }

        if (requestParameters['maxTimeframe'] != null) {
            queryParameters['maxTimeframe'] = requestParameters['maxTimeframe'];
        }

        if (requestParameters['to'] != null) {
            queryParameters['to'] = requestParameters['to'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/pl/searching`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PlayListEntryFromJSON));
    }

    /**
     */
    async getLastSong(requestParameters: GetLastSongRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<PlayListEntry>> {
        const response = await this.getLastSongRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMyFavoriteSongsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserSong>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pl/my`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserSongFromJSON));
    }

    /**
     */
    async getMyFavoriteSongs(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserSong>> {
        const response = await this.getMyFavoriteSongsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async removeUserSongRaw(requestParameters: RemoveUserSongRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['songId'] == null) {
            throw new runtime.RequiredError(
                'songId',
                'Required parameter "songId" was null or undefined when calling removeUserSong().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pl/vote/{songId}`.replace(`{${"songId"}}`, encodeURIComponent(String(requestParameters['songId']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async removeUserSong(requestParameters: RemoveUserSongRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.removeUserSongRaw(requestParameters, initOverrides);
    }

    /**
     */
    async voteRaw(requestParameters: VoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['songId'] == null) {
            throw new runtime.RequiredError(
                'songId',
                'Required parameter "songId" was null or undefined when calling vote().'
            );
        }

        if (requestParameters['vote'] == null) {
            throw new runtime.RequiredError(
                'vote',
                'Required parameter "vote" was null or undefined when calling vote().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pl/vote/{songId}/{vote}`.replace(`{${"songId"}}`, encodeURIComponent(String(requestParameters['songId']))).replace(`{${"vote"}}`, encodeURIComponent(String(requestParameters['vote']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async vote(requestParameters: VoteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.voteRaw(requestParameters, initOverrides);
    }

}
