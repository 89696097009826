import { useMutation } from '@tanstack/react-query';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Button, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { Section, SectionHeading } from '../../../common/Section';
import { systemSetPopupMessage } from '../../../System/actions';
import { getProfile2Api } from '../../../System/systemConfig';

export type PasswordChangeState = 'ok' | 'error' | undefined;

interface Props {
}

const ChangePassword: React.FC<Props> = (props) => {
    const api = getProfile2Api();
    const dispatch = useDispatch();

    const [oldPassword, setOldPassword] = React.useState('');
    const [newPassword, setNewPassword] = React.useState('');
    const [newPassword2, setNewPassword2] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');

    const changePasswordMutation = useMutation({
        mutationFn: () => api.updatePassword({newPwd: newPassword, oldPwd: oldPassword}),
    });

    const onUpdate = () => {
        if (!newPassword || !newPassword2) {
            setErrorMessage('Kennwörter dürfen nicht leer sein.');
        } else if (newPassword.length < 3) {
            setErrorMessage('Kennwörter müssen mindestens 3 Zeichen enthalten.');
        } else if (newPassword !== newPassword2) {
            setErrorMessage('Kennwörter sind nicht identisch.');
        } else {
            changePasswordMutation.mutate(undefined, {
                onSuccess: () => {
                    dispatch(systemSetPopupMessage('Das Kennwort wurde erfolgreich geändert.'));
                    setErrorMessage('');
                    setOldPassword('');
                    setNewPassword('');
                    setNewPassword2('');
                },
                onError: () => {
                    dispatch(systemSetPopupMessage('Das Kennwort konnte nicht geändert werden.'));
                },
            });
        }
    };

    return (
        <Section>
            <SectionHeading>Kennwort ändern</SectionHeading>
            <FormGroup>
                <Label>altes Kennwort</Label>
                <Input
                    type="password"
                    name="oldPassword"
                    placeholder="altes Kennwort"
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.currentTarget.value)}
                />
            </FormGroup>
            <FormGroup>
                <Label>neues Kennwort</Label>
                <Input
                    type="password"
                    name="newPassword"
                    placeholder="neues Kennwort"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.currentTarget.value)}
                />
            </FormGroup>
            <FormGroup>
                <Label>neues Kennwort wiederholen</Label>
                <Input
                    type="password"
                    name="newPassword2"
                    placeholder="neues Kennwort wiederholen"
                    value={newPassword2}
                    onChange={(e) => setNewPassword2(e.currentTarget.value)}
                    invalid={errorMessage !== ''}
                />
                <FormFeedback>{errorMessage}</FormFeedback>
            </FormGroup>
            <Button onClick={onUpdate} color="success">
                Kennwort ändern
            </Button>
        </Section>
    );
};

export default ChangePassword;
