import { faCopy, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ButtonWithConfirmation } from '../../../../common/ButtonWithConfirmation';
import { LoadingIndicatorContent } from '../../../../common/LoadingIndicatorSpinner';
import { LoadingIndicatorSpinner2 } from '../../../../common/LoadingIndicatorSpinner2';
import { systemSetPopupMessage } from '../../../../System/actions';
import { getTournamentSysAdminApi } from '../../../../System/systemConfig';
import { IncrementalTournamentSysadminSearch } from '../IncrementalTournamentSysadminSearch';

interface ClientTournamentEditProps {
    tId: string;
    clId: string;
    onClose: () => void;
}
export const ClientTournamentEdit: FC<ClientTournamentEditProps> = (props) => {
    const api = getTournamentSysAdminApi();

    const [highscoreStrategyId, setHighscoreStrategyId] = useState('');
    const [state, setState] = useState('');

    const dispatch = useDispatch();

    const [copySubscriptionsFromTournamentId, setCopySubscriptionsFromTournamentId] = useState('');
    const [showSubscriptionCopyDialog, setShowSubscriptionCopyDialog] = useState(false);

    const clientTournamentQuery = useQuery({
        queryKey: ['clientTournament.get', props.tId, props.clId],
        queryFn: () => api.getClientTournament({tId: props.tId, clId: props.clId}),
        retry: false,
    });

    const clientTournamentStatsQuery = useQuery({
        queryKey: ['clientTournament.getStats', props.tId, props.clId],
        queryFn: () => api.getClientTournamentStats({tId: props.tId, clId: props.clId}),
        retry: false,
    });

    useEffect(() => {
        if (clientTournamentQuery.data) {
            setHighscoreStrategyId(String(clientTournamentQuery.data.highscoreStrategyDescriptorId ?? ''));
            setState(String(clientTournamentQuery.data.state ?? ''));
        }
    }, [clientTournamentQuery.data]);

    const hsdQuery = useQuery({
        queryKey: ['highscoreStrategyDescriptor.getAll'],
        queryFn: () => api.getHighscoreStrategyDescriptors({clId: props.clId}),
        /*
        onSuccess(data) {
            setHsds(data);
        },
        */
    });

    const updateMutation = useMutation({
        mutationFn: () => api.createOrUpdateClientTournament({tId: props.tId, clId: props.clId, hsdId: highscoreStrategyId, state}),
    });

    const onSave = () => {
        updateMutation.mutate(undefined, {
            onSuccess(data, variables, context) {
                props.onClose();
            },
        });
    };

    const copySubscriberMutation = useMutation({
        mutationFn: () => api.copyTournamentSubscribers({tId: props.tId, clId: props.clId, fromTId: copySubscriptionsFromTournamentId}),
    });

    const refreshParticipatingUserGroupsMutation = useMutation({
        mutationFn: () => api.refreshUserGroupClientTournamentsAsync({tId: props.tId, clId: props.clId}),
    });

    const refreshParticipatingUserGroups = () => {
        refreshParticipatingUserGroupsMutation.mutate(undefined, {
            onSuccess(data, variables, context) {
                dispatch(systemSetPopupMessage('Participating user groups will be refreshed in the next minutes.'));
            },
            onError(error, variables, context) {
                dispatch(systemSetPopupMessage('Error refreshing participating user groups.'));
            },
        });
    };

    const copySubscriptionsFromTournament = () => {
        if (!copySubscriptionsFromTournamentId) {
            dispatch(systemSetPopupMessage('Please select a tournament to copy from.'));
            return;
        }
        if (copySubscriptionsFromTournamentId === props.tId) {
            dispatch(systemSetPopupMessage('Cannot copy from the same tournament.'));
            return;
        }
        copySubscriberMutation.mutate(undefined, {
            onSuccess(data, variables, context) {
                dispatch(systemSetPopupMessage('Subscriptions copied.'));
                setShowSubscriptionCopyDialog(false);
                clientTournamentStatsQuery.refetch();
            },
            onError(error, variables, context) {
                dispatch(systemSetPopupMessage('Error copying subscriptions.'));
            },
        });
    };

    return (
        <Modal isOpen={true} toggle={props.onClose}>
            <ModalHeader toggle={props.onClose}>Edit Client Tournament</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label>Client</Label>
                    <div className="rounded bg-secondary p-2 ">{clientTournamentQuery.data?.clientName}</div>
                </FormGroup>
                <FormGroup>
                    <Label>Tournament</Label>
                    <div className="rounded bg-secondary p-2 ">{clientTournamentQuery.data?.tournamentName}</div>
                </FormGroup>
                <FormGroup>
                    <Label>Highscore Strategy</Label>
                    <LoadingIndicatorContent isLoading={hsdQuery.isFetching}>
                        <Input
                            type="select"
                            value={highscoreStrategyId}
                            onChange={(e) => setHighscoreStrategyId(e.currentTarget.value)}
                        >
                            {hsdQuery.data?.map((hsd) => (
                                <option key={hsd.id} value={hsd.id}>
                                    {hsd.name}
                                </option>
                            ))}
                        </Input>
                    </LoadingIndicatorContent>
                </FormGroup>
                <FormGroup>
                    <Label>State</Label>
                    <LoadingIndicatorContent isLoading={clientTournamentQuery.isFetching}>
                        <Input type="select" value={state} onChange={(e) => setState(e.currentTarget.value)}>
                            <option value="1">active</option>
                            <option value="2">inactive</option>
                            <option value="4">on-hold</option>
                        </Input>
                    </LoadingIndicatorContent>
                </FormGroup>
                <FormGroup>
                    <Label>Subscribed Users</Label>
                    <div className="d-flex flex-row w-100 align-items-center">
                        <div className="rounded bg-secondary p-2 w-100 me-2 ">
                            <LoadingIndicatorContent isLoading={clientTournamentStatsQuery.isFetching}>
                                {clientTournamentStatsQuery.data?.subscribedUsers || 0}
                            </LoadingIndicatorContent>
                        </div>
                        <Button color="link" className="border" onClick={() => setShowSubscriptionCopyDialog(true)}>
                            <FontAwesomeIcon icon={faCopy} />
                        </Button>
                    </div>
                </FormGroup>
                {showSubscriptionCopyDialog && (
                    <FormGroup>
                        <Label>Copy Existing Subcribers from Tournament</Label>
                        <div className="d-flex flex-row w-100 align-items-center mt-2 ms-0">
                            <IncrementalTournamentSysadminSearch
                                onSelected={(tId) => setCopySubscriptionsFromTournamentId(tId)}
                            />
                            <LoadingIndicatorSpinner2 isLoading={copySubscriberMutation.isPending} />
                            <ButtonWithConfirmation
                                title="Copy Subscriptions"
                                message="Do you really want to copy the subscriptions?"
                                onOK={copySubscriptionsFromTournament}
                                color="success"
                            >
                                Copy
                            </ButtonWithConfirmation>
                        </div>
                    </FormGroup>
                )}
                <FormGroup>
                    <Label>Participating User Groups</Label>
                    <div className="d-flex flex-row w-100 align-items-center">
                        <div className="rounded bg-secondary p-2 w-100 me-2">
                            <LoadingIndicatorContent isLoading={clientTournamentStatsQuery.isFetching}>
                                {clientTournamentStatsQuery.data?.participatingUserGroups || 0}
                            </LoadingIndicatorContent>
                        </div>
                        <ButtonWithConfirmation
                            color="link"
                            className="border"
                            title="Refresh Participating User Groups"
                            message="Do you really want to refresh the participating user groups? This is an expensive operation which will take a few minutes."
                            onOK={refreshParticipatingUserGroups}
                        >
                            <FontAwesomeIcon icon={faRefresh} />
                        </ButtonWithConfirmation>
                    </div>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <FormGroup>
                    <Button className="m-2" onClick={props.onClose}>
                        Cancel
                    </Button>
                    <Button className="m-2" onClick={onSave} color="success">
                        Save
                    </Button>
                </FormGroup>
            </ModalFooter>
        </Modal>
    );
};
