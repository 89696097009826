import React, { FC, useEffect, useState } from 'react';
import { getTournamentSysAdminApi } from '../../../../System/systemConfig';
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useMutation, useQuery } from '@tanstack/react-query';
import { TournamentGroup } from '../../../../../services/betting';
import { ButtonWithConfirmation } from '../../../../common/ButtonWithConfirmation';
import { TournamentGroupMemberEdit } from './TournamentGroupMemberEdit';

interface TournamentGroupEditProps {
    tgId?: string;
    trId?: string;
    isShown: boolean;
    onClose: () => void;
}
export const TournamentGroupEdit: FC<TournamentGroupEditProps> = (props) => {
    const tournamentApi = getTournamentSysAdminApi();

    const [name, setName] = useState('');
    const [longName, setLongName] = useState('');
    const [order, setOrder] = useState(0);
    const [tournamentGroup, setTournamentGroup] = useState<TournamentGroup>();

    const tournamentGroupQuery = useQuery({
        queryKey: ['tournamentGroup.getById', props.tgId],
        queryFn: () => tournamentApi.getTournamentGroup({ tgId: props.tgId! }),
        enabled: !!props.tgId,
    });

    useEffect(() => {
        if (tournamentGroupQuery.data) {
            setTournamentGroup(tournamentGroupQuery.data);
            setName(tournamentGroupQuery.data.name ?? '');
            setLongName(tournamentGroupQuery.data.longName ?? '');
            setOrder(tournamentGroupQuery.data.order ?? 0);
        }
    }, [tournamentGroupQuery.data]);

    const updateTournamentGroupMutation = useMutation({
        mutationFn: (param: { tgId: string; name: string; longName: string; order: number }) =>
            tournamentApi.updateTournamentGroup({
                tgId: param.tgId,
                name: param.name,
                longName: param.longName,
                order: param.order,
            }),

        onSuccess(data, variables, context) {
            setTournamentGroup(data);
            setName(data.name ?? '');
            setLongName(data.longName ?? '');
            setOrder(data.order ?? 0);
        },
    });

    const createTournamentGroupMutation = useMutation({
        mutationFn: (param: { trId: string; name: string; longName: string; order: number }) =>
            tournamentApi.createTournamentGroup(param),

        onSuccess(data, variables, context) {
            props.onClose();
        },
    });

    const deleteTournamentGroupMutation = useMutation({
        mutationFn: (tgId: string) => tournamentApi.deleteTournamentGroup({tgId}),
        onSuccess(data, variables, context) {},
    });

    const onClose = () => {
        props.onClose();
    };

    const onDelete = () => {
        if (props.tgId) {
            deleteTournamentGroupMutation.mutate(props.tgId, { onSuccess: () => props.onClose() });
        }
    };

    const onSave = () => {
        if (props.tgId) {
            // update
            updateTournamentGroupMutation.mutate(
                { tgId: props.tgId, name, longName, order },
                { onSuccess: () => onClose() },
            );
        } else {
            // create
            if (props.trId) {
                createTournamentGroupMutation.mutate(
                    { trId: props.trId, name, longName, order },
                    { onSuccess: () => props.onClose() },
                );
            }
        }
    };

    return (
        <>
            <Modal isOpen={props.isShown} toggle={onClose}>
                <ModalHeader>Edit Tournament Group '{tournamentGroup?.longName || '<New>'}'</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label>Name</Label>
                            <Input value={name} onChange={(e) => setName(e.currentTarget.value)} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Long Name</Label>
                            <Input value={longName} onChange={(e) => setLongName(e.currentTarget.value)} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Order</Label>
                            <Input value={order} onChange={(e) => setOrder(Number(e.currentTarget.value))} />
                        </FormGroup>
                    </Form>
                    {!!props.tgId && <TournamentGroupMemberEdit tgId={props.tgId} />}
                </ModalBody>
                <ModalFooter>
                    {props.tgId && (
                        <ButtonWithConfirmation
                            color="danger"
                            onOK={onDelete}
                            message={`Are you sure you want to delete Tournamen Group '${tournamentGroup?.longName}'?`}
                            title="Delete Tournament Group"
                        >
                            Delete
                        </ButtonWithConfirmation>
                    )}
                    <Button className="mx-2" color="success" onClick={onSave}>
                        {props.tgId && <>Save</>}
                        {props.trId && <>Create</>}
                    </Button>

                    <Button className="mx-2" onClick={onClose}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};
