/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MusicbrainzMedia } from './MusicbrainzMedia';
import {
    MusicbrainzMediaFromJSON,
    MusicbrainzMediaFromJSONTyped,
    MusicbrainzMediaToJSON,
} from './MusicbrainzMedia';

/**
 * 
 * @export
 * @interface MusicbrainzRelease
 */
export interface MusicbrainzRelease {
    /**
     * 
     * @type {string}
     * @memberof MusicbrainzRelease
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof MusicbrainzRelease
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof MusicbrainzRelease
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof MusicbrainzRelease
     */
    coverArtUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof MusicbrainzRelease
     */
    thumbnailUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof MusicbrainzRelease
     */
    date: string;
    /**
     * 
     * @type {number}
     * @memberof MusicbrainzRelease
     */
    trackCount: number;
    /**
     * 
     * @type {Array<MusicbrainzMedia>}
     * @memberof MusicbrainzRelease
     */
    media: Array<MusicbrainzMedia>;
}

/**
 * Check if a given object implements the MusicbrainzRelease interface.
 */
export function instanceOfMusicbrainzRelease(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "trackCount" in value;
    isInstance = isInstance && "media" in value;

    return isInstance;
}

export function MusicbrainzReleaseFromJSON(json: any): MusicbrainzRelease {
    return MusicbrainzReleaseFromJSONTyped(json, false);
}

export function MusicbrainzReleaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MusicbrainzRelease {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'title': json['title'],
        'status': json['status'],
        'coverArtUrl': !exists(json, 'coverArtUrl') ? undefined : json['coverArtUrl'],
        'thumbnailUrl': !exists(json, 'thumbnailUrl') ? undefined : json['thumbnailUrl'],
        'date': json['date'],
        'trackCount': json['trackCount'],
        'media': ((json['media'] as Array<any>).map(MusicbrainzMediaFromJSON)),
    };
}

export function MusicbrainzReleaseToJSON(value?: MusicbrainzRelease | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'status': value.status,
        'coverArtUrl': value.coverArtUrl,
        'thumbnailUrl': value.thumbnailUrl,
        'date': value.date,
        'trackCount': value.trackCount,
        'media': ((value.media as Array<any>).map(MusicbrainzMediaToJSON)),
    };
}

