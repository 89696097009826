/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-webapp API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.25
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AgbInfo
 */
export interface AgbInfo {
    /**
     * 
     * @type {string}
     * @memberof AgbInfo
     */
    privacyStatementVersion: string;
    /**
     * 
     * @type {string}
     * @memberof AgbInfo
     */
    confirmedPrivacyStatementVersion: string;
    /**
     * 
     * @type {string}
     * @memberof AgbInfo
     */
    privacyStatementUrl: string;
    /**
     * 
     * @type {string}
     * @memberof AgbInfo
     */
    privacyStatement: string;
    /**
     * 
     * @type {string}
     * @memberof AgbInfo
     */
    imprint: string;
}

/**
 * Check if a given object implements the AgbInfo interface.
 */
export function instanceOfAgbInfo(value: object): value is AgbInfo {
    if (!('privacyStatementVersion' in value) || value['privacyStatementVersion'] === undefined) return false;
    if (!('confirmedPrivacyStatementVersion' in value) || value['confirmedPrivacyStatementVersion'] === undefined) return false;
    if (!('privacyStatementUrl' in value) || value['privacyStatementUrl'] === undefined) return false;
    if (!('privacyStatement' in value) || value['privacyStatement'] === undefined) return false;
    if (!('imprint' in value) || value['imprint'] === undefined) return false;
    return true;
}

export function AgbInfoFromJSON(json: any): AgbInfo {
    return AgbInfoFromJSONTyped(json, false);
}

export function AgbInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AgbInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'privacyStatementVersion': json['privacyStatementVersion'],
        'confirmedPrivacyStatementVersion': json['confirmedPrivacyStatementVersion'],
        'privacyStatementUrl': json['privacyStatementUrl'],
        'privacyStatement': json['privacyStatement'],
        'imprint': json['imprint'],
    };
}

export function AgbInfoToJSON(value?: AgbInfo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'privacyStatementVersion': value['privacyStatementVersion'],
        'confirmedPrivacyStatementVersion': value['confirmedPrivacyStatementVersion'],
        'privacyStatementUrl': value['privacyStatementUrl'],
        'privacyStatement': value['privacyStatement'],
        'imprint': value['imprint'],
    };
}

