import { AnyAction, isType } from 'typescript-fsa';
import { BettingUser } from '../../../services/betting';
import { userSearchSet, userSearchSetEntities, userSearchSetResultCount } from './actions';

export interface UserSearchState {
    searchString: string;
    searchResults: BettingUser[];
    searchResultCount: number;
}

const initialUserSearchState: UserSearchState = {
    searchString: '',
    searchResults: [],
    searchResultCount: 0
};

export function userSearchReducer(state: UserSearchState = initialUserSearchState, action: AnyAction): UserSearchState {
    if (isType(action, userSearchSetEntities)) {
        return { ...state, searchResults: action.payload };
    } else if (isType(action, userSearchSet)) {
        return { ...state, searchString: action.payload };
    } else if (isType(action, userSearchSetResultCount)) {
        return { ...state, searchResultCount: action.payload };
    }
    return state;
}
