import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Input } from 'reactstrap';
import { Bet } from '../../../../services/betting';
import { logError } from '../../../../utils';
import { saveBet, updateBetState } from '../../actions';
import { isValidBetValue } from '../../betting';

interface BetComponentProps {
    gameId: number;
    bet1: number | undefined;
    bet2: number | undefined;
    bettingAllowed: boolean;
    points: number | undefined;
    betState: BetState;
}

export enum BetState {
    loaded,
    saving,
    saved,
    invalid,
    empty,
    error
}

const BetComponent: React.FC<BetComponentProps> = props => {
    const [bet1, setBet1] = React.useState('');
    const [bet2, setBet2] = React.useState('');

    const dispatch = useDispatch();

    React.useEffect(() => {
        setBet1(props.bet1 != null ? String(props.bet1) : '');
        setBet2(props.bet2 != null ? String(props.bet2) : '');
    }, [props.bet1, props.bet2]);

    const onValidBet = React.useCallback((gameId: number, bet1: number, bet2: number) => {
        let newBet: Bet = { gameId: gameId, bet1: bet1, bet2: bet2 };
        dispatch(saveBet(newBet));
    }, [dispatch]);

    const onInvalidBet = React.useCallback((gameId: number) => {
        dispatch(updateBetState({ gameId: gameId, betState: BetState.invalid }));
    }, [dispatch]);

/*     React.useEffect(() => {
        if (!bet1 && !bet2) {
            return;
        }
    }, [bet1, bet2, props.gameId, onValidBet, onInvalidBet, dispatch]);
 */
    const onBetChanged = (event: React.FormEvent<HTMLInputElement>) => {
        let bet1Val = bet1;
        let bet2Val = bet2;
        if (event.currentTarget.name === 'bet1') {
            setBet1(event.currentTarget.value);
            bet1Val = event.currentTarget.value;
        } else {
            setBet2(event.currentTarget.value);
            bet2Val = event.currentTarget.value;
        }
        const val = isValidBetValue(bet1Val) && isValidBetValue(bet2Val);
        if (!val) {
            onInvalidBet(props.gameId);
        } else {
            onValidBet(props.gameId, Number(bet1Val), Number(bet2Val));
        }
    };

    const onBetFocus = (event: React.FormEvent<HTMLInputElement>) => {
        event.currentTarget.select();
    }

    if (props.bettingAllowed) {
        let color = '';
        if (props.betState === BetState.saved || props.betState === BetState.loaded) {
            color = 'white';
        } else if (props.betState === BetState.empty) {
            color = '#c2c205';
        } else if (props.betState === BetState.error || props.betState === BetState.invalid) {
            color = '#f5356a';
        } else if (props.betState === BetState.saving) {
            color = 'blue';
        } else {
            logError(new Error('invalid state: ' + props.betState + ' ' + color));
        }
        return (
            <span className="d-inline-flex align-items-center text-nowrap btn-r1--bet-input  px-sm-3" style={{ overflowX: 'auto' }}>
                <Input
                    className="d-inline-block border-0 p-0 px-0 text-center"
                    type="number" pattern="\d*"
                    sm={1}
                    name="bet1"
                    size={1}
                    bsSize="sm"
                    value={bet1}
                    onChange={onBetChanged}
                    onFocus={onBetFocus}
                />
                <span className="btn-r1--bet-input-colon mt-1" style={{ backgroundColor: '#E2FCE2', color: 'black', display: 'inline-block', minHeight: '16px'}}>:</span>
                <Input
                    className="d-inline-block border-0 p-0 text-center"
                    type="number" pattern="\d*"
                    sm={1}
                    name="bet2"
                    size={1}
                    bsSize="sm"
                    value={bet2}
                    onChange={onBetChanged}
                    onFocus={onBetFocus}
                />
            </span>
        );
    } else {
        return (
            props.bet1 !== undefined && props.bet1 !== null ?
            <span className="btn-r1--bet  px-sm-3">
                {bet1}
                &nbsp;:&nbsp;
                {bet2}
            </span>
                : null

        );
    }
};

export default BetComponent;
