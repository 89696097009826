import { useQuery } from '@tanstack/react-query';
import * as R from 'ramda';
import { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import { withFooter } from '../../../reactutils/withFooter';
import { withMenu } from '../../../reactutils/withMenu';
import { Section, SectionHeading } from '../../common/Section';
import { MenuBetting } from '../../MenuBetting';
import { getProfile2Api, getSystemConfig } from '../../System/systemConfig';

const PrivacyStatementComponent: FC<{}> = () => {
    const agbInfoQuery = useQuery({
        queryKey: ['agbInfos', getSystemConfig().clientName],
        queryFn: () => getProfile2Api().getAgbInfos({ tenant: getSystemConfig().clientName }),
    });

    return (
        <>
            <Section>
                <SectionHeading>AGB und Datenschutz</SectionHeading>
                <ReactMarkdown>{agbInfoQuery.data?.privacyStatement ?? ''}</ReactMarkdown>
            </Section>
        </>
    );
};

export const PrivacyStatement = R.compose(withMenu(<MenuBetting />), withFooter())(PrivacyStatementComponent);
