/* tslint:disable */
/* eslint-disable */
/**
 * quarkus-betting API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.36.34
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Category,
  Tournament,
  TournamentRound,
  TournamentRoundUseCaseType,
} from '../models/index';
import {
    CategoryFromJSON,
    CategoryToJSON,
    TournamentFromJSON,
    TournamentToJSON,
    TournamentRoundFromJSON,
    TournamentRoundToJSON,
    TournamentRoundUseCaseTypeFromJSON,
    TournamentRoundUseCaseTypeToJSON,
} from '../models/index';

export interface AssignAllTournamentRoundsRequest {
    provider: string;
    tId: string;
    roundKeys?: Array<string>;
}

export interface AssignTournamentExternalkeyRequest {
    key: string;
    provider: string;
    tId: string;
}

export interface AssignTournamentRoundExternalkeyRequest {
    key: string;
    provider: string;
    trId: string;
}

export interface DeleteTournamentExternalkeyRequest {
    provider: string;
    tId: string;
}

export interface DeleteTournamentRoundExternalkeyRequest {
    provider: string;
    trId: string;
}

export interface GetActiveTournamentsRequest {
    clientId: number;
}

export interface GetDefaultTournamentRequest {
    clientId: number;
}

export interface GetDefaultTournamentRoundByTypeRequest {
    tournamentId: number;
    type: TournamentRoundUseCaseType;
}

export interface GetDefaultTournamentRoundIdByTypeRequest {
    tournamentId: number;
    type: TournamentRoundUseCaseType;
}

export interface GetLastTournamentRoundByTypeRequest {
    tournamentId: number;
    type: TournamentRoundUseCaseType;
}

export interface GetNextTournamentRoundByTypeRequest {
    trId: number;
    type: TournamentRoundUseCaseType;
}

export interface GetPreviousTournamentRoundByTypeRequest {
    trId: number;
    type: TournamentRoundUseCaseType;
}

export interface GetRootTournamentRoundByTypeRequest {
    tournamentId: number;
    type: TournamentRoundUseCaseType;
}

export interface GetTournamentArchiveRequest {
    clientId: number;
}

export interface GetTournamentByExternalKeyRequest {
    key: string;
    provider: string;
}

export interface GetTournamentByIdRequest {
    tId: number;
}

export interface GetTournamentCategoriesRequest {
    clientId: number;
}

export interface GetTournamentRoundByExternalKeyRequest {
    key: string;
    provider: string;
}

export interface GetTournamentRoundByIdRequest {
    trId: number;
}

export interface GetTournamentRoundsByTypeRequest {
    tournamentId: number;
    type: TournamentRoundUseCaseType;
}

export interface ImportFromProviderRequest {
    provider: string;
    trId: string;
}

export interface ImportTournamentFromProviderRequest {
    provider: string;
    tId: string;
}

/**
 * 
 */
export class TournamentControllerApi extends runtime.BaseAPI {

    /**
     */
    async assignAllTournamentRoundsRaw(requestParameters: AssignAllTournamentRoundsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Tournament>> {
        if (requestParameters['provider'] == null) {
            throw new runtime.RequiredError(
                'provider',
                'Required parameter "provider" was null or undefined when calling assignAllTournamentRounds().'
            );
        }

        if (requestParameters['tId'] == null) {
            throw new runtime.RequiredError(
                'tId',
                'Required parameter "tId" was null or undefined when calling assignAllTournamentRounds().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['roundKeys'] != null) {
            queryParameters['roundKeys'] = requestParameters['roundKeys'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournaments/{tId}/assign-all-rounds/{provider}`.replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters['provider']))).replace(`{${"tId"}}`, encodeURIComponent(String(requestParameters['tId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TournamentFromJSON(jsonValue));
    }

    /**
     */
    async assignAllTournamentRounds(requestParameters: AssignAllTournamentRoundsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Tournament> {
        const response = await this.assignAllTournamentRoundsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async assignTournamentExternalkeyRaw(requestParameters: AssignTournamentExternalkeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Tournament>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling assignTournamentExternalkey().'
            );
        }

        if (requestParameters['provider'] == null) {
            throw new runtime.RequiredError(
                'provider',
                'Required parameter "provider" was null or undefined when calling assignTournamentExternalkey().'
            );
        }

        if (requestParameters['tId'] == null) {
            throw new runtime.RequiredError(
                'tId',
                'Required parameter "tId" was null or undefined when calling assignTournamentExternalkey().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournaments/external-key/assign/{tId}/{provider}/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))).replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters['provider']))).replace(`{${"tId"}}`, encodeURIComponent(String(requestParameters['tId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TournamentFromJSON(jsonValue));
    }

    /**
     */
    async assignTournamentExternalkey(requestParameters: AssignTournamentExternalkeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Tournament> {
        const response = await this.assignTournamentExternalkeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async assignTournamentRoundExternalkeyRaw(requestParameters: AssignTournamentRoundExternalkeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TournamentRound>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling assignTournamentRoundExternalkey().'
            );
        }

        if (requestParameters['provider'] == null) {
            throw new runtime.RequiredError(
                'provider',
                'Required parameter "provider" was null or undefined when calling assignTournamentRoundExternalkey().'
            );
        }

        if (requestParameters['trId'] == null) {
            throw new runtime.RequiredError(
                'trId',
                'Required parameter "trId" was null or undefined when calling assignTournamentRoundExternalkey().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournaments/tournament-rounds/external-key/assign/{trId}/{provider}/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))).replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters['provider']))).replace(`{${"trId"}}`, encodeURIComponent(String(requestParameters['trId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TournamentRoundFromJSON(jsonValue));
    }

    /**
     */
    async assignTournamentRoundExternalkey(requestParameters: AssignTournamentRoundExternalkeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TournamentRound> {
        const response = await this.assignTournamentRoundExternalkeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteTournamentExternalkeyRaw(requestParameters: DeleteTournamentExternalkeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Tournament>> {
        if (requestParameters['provider'] == null) {
            throw new runtime.RequiredError(
                'provider',
                'Required parameter "provider" was null or undefined when calling deleteTournamentExternalkey().'
            );
        }

        if (requestParameters['tId'] == null) {
            throw new runtime.RequiredError(
                'tId',
                'Required parameter "tId" was null or undefined when calling deleteTournamentExternalkey().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournaments/external-key/delete/{tId}/{provider}`.replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters['provider']))).replace(`{${"tId"}}`, encodeURIComponent(String(requestParameters['tId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TournamentFromJSON(jsonValue));
    }

    /**
     */
    async deleteTournamentExternalkey(requestParameters: DeleteTournamentExternalkeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Tournament> {
        const response = await this.deleteTournamentExternalkeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteTournamentRoundExternalkeyRaw(requestParameters: DeleteTournamentRoundExternalkeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TournamentRound>> {
        if (requestParameters['provider'] == null) {
            throw new runtime.RequiredError(
                'provider',
                'Required parameter "provider" was null or undefined when calling deleteTournamentRoundExternalkey().'
            );
        }

        if (requestParameters['trId'] == null) {
            throw new runtime.RequiredError(
                'trId',
                'Required parameter "trId" was null or undefined when calling deleteTournamentRoundExternalkey().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournaments/tournament-rounds/external-key/delete/{trId}/{provider}`.replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters['provider']))).replace(`{${"trId"}}`, encodeURIComponent(String(requestParameters['trId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TournamentRoundFromJSON(jsonValue));
    }

    /**
     */
    async deleteTournamentRoundExternalkey(requestParameters: DeleteTournamentRoundExternalkeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TournamentRound> {
        const response = await this.deleteTournamentRoundExternalkeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getActiveTournamentsRaw(requestParameters: GetActiveTournamentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Tournament>>> {
        if (requestParameters['clientId'] == null) {
            throw new runtime.RequiredError(
                'clientId',
                'Required parameter "clientId" was null or undefined when calling getActiveTournaments().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tournaments/{clientId}/active`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters['clientId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TournamentFromJSON));
    }

    /**
     */
    async getActiveTournaments(requestParameters: GetActiveTournamentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Tournament>> {
        const response = await this.getActiveTournamentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getDefaultTournamentRaw(requestParameters: GetDefaultTournamentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Tournament>> {
        if (requestParameters['clientId'] == null) {
            throw new runtime.RequiredError(
                'clientId',
                'Required parameter "clientId" was null or undefined when calling getDefaultTournament().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tournaments/{clientId}/default`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters['clientId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TournamentFromJSON(jsonValue));
    }

    /**
     */
    async getDefaultTournament(requestParameters: GetDefaultTournamentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Tournament> {
        const response = await this.getDefaultTournamentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getDefaultTournamentRoundByTypeRaw(requestParameters: GetDefaultTournamentRoundByTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TournamentRound>> {
        if (requestParameters['tournamentId'] == null) {
            throw new runtime.RequiredError(
                'tournamentId',
                'Required parameter "tournamentId" was null or undefined when calling getDefaultTournamentRoundByType().'
            );
        }

        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling getDefaultTournamentRoundByType().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tournaments/{tournamentId}/tournament-rounds/default/{type}`.replace(`{${"tournamentId"}}`, encodeURIComponent(String(requestParameters['tournamentId']))).replace(`{${"type"}}`, encodeURIComponent(String(requestParameters['type']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TournamentRoundFromJSON(jsonValue));
    }

    /**
     */
    async getDefaultTournamentRoundByType(requestParameters: GetDefaultTournamentRoundByTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TournamentRound> {
        const response = await this.getDefaultTournamentRoundByTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getDefaultTournamentRoundIdByTypeRaw(requestParameters: GetDefaultTournamentRoundIdByTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['tournamentId'] == null) {
            throw new runtime.RequiredError(
                'tournamentId',
                'Required parameter "tournamentId" was null or undefined when calling getDefaultTournamentRoundIdByType().'
            );
        }

        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling getDefaultTournamentRoundIdByType().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tournaments/{tournamentId}/tournament-round-ids/default/{type}`.replace(`{${"tournamentId"}}`, encodeURIComponent(String(requestParameters['tournamentId']))).replace(`{${"type"}}`, encodeURIComponent(String(requestParameters['type']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async getDefaultTournamentRoundIdByType(requestParameters: GetDefaultTournamentRoundIdByTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.getDefaultTournamentRoundIdByTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getLastTournamentRoundByTypeRaw(requestParameters: GetLastTournamentRoundByTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TournamentRound>> {
        if (requestParameters['tournamentId'] == null) {
            throw new runtime.RequiredError(
                'tournamentId',
                'Required parameter "tournamentId" was null or undefined when calling getLastTournamentRoundByType().'
            );
        }

        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling getLastTournamentRoundByType().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tournaments/{tournamentId}/tournament-rounds/last/{type}`.replace(`{${"tournamentId"}}`, encodeURIComponent(String(requestParameters['tournamentId']))).replace(`{${"type"}}`, encodeURIComponent(String(requestParameters['type']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TournamentRoundFromJSON(jsonValue));
    }

    /**
     */
    async getLastTournamentRoundByType(requestParameters: GetLastTournamentRoundByTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TournamentRound> {
        const response = await this.getLastTournamentRoundByTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getNextTournamentRoundByTypeRaw(requestParameters: GetNextTournamentRoundByTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TournamentRound>> {
        if (requestParameters['trId'] == null) {
            throw new runtime.RequiredError(
                'trId',
                'Required parameter "trId" was null or undefined when calling getNextTournamentRoundByType().'
            );
        }

        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling getNextTournamentRoundByType().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tournaments/tournament-rounds/{trId}/next/{type}`.replace(`{${"trId"}}`, encodeURIComponent(String(requestParameters['trId']))).replace(`{${"type"}}`, encodeURIComponent(String(requestParameters['type']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TournamentRoundFromJSON(jsonValue));
    }

    /**
     */
    async getNextTournamentRoundByType(requestParameters: GetNextTournamentRoundByTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TournamentRound> {
        const response = await this.getNextTournamentRoundByTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPreviousTournamentRoundByTypeRaw(requestParameters: GetPreviousTournamentRoundByTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TournamentRound>> {
        if (requestParameters['trId'] == null) {
            throw new runtime.RequiredError(
                'trId',
                'Required parameter "trId" was null or undefined when calling getPreviousTournamentRoundByType().'
            );
        }

        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling getPreviousTournamentRoundByType().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tournaments/tournament-rounds/{trId}/previous/{type}`.replace(`{${"trId"}}`, encodeURIComponent(String(requestParameters['trId']))).replace(`{${"type"}}`, encodeURIComponent(String(requestParameters['type']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TournamentRoundFromJSON(jsonValue));
    }

    /**
     */
    async getPreviousTournamentRoundByType(requestParameters: GetPreviousTournamentRoundByTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TournamentRound> {
        const response = await this.getPreviousTournamentRoundByTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getRootTournamentRoundByTypeRaw(requestParameters: GetRootTournamentRoundByTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TournamentRound>> {
        if (requestParameters['tournamentId'] == null) {
            throw new runtime.RequiredError(
                'tournamentId',
                'Required parameter "tournamentId" was null or undefined when calling getRootTournamentRoundByType().'
            );
        }

        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling getRootTournamentRoundByType().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tournaments/{tournamentId}/tournament-rounds/root/{type}`.replace(`{${"tournamentId"}}`, encodeURIComponent(String(requestParameters['tournamentId']))).replace(`{${"type"}}`, encodeURIComponent(String(requestParameters['type']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TournamentRoundFromJSON(jsonValue));
    }

    /**
     */
    async getRootTournamentRoundByType(requestParameters: GetRootTournamentRoundByTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TournamentRound> {
        const response = await this.getRootTournamentRoundByTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTournamentArchiveRaw(requestParameters: GetTournamentArchiveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Tournament>>> {
        if (requestParameters['clientId'] == null) {
            throw new runtime.RequiredError(
                'clientId',
                'Required parameter "clientId" was null or undefined when calling getTournamentArchive().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tournaments/{clientId}/archive`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters['clientId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TournamentFromJSON));
    }

    /**
     */
    async getTournamentArchive(requestParameters: GetTournamentArchiveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Tournament>> {
        const response = await this.getTournamentArchiveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTournamentByExternalKeyRaw(requestParameters: GetTournamentByExternalKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Tournament>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling getTournamentByExternalKey().'
            );
        }

        if (requestParameters['provider'] == null) {
            throw new runtime.RequiredError(
                'provider',
                'Required parameter "provider" was null or undefined when calling getTournamentByExternalKey().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tournaments/external-key/{provider}/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))).replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters['provider']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TournamentFromJSON(jsonValue));
    }

    /**
     */
    async getTournamentByExternalKey(requestParameters: GetTournamentByExternalKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Tournament> {
        const response = await this.getTournamentByExternalKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTournamentByIdRaw(requestParameters: GetTournamentByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Tournament>> {
        if (requestParameters['tId'] == null) {
            throw new runtime.RequiredError(
                'tId',
                'Required parameter "tId" was null or undefined when calling getTournamentById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tournaments/{tId}`.replace(`{${"tId"}}`, encodeURIComponent(String(requestParameters['tId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TournamentFromJSON(jsonValue));
    }

    /**
     */
    async getTournamentById(requestParameters: GetTournamentByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Tournament> {
        const response = await this.getTournamentByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTournamentCategoriesRaw(requestParameters: GetTournamentCategoriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Category>>> {
        if (requestParameters['clientId'] == null) {
            throw new runtime.RequiredError(
                'clientId',
                'Required parameter "clientId" was null or undefined when calling getTournamentCategories().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tournaments/{clientId}/category`.replace(`{${"clientId"}}`, encodeURIComponent(String(requestParameters['clientId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CategoryFromJSON));
    }

    /**
     */
    async getTournamentCategories(requestParameters: GetTournamentCategoriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Category>> {
        const response = await this.getTournamentCategoriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTournamentRoundByExternalKeyRaw(requestParameters: GetTournamentRoundByExternalKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TournamentRound>> {
        if (requestParameters['key'] == null) {
            throw new runtime.RequiredError(
                'key',
                'Required parameter "key" was null or undefined when calling getTournamentRoundByExternalKey().'
            );
        }

        if (requestParameters['provider'] == null) {
            throw new runtime.RequiredError(
                'provider',
                'Required parameter "provider" was null or undefined when calling getTournamentRoundByExternalKey().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tournaments/tournament-rounds/external-key/{provider}/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters['key']))).replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters['provider']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TournamentRoundFromJSON(jsonValue));
    }

    /**
     */
    async getTournamentRoundByExternalKey(requestParameters: GetTournamentRoundByExternalKeyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TournamentRound> {
        const response = await this.getTournamentRoundByExternalKeyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTournamentRoundByIdRaw(requestParameters: GetTournamentRoundByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TournamentRound>> {
        if (requestParameters['trId'] == null) {
            throw new runtime.RequiredError(
                'trId',
                'Required parameter "trId" was null or undefined when calling getTournamentRoundById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tournaments/tournament-rounds/{trId}`.replace(`{${"trId"}}`, encodeURIComponent(String(requestParameters['trId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TournamentRoundFromJSON(jsonValue));
    }

    /**
     */
    async getTournamentRoundById(requestParameters: GetTournamentRoundByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TournamentRound> {
        const response = await this.getTournamentRoundByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getTournamentRoundsByTypeRaw(requestParameters: GetTournamentRoundsByTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<TournamentRound>>> {
        if (requestParameters['tournamentId'] == null) {
            throw new runtime.RequiredError(
                'tournamentId',
                'Required parameter "tournamentId" was null or undefined when calling getTournamentRoundsByType().'
            );
        }

        if (requestParameters['type'] == null) {
            throw new runtime.RequiredError(
                'type',
                'Required parameter "type" was null or undefined when calling getTournamentRoundsByType().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/tournaments/{tournamentId}/tournament-rounds/list/{type}`.replace(`{${"tournamentId"}}`, encodeURIComponent(String(requestParameters['tournamentId']))).replace(`{${"type"}}`, encodeURIComponent(String(requestParameters['type']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TournamentRoundFromJSON));
    }

    /**
     */
    async getTournamentRoundsByType(requestParameters: GetTournamentRoundsByTypeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<TournamentRound>> {
        const response = await this.getTournamentRoundsByTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async importFromProviderRaw(requestParameters: ImportFromProviderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TournamentRound>> {
        if (requestParameters['provider'] == null) {
            throw new runtime.RequiredError(
                'provider',
                'Required parameter "provider" was null or undefined when calling importFromProvider().'
            );
        }

        if (requestParameters['trId'] == null) {
            throw new runtime.RequiredError(
                'trId',
                'Required parameter "trId" was null or undefined when calling importFromProvider().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournaments/tournament-rounds/{trId}/import-from-provider/{provider}`.replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters['provider']))).replace(`{${"trId"}}`, encodeURIComponent(String(requestParameters['trId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TournamentRoundFromJSON(jsonValue));
    }

    /**
     */
    async importFromProvider(requestParameters: ImportFromProviderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TournamentRound> {
        const response = await this.importFromProviderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async importTournamentFromProviderRaw(requestParameters: ImportTournamentFromProviderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Tournament>> {
        if (requestParameters['provider'] == null) {
            throw new runtime.RequiredError(
                'provider',
                'Required parameter "provider" was null or undefined when calling importTournamentFromProvider().'
            );
        }

        if (requestParameters['tId'] == null) {
            throw new runtime.RequiredError(
                'tId',
                'Required parameter "tId" was null or undefined when calling importTournamentFromProvider().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("SecurityScheme", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/tournaments/{tId}/import-from-provider/{provider}`.replace(`{${"provider"}}`, encodeURIComponent(String(requestParameters['provider']))).replace(`{${"tId"}}`, encodeURIComponent(String(requestParameters['tId']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TournamentFromJSON(jsonValue));
    }

    /**
     */
    async importTournamentFromProvider(requestParameters: ImportTournamentFromProviderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Tournament> {
        const response = await this.importTournamentFromProviderRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
