import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FileUpload } from '../../../common/FileUpload';
import { systemSetPopupMessage } from '../../../System/actions';
import { getNewsletterAdminApi } from '../../../System/systemConfig';
import { NewsletterImage } from '../NewsletterEdit';

interface Props {
    mode: 'create' | 'edit';
    nlId: string;
    nlbId: string | undefined;
    show: boolean;
    onClose: () => void;
}

export const NewsletterBlockEdit: React.FC<Props> = (props) => {
    const api = getNewsletterAdminApi();

    const [title, setTitle] = React.useState('');
    const [link, setLink] = React.useState('');
    const [text, setText] = React.useState('');
    const [imageId, setImageId] = React.useState<string>();
    const [layoutBlockId, setLayoutBlockId] = React.useState<string>();

    const newsletterBlockQuery = useQuery({
        queryKey: ['newsletterBlock', { nlbId: props.nlbId }],
        queryFn: async () => api.getNewsletterBlock({nlbId: props.nlbId!}),
        enabled: !!props.nlbId && Number(props.nlbId) > 0,
    });

    const newsletterQuery = useQuery({
        queryKey: ['newsletter', { nlId: props.nlId }],
        queryFn: async () => api.getNewsletter({nlId: props.nlId}),
        enabled: !!props.nlId,
    });

    const newsletterLayoutBlockQuery = useQuery({
        queryKey: ['newsletterLayoutBlock', newsletterQuery.data?.layoutId],
        queryFn: async () => api.getNewsletterLayoutBlocks({nllId: newsletterQuery.data!.layoutId}),
        enabled: !!newsletterQuery.data?.layoutId,
    });

    const updateNewsletterBlockMutation = useMutation({
        mutationFn: () => api.updateNewsletterBlock({nlbId: props.nlbId!, imageId, link, text, title}),
    });

    const createNewsletterBlockMutation = useMutation({
        mutationFn: () => api.createNewsletterBlock({nlId: props.nlId, imageId, layoutBlockId, link, text, title}),
    });

    const dispatch = useDispatch();

    const onUpdateOrCreate = () => {
        if (props.mode === 'edit') {
            // update
            updateNewsletterBlockMutation.mutate(undefined, {
                onSuccess: () => {
                    clearValues();
                    props.onClose();
                },
                onError: (error) => {
                    dispatch(systemSetPopupMessage('Error updating newsletter block: ' + error));
                },
            });
        } else {
            // create
            createNewsletterBlockMutation.mutate(undefined, {
                onSuccess: () => {
                    clearValues();
                    props.onClose();
                },
                onError: (error) => {
                    dispatch(systemSetPopupMessage('Error creating newsletter block: ' + error));
                },
            });
        }
    };

    const clearValues = () => {
        setText('');
        setTitle('');
        setLink('');
        setImageId(undefined);
    };

    useEffect(() => {
        if (newsletterBlockQuery.data) {
            setText(newsletterBlockQuery.data?.text || '');
            setTitle(newsletterBlockQuery.data?.title || '');
            setLink(newsletterBlockQuery.data?.link || '');
            setImageId(newsletterBlockQuery.data?.imageId);
            setLayoutBlockId(newsletterBlockQuery.data?.newsletterLayoutBlockId);
        } else {
            clearValues();
            setLayoutBlockId(newsletterLayoutBlockQuery.data?.[0]?.id);
        }
    }, [
        newsletterBlockQuery.data,
        newsletterBlockQuery.data?.imageId,
        newsletterBlockQuery.data?.link,
        newsletterBlockQuery.data?.newsletterLayoutBlockId,
        newsletterBlockQuery.data?.text,
        newsletterBlockQuery.data?.title,
        newsletterLayoutBlockQuery.data,
    ]);

    return (
        <Modal isOpen={props.show} toggle={props.onClose}>
            <ModalHeader toggle={props.onClose}>Newsletterblock bearbeiten</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label>Titel</Label>
                    <Input type="text" value={title} onChange={(e) => setTitle(e.currentTarget.value)} />
                </FormGroup>
                <FormGroup>
                    <Label>Layout</Label>
                    <select
                        className="form-control"
                        value={layoutBlockId}
                        onChange={(e) => setLayoutBlockId(e.currentTarget.value)}
                        disabled={props.mode === 'edit'}
                    >
                        {newsletterLayoutBlockQuery.data?.map((nlb) => (
                            <option key={nlb.id} value={nlb.id}>
                                {nlb.name}
                            </option>
                        ))}
                    </select>
                </FormGroup>
                <FormGroup>
                    <Label>Link</Label>
                    <Input type="text" value={link} onChange={(e) => setLink(e.currentTarget.value)} />
                </FormGroup>
                <FormGroup>
                    <Label>Text</Label>
                    <textarea
                        className="form-control"
                        rows={5}
                        value={text}
                        onChange={(e) => setText(e.currentTarget.value)}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Image</Label>
                    <div>
                        <div className="m-0 mb-2 p-0">
                            <NewsletterImage imageId={imageId} maxWidth="200px" />
                        </div>
                        <FileUpload imageUploaded={(imageId) => setImageId(imageId)} />
                    </div>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button onClick={props.onClose}>Abbrechen</Button>
                {props.mode === 'edit' && (
                    <Button color="success" onClick={onUpdateOrCreate}>
                        Speichern
                    </Button>
                )}
                {props.mode === 'create' && (
                    <Button color="success" onClick={onUpdateOrCreate}>
                        Anlegen
                    </Button>
                )}
            </ModalFooter>
        </Modal>
    );
};
