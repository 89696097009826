import { push } from '@lagunovsky/redux-react-router';
import { all, put, takeLatest } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';
import { loadWithoutPropsTournamentRoundForwarder } from '../../../reactUtils';
import { fetchWrapperFuncNoLoading } from '../../../utils';
import { groupInternalHighscorePath } from '../../GroupInternalHighscores';
import { highscoresPath } from '../../Highscores';
import { getHighscoreApi2, getTeamChatApi } from '../../System/systemConfig';
import {
    MY_GROUP_HIGHSCORES_FORWARD,
    MY_GROUP_HIGHSCORES_GOTO_HIGHSCORE,
    MY_GROUP_HIGHSCORES_REFRESH_UNREAD_TEAMCHAT_MESSAGES,
    myGroupHighscoresSetUnreadTeamChatMessages
} from './actions';

function* refreshUnreadTeamChat(action: Action<string>) {
    const teamChatApi = getTeamChatApi();

    const ugId = action.payload;
    if (ugId) {
        yield* fetchWrapperFuncNoLoading(() => teamChatApi.countUnread(ugId), [
            r => myGroupHighscoresSetUnreadTeamChatMessages(r.value)
        ]);
    }
}

function* forward(action: Action<string>) {
    const ugId = action.payload;
    yield* loadWithoutPropsTournamentRoundForwarder('highscores', trId =>
        groupInternalHighscorePath(trId, String(ugId))
    );
}

function* gotoHighscore(action: Action<string>) {
    const tId = action.payload;
    const api = getHighscoreApi2();

    const r = yield* fetchWrapperFuncNoLoading(() => api.getMyTournamentHighscore({tId}));

    if (r) {
        yield put(push(highscoresPath(r.tournamentRoundId, r.absolutePos - 1)));
    }
}

function* watch() {
    //yield takeLatest(MY_GROUP_HIGHSCORES_LOAD, load);
    yield takeLatest(MY_GROUP_HIGHSCORES_FORWARD, forward);
    yield takeLatest(MY_GROUP_HIGHSCORES_GOTO_HIGHSCORE, gotoHighscore);
    yield takeLatest(MY_GROUP_HIGHSCORES_REFRESH_UNREAD_TEAMCHAT_MESSAGES, refreshUnreadTeamChat);
}

export function* myGroupHighscoreSagas() {
    yield all([watch()]);
}
