import { GlobalState } from '../../actions';

const matchStatisticSelector = (state: GlobalState) => state.matchStatistic;

export const matchStatisticEntitySelector = (state: GlobalState) => matchStatisticSelector(state).stats;

export const matchStatisticGamesSelector = (state: GlobalState) => matchStatisticSelector(state).games;

export const matchStatisticCountSelector = (state: GlobalState) => matchStatisticSelector(state).count;
export const matchStatisticCurrentOffsetSelector = (state: GlobalState) => matchStatisticSelector(state).currentOffset;
export const matchStatisticGameCountSelector = (state: GlobalState) => matchStatisticSelector(state).gameCount;
