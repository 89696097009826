import actionCreatorFactory from 'typescript-fsa';
import { UserProfileSettings } from '../../../services/account';
import { TournamentSubscription } from '../../../services/betting';
import { DeleteProfileResult } from './DeleteProfile';

const actionCreator = actionCreatorFactory();

export const ACCOUNT_USER_SETTING_LOAD_SUBSCRIBED_TOURNAMENTS = 'ACCOUNT_USER_SETTING_LOAD_SUBSCRIBED_TOURNAMENTS';
export const accountUserSettingLoadSubscribedTournaments = actionCreator(
    ACCOUNT_USER_SETTING_LOAD_SUBSCRIBED_TOURNAMENTS,
);

export const ACCOUNT_USER_SETTING_LOAD_USER_SETTINGS = 'ACCOUNT_USER_SETTING_LOAD_USER_SETTINGS';
export const accountUserSettingLoadUserSettings = actionCreator(ACCOUNT_USER_SETTING_LOAD_USER_SETTINGS);

export const ACCOUNT_USER_SETTING_SET_SUBSCRIBED_TOURNAMENTS = 'ACCOUNT_USER_SETTING_SET_SUBSCRIBED_TOURNAMENTS';
export const accountUserSettingSetSubscribedTournaments = actionCreator<TournamentSubscription[]>(
    ACCOUNT_USER_SETTING_SET_SUBSCRIBED_TOURNAMENTS,
);

export const ACCOUNT_USER_SETTING_SET_PROFILE_SETTINGS = 'ACCOUNT_USER_SETTING_SET_PROFILE_SETTINGS';
export const accountUserSettingSetProfileSettings = actionCreator<UserProfileSettings>(
    ACCOUNT_USER_SETTING_SET_PROFILE_SETTINGS,
);

export const ACCOUNT_USER_SETTING_CLEAR_PROFILE_SETTINGS = 'ACCOUNT_USER_SETTING_CLEAR_PROFILE_SETTINGS';
export const accountUserSettingClearProfileSettings = actionCreator(ACCOUNT_USER_SETTING_CLEAR_PROFILE_SETTINGS);

export const ACCOUNT_USER_SETTINGS_SAVE_SETTINGS = 'ACCOUNT_USER_SETTINGS_SAVE_SETTINGS';
export const accountUserSettingsSaveSettings = actionCreator<{
    subscribedTournaments: TournamentSubscription[];
    userProfileSettings: UserProfileSettings;
}>(ACCOUNT_USER_SETTINGS_SAVE_SETTINGS);


export const ACCOUNT_USER_SETTING_DELETE_PROFILE = 'ACCOUNT_USER_SETTING_DELETE_PROFILE';
export const accountUserSettingDeleteProfile = actionCreator(ACCOUNT_USER_SETTING_DELETE_PROFILE);

export const ACCOUNT_USER_SETTING_SET_DELETE_ACCOUNT_MESSAGE = 'ACCOUNT_USER_SETTING_SET_DELETE_ACCOUNT_MESSAGE';
export const accountUserSettingSetDeleteAccountMessage = actionCreator<{ msg: string; state: DeleteProfileResult }>(
    ACCOUNT_USER_SETTING_SET_DELETE_ACCOUNT_MESSAGE,
);

export const ACCOUNT_USER_SETTING_SUBSCRIBE_NEWSLETTER = 'ACCOUNT_USER_SETTING_SUBSCRIBE_NEWSLETTER';
export const accountUserSettingSubscribeNewsletter = actionCreator(ACCOUNT_USER_SETTING_SUBSCRIBE_NEWSLETTER);

export const ACCOUNT_USER_SETTING_DISMISS_NEWSLETTER_TEASER = 'ACCOUNT_USER_SETTING_DISMISS_NEWSLETTER_TEASER';
export const accountUserSettingDismissNewsletterTeaser = actionCreator(ACCOUNT_USER_SETTING_DISMISS_NEWSLETTER_TEASER);

export const ACCOUNT_USER_SETTING_DISMISS_INVALID_EMAIL_TEASER = 'ACCOUNT_USER_SETTING_DISMISS_INVALID_EMAIL_TEASER';
export const accountUserSettingDismissInvalidEmailTeaser = actionCreator(
    ACCOUNT_USER_SETTING_DISMISS_INVALID_EMAIL_TEASER,
);

