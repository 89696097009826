import { GlobalState } from '../../actions';

const accountUserSettingsSelector = (state: GlobalState) => state.accountUserSettings;

export const accountUserSettingsSubscribedTournamentsSelector = (state: GlobalState) =>
    accountUserSettingsSelector(state).subscribedTournaments;

export const accountUserSettingsProfileSettingsSelector = (state: GlobalState) =>
    accountUserSettingsSelector(state).userProfileSettings;

export const accountUserSettingsPasswordChangeMessageSelector = (state: GlobalState) =>
    accountUserSettingsSelector(state).passwordChangeMessage;

export const accountUserSettingsPasswordChangeStateSelector = (state: GlobalState) =>
    accountUserSettingsSelector(state).passwordChangeState;

export const accountUserSettingsEmailChangeMessageSelector = (state: GlobalState) =>
    accountUserSettingsSelector(state).emailChangeMessage;

export const accountUserSettingsEmailChangeStateSelector = (state: GlobalState) =>
    accountUserSettingsSelector(state).emailChangeState;

export const accountUserSettingsDeleteAccountMessageSelector = (state: GlobalState) =>
    accountUserSettingsSelector(state).deleteAccountMessage;

export const accountUserSettingsDeleteAccountStateSelector = (state: GlobalState) =>
    accountUserSettingsSelector(state).deleteAccountState;

