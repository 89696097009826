import { Action } from 'typescript-fsa';
import { fetchWrapperFunc } from '../../../utils';
import { getBettingApi2, getSysAdminProfile2Api } from '../../System/systemConfig';
import { userAdminSet, USER_ADMIN_LOAD, USER_ADMIN_TOGGLE_R1_MODERATOR, USER_ADMIN_MERGE_BETS, userAdminSetMergeBetsCount } from './actions';
import { all, takeLatest } from 'redux-saga/effects';


function* userAdminLoad(action: Action<string>) {
    yield* fetchWrapperFunc(
        () => getSysAdminProfile2Api().getUserProfile({userId: action.payload}),
        [r => userAdminSet(r)]
    )
}

function* toggleR1Moderator(action: Action<string>) {
    const userId = action.payload;
    const api = getSysAdminProfile2Api();
    yield* fetchWrapperFunc(() => api.toggleR1Moderator({userId}), [r => userAdminSet(r)]);
}

function* mergeBets(action: Action<{destUserId: string, srcUserName: string}>) {
    const srcUserName = action.payload.srcUserName;
    const destUserId = action.payload.destUserId;
    const api = getBettingApi2();
    yield* fetchWrapperFunc(() => api.mergeBets({destUserId: Number(destUserId), srcUserName}), [r => userAdminSetMergeBetsCount(r.message ?? '')]);
}

function* watchUserAdminActions() {
    yield takeLatest(USER_ADMIN_LOAD, userAdminLoad);
    yield takeLatest(USER_ADMIN_TOGGLE_R1_MODERATOR, toggleR1Moderator);
    yield takeLatest(USER_ADMIN_MERGE_BETS, mergeBets);
}

export function* userAdminSagas() {
    yield all([
        watchUserAdminActions()
    ])
}