import { AnyAction, isType } from 'typescript-fsa';
import { UserGroup } from '../../services/betting';
import {
    setUserGroupCount,
    setUserGroups,
    setUserGroupSearchString,
    setUserGroupsNextEnabled,
    setUserGroupsPriorEnabled,
    setUserGroupStartPos,
    userGroupsIsLoading
} from './actions';

export interface UserGroupListStore {
    userGroups: UserGroup[];
    count: number;
    startPos: number;
    slotSize: number;
    searchString: string;
    priorEnabled: boolean;
    nextEnabled: boolean;
    isLoading: boolean;
}

export const initialUserGroupListStore: UserGroupListStore = {
    userGroups: [],
    count: 0,
    startPos: 0,
    slotSize: 20,
    searchString: '',
    priorEnabled: false,
    nextEnabled: false,
    isLoading: false
};

export function userGroupListReducer(state: UserGroupListStore = initialUserGroupListStore, action: AnyAction) {
    if (isType(action, setUserGroupSearchString)) {
        return Object.assign({}, state, { searchString: action.payload });
    } else if (isType(action, setUserGroups)) {
        return Object.assign({}, state, { userGroups: action.payload });
    } else if (isType(action, setUserGroupCount)) {
        return Object.assign({}, state, { count: action.payload });
    } else if (isType(action, setUserGroupStartPos)) {
        return Object.assign({}, state, { startPos: action.payload });
    } else if (isType(action, setUserGroupsNextEnabled)) {
        return Object.assign({}, state, { nextEnabled: action.payload });
    } else if (isType(action, setUserGroupsPriorEnabled)) {
        return Object.assign({}, state, { priorEnabled: action.payload });
    } else if (isType(action, userGroupsIsLoading)) {
        return { ...state, isLoading: action.payload };
    }
    return state;
}
