import * as R from 'ramda';
import React, { Component, FC } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { AccountUserProfileScreen } from '../../components/AccountUserApp/AccountUserProfile';
import { AccountUserSettingsScreen } from '../../components/AccountUserApp/AccountUserSettings';
import { InvalidEmailTeaser } from '../../components/AccountUserApp/InvalidEmailTeaser';
import { NewsletterTeaser } from '../../components/AccountUserApp/NewsletterTeaser';
import { BettingProfileScreen } from '../../components/BettingApp/BettingProfile';
import { userNameSelector } from '../../components/System/selectors';
import { AppFeature, hasFeature } from '../../components/System/systemConfig';
import { withBorder } from '../../reactutils/withBorder';
import { withLogin } from '../../reactutils/withLogin';
import { withNavLocation } from '../../reactutils/withNavLocation';
import { TabbedParams, TabbedRequestParams, withTabbedScreen } from '../../reactutils/withTabbedScreen';
import { withTitleAsComponent } from '../../reactutils/withTitleAsComponent';
import { AccountUserMailScreen } from '../../components/AccountUserApp/AccountUserMail';
import { MyPlayListScreen } from '../../components/Playlist/MyPlayList';

interface Props extends TabbedRequestParams {}

class UserProfileComponent extends Component<Props> {
    render() {
        return (
            <>
                <NewsletterTeaser />
                <InvalidEmailTeaser />
                {this.props.tabName === 'Profil' && <AccountUserProfileScreen />}
                {this.props.tabName === 'Meine Lieblingsmusik' && <MyPlayListScreen />}
                {this.props.tabName === 'Tippspiel' && <BettingProfileScreen />}
                {this.props.tabName === 'Einstellungen' && <AccountUserSettingsScreen />}
                {this.props.tabName === 'Nachrichten' && <AccountUserMailScreen />}
            </>
        );
    }
}

const myProfilePathBaseFunc = (tabName: string) => `/b/profile/${tabName}`;

export const myProfilePath = () => myProfilePathBaseFunc('profile');
export const myProfileBettingPath = () => myProfilePathBaseFunc('betting');
export const myProfilePlaylistPath = () => myProfilePathBaseFunc('playlist');
export const myProfileSettingsPath = () => myProfilePathBaseFunc('settings');
export const myProfileNewsletterPath = () => myProfilePathBaseFunc('newsletter');
export const myUserMailPath = () => myProfilePathBaseFunc('mail');

const myProfilePathFunc = (tabName: string) => {
    switch (tabName) {
        case 'Profil': {
            return myProfilePath();
        }
        case 'Tippspiel': {
            return myProfileBettingPath();
        }
        case 'Meine Lieblingsmusik': {
            return myProfilePlaylistPath();
        }
        case 'Einstellungen': {
            return myProfileSettingsPath();
        }
        case 'Nachrichten': {
            return myUserMailPath();
        }
        default: {
            return myProfilePathBaseFunc(tabName);
        }
    }
};

function getTabNames() {
    const tabNames = ['Profil', 'Tippspiel', 'Einstellungen'];
    if (hasFeature(AppFeature.playlist)) {
        tabNames.push('Meine Lieblingsmusik');
    }
    tabNames.push('Nachrichten');

    return tabNames;
}

const bettingProfileTabbedProps: TabbedParams<TabbedRequestParams> = {
    tabNames: getTabNames(),
    tabLinks: (params: TabbedRequestParams) => myProfilePathFunc(params.tabName)
};
interface myProps {}

const MyTitle: FC<myProps> = props => {
    const userName = useSelector(userNameSelector);
    return (
        <div className="ft-screen ft-screen--my-profile ps-2 pe-2 ps-lg-4 pe-lg-4">
            <Helmet>
                <title>Mein Profil</title>
            </Helmet>
            <h1 className="mt-3">Mein Profil {userName}</h1>
        </div>
    );
};

export const UserProfileScreen = R.compose(
    withBorder(),
    withTitleAsComponent<Props>(MyTitle),
    withTabbedScreen<Props>(bettingProfileTabbedProps),
    withNavLocation('profile'),
    withLogin<Props>()
)(UserProfileComponent);
