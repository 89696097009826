import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isLoggedInSelector, userIdSelector } from '../../System/selectors';
import { bettingProfileLoadDefaultGroup } from '../BettingProfile/actions';
import { bettingProfileMyDefaultGroupSelector } from '../BettingProfile/selectors';
import { GroupInternalHighscoresTop5 } from '../GroupInternalHighscoresTop5';

interface Props {
    trId: string;
}

export const MyMainGroupInternalHighscoresTop5: FC<Props> = props => {
    const dispatch = useDispatch();

    const isLoggedIn = useSelector(isLoggedInSelector);
    const userId = useSelector(userIdSelector);
    const defaultGroup = useSelector(bettingProfileMyDefaultGroupSelector);

    useEffect(() => {
        if (isLoggedIn) {
            dispatch(bettingProfileLoadDefaultGroup());
        }
    }, [dispatch, isLoggedIn]);

    if (isLoggedIn) {
        if (defaultGroup) {
            return (
                <GroupInternalHighscoresTop5
                    trId={props.trId}
                    ugId={String(defaultGroup.id)}
                    mainUserId={String(userId)}
                    maxUser={5}
                    filterUserWithoutBets={true}
                />
            );
        } else {
            return <>Bitte eine Gruppe anlegen oder einer Gruppe beitreten</>;
        }
    } else {
        return <>Bitte anmelden</>;
    }
};
