import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQuery } from '@tanstack/react-query';
import * as R from 'ramda';
import { FC, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';
import { Button, Input } from 'reactstrap';
import rehypeRaw from 'rehype-raw';
import { withBorder } from '../../../reactutils/withBorder';
import { withNavLocation } from '../../../reactutils/withNavLocation';
import { withTitle } from '../../../reactutils/withTitle';
import { isClientAdminSelector } from '../../System/selectors';
import { getPrizesApi2, getSystemConfig } from '../../System/systemConfig';
import { LoadingIndicatorContent } from '../../common/LoadingIndicatorSpinner';
import { Section, SectionHeading } from '../../common/Section';

const R1PrizesComponent: FC<{}> = () => {
    const CACHE_NAME = 'r1Prizes';

    const cacheQuery = useQuery({
        queryKey: [CACHE_NAME],
        queryFn: () => getPrizesApi2().getPricesTextMarkdown({tenant: getSystemConfig().clientName}),
    });

    const mutation = useMutation({
        mutationFn: (text: string) => getPrizesApi2().updatePricesTextMarkdown({tenant: getSystemConfig().clientName, text}),
    });

    const isClientAdmin = useSelector(isClientAdminSelector);

    const [pricesTextMarkdown, setPricesTextMarkdown] = useState('');
    const [editMode, setEditMode] = useState(false);
    const [editText, setEditText] = useState('');

    useEffect(() => {
        setPricesTextMarkdown(cacheQuery.data?.message || '');
    }, [cacheQuery.data]);

    const startEditing = () => {
        setEditText(pricesTextMarkdown);
        setEditMode(true);
    };

    const save = () => {
        mutation.mutate(editText, { onSuccess: () => cacheQuery.refetch() });
        setEditMode(false);
    };

    const cancel = () => {
        setEditMode(false);
    };

    return (
        <>
            <Section>
                <SectionHeading>Preise für den Gewinn des Radioeins-Tippspiels</SectionHeading>
                <LoadingIndicatorContent isLoading={cacheQuery.isLoading}>
                    <ReactMarkdown rehypePlugins={[rehypeRaw]}>{pricesTextMarkdown}</ReactMarkdown>
                </LoadingIndicatorContent>
                {editMode && (
                    <div>
                        <Input type="textarea" value={editText} onChange={(e) => setEditText(e.currentTarget.value)} />
                        <Button onClick={save} className="m-2" color="success">
                            Speichern
                        </Button>
                        <Button onClick={cancel} className="m-2" color="warning">
                            Abbrechen
                        </Button>
                    </div>
                )}
                {isClientAdmin && !editMode && (
                    <Button onClick={startEditing} title="Preise-Beschreibung bearbeiten">
                        <FontAwesomeIcon icon={faEdit} color="red" />
                    </Button>
                )}
            </Section>

            <Section>
                <div className="textbox">
                    <em>
                        Der Rechtsweg ist ausgeschlossen. Bei möglicher Punktgleichheit entscheidet das Los. Der
                        Umtausch eines Preises oder die Auszahlung in Form von Bargeld sind ausgeschlossen.{' '}
                    </em>
                </div>
            </Section>
        </>
    );
};

export const prizesPath = () => `/b/prizes`;

export const R1PrizesScreen = R.compose(
    withBorder(),
    withTitle('RADIOEINS-FUSSBALL-TIPPSPIEL - MITMACHEN LOHNT SICH!'),
    withNavLocation('prizes'),
)(R1PrizesComponent);
