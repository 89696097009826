import actionCreatorFactory from 'typescript-fsa';
import { Game, MatchStatistic } from '../../../services/betting';

const actionCreator = actionCreatorFactory();

export const MS_LOAD = 'MS_LOAD';
export const matchStatisticLoad = actionCreator<{team1: string, team2: string}>(MS_LOAD);

export const MS_SET = 'MS_SET';
export const matchStatisticSet = actionCreator<MatchStatistic>(MS_SET);

export interface MatchStatisticGameParams {
    team1Id: string;
    team2Id: string;
    count: number;
    offset: number;
}

export const MS_LOAD_GAMES = 'MS_LOAD_GAMES';
export const matchStatisticLoadGames = actionCreator<MatchStatisticGameParams>(MS_LOAD_GAMES);

export const MS_GAMES_SET = 'MS_GAMES_SET';
export const matchStatisticGamesSet = actionCreator<Game[]>(MS_GAMES_SET);

export const MS_GAMES_SET_CURRENT = 'MS_GAMES_SET_CURRENT';
export const matchStatisticGamesSetCurrent = actionCreator<number>(MS_GAMES_SET_CURRENT);

export const MS_GAMES_SET_GAME_COUNT = 'MS_GAMES_SET_GAME_COUNT';
export const matchStatisticGamesSetGameCount = actionCreator<number>(MS_GAMES_SET_GAME_COUNT);

export const MS_GAMES_FAST_BACKWARD = 'MS_GAMES_FAST_BACKWARD';
export const matchStatisticGamesFastBackward = actionCreator(MS_GAMES_FAST_BACKWARD);

export const MS_GAMES_BACKWARD = 'MS_GAMES_BACKWARD';
export const matchStatisticGamesBackward = actionCreator(MS_GAMES_BACKWARD);

export const MS_GAMES_FORWARD = 'MS_GAMES_FORWARD';
export const matchStatisticGamesForward = actionCreator(MS_GAMES_FORWARD);

export const MS_GAMES_FAST_FORWARD = 'MS_GAMES_FAST_FORWARD';
export const matchStatisticGamesFastForward = actionCreator(MS_GAMES_FAST_FORWARD);