/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PlaylistStatistic
 */
export interface PlaylistStatistic {
    /**
     * 
     * @type {number}
     * @memberof PlaylistStatistic
     */
    totalNumberOfDistinctUsersInUserSongs: number;
    /**
     * 
     * @type {number}
     * @memberof PlaylistStatistic
     */
    totalNumberOfSongs: number;
    /**
     * 
     * @type {number}
     * @memberof PlaylistStatistic
     */
    totalNumberOfSongsWithUrl: number;
    /**
     * 
     * @type {number}
     * @memberof PlaylistStatistic
     */
    totalNumberOfUserSongs: number;
    /**
     * 
     * @type {number}
     * @memberof PlaylistStatistic
     */
    totalNumberOfAlbums: number;
    /**
     * 
     * @type {number}
     * @memberof PlaylistStatistic
     */
    totalNumberOfSongsWithAlbum: number;
    /**
     * 
     * @type {number}
     * @memberof PlaylistStatistic
     */
    totalNumberOfAlbumWithCover: number;
    /**
     * 
     * @type {number}
     * @memberof PlaylistStatistic
     */
    totalNumberOfAlbumsComplete: number;
}

/**
 * Check if a given object implements the PlaylistStatistic interface.
 */
export function instanceOfPlaylistStatistic(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "totalNumberOfDistinctUsersInUserSongs" in value;
    isInstance = isInstance && "totalNumberOfSongs" in value;
    isInstance = isInstance && "totalNumberOfSongsWithUrl" in value;
    isInstance = isInstance && "totalNumberOfUserSongs" in value;
    isInstance = isInstance && "totalNumberOfAlbums" in value;
    isInstance = isInstance && "totalNumberOfSongsWithAlbum" in value;
    isInstance = isInstance && "totalNumberOfAlbumWithCover" in value;
    isInstance = isInstance && "totalNumberOfAlbumsComplete" in value;

    return isInstance;
}

export function PlaylistStatisticFromJSON(json: any): PlaylistStatistic {
    return PlaylistStatisticFromJSONTyped(json, false);
}

export function PlaylistStatisticFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlaylistStatistic {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalNumberOfDistinctUsersInUserSongs': json['totalNumberOfDistinctUsersInUserSongs'],
        'totalNumberOfSongs': json['totalNumberOfSongs'],
        'totalNumberOfSongsWithUrl': json['totalNumberOfSongsWithUrl'],
        'totalNumberOfUserSongs': json['totalNumberOfUserSongs'],
        'totalNumberOfAlbums': json['totalNumberOfAlbums'],
        'totalNumberOfSongsWithAlbum': json['totalNumberOfSongsWithAlbum'],
        'totalNumberOfAlbumWithCover': json['totalNumberOfAlbumWithCover'],
        'totalNumberOfAlbumsComplete': json['totalNumberOfAlbumsComplete'],
    };
}

export function PlaylistStatisticToJSON(value?: PlaylistStatistic | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalNumberOfDistinctUsersInUserSongs': value.totalNumberOfDistinctUsersInUserSongs,
        'totalNumberOfSongs': value.totalNumberOfSongs,
        'totalNumberOfSongsWithUrl': value.totalNumberOfSongsWithUrl,
        'totalNumberOfUserSongs': value.totalNumberOfUserSongs,
        'totalNumberOfAlbums': value.totalNumberOfAlbums,
        'totalNumberOfSongsWithAlbum': value.totalNumberOfSongsWithAlbum,
        'totalNumberOfAlbumWithCover': value.totalNumberOfAlbumWithCover,
        'totalNumberOfAlbumsComplete': value.totalNumberOfAlbumsComplete,
    };
}

