/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UseCaseAccounting
 */
export interface UseCaseAccounting {
    /**
     * 
     * @type {number}
     * @memberof UseCaseAccounting
     */
    avgDuration?: number;
    /**
     * 
     * @type {number}
     * @memberof UseCaseAccounting
     */
    maxDuration?: number;
    /**
     * 
     * @type {number}
     * @memberof UseCaseAccounting
     */
    minDuration?: number;
    /**
     * 
     * @type {number}
     * @memberof UseCaseAccounting
     */
    count?: number;
    /**
     * 
     * @type {number}
     * @memberof UseCaseAccounting
     */
    duration?: number;
    /**
     * 
     * @type {string}
     * @memberof UseCaseAccounting
     */
    name?: string;
}

/**
 * Check if a given object implements the UseCaseAccounting interface.
 */
export function instanceOfUseCaseAccounting(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UseCaseAccountingFromJSON(json: any): UseCaseAccounting {
    return UseCaseAccountingFromJSONTyped(json, false);
}

export function UseCaseAccountingFromJSONTyped(json: any, ignoreDiscriminator: boolean): UseCaseAccounting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'avgDuration': !exists(json, 'avgDuration') ? undefined : json['avgDuration'],
        'maxDuration': !exists(json, 'maxDuration') ? undefined : json['maxDuration'],
        'minDuration': !exists(json, 'minDuration') ? undefined : json['minDuration'],
        'count': !exists(json, 'count') ? undefined : json['count'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function UseCaseAccountingToJSON(value?: UseCaseAccounting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'avgDuration': value.avgDuration,
        'maxDuration': value.maxDuration,
        'minDuration': value.minDuration,
        'count': value.count,
        'duration': value.duration,
        'name': value.name,
    };
}

