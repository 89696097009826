import { faFootball } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, PropsWithChildren } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import { getBettingApi2, getGameApi2, getSystemConfig } from '../../System/systemConfig';
import { useQuery } from '@tanstack/react-query';
import { GameResult } from '../../TournamentApp/GameResult';
import { BestBet } from '../../../services/betting';
import UserLink from '../../../links/UserLink';
import { LoadingIndicatorSpinner2 } from '../../common/LoadingIndicatorSpinner2';

interface Props {
    gameId: string;
    onClose: () => void;
}

export const BestBetsComp: FC<Props> = (props) => {
    const api = getGameApi2();

    const gameQuery = useQuery({
        queryKey: ['game', props.gameId],
        queryFn: () => api.getGameById({ gameId: props.gameId }),
        enabled: !!props.gameId,
    });

    const bestBetsQuery = useQuery({
        queryKey: ['bestBets', props.gameId],
        queryFn: () =>
            getBettingApi2().getBestGameBets({ gameId: Number(props.gameId), clientId: getSystemConfig().clientId }),
        enabled: !!props.gameId,
    });

    const renderBestBet = (bet: BestBet) => {
        return (
            <tr key={bet.userId} className="">
                <td>
                    <UserLink id={String(bet.userId)} showImage={true}/>
                </td>
                <td className="text-center">
                    {bet.bet1}:{bet.bet2}
                </td>
                <td className="text-center">{bet.points}</td>
            </tr>
        );
    };

    return (
        <Modal isOpen={true} toggle={props.onClose} size="lg">
            <ModalHeader toggle={props.onClose}>
            <div className="me-2 text-warning text-nowrap">Erfolgreichste Tipps</div>
            <div className="d-flex justify-content-start align-items-center flex-nowrap">
                    <div className="me-3 p-0 text-nowrap">
                        {gameQuery.data?.team1.shortName} - {gameQuery.data?.team2.shortName}{' '}
                    </div>
                    <GameResult gameId={props.gameId} />
                </div>
            </ModalHeader>
            <ModalBody>
                <Table>
                    <thead>
                        <tr>
                            <th>Nutzer</th>
                            <th className="text-center">Tip</th>
                            <th className="text-center">Punkte</th>
                        </tr>
                    </thead>
                    <tbody>{bestBetsQuery.data?.map((bet) => renderBestBet(bet))}</tbody>
                </Table>
                <LoadingIndicatorSpinner2 isLoading={bestBetsQuery.isFetching} />
            </ModalBody>
            <ModalFooter>
                <Button color="success" onClick={props.onClose}>
                    Schließen
                </Button>
            </ModalFooter>
        </Modal>
    );
};

interface BestBetsIndicatorProps extends PropsWithChildren {
    gameId: string;
}

export const BestBetsIndicator: FC<BestBetsIndicatorProps> = (props) => {
    const [showModal, setShowModal] = React.useState(false);

    const onShow = () => {
        setShowModal(true);
    };

    const onClose = () => {
        setShowModal(false);
    };

    return (
        <>
            {showModal && <BestBetsComp gameId={props.gameId} onClose={onClose} />}
            <Button color="link" onClick={onShow}>
                {!!props.children && <span>{props.children}</span>}
                {!props.children && <FontAwesomeIcon icon={faFootball} size="sm" />}
            </Button>
        </>
    );
};
