import * as R from 'ramda';
import React from 'react';
import { withBorder } from '../../../reactutils/withBorder';
import { withFooter } from '../../../reactutils/withFooter';
import { withLogin } from '../../../reactutils/withLogin';
import { withMenu } from '../../../reactutils/withMenu';
import { BetCountOverview } from '../../SysAdminApp/SysAdminSportsApp/SysAdminTournamentApp/BetCountOverview';
import { MenuClientAdmin } from '../MenuClientAdmin';


 const ClientAdminBetOverview: React.FC = () => {
    return (
        <>
            <BetCountOverview clients={['RadioEins']} />
        </>
    );
};

export const ClientAdminBetOverviewScreen = R.compose(
    withMenu(<MenuClientAdmin />),
    withBorder(),
    withLogin(),
    withFooter(),
)(ClientAdminBetOverview);
