import { DateTime } from 'luxon';
import * as R from 'ramda';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'reactstrap';
import { GroupInternalHighscoreLink } from '../../links/GroupInternalHighscoreLink';
import { UserGroupAdminLink } from '../../links/UserGroupAdminLink';
import { withBorder } from '../../reactutils/withBorder';
import { withNavLocation } from '../../reactutils/withNavLocation';
import { PagingComponent } from '../../reactutils/withPagination';
import { PagingParams } from '../../reactutils/withPaginationJS';
import { SearchComponent, SearchProps } from '../../reactutils/withSearch';
import { withTitle } from '../../reactutils/withTitle';
import { UserGroup, UserGroupDefaultHighscoreRound } from '../../services/betting';
import { SubMenuOverallHihgscores } from '../BettingApp/SubMenuOverallHighscores';
import { LoadingIndicatorSpinner2 } from '../common/LoadingIndicatorSpinner2';
import { userGroupHighscoresDefaultTrSelector } from '../UserGroupHighscores/selectors';
import {
    fetchUserGroups,
    pageDownUserGroups,
    pageFirstUserGroups,
    pageLastUserGroups,
    pageUpUserGroups,
    searchUserGroups,
} from './actions';
import {
    userGroupListCountSelector,
    userGroupListCurrentPosSelector,
    userGroupListEntitiesSelector,
    userGroupListIsLoadingSelector,
    userGroupListPageSizeSelector,
    userGroupListSearchStringSelector,
} from './selectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/free-solid-svg-icons';

export function getUserGroupDefaultHighscoreRound(
    ugId: string,
    defaultHighscoreRounds: { [ugId: string]: UserGroupDefaultHighscoreRound },
) {
    const defaultHighscoreTournamentRound: UserGroupDefaultHighscoreRound = defaultHighscoreRounds[ugId];
    if (defaultHighscoreTournamentRound) {
        return defaultHighscoreTournamentRound.tournamentRoundId;
    } else {
        return null;
    }
}

const UserGroupList = () => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(fetchUserGroups());
    }, [dispatch]);

    const selectedEntities = useSelector(userGroupListEntitiesSelector);
    const defaultHighscoreRounds = useSelector(userGroupHighscoresDefaultTrSelector);
    const isLoading = useSelector(userGroupListIsLoadingSelector);

    const renderUserGroup = (ug: UserGroup) => {
        const defaultRound = getUserGroupDefaultHighscoreRound(String(ug.id), defaultHighscoreRounds);
        const d = DateTime.fromJSDate(ug.date);
        const dateStr = d.isValid ? d.toFormat('dd.MM.yy') : '-';
        return (
            <tr key={ug.id}>
                <td>
                    <UserGroupAdminLink ugId={ug.id}>{ug.name}</UserGroupAdminLink>
                </td>
                <td className="d-none d-lg-table-cell">{dateStr}</td>
                <td className="text-center">
                    {defaultRound && (
                        <GroupInternalHighscoreLink ugId={ug.id} trId={defaultRound}>
                            <FontAwesomeIcon icon={faList} />
                        </GroupInternalHighscoreLink>
                    )}
                </td>
            </tr>
        );
    };

    return (
        <>
            <SubMenuOverallHihgscores activeSubMenu="team-directory" />
            <div className="d-flex align-items-center justify-content-between flex-wrap">
                <SearchComponent
                    searchResultCountSelector={(state: any) => searchProps.searchResultCountSelector(state)}
                    searchStringSelector={(state: any) => searchProps.searchStringSelector(state)}
                    onSearch={(s: string) => searchProps.onSearch(s)}
                    placeholder="Tipper Suche"
                />
                <PagingComponent
                    onForward={() => paginationParams.onForward()}
                    onFastForward={() => paginationParams.onFastForward()}
                    onBackward={() => paginationParams.onBackward()}
                    onFastBackward={() => paginationParams.onFastBackward()}
                    searchResultCount={paginationParams.searchResultCount}
                    searchResultCurrentPos={paginationParams.searchResultCurrentPos}
                    searchResultPageSize={paginationParams.searchResultPageSize}
                />
            </div>
            <LoadingIndicatorSpinner2 isLoading={isLoading}>
                <Table className="mt-3">
                    <thead>
                        <tr>
                            <th>Gruppe</th>
                            <th className="d-none d-lg-table-cell">seit</th>
                            <th className="text-center">Wertung</th>
                        </tr>
                    </thead>
                    <tbody>{selectedEntities && selectedEntities.map((ug) => renderUserGroup(ug))}</tbody>
                </Table>
            </LoadingIndicatorSpinner2>
        </>
    );
};

const searchProps: SearchProps = {
    searchResultCountSelector: (state: any) => userGroupListCountSelector(state),
    searchStringSelector: (state: any) => userGroupListSearchStringSelector(state),
    onSearch: (s: string) => searchUserGroups(s),
};

const paginationParams: PagingParams = {
    searchResultCount: (state: any) => userGroupListCountSelector(state),
    searchResultCurrentPos: (state: any) => userGroupListCurrentPosSelector(state),
    searchResultPageSize: (state: any) => userGroupListPageSizeSelector(state),
    onForward: () => pageDownUserGroups(),
    onFastForward: () => pageLastUserGroups(),
    onBackward: () => pageUpUserGroups(),
    onFastBackward: () => pageFirstUserGroups(),
};

const composeFunc = R.compose(withBorder(), withNavLocation('usergroup-list'), withTitle('Team-Verzeichnis'));

export const UserGroupListScreen = composeFunc(UserGroupList);
