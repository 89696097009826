import { faArrowCircleDown, faArrowCircleUp, faBan, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Table } from 'reactstrap';
import { GameEvent } from '../../../services/betting';
import { systemSetPopupMessage } from '../../System/actions';
import { isSysAdminSelector } from '../../System/selectors';
import { getGameApi2 } from '../../System/systemConfig';
import { GameResult } from '../../TournamentApp/GameResult';
import { ButtonWithConfirmation } from '../../common/ButtonWithConfirmation';
import { Section, SectionHeading } from '../../common/Section';

interface LiveTickerProps {
    gameId: string;
    onClose?: () => void;
}

export const LiveTicker: FC<LiveTickerProps> = (props) => {
    const api = getGameApi2();

    const dispatch = useDispatch();
    const isSysAdmin = useSelector(isSysAdminSelector);

    const deleteGameEventMutation = useMutation({
        mutationFn: (p: { gameId: string; gameEventId: string }) => api.deleteGameEvent({gameId: p.gameId, id: p.gameEventId}),

        onSuccess() {
            dispatch(systemSetPopupMessage('GameEvent successfully deleted'));
            gameEventQuery.refetch();
        },
        onError(e) {
            dispatch(systemSetPopupMessage('Error deleting GameEvent: ' + JSON.stringify(e)));
            gameEventQuery.refetch();
        },
    });

    const gameQuery = useQuery({ queryKey: ['game', props.gameId], queryFn: () => api.getGameById({gameId: props.gameId}) });

    const gameEventQuery = useQuery({
        queryKey: ['gameEvent', props.gameId],
        queryFn: () => api.getGameEvents({gameId: props.gameId}),
        refetchInterval: 60000,
    });

    const noGoalIcon = () => {
        return <FontAwesomeIcon icon={faBan} size="1x" color="red" className="m-0 p-0" />;
    };

    const onDeleteGameEvent = (geId: string) => {
        if (isSysAdmin) {
            deleteGameEventMutation.mutate({ gameId: props.gameId, gameEventId: geId });
        }
    };

    const getTypeIcon = (ge: GameEvent) => {
        switch (ge.type) {
            case 20:
                return '⚽';
            case 21:
                // Eigentor
                return '⚽';
            case 22:
                // Elfmetertor
                return '⚽';
            case 23:
                // vergebener Elfmeter
                return noGoalIcon();
            case 4:
                // yellow card
                return '🟨';
            case 3:
                // red card
                return '🟥';
            case 6:
                // player change
                return '🔄';
            case 24:
                // goal canceled by VAR
                return noGoalIcon();
            case 25:
                // penalty confirmed by VAR
                return 'peanlty ⚽ confirmed';
            case 26:
                // goal confirmed by VAR
                return '⚽ confirmed';
            default:
                return ge.type;
        }
    };

    const renderGameIntermediateResult = (ge: GameEvent) => {
        if (ge.type === 20 || ge.type === 21 || ge.type === 22) {
            return (
                <>
                    <b>
                        {ge.result1}&nbsp;:&nbsp;{ge.result2}
                    </b>
                </>
            );
        }
    };

    const renderGameEventComment = (ge: GameEvent) => {
        let comment = '';
        if (ge && ge.comment === 'Argument') {
            comment = 'Meckern';
        } else if (ge && ge.comment === 'Foul') {
            comment = 'Foulspiel';
        } else if (ge && ge.comment === 'Handball') {
            comment = 'Handspiel';
        } else if (ge && ge.comment === 'Time wasting') {
            comment = 'Zeitspiel';
        } else if (ge && ge.comment === 'Tripping') {
            comment = 'Stempeln';
        } else if (ge && ge.comment === 'Holding') {
            comment = 'Halten';
        } else if (ge && ge.comment === 'Unsportsmanlike conduct') {
            comment = 'unsportliches Verhalten';
        } else if (ge && ge.comment === 'Roughing') {
            comment = 'Rauferei';
        } else {
            comment = ge.comment ?? '';
        }
        if (ge.comment) {
            return (
                <>
                    <br />
                    <span>{comment}</span>
                </>
            );
        }
        return null;
    };

    const renderGameEventContent = (ge: GameEvent) => {
        switch (ge.type) {
            case 20:
                return (
                    <span>
                        Tor durch {ge.playerId1FirstName} {ge.playerId1LastName}
                        {ge.playerId2 && (
                            <>
                                <br />
                                <small>
                                    Vorlage: {ge.playerId2FirstName} {ge.playerId2LastName}
                                </small>
                            </>
                        )}
                        {renderGameEventComment(ge)}
                    </span>
                );
            case 21:
                // Eigentor
                return (
                    <span>
                        Eigentor von {ge.playerId1FirstName} {ge.playerId1LastName}
                        {renderGameEventComment(ge)}
                    </span>
                );
            case 22:
                // Elfmetertor
                return (
                    <span>
                        Elfmetertor {ge.playerId1FirstName} {ge.playerId1LastName}
                        {renderGameEventComment(ge)}
                    </span>
                );
            case 23:
                // vergebener Elfmeter
                return (
                    <span>
                        Elfmeter vergeben durch {ge.playerId1FirstName} {ge.playerId1LastName}
                        {renderGameEventComment(ge)}
                    </span>
                );
            case 4:
                // yellow card
                return (
                    <span>
                        Gelbe Karte für {ge.playerId1FirstName} {ge.playerId1LastName}
                        {renderGameEventComment(ge)}
                    </span>
                );
            case 3:
                // red card
                return (
                    <span>
                        Rote Karte für {ge.playerId1FirstName} {ge.playerId1LastName}
                        {renderGameEventComment(ge)}
                    </span>
                );
            case 6:
                // player change
                return (
                    <span>
                        <FontAwesomeIcon icon={faArrowCircleUp} size="sm" color="green" className="me-1" />
                        {ge.playerId1FirstName} {ge.playerId1LastName}
                        <br />
                        <FontAwesomeIcon icon={faArrowCircleDown} size="sm" color="red" className="me-1" />
                        {ge.playerId2FirstName} {ge.playerId2LastName}
                        {renderGameEventComment(ge)}
                    </span>
                );
            case 24:
                // goal canceled by VAR
                return (
                    <span>
                        Tor durch VAR zurückgenommen
                        {renderGameEventComment(ge)}
                    </span>
                );
            case 25:
                // penalty confirmed by VAR
                return (
                    <span>
                        Elfmeter durch VAR bestätigt
                        {renderGameEventComment(ge)}
                    </span>
                );
            case 26:
                // goal confirmed by VAR
                return (
                    <span>
                        Tor durch VAR bestätigt
                        {renderGameEventComment(ge)}
                    </span>
                );
            case 27:
                // penalty canceled
                return (
                    <span>
                        Elfmeter zurückgenommen
                        {renderGameEventComment(ge)}
                    </span>
                );
            default:
                return (
                    <span>
                        ?&nbsp;
                        {ge.playerId1FirstName} {ge.playerId1LastName}
                        {ge.playerId2FirstName} {ge.playerId2LastName}
                        {renderGameEventComment(ge)}
                    </span>
                );
        }
    };

    const renderGameEvent = (gameEvent: GameEvent) => {
        return (
            <tr key={gameEvent.id}>
                <td className="mx-0 px-0 text-center">
                    {gameEvent.minute}
                    {gameEvent.extraMinute > 0 && <small>+{gameEvent.extraMinute}</small>}
                </td>
                <td className="mx-0 px-0 text-center">{renderGameIntermediateResult(gameEvent)}</td>
                <td className="mx-0 px-0">{getTypeIcon(gameEvent)}</td>
                <td className="mx-0 px-0">{gameEvent.team === 'home' && renderGameEventContent(gameEvent)}</td>
                <td className="mx-0 px-0">{gameEvent.team === 'away' && renderGameEventContent(gameEvent)}</td>
                {isSysAdmin && (
                    <td className="mx-0 px-0">
                        <ButtonWithConfirmation
                            color="link"
                            title="GameEvent löschen"
                            message="Sind Sie sicher, dass Sie das GameEvent löschen wollen?"
                            onOK={() => onDeleteGameEvent(gameEvent.id)}
                        >
                            <FontAwesomeIcon icon={faTrash} size="sm" />
                        </ButtonWithConfirmation>
                    </td>
                )}
            </tr>
        );
    };

    const sortGameEvents = (a: GameEvent, b: GameEvent) => {
        if (a.minute === b.minute) {
            return b.extraMinute - a.extraMinute;
        }
        return b.minute - a.minute;
    };

    return (
        <div>
            <Section>
                <SectionHeading>
                    <div className="d-flex justify-content-between align-items-center">
                        <div>
                            {gameQuery.data?.team1.shortName} - {gameQuery.data?.team2.shortName}
                        </div>
                        <GameResult gameId={props.gameId} date={gameQuery.data?.date} />
                    </div>
                </SectionHeading>
                {gameEventQuery.isLoading && <div>Loading...</div>}
                {gameEventQuery.isError && <div>Error loading game events</div>}
                {gameEventQuery.data?.length === 0 && (
                    <Alert color="success">Es liegen noch keine Ereignisse vor.</Alert>
                )}
                <Table>
                    <thead>
                        <tr>
                            <th className="col-1 mx-0 px-0 text-center" title="Spielminute">
                                M
                            </th>
                            <th className="col-2 mx-0 px-0 text-center">Stand</th>
                            <th className="col-1 mx-0 px-0"></th>
                            <th className="col-4 mx-0 px-0">{gameQuery.data?.team1.name}</th>
                            <th className="col-4 mx-0 px-0">{gameQuery.data?.team2.name}</th>
                            {isSysAdmin && <th className="col-1 mx-0 px-0"></th>}
                        </tr>
                    </thead>
                    <tbody>
                        {gameEventQuery.isSuccess &&
                            gameEventQuery.data.sort(sortGameEvents).map((gameEvent) => renderGameEvent(gameEvent))}
                    </tbody>
                </Table>
                {props.onClose && (
                    <Button color="success" className="mt-2" onClick={props.onClose}>
                        Close
                    </Button>
                )}
            </Section>
        </div>
    );
};
