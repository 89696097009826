import * as R from 'ramda';
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Button, Table } from 'reactstrap';
import UserLink from '../../../../links/UserLink';
import { PaginationParams, withPagination } from '../../../../reactutils/withPagination';
import { UserMail } from '../../../../services/betting';
import { Section, SectionHeading } from '../../../common/Section';
import { truncateText, TruncateText } from '../../../common/TruncateText';
import { DateTime } from '../../../DateTime';
import {
    userMailBackward,
    userMailDelete,
    userMailFastBackward,
    userMailFastForward,
    userMailForward,
    userMailLoad
} from '../actions';
import {
    userMailCurrentPosSelector,
    userMailEntityCountSelector,
    userMailEntitySelector,
    userMailSlotSizeSelector
} from '../selectors';
import { UserMailDetails } from '../UserMailDetails';
import { UserMailForm } from '../UserMailForm';

export const UserMailGridComponent = () => {
    const mails = useSelector(userMailEntitySelector);
    const [showInlineForm, setShowInlineForm] = useState<{ [key: string]: boolean }>({});
    const [showMailDetails, setShowMailDetails] = useState<UserMail>();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(userMailLoad());
    }, [dispatch]);

    function onHideMailDetails() {
        setShowMailDetails(undefined);
    }

    function onDelete(um: UserMail) {
        onHideMailDetails();
        dispatch(userMailDelete(um.id));
    }

    function onAnswer(um: UserMail) {
        onHideMailDetails();
        setShowInlineForm({ ...showInlineForm, [um.id]: true });
    }

    function onAnswerSend(um: UserMail) {
        onDelete(um);
        setShowInlineForm({ ...showInlineForm, [um.id]: false });
    }

    function onAnswerCancel(um: UserMail) {
        setShowInlineForm({ ...showInlineForm, [um.id]: false });
    }

    function markdownIndent(s: string) {
        return s
            .split('\n')
            .map(s => '> ' + s)
            .join('\n');
    }

    function showMail(um: UserMail) {
        return (
            <React.Fragment key={um.id}>
                <tr key={um.id}>
                    <td>
                        <UserLink id={um.senderId} name={um.senderName} />
                        <span className="d-block d-md-none">
                            <DateTime date={um.date} />
                        </span>
                    </td>
                    <td className="d-none d-md-table-cell">
                        <DateTime date={um.date} />
                    </td>
                    <td>
                        <Button size="sm" color="link" className="ms-0 ps-0" onClick={() => setShowMailDetails(um)}>
                            <TruncateText text={um.subject} maxLength={15} />
                        </Button>
                        <span className="d-none d-md-table-cell">
                            <ReactMarkdown>{truncateText(um.message, 100)}</ReactMarkdown>
                        </span>
                    </td>
                </tr>
                {showInlineForm[um.id] && (
                    <tr>
                        <td colSpan={4}>
                            <UserMailForm
                                receiver={um.senderName}
                                subject={`Re: ${um.subject}`}
                                message={markdownIndent(um.message)}
                                onCancel={() => onAnswerCancel(um)}
                                onSend={() => onAnswerSend(um)}
                            />
                        </td>
                    </tr>
                )}
            </React.Fragment>
        );
    }

    return (
        <>
            {(!mails || mails.length === 0) && (
                <Alert color="primary" className="mt-4">
                    Es liegen derzeit keine Nachrichten für sie vor.
                </Alert>
            )}
            {showMailDetails && (
                <UserMailDetails
                    um={showMailDetails}
                    onCancel={() => onHideMailDetails()}
                    onDelete={() => onDelete(showMailDetails)}
                    onReply={() => onAnswer(showMailDetails)}
                />
            )}
            <Table striped={true}>
                <thead>
                    <tr>
                        <th className="d-none d-md-table-cell">Absender</th>
                        <th className="d-table-cell d-md-none">Abs</th>
                        <th className="d-none d-md-table-cell">Datum</th>
                        <th>Nachricht</th>
                    </tr>
                </thead>
                <tbody>{mails && mails.map(m => showMail(m))}</tbody>
            </Table>
        </>
    );
};

const pagingParams: PaginationParams = {
    searchResultCount: (state: any) => userMailEntityCountSelector(state),
    searchResultCurrentPos: (state: any) => userMailCurrentPosSelector(state),
    searchResultPageSize: (state: any) => userMailSlotSizeSelector(state),
    onBackward: () => userMailBackward(),
    onFastBackward: () => userMailFastBackward(),
    onFastForward: () => userMailFastForward(),
    onForward: () => userMailForward()
};

const UserMailGridComp = R.compose(withPagination(pagingParams))(UserMailGridComponent);

export const UserMailGrid = () => (
    <Section>
        <SectionHeading>Nachrichten-Übersicht</SectionHeading>
        <UserMailGridComp />
    </Section>
);
