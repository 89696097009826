import React, { FC, PropsWithChildren } from 'react';
import { useSelector } from 'react-redux';
import { systemUserRolesSelector } from '../../System/selectors';

interface Props extends PropsWithChildren {
    roleName: string;
}
export const ShowWhenUserHasRole: FC<Props> = props => {

    const userRoles = useSelector(systemUserRolesSelector);

    const hasRole = userRoles.find(s => s === props.roleName) !== undefined;

    if (hasRole) {
        return <> {props.children} </>;
    } else return null;
};
