import { faSignInAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form } from 'reactstrap';
import { ButtonWithConfirmation } from '../common/ButtonWithConfirmation';
import { logout } from '../System/actions';
import { isLoggedInSelector } from '../System/selectors';

export const Login = () => {
    const dispatch = useDispatch();

    const loggedIn = useSelector(isLoggedInSelector);
    const onLogout = () => {
        dispatch(logout());
    };

    function loginClick() {
        // redirectToLogin('/betting');
    }

    if (loggedIn) {
        return (
            <Form >
                <ButtonWithConfirmation
                    color="danger"
                    outline={true}
                    onOK={onLogout}
                    title="Abmeldung"
                    message="Wollen Sie sich abmelden?"
                >
                    <FontAwesomeIcon icon={faSignOutAlt} />
                </ButtonWithConfirmation>
            </Form>
        );
    } else {
        return (
            <Form >
                <Button color="success" outline={true} onClick={loginClick}>
                    <FontAwesomeIcon icon={faSignInAlt} />
                </Button>
            </Form>
        );
    }
};
