import { useMutation, useQuery } from '@tanstack/react-query';
import * as R from 'ramda';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Container } from 'reactstrap';
import { withBorder } from '../../../reactutils/withBorder';
import { withLogin } from '../../../reactutils/withLogin';
import { withMenu } from '../../../reactutils/withMenu';
import { TabbedContent } from '../../../reactutils/withTabbedScreen';
import { withTitle } from '../../../reactutils/withTitle';
import { SqlStatistics } from '../../../services/betting';
import { formatTimeDurationGerman } from '../../../utils';
import { MenuSysAdmin } from '../../MenuSysAdmin';
import { systemSetPopupMessage } from '../../System/actions';
import { ServerType, getSqlStatisticsApi, relevantServerNames } from '../../System/systemConfig';
import { LoadingIndicatorContent } from '../../common/LoadingIndicatorSpinner';
import { usePathSegment } from '../CacheStatisticsComponent';
import { SysAdminSystemSubMenu } from '../SysAdminSubMenu';
import { sysAdminSqlPath } from '../sysAdminPathes';

export const SQLStatisticsComponent: React.FC<{}> = (props) => {
    const [tabName] = usePathSegment(3) as ServerType[];

    const api = getSqlStatisticsApi(tabName);

    const [stats, setStats] = React.useState<SqlStatistics[]>([]);

    const statsQuery = useQuery({
        queryKey: ['sqlStatistics', tabName],
        queryFn: () => api.getSqlStatistics(),
        retry: false,
    });

    React.useEffect(() => {
        setStats(statsQuery.data || []);
    }, [statsQuery.data]);

    const clearStatsMutation = useMutation({
        mutationFn: () => api.clearSqlStatistics(),
        onSuccess() {
            dispatch(systemSetPopupMessage('Statistics cleared'));
            statsQuery.refetch();
        },
        onError() {
            dispatch(systemSetPopupMessage('Failed to clear statistics'));
            statsQuery.refetch();
        },
    });

    // const statsOld = useSelector((state: any) => sqlStatisticsStatsSelector(state, tabName) || ([] as SqlStatistics[]));
    // const serverAvailable = useSelector((state: any) => sqlStatisticsServerAvailableSelector(state, tabName) || false);

    const dispatch = useDispatch();

    const onReload = () => {
        statsQuery.refetch();
    };
    const onClear = () => {
        clearStatsMutation.mutate();
    };

    function formatNumber(n?: number) {
        if (n) {
            const nf = new Intl.NumberFormat(undefined, {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            });
            return nf.format(n);
        } else {
            return null;
        }
    }

    return (
        <>
            <TabbedContent activeTab={tabName} tabNames={relevantServerNames()} tabLinks={sysAdminSqlPath} />
            <Container>
                <Button className="m-2" color="secondary" onClick={onReload} outline={false}>
                    Reload
                </Button>
                <Button className="m-2" color="warning" onClick={onClear} outline={false}>
                    Clear
                </Button>
            </Container>
            <table className="table table-bordered table-striped">
                <thead>
                    <tr>
                        <th> Statement</th>
                        <th className="d-none d-lg-table-cell">Count</th>
                        <th className="d-none d-lg-table-cell">Time</th>
                        <th className="d-none d-lg-table-cell">Avg. time (ms)</th>
                        <th>%</th>
                    </tr>
                </thead>
                <tbody>
                    {stats &&
                        stats.map((stat, index) => (
                            <tr key={index}>
                                <td className="text-break">
                                    <LoadingIndicatorContent isLoading={statsQuery.isFetching}>
                                        {stat.name}
                                    </LoadingIndicatorContent>
                                </td>
                                <td className="text-end d-none d-lg-table-cell">{stat.executionCount}</td>
                                <td className="text-end d-none d-lg-table-cell">
                                    {formatTimeDurationGerman(stat.executionTime)}
                                </td>
                                <td className="text-end d-none d-lg-table-cell">
                                    {stat.executionCount && stat.executionTime
                                        ? formatNumber(stat.executionTime / stat.executionCount)
                                        : 0}
                                </td>
                                <td className="text-end">{stat.percentage && formatNumber(stat.percentage / 100.0)}</td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </>
    );
};

export const SqlStatisticsScreen = R.compose(
    withMenu(<MenuSysAdmin />),
    withBorder(),
    withMenu(<SysAdminSystemSubMenu />),
    withTitle('SQL Statistics'),
    withLogin(),
)(SQLStatisticsComponent);
