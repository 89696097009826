import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

export const LoadingIndicatorSpinner2: React.FC<React.PropsWithChildren<{ isLoading: boolean }>> = props => {
    return (
        <div>
            {props.isLoading && (
                <FontAwesomeIcon
                    icon={faSpinner}
                    spin={true}
                    size="2x"
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 999
                    }}
                />
            )}
            {props.children}
        </div>
    );
};
