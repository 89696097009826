import { push } from '@lagunovsky/redux-react-router';
import { useMutation, useQuery } from '@tanstack/react-query';
import * as R from 'ramda';
import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Alert, Button, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { withBorder } from '../../../reactutils/withBorder';
import { withNavLocation } from '../../../reactutils/withNavLocation';
import { FuntipTitle } from '../../../reactutils/withTitle';
import { isValidEmail } from '../../../utils';
import { getProfile2Api, getRegistration2Api, getSystemConfig } from '../../System/systemConfig';
import { LoadingIndicatorSpinner2 } from '../../common/LoadingIndicatorSpinner2';
import { newsletterArchivePath } from '../NewsletterUserProfile';

const NewsletterSubscriptionComponent: FC<{}> = () => {
    const dispatch = useDispatch();

    const agbInfoQuery = useQuery({
        queryKey: ['agbInfos', getSystemConfig().clientName],
        queryFn: () => getProfile2Api().getAgbInfos({ tenant: getSystemConfig().clientName }),
    });

    const [email, setEmail] = useState('');
    const [success, setSuccess] = useState(false);
    const [failure, setFailure] = useState(false);
    const [emailValid, setEmailValid] = useState(false);
    const [privacyStatementAccepted, setPrivacyStatementAccepted] = useState(false);
    const [subscriptionResult, setSubscriptionResult] = useState('');

    const [okButtonEnabled, setOkButtonEnabled] = useState(false);

    const api = getRegistration2Api();
    const subscribeMutation = useMutation({
        mutationFn: (email: string) => api.newsletterRegistration({ tenant: getSystemConfig().clientName, email }),
    });

    useEffect(() => {
        setEmailValid(isValidEmail(email) || email === '');
        setOkButtonEnabled(privacyStatementAccepted && isValidEmail(email));
    }, [email, privacyStatementAccepted]);

    const cancel = () => {
        setFailure(false);
        setSuccess(false);
        dispatch(push(newsletterArchivePath(0)));
    };

    useEffect(() => {
        if (success) {
            const timeout = setTimeout(() => {
                dispatch(push(newsletterArchivePath(0)));
                setSuccess(false);
            }, 10000);
            return () => clearTimeout(timeout);
        }
    }, [success, dispatch]);

    const handleSubscriptionResult = (r: string) => {
        if (r === 'successNewUserCreated') {
            setSubscriptionResult(
                'Sie wurden als neuer Newsletter-Abonnent hinzugfügt. Sie erhalten in Kürze eine Email, in der Sie einen Link zur Bestätigung ihrer E-Mail-Adresse finden.',
            );
            setSuccess(true);
        } else if (r === 'successExistingUserSubscribed') {
            setSubscriptionResult(
                'Sie erhalten zukünftig den Newsletter. Bitte bestätigen Sie Ihre E-Mail Adresse über eine Email, die Sie in Kürze erhalten.',
            );
            setSuccess(true);
        } else if (r === 'failure') {
            setSubscriptionResult('Ooops ... ein Fehler ist aufgetreten.');
            setFailure(true);
        } else if (r === 'failureEmailInvalid') {
            setSubscriptionResult(
                'Die E-Mail Adresse ist nicht gültig. Bitte korrigieren Sie die Adresse bevor Sie die Anmeldung wiederholen.',
            );
            setFailure(true);
        }
    };

    const subscribe = () => {
        subscribeMutation.mutate(email, {
            onSuccess: (data, variables, context) => {
                handleSubscriptionResult(data.registrationResult ?? '');
            },
        });
    };

    return (
        <>
            <div className="my-4">
                <FuntipTitle title="Newsletter Anmeldung" subTitle="" />
                <Form>
                    <p className="mb-3 w-50">
                        Tragen Sie hier ihre Mailadresse ein. Nach Absenden des Formulars erhalten Sie eine Mail mit
                        weiteren Informationen zur Aktivierung des Newsletter-Abos.
                    </p>
                    <FormGroup>
                        <Label>E-Mail Adresse</Label>
                        <Input
                            className="w-25"
                            value={email}
                            onChange={(e) => setEmail(e.currentTarget.value)}
                            invalid={!emailValid}
                        />
                        <FormFeedback error={true}>ungültige E-Mail Adresse</FormFeedback>
                    </FormGroup>
                    <h2>Datenschutzhinweis</h2>
                    <p className="mb-3 w-50">
                        Mit der Anmeldung zu diesem Service willigen Sie in die Speicherung der von Ihnen eingetragenen
                        Daten ein. Diese werden vom rbb ausschließlich dazu verwandt, Ihnen den ausgewählten Service
                        bereitzustellen. Ihre Daten werden von uns nicht an Dritte weitergegeben oder Dritten
                        überlassen. Sie haben jederzeit die Möglichkeit diesen Service abzubestellen, mit der Abmeldung
                        des Services werden Ihre Daten gelöscht.
                    </p>
                    <FormGroup check={true}>
                        <Label>
                            <Input
                                type="checkbox"
                                checked={privacyStatementAccepted}
                                onChange={(e) => setPrivacyStatementAccepted(e.currentTarget.checked)}
                            />
                            Ich habe die rbb{' '}
                            <Link
                                to={agbInfoQuery.data?.privacyStatementUrl ?? ''}
                                className="text-decoration-underline"
                            >
                                Datenschutzerklärung
                            </Link>{' '}
                            zur Kenntnis genommen.
                        </Label>
                    </FormGroup>
                </Form>
                {success && (
                    <Alert color="success">
                        <h2 className="alert-heading">Newsletter-Registrierung erfolgreich</h2>
                        <p>{subscriptionResult}</p>
                        <p>Sie werden in 10 Sekunden weitergeleitet.</p>
                    </Alert>
                )}
                {failure && (
                    <Alert color="warning">
                        <h2 className="alert-heading">Newsletter-Registrierung nicht erfolgreich</h2>
                        <p>{subscriptionResult}</p>
                    </Alert>
                )}
                {!success && (
                    <LoadingIndicatorSpinner2 isLoading={subscribeMutation.isPending}>
                        <Button className="m-2" color="dark" disabled={!okButtonEnabled} onClick={subscribe}>
                            Absenden
                        </Button>
                        <Button className="m-2" onClick={cancel}>
                            Zurück zum Newsletter-Archiv
                        </Button>
                    </LoadingIndicatorSpinner2>
                )}
            </div>
        </>
    );
};

export const NewsletterSubscription = R.compose(
    withBorder(),
    withNavLocation('newsletter'),
)(NewsletterSubscriptionComponent);
