import actionCreatorFactory from "typescript-fsa/dist/typescript-fsa";
import { SystemProperty } from "../../../services/account";

const actionCreator = actionCreatorFactory();

export const SYSTEM_PROPERTIES_LOAD = 'SYSTEM_PROPERTIES_LOAD';
export const systemPropertiesLoad = actionCreator(SYSTEM_PROPERTIES_LOAD);

export const SYSTEM_PROPERTIES_SET = 'SYSTEM_PROPERTIES_SET';
export const systemPropertiesSet = actionCreator<SystemProperty[]>(SYSTEM_PROPERTIES_SET);

export const SYSTEM_PROPERTIES_UPDATE = 'SYSTEM_PROPERTIES_UPDATE';
export const systemPropertiesUpdate = actionCreator<{name: string, value: string}>(SYSTEM_PROPERTIES_UPDATE);